import Chain from '../../../../utils/classes/chain.js'

const load = (tagIds, limit, setIsLoading, setTags, actions) => {
  const _getRelatedTags = () => {
    return actions.getRelatedTags({ tagId: tagIds[tagIds.length - 1], limit })
  }

  const chain = new Chain()

  return _getRelatedTags()
    .then((data1) => {
      if (data1.error) {
        chain.break('Failed to get related tags.')

        return Promise.resolve()
      }

      chain.break('Successfully retrieved related tags.')

      return Promise.resolve()
    })
    .then((last_data) => {
      let { broken, log } = chain

      if (broken) {
        console.log('[DEBUG]: ', log)

        setTags(tagIds)
        setIsLoading(false)

        return Promise.resolve()
      }

      throw new Error('Unhandled promise chain logic.')
    })
    .catch((error) => {
      console.log('[ERROR]: ', error)
    })
}

export default load
