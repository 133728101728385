import config from '../../../config'
import { authenticatedFetch } from '../../../utils/functions/authenticatedFetch'
import { rolesActions } from '../../action-creators/roles.js'

/** takes an array of roleIds */
export const getOrganizationRolesByID = (params = {}, options = {}) => {
  const { roleIds } = params

  let responseCode = ''
  let url = `${config.baseURL}/api/role/organization/?roleIds=${JSON.stringify(roleIds)}`
  let method = 'GET'
  let headers = {
    'Content-Type': 'application/json'
  }

  return (dispatch) => {
    console.log('Sending getOrganizationRolesByID request to CivilTalk')

    return authenticatedFetch(url, {
      method,
      headers,
      credentials: 'include'
    })
      .then((response) => {
        responseCode = response.status

        return response.json()
      })
      .then((data) => {
        if (responseCode === 200) {
          if (!options.disableRedux) {
            dispatch(rolesActions.set(data.roles))
          }

          return {
            error: false,
            code: responseCode,
            message: data.message,
            roles: data.roles
          }
        } else {
          console.log(data.message)

          return {
            error: true,
            code: responseCode,
            message: data.message
          }
        }
      })
      .catch((error) => {
        console.log(error)

        return {
          error: true,
          code: '',
          message: error
        }
      })
  }
}
