//////////////////
// ACTION TYPES //
//////////////////

export const tagResultActionTypes = {
  SET_TAG_RESULTS: "SET_TAG_RESULTS",
  CLEAR_TAG_RESULTS: "CLEAR_TAG_RESULTS",
}

/////////////
// ACTIONS //
/////////////

// TAG RESULTS
const set = (tags) => {
  return {
    type: tagResultActionTypes.SET_TAG_RESULTS,
    tags,
  }
}

const clear = () => {
  return {
    type: tagResultActionTypes.CLEAR_TAG_RESULTS,
  }
}

export const tagResultsActions = {
  set,
  clear,
}
