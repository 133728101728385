import { pollVoteActionTypes } from '../action-creators/poll-votes.js'
import _ from 'lodash'

const copyPollVotes = (pollVotes) => {
  return pollVotes.map((pollVote) => ({ ...pollVote }))
}

// FOR POLL VOTES
const pollVotesReducer = (state = [], action) => {
  switch (action.type) {
    case pollVoteActionTypes.SET_POLL_VOTES:
      return copyPollVotes(action.pollVotes)

    case pollVoteActionTypes.ADD_POLL_VOTES:
      let newPollVotes = copyPollVotes(state)

      for (var i = 0; i < action.pollVotes.length; i++) {
        const pollVoteExists = _.some(newPollVotes, {
          _id: action.pollVotes[i]._id
        })

        if (!pollVoteExists) {
          newPollVotes.push(action.pollVotes[i])
        }
      }

      return newPollVotes

    case pollVoteActionTypes.CLEAR_POLL_VOTES:
      return []

    default:
      return state
  }
}

export default pollVotesReducer
