import styled from 'styled-components'
import theme from '../../../../../../res/theme.js'
// import config from '../../../../../../config.js'

export const MainContainer = styled.div`
  background-color: ${(props) => props.theme.palette.common.white};
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  /* border-radius: 0.3rem; */
  box-shadow: 0px 5px 5px -2px rgba(0, 0, 0, 0.2);
  /* height: 100vh; */
`

export const HeadingPrimary = styled.p`
  font-family: ${theme.typography.fontFamily};
  font-size: 1.6rem;
  font-weight: ${theme.typography.fontWeight.bold};
  color: ${(props) => props.theme.palette.text.primary};
  margin-bottom: 0.5rem;

  & > span {
    color: ${(props) => props.theme.palette.secondary.main};
  }

  @media screen and (max-width: 600px) {
    font-size: 1.25rem;
  }

  @media screen and (max-width: 400px) {
    font-size: 1.15rem;
  }
`
