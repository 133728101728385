//////////////////
// ACTION TYPES //
//////////////////

export const loadedProfileActionTypes = {
	SET_LOADED_PROFILES:    			'SET_LOADED_PROFILES',
	ADD_LOADED_PROFILES: 		    	'ADD_LOADED_PROFILES',
	UPDATE_LOADED_PROFILE:     			'UPDATE_LOADED_PROFILE',
	ADD_LOADED_PROFILE_FOLLOWER: 		'ADD_LOADED_PROFILE_FOLLOWER',
	REMOVE_LOADED_PROFILE_FOLLOWER: 	'REMOVE_LOADED_PROFILE_FOLLOWER',
	CLEAR_LOADED_PROFILES: 	  			'CLEAR_LOADED_PROFILES'
}

/////////////
// ACTIONS //
/////////////

// LOADED PROFILES
const setProfiles = profiles => {
  	return { 
    	type: loadedProfileActionTypes.SET_LOADED_PROFILES,
    	profiles
  	}
}

const addProfiles = profiles => {
	return { 
		type: loadedProfileActionTypes.ADD_LOADED_PROFILES, 
		profiles
	}
}

const updateProfile = params => {
  	return { 
    	type: loadedProfileActionTypes.UPDATE_LOADED_PROFILE, 
    	userId: params.userId, 
    	fields: params.fields 
  	}
}

const addFollower = userId => {
  	return { 
    	type: loadedProfileActionTypes.ADD_LOADED_PROFILE_FOLLOWER, 
    	userId
  	}
}

const removeFollower = userId => {
  	return { 
    	type: loadedProfileActionTypes.REMOVE_LOADED_PROFILE_FOLLOWER, 
    	userId
  	}
}

const clearProfiles = () => {
	return { 
		type: loadedProfileActionTypes.CLEAR_LOADED_PROFILES 
	}
}

export const loadedProfilesActions = {
	setProfiles,
	addProfiles,
	updateProfile,
	addFollower,
	removeFollower,
	clearProfiles
}