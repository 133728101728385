import {
  ArticleContainer,
  ArticleHeader,
  RedArticleHeader,
  WhiteBackgroundContainer,
  SponsoredByTitle,
  SponsoredByItem,
  SponsoredByContainer,
  Image,
  SponsoredByCivilTalkContainer,
  CivilTalkImage
} from './styled'
import MasterCardIcon from '../../../../../../../media/icons/mastercard-light.svg'
import LazyLoad from 'react-lazyload'

const CIVILTALK_LOGO = `https://res.cloudinary.com/headliner/image/upload/v1712948051/logos/civiltalk_logo_transparent.png`

const SponsoredBy = () => {
  return (
    <ArticleContainer>
      <RedArticleHeader>Sponsored By</RedArticleHeader>
      <WhiteBackgroundContainer>
        {/* <SponsoredByContainer>
          <SponsoredByItem>
            <LazyLoad>
              <Image src={MasterCardIcon} alt={'Mastercard logo'} width={'7rem'} height={'7rem'} />
            </LazyLoad>
            <SponsoredByTitle>Mastercard</SponsoredByTitle>
          </SponsoredByItem>
        </SponsoredByContainer> */}
        <SponsoredByCivilTalkContainer>
          <SponsoredByItem>
            <LazyLoad>
              <CivilTalkImage
                src={CIVILTALK_LOGO}
                alt={'CivilTalk logo'}
                width={'20rem'}
                height={'7rem'}
              />
            </LazyLoad>
          </SponsoredByItem>
        </SponsoredByCivilTalkContainer>
      </WhiteBackgroundContainer>
    </ArticleContainer>
  )
}

export default SponsoredBy

{
  /* <Image src={CIVILTALK_LOGO} alt={'CivilTalk logo'} width={'20rem'} height={'7rem'} /> */
}
