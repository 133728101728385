//////////////////
// ACTION TYPES //
//////////////////

export const followActionTypes = {
  SET_FOLLOWS: 'SET_FOLLOWS',
  ADD_FOLLOWS: 'ADD_FOLLOWS',
  REMOVE_USER_FOLLOW: 'REMOVE_USER_FOLLOW',
  REMOVE_ORGANIZATION_FOLLOW: 'REMOVE_ORGANIZATION_FOLLOW',
  CLEAR_FOLLOWS: 'CLEAR_FOLLOWS'
}

/////////////
// ACTIONS //
/////////////

// Follows
const set = (follows) => {
  return {
    type: followActionTypes.SET_FOLLOWS,
    follows
  }
}

const add = (follows) => {
  return {
    type: followActionTypes.ADD_FOLLOWS,
    follows
  }
}

const removeUser = (follower, userId) => {
  return {
    type: followActionTypes.REMOVE_USER_FOLLOW,
    follower,
    userId
  }
}

const removeOrg = (follower, organizationId) => {
  return {
    type: followActionTypes.REMOVE_ORGANIZATION_FOLLOW,
    follower,
    organizationId
  }
}

const clear = () => {
  return {
    type: followActionTypes.CLEAR_FOLLOWS
  }
}

export const followsActions = {
  set,
  add,
  removeUser,
  removeOrg,
  clear
}
