//////////////////
// ACTION TYPES //
//////////////////

export const categoryActionTypes = {
  	SET_CATEGORIES:       'SET_CATEGORIES',
  	CLEAR_CATEGORIES:     'CLEAR_CATEGORIES'
}

/////////////
// ACTIONS //
/////////////

// CATEGORIES
const setCategories = categories => {
  	return {
    	type: categoryActionTypes.SET_CATEGORIES,
    	categories
  	}
}

const clearCategories = () => {
  	return {
    	type: categoryActionTypes.CLEAR_CATEGORIES
  	}
}

export const categoriesActions = {
	setCategories,
	clearCategories
}