import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { analytics } from '../../firebase/firebase'
import moment from 'moment'
import { sendFriendInvitation } from '../../redux/thunks/emails/sendFriendInvitation'
import { inviteFriendsActions } from '../../redux/action-creators/invite-friends'
// import InviteAFriendValidation from '../../validations/invite-a-friend'
import getName from '../../utils/functions/getName'
import FormError from '../alerts/form-error'
import UserAvatar from '../partials/user-avatar/user-avatar'
import withStyles from '@mui/styles/withStyles'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import CloseIcon from '@mui/icons-material/Close'
import {
  CloseButton,
  Header,
  Subheader,
  FormContainer,
  PreviewContainer,
  PreviewTextGroup,
  AvatarContainer,
  PreviewSummaryContentContainer,
  ButtonsContainer,
  SuccessContainer
} from './styled/invite-friends-modal-comps'

const styles = (theme) => ({
  paper: {
    padding: '1rem',
    margin: '1rem'
  },
  content: {
    [theme.breakpoints.down('md')]: {
      padding: '1rem 0.5rem 0.5rem 0.5rem'
    }
  },
  button: {
    height: '3rem',
    minHeight: '3rem',
    marginTop: '.5rem',
    backgroundColor: '#6e8c76',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#abcab3',
      color: '#333'
    }
  },
  inviteMore: {
    display: 'block',
    width: '14rem',
    height: '3rem',
    minHeight: '3rem',
    marginLeft: '2rem',
    float: 'left',
    textTransform: 'none',
    [theme.breakpoints.down('md')]: {
      float: 'none',
      marginLeft: 0,
      marginBottom: '0.5rem'
    }
  },
  returnButton: {
    display: 'block',
    width: '14rem',
    height: '3rem',
    minHeight: '3rem',
    marginRight: '2rem',
    float: 'right',
    textTransform: 'none',
    [theme.breakpoints.down('md')]: {
      float: 'none',
      marginRight: 0
    }
  }
})

class InviteModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      fields: {
        fname: '',
        email: ''
      },
      didSubmit: false,
      didSucceed: false,
      error: ''
    }

    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleSendInvite = this.handleSendInvite.bind(this)
    this.handleInviteMore = this.handleInviteMore.bind(this)
  }

  handleInputChange(event) {
    const target = event.target
    const name = target.name

    this.setState({
      ...this.state,
      fields: {
        ...this.state.fields,
        [name]: target.value
      }
    })
  }

  handleClose() {
    this.props.setInviteFriends(false)
  }

  handleInviteMore() {
    this.setState({
      ...this.state,
      fields: {
        fname: '',
        email: ''
      },
      didSubmit: false,
      didSucceed: false,
      error: ''
    })
  }

  handleSendInvite() {
    // const validation = InviteAFriendValidation(this.state.fields)
    // const error = validation.error

    // if (error !== '') {
    //   console.log('[VALIDATION ERROR]: ', error)

    //   this.setState({
    //     ...this.state,
    //     error: error
    //   })
    // } else {
    const { userId, avatar, numFriendsInvited } = this.props.currentProfile
    const profilePicture =
      avatar !== ''
        ? avatar
        : 'https://res.cloudinary.com/headliner/image/upload/v1576268106/emails/assets/avatar-placeholder.png'

    this.props
      .sendFriendInvitation({
        fname: this.state.fields.fname,
        email: this.state.fields.email,
        profilePicture: profilePicture,
        userId: userId,
        numFriendsInvited: numFriendsInvited
      })
      .then((data) => {
        if (!data.error) {
          console.log('[SUCCESS]: ', 'Successfully sent friend invitation!')

          analytics.logEvent('invite-sent')

          this.setState({
            ...this.state,
            fields: {
              fname: '',
              email: ''
            },
            didSubmit: true,
            didSucceed: true,
            error: ''
          })
        } else {
          console.log('[FAIL]: ', 'Failed to send friend invitation.')

          this.setState({
            ...this.state,
            fields: {
              fname: '',
              email: ''
            },
            didSubmit: true,
            didSucceed: false,
            error: 'Something went wrong, please try again later...'
          })
        }
      })
    // }
  }

  render() {
    const { classes, currentUser, currentProfile } = this.props
    const { documentVerified } = currentProfile
    const verified = documentVerified ? 1 : 0
    const { fields, didSubmit, didSucceed, error } = this.state
    const recipientName = fields.fname === '' ? '[First Name]' : fields.fname

    return (
      <Dialog
        open={true}
        hideTitle={true}
        hideButton={true}
        fullWidth
        classes={{ paper: classes.paper }}>
        <DialogContent classes={{ root: classes.content }}>
          <CloseButton onClick={this.handleClose}>
            <CloseIcon />
          </CloseButton>

          {!didSubmit && (
            <>
              <Header>Invite a Friend to CivilTalk</Header>

              <FormError message={error} />

              <FormContainer>
                <input
                  type="text"
                  name="fname"
                  placeholder="First Name"
                  value={fields.fname}
                  onChange={this.handleInputChange}
                />

                <input
                  type="text"
                  name="email"
                  placeholder="Email Address"
                  value={fields.email}
                  onChange={this.handleInputChange}
                />
              </FormContainer>

              <PreviewContainer>
                <PreviewTextGroup>Hi {recipientName}</PreviewTextGroup>

                {/* <PreviewTextGroup>I'm inviting you to join CivilTalk</PreviewTextGroup> */}

                <PreviewTextGroup>
                  Let’s practice our Social Emotional Learning together on CivilTalk. CivilTalk
                  helps build social and emotional intelligence by giving users the tools to Do
                  Good, Be Better and Add Value in their relationships, workplace, and community.
                  It’s free to get started!
                </PreviewTextGroup>

                <PreviewTextGroup>I hope you’ll join me.</PreviewTextGroup>

                <div style={{ marginTop: '1rem' }}>
                  <AvatarContainer>
                    <UserAvatar
                      src={currentProfile.avatar}
                      width={'3.2rem'}
                      height={'3.2rem'}
                      borderWidth={'0.2rem'}
                      verified={verified}
                    />
                  </AvatarContainer>

                  <PreviewSummaryContentContainer style={{ marginTop: '.2rem' }}>
                    <PreviewTextGroup style={{ marginBottom: '.2rem' }}>
                      <b>{getName(currentUser)}</b>
                    </PreviewTextGroup>

                    <PreviewTextGroup>
                      Member Since <b>{moment(currentUser.whenCreated).format('MMM, YYYY')}</b>
                    </PreviewTextGroup>
                  </PreviewSummaryContentContainer>
                </div>
              </PreviewContainer>

              <ButtonsContainer>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  fullWidth
                  onClick={this.handleSendInvite}>
                  Send Invite
                </Button>
              </ButtonsContainer>
            </>
          )}

          {didSubmit && didSucceed && (
            <SuccessContainer>
              <Header>Success!</Header>

              <Subheader>Your invitation has been sent successfully!</Subheader>

              <ButtonsContainer>
                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.inviteMore}
                  fullWidth
                  onClick={this.handleInviteMore}>
                  Invite Someone Else
                </Button>

                <Button
                  variant="contained"
                  color="primary"
                  className={classes.returnButton}
                  fullWidth
                  onClick={this.handleClose}>
                  Return to Homepage
                </Button>
              </ButtonsContainer>
            </SuccessContainer>
          )}

          {didSubmit && !didSucceed && (
            <SuccessContainer>
              <Header>Good News!</Header>

              <Subheader>This email is already registered on CivilTalk!</Subheader>

              <ButtonsContainer>
                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.inviteMore}
                  fullWidth
                  onClick={this.handleInviteMore}>
                  Invite Someone Else
                </Button>

                <Button
                  variant="contained"
                  color="primary"
                  className={classes.returnButton}
                  fullWidth
                  onClick={this.handleClose}>
                  Return to Homepage
                </Button>
              </ButtonsContainer>
            </SuccessContainer>
          )}
        </DialogContent>
      </Dialog>
    )
  }
}

InviteModal.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  currentUser: state.currentUser,
  currentProfile: state.currentProfile
})

const mapDispatchToProps = (dispatch) => ({
  sendFriendInvitation: (params) => {
    return dispatch(sendFriendInvitation(params))
  },
  setInviteFriends: (open) => {
    return dispatch(inviteFriendsActions.set(open))
  }
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(InviteModal))
)
