import styled from 'styled-components'
import config from '../../../../../../config'

export const MainContainer = styled.div`
  margin-top: 0.7rem;
  box-sizing: border-box;
  position: relative;

  & > span {
    font-size: ${(props) => props.theme.typography.fontSizes.larger};
    color: ${(props) =>
      props.variant === 'transparent' ? 'white' : props.theme.typography.fontColor.headerCopy};
    position: absolute;
    top: 50%;
    right: 0.5rem;
    transform: translateY(-50%);
  }
`

export const InputContainer = styled.div`
  display: block;
  margin-top: 0.8rem;
  border: 1px solid #ddd;
  border-radius: 0.3rem;
  box-sizing: border-box;
  position: relative;

  & > span {
    font-size: ${(props) => props.theme.typography.fontSizes.larger};
    color: ${(props) => props.theme.typography.fontColor.headerCopy};
    position: absolute;
    top: 50%;
    right: 0.5rem;
    transform: translateY(-50%);
  }
`

export const Input = styled.input`
  background-color: #fff;
  height: 42px;
  display: block;
  width: 100%;
  padding: 2px 2rem 2px 14px;
  vertical-align: middle;
  border: 1px solid #ddd;
  border-radius: 0.3rem;
  box-sizing: border-box;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.short};
  font-weight: ${(props) =>
    props.variant === 'transparent'
      ? props.theme.typography.fontWeight.light
      : props.theme.typography.fontWeight.regular};
  color: ${(props) =>
    props.variant === 'transparent' ? 'white' : props.theme.typography.fontColor.bodyCopy};
  box-shadow: 0px 7px 5px -2px rgba(130, 130, 130, 0.1);
  transition: background-color 0.3s ease-in-out;

  &:focus,
  &:active {
    outline: none;
  }

  &:focus {
    border: 2px solid rgb(237, 184, 1);
    box-shadow: ${(props) =>
      props.variant !== 'default' ? 'none' : '0px 0px 1px 4px #abcab3'}; // light olive
  }

  &::-webkit-input-placeholder,
  &:-moz-placeholder,
  &::-moz-placeholder,
  &:-ms-input-placeholder,
  &::-ms-input-placeholder,
  &::placeholder {
    color: #888;
    font-weight: ${(props) =>
      props.variant === 'transparent'
        ? props.theme.typography.fontWeight.light
        : props.theme.typography.fontWeight.regular};
  }
`

export const ProfileContainer = styled.div`
  display: block;
  padding: 0.5rem 1rem;
  cursor: pointer;

  @media (max-width: ${config.UI.drawer_breakpoint.max}) {
    padding: 0.8rem 1rem;
  }
`

export const ProfilePicture = styled.div`
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: block;
  float: left;
  width: 2rem;
  height: 2rem;
  margin-right: 1rem;
  border-radius: 50%;
  border: 2.5px solid ${(props) => props.theme.palette.primary.main};

  @media (max-width: ${config.UI.drawer_breakpoint.max}) {
    width: 3.3rem;
    height: 3.3rem;
  }
`

export const ProfileInfoContainer = styled.div`
  display: block;
  margin-top: -0.2rem;
  overflow: hidden;

  @media (max-width: ${config.UI.drawer_breakpoint.max}) {
    margin-top: 0.2rem;
  }
`

export const ProfileName = styled.p`
  display: block;
  text-transform: capitalize;
  line-height: 1.4rem;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.short};
  font-weight: ${(props) => props.theme.typography.fontWeight.bold};
  color: ${(props) => props.theme.typography.fontColor.bodyCopy};

  @media (max-width: ${config.UI.drawer_breakpoint.max}) {
    font-size: ${(props) => props.theme.typography.fontSizes.large};
    font-weight: ${(props) => props.theme.typography.fontWeight.semiBold};
  }
`
export const ProfileLocation = styled.p`
  display: block;
  /* margin-top: 0.1rem; */
  text-transform: capitalize;
  line-height: ${(props) => props.theme.typography.lineHeight};
  font-family: ${(props) => props.theme.typography.fontFamily};
  /* font-size: ${(props) => props.theme.typography.fontSizes.short}; */
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  color: ${(props) => props.theme.palette.text.primary};

  /* @media (max-width: ${config.UI.drawer_breakpoint.max}) {
  } */
  font-size: ${(props) => props.theme.typography.fontSizes.regular};
  margin-top: 0.5rem;
`

export const SelectText = styled.p`
  display: block;
  margin-top: 0.1rem;
  text-transform: capitalize;
  line-height: ${(props) => props.theme.typography.lineHeight};
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.short};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  color: ${(props) => props.theme.typography.fontColor.bodyCopy};

  @media (max-width: ${config.UI.drawer_breakpoint.max}) {
    font-size: ${(props) => props.theme.typography.fontSizes.regular};
    margin-top: 0.5rem;
  }
`

export const Result = styled.div`
  display: block;
  padding: 0.3rem 0.7rem;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.small};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  color: ${(props) => props.theme.typography.fontColor.bodyCopy};
  pointer-events: none;

  & span {
    vertical-align: middle;
  }

  & span.material-icons {
    margin-right: 0.5rem;
    font-size: ${(props) => props.theme.typography.fontSizes.regular};
    font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  }

  & > span:nth-child(2) {
    font-size: ${(props) => props.theme.typography.fontSizes.small};
    font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  }
`
