import TransactionHistory from './components/transaction-history/transaction-history'
import TokenBalance from './components/token-balance/token-balance'
import StripeAccountActions from './components/stripe-account-actions/stripe-account-actions'

function Billing() {
  return (
    <div>
      <TokenBalance />
      <StripeAccountActions />
      <>
        <TransactionHistory />
      </>
    </div>
  )
}

export default Billing
