import config from '../../../config'
import { loadedTakesActions } from '../../action-creators/loaded-takes.js'
import { currentProfileActions } from '../../action-creators/current-profile.js'
import { actionResponseActions } from '../../action-creators/action-response.js'
import { authenticatedFetch } from '../../../utils/functions/authenticatedFetch'

/*
 * METHOD TO SHOW TAKE BY ID
 */
export const showTakeByID = (params) => {
  let responseCode = ''
  const method = 'PUT'
  const url = `${config.baseURL}/api/take/show/${params.takeId}`
  const headers = {
    'Content-Type': 'application/json'
  }

  // Thunk middleware knows how to handle functions.
  // It passes the dispatch method as an argument to the function,
  // thus making it able to dispatch actions itself.
  return (dispatch) => {
    console.log('Sending show take request to CivilTalk')

    // Return the fetch so react components calling 'store.dispatch()' can use 'then()'
    return authenticatedFetch(url, {
      method,
      headers,
      credentials: 'include'
    })
      .then((response) => {
        responseCode = response.status

        return response.json()
      })
      .then((data) => {
        if (responseCode === 200) {
          dispatch(loadedTakesActions.showTake(params.takeId))
          dispatch(
            currentProfileActions.updateProfile({
              numPublicTakes: params.currentProfile.numPublicTakes + 1
            })
          )
          dispatch(
            currentProfileActions.updateProfile({
              numHiddenTakes: params.currentProfile.numHiddenTakes - 1
            })
          )
          dispatch(actionResponseActions.createActionResponse('Your take is no longer hidden!'))

          return {
            error: false,
            code: responseCode,
            message: data.message
          }
        } else {
          console.log(data.message)

          return {
            error: true,
            code: responseCode,
            message: data.message
          }
        }
      })
      .catch((error) => {
        console.log(error)

        return {
          error: true,
          code: '',
          message: error
        }
      })
  }
}
