import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import UpdatePasswordValidation from './validations/update-password'
import { updatePassword } from '../../../../../../../redux/thunks/users/updatePassword'
import FormError from '../../../../../../../components/alerts/form-error'
import PropTypes from 'prop-types'
import withStyles from '@mui/styles/withStyles'
import Button from '@mui/material/Button'
import { ItemContainer, InputLabel, InputsContainer, Input } from './styled/password-form-comps'
import config from '../../../../../../../config'

const styles = (theme) => ({
  actionButton: {
    textTransform: 'capitalize',
    width: '15rem',
    margin: '1rem 0 0 35%',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light
    },
    [`@media (max-width: ${config.UI.drawer_breakpoint.max})`]: {
      width: '65%'
    },
    '@media (max-width: 600px)': {
      width: '100%',
      margin: '1rem 0 0 0'
    }
  }
})

class PasswordForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      fields: {
        password: '111111111',
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
      },
      status: {
        isChangingPassword: false
      },
      error: ''
    }

    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleBeginUpdatePassword = this.handleBeginUpdatePassword.bind(this)
    this.handleUpdatePassword = this.handleUpdatePassword.bind(this)
  }

  handleInputChange(event) {
    const target = event.target
    const name = target.name

    this.setState({
      ...this.state,
      fields: {
        ...this.state.fields,
        [name]: target.value
      }
    })
  }

  handleBeginUpdatePassword() {
    this.setState({
      ...this.state,
      status: {
        ...this.state.status,
        isChangingPassword: true
      }
    })
  }

  handleUpdatePassword(event) {
    event.preventDefault()

    const { currentPassword, newPassword, confirmPassword } = this.state.fields
    const { error } = UpdatePasswordValidation({
      currentPassword,
      newPassword,
      confirmPassword
    })

    if (error !== '') {
      console.log('[VALIDATION ERROR]: ', error)

      this.setState({
        ...this.state,
        error
      })
    } else {
      this.props.updatePassword({ currentPassword, newPassword, confirmPassword }).then((data) => {
        if (!data.error) {
          console.log('[SUCCESS]: ', 'Successfully updated password!')

          this.setState({
            ...this.state,
            fields: {
              ...this.state.fields,
              currentPassword: '',
              newPassword: '',
              confirmPassword: ''
            },
            status: {
              ...this.state.status,
              isChangingPassword: false
            },
            error: ''
          })
        } else {
          console.log('[FAIL]: ', 'Failed to update password.')

          this.setState({
            ...this.state,
            error: data.message
          })
        }
      })
    }
  }

  render() {
    const { classes } = this.props
    const { fields, status, error } = this.state

    return (
      <ItemContainer>
        <FormError message={error} />

        {!status.isChangingPassword && (
          <>
            <InputLabel>Password:</InputLabel>
            <InputsContainer>
              <Input
                type="password"
                name="password"
                placeholder="Password"
                value={fields.password}
                width="15rem"
                disabled
              />
            </InputsContainer>

            <Button
              variant="contained"
              color="primary"
              className={classes.actionButton}
              onClick={this.handleBeginUpdatePassword}>
              Change Password
            </Button>
          </>
        )}

        {status.isChangingPassword && (
          <>
            <InputLabel>Current Password:</InputLabel>
            <InputsContainer>
              <Input
                type="password"
                name="currentPassword"
                placeholder="Confirm your current password"
                value={fields.currentPassword}
                width="15rem"
                onChange={this.handleInputChange}
              />
            </InputsContainer>

            <InputLabel>New Password:</InputLabel>
            <InputsContainer>
              <Input
                type="password"
                name="newPassword"
                placeholder="Enter a new password"
                value={fields.newPassword}
                width="15rem"
                onChange={this.handleInputChange}
              />
            </InputsContainer>

            <InputLabel>Confirm Password:</InputLabel>
            <InputsContainer>
              <Input
                type="password"
                name="confirmPassword"
                placeholder="Confirm your new password"
                value={fields.confirmPassword}
                width="15rem"
                onChange={this.handleInputChange}
              />
            </InputsContainer>

            <Button
              variant="contained"
              color="primary"
              className={classes.actionButton}
              onClick={this.handleUpdatePassword}>
              Submit Password
            </Button>
          </>
        )}
      </ItemContainer>
    )
  }
}

PasswordForm.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  currentUser: state.currentUser
})

const mapDispatchToProps = (dispatch) => ({
  updatePassword: (params) => {
    return dispatch(updatePassword(params))
  }
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PasswordForm))
)
