import { actionResponseActionTypes } from '../action-creators/action-response.js'

// FOR ACTION NOTIFIERS
const actionResponseReducer = (state = '', action) => {
  switch (action.type) {
    case actionResponseActionTypes.CREATE_ACTION_RESPONSE:
      return action.message
    case actionResponseActionTypes.CLEAR_ACTION_RESPONSE:
      return ''
    default:
      return state
  }
}

export default actionResponseReducer
