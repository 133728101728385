import config from '../../../config'
import { authenticatedFetch } from '../../../utils/functions/authenticatedFetch'
import { loaderActions } from '../../action-creators/loader.js'

/*
 * METHOD TO SEND UPDATE PHONE MOBILE VERIFICATION CODE
 */
export const sendUpdateMobileVerificationCode = (params) => {
  let responseCode = ''
  const method = 'POST'
  const url = `${config.baseURL}/api/mobile/phone-update`
  const headers = {
    'Content-Type': 'application/json'
  }
  const requestBody = {
    'phone': params.phone,
    'countryCode': params.countryCode,
    'channelType': params.channelType
  }
  const requestBodyJSON = JSON.stringify(requestBody)

  // Thunk middleware knows how to handle functions.
  // It passes the dispatch method as an argument to the function,
  // thus making it able to dispatch actions itself.
  return (dispatch) => {
    console.log('Sending update phone mobile verification code...')

    dispatch(loaderActions.startLoader())

    // Return the fetch so react components calling 'store.dispatch()' can use 'then()'
    return authenticatedFetch(url, {
      method,
      body: requestBodyJSON,
      headers,
      credentials: 'include'
    })
      .then((response) => {
        responseCode = response.status

        return response.json()
      })
      .then((data) => {
        dispatch(loaderActions.stopLoader())

        if (responseCode === 201) {
          return {
            error: false,
            code: responseCode,
            message: data.message
          }
        } else {
          console.log(data.message)

          return {
            error: true,
            code: responseCode,
            message: data.message
          }
        }
      })
      .catch((error) => {
        console.log(error)

        return {
          error: true,
          code: '',
          message: error
        }
      })
  }
}
