const sendUpdateMobileVerificationCodeValidation = (state, currentUser) => {
    // Phone
    if (!state.phone) {
      return {
        error: 'Phone is required'
      }
    } else if (`+${state.phone}` === currentUser.phone.phoneNumber) {
      return {
        error: 'You are already using this phone number.'
      }
    }
  
    return {
      error: ''
    }
  }
  
  export default sendUpdateMobileVerificationCodeValidation
  