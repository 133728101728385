import React from 'react'
import Button from '@mui/material/Button'
// import theme from '../../../res/theme'
import { styled, useTheme } from '@mui/material/styles'

// **************************** //
// *** STYLED               *** //
// **************************** //
const MuiEmotionButton = styled(Button, {
  shouldForwardProp: (prop) =>
    prop !== 'backgroundColor' &&
    prop !== 'selected' &&
    prop !== 'borderColor' &&
    prop !== 'borderColor' &&
    prop !== 'opacity'
})((props) => ({
  backgroundColor: props.backgroundColor,
  color: props.fontcolor,
  fontSize: '0.875rem', // 14px
  fontWeight: props.fontWeight,
  margin: '0.25rem',
  padding: props.selected ? '0.5rem 1rem' : '0.25rem 0.625rem',
  opacity: props.opacity,
  '&:hover': {
    backgroundColor: props.backgroundColor,
    opacity: 0.8
  }
}))

const MAIN_EMOTIONS = ['Joy', 'Love', 'Surprise', 'Sadness', 'Fear', 'Anger']

const EMO_COLORS = {
  Love: 'rgba(245, 237, 166, 1)',

  Joy: 'rgba(82, 255, 140, 1)',
  Surprise: 'rgba(49, 207, 234, 1)',
  Sadness: 'rgba(157, 169, 189, 1)',
  Anger: 'rgba(191, 67, 67, 1)',
  Fear: 'rgba(210, 145, 113, 1)'
}
const EMO_COLORS_TRANS = {
  Love: 'rgba(245, 237, 166, 0.5)',
  Joy: 'rgba(168, 203, 165, 0.5)',
  Fear: 'rgba(228, 174, 147, 0.5)',
  Anger: 'rgba(195,133, 169, 0.5)',
  Sadness: 'rgba(148, 164,190, 0.5)',
  Surprise: 'rgba(156, 196, 197, 0.5)'
}
const RELATED_EMOTIONS_ONE = {
  Anger: ['Rage', 'Exasperated', 'Irritable', 'Envy', 'Disgust'],
  Sadness: ['Suffering', 'Disappointed', 'Shameful', 'Neglected', 'Despair'],
  Surprise: ['Stunned', 'Confused', 'Amazed', 'Overcome', 'Moved'],
  Joy: [
    'Enthralled',
    'Elation',
    'Enthusiastic',
    'Optimistic',
    'Proud',
    'Cheerful',
    'Happy',
    'Content'
  ],
  Love: ['Peaceful', 'Tenderness', 'Desire', 'Longing', 'Affectionate'],
  Fear: ['Horror', 'Nervous', 'Insecure', 'Terror', 'Scared']
}

const RELATED_EMOTIONS_TWO = {
  Rage: ['Hostile', 'Hate'],
  Exasperated: ['Agitated', 'Frustrated'],
  Irritable: ['Annoyed', 'Aggravated'],
  Envy: ['Resentful', 'Jealous'],
  Disgust: ['Contempt', 'Revolted'],

  Suffering: ['Agony', 'Hurt'],
  Sadness: ['Depressed', 'Sorrow'],
  Disappointed: ['Dismayed', 'Displeased'],
  Shameful: ['Regretful', 'Guilty'],
  Neglected: ['Isolated', 'Lonely'],
  Despair: ['Grief', 'Powerless'],

  Stunned: ['Shocked', 'Dismayed'],
  Confused: ['Disillusioned', 'Perplexed'],
  Amazed: ['Astonished', 'AweStruck'],
  Overcome: ['Speechless', 'Astounded'],
  Moved: ['Stimulated', 'Touched'],

  Enthralled: ['Rapture', 'Enchanted'],
  Elation: ['Jubilation', 'Euphoric'],
  Enthusiastic: ['Zeal', 'Excited'],
  Optimistic: ['Hopeful', 'Eager'],
  Proud: ['Illustrious', 'Triumphant'],
  Cheerful: ['Jovial', 'Blissful'],
  Happy: ['Amused', 'Delighted'],
  Content: ['Pleased', 'Satisfied'],

  Peaceful: ['Satisfied', 'Relieved'],
  Tenderness: ['Compassionate', 'Caring'],
  Desire: ['Infatuation', 'Passion'],
  Longing: ['Attracted', 'Sentimental'],
  Affectionate: ['Fondness', 'Romantic'],

  Horror: ['Dread', 'Mortified'],
  Nervous: ['Anxious', 'Worried'],
  Insecure: ['Inadequate', 'Inferior'],
  Terror: ['Hysterical', 'Panic'],
  Scared: ['Helpless', 'Frightened']
}

export const topEmotionForEmotion = (emotion) => {
  let mainEmo = ''
  if (MAIN_EMOTIONS.includes(emotion)) {
    mainEmo = emotion
  } else {
    let sub = subEmotionForEmotion(emotion)
    MAIN_EMOTIONS.forEach((emo) => {
      if (RELATED_EMOTIONS_ONE[emo].includes(sub)) {
        mainEmo = emo
      }
    })
  }

  return mainEmo
}

export const subEmotionForEmotion = (emotion) => {
  let mainEmo = ''
  Object.keys(RELATED_EMOTIONS_TWO).forEach((emo) => {
    if (emo == emotion) {
      mainEmo = emotion
    }
  })
  if (mainEmo == '') {
    Object.keys(RELATED_EMOTIONS_TWO).forEach((emo) => {
      if (RELATED_EMOTIONS_TWO[emo].includes(emotion)) {
        mainEmo = emo
      }
    })
  }

  return mainEmo
}

export const finalEmotionForEmotion = (emotion) => {
  let mainEmo = ''
  Object.keys(RELATED_EMOTIONS_TWO).forEach((emo) => {
    if (RELATED_EMOTIONS_TWO[emo].includes(emotion)) {
      mainEmo = emotion
    }
  })

  return mainEmo
}

export const colorForEmotion = (emotion) => {
  let mainEmo = topEmotionForEmotion(emotion)

  if (MAIN_EMOTIONS.includes(mainEmo)) {
    return EMO_COLORS[mainEmo]
  } else {
    return '#fff'
  }
}

export default function EmotionPicker({
  topEmotion,
  subEmotion,
  finalEmotion,
  setTopEmotion,
  setSubEmotion,
  setFinalEmotion
}) {
  const theme = useTheme()

  const postEmotion = React.useMemo(() => {
    if (finalEmotion) {
      return finalEmotion
    } else if (subEmotion) {
      return subEmotion
    }

    return topEmotion
  }, [topEmotion, subEmotion, finalEmotion])

  return (
    <div style={{ marginBottom: '0.5rem' }}>
      <div
        style={{
          marginTop: '0.5rem',
          marginBottom: '0.5rem'
        }}>
        {MAIN_EMOTIONS.map((emo) => (
          <MuiEmotionButton
            key={emo}
            variant="contained"
            size="small"
            backgroundColor={theme.palette[emo].light}
            fontcolor={theme.palette.text.primary}
            selected={postEmotion === emo}
            opacity={postEmotion === emo ? 1 : 0.5}
            fontWeight={postEmotion === emo ? 700 : 400}
            onClick={() => {
              if (topEmotion == emo) {
                setTopEmotion('')
                setSubEmotion('')
                setFinalEmotion('')
              } else {
                setTopEmotion(emo)
                setSubEmotion('')
                setFinalEmotion('')
              }
            }}>
            {emo}
          </MuiEmotionButton>
        ))}
      </div>

      {topEmotion ? (
        <div
          style={{
            marginTop: '0.5rem',
            marginBottom: '0.5rem'
            // display: 'flex',
            // justifyContent: 'flex-start'
          }}>
          {RELATED_EMOTIONS_ONE[topEmotion].map((emo) => (
            <MuiEmotionButton
              key={emo}
              variant="contained"
              size="small"
              backgroundColor={theme.palette[topEmotion].light}
              fontcolor={theme.palette.text.primary}
              selected={postEmotion === emo}
              opacity={postEmotion === emo ? 1 : 0.5}
              fontWeight={postEmotion === emo ? 700 : 400}
              onClick={() => {
                if (subEmotion == emo) {
                  setSubEmotion('')
                  setFinalEmotion('')
                } else {
                  setSubEmotion(emo)
                  setFinalEmotion('')
                }
              }}>
              {emo}
            </MuiEmotionButton>
          ))}
        </div>
      ) : null}

      {subEmotion ? (
        <div
          style={{
            marginTop: '0.5rem',
            marginBottom: '0.5rem'
          }}>
          {RELATED_EMOTIONS_TWO[subEmotion].map((emo) => (
            <MuiEmotionButton
              key={emo}
              variant="contained"
              size="small"
              backgroundColor={theme.palette[topEmotion].light}
              fontcolor={theme.palette.text.primary}
              selected={postEmotion === emo}
              opacity={postEmotion === emo ? 1 : 0.5}
              fontWeight={postEmotion === emo ? 700 : 400}
              onClick={() => {
                if (finalEmotion == emo) {
                  setFinalEmotion('')
                } else {
                  setFinalEmotion(emo)
                }
              }}>
              {emo}
            </MuiEmotionButton>
          ))}
        </div>
      ) : null}
    </div>
  )
}

// **************************** //
// *** END OF COMPONENT CODE *** //
// **************************** //
