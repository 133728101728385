import { searchResultActionTypes } from '../action-creators/search-results.js'
import _ from 'lodash'

const copyProfiles = (profiles) => {
  return profiles.map((profile) => {
    return {
      ...profile,
      rating: { ...profile.rating }
    }
  })
}

const copyProfile = (profile) => {
  return {
    ...profile,
    rating: { ...profile.rating }
  }
}

const copyTags = (tags) => {
  return tags.map((tag) => ({ ...tag }))
}

const copyTag = (tag) => ({ ...tag })

const copyOrganizations = (organizations) => {
  return organizations.map((organization) => copyOrganization(organization))
}

const copyOrganization = (organization) => ({
  ...organization,
  phone: { ...organization.phone },
  address: { ...organization.address },
  tagsIds: [...organization.tagIds]
})

// FOR SEARCH RESULTS
const searchResultsReducer = (state = { profiles: [], tags: [], organizations: [] }, action) => {
  switch (action.type) {
    case searchResultActionTypes.SET_PROFILE_SEARCH_RESULTS:
      return {
        ...state,
        profiles: copyProfiles(action.profiles),
        tags: copyTags(state.tags),
        organizations: copyOrganizations(state.organizations)
      }

    case searchResultActionTypes.SET_TAG_SEARCH_RESULTS:
      return {
        ...state,
        profiles: copyProfiles(state.profiles),
        tags: copyTags(action.tags),
        organizations: copyOrganizations(state.organizations)
      }

    case searchResultActionTypes.SET_ORGANIZATION_SEARCH_RESULTS:
      return {
        ...state,
        profiles: copyProfiles(state.profiles),
        tags: copyTags(state.tags),
        organizations: copyOrganizations(action.organizations)
      }

    case searchResultActionTypes.ADD_PROFILE_SEARCH_RESULTS:
      var newProfiles = copyProfiles(state.profiles)

      for (var i = 0; i < action.profiles.length; i++) {
        let profileExists = _.some(newProfiles, {
          '_id': action.profiles[i]._id
        })

        if (!profileExists) {
          newProfiles.push(copyProfile(action.profiles[i]))
        }
      }

      return newProfiles

    case searchResultActionTypes.ADD_TAG_SEARCH_RESULTS:
      let newTags = copyTags(state.tags)

      for (var j = 0; j < action.tags.length; j++) {
        let tagExists = _.some(newTags, {
          '_id': action.tags[j]._id
        })

        if (!tagExists) {
          newTags.push(copyTag(action.tags[j]))
        }
      }

      return newTags

    case searchResultActionTypes.ADD_ORGANIZATION_SEARCH_RESULTS:
      let newOrganizations = copyOrganizations(state.organizations)

      for (var k = 0; k < action.organizations.length; k++) {
        let orgExists = _.some(newOrganizations, {
          '_id': action.organizations[k]._id
        })

        if (!orgExists) {
          newOrganizations.push(copyOrganization(action.organizations[k]))
        }
      }

      return newOrganizations

    case searchResultActionTypes.CLEAR_SEARCH_RESULTS:
      return {
        profiles: [],
        tags: [],
        organizations: []
      }

    default:
      return state
  }
}

export default searchResultsReducer
