import { useState, useEffect, useContext, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import debounce from 'lodash/debounce'
import { getProfilesByUserIDs } from '../../../../../../../../../../../redux/thunks/profiles/getProfilesByUserIDs.js'
import { createOrganizationAssignedRole } from '../../../../../../../../../../../redux/thunks/assigned-roles/createOrganizationAssignedRole.js'
import FormError from '../../../../../../../../../../alerts/form-error'
import getName from '../../../../../../../../../../../utils/functions/getName.js'
import UserAvatar from '../../../../../../../../../../partials/user-avatar/user-avatar'
import SearchUser from '../../../../../../../../../../partials/search-user/search-user'
import Modal from '@mui/material/Modal'
import Backdrop from '@mui/material/Backdrop'
import Fade from '@mui/material/Fade'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import {
  MainContainer,
  Heading,
  Subheading,
  InputContainer,
  Input,
  MemberContainer,
  AvatarContainer,
  ProfileName,
  ActionContainer,
  ProfileNotFound,
  ButtonsContainer
} from './styled/add-member-modal-comps.js'
import LoadOrganizationRolesContext from '../../../../../../../../../../data-loading/load-organization-assigned-roles/context/context.js'
import theme from '../../../../../../../../../../../res/theme.js'

const styles = {
  modal: {
    width: '100%',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: '0',
    boxShadow: 24,
    p: 4
  },
  tabs: {
    marginTop: '1rem'
  },
  tab: {
    color: theme.typography.fontColor.bodyCopy,
    borderBottom: '2px solid #eee'
  },
  tabSelected: {
    color: theme.typography.fontColor.bodyCopy,
    fontWeight: 'bold'
  },
  cancel: {
    display: 'inline-block',
    width: '12rem',
    marginRight: '1rem',
    verticalAlign: 'middle',
    boxSizing: 'border-box',
    '@media (max-width: 500px)': {
      width: '100%',
      marginRight: '0'
    }
  },
  submit: {
    display: 'inline-block',
    width: '12rem',
    verticalAlign: 'middle',
    boxSizing: 'border-box',
    '@media (max-width: 500px)': {
      width: '100%',
      marginTop: '.5rem'
    }
  },
  circularProgress: {
    display: 'block',
    width: '17px !important',
    height: '17px !important',
    margin: '0 auto 0 auto',
    position: 'absolute',
    top: '25%',
    left: '25%',
    transform: 'translate(-50%, -50%)',
    '& svg': {
      color: theme.palette.secondary.main
    }
  }
}

const AddMemberModal = ({ setOpen, selectedRole }) => {
  const { orgId } = useParams()
  const { assignedRoles, setAssignedRoles } = useContext(LoadOrganizationRolesContext)
  const [tab, setTab] = useState('search')
  const [userId, setUserId] = useState('')
  const [error, setError] = useState('')
  const [processing, setProcessing] = useState(false)
  const loadedProfiles = useSelector(({ loadedProfiles }) => loadedProfiles)
  const dispatch = useDispatch()
  const profile = loadedProfiles[userId]

  const handleTabChange = (e, newTab) => {
    setUserId('')
    setError('')
    setTab(newTab)
  }

  const handleClose = () => {
    setTab('search')
    setUserId('')
    setError('')
    setOpen(false)
  }

  const handleSubmit = () => {
    setProcessing(true)

    dispatch(
      createOrganizationAssignedRole({
        organizationId: orgId,
        assignedRole: {
          userId,
          roleId: selectedRole
        }
      })
    )
      .then((data) => {
        if (data.error) {
          console.log('[FAIL]: ', 'Error creating organization assigned role')

          setError(data.message)
        } else {
          console.log('[SUCCESS]: ', 'Successfully created organization assigned role')

          const newAssignedRoles = [...assignedRoles, data.assignedRole]

          setAssignedRoles(newAssignedRoles)
        }

        setOpen(false)
      })
      .catch((e) => {
        console.log('[ERROR]: ', e)

        setProcessing(false)
        setError(e.message)
      })
  }

  const handleSearch = useRef(
    debounce((userIds) => {
      setProcessing(true)

      dispatch(getProfilesByUserIDs({ userIds }))
        .then((data) => {
          if (!data.error) {
            console.log('[SUCCESS]: ', 'Successfully retrieved profiles by user IDs!')
          } else {
            console.log('[FAIL]: ', 'Failed to retrieve profiles by user IDs.')

            // setError(data.message)
          }
          setProcessing(false)
        })
        .catch((e) => {
          console.log('[ERROR]: ', e.message)
          setProcessing(false)
        })
    }, 300)
  ).current

  useEffect(() => {
    if (userId.trim() && userId.trim().length > 1) {
      handleSearch([userId.trim()])
    }
  }, [handleSearch, userId])

  return (
    <Modal
      aria-labelledby="transition-modal-add-member"
      aria-describedby="transition-modal-add-member-desc"
      open={true}
      onClose={() => setOpen(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}>
      <Fade in={true}>
        <MainContainer>
          <Heading>Add a Member</Heading>
          <Subheading>Find a user using one of the methods below.</Subheading>

          <FormError message={error} style={{ margin: '1rem 0 0 0' }} />

          <Tabs
            variant="fullWidth"
            value={tab}
            onChange={handleTabChange}
            textColor="secondary"
            indicatorColor="secondary"
            aria-label="secondary tabs example"
            sx={styles.tabs}>
            <Tab
              value="search"
              label="Search"
              style={tab === 'search' ? styles.selected : styles.tab}
            />
            <Tab
              value="user-id"
              label="User ID"
              style={tab === 'user-id' ? styles.selected : styles.tab}
            />
          </Tabs>

          {tab === 'search' && (
            <InputContainer>
              <SearchUser setUserId={setUserId} setError={setError} />
            </InputContainer>
          )}

          {tab === 'user-id' && (
            <InputContainer>
              <Input
                type="text"
                name="userId"
                placeholder="Enter a user ID..."
                value={userId}
                onChange={(e) => setUserId(e.target.value)}
              />
            </InputContainer>
          )}

          {profile ? (
            <MemberContainer>
              <AvatarContainer>
                <UserAvatar
                  src={profile.avatar}
                  width={'2rem'}
                  height={'2rem'}
                  borderWidth={'0.2rem'}
                  verified={profile.documentVerified ? 1 : 0}
                />
              </AvatarContainer>

              <ProfileName>{getName(profile)}</ProfileName>

              <ActionContainer onClick={() => setUserId('')}>
                {processing ? (
                  <CircularProgress color="secondary" sx={styles.circularProgress} />
                ) : (
                  <span className="material-icons">close</span>
                )}
              </ActionContainer>
            </MemberContainer>
          ) : (
            <ProfileNotFound>
              {userId && !processing
                ? "We we're unable to find a user with the given user ID."
                : 'Please select a user.'}
            </ProfileNotFound>
          )}

          <ButtonsContainer>
            <Button variant="contained" color="secondary" sx={styles.cancel} onClick={handleClose}>
              Cancel
            </Button>

            <Button
              variant="contained"
              color="primary"
              sx={styles.submit}
              disabled={processing || !profile}
              onClick={handleSubmit}>
              {processing ? (
                <CircularProgress color="secondary" sx={styles.circularProgress} />
              ) : (
                'Add Member'
              )}
            </Button>
          </ButtonsContainer>
        </MainContainer>
      </Fade>
    </Modal>
  )
}

export default AddMemberModal
