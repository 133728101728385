//////////////////
// ACTION TYPES //
//////////////////

export const myCoursesActionTypes = {
  SET_MY_COURSES: 'SET_MY_COURSES',
  UPDATE_MY_COURSES: 'UPDATE_MY_COURSES',
  CLEAR_MY_COURSES: 'CLEAR_MY_COURSES'
}

/////////////
// ACTIONS //
/////////////
// Adds all courses for a user to the store
// Result of a fetch request to get all courses for a user
const setCourses = (courses) => {
  return {
    type: myCoursesActionTypes.SET_MY_COURSES,
    courses
  }
}

// Handles changes to a course for a user
// const updateCourses = courses => {
//   return {
//   		type: myCoursesActionTypes.UPDATE_MY_COURSES,
//   		courses
//   }
// }

// Clears all courses for a user from the store
// Primary use case is logging out a user
const clearCourses = () => {
  return {
    type: myCoursesActionTypes.CLEAR_MY_COURSES
  }
}

export const myCoursesActions = {
  setCourses,
  // updateCourses,
  clearCourses
}
