import { loadedTopicActionTypes } from '../action-creators/loaded-topics.js'
import _ from 'lodash'

const copyTopics = topics => {
	return topics.map(topic => {
		return {
			...topic,
			categoryIds: [...topic.categoryIds]
		}
	})
}

const copyTopic = topic => {
	return {
		...topic,
		categoryIds: [...topic.categoryIds]
	}
}

// FOR LOADED TOPICS
const loadedTopicsReducer = (state = [], action) => {
	switch (action.type) {
		case loadedTopicActionTypes.SET_LOADED_TOPICS:
			return copyTopics(action.topics)
		case loadedTopicActionTypes.ADD_LOADED_TOPICS:
			var newTopics1 = copyTopics(state)

			for (var i = 0; i < action.topics.length; i++) {
				let topicIndex1 = _.findIndex(newTopics1, {"_id": action.topics[i]._id})

				if (topicIndex1 === -1) {
					newTopics1.push(copyTopic(action.topics[i]))
				} else {
					newTopics1[topicIndex1] = copyTopic(action.topics[i])
				}
			}
			
			return newTopics1
		case loadedTopicActionTypes.CLEAR_LOADED_TOPICS:
			return []
		default:
			return state
	}
}

export default loadedTopicsReducer