import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { isLoaded, isEmpty } from 'react-redux-firebase'
import { tokensTransactionModalActions } from '../../../../../redux/action-creators/tokens-transaction-modal'
import TokenIcon from '../../../../../media/icons/svg/token-black.svg'
import Listener from './listeners/user-meta'
import BeginVerificationButton from './components/begin-verification-button'
import { Button, Tooltip } from '@mui/material'

const styles = {
  button: {
    width: '15rem',
    height: '3.5rem',
    borderRadius: '.3rem',
    minWidth: '11rem',
    // width: 'auto',
    '& img': {
      height: '1.4rem',
      marginTop: '.1rem',
      marginRight: '.7rem'
    },
    '@media (max-width: 650px)': {
      display: 'block',
      width: '16rem',
      height: '3rem',
      margin: '1rem auto 0 auto',
      fontSize: '0.875rem' // 14px
    },
    '@media (max-width: 400px)': {
      width: '100%'
    }
  }
}

function ButtonContainer(props) {
  const { processing, beginVerification, documentVerified } = props
  const authRef = useSelector(({ firebase }) => firebase.auth)
  const userMetaTokensRef = useSelector(
    ({
      firestore: {
        data: { userMeta }
      }
    }) => {
      return userMeta && userMeta[authRef.uid] && userMeta[authRef.uid].tokens
    }
  ) || { balance: 0 }
  const dispatch = useDispatch()

  const handleOpen = () => {
    dispatch(tokensTransactionModalActions.set(true, { initialState: 'purchase' }))
  }

  return (
    <>
      {isLoaded(authRef) && !isEmpty(authRef) && <Listener auth={authRef} />}

      {userMetaTokensRef.balance === 0 ? (
        <Tooltip title="Buy Tokens">
          <Button color="primary" variant="contained" sx={styles.button} onClick={handleOpen}>
            <img src={TokenIcon} alt="Purchase Tokens Icon" />
            <span>
              {userMetaTokensRef.balance === 0 ? 'Buy' : userMetaTokensRef.balance} Tokens
            </span>
          </Button>
        </Tooltip>
      ) : (
        <BeginVerificationButton
          processing={processing}
          beginVerification={beginVerification}
          documentVerified={documentVerified}
        />
      )}
    </>
  )
}

export default ButtonContainer
