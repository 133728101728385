import React, { useState, useEffect, useRef } from 'react'
import { history } from '../../../history/history'
import { TagCardsContainer, TagCard } from './styled/emotional-intelligence-essentials-comps'
import { getResponsiveStyles } from './responsive-styles/responsive-styles'
import Button from '@mui/material/Button'
import useMediaQuery from '@mui/material/useMediaQuery'

/**
 * This component is based on the Popular Tags Component
 */

function EmotionalIntelligenceEssentials(props) {
  const mainRef = useRef(null)
  const [didRecognizeRef, setDidRecognizeRef] = useState(false)
  const [componentWidth, setComponentWidth] = useState(null)
  const isMobile = useMediaQuery('(max-width:400px)')
  const [tags, setTags] = useState([
    {
      tagId: 'civility',
      numPosts: 1
    },
    {
      tagId: 'anonymity',
      numPosts: 1
    },
    {
      tagId: 'authenticity',
      numPosts: 1
    },
    {
      tagId: 'gaslighting',
      numPosts: 1
    },
    {
      tagId: 'trolling',
      numPosts: 1
    },
    {
      tagId: 'boundaries',
      numPosts: 1
    },
    {
      tagId: 'cognitive-empathy',
      numPosts: 1
    },
    {
      tagId: 'belonging',
      numPosts: 1
    },
    {
      tagId: 'self-reflection',
      numPosts: 1
    },
    {
      tagId: 'conformity',
      numPosts: 1
    },
    {
      tagId: 'individuality',
      numPosts: 1
    },
    {
      tagId: 'morals',
      numPosts: 1
    },
    {
      tagId: 'ethics',
      numPosts: 1
    },
    {
      tagId: 'emotional-intelligence',
      numPosts: 1
    },
    {
      tagId: 'self-awareness',
      numPosts: 1
    },
    {
      tagId: 'positivity',
      numPosts: 1
    },
    {
      tagId: 'resilience',
      numPosts: 1
    },
    {
      tagId: 'empathy',
      numPosts: 1
    },
    {
      tagId: 'leadership',
      numPosts: 1
    },
    {
      tagId: 'teamwork',
      numPosts: 1
    },
    {
      tagId: 'mentoring',
      numPosts: 1
    },
    {
      tagId: 'self-esteem',
      numPosts: 1
    }
  ])

  const [responsiveStyles, setResponsiveStyles] = useState(getResponsiveStyles(componentWidth))

  const handleSetComponentWidth = () => {
    const width = mainRef.current ? mainRef.current.clientWidth : window.innerWidth

    setComponentWidth(width)
  }

  useEffect(() => {
    window.addEventListener('resize', handleSetComponentWidth)

    return () => {
      window.removeEventListener('resize', handleSetComponentWidth)
    }
  }, [])

  useEffect(() => {
    if (!didRecognizeRef) {
      if (mainRef.current !== null && typeof mainRef.current !== 'undefined') {
        setComponentWidth(mainRef.current.clientWidth)
        setDidRecognizeRef(true)
      }
    }
  }, [mainRef, didRecognizeRef])

  useEffect(() => {
    setResponsiveStyles(getResponsiveStyles(componentWidth))
  }, [componentWidth])

  const handleGoToTag = (tag) => {
    history.push(`/topic/${tag}`)
  }

  const activateLinks = true

  return (
    <TagCardsContainer>
      {tags.map((tag) => (
        <Button
          variant="contained"
          size={isMobile ? 'small' : 'medium'}
          color={'secondary'}
          sx={{
            margin: '0.25rem',
            bgcolor: 'secondary.light',
            color: 'text.primary',
            boxShadow: 3,
            '@media (max-width: 400px)': {
              fontSize: '0.9375rem',
              margin: '0.25rem',
              height: '2.25rem'
            }
          }}
          key={tag.tagId}
          onClick={activateLinks ? () => handleGoToTag(tag.tagId) : () => {}}>
          {tag.tagId}
        </Button>
      ))}
    </TagCardsContainer>
  )
}

export default EmotionalIntelligenceEssentials
