const isVerifiedUser = (profile) => {
  if (!profile) return true

  const { documentVerified } = profile

  if (documentVerified) return true

  return false
}

export default isVerifiedUser
