import config from '../../../config'
import { currentProfileActions } from '../../action-creators/current-profile.js'
import { actionResponseActions } from '../../action-creators/action-response.js'
import { loaderActions } from '../../action-creators/loader.js'
import { authenticatedFetch } from '../../../utils/functions/authenticatedFetch'

/*
 * METHOD TO SEND FRIEND INVITATION
 */
export const sendFriendInvitation = (params) => {
  let responseCode = ''
  const method = 'POST'
  const url = `${config.baseURL}/api/email/invite-friend`
  const headers = {
    'Content-Type': 'application/json'
  }
  const requestBody = {
    'fname': params.fname,
    'email': params.email,
    'profilePicture': params.profilePicture
  }
  const requestBodyJSON = JSON.stringify(requestBody)

  // Thunk middleware knows how to handle functions.
  // It passes the dispatch method as an argument to the function,
  // thus making it able to dispatch actions itself.
  return (dispatch) => {
    console.log('Sending friend invitation email...')

    dispatch(loaderActions.startLoader())

    // Return the fetch so react components calling 'store.dispatch()' can use 'then()'
    return authenticatedFetch(url, {
      method,
      body: requestBodyJSON,
      headers,
      credentials: 'include'
    })
      .then((response) => {
        dispatch(loaderActions.stopLoader())
        responseCode = response.status

        return response.json()
      })
      .then((data) => {
        if (responseCode === 201) {
          dispatch(currentProfileActions.addNumFriendsInvited())
          dispatch(actionResponseActions.createActionResponse(`You just earned 20 points!`))

          return {
            error: false,
            code: responseCode,
            message: data.message
          }
        }

        return {
          error: true,
          code: responseCode,
          message: data.message
        }
      })
      .catch((error) => {
        console.log(error)

        return {
          error: true,
          code: '',
          message: error
        }
      })
  }
}
