import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { reduxFirestore, getFirestore } from 'redux-firestore'
import { getFirebase } from 'react-redux-firebase'
import { firebase, firebaseConfig } from '../firebase/firebase.js'
import { createLogger } from 'redux-logger'
import { composeWithDevTools } from 'redux-devtools-extension'
import reducer from './reducer.js'
import { jwtRefresh } from './middleware/jwt-refresh.js'

// MIDDLEWARE

const loggerMiddleware = createLogger({
  collapsed: (getState, action, logEntry) => !logEntry.error
})

const composeEnhancers = composeWithDevTools({ trace: true, traceLimit: 25 })

// Store for Headliner Application
export const store = createStore(
  reducer,
  composeEnhancers(
    applyMiddleware(
      jwtRefresh,
      thunk.withExtraArgument({ getFirebase, getFirestore })
      // loggerMiddleware // neat middleware that logs actions
    ),
    reduxFirestore(firebase, firebaseConfig)
  )
)
