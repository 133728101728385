//////////////////
// ACTION TYPES //
//////////////////

export const notificationOrganizationActionTypes = {
  SET_NOTIFICATION_ORGANIZATIONS: 'SET_NOTIFICATION_ORGANIZATIONS',
  ADD_NOTIFICATION_ORGANIZATIONS: 'ADD_NOTIFICATION_ORGANIZATIONS',
  CLEAR_NOTIFICATION_ORGANIZATIONS: 'CLEAR_NOTIFICATION_ORGANIZATIONS'
}

/////////////
// ACTIONS //
/////////////

// NOTIFCATION ORGANIZATIONS
const set = (organizations) => {
  return {
    type: notificationOrganizationActionTypes.SET_NOTIFICATION_ORGANIZATIONS,
    organizations
  }
}

const add = (organizations) => {
  return {
    type: notificationOrganizationActionTypes.ADD_NOTIFICATION_ORGANIZATIONS,
    organizations
  }
}

const clear = () => {
  return {
    type: notificationOrganizationActionTypes.CLEAR_NOTIFICATION_ORGANIZATIONS
  }
}

export const notificationOrganizationsActions = {
  set,
  add,
  clear
}
