//////////////////
// ACTION TYPES //
//////////////////

export const isAuthenticatedActionTypes = {
	SET_AUTHENTICATION: 	'SET_AUTHENTICATION'
}

/////////////
// ACTIONS //
/////////////

// AUTHENTICATION
const setAuthentication = isAuthenticated => {
	return { 
		type: isAuthenticatedActionTypes.SET_AUTHENTICATION, 
		isAuthenticated 
	}
}

export const isAuthenticatedActions = {
	setAuthentication
}