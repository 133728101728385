import theme from '../../../../../../res/theme'

export const getResponsiveStyles = (width) => {
  const styles = {
    mainContainer: {
      padding: '1rem'
    },
    backgroundIcon: {
      display: 'block'
    },
    singleRow: {
      display: 'block'
    },
    dualRow: {
      display: 'none'
    },
    menuContainer: {
      height: '5.5rem',
      marginLeft: '2%'
    },
    menuItemContainer: {
      backgroundColor: {
        active: theme.palette.secondary.light,
        inactive: theme.palette.secondary.light
      },
      height: {
        active: '5.5rem',
        inactive: '4.5rem'
      },
      singleRow: {
        width: '23.5%'
      },
      dualRow: {
        width: '30%'
      }
    },
    menuItemIcon: {
      // height: '2rem' // save in case we revert to the old icons
      height: '3rem'
    }
  }

  // if (width) {
  //   if (width <= 600) {
  //     // styles.mainContainer.padding = '1.5rem'
  //     styles.backgroundIcon.display = 'none'
  //     styles.menuContainer.height = '4.5rem'
  //     styles.menuItemContainer.height.active = '4.5rem'
  //     styles.menuItemContainer.height.inactive = '3.5rem'
  //     styles.singleRow.display = 'none'
  //     styles.dualRow.display = 'block'
  //     styles.menuContainer.marginLeft = '4%'
  //   }

  //   if (width <= 400) {
  //     styles.menuItemIcon.height = '1.5rem'
  //   }
  // }

  return styles
}
