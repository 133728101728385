import React from 'react'
import { useRef } from 'react'
import LazyLoad from 'react-lazyload'
/** Redux */
import { useSelector } from 'react-redux'
/** Utils */
import getAvatar from '../../../../utils/functions/getAvatar'
import makeStyles from '@mui/styles/makeStyles'
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser'
/** Styled Components */
import {
  VerificationStatusAvatarContainer,
  VerificationStatusImageContainer,
  Image,
  Placeholder,
  VerificationStatusContainer,
  VerificationCurrentStatus
} from '../styled/verify-comps'

const useStyles = makeStyles((theme) => ({
  verifiedIcon: {
    color: theme.palette.text.primary,
    fill: theme.palette.primary.main,
    marginRight: '0.25rem'
  }
}))

function VerificationStatusAvatar() {
  /** MUI */
  const classes = useStyles()
  /** Lazyload */
  const refPlaceholder = useRef()
  const removePlaceholder = () => {
    refPlaceholder.current.remove()
  }
  /** Verification Status */
  const { avatar, documentVerified } = useSelector((state) => state.currentProfile)
  const verificationStatus = documentVerified === true ? 1 : 0

  return (
    <VerificationStatusAvatarContainer>
      <VerificationStatusImageContainer>
        <Placeholder ref={refPlaceholder} />
        <LazyLoad>
          <Image
            onLoad={removePlaceholder}
            onError={removePlaceholder}
            src={getAvatar(avatar)}
            alt={'profile image'}
            verified={verificationStatus}
          />
        </LazyLoad>
      </VerificationStatusImageContainer>
      <VerificationStatusContainer>
        {documentVerified && <VerifiedUserIcon color="primary" className={classes.verifiedIcon} />}
        <VerificationCurrentStatus verificationStatus={verificationStatus}>
          {documentVerified ? 'Verified' : 'Not Verified'}
        </VerificationCurrentStatus>
      </VerificationStatusContainer>
    </VerificationStatusAvatarContainer>
  )
}

export default VerificationStatusAvatar
