import Chain from '../../../../utils/classes/chain.js'

const load = (days, limit, setIsLoading, setTagsExist, actions) => {
  const _getPopularTags = () => {
    return actions.getPopularTags({ days, limit })
  }

  const chain = new Chain()
  let tagsExist = true

  return _getPopularTags()
    .then((data) => {
      if (data.error) {
        chain.break('Failed to get popular tags.')

        return Promise.resolve()
      }

      tagsExist = data.tags.length > 0

      chain.break('Successfully retrieved popular tags.')

      return Promise.resolve()
    })
    .then((last_data) => {
      let { broken, log } = chain

      if (broken) {
        console.log('[DEBUG]: ', log)

        setTagsExist(tagsExist)
        setIsLoading(false)

        return Promise.resolve()
      }

      throw new Error('Unhandled promise chain logic.')
    })
    .catch((error) => {
      console.log('[ERROR]: ', error)
    })
}

export default load
