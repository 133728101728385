import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { history } from '../../../../../history/history'
import getOrganizationImage from '../../../../../utils/functions/getOrganizationImage'
// import getStylesFromTheme from '../../functions/get-styles-from-theme'
import followOrganization from './functions/follow-organization'
import unfollowOrganization from './functions/unfollow-organization'
import { CircularProgress } from '@mui/material'
import {
  OuterContainer,
  InnerContainer,
  IconContainer,
  IconSpan,
  Image,
  Name
} from './styled/organization-comps'

const Organization = (props) => {
  const { organization, isFollowing, displayFollowActions } = props
  // const style = getStylesFromTheme(theme)
  const [processing, setProcessing] = useState(false)
  const currentUser = useSelector(({ currentUser }) => currentUser)
  const dispatch = useDispatch()
  const { _id, image, publicName } = organization

  const handleFollowOrganization = (e, organization) => {
    e.stopPropagation()

    followOrganization(organization, setProcessing, dispatch)
  }

  const handleUnfollowOrganization = (e, organization) => {
    e.stopPropagation()

    unfollowOrganization(organization, currentUser, setProcessing, dispatch)
  }

  return (
    <OuterContainer key={_id}>
      <InnerContainer>
        <Image
          verified={true}
          style={{ backgroundImage: `url(${getOrganizationImage(image)})` }}
          onClick={() => history.push(`/organization/${_id}`)}>
          {!isFollowing && displayFollowActions && (
            <IconContainer onClick={(e) => handleFollowOrganization(e, organization)}>
              {processing ? (
                <CircularProgress
                  size={'1rem'}
                  sx={{
                    display: 'block',
                    color: 'common.white',
                    position: 'absolute',
                    top: '25%',
                    left: '25%'
                  }}
                />
              ) : (
                <IconSpan className="material-icons">person_add</IconSpan>
              )}
            </IconContainer>
          )}

          {isFollowing && displayFollowActions && (
            <IconContainer onClick={(e) => handleUnfollowOrganization(e, organization)}>
              {processing ? (
                <CircularProgress
                  size={'1rem'}
                  sx={{
                    display: 'block',
                    color: 'common.white',
                    position: 'absolute',
                    top: '25%',
                    left: '25%'
                  }}
                />
              ) : (
                <IconSpan className="material-icons">person_remove</IconSpan>
              )}
            </IconContainer>
          )}
        </Image>

        <br />

        <Name onClick={() => history.push(`/organization/${_id}`)}>{publicName}</Name>
      </InnerContainer>
    </OuterContainer>
  )
}

export default Organization
