import config from '../../../config'
import { authenticatedFetch } from '../../../utils/functions/authenticatedFetch'
import { loadedTakesActions } from '../../action-creators/loaded-takes.js'
// import { loaderActions } from '../../action-creators/loader.js'

/*
 * METHOD TO REMOVE VOTE BY ID
 */
export const removeVoteByID = (params) => {
  let responseCode = ''
  const method = 'DELETE'
  const url = `${config.baseURL}/api/vote/?voteId=${params.voteId}`
  const headers = {
    'Content-Type': 'application/json'
  }

  // Thunk middleware knows how to handle functions.
  // It passes the dispatch method as an argument to the function,
  // thus making it able to dispatch actions itself.
  return (dispatch) => {
    console.log('Sending remove vote request to CivilTalk')

    // dispatch(loaderActions.startLoader())

    // Return the fetch so react components calling 'store.dispatch()' can use 'then()'
    return authenticatedFetch(url, {
      method,
      headers,
      credentials: 'include'
    })
      .then((response) => {
        // dispatch(loaderActions.stopLoader())
        responseCode = response.status

        return response.json()
      })
      .then((data) => {
        if (responseCode === 200) {
          dispatch(loadedTakesActions.removeVote(params.voteId))

          return {
            error: false,
            code: responseCode,
            message: data.message
          }
        } else {
          console.log(data.message)

          return {
            error: true,
            code: responseCode,
            message: data.message
          }
        }
      })
      .catch((error) => {
        console.log(error)

        // dispatch(loaderActions.stopLoader())

        return {
          error: true,
          code: '',
          message: error
        }
      })
  }
}
