const onlyDigitsRegExp = new RegExp(/^[0-9]+$/)

const tokensValidation = (tokens) => {
  const parsedTokens = parseInt(tokens)

  // tokens validation
  if (!tokens) {
    return {
      error: "Please enter a token amount.",
    }
  } else if (!onlyDigitsRegExp.test(tokens)) {
    return {
      error: "Token amount must be a number.",
    }
  } else if (isNaN(parsedTokens)) {
    return {
      error: "Token amount must be a number.",
    }
  } else if (parsedTokens < 1 || parsedTokens > 1000) {
    return {
      error: "Please enter a token amount between 1 and 1000.",
    }
  }

  return {
    error: "",
  }
}

export default tokensValidation
