import config from '../../../../config.js'
import _ from 'lodash'
import Chain from '../../../../utils/classes/chain.js'

const loadDiscussionRoom = (
  afterDate,
  userId,
  isLoadingPage,
  outgoing,
  status,
  setStatus,
  dispatch,
  actions
) => {
  const { loadLimits } = config

  // EXECUTABLES
  const _getDiscussions = (params) => {
    return dispatch(actions.getDiscussions(params))
  }

  const _getRebuttals = (params) => {
    return dispatch(actions.getRebuttals(params))
  }

  const _getTakesByIDs = (takeIds) => {
    return dispatch(actions.getTakesByIDs({ takeIds }))
  }

  const _getProfilesByUserIDs = (userIds) => {
    return dispatch(actions.getProfilesByUserIDs({ userIds }))
  }

  // PRE-EXECUTION

  var chain = new Chain(status)

  // EXECUTION

  return _getDiscussions({
    userId,
    limit: loadLimits.discussions.perUser,
    afterDate,
    isLoadingPage,
    outgoing: false
  })
    .then((data1) => {
      if (data1.discussions.length === 0) {
        setStatus((status) => ({
          ...status,
          pageLoaded: true,
          error: true,
          message: 'No discussions found'
        }))

        chain.break('No discussions found.')

        return Promise.resolve()
      }

      let debateIds = []
      let takeIds = []
      let userIds = []

      for (var i = 0; i < data1.discussions.length; i++) {
        let { _id, hostUserId, guestUserId, takeId } = data1.discussions[i]

        // Grab discussion ids
        debateIds.push(_id)

        // Grab take IDs from Discussions
        if (!_.includes(takeIds, takeId)) {
          takeIds.push(takeId)
        }

        // Grab user IDs from Discussions
        if (data1.discussions[i].guestUserId === userId) {
          if (!_.includes(userIds, hostUserId)) {
            userIds.push(hostUserId)
          }
        } else {
          if (!_.includes(userIds, guestUserId)) {
            userIds.push(guestUserId)
          }
        }
      }

      chain.setData('discussions', data1.discussions)
      chain.setData('debateIds', debateIds)
      chain.setData('takeIds', takeIds)
      chain.setData('userIds', userIds)

      return _getRebuttals({ debateIds, limit: loadLimits.rebuttals, isLoadingPage })
    })
    .then((data2) => {
      if (chain.broken) return Promise.resolve()

      if (chain.data.debateIds.length === 0 && data2.rebuttals.length === 0) {
        setStatus((status) => ({
          ...status,
          pageLoaded: true,
          error: true,
          message: 'No rebuttals found'
        }))

        chain.break('No rebuttals found.')

        return Promise.resolve()
      }

      return _getTakesByIDs(chain.data.takeIds)
    })
    .then((data3) => {
      if (chain.broken) {
        setStatus((status) => ({
          ...status,
          pageLoaded: true,
          error: true,
          message: 'No takes found'
        }))

        return Promise.resolve()
      }

      return _getProfilesByUserIDs(chain.data.userIds)
    })
    .then((data4) => {
      if (chain.broken) {
        setStatus((status) => ({
          ...status,
          pageLoaded: true,
          error: true,
          message: 'No profiles found'
        }))

        return Promise.resolve()
      }

      setStatus((status) => ({
        ...status,
        pageLoaded: true,
        inboxLoadMore: chain.data.discussions.length === loadLimits.discussions.perUser
      }))

      return _getDiscussions({
        userId,
        limit: loadLimits.discussions.perUser,
        afterDate,
        isLoadingPage,
        outgoing: true
      })
    })
    .then((data5) => {
      if (data5.discussions.length === 0) {
        setStatus((status) => ({
          ...status,
          pageLoaded: true,
          error: true,
          message: 'No sent discussions found'
        }))

        chain.break('No discussions found.')

        return Promise.resolve()
      }

      let debateIds = []
      let takeIds = []
      let userIds = []

      for (var i = 0; i < data5.discussions.length; i++) {
        let { _id, hostUserId, guestUserId, takeId } = data5.discussions[i]

        // Grab discussion ids
        debateIds.push(_id)

        // Grab take IDs from Discussions
        if (!_.includes(takeIds, takeId)) {
          takeIds.push(takeId)
        }

        // Grab user IDs from Discussions
        if (data5.discussions[i].guestUserId === userId) {
          if (!_.includes(userIds, hostUserId)) {
            userIds.push(hostUserId)
          }
        } else {
          if (!_.includes(userIds, guestUserId)) {
            userIds.push(guestUserId)
          }
        }
      }

      chain.setData('discussions', data5.discussions)
      chain.setData('debateIds', debateIds)
      chain.setData('takeIds', takeIds)
      chain.setData('userIds', userIds)

      return _getRebuttals({ debateIds, limit: loadLimits.rebuttals, isLoadingPage, outgoing })
    })
    .then((data6) => {
      if (chain.broken) return Promise.resolve()

      if (chain.data.debateIds.length === 0 && data6.rebuttals.length === 0) {
        setStatus((status) => ({
          ...status,
          pageLoaded: true,
          error: true,
          message: 'No sent rebuttals found'
        }))

        chain.break('No rebuttals found.')

        return Promise.resolve()
      }

      return _getTakesByIDs(chain.data.takeIds)
    })
    .then((data7) => {
      if (chain.broken) {
        setStatus((status) => ({
          ...status,
          pageLoaded: true,
          error: true,
          message: 'No sent takes found'
        }))

        return Promise.resolve()
      }

      return _getProfilesByUserIDs(chain.data.userIds)
    })
    .then((data8) => {
      if (chain.broken) {
        setStatus((status) => ({
          ...status,
          pageLoaded: true,
          error: true,
          message: 'No sent profiles found'
        }))

        return Promise.resolve()
      }

      setStatus((status) => ({
        ...status,
        pageLoaded: true,
        sentLoadMore: chain.data.discussions.length === loadLimits.discussions.perUser,
        message: 'Successfully loaded discussions.'
      }))

      chain.break('Successfully loaded discussions.')

      return Promise.resolve()
    })
    .then((last_data) => {
      let { broken, log } = chain

      if (broken) {
        console.log('[DEBUG]: ', log)

        return Promise.resolve()
      }

      throw new Error('Unhandled promise chain logic (Load Discussion Room)')
    })
    .catch((error) => {
      console.log('[ERROR]: ', error)
    })
}

export default loadDiscussionRoom
