import { useState, useEffect, useContext } from 'react'
import { useSelector } from 'react-redux'
import { useParams, withRouter } from 'react-router-dom'
import { history } from '../../../../../history/history'
import checkOrgPermissions from '../../../../../utils/functions/checkPermissions'
import { MainContainer, Header, Step } from './styled/side-nav-desktop-comps'
import LoadOrganizationRolesContext from '../../../../data-loading/load-organization-assigned-roles/context/context'

function SideNavDesktop(props) {
  const params = useParams()
  const { roles, assignedRoles } = useContext(LoadOrganizationRolesContext)
  const [section, setSection] = useState('')
  const currentUser = useSelector(({ currentUser }) => currentUser)

  useEffect(() => {
    const { match } = props
    const { tab } = match.params

    if (tab) {
      setSection(tab)
    }
  }, [props])

  const handleSelectTab = (e) => {
    history.push(`/organization/${params.orgId}/settings/${e.target.id}`)
  }

  const hasAdminPermissions = checkOrgPermissions({
    assignedRoles,
    roles,
    currUserId: currentUser.id,
    permissions: [] // Admin only
  })

  const hasOrganizationDetailsPermissions = checkOrgPermissions({
    assignedRoles,
    roles,
    currUserId: currentUser.id,
    permissions: ['manageOrganizationDetails']
  })

  const hasRoleManagementPermissions = checkOrgPermissions({
    assignedRoles,
    roles,
    currUserId: currentUser.id,
    permissions: ['manageRoles', 'manageAssignedRoles']
  })

  const hasBillingPermissions = checkOrgPermissions({
    assignedRoles,
    roles,
    currUserId: currentUser.id,
    permissions: ['manageBilling']
  })

  return (
    <MainContainer>
      <Header>Organization Account</Header>

      <div>
        {hasAdminPermissions && (
          <Step
            id="account"
            onClick={handleSelectTab}
            style={{ marginTop: 0 }}
            current={section === 'account'}>
            Account Information
          </Step>
        )}

        {hasOrganizationDetailsPermissions && (
          <Step id="picture" onClick={handleSelectTab} current={section === 'picture'}>
            Profile Picture
          </Step>
        )}

        {hasOrganizationDetailsPermissions && (
          <Step id="details" onClick={handleSelectTab} current={section === 'details'}>
            Organization Details
          </Step>
        )}

        {hasOrganizationDetailsPermissions && (
          <Step id="location" onClick={handleSelectTab} current={section === 'location'}>
            Location Settings
          </Step>
        )}

        {hasRoleManagementPermissions && (
          <Step id="roles" onClick={handleSelectTab} current={section === 'roles'}>
            Role Management
          </Step>
        )}

        {hasBillingPermissions && (
          <Step id="billing" onClick={handleSelectTab} current={section === 'billing'}>
            Billing & Donations
          </Step>
        )}
      </div>
    </MainContainer>
  )
}

export default withRouter(SideNavDesktop)
