import React, { useState, createRef, useCallback } from 'react'
import DiscussionThumbnail from './discussion-thumbnail'
import ThumbnailSkeleton from './skeletons/discussion-thumbnail-skeleton'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import MuiIconButton from '@mui/material/IconButton'
// import MuiButton from '@mui/material/Button'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import {
  MainContainer,
  InnerContainer,
  LeftContainer,
  RightContainer,
  Header,
  NoResults
} from './styled/discussions-comps'

const useStyles = makeStyles((theme) =>
  createStyles({
    expandIcon: {
      color: theme.palette.common.white
    },
    details: {
      // display: 'block',
      padding: 0
    },
    tableContainer: {
      background: '#ffffff',
      padding: '0.5rem 1.5rem',
      [theme.breakpoints.down('md')]: {
        padding: '0.5rem'
      }
    },
    tableRoot: {
      display: 'block',
      tableLayout: 'fixed',
      width: '100%',
      borderCollapse: 'collapse',
      boxSizing: 'border-box',
      textIndent: 'initial',
      borderSpacing: 0
    },
    tableBody: {
      display: 'block',
      verticalAlign: 'middle',
      '& > tr:first-child': {
        '& > td': {
          borderTop: 'none'
        }
      },
      [theme.breakpoints.down('sm')]: {
        '& > tr:last-child': {
          '& > td': {
            borderBottom: '1.5px solid rgba(70, 70, 70, 0.5)'
          }
        }
      }
    },
    tableRow: {
      display: 'flex',
      verticalAlign: 'inherit',
      position: 'relative',
      cursor: 'pointer',
      padding: '0.25rem 0',
      '&:first-child': {
        paddingTop: 0
      },
      [theme.breakpoints.down('md')]: {
        display: 'flex',
        padding: 0
      }
    },
    tablePagination: {
      display: 'block',
      border: 0
    },
    tableCellMobile: {
      flex: '1 1 auto',
      padding: '0.5rem 0',
      marginRight: '1rem',
      borderBottom: 'none',
      borderTop: '1.5px solid rgba(70, 70, 70, 0.5)'
    }
  })
)

// const Button = withStyles((theme) => ({
//   root: {
//    backgroundColor: theme.palette.secondary.main,
//     color: theme.palette.common.white,
//     width: '10rem',
//     fontSize: '0.875rem'
//   }
// }))(MuiButton)

const ExpandIconButton = withStyles((theme) => ({
  root: {
    width: '1.15rem',
    height: '1.15rem',
    borderRadius: '50%',
    backgroundColor: theme.palette.secondary.main,

    '&:hover': {
      backgroundColor: theme.palette.secondary.light
    }
  }
}))(MuiIconButton)

const Accordion = withStyles({
  root: {
    backgroundColor: 'unset',
    overflow: 'hidden'
  },
  expanded: {}
})(MuiAccordion)

const AccordionSummary = withStyles({
  root: {
    padding: 0,
    '&$expanded': {}
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: 0,
    '&$expanded': {
      margin: 0
    }
  },
  expanded: {}
})(MuiAccordionSummary)

const AccordionDetails = withStyles((theme) => ({
  root: {}
}))(MuiAccordionDetails)

// ******************************************************************* //

function MobileDiscussions({ discussions, type, handleLoadMore, loadMore, pageLoaded }) {
  /** Accordion Controls */
  const [expanded, setExpanded] = useState(true)
  const handleSetExpanded = (e) => {
    e.stopPropagation()
    e.preventDefault()
    setExpanded(!expanded)
  }

  // *** Infinite Scroll Logic *** //
  const observer = createRef()
  const lastDiscussionElementRef = useCallback(
    (node) => {
      if (!pageLoaded) return
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && loadMore) {
          handleLoadMore(type)
        }
      })
      if (node) observer.current.observe(node)
    },
    [handleLoadMore, observer, pageLoaded, loadMore, type]
  )

  // MUI STYLES
  const classes = useStyles()

  return (
    <MainContainer>
      <InnerContainer>
        <Accordion
          classes={{}}
          expanded={expanded}
          onChange={(e) => handleSetExpanded(e)}
          elevation={0}>
          <AccordionSummary
            aria-label="Expand"
            aria-controls="expand-contract-content"
            id="expand-contract-header">
            <LeftContainer>
              <Header>{type}</Header>
              <ExpandIconButton>
                {!expanded ? (
                  <ExpandMoreIcon className={classes.expandIcon} />
                ) : (
                  <ExpandLessIcon className={classes.expandIcon} />
                )}
              </ExpandIconButton>
            </LeftContainer>

            <RightContainer>
              {/* <DropDownButton type={type} handleLoadMore={handleLoadMore} loadMore={loadMore} /> */}
            </RightContainer>
          </AccordionSummary>
          <AccordionDetails className={classes.details}>
            <TableContainer className={classes.tableContainer}>
              <Table classes={{ root: classes.tableRoot }}>
                <TableHead></TableHead>
                <TableBody className={classes.tableBody}>
                  {!pageLoaded ? (
                    <>
                      <ThumbnailSkeleton />
                    </>
                  ) : (
                    <>
                      {discussions.length === 0 ? (
                        <NoResults>No Discussions</NoResults>
                      ) : (
                        typeof discussions !== 'undefined' &&
                        discussions.map((discussion, index) => {
                          if (discussions.length === index + 1) {
                            return (
                              <DiscussionThumbnail
                                discussion={discussion}
                                key={discussion._id}
                                type={type}
                                ref={lastDiscussionElementRef}
                              />
                            )
                          } else {
                            return (
                              <DiscussionThumbnail
                                discussion={discussion}
                                key={discussion._id}
                                type={type}
                              />
                            )
                          }
                        })
                      )}
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>
      </InnerContainer>
    </MainContainer>
  )
}

export default MobileDiscussions
