import { inviteFriendsActionTypes } from '../action-creators/invite-friends.js'

// FOR INVITE FRIENDS MODAL STATE
const inviteFriendsReducer = (state = false, action) => {
  switch (action.type) {
    case inviteFriendsActionTypes.SET_INVITE_FRIENDS:
      return action.open
    case inviteFriendsActionTypes.CLEAR_INVITE_FRIENDS:
      return false
    default:
      return state
  }
}

export default inviteFriendsReducer
