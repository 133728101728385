import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import PostForm from '../../../../partials/post-form/post-form'
import checkOrgPermissions from '../../../../../utils/functions/checkPermissions'
import LoadOrganizationRolesContext from '../../../../data-loading/load-organization-assigned-roles/context/context'

function OrgPostFormContainer(props) {
  const { _id } = props
  const { roles, assignedRoles } = useContext(LoadOrganizationRolesContext)
  const organizationId = _id
  const { id } = useSelector((state) => state.currentUser)

  // returns a boolean if a user has permission for each permission passed into the permissions array
  const hasPostsPermissions = checkOrgPermissions({
    assignedRoles,
    roles,
    currUserId: id,
    permissions: ['managePosts']
  })

  if (!hasPostsPermissions) return null

  return <PostForm type="share" organizationId={organizationId} />
}

export default OrgPostFormContainer
