import styled from 'styled-components'
import theme from '../../../../../res/theme.js'

export const CreateFormContainer = styled.div`
  margin-top: 1rem;
`

export const TextCount = styled.span`
  display: block;
  font-family: ${theme.typography.fontFamily};
  font-size: ${theme.typography.fontSizes.small};
  font-weight: ${theme.typography.fontWeight.medium};
  color: ${theme.typography.fontColor.headerCopy};
  text-align: end;
  padding: 0.25rem 0;
`
