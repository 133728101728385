import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'
import debounce from 'lodash/debounce'
import includes from 'lodash/includes'
import some from 'lodash/some'
import { searchTags } from '../../../redux/thunks/search/searchTags'
import normalizeTagSearch from './functions/normalize-tag-search'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import { ClickAwayListener, Grow, Paper, Popper, MenuList, MenuItem } from '@mui/material'
import { MainContainer, Input, Result } from './styled/tags-search-comps'

const useStyles = makeStyles(() =>
  createStyles({
    popper: {
      marginTop: '.1rem'
    },
    menuList: {
      padding: '.2rem 0',
      maxWidth: '25rem'
    },
    menuItem: {
      padding: '0 ',
      height: 'auto',
      minHeight: 'auto',
      whiteSpace: 'normal'
    }
  })
)

const TagsSearch = (props) => {
  const { tagIds, setTagIds, setError, disableNewTags, style, variant } = props
  const inputRef = useRef(null)
  const [didRecognizeRef, setDidRecognizeRef] = useState(false)
  const [tagSearch, setTagSearch] = useState('')
  const [tagResultsOpen, setTagResultsOpen] = useState(false)
  const tagResults = useSelector(({ tagResults }) => tagResults)
  const classes = useStyles()
  const dispatch = useDispatch()

  const handleSearch = useRef(
    debounce((query) => {
      dispatch(searchTags({ query, limit: 8 })).then((data) => {
        if (!data.error) {
          console.log('[SUCCESS]: ', 'Tag search succeeded!')
        } else {
          console.log('[FAIL]: ', 'Failed to search tags.')
        }

        setTagResultsOpen(true)
      })
    }, 300)
  ).current

  const handleTagInputChange = (e) => {
    const { value } = e.target

    const { error, tag, normalizedTag } = normalizeTagSearch(value)

    if (!error) {
      setTagSearch(tag)
    } else if (error && normalizedTag.length === 0) {
      setTagSearch('')
    } else {
      // setTagResultsOpen(false)
    }
  }

  const handleSelectTag = (e) => {
    e.preventDefault()

    const { id } = e.target
    const newTags = [...tagIds]

    if (includes(tagIds, id)) {
      if (setError) setError(`${id} has already been tagged.`)

      setTagSearch('')

      return
    }

    newTags.push(id)

    setTagIds(newTags)
    setTagSearch('')

    if (setError) setError('')
  }

  const handleSubmit = (event) => {
    event.preventDefault()
  }

  useEffect(() => {
    if (!didRecognizeRef) {
      if (inputRef.current !== null && typeof inputRef.current !== 'undefined') {
        setDidRecognizeRef(true)
      }
    }
  }, [inputRef, didRecognizeRef])

  useEffect(() => {
    if (tagSearch && tagSearch.length > 0) {
      handleSearch(tagSearch)
    } else {
      setTagResultsOpen(false)
    }
  }, [tagSearch, handleSearch])

  const searchResults = tagResults.map((tag) => {
    // Here we bold only the search term in the returned tag result
    const normalizedSearchTerm = normalizeTagSearch(tagSearch).normalizedTag
    const replacedStr = tag._id.replace(normalizedSearchTerm, `,${normalizedSearchTerm},`)
    const splitStr = replacedStr.split(',')
    const searchTermIndex = splitStr.indexOf(normalizedSearchTerm)
    const resultArr = []

    splitStr.forEach((str, i) => {
      if (i === searchTermIndex) {
        resultArr.push(<b key={str}>{str}</b>)
      } else {
        resultArr.push(str)
      }
    })

    return (
      <MenuItem id={tag._id} key={tag._id} className={classes.menuItem} onClick={handleSelectTag}>
        <Result>
          <span className="material-icons">add</span>

          <span>
            {/* <b>{tag._id}</b> */}
            {resultArr}
          </span>
        </Result>
      </MenuItem>
    )
  })

  return (
    <MainContainer variant={variant} style={style || {}}>
      <span className="material-icons" aria-hidden="true">
        search
      </span>

      <Input
        ref={inputRef}
        type="text"
        placeholder={tagIds?.length >= 5 ? 'No more tags allowed' : 'e.g. do-good'}
        value={tagSearch}
        onChange={handleTagInputChange}
        disabled={tagIds?.length >= 5}
        variant={variant}
        aria-label="Search for tags input"
        autocomplete="off"
      />

      {didRecognizeRef && (
        <Popper
          id="tag-search-results"
          open={tagResultsOpen}
          anchorEl={inputRef.current}
          placement="bottom-start"
          transition
          disablePortal
          className={classes.popper}
          style={{ zIndex: 100 }}>
          {({ TransitionProps }) => (
            <Grow {...TransitionProps} id="nav-menu-dropdown">
              <Paper>
                <ClickAwayListener onClickAway={() => setTagResultsOpen(false)}>
                  <MenuList
                    className={classes.menuList}
                    tabIndex="0"
                    aria-live="polite"
                    aria-atomic="true">
                    {searchResults}

                    {searchResults.length === 0 &&
                      disableNewTags &&
                      !normalizeTagSearch(tagSearch).error && (
                        <MenuItem
                          id={normalizeTagSearch(tagSearch).normalizedTag}
                          className={classes.menuItem}
                          tabIndex="-1">
                          <Result>
                            <span>
                              No results found for{' '}
                              <b>{normalizeTagSearch(tagSearch).normalizedTag}</b>
                            </span>
                          </Result>
                        </MenuItem>
                      )}

                    {/* Allow user to create new tag if it doesn't exist */}
                    {!some(tagResults, {
                      _id: normalizeTagSearch(tagSearch).normalizedTag
                    }) && (
                      <MenuItem
                        id={normalizeTagSearch(tagSearch).normalizedTag}
                        className={classes.menuItem}
                        onClick={handleSelectTag}
                        tabIndex="-1">
                        {!disableNewTags && (
                          <Result>
                            <span className="material-icons">add</span>

                            <span>
                              Create new tag <b>{normalizeTagSearch(tagSearch).normalizedTag}</b>
                            </span>
                          </Result>
                        )}
                      </MenuItem>
                    )}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      )}
    </MainContainer>
  )
}

export default withRouter(TagsSearch)
