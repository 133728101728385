import { useSelector, useDispatch } from 'react-redux'
import some from 'lodash/some'
import { analytics } from '../../../../../firebase/firebase'
/** REDUX */
import { createUserFollow } from '../../../../../redux/thunks/follows/createUserFollow'
import { deleteFollowByUserID } from '../../../../../redux/thunks/follows/deleteFollowByUserID'
/** UTILS */
import getName from '../../../../../utils/functions/getName'
/** MUI */
import MuiTooltip from '@mui/material/Tooltip'
import Button from '@mui/material/Button'
import makeStyles from '@mui/styles/makeStyles'
import withStyles from '@mui/styles/withStyles'
import { PersonAddSpan } from './styled/header-comps'

const useStyles = makeStyles((theme) => ({
  root: {
    // position: 'absolute',
    // top: '1.5rem',
    // right: '5rem',
    // right: '1.5rem',
    padding: 0,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.secondary.main,
    // borderRadius: '50%',
    minWidth: '0',
    minHeight: '0',
    height: '2.5rem',
    width: '2.5rem',
    [theme.breakpoints.down('md')]: {
      // top: '1rem',
      // right: '1rem',
      height: '2.5rem',
      width: '2.5rem'
    },
    [theme.breakpoints.down('sm')]: {
      // top: '0.75rem',
      // right: '.75rem',
      height: '2.35rem',
      width: '2.35rem'
    },
    [theme.breakpoints.down(undefined)]: {
      // top: '0.5rem',
      // right: '.5rem',
      height: '2.35rem',
      width: '2.35rem'
    }
  },
  label: {
    // borderRadius: '50%'
  }
}))

const Tooltip = withStyles((theme) => ({
  tooltip: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.secondary.main,
    fontSize: '1rem'
  }
}))(MuiTooltip)

function FollowButton({ userId, profile }) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const currentUser = useSelector(({ currentUser }) => currentUser)
  const follows = useSelector(({ follows }) => follows)
  const isFollowing = some(follows, { follower: currentUser.id, userId })

  const handleFollow = () => {
    dispatch(
      createUserFollow({
        userId: profile.userId,
        name: getName(profile)
      })
    ).then((data) => {
      if (!data.error) {
        console.log('[SUCCESS]: ', 'Successfully followed user!')
      } else {
        console.log('[FAIL]: ', 'Failed to follow user.')
      }
    })

    analytics.logEvent('toggle_follow')
  }

  const handleUnfollow = () => {
    dispatch(
      deleteFollowByUserID({
        userId: profile.userId,
        currUserId: currentUser.id,
        name: getName(profile)
      })
    ).then((data) => {
      if (!data.error) {
        console.log('[SUCCESS]: ', 'Successfully unfollowed user!')
      } else {
        console.log('[FAIL]: ', 'Failed to unfollow user.')
      }
    })
  }

  return (
    <Tooltip title={isFollowing ? 'Unfollow' : 'Follow'}>
      <Button
        onClick={isFollowing ? handleUnfollow : handleFollow}
        classes={{ root: classes.root, label: classes.label }}
        variant="contained">
        {isFollowing ? (
          <PersonAddSpan className="material-icons" style={{ fontSize: '1.3rem' }}>
            person_remove
          </PersonAddSpan>
        ) : (
          <PersonAddSpan className="material-icons" style={{ fontSize: '1.3rem' }}>
            person_add
          </PersonAddSpan>
        )}
      </Button>
    </Tooltip>
  )
}

export default FollowButton
