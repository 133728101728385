import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import withStyles from '@mui/styles/withStyles'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

const styles = (theme) => ({
  paper: {
    padding: '1rem',
    margin: '1rem'
  },
  dialogContentText: {
    color: theme.palette.text.primary
  }
})

class ConfirmationDialog extends Component {
  handleSecondaryAction = () => {
    this.props.secondaryAction()
  }

  handlePrimaryAction = () => {
    this.props.primaryAction()

    if (!this.props.preventAutoCloseOnSubmit) {
      this.handleSecondaryAction()
    }
  }

  render() {
    const {
      fullScreen,
      open,
      title,
      body,
      primaryText,
      primaryAction,
      secondaryText,
      hideTitle,
      hideButton,
      classes
    } = this.props

    return (
      <div>
        <Dialog
          fullWidth={true}
          maxWidth={'sm'}
          fullScreen={fullScreen}
          classes={{ paper: classes.paper }}
          open={open}
          onClose={this.handleSecondaryAction}
          aria-labelledby="responsive-dialog-title">
          {!hideTitle && <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>}

          <DialogContent>
            <DialogContentText
              sx={{
                color: 'text.primary'
              }}>
              {body}
            </DialogContentText>
          </DialogContent>

          <DialogActions style={{ paddingBottom: '1rem' }}>
            {!hideButton && (
              <div>
                <Button
                  onClick={this.handleSecondaryAction}
                  color="secondary"
                  variant={'contained'}
                  sx={{
                    marginRight: '0.5rem'
                  }}>
                  {secondaryText || 'No'}
                </Button>

                {typeof primaryAction !== 'undefined' && (
                  <Button
                    onClick={this.handlePrimaryAction}
                    color="primary"
                    autoFocus
                    variant={'contained'}>
                    {primaryText || 'Yes'}
                  </Button>
                )}
              </div>
            )}
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

ConfirmationDialog.propTypes = {
  fullScreen: PropTypes.bool.isRequired
}

export default withRouter(withStyles(styles)(ConfirmationDialog))
