import Validator from "validator"

const SendEmailUpdateValidation = (state, currentOrganizations) => {
  // Email
  if (Validator.isEmpty(state.email)) {
    return {
      error: "Email is required",
      field: "email",
    }
  } else if (!Validator.isEmail(state.email)) {
    return {
      error: "Invalid email address",
      field: "email",
    }
  } else if (state.email.toLowerCase() === currentOrganizations.email) {
    return {
      error: "You are already using this email address.",
      field: "email",
    }
  }

  return {
    error: "",
    field: "",
  }
}

export default SendEmailUpdateValidation
