import config from '../../../config'
import { authenticatedFetch } from '../../../utils/functions/authenticatedFetch'
import { logout } from './logout.js'

/*
 * METHOD TO DELETE USER ACCOUNT
 */
export const deleteAccount = () => {
  let responseCode = ''
  const method = 'DELETE'
  const url = `${config.baseURL}/api/user`

  // Thunk middleware knows how to handle functions.
  // It passes the dispatch method as an argument to the function,
  // thus making it able to dispatch actions itself.
  return (dispatch) => {
    console.log('Sending delete account request to CivilTalk')

    // Return the fetch so react components calling 'store.dispatch()' can use 'then()'
    return authenticatedFetch(url, { method, credentials: 'include' })
      .then((response) => {
        responseCode = response.status

        return response.json()
      })
      .then((data) => {
        if (responseCode === 200) {
          dispatch(logout(true))

          return {
            error: false,
            code: responseCode,
            message: data.message
          }
        }

        return {
          error: true,
          code: responseCode,
          message: data.message
        }
      })
      .catch((error) => {
        console.log(error)

        return {
          error: true,
          code: '',
          message: error
        }
      })
  }
}
