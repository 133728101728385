import styled from 'styled-components'
// import config from "../../../../config.js"

export const MainContainer = styled.div`
  background-color: white;
  display: block;
  padding: 2rem;
  margin-bottom: 1rem;
  border-radius: 0.3rem;
  box-sizing: border-box;
  box-shadow: 0px 5px 5px -2px rgba(0, 0, 0, 0.2);
  border: 1px solid #dcdddd;
`

export const HeadingPrimary = styled.p`
  line-height: 2.2rem;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: 1.6rem;
  font-weight: ${(props) => props.theme.typography.fontWeight.bold};
  color: ${(props) => props.theme.palette.text.primary};
  ${'' /* word-break: ${({ wordBreak }) => wordBreak}; */}
`

export const HeadingSecondary = styled.p`
  margin-top: 1.3rem;
  line-height: 2.2rem;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: 1.4rem;
  font-weight: ${(props) => props.theme.typography.fontWeight.semiBold};
  color: ${(props) => props.theme.palette.text.primary};
  ${'' /* word-break: ${({ wordBreak }) => wordBreak}; */}
`

export const AvatarWrapper = styled.div`
  padding: 1.75rem 0 0.75rem 0;
`

export const OrgName = styled.h4`
  display: block;
  margin-top: 0.5rem;
  /* text-align: center; */
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.small};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  color: ${(props) => props.theme.palette.text.primary};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`
