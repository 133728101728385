import config from '../../../config'
import { authenticatedFetch } from '../../../utils/functions/authenticatedFetch'

/*
 * METHOD RECEIVES USER SELECTED PERSONAL ADDRESS DETAILS
 * AND A JWT TOKEN w PAYLOAD CONTAINING THE RESULTS FROM THE LOCATION API
 */

export const getZipCodeLocation = (params) => {
  const { city, state, zipCode } = params

  let responseCode = ''
  const method = 'GET'
  const baseUrl = `${config.baseURL}/api/smartystreets/zip-code`
  const url = `${baseUrl}?city=${city}&state=${state}&zipCode=${zipCode}`
  const headers = {
    'Content-Type': 'application/json'
  }

  return function (dispatch) {
    console.log('Sending getLocation request to CivilTalk')

    return authenticatedFetch(url, {
      method,
      headers,
      credentials: 'include'
    })
      .then((response) => {
        responseCode = response.status

        return response.json()
      })
      .then((data) => {
        console.log('*** location thunk data', data)
        // CivilTalk API will return a 200 if the results are OK
        if (responseCode === 200) {
          // We decided NOT to add this data to the reducer since we do not share outside of the <LocationForm />
          // response status, message, data and token
          return { error: false, code: responseCode, location: data }
        } else {
          return {
            error: true,
            code: responseCode,
            message: data.message
          }
        }
      })
      .catch((error) => {
        return {
          error: true,
          code: responseCode,
          message: error
        }
      })
  }
}
