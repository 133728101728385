import React, {
  useState
  //  useEffect
} from 'react'
import {
  useStripe,
  useElements,
  // PaymentRequestButtonElement,
  PaymentElement
} from '@stripe/react-stripe-js'
import FormError from '../../../../../../../../alerts/form-error'
// import StripeIcon from '../../../../../../../../../media/icons/stripe/png/powered_by_stripe_blurple.png'
// import TokenIcon from '../../../../../../../../../media/icons/svg/token-black.svg'
import { Button, CircularProgress } from '@mui/material'
import { Heading, ButtonContainer } from './styled/stripe-form-comps'
import config from '../../../../../../../../../config'

const styles = {
  back: {
    width: '10rem',
    marginRight: '1rem',
    '@media (max-width: 450px)': {
      width: '100%',
      marginRight: '0'
    }
  },
  submit: {
    width: '10rem',
    '@media (max-width: 450px)': {
      width: '100%',
      marginTop: '.5rem'
    }
  },
  circularProgress: {
    display: 'block',
    width: '17px !important',
    height: '17px !important',
    margin: '.1rem auto 0 auto'
  }
}

function StripeForm(props) {
  const {
    setStep,
    charge,
    // clientSecret,
    setClientSecret
  } = props
  // const [paymentRequest, setPaymentRequest] = useState(null)
  // const [initiatedPaymentRequest, setInitiatedPaymentRequest] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState('')
  //   const dispatch = useDispatch()
  const stripe = useStripe()
  const elements = useElements()

  // useEffect(() => {
  //   if (stripe && !initiatedPaymentRequest) {
  //     setInitiatedPaymentRequest(true)

  //     const pr = stripe.paymentRequest({
  //       country: 'US',
  //       currency: 'usd',
  //       total: {
  //         label: `Purchase of ${tokens} CivilTalk tokens.`,
  //         amount: tokens * 100
  //       }
  //       // requestPayerName: true,
  //       // requestPayerEmail: true
  //     })

  //     // Check the availability of the Payment Request API.
  //     pr.canMakePayment().then((result) => {
  //       if (result) {
  //         setPaymentRequest(pr)
  //       }
  //     })
  //   }
  // }, [initiatedPaymentRequest, stripe, tokens])

  const handleBack = () => {
    setClientSecret('')
    setStep('pricing')
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    if (!stripe || !elements) {
      // Stripe has not yet loaded.
      // Make sure to disable form submission until Stripe has loaded.
      return
    }

    setProcessing(true)

    const success_url = config.baseURL ? `${config.baseURL}/home` : `http://localhost:3000/home`

    stripe
      .confirmPayment({
        //`Elements` instance that was used to create the Payment Element
        elements,
        confirmParams: {
          // return_url: `${config.baseURL}/home`
          return_url: success_url
        },
        redirect: 'if_required'
      })
      .then((data) => {
        console.log({ data })

        if (!data.error) {
          console.log('[SUCCESS]: ', 'Successfully confirmed Stripe payment intent.')

          setStep('complete')
        } else {
          console.log('[FAIL]: ', 'Failed to confirm Stripe payment intent.')

          setError(data.error.message)
          setProcessing(false)
        }
      })
  }

  // Listen to the paymentmethod event to receive a PaymentMethod object.
  // Pass the PaymentMethod ID and the PaymentIntent’s client secret to
  // stripe.confirmCardPayment to complete the payment.
  // paymentRequest &&
  //   paymentRequest.on('paymentmethod', async (ev) => {
  //     // Confirm the PaymentIntent without handling potential next actions (yet).
  //     stripe
  //       .confirmCardPayment(
  //         clientSecret,
  //         { payment_method: ev.paymentMethod.id },
  //         { handleActions: false }
  //       )
  //       .then((data) => {
  //         if (data.error) {
  //           // Report to the browser that the payment failed, prompting it to
  //           // re-show the payment interface, or show an error message and close
  //           // the payment interface.
  //           ev.complete('fail')
  //         } else {
  //           // Report to the browser that the confirmation was successful, prompting
  //           // it to close the browser payment method collection interface.
  //           ev.complete('success')
  //           // Check if the PaymentIntent requires any actions and if so let Stripe
  //           // handle the flow. If using an API version older than "2019-02-11"
  //           // instead check for: `paymentIntent.status === "requires_source_action"`.
  //           if (data.paymentIntent.status === 'requires_action') {
  //             // Let Stripe handle the rest of the payment flow.
  //             stripe.confirmCardPayment(clientSecret).then((data) => {
  //               if (error) {
  //                 // The payment failed -- ask your customer for a new payment method.
  //                 setError('Failed to complete payment. Please provide another payment method.')
  //               } else {
  //                 // The payment has succeeded.
  //                 setStep('complete')
  //               }
  //             })
  //           } else {
  //             // The payment has succeeded.
  //             setStep('complete')
  //           }
  //         }
  //       })
  //   })

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <Heading>Please select a payment method</Heading>

      <div style={{ marginTop: '1.5rem' }}>
        <FormError message={error} style={{ marginBottom: '1rem' }} />

        {/* {paymentRequest && (
          <>
            <PaymentRequestButtonElement options={{ paymentRequest }} />

            <div style={{ margin: '1rem 0', borderBottom: '1px solid rgba(0,0,0,.1)' }} />
          </>
        )} */}

        <PaymentElement />
      </div>

      <ButtonContainer>
        <Button color="secondary" variant="contained" onClick={handleBack} sx={styles.back}>
          Cancel
        </Button>

        <Button
          type="submit"
          color="primary"
          variant="contained"
          sx={styles.submit}
          disabled={processing || !stripe}>
          {processing ? (
            <CircularProgress color="primary" sx={styles.circularProgress} />
          ) : (
            `Pay $${charge}`
          )}
        </Button>
      </ButtonContainer>
    </form>
  )
}

export default StripeForm
