import styled from 'styled-components'

export const MainContainer = styled.div`
  background-color: white;
  display: block;
  margin-bottom: 1.5rem;
  padding: 0 1rem;
  box-sizing: border-box;
  border-radius: 0.3rem;
  box-shadow: 0px 5px 5px -2px rgba(0, 0, 0, 0.2);
  position: relative;
  padding: 2rem;

  @media screen and (max-width: 499px) {
    padding: 1rem 1.5rem 1.25rem 1.5rem;
  }
`

export const HeadingPrimary = styled.h1`
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: 1.6rem;
  font-weight: ${(props) => props.theme.typography.fontWeight.semiBold};
  color: ${(props) => props.theme.typography.fontColor.bodyCopy};

  @media screen and (max-width: 499px) {
    font-size: 1.25rem;
  }
`

export const HeadingSecondary = styled.h2`
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: 1.15rem;
  font-weight: ${(props) => props.theme.typography.fontWeight.medium};
  color: ${(props) => props.theme.typography.fontColor.bodyCopy};
  margin: 1rem 0 0.5rem 0;

  @media screen and (max-width: 499px) {
    font-size: 1rem;
  }
`

export const Subheading = styled.p`
  margin-top: 0.5rem;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.regular};
  font-weight: ${(props) => props.theme.typography.fontWeight.medium};
  color: ${(props) => props.theme.typography.fontColor.headerCopy};
`
