import { myAccoladesActionTypes } from '../action-creators/my-accolades'

const copyMyAccolades = (accolades) => {
  return accolades.map((accolade) => {
    return { ...accolade }
  })
}

// FOR ACCOLADES
const myAccoladesReducer = (state = [], action) => {
  switch (action.type) {
    case myAccoladesActionTypes.SET_MY_ACCOLADES:
      return copyMyAccolades(action.accolades)
    case myAccoladesActionTypes.CLEAR_MY_ACCOLADES:
      return []
    default:
      return state
  }
}

export default myAccoladesReducer
