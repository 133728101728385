import React from 'react'
import DetailsSkeleton from './skeletons/org-details-skeleton'
import About from './components/about'
import Connections from './components/connections'
import OrgLeaders from './components/org-leaders'
import { AsideContainer, SectionContainer } from './styled/org-details-comps'
import SponsoredBy from './components/sponsored-by'

function OrgDetails(props) {
  const { organization, isMobile } = props

  if (!props.organization) {
    return (
      <AsideContainer>
        <DetailsSkeleton />
      </AsideContainer>
    )
  }

  const hasSponsor = ['UCONN', 'CIVILTALK'].includes(organization?.publicName.toUpperCase())

  return (
    <AsideContainer>
      <SectionContainer>
        <About organization={{ ...organization }} isMobile={isMobile} />
        {hasSponsor ? <SponsoredBy /> : null}
        <Connections orgId={organization._id} followers={organization.followers} />
      </SectionContainer>

      <OrgLeaders _id={organization._id} />
    </AsideContainer>
  )
}

export default OrgDetails
