import Placeholder from '../../media/placeholders/topic/topic-placeholder.jpg'

// Check Bing Media Object for a valid thumbnail
// Returns a placeholder if no valid thumbnail is found
const getThumbnailUrlFromMedia = mediaItem => {
  let thumbnailUrl = Placeholder

  if (mediaItem.type === 'article') {
    if (mediaItem.image && mediaItem.image.contentUrl) {
      thumbnailUrl = mediaItem.image.contentUrl
    } else if (
      mediaItem.image &&
      mediaItem.image.thumbnail &&
      mediaItem.image.thumbnail.contentUrl
    ) {
      thumbnailUrl = mediaItem.image.thumbnail.contentUrl
    }
  } else if (mediaItem.type === 'video') {
    if (mediaItem.thumbnailUrl) {
      thumbnailUrl = mediaItem.thumbnailUrl
    }
  }

  return thumbnailUrl
}

export default getThumbnailUrlFromMedia
