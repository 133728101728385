import useLoadBadges from './hooks/use-load-badges'
import BadgeList from './components/badge-list'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import Tooltip from '@mui/material/Tooltip'
import Zoom from '@mui/material/Zoom'
import {
  ArticleContainer,
  GreenArticleHeader,
  WhiteBackgroundContainer
} from './styled/badges-comps'

//////////////////
// CUSTOM TOOLTIP
//////////////////

const useStyles = makeStyles((theme) =>
  createStyles({
    arrow: {
      color: theme.palette.primary.main
    },
    tooltip: {
      backgroundColor: theme.palette.primary.main,
      padding: '.4rem 1rem',
      color: theme.palette.text.primary,
      fontSize: '1rem',
      fontWeight: 400,
      marginBottom: '1rem'
    }
  })
)

function CustomTooltip(props) {
  const classes = useStyles()

  return <Tooltip arrow TransitionComponent={Zoom} classes={classes} {...props} />
}

// ************ //
// *** MAIN *** //
// ************ //

function Badges(props) {
  const { userId, profile } = props
  const badges = useLoadBadges({ profile, userId })

  const hasBadges = badges.length > 0

  return (
    <>
      {hasBadges && (
        <ArticleContainer>
          <GreenArticleHeader>Badges</GreenArticleHeader>
          <WhiteBackgroundContainer>
            <BadgeList badges={badges} userId={userId} />
          </WhiteBackgroundContainer>
        </ArticleContainer>
      )}
    </>
  )
}

export default Badges
