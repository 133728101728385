// SETUP ENVIRONMENT VARIABLE
let env

if (
  import.meta.env.VITE_NODE_ENV === 'local' ||
  import.meta.env.VITE_NODE_ENV === 'development' ||
  import.meta.env.VITE_NODE_ENV === 'undefined' ||
  typeof import.meta.env.VITE_NODE_ENV === 'undefined'
) {
  env = 'local'
} else if (import.meta.env.VITE_NODE_ENV === 'dev') {
  env = 'dev'
} else if (import.meta.env.VITE_NODE_ENV === 'staging') {
  env = 'staging'
} else {
  env = 'production'
}

const GLOBAL_CONFIG = {
  UI: {
    tablet_breakpoint: {
      min: '1081px',
      max: '1080px'
    },
    drawer_breakpoint: {
      min: '1051px',
      max: '1050px'
    },
    mobile_breakpoint: {
      min: '601px',
      max: '600px'
    },
    smallMobile_breakpoint: {
      min: '401px',
      max: '400px'
    },
    header: {
      height: '5.5rem' // No longer using in the header
    },
    main: {
      marginTop: '1.5rem'
    }
  },
  loadLimits: {
    posts: 8,
    solutions: 10,
    bulletins: 7,
    discussions: {
      perPost: 6,
      perUser: 30
    },
    follows: 20,
    rebuttals: 6,
    topics: 12,
    notifications: 15,
    searchResults: 30,
    suggestedConnections: 9
  },
  timeLimits: {
    updateNickname: 15778800000 // 6 months
  },
  limits: {
    verifications: 5
  }
}

// ****************************************
// PRODUCTION *****************************
// ****************************************
const production = {
  ...GLOBAL_CONFIG,
  debug: false,
  baseURL: 'https://api.civiltalk.com',
  publicSiteURL: 'https://www.civiltalk.com',
  cognito: {
    REGION: 'us-east-1',
    USER_POOL_ID: import.meta.env.VITE_AWS_COGNITO_USER_POOL_ID,
    APP_CLIENT_ID: import.meta.env.VITE_AWS_COGNITO_APP_CLIENT_ID,
    oauthDomain: import.meta.env.VITE_AWS_COGNITO_OAUTH_DOMAIN,
    oauthResponseType: `code`,
    COGNITO_REDIRECT_URI: import.meta.env.VITE_AWS_COGNITO_REDIRECT_URI
  },
  keys: {
    googleAnalytics: 'UA-154473219-1',
    googleRecaptcha:
      typeof window !== 'undefined' && window?.Cypress
        ? import.meta.env.VITE_GOOGLE_RECAPTCHA_E2E_TEST
        : import.meta.env.VITE_GOOGLE_RECAPTCHA
  },
  stripe: {
    paymentPlans: [
      // Deprecated /Limited Access Plan
      {
        id: 'price_1JNMOZHvGX36GL6rPydNd39T',
        title: 'Limited-Access',
        price: '$1.49',
        billingPeriod: 'month',
        restricted: true
      },
      // Deprecated /Limited Access Plan
      {
        id: 'price_1JNMNzHvGX36GL6rgiqPyHsT',
        title: 'Limited-Access',
        price: '$7.99',
        billingPeriod: 'year',
        restricted: true
      },
      {
        id: 'price_1JNMN4HvGX36GL6rg9s0beSq',
        title: 'Full-Access',
        price: '$2.99',
        billingPeriod: 'month',
        restricted: false
      },
      {
        id: 'price_1J6JfhHvGX36GL6rrBTBF2v3',
        title: 'Full-Access',
        price: '$19.99',
        billingPeriod: 'year',
        restricted: false
      },
      // Deprecated / Free Plan
      {
        id: 'plan_FT05rCeEeaq4uL',
        title: 'Full-Access',
        price: '$0.00',
        billingPeriod: 'month',
        restricted: true
      }
    ]
  },
  sentry: import.meta.env.VITE_SENTRY_DSN_PRODUCTION
}

// ****************************************
// STAGING ********************************
// ****************************************
const staging = {
  ...GLOBAL_CONFIG,
  debug: true,
  baseURL: 'https://api-staging.civiltalk.me',
  publicSiteURL: 'https://civiltalk-public-staging.herokuapp.com/',
  cognito: {
    REGION: 'us-east-1',
    USER_POOL_ID: import.meta.env.VITE_AWS_COGNITO_USER_POOL_ID,
    APP_CLIENT_ID: import.meta.env.VITE_AWS_COGNITO_APP_CLIENT_ID,
    oauthDomain: import.meta.env.VITE_AWS_COGNITO_OAUTH_DOMAIN,
    oauthResponseType: `code`,
    COGNITO_REDIRECT_URI: import.meta.env.VITE_AWS_COGNITO_REDIRECT_URI
  },
  keys: {
    googleAnalytics: 'UA-154473219-1',
    googleRecaptcha:
      typeof window !== 'undefined' && window?.Cypress
        ? import.meta.env.VITE_GOOGLE_RECAPTCHA_E2E_TEST
        : import.meta.env.VITE_GOOGLE_RECAPTCHA
  },
  stripe: {
    paymentPlans: [
      // Deprecated /Limited Access Plan
      {
        id: 'price_1JNMSUHvGX36GL6roY9t3bZa',
        title: 'Limited-Access',
        price: '$1.49',
        billingPeriod: 'month',
        restricted: true
      },
      // Deprecated /Limited Access Plan
      {
        id: 'price_1JNMSAHvGX36GL6rFnwd2MFA',
        title: 'Limited-Access',
        price: '$7.99',
        billingPeriod: 'year',
        restricted: true
      },
      {
        id: 'price_1JNMRWHvGX36GL6rq21rokRJ',
        title: 'Full-Access',
        price: '$2.99',
        billingPeriod: 'month',
        restricted: false
      },
      {
        id: 'price_1JNOmyHvGX36GL6rxMoU5x8V',
        title: 'Full-Access',
        price: '$19.99',
        billingPeriod: 'year',
        restricted: false
      },
      // Deprecated / Free Plan
      {
        id: 'plan_FRkQgtXamwBMp5',
        title: 'Full-Access',
        price: '$0.00',
        billingPeriod: 'month',
        restricted: true
      }
    ]
  },
  sentry: import.meta.env.VITE_SENTRY_DSN_TEST
}

// ****************************************
// DEVELOPMENT ****************************
// ****************************************
const dev = {
  ...GLOBAL_CONFIG,
  debug: true,
  baseURL: 'https://api.civiltalk.me',
  publicSiteURL: 'https://civiltalk-public-dev.vercel.app',
  cognito: {
    REGION: 'us-east-1',
    USER_POOL_ID: import.meta.env.VITE_AWS_COGNITO_USER_POOL_ID,
    APP_CLIENT_ID: import.meta.env.VITE_AWS_COGNITO_APP_CLIENT_ID,
    oauthDomain: import.meta.env.VITE_AWS_COGNITO_OAUTH_DOMAIN,
    oauthResponseType: `code`,
    COGNITO_REDIRECT_URI: import.meta.env.VITE_AWS_COGNITO_REDIRECT_URI
  },
  keys: {
    googleAnalytics: 'UA-154473219-2',
    googleRecaptcha:
      typeof window !== 'undefined' && window?.Cypress
        ? import.meta.env.VITE_GOOGLE_RECAPTCHA_E2E_TEST
        : import.meta.env.VITE_GOOGLE_RECAPTCHA
  },
  stripe: {
    paymentPlans: [
      // Deprecated /Limited Access Plan
      {
        id: 'price_1JNMSUHvGX36GL6roY9t3bZa',
        title: 'Limited-Access',
        price: '$1.49',
        billingPeriod: 'month',
        restricted: true
      },
      // Deprecated /Limited Access Plan
      {
        id: 'price_1JNMSAHvGX36GL6rFnwd2MFA',
        title: 'Limited-Access',
        price: '$7.99',
        billingPeriod: 'year',
        restricted: true
      },
      {
        id: 'price_1JNMRWHvGX36GL6rq21rokRJ',
        title: 'Full-Access',
        price: '$2.99',
        billingPeriod: 'month',
        restricted: false
      },
      {
        id: 'price_1JNOmyHvGX36GL6rxMoU5x8V',
        title: 'Full-Access',
        price: '$19.99',
        billingPeriod: 'year',
        restricted: false
      },
      // Deprecated / Free Plan
      {
        id: 'plan_FRkQgtXamwBMp5',
        title: 'Full-Access',
        price: '$0.00',
        billingPeriod: 'month',
        restricted: true
      }
    ]
  },
  sentry: import.meta.env.VITE_SENTRY_DSN_TEST
}

// ****************************************
// LOCAL **********************************
// ****************************************
const local = {
  ...GLOBAL_CONFIG,
  debug: true,
  baseURL: '',
  // baseURL: 'http://localhost:8000',
  publicSiteURL: 'http://localhost:3005',
  cognito: {
    REGION: 'us-east-1',
    USER_POOL_ID: import.meta.env.VITE_AWS_COGNITO_USER_POOL_ID,
    APP_CLIENT_ID: import.meta.env.VITE_AWS_COGNITO_APP_CLIENT_ID,
    oauthDomain: import.meta.env.VITE_AWS_COGNITO_OAUTH_DOMAIN,
    oauthResponseType: `code`,
    COGNITO_REDIRECT_URI: import.meta.env.VITE_AWS_COGNITO_REDIRECT_URI
  },
  keys: {
    googleAnalytics: 'UA-154473219-2',
    googleRecaptcha:
      typeof window !== 'undefined' && window?.Cypress
        ? import.meta.env.VITE_GOOGLE_RECAPTCHA_E2E_TEST
        : import.meta.env.VITE_GOOGLE_RECAPTCHA
  },
  stripe: {
    paymentPlans: [
      // Deprecated /Limited Access Plan
      {
        id: 'price_1JNMSUHvGX36GL6roY9t3bZa',
        title: 'Limited-Access',
        price: '$1.49',
        billingPeriod: 'month',
        restricted: true
      },
      // Deprecated /Limited Access Plan
      {
        id: 'price_1JNMSAHvGX36GL6rFnwd2MFA',
        title: 'Limited-Access',
        price: '$7.99',
        billingPeriod: 'year',
        restricted: true
      },
      {
        id: 'price_1JNMRWHvGX36GL6rq21rokRJ',
        title: 'Full-Access',
        price: '$2.99',
        billingPeriod: 'month',
        restricted: false
      },
      {
        id: 'price_1JNOmyHvGX36GL6rxMoU5x8V',
        title: 'Full-Access',
        price: '$19.99',
        billingPeriod: 'year',
        restricted: false
      },
      // Deprecated / Free Plan
      {
        id: 'plan_FRkQgtXamwBMp5',
        title: 'Full-Access',
        price: '$0.00',
        billingPeriod: 'month',
        restricted: true
      }
    ]
  },
  sentry: import.meta.env.VITE_SENTRY_DSN_TEST
}

const config = {
  production,
  staging,
  dev,
  local
}

if (import.meta.env.VITE_NODE_ENV === 'local') {
  console.log(`************************************************************`)
  console.log(`*** Welcome to CivilTalk, you are running in ${env} mode ***`)
  console.log('*** NODE_ENV =', process.env.NODE_ENV)
  console.log('*** VITE_NODE_ENV =', import.meta.env.VITE_NODE_ENV)
  console.log('*** Environment Key =', env)
  console.log(`************************************************************`)
}

export default config[env]
