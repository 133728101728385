import Validator from 'validator'

const UpdateWebsiteValidation = (website) => {
  if (!website) {
    return { error: 'Website is required.' }
  } else if (!Validator.isURL(website)) {
    return { error: 'Please enter a valid URL.' }
  }

  return { error: '' }
}

export default UpdateWebsiteValidation
