import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getOrganizationsByIDs } from '../../../redux/thunks/organizations/getOrganizationsByIDs'
import Context from './context/context'

// Props:
// children --> (Child JSX Elements)
// organizationIds --> <Array>: Organization IDs from which to load roles and assigned roles
// enableAsync --> <Boolean>: If set to true, children will be loaded immediately
// disableRedux --> <Boolean>: If set to true, data will not be stored in redux
// hideOnNoResults --> <Boolean>: If set to true, children will be hidden if no data is returned
const LoadOrganizations = (props) => {
  const { children, organizationIds, enableAsync, disableRedux, hideOnNoResults } = props
  const [loadingInitialized, setLoadingInitialized] = useState(false)
  const [displayChildren, setDisplayChildren] = useState(false)
  const [organizations, setOrganizations] = useState([])
  const dispatch = useDispatch()

  useEffect(() => {
    if (!loadingInitialized) {
      setLoadingInitialized(true)

      console.log('[DEBUG]: Loading organizations by IDs...')

      dispatch(getOrganizationsByIDs({ organizationIds }, { disableRedux }))
        .then((data) => {
          if (data.error) {
            throw new Error('Error retrieving organizations by IDs <LoadOrganizations>')
          }

          setOrganizations(data.organizations)
          setDisplayChildren(true)

          console.log('[SUCCESS]: Successfully retrieved organizations by IDs.')
        })
        .catch((e) => {
          console.log('[ERROR]: ', e)
        })
    }
  }, [disableRedux, dispatch, loadingInitialized, organizationIds])

  // If asynchronous loading is disabled and data isn't finished loading, return null
  if (!enableAsync && !displayChildren) return null
  // If asynchronous loading is disabled, hideOnNoResults is 'true', and no data is returned, return null
  if (!enableAsync && hideOnNoResults && organizations.length === 0) return null

  return <Context.Provider value={{ organizations }}>{children}</Context.Provider>
}

export default LoadOrganizations
