import statesAbbreviated from '../../../../../../utils/constants/statesAbbreviated'
import MuiSelectWrapper from './mui-select-wrapper'
import FormError from '../../../../../alerts/form-error'
import { SubmitButton } from './mui-buttons'
import {
  SectionContainer,
  Header,
  SubHeader,
  FinePrint,
  Form,
  ItemContainer,
  InputLabel,
  InputsContainer,
  Input,
  ButtonContainer
} from '../styled/location-form-comps'

function SmartyStreetsInputForm(props) {
  const {
    currentLocation,
    city,
    updateCity,
    state,
    updateState,
    zipCode,
    updateZipCode,
    handleStep2,
    error
  } = props

  return (
    <>
      <Header>Where are you located?</Header>
      <SubHeader>
        Add your location to have conversations in your town, county, state and zip code.
      </SubHeader>

      <FormError message={error} style={{ marginTop: '.5rem', marginBottom: '0' }} />
      <Form onSubmit={handleStep2}>
        <SectionContainer>
          <ItemContainer>
            <InputLabel htmlFor="city">City:</InputLabel>
            <InputsContainer>
              <Input
                type="text"
                name="city"
                placeholder="Enter your city"
                value={city}
                width="15rem"
                id="city"
                onChange={(e) => updateCity(e.target.value)}
              />
            </InputsContainer>
          </ItemContainer>

          <ItemContainer>
            <InputLabel id="state-label">State:</InputLabel>
            <InputsContainer>
              <MuiSelectWrapper
                selectedItem={currentLocation.state}
                items={statesAbbreviated}
                handleUpdate={updateState}
                labelId={'state-label'}
                iD={'state'}
              />
            </InputsContainer>
          </ItemContainer>

          <ItemContainer>
            <InputLabel htmlFor="zipcode">Zip Code:</InputLabel>
            <InputsContainer>
              <Input
                type="text"
                name="zipCode"
                placeholder="Enter your zip code"
                value={zipCode}
                width="15rem"
                id="zipcode"
                onChange={(e) => updateZipCode(e.target.value)}
              />
            </InputsContainer>
          </ItemContainer>
        </SectionContainer>

        <FinePrint>
          * Privacy is important. We will not ask for your precise street address and you may easily
          choose to show or hide your location by visiting My Account - Privacy Settings.
        </FinePrint>
        
        <ButtonContainer>
          <SubmitButton
            color="primary"
            variant="contained"
            disabled={city === '' || state === '' || zipCode === ''}
            onClick={handleStep2}>
            Submit
          </SubmitButton>
        </ButtonContainer>
      </Form>
    </>
  )
}

export default SmartyStreetsInputForm
