import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import {
  MainContainer,
  Input,
  ProfileContainer,
  ProfilePicture,
  ProfileInfoContainer,
  ProfileName,
  Result,
  ProfileLocation
} from './styled/users-search-comps'
import { debounce } from 'lodash'
import { useCallback, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { ClickAwayListener, Grow, Paper, Popper, MenuList, MenuItem } from '@mui/material'
import getAvatar from '../../../../../utils/functions/getAvatar'
import getName from '../../../../../utils/functions/getName'
import { history } from '../../../../../history/history'
import { searchOrganizationMembers } from '../../../../../redux/thunks/search/searchOrganizationMembers'
import { getProfilesByUserIDs } from '../../../../../redux/thunks/profiles/getProfilesByUserIDs'

const useStyles = makeStyles(() =>
  createStyles({
    popper: {
      marginTop: '.1rem',
      width: '100%'
    },
    menuList: {
      padding: '.2rem 0',
      width: '100%'
    },
    menuItem: {
      padding: '0',
      height: 'auto',
      minHeight: 'auto',
      whiteSpace: 'normal'
    }
  })
)

const MembersSearch = ({ publicName }) => {
  const [query, setQuery] = useState('')
  /**
   * interface User {
      fname: string,
      lname:string,
      _id:string,
      email: string
    }[]
   * 
   */
  const [users, setUsers] = useState([])
  const [resultOpen, setResultOpen] = useState(false)
  const classes = useStyles()

  const dispatch = useDispatch()
  const inputRef = useRef(null)

  const debouncedFindUsers = useCallback(
    debounce((query) => {
      setResultOpen(false)
      dispatch(searchOrganizationMembers({ query, limit: 5, orgName: publicName })).then(
        (result) => {
          if (!result.error) {
            const userIds = result.data?.users?.map((i) => i?._id)

            dispatch(getProfilesByUserIDs({ userIds }))
              .then((profileResult) => {
                const profiles = Object.values(profileResult?.profiles)

                const users = result.data?.users?.map((user) => {
                  const profile = profiles?.find((profile) => profile?.userId === user?._id)

                  return {
                    ...profile,
                    ...user
                  }
                })
                users && setUsers(users)
              })
              .finally(() => {
                setResultOpen(true)
              })
          } else {
            setResultOpen(true)
            setUsers([])
          }
        }
      )
    }, 1000),
    []
  )

  const handleInputChange = (query) => {
    setQuery(query)
    if (query) {
      debouncedFindUsers(query)
    } else {
      setResultOpen(false)
    }
  }

  const handleGoToProfile = (e) => {
    e.preventDefault()
    e.stopPropagation()
    history.push(`/profile/${e.target.id}`)
  }

  const profiles = users?.map((profile) => {
    const { _id, avatar, personalAddress, privacySettings, documentVerified } = profile
    const showLocation =
      personalAddress.city !== '' && personalAddress.state !== '' && privacySettings.showLocation
    const verified = documentVerified ? 1 : 0

    return (
      <MenuItem key={_id} id={_id} className={classes.dropdownItem} onClick={handleGoToProfile}>
        <ProfileContainer id={_id}>
          <ProfilePicture
            id={_id}
            style={{
              backgroundImage: `url(${getAvatar(avatar)})`
            }}
            verified={verified}
          />

          <ProfileInfoContainer id={_id}>
            <ProfileName id={_id} style={!showLocation ? { marginTop: '.5rem' } : {}}>
              {getName(profile)}
            </ProfileName>

            {showLocation && (
              <ProfileLocation id={_id}>
                {[personalAddress.city, personalAddress.state].join(', ')}
              </ProfileLocation>
            )}
          </ProfileInfoContainer>
        </ProfileContainer>
      </MenuItem>
    )
  })

  return (
    <MainContainer>
      <span className="material-icons">search</span>
      <Input
        placeholder={`Search ${publicName} Members`}
        ref={inputRef}
        defaultValue={query}
        onChange={(e) => handleInputChange(e.target.value)}
        type="text"
      />
      <Popper
        open={resultOpen}
        anchorEl={inputRef.current}
        placement="bottom-start"
        transition
        disablePortal
        className={classes.popper}
        style={{ zIndex: 100 }}>
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} id="nav-menu-dropdown">
            <Paper>
              <ClickAwayListener onClickAway={() => setResultOpen(false)}>
                <MenuList className={classes.menuList}>
                  {profiles}

                  {profiles.length === 0 && (
                    <MenuItem className={classes.menuItem}>
                      <Result>
                        <span>
                          No results found for <b>{query}</b>
                        </span>
                      </Result>
                    </MenuItem>
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </MainContainer>
  )
}

export default MembersSearch
