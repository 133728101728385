import React, { useState, useEffect, useRef } from 'react'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
import { history } from '../../../history/history'
import { TagCardsContainer, TagCard } from './styled/tags-comps'
import { getResponsiveStyles } from './responsive-styles/tags'
import Button from '@mui/material/Button'
import useMediaQuery from '@mui/material/useMediaQuery'

function Tags(props) {
  const {
    existingTagIds,
    tagIds,
    setTagIds,
    setError,
    sortBy,
    handleSort,
    filter,
    handleFilter,
    hideTagRemove,
    hideTagAdd,
    enableFullClick,
    style,
    tagStyle,
    bgColor,
    color
  } = props

  const mainRef = useRef(null)
  const [didRecognizeRef, setDidRecognizeRef] = useState(false)
  const [componentWidth, setComponentWidth] = useState(null)
  const [responsiveStyles, setResponsiveStyles] = useState(getResponsiveStyles(componentWidth))
  const newExistingTagIds = existingTagIds || []
  const isMobile = useMediaQuery('(max-width:400px)')

  const handleSetComponentWidth = () => {
    const width = mainRef.current ? mainRef.current.clientWidth : window.innerWidth

    setComponentWidth(width)
  }

  useEffect(() => {
    window.addEventListener('resize', handleSetComponentWidth)

    return () => {
      window.removeEventListener('resize', handleSetComponentWidth)
    }
  }, [])

  useEffect(() => {
    if (!didRecognizeRef) {
      if (mainRef.current !== null && typeof mainRef.current !== 'undefined') {
        setComponentWidth(mainRef.current.clientWidth)
        setDidRecognizeRef(true)
      }
    }
  }, [mainRef, didRecognizeRef])

  useEffect(() => {
    setResponsiveStyles(getResponsiveStyles(componentWidth))
  }, [componentWidth])

  const handleRemoveTag = (e) => {
    if (typeof setTagIds !== 'undefined' && !hideTagRemove) {
      e.stopPropagation()
      e.preventDefault()

      const { id } = e.target
      const newTags = [...tagIds]

      _.remove(newTags, (tag) => tag === id)

      setTagIds(newTags)
    }
  }

  const handleSelectTag = (e) => {
    e.stopPropagation()
    e.preventDefault()

    const { id } = e.target

    if (_.includes(newExistingTagIds, id)) {
      return setError(`${id} has already been tagged.`)
    }

    setTagIds([...newExistingTagIds, id])

    if (setError) {
      setError('')
    }
  }

  const handleGoToTag = (tag) => {
    history.push(`/topic/${tag}`)
  }

  const tags = tagIds?.map((tag) => {
    return (
      <Button
        variant="contained"
        size={isMobile ? 'small' : 'medium'}
        color={'secondary'}
        sx={{
          margin: '0.25rem',
          bgcolor: 'secondary.light',
          color: 'text.primary',
          boxShadow: 3,
          '@media (max-width: 400px)': {
            fontSize: '0.9375rem',
            margin: '0.25rem',
            height: '2.25rem'
          }
        }}
        key={tag}
        id={tag}
        onClick={enableFullClick ? handleSelectTag : () => handleGoToTag(tag)}>
        {tag}
      </Button>

      // <TagCard
      //   key={tag}
      //   id={tag}
      //   display={responsiveStyles.tagCard.display}
      //   margin={responsiveStyles.tagCard.margin}
      //   enableFullClick={enableFullClick}
      //   style={tagStyle || {}}
      //   bgColor={bgColor}
      //   color={color}
      //   onClick={enableFullClick ? handleSelectTag : () => handleGoToTag(tag)}>
      //   {typeof setTagIds !== 'undefined' && !hideTagRemove && (
      //     <div id={tag} className="material-icons" onClick={handleRemoveTag}>
      //       close
      //     </div>
      //   )}

      //   {typeof setTagIds !== 'undefined' && !hideTagAdd && newExistingTagIds.length < 5 && (
      //     <div id={tag} className="material-icons" onClick={handleSelectTag}>
      //       add
      //     </div>
      //   )}

      //   {/* <p id={tag} onClick={enableFullClick ? handleSelectTag : () => handleGoToTag(tag)}> */}
      //   {tag}
      //   {/* </p> */}
      // </TagCard>
    )
  })

  if (sortBy) {
    tags.splice(
      0,
      0,
      <TagCard
        key={sortBy}
        id={sortBy}
        style={tagStyle || {}}
        display={'inline-block'}
        bgColor={bgColor}
        color={color}
        enableFullClick={true}>
        <div className="material-icons" onClick={() => handleSort('')}>
          close
        </div>
        {sortBy.charAt(0).toUpperCase() + sortBy.slice(1)}
      </TagCard>
    )
  }

  if (filter) {
    tags.splice(
      0,
      0,
      <TagCard
        key={filter}
        id={filter}
        style={tagStyle || {}}
        display={'inline-block'}
        bgColor={bgColor}
        color={color}
        enableFullClick={true}>
        <div className="material-icons" onClick={() => handleFilter('')}>
          close
        </div>

        {filter.charAt(0).toUpperCase() + filter.slice(1)}
      </TagCard>
    )
  }

  if (tags?.length === 0 || !tags) return null

  return (
    <TagCardsContainer
      ref={mainRef}
      style={style || {}}
      marginRight={responsiveStyles.tagCardsContainer.marginRight}>
      {tags}
    </TagCardsContainer>
  )
}

export default withRouter(Tags)
