//////////////////
// ACTION TYPES //
//////////////////

export const refreshPromiseActionTypes = {
  SET_REFRESH_PROMISE: 'SET_REFRESH_PROMISE',
  CLEAR_REFRESH_PROMISE: 'CLEAR_REFRESH_PROMISE'
}

/////////////
// ACTIONS //
/////////////

// REFRESH PROMISE
const set = promise => {
  return {
    type: refreshPromiseActionTypes.SET_REFRESH_PROMISE,
    promise
  }
}

const clear = () => {
  return {
    type: refreshPromiseActionTypes.CLEAR_REFRESH_PROMISE
  }
}

export const refreshPromiseActions = {
  set,
  clear
}
