import Validator from 'validator'

const UpdateLegalNameValidation = (legalName) => {
  if (legalName.length > 150) {
    return { error: 'Legal name must be less than 50 characters.' }
  } else {
    const splitStrArr = legalName?.split(/[\s-]+/)

    for (var i = 0; i < splitStrArr?.length; i++) {
      if (splitStrArr[i] === '') continue

      if (!Validator.isAlpha(splitStrArr[i])) {
        return {
          error: 'Legal name may only contain letters, spaces, or hyphens (-).'
        }
      }
    }
  }

  return { error: '' }
}

export default UpdateLegalNameValidation
