import styled from 'styled-components'

export const MainContainer = styled.div`
  margin-top: 1rem;
`

export const MemberContainer = styled.div`
  padding: 0.5rem 0.5rem 0.3rem 0.5rem;
  box-sizing: border-box;
  position: relative;

  &:not(:last-child) {
    border-bottom: 1px solid #eee;
  }
`

export const AvatarContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin-right: 1rem;
  border-radius: 50%;
`

export const ProfileName = styled.span`
  display: inline-block;
  margin-bottom: 0.2rem;
  vertical-align: middle;
  text-transform: capitalize;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.regular};
  font-weight: ${(props) => props.theme.typography.fontWeight.semiBold};
  line-height: 1rem;
  color: ${(props) => props.theme.typography.fontColor.bodyCopy};
`

export const ActionContainer = styled.div`
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: middle;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  cursor: pointer;

  & > .material-icons {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.2rem;
  }

  &:hover {
    background-color: #ddd;
  }

  &:active {
    background-color: #ccc;
  }
`
