import { isEmptyObject } from '../../utils/functions/isEmptyObject'
import { organizationPostActionTypes } from '../action-creators/organization-posts'

// FOR ORGANIZATION POSTS
const organizationsPostsReducer = (state = [], action) => {
  switch (action.type) {
    case organizationPostActionTypes.SET_ORGANIZATION_POST:
      const newOrganizationPost = !isEmptyObject(action.payload)
        ? [...state, { ...action.payload }]
        : state

      return newOrganizationPost

    case organizationPostActionTypes.CLEAR_ORGANIZATION_POSTS:
      return []

    default:
      return state
  }
}

export default organizationsPostsReducer
