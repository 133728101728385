import React from 'react'
/** Redux */
import { useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser'
/** Styled Components */
import {
  VerificationStatusContainer,
  VerificationStatusLabel,
  VerificationCurrentStatus
} from '../styled/verify-comps'

const useStyles = makeStyles((theme) => ({
  verifiedIcon: {
    fill: theme.palette.primary.main,
    marginRight: '0.25rem'
  }
}))

function VerificationStatusBanner() {
  const classes = useStyles()
  const { documentVerified } = useSelector((state) => state.currentProfile)
  const verificationStatus = documentVerified === true ? 1 : 0

  return (
    <VerificationStatusContainer>
      <VerificationStatusLabel>Verification Status: </VerificationStatusLabel>
      {documentVerified && <VerifiedUserIcon className={classes.verifiedIcon} />}
      <VerificationCurrentStatus verificationStatus={verificationStatus}>
        {documentVerified ? 'Verified' : 'Not Verified'}
      </VerificationCurrentStatus>
    </VerificationStatusContainer>
  )
}

export default VerificationStatusBanner
