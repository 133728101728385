import styled from 'styled-components'
import config from '../../../../../../config'

export const TabsContainer = styled.div`
  /** Windows 11 Fallback */
  /* display: block;
  position: relative;
  */

  /** Modern Browser Support */
  display: flex;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 0.5rem;
  overflow-x: scroll;

  /** Customize scrollbar in Chrome, Safari and Edge */
  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar-track {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    display: none;
  }

  /** Unfortunately, there is no way to customize the scrollbar in Firefox if that's what you're trying to do! */
`

export const Tab = styled.div`
  background: ${(props) => props.theme.palette.secondary.light};
  padding: ${(props) => (props.focused ? '0.85rem 2rem 0.85rem 2rem' : '0.65rem 1.25rem')};
  border-radius: 0.3rem;
  text-align: center;
  display: inline-block;
  margin-right: 0.25rem;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.small};
  font-weight: ${(props) => props.theme.typography.fontWeight.strong};
  color: ${(props) => props.theme.palette.text.primary};
  cursor: pointer;
  position: relative;

  &:hover {
    opacity: 0.5;
  }

  /** Mobile Focused Padding Dimensions */
  @media (max-width: ${config.UI.drawer_breakpoint.max}) {
    padding: ${(props) => (props.focused ? '0.7rem 1.25rem 0.7rem 1.25rem' : '0.5rem 1rem')};
    font-size: ${(props) => props.theme.typography.fontSizes.smaller}; /** 12px */
  }
`

export const TabContent = styled.span`
  margin-left: 0.5rem;
  line-height: ${(props) => props.theme.typography.lineHeight};
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.small};
  font-weight: ${(props) => props.theme.typography.fontWeight.strong};
  color: ${(props) => props.theme.palette.text.primary};

  /** Mobile Spacing */
  @media (max-width: ${config.UI.drawer_breakpoint.max}) {
    margin-left: 0.25rem;
    font-size: ${(props) => props.theme.typography.fontSizes.smaller}; /** 12px */
  }
`

export const MobileDetailsTabContainer = styled.div`
  /** Mobile Layout  */
  @media (min-width: ${config.UI.drawer_breakpoint.min}) {
    display: none;
  }
`
