import styled from 'styled-components'
// import config from '../../../../../../../../config.js'

export const MoreContainer = styled.p`
  font-size: 0;
`

export const MoreText = styled.span`
  display: inline-block;
  vertical-align: middle;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.regular};
  font-weight: ${(props) => props.theme.typography.fontWeight.semiBold};
  color: ${(props) => props.theme.palette.text.primary};
  cursor: pointer;
`

export const CaretIconContainer = styled.span`
  background-color: ${(props) => props.theme.palette.secondary.main};
  display: inline-block;
  width: 1.4rem;
  height: 1.4rem;
  margin: 0 0 0 0.2rem;
  vertical-align: middle;
  border-radius: 50%;
  color: ${(props) => props.theme.palette.text.secondary};
  position: relative;
`

export const ActionsContainer = styled.div`
  margin-top: 0.5rem;
`

export const Action = styled.div`
  text-decoration: underline;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.regular};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  color: ${(props) => props.theme.palette.secondary.main};
  cursor: pointer;
`
