import Button from '@mui/material/Button'
import DiscussIcon from '@mui/icons-material/Chat'
import { useCurrentUserIsUConn } from '../../../../hooks/useCurrentUserIsUConn'
import { useCurrentUserIsCiviltalkCertified } from '../../../../hooks/useCurrentUserIsCiviltalkCertified'

function DiscussButton({
  classes,
  currentProfile,
  status,
  isVerifiedUser,
  toggleCreateDiscussionForm
}) {
  // const isUConn = useCurrentUserIsUConn()
  // const isCivilTalkCertified = useCurrentUserIsCiviltalkCertified()

  return (
    <Button
      // disableElevation
      variant="contained"
      className={status.isCreatingDiscussion ? classes.actionButtonActive : classes.actionButton}
      onClick={() => toggleCreateDiscussionForm(!status.isCreatingDiscussion)}
      disabled={!isVerifiedUser(currentProfile)}>
      <DiscussIcon
        className={classes.actionIcon}
        role="img"
        style={!isVerifiedUser(currentProfile) ? { color: '#999' } : {}}
      />
      <span disabled={!isVerifiedUser(currentProfile)}>Discuss</span>
    </Button>
  )
}

export default DiscussButton
