import React from 'react'
import ImageEditor from './components/image-editor'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import CloseIcon from '@mui/icons-material/Close'
import makeStyles from '@mui/styles/makeStyles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { DialogFormContainer } from './styled/image-upload-comps'

const useStyles = makeStyles((theme) => ({
  closeIcon: {
    position: 'absolute',
    top: '0.35rem',
    right: '0.5rem',
    fill: theme.palette.text.primary
  }
}))

function ImageUpload(props) {
  const {
    open,
    handleClose,
    organizationId,
    postForm,
    postImage,
    setPostImage,
    postImagePreview,
    setPostImagePreview,
    isImageDifferent,
    setIsImageDifferent,
    setShowTakeImage
  } = props
  const classes = useStyles()
  const isMobile = useMediaQuery('(max-width: 799px)')

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={'xl'} fullScreen={isMobile}>
      <DialogFormContainer>
        {isMobile && <CloseIcon className={classes.closeIcon} onClick={handleClose} />}
        <DialogTitle sx={{ textAlign: 'center' }}>Max Image size of 3MB's</DialogTitle>
        <ImageEditor
          handleClose={handleClose}
          organizationId={organizationId}
          postForm={postForm}
          postImage={postImage}
          setPostImage={setPostImage}
          postImagePreview={postImagePreview}
          setPostImagePreview={setPostImagePreview}
          isImageDifferent={isImageDifferent}
          setIsImageDifferent={setIsImageDifferent}
          setShowTakeImage={setShowTakeImage}
        />
      </DialogFormContainer>
    </Dialog>
  )
}

export default ImageUpload
