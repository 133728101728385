import styled from 'styled-components'
import config from '../../../../../config.js'
import theme from '../../../../../res/theme.js'

export const OuterContainer = styled.div`
  text-align: ${({ textAlign }) => textAlign};
  margin-top: ${({ marginTop }) => marginTop};
`

export const InnerContainer = styled.div`
  display: inline-block;
  max-width: 85%;
  box-sizing: border-box;
  overflow: auto;
`

export const RebuttalUserImage = styled.div`
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: block;
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  float: ${({ float }) => float};
  margin: ${({ margin }) => margin};
  margin-top: 0.5rem;
  border-radius: 50%;
  cursor: pointer;
  border: 0.15rem solid;
  border-color: ${(props) => (props.verified ? props.theme.palette.primary.main : '#ccc')};
`

// export const RebuttalName = styled.p`
//   display: block;
//   width: 100%;
//   line-height: 1rem;
//   font-family: ${(props) => props.theme.typography.fontFamily};
//   font-size: 0.9rem;
//   font-weight: ${(props) => props.theme.typography.fontWeight.strong};
//   color: white;
//   cursor: pointer;

//   &:hover {
//     text-decoration: underline;
//   }
// `

export const RebuttalWrapper = styled.div`
  display: block;
  padding: 0.5rem 0.7rem 0.2rem 0.7rem;
  overflow: hidden;
  position: relative;
`

export const Rebuttal = styled.div`
  background-color: ${(props) =>
    props.mode === 'light' ? '#f2f2f2' : props.theme.palette.primary.light};
  display: block;
  min-width: 6rem;
  padding: 0.8rem 0.8rem 1.5rem 0.8rem;
  margin-bottom: 1rem;
  float: ${({ float }) => float};
  overflow: hidden;
  border-radius: 0.3rem;
  text-align: left;
  position: relative;
`

export const RebuttalDate = styled.p`
  display: block;
  line-height: 1.2rem;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: 0.8rem;
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  color: ${({ mode }) => (mode === 'light' ? 'rgba(20,20,20,.5)' : 'rgba(255,255,255,.8)')};
  position: absolute;
  bottom: 0.2rem;
  right: ${({ position }) => (position === 'right' ? 'auto' : '.8rem')};
  left: ${({ position }) => (position === 'left' ? 'auto' : '.8rem')};
`

export const LoadingMore = styled.div`
  text-align: center;
  margin: 0.5rem 0 1rem 0;
`

export const LoadMore = styled.p`
  margin-bottom: 0.5rem;
  text-align: center;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  color: ${({ mode }) => (mode === 'light' ? 'rgba(20,20,20,.7)' : 'rgba(255,255,255,.8)')};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`

export const StatusMessage = styled.p`
  display: block;
  margin-top: 0.5rem;
  text-align: center;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSize};
  font-weight: ${theme.typography.fontWeight.bold};
  color: ${theme.palette.text.primary};
`

// Create and Edit Rebuttal
export const FormContainer = styled.div`
  display: block;
  margin-top: 1rem;
`

export const TextareaProfilePicture = styled.div`
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: block;
  width: 2.3rem;
  height: 2.3rem;
  float: left;
  margin-right: 1.5rem;
  border-radius: 50%;
  cursor: pointer;
  border: 0.15rem solid;
  border-color: ${(props) => (props.verified === 1 ? props.theme.palette.primary.main : '#ccc')};
`

export const TextareaContainer = styled.div`
  display: block;
  overflow: hidden;
  border-radius: 0.2rem;
  position: relative;
`

export const Label = styled.label`
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  color: ${(props) => props.theme.typography.fontColor.bodyCopy};
  position: absolute;
  top: 0.5rem;
  left: 1rem;
`

export const StyledTextarea = styled.textarea`
  background-color: props.theme.palette.common.white;
  display: block;
  width: 100%;
  height: 8rem;
  padding: 0.7rem;
  box-sizing: border-box;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  color: ${(props) => props.theme.typography.fontColor.bodyCopy};
  border: 2px solid #ddd;
  border-radius: 0.2rem;
  overflow: auto;
  resize: none;

  &:focus,
  &:active {
    outline: none;
  }

  &:focus {
    background-color: rgba(255, 255, 255, 0.3);
    border: 2px solid props.theme.palette.primary.main;
  }
`

export const SubmitContainer = styled.div`
  display: block;
  width: 100%;
  margin-bottom: 0.3rem;
  text-align: right;
  font-size: 0;

  /** gutters for tablet view */
  @media (max-width: ${config.UI.drawer_breakpoint.max}) {
    text-align: center;
  }
`

export const EmotionCardsContainer = styled.div`
  display: flex;
  align-items: baseline;
  font-size: 0;
  padding: 0.4rem;
`

export const EmotionMessage = styled.p`
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.small};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  color: ${(props) => props.theme.palette.text.primary};
  margin-right: 0.5rem;
  vertical-align: middle;
  box-sizing: border-box;
`

export const EmotionCard = styled.div`
  display: block;
  padding: 0.4rem;
  padding-right: 0.4rem;
  vertical-align: middle;
  box-sizing: border-box;
  border-radius: 0.3rem;
  background-color: ${(props) => props.bgColor || props.theme.palette.common.white};
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.small};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  color: ${(props) => props.theme.palette.text.primary};
  ${'' /* white-space: nowrap; */}
  word-break: break-word;

  & > div {
    display: block;
    margin-top: 0.15rem;
    margin-right: 0.3rem;
    padding: 0.1rem;
    float: left;
    border-radius: 0.3rem;
    font-size: ${(props) => props.theme.typography.fontSizes.small};
    font-weight: ${(props) => props.theme.typography.fontWeight.medium};
    color: ${(props) => props.color || props.theme.palette.secondary.main};
    cursor: pointer;

    &:hover {
      background-color: ${(props) => props.color || props.theme.palette.secondary.main};
      color: ${(props) => props.bgColor || props.theme.palette.common.white};
    }
  }
`
