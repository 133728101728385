import _ from 'lodash'
import { loadedRebuttalActionTypes } from '../action-creators/loaded-rebuttals.js'
import nestedSort from '../../utils/functions/nestedSort.js'

const copyRebuttals = (rebuttals) => {
  return rebuttals.map((rebuttal) => ({ ...rebuttal }))
}

// FOR LOADED DISCUSSIONS
const loadedRebuttalsReducer = (state = [], action) => {
  switch (action.type) {
    case loadedRebuttalActionTypes.SET_LOADED_REBUTTALS:
      return nestedSort(copyRebuttals(action.rebuttals), 1, 'whenCreated')
    case loadedRebuttalActionTypes.ADD_LOADED_REBUTTALS:
      let newRebuttals1 = copyRebuttals(state)

      action.rebuttals.forEach((rebuttal) => {
        if (!_.some(newRebuttals1, { _id: rebuttal._id })) {
          newRebuttals1.push(rebuttal)
        }
      })

      return nestedSort(newRebuttals1, 1, 'whenCreated')
    case loadedRebuttalActionTypes.READ_LOADED_REBUTTAL:
      let newRebuttals2 = copyRebuttals(state)
      const rebuttalIndex2 = _.findIndex(newRebuttals2, { _id: action.rebuttalId })

      if (rebuttalIndex2 !== -1) {
        newRebuttals2[rebuttalIndex2].read = true
      } else {
        console.log('[REDUX ERROR]: ', 'Rebuttal not found.')
      }

      return newRebuttals2
    case loadedRebuttalActionTypes.EDIT_LOADED_REBUTTAL:
      let newRebuttals3 = copyRebuttals(state)
      const rebuttalIndex3 = _.findIndex(newRebuttals3, { _id: action.rebuttalId })

      if (rebuttalIndex3 !== -1) {
        newRebuttals3[rebuttalIndex3].rebuttal = action.rebuttal
        newRebuttals3[rebuttalIndex3].emotion = action.emotion
      } else {
        console.log('[REDUX ERROR]: ', 'Rebuttal not found.')
      }

      return newRebuttals3
    case loadedRebuttalActionTypes.CLEAR_LOADED_REBUTTALS:
      return []
    default:
      return state
  }
}

export default loadedRebuttalsReducer
