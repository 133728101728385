import React from 'react'
import { useSelector } from 'react-redux'
import { isLoaded, isEmpty } from 'react-redux-firebase'
import { useHistory } from 'react-router-dom'
import { useFirestoreConnect } from 'react-redux-firebase'
import Listener from './listeners/user-meta'
import DetailsSkeleton from './skeletons/details-skeleton'
import MyProfileOrganizations from './components/my-organizations/my-organizations'
import SponsoredBy from './components/sponsored-by'
import CollapsedContent from '../../../../partials/collapsed-content/collapsed-content'
import Tags from '../../../../partials/tags/tags'
import Rating from '@mui/material/Rating'
import Badges from './components/badges/badges'

import {
  AsideContainer,
  SectionContainer,
  ArticleContainer,
  ArticleHeader,
  TransparentInlineContainer,
  // TransparentFlexContainer,
  MobileInterestContainer,
  RedArticleHeader,
  GrayBackgroundContainer,
  Label,
  AboutCopyContainer,
  Digit,
  RatingDigit,
  FollowContent,
  // CourseCopy,
  FlexContainer,
  // TimesRatedLabel,
  RatingPointsLabel
} from './styled/details-comps'

function Details(props) {
  const history = useHistory()
  const { profile, isCurrentUser } = props
  const {
    rating,
    points,
    coreBeliefs,
    professionalExperience,
    educationalExperience,
    tagsOfKnowledge,
    tagsOfInterest,
    numDiscussionReq,
    numPublicTakes
  } = profile

  const authRef = useSelector(({ firebase }) => firebase.auth)
  const userMetaTokensRef = useSelector(
    ({
      firestore: {
        data: { userMeta }
      }
    }) => {
      return userMeta && userMeta[authRef.uid] && userMeta[authRef.uid].tokens
    }
  ) || { balance: 0, totalTransferedAmount: 0, totalDonatedAmount: 0 }

  //  Mount/Unmount Firestore listener to the redux store
  useFirestoreConnect([{ collection: 'userMeta', doc: profile.userId, storeAs: 'profilePoints' }])

  // Redux selector
  const balance = useSelector(
    ({ firestore: { data } }) => data?.profilePoints && data?.profilePoints?.points?.balance
  )

  if (!profile) {
    return (
      <AsideContainer>
        <DetailsSkeleton />
      </AsideContainer>
    )
  }

  return (
    <AsideContainer>
      {isLoaded(authRef) && !isEmpty(authRef) && <Listener auth={authRef} />}

      <SectionContainer>
        <ArticleContainer>
          <ArticleHeader style={{ marginTop: '0' }}>About Me:</ArticleHeader>
          <MyProfileOrganizations profileUserId={profile.userId} />
          <SponsoredBy />
          <Badges profile={profile} userId={profile.userId} />
          <ArticleContainer>
            <RedArticleHeader>EQ</RedArticleHeader>
            <GrayBackgroundContainer>
              <TransparentInlineContainer width="100%">
                <FlexContainer>
                  <RatingPointsLabel style={{ marginRight: '0.5rem' }}>EQ Score:</RatingPointsLabel>
                  {rating.score && (
                    <>
                      <Rating
                        name="EQ Rating:"
                        value={rating.score}
                        precision={0.1}
                        readOnly
                        sx={{ color: 'text.primary' }}
                      />
                      <RatingDigit style={{ marginLeft: '0.5rem' }}>
                        {`(${rating.score})`}{' '}
                      </RatingDigit>
                    </>
                  )}
                </FlexContainer>
                <FlexContainer>
                  <RatingPointsLabel style={{ marginRight: '0.5rem' }}>Rated:</RatingPointsLabel>
                  <RatingDigit style={{ marginLeft: '0.5rem' }}>
                    {rating.count ? rating.count : 0} <span>times</span>
                  </RatingDigit>
                </FlexContainer>

                <FlexContainer>
                  <RatingPointsLabel style={{ marginRight: '0.5rem' }}>Points:</RatingPointsLabel>
                  <RatingDigit style={{ marginLeft: '0.5rem' }}>{points}</RatingDigit>
                </FlexContainer>
              </TransparentInlineContainer>
            </GrayBackgroundContainer>
          </ArticleContainer>
          <ArticleContainer>
            <RedArticleHeader>Statistics</RedArticleHeader>
            <GrayBackgroundContainer>
              <TransparentInlineContainer width="100%">
                <FlexContainer>
                  <RatingPointsLabel style={{ marginRight: '0.5rem' }}>
                    Public Posts:
                  </RatingPointsLabel>
                  <RatingDigit style={{ marginLeft: '0.5rem' }}>{numPublicTakes}</RatingDigit>
                </FlexContainer>
              </TransparentInlineContainer>

              <FlexContainer>
                <RatingPointsLabel style={{ marginRight: '0.5rem' }}>
                  Discussions:
                </RatingPointsLabel>
                <RatingDigit style={{ marginLeft: '0.5rem' }}>{numDiscussionReq}</RatingDigit>
              </FlexContainer>
            </GrayBackgroundContainer>
          </ArticleContainer>
          {coreBeliefs && (
            <ArticleContainer>
              <RedArticleHeader>Core Beliefs</RedArticleHeader>

              <GrayBackgroundContainer>
                <AboutCopyContainer>
                  <CollapsedContent
                    content={coreBeliefs}
                    rows={5}
                    color={'#333'}
                    fontWeight={400}
                    fontSize={'0.9375rem'}
                    lineHeight={'1.4rem'}
                    collapsible={true}
                  />
                </AboutCopyContainer>
              </GrayBackgroundContainer>
            </ArticleContainer>
          )}
          {professionalExperience && (
            <ArticleContainer>
              <RedArticleHeader>Professional Experience</RedArticleHeader>
              <GrayBackgroundContainer>
                <AboutCopyContainer>
                  <CollapsedContent
                    content={professionalExperience}
                    rows={5}
                    color={'#333'}
                    fontWeight={400}
                    fontSize={'0.9375rem'}
                    lineHeight={'1.4rem'}
                    collapsible={true}
                  />
                </AboutCopyContainer>
              </GrayBackgroundContainer>
            </ArticleContainer>
          )}
          {educationalExperience && (
            <ArticleContainer>
              <RedArticleHeader>Educational Experience</RedArticleHeader>
              <GrayBackgroundContainer>
                <AboutCopyContainer>
                  <CollapsedContent
                    content={educationalExperience}
                    rows={5}
                    color={'#333'}
                    fontWeight={400}
                    fontSize={'0.9375rem'}
                    lineHeight={'1.4rem'}
                    collapsible={true}
                  />
                </AboutCopyContainer>
              </GrayBackgroundContainer>
            </ArticleContainer>
          )}
        </ArticleContainer>

        {(tagsOfKnowledge.length > 0 || tagsOfInterest.length > 0) && (
          <MobileInterestContainer>
            <ArticleContainer style={{ padding: '2rem 0' }}>
              {tagsOfKnowledge.length > 0 && (
                <>
                  <ArticleHeader style={{ marginTop: '0' }}>Topics of Knowledge:</ArticleHeader>
                  <Tags tagIds={tagsOfKnowledge} />
                </>
              )}

              {tagsOfInterest.length > 0 && (
                <>
                  <ArticleHeader>Topics of Interest:</ArticleHeader>
                  <Tags tagIds={tagsOfInterest} />
                </>
              )}
            </ArticleContainer>
          </MobileInterestContainer>
        )}
      </SectionContainer>

      <SectionContainer>
        <ArticleContainer>
          <ArticleHeader style={{ marginTop: '0' }}>Member Connections:</ArticleHeader>
          <GrayBackgroundContainer>
            <TransparentInlineContainer>
              <FollowContent
                onClick={() => history.push(`/connections/${profile.userId}/followers`)}>
                {profile.followers}
              </FollowContent>

              <Label style={{ marginRight: '0' }}>Followers</Label>
            </TransparentInlineContainer>

            <TransparentInlineContainer>
              <FollowContent
                onClick={() => history.push(`/connections/${profile.userId}/following`)}>
                {profile.following}
              </FollowContent>

              <Label style={{ marginRight: '0' }}>Following</Label>
            </TransparentInlineContainer>
          </GrayBackgroundContainer>
        </ArticleContainer>
      </SectionContainer>

      {/* {isCurrentUser && (
        <SectionContainer>
          <ArticleContainer>
            <ArticleHeader style={{ marginTop: '0' }}>Available Tokens:</ArticleHeader>
            <GrayBackgroundContainer>
              <TransparentInlineContainer width="30%">
                <Digit>{userMetaTokensRef.balance}</Digit>
                <Label style={{ marginRight: '0' }}>Balance</Label>
              </TransparentInlineContainer>

              <TransparentInlineContainer width="30%" style={{ marginLeft: '5%' }}>
                <Digit>{userMetaTokensRef.totalTransferedAmount}</Digit>
                <Label style={{ marginRight: '0' }}>Total Awarded</Label>
              </TransparentInlineContainer>

              <TransparentInlineContainer width="30%" style={{ marginLeft: '5%' }}>
                <Digit>{userMetaTokensRef.totalDonatedAmount}</Digit>
                <Label style={{ marginRight: '0' }}>Total Donated</Label>
              </TransparentInlineContainer>
            </GrayBackgroundContainer>
          </ArticleContainer>
        </SectionContainer>
      )} */}
    </AsideContainer>
  )
}

export default Details
