const copyVerificationAttempt = (obj) => {
  if (!obj || Object.keys(obj).length === 0) {
    return {}
  }

  return {
    ...obj,
    stripe: { ...obj.stripe },
  }
}

export default copyVerificationAttempt
