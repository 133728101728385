import { useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import {
  Button,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuList,
  MenuItem,
  Dialog
} from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { MoreHoriz, Create, Close } from '@mui/icons-material'
import { NavItemContainer, NavItem } from '../styled/prompts-comps'
import { PostFormContainer } from '../../prompts/components/start-conversation/styled/start-conversation-comps'
import PromptForm from '../../../../../partials/prompt-form/prompt-form'

const useStyles = makeStyles((theme) =>
  createStyles({
    dropdownButton: {
      borderRadius: '.2rem',
      minHeight: '1.5rem',
      height: '1.5rem',
      minWidth: '2.5rem',
      width: '2.5rem',
      position: 'absolute',
      top: '1rem',
      right: '1rem',
      boxShadow: 'none',
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.white,
      '&:hover': {
        backgroundColor: theme.palette.secondary.light,
        boxShadow: 'none'
      },
      '& svg': {
        // color: 'white',
        fontSize: '1.8rem',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translateX(-50%) translateY(-50%)'
      },
      closeIcon: {
        position: 'absolute',
        top: '0.35rem',
        right: '0.5rem'
      }
    },
    menuList: {
      padding: '.4rem 0',
      width: '12rem'
    },
    dropdownItem: {
      padding: '.4rem 1rem'
    },
    divider: {
      margin: '.4rem 0'
    },
    dropdownIcon: {
      width: '1rem',
      height: 'auto',
      marginRight: '.7rem',
      marginTop: '1px',
      verticalAlign: 'middle'
    }
  })
)

const DropdownMenu = (props) => {
  const { prompt, prompts, setPrompts } = props
  const classes = useStyles()
  const isMobile = useMediaQuery('(max-width: 799px)')
  const ref = useRef(null)
  const [open, setOpen] = useState(false)
  const [openEditForm, setOpenEditForm] = useState(false)
  const currentUser = useSelector(({ currentUser }) => currentUser)
  const isPromptOwner = currentUser.id === prompt.userId

  const handleClose = () => {
    setOpenEditForm(false)
  }

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        className={classes.dropdownButton}
        ref={ref}
        aria-owns={open ? 'menu-dropdown' : undefined}
        aria-haspopup="true"
        onClick={() => setOpen(!open)}>
        <MoreHoriz fontSize="large" />
      </Button>

      <Popper
        open={open}
        anchorEl={ref.current}
        placement="bottom-end"
        transition
        style={{ zIndex: 100 }}>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            id="menu-dropdown"
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
            }}>
            <Paper>
              <ClickAwayListener onClickAway={() => setOpen(false)}>
                <MenuList className={classes.menuList}>
                  {isPromptOwner && (
                    <MenuItem
                      className={classes.dropdownItem}
                      onClick={() => setOpenEditForm(true)}>
                      <NavItemContainer>
                        <Create className={classes.dropdownIcon} />
                        <NavItem>Edit</NavItem>
                      </NavItemContainer>
                    </MenuItem>
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>

      <Dialog open={openEditForm} onClose={handleClose} maxWidth={'xl'} fullScreen={isMobile}>
        <PostFormContainer>
          {isMobile && <Close onClick={handleClose} className={classes.closeIcon} />}
          <PromptForm
            setOpen={setOpenEditForm}
            courseId={prompt.courseId}
            prompts={prompts}
            setPrompts={setPrompts}
            promptToEdit={prompt}
            isEditing={true}
            handleClose={handleClose}
          />
        </PostFormContainer>
      </Dialog>
    </>
  )
}

export default DropdownMenu
