import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
// import { isLoaded, isEmpty } from 'react-redux-firebase'
import _ from 'lodash'
import getNotificationMeta from '../../../utils/functions/getNotificationMeta'
import { actionResponseActions } from '../../../redux/action-creators/action-response'
import nestedSort from '../../../utils/functions/nestedSort'

function NotificationListener() {
  const dispatch = useDispatch()
  const [notifications, setNotifications] = useState([])
  const [loadedNotifications, setLoadedNotifications] = useState(false)
  const notificationProfiles = useSelector((state) => state.notificationProfiles)
  const notificationsRef = useSelector(({ firestore: { ordered } }) => {
    return ordered.myNotifications
  })

  // Hook to check if new notification came in
  useEffect(() => {
    if (notifications.length === 0 && !loadedNotifications) {
      if (notificationsRef && notificationsRef.length > 0) {
        setLoadedNotifications(true)
        setNotifications(notificationsRef)
      }
    } else if (notifications.length > 0) {
      // Sort existing notifications to find latest notification
      const sortedNotifications = nestedSort(notifications, -1, 'whenCreated.seconds')

      // See if any any new notifications just came in
      const newNotifications = _.filter(notificationsRef, (notification) => {
        return notification.whenCreated.seconds > sortedNotifications[0].whenCreated.seconds
      })

      // If new notifications just came in, create a toaster for it!
      // Set notifications to new notifications
      if (newNotifications.length > 0) {
        console.log(newNotifications)

        const notification = newNotifications[0]
        const notificationProfile = _.find(notificationProfiles, {
          userId: notification.payload.userId
        })

        if (notificationProfile) {
          const notificationMeta = getNotificationMeta(notification, notificationProfile)

          dispatch(actionResponseActions.createActionResponse(notificationMeta.title))

          setNotifications(notificationsRef)
        }
      }
    }
  }, [notificationsRef, notificationProfiles, notifications, loadedNotifications, dispatch])

  return <></>
}

export default NotificationListener
