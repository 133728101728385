import React from 'react'
import MuiTableRow from '@mui/material/TableRow'
import MuiTableCell from '@mui/material/TableCell'
import MuiSkeleton from '@mui/material/Skeleton'
import withStyles from '@mui/styles/withStyles';

const TableRow = withStyles((theme) => ({
  root: {
    display: 'flex',
    verticalAlign: 'inherit',
    position: 'relative',
    cursor: 'pointer',
    padding: '0.75rem 0',
    '&:last-child > td': {
      borderBottom: 'none'
    },
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      padding: '0.75rem 0'
    }
  }
}))(MuiTableRow)

const AvatarTableCell = withStyles((theme) => ({
  root: {
    flex: '0 0 auto',
    whiteSpace: 'nowrap',
    padding: 0,
    alignItems: 'center',
    lineHeight: '1.4rem',
    borderTop: 'none',
    borderBottom: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      borderTop: 'none',
      borderBottom: 'none!important'
    }
  }
}))(MuiTableCell)

const NameTableCell = withStyles((theme) => ({
  root: {
    display: 'flex',
    flex: '0 0 auto',
    flexBasis: '9.5rem',
    maxWidth: '9.5rem',
    paddingRight: '1rem',
    alignItems: 'center',
    lineHeight: '1.4rem',
    borderTop: 'none',
    borderBottom: 'none',
    [theme.breakpoints.down('md')]: {
      borderBottom: 'none!important'
    }
  }
}))(MuiTableCell)

const SubjectTableCell = withStyles((theme) => ({
  root: {
    display: 'flex',
    flex: '1 1 auto',
    flexWrap: 'wrap',
    height: 'auto',
    minWidth: 0,
    borderTop: 'none',
    borderBottom: 'none',
    paddingRight: '1rem',
    [theme.breakpoints.down('md')]: {
      display: 'block',

      borderBottom: 'none!important'
    }
  }
}))(MuiTableCell)

const TimestampTableCell = withStyles((theme) => ({
  root: {
    display: 'flex',
    flex: '0 0 auto',
    justifyContent: 'flex-end',
    minWidth: '5rem',
    borderTop: 'none',
    borderBottom: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'block',
      borderBottom: 'none!important'
    }
  }
}))(MuiTableCell)

const Skeleton = withStyles((theme) => ({
  root: {
    // margin: '0.5rem',
    width: '100%'
  }
}))(MuiSkeleton)

const AvatarSkeleton = withStyles((theme) => ({
  root: {
    display: 'inline-block',
    verticalAlign: 'middle',
    minWidth: '3rem',
    width: '3rem',
    minHeight: '3rem',
    height: '3rem',
    borderRadius: '50%'
  }
}))(MuiSkeleton)

const animation = 'pulse'

function ThumbnailSkeleton() {
  const Skeletons = []

  for (let i = 10; i >= 0; i--) {
    Skeletons.push(
      <TableRow key={i}>
        <AvatarTableCell>
          <AvatarSkeleton animation={animation} variant="circle" />
        </AvatarTableCell>
        <NameTableCell>
          <Skeleton animation={animation} variant="text" />
        </NameTableCell>
        <SubjectTableCell>
          <Skeleton animation={animation} variant="text" />
        </SubjectTableCell>
        <TimestampTableCell>
          <Skeleton animation={animation} variant="text" />
        </TimestampTableCell>
      </TableRow>
    )
  }

  return Skeletons
}

export default ThumbnailSkeleton
