import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'
// import { analytics } from "../../../firebase/firebase"
import { rejectVerificationAttempt } from '../../../../redux/thunks/verification-attempts/rejectVerificationAttempt'
import { acceptVerificationAttempt } from '../../../../redux/thunks/verification-attempts/acceptVerificationAttempt'
import ConfirmationDialog from '../../../dialogs/dialog-confirmation'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import { Button, CircularProgress } from '@mui/material'
import { Subheader, ButtonsContainer } from '../styled/verify-comps'

const useStyles = makeStyles((theme) =>
  createStyles({
    secondaryButton: {
      display: 'inline-block',
      width: '15rem',
      height: '3.5rem',
      marginRight: '2rem',
      borderRadius: '.3rem',
      fontSize: '0.9375rem', // 15px
      textTransform: 'capitalize',
      color: theme.palette.text.secondary,
      backgroundColor: theme.palette.secondary.dark,
      '&:hover': {
        backgroundColor: theme.palette.secondary.light
      },
      '@media (max-width: 650px)': {
        display: 'block',
        width: '16rem',
        height: '3rem',
        margin: '0 auto',
        fontSize: '0.875rem' // 14px
      },
      '@media (max-width: 400px)': {
        width: '100%'
      }
    },
    primaryButton: {
      display: 'inline-block',
      width: '15rem',
      height: '3.5rem',
      borderRadius: '.3rem',
      fontSize: '0.9375rem', // 15px
      textTransform: 'capitalize',
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.light
      },
      '@media (max-width: 650px)': {
        display: 'block',
        width: '16rem',
        height: '3rem',
        margin: '1rem auto 0 auto',
        fontSize: '0.875rem' // 14px
      },
      '@media (max-width: 400px)': {
        width: '100%'
      }
    },
    circularProgress: {
      display: 'block',
      margin: '.2rem auto 0 auto',
      width: '18px !important',
      height: '18px !important',
      '& svg': {
        color: 'white'
      }
    }
  })
)

function Verified(props) {
  const {
    setStep,
    setStatus,
    verificationAttempt,
    setVerificationAttempt,
    verificationAttemptId,
    setVerificationAttemptId,
    setLastError,
    setSuccess
  } = props
  const [processing, setProcessing] = useState(false)
  const [retryDialog, setRetryDialog] = useState(false)
  const classes = useStyles()
  const dispatch = useDispatch()

  const handleRejectVerificationAttempt = () => {
    setProcessing(true)

    dispatch(rejectVerificationAttempt({ verificationAttemptId })).then((data) => {
      setProcessing(false)

      if (!data.error) {
        console.log('[SUCCESS]: ', 'Successfully rejected verification attempt!')

        setVerificationAttempt({})
        setVerificationAttemptId('')
        setStatus(null)
        setLastError('')
      } else {
        console.log('[FAIL]: ', 'Failed to reject verification attempt.')
      }
    })
  }

  const handleAcceptVerificationAttempt = () => {
    setProcessing(true)

    dispatch(acceptVerificationAttempt({ verificationAttemptId })).then((data) => {
      setProcessing(false)

      if (!data.error) {
        console.log('[SUCCESS]: ', 'Successfully accepted verification attempt!')

        setVerificationAttempt({})
        setVerificationAttemptId('')
        setStatus(null)
        setLastError('')
        setSuccess(true)
        setStep(2)
      } else {
        console.log('[FAIL]: ', 'Failed to accept verification attempt.')
      }
    })
  }

  return (
    <>
      <ConfirmationDialog
        open={retryDialog}
        primaryAction={handleRejectVerificationAttempt}
        secondaryAction={() => setRetryDialog(false)}
        title="Retry identity verification?"
        body={
          <>
            Please confirm that you would like to retry your identity verification. If so, all
            information gathered during your current verification attempt will be redacted.
            {/* 
            <br />
            <p>
              <b>{`Verification attempts remaining: ${tries}`}</b>
            </p> 
            */}
          </>
        }
      />

      <Subheader>
        Thank you for taking the time to verify your identity. You have been successfully verified.
        Please confirm that your name as shown is correct as this will be your identity on
        CivilTalk. You can change your first and middle name at any time in <b>My Account</b>.
      </Subheader>

      <Subheader>
        <b>Full Name:</b>&nbsp;
        {`${verificationAttempt.fname} ${verificationAttempt.lname}`}
      </Subheader>

      <ButtonsContainer>
        <Button
          variant="contained"
          color="secondary"
          className={classes.secondaryButton}
          onClick={() => setRetryDialog(true)}>
          Something's Wrong
        </Button>

        <Button
          variant="contained"
          color="primary"
          className={classes.primaryButton}
          onClick={handleAcceptVerificationAttempt}
          disabled={processing}>
          {processing ? (
            <CircularProgress color="primary" className={classes.circularProgress} />
          ) : (
            'Confirm Identity'
          )}
        </Button>
      </ButtonsContainer>
    </>
  )
}

export default withRouter(Verified)
