//////////////////
// ACTION TYPES //
//////////////////
export const accoladeActionTypes = {
  SET_ACCOLADES: 'SET_ACCOLADES',
  CLEAR_ACCOLADES: 'CLEAR_ACCOLADES'
}

///////////////
// ACCOLADES //
///////////////

const set = (accolades) => {
  return {
    type: accoladeActionTypes.SET_ACCOLADES,
    accolades
  }
}

const clear = () => {
  return {
    type: accoladeActionTypes.CLEAR_ACCOLADES
  }
}

export const accoladesActions = {
  set,
  clear
}
