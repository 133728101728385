import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getAccoladesByUserID } from '../../../../../../../../redux/thunks/accolades/getAccoladesByUserID'

function useLoadBadges({ profile, userId }) {
  const [userAccolades, setUserAccolades] = useState([])
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getAccoladesByUserID({ userId })).then((data) => {
      if (data.error) {
        console.log('[FAIL]: ', 'Failed to retrieve accolades by user ID.')
      }

      console.log('[SUCCESS]: ', 'Successfully retrieved accolades by user ID!')

      const thisProfilesAccolades = data.accolades.filter((accolade) => accolade.userId === userId)
      /* badge is synonyms with accolade */
      const certifiedBadge = thisProfilesAccolades.filter(
        (badge) => badge.type === 'uconn-civiltalk-certified'
      )
      const restOfThisProfilesBadges = thisProfilesAccolades.filter(
        (badge) => badge.type !== 'uconn-civiltalk-certified'
      )

      // always display certifiedBadge first
      let orderedBadges = [...certifiedBadge]

      const isInstructor =
        profile.userId === '636bd97a13ce1d0017c1a051' ||
        profile.userId === '6320958cf2b6c4001755c617' ||
        profile.userId === '63dbd4787f7341002b4b7c54' ||
        profile.userId === '5c71a0503a121c0017c7b3f4'

      const instructorBadge = {
        _id: '606be7669d370d07b2299999',
        type: 'instructor-badge',
        desc: 'Instructor',
        userId: userId
      }

      if (isInstructor) {
        orderedBadges = [...orderedBadges, instructorBadge]
      }
      // TODO: Temporary removing certified badge
      // orderedBadges = [...orderedBadges, ...restOfThisProfilesBadges]
      orderedBadges = [...restOfThisProfilesBadges]


      setUserAccolades([...orderedBadges])
    })
  }, [])

  return userAccolades
}

export default useLoadBadges
