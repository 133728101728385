//////////////////
// ACTION TYPES //
//////////////////

export const loaderActionTypes = {
  	START_LOADER: 	  'START_LOADER',
  	STOP_LOADER: 	    'STOP_LOADER',
  	CLEAR_LOADER: 	  'CLEAR_LOADER'
}

/////////////
// ACTIONS //
/////////////

// LOADER
const startLoader = () => {
	return { 
  		type: loaderActionTypes.START_LOADER,
	}
}

const stopLoader = () => {
	return { 
  		type: loaderActionTypes.STOP_LOADER,
	}
}

export const loaderActions = {
	startLoader,
	stopLoader
}