import { notificationOrganizationActionTypes } from '../action-creators/notification-organizations.js'
import _ from 'lodash'

const copyOrganizations = (organizations) => {
  return organizations.map((organization) => copyOrganization(organization))
}

const copyOrganization = (organization) => ({
  ...organization,
  phone: { ...organization.phone },
  address: { ...organization.address },
  tagsIds: [...organization.tagIds]
})

// FOR LOADED PROFILES
const notificationOrganizationsReducer = (state = [], action) => {
  switch (action.type) {
    case notificationOrganizationActionTypes.SET_NOTIFICATION_ORGANIZATIONS:
      return copyOrganizations(action.organizations)
    case notificationOrganizationActionTypes.ADD_NOTIFICATION_ORGANIZATIONS:
      let newOrganizations1 = copyOrganizations(state)

      for (var i = 0; i < action.organizations.length; i++) {
        let exists = _.some(newOrganizations1, { _id: action.organizations[i]._id })

        if (!exists) {
          newOrganizations1.push(action.organizations[i])
        }
      }

      return newOrganizations1
    case notificationOrganizationActionTypes.CLEAR_NOTIFICATION_ORGANIZATIONS:
      return []
    default:
      return state
  }
}

export default notificationOrganizationsReducer
