import { useSelector } from 'react-redux'
import Logo from './components/logo/logo'
import StaticLinks from './components/static-links/static-links'
import Notifications from './components/notifications-new/notifications'
import MobileDrawer from './components/mobile-drawer/mobile-drawer'
import ProfileIcon from './components/profile-icon/profile-icon'
import Discussions from './components/discussions/discussions'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import { useMediaQuery } from '@mui/material'

function Header() {
  const isAuthenticated = useSelector(({ isAuthenticated }) => isAuthenticated)
  const isMobile = useMediaQuery('(max-width: 599px)')

  return (
    <AppBar
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: isAuthenticated ? 'space-between' : 'flex-end',
        alignItems: 'center',
        background: '#fff'
      }}>
      <Logo />
      <Toolbar variant={isMobile ? 'dense' : 'regular'} sx={{ paddingLeft: 0 }}>
        {isAuthenticated ? (
          <nav
            id="primary-navigation"
            aria-labelledby="primary-navigation"
            style={{ display: 'flex' }}>
            <ProfileIcon />
            <Discussions />
            <Notifications />
            <MobileDrawer />
          </nav>
        ) : (
          <StaticLinks />
        )}
      </Toolbar>
    </AppBar>
  )
}

export default Header
