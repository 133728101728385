import config from '../../../config'
import { authenticatedFetch } from '../../../utils/functions/authenticatedFetch'
/*
 * METHOD TO MARK NOTIFICATION AS READ
 */
export const markNotificationAsRead = (params) => {
  let responseCode = ''
  const method = 'PUT'
  const url = `${config.baseURL}/api/notification/${params.notificationId}`

  const headers = {
    'Content-Type': 'application/json'
  }

  return (dispatch) => {
    console.log('Sending mark notification as read request to Civiltalk')

    return authenticatedFetch(url, {
      method,
      headers,
      credentials: 'include'
    })
      .then((response) => {
        responseCode = response.status

        return response.json()
      })
      .then((data) => {
        if (responseCode === 200) {
          return {
            error: false,
            code: responseCode,
            message: data.message
          }
        }

        console.log(data.message)

        return {
          error: false,
          code: responseCode,
          message: data.message
        }
      })
      .catch((error) => {
        console.log('[ERROR]: markNotificationAsRead --> ', error)

        return {
          error: true,
          code: '',
          message: error
        }
      })
  }
}
