import { useState, useMemo } from 'react'
import seasons from '../assets/seasons'
import subjects from '../assets/subjects'
import years from '../assets/years'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import { styled } from '@mui/material/styles'

function Select({
  label,
  id,
  name,
  value,
  handleChange,
  error,
  setError,
  errorsObj,
  setErrorsObj,
  helperText,
  setHelperText,
  validationFunc
}) {
  // const [value, setValue] = useState('')

  // const handleInputChange = (e) => {
  //   handleChange(e.target)
  // }

  // Validate the users input when they leave the input field
  const handleBlur = () => {
    if (validationFunc) {
      const validationErrors = validationFunc(value)

      const validationError = Object.values(validationErrors)[0]
      if (validationError) {
        setError(true)
        setErrorsObj(validationErrors)
        setHelperText(validationError)
      } else {
        setError(false)
        setErrorsObj({})
        setHelperText('')
      }
    }
  }

  const items = useMemo(() => {
    switch (id) {
      case 'subject':
        return subjects
      case 'season':
        return seasons
      case 'year':
        return years
      default:
        return []
    }
  }, [id])

  return (
    <MuiTextField
      id={id}
      label={label}
      select
      color="secondary"
      name={name}
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
      error={!!error}
      helperText={helperText}>
      {items.map((item, index) => (
        <MuiMenuItem key={item} value={item} disabled={name === 'subject' && index === 0}>
          {item}
        </MuiMenuItem>
      ))}
    </MuiTextField>
  )
}

export default Select

// ***************************** //
// *** MUI Styled Components *** //
// ***************************** //

const MuiTextField = styled(TextField)(({ theme }) => ({
  background: theme.palette.background.gray,
  color: theme.palette.text.primary,
  minWidth: '18rem',
  '& .MuiFormLabel': {
    color: theme.palette.text.primary
  },
  '& .MuiInputLabel': {
    color: theme.palette.text.primary
  },
  '& .MuiFormHelperText-root': {
    color: theme.palette.text.primary
  },
  '& label': {
    color: theme.palette.text.primary
  },
  '& label.Mui-focused': {
    color: theme.palette.secondary.main
  },
  '& .MuiInput-underline:after': {
    // borderBottomColor: '#B2BAC2'
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      // borderColor: '#E0E3E7'
    },
    '&:hover fieldset': {
      // borderColor: '#B2BAC2'
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.secondary.main
    }
  }
}))

const MuiMenuItem = styled(MenuItem)(({ theme }) => ({
  color: theme.palette.text.primary
}))
