import { loadedTakeActionTypes } from '../action-creators/loaded-takes.js'
import _ from 'lodash'
import nestedSort from '../../utils/functions/nestedSort.js'

const copyTakes = (takes) => {
  return takes.map((take) => {
    return {
      ...take,
      tagIds: take.tagIds ? [...take.tagIds] : [],
      location: { ...take.location },
      visibleTo: { ...take.visibleTo },
      votes: take.votes.length === 0 ? [] : take.votes.map((vote) => ({ ...vote })),
      courseId: take.courseId
    }
  })
}

const copyTake = (take) => {
  return {
    ...take,
    tagIds: take.tagIds ? [...take.tagIds] : [],
    location: { ...take.location },
    visibleTo: { ...take.visibleTo },
    votes: take.votes.length === 0 ? [] : take.votes.map((vote) => ({ ...vote })),
    courseId: take.courseId
  }
}

// FOR LOADED TAKES
const loadedTakesReducer = (state = [], action) => {
  switch (action.type) {
    case loadedTakeActionTypes.SET_LOADED_TAKES:
      switch (action.sortBy) {
        case 'popularity':
          return nestedSort(copyTakes(action.takes), -1, 'numVotes', 'whenCreated')
        default:
          return nestedSort(copyTakes(action.takes), -1, 'whenCreated')
      }
    case loadedTakeActionTypes.ADD_LOADED_TAKES:
      var newTakes1 = copyTakes(state)

      for (var i = 0; i < action.takes.length; i++) {
        // this is checking that passed in take exists
        let takeExists = _.some(newTakes1, { _id: action.takes[i]._id })

        if (!takeExists) {
          if (typeof action.takes[i].votes === 'undefined') {
            newTakes1.push(
              copyTake({
                ...action.takes[i],
                votes: []
              })
            )
          } else {
            newTakes1.push(copyTake(action.takes[i]))
          }
        }
      }

      switch (action.sortBy) {
        case 'popularity':
          return nestedSort(newTakes1, -1, 'numVotes', 'whenCreated')
        default:
          return nestedSort(newTakes1, -1, 'whenCreated')
      }
    case loadedTakeActionTypes.ADD_LOADED_TAKE_VOTE:
      var newTakes2 = copyTakes(state)
      let takeIndex2 = _.findIndex(newTakes2, { _id: action.vote.takeId })

      if (takeIndex2 !== -1) {
        newTakes2[takeIndex2].votes.push(action.vote)
        newTakes2[takeIndex2].numVotes += 1
      } else {
        console.log('[REDUX ERROR]: ', 'Take not found.')
      }

      return newTakes2
    case loadedTakeActionTypes.REMOVE_LOADED_TAKE_VOTE:
      var newTakes3 = copyTakes(state)

      for (var j = 0; j < newTakes3.length; j++) {
        let voteIndex3 = _.findIndex(newTakes3[j].votes, {
          _id: action.voteId
        })

        if (voteIndex3 !== -1) {
          newTakes3[j].votes.splice(voteIndex3, 1)
          newTakes3[j].numVotes -= 1

          break
        }
      }

      return newTakes3
    case loadedTakeActionTypes.HIDE_LOADED_TAKE:
      var newTakes4 = copyTakes(state)
      let takeIndex4 = _.findIndex(newTakes4, { _id: action.takeId })

      if (takeIndex4 !== -1) {
        newTakes4[takeIndex4].hidden = true
      } else {
        console.log('[REDUX ERROR]: ', 'Take not found.')
      }

      return newTakes4
    case loadedTakeActionTypes.SHOW_LOADED_TAKE:
      var newTakes5 = copyTakes(state)
      let takeIndex5 = _.findIndex(newTakes5, { _id: action.takeId })

      if (takeIndex5 !== -1) {
        newTakes5[takeIndex5].hidden = false
      } else {
        console.log('[REDUX ERROR]: ', 'Take not found.')
      }

      return newTakes5
    case loadedTakeActionTypes.ARCHIVE_LOADED_TAKE:
      var newTakes6 = copyTakes(state)
      let takeIndex6 = _.findIndex(newTakes6, { _id: action.takeId })

      if (takeIndex6 !== -1) {
        newTakes6[takeIndex6].archived = true
      } else {
        console.log('[REDUX ERROR]: ', 'Take not found.')
      }

      return newTakes6
    case loadedTakeActionTypes.EDIT_LOADED_TAKE:
      var newTakes9 = copyTakes(state)
      let takeIndex9 = _.findIndex(newTakes9, { _id: action.takeId })

      if (takeIndex9 !== -1) {
        newTakes9[takeIndex9].take = action.take
        newTakes9[takeIndex9].image = action.image
        newTakes9[takeIndex9].emotion = action.emotion
        newTakes9[takeIndex9].tagIds = [...action.tagIds]
        newTakes9[takeIndex9].location = { ...action.location }
        newTakes9[takeIndex9].visibleTo = { ...action.visibleTo }
        newTakes9[takeIndex9].courseId = action.courseId
      } else {
        console.log('[REDUX ERROR]: ', 'Take not found.')
      }

      return newTakes9
    case loadedTakeActionTypes.UPDATE_LOADED_TAKE_VIEW_COUNT:
      const newTakes10 = copyTakes(state)
      const takeIndex10 = _.findIndex(newTakes10, { _id: action.takeId })

      if (takeIndex10 !== -1) {
        const updatedViewCount = newTakes10[takeIndex10].viewCount + 1

        newTakes10[takeIndex10].viewCount = updatedViewCount
      } else {
        console.log('[REDUX ERROR]: ', 'Take not found.')
      }

      return newTakes10
    case loadedTakeActionTypes.CLEAR_LOADED_TAKES:
      return []
    default:
      return state
  }
}

export default loadedTakesReducer
