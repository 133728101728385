import withStyles from '@mui/styles/withStyles'
import MuiDialog from '@mui/material/Dialog'
import MuiButton from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'

export const Dialog = withStyles((theme) => ({
  root: {},
  container: {},
  paper: {
    background: '#eaeaea'
  }
}))(MuiDialog)

export const MuiAppBar = withStyles((theme) => ({
  root: {}
}))(AppBar)

export const MuiToolbar = withStyles((theme) => ({
  root: {}
}))(Toolbar)

export const MuiIconButton = withStyles((theme) => ({
  root: {}
}))(IconButton)

export const MuiTypography = withStyles((theme) => ({
  root: {}
}))(Typography)

export const MuiCloseIcon = withStyles((theme) => ({
  root: {}
}))(CloseIcon)

export const HomeButton = withStyles((theme) => ({
  root: {
    display: 'inline-block',
    width: '12rem',
    margin: '0.5rem'
  },
  [theme.breakpoints.down(undefined)]: {
    root: {
      display: 'block',
      marginBottom: '1rem'
    }
  }
}))(MuiButton)

export const ProfileButton = withStyles((theme) => ({
  root: {
    display: 'inline-block',
    width: '12rem',
    margin: '0.5rem'
  },
  [theme.breakpoints.down(undefined)]: {
    root: {
      display: 'block'
    }
  }
}))(MuiButton)

export const MuiCheckbox = withStyles((theme) => ({
  root: {}
}))(Checkbox)
