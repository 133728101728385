import fontFamily from './fonts.js'

const theme = {
  typography: {
    fontFamily: fontFamily,
    fontSize: '1rem',
    fontSizeUC: '.8rem',
    fontSizes: {
      smaller: '0.75rem', // 12px
      small: '0.875rem', // 14px
      short: '0.9375rem', // 15px
      regular: '1rem', // 16px
      tall: '1.0625rem', // 17px
      medium: '1.125rem', // 18px
      large: '1.1875rem', // 19px
      larger: '1.25rem', // 20px
      extraLarge: '1.35rem', // just short of...22px
      footerLogo: '3.8rem'
    },
    fontWeight: {
      light: 300,
      regular: 400, // body copy
      medium: 500,
      semiBold: 600,
      bold: 700 // titles
    },
    fontColor: {
      bodyCopy: '#333333',
      headerCopy: '#6b6b6b',
      actionBarCopy: '#444'
    },
    lineHeight: '1.2rem'
  },
  palette: {
    primary: {
      main: '#edb801',
      light: '#fed234' // use for hover state // gold
    },
    secondary: {
      main: '#6e8c76', // dark olive
      light: '#abcab3' // light olive
    },
    common: {
      white: '#ffffff',
      black: '#000000'
    },
    link: {
      primary: '#4063AF',
      secondary: '#555'
    },
    text: {
      primary: '#333333', // coal
      secondary: '#ffffff' // white
      // disabled: '#' TODO: decide if we want a custom color here
    },
    background: {
      lightGray: '#f2f2f2', // apple systemGray6
      midGray: '#e5e5e5',
      mediumGray: '#dcdddd',
      heavyGray: '#cccccc',
      darkGray: 'rgba(99, 99, 102, 1)' // apple systemGray2
    },
    Love: {
      light: 'rgba(245, 237, 166, 1)',
      dark: 'rgba(245, 237, 166, 1)'
    },
    Joy: {
      light: 'rgba(168, 203, 165, 1)',
      dark: 'rgba(168, 203, 165, 1)'
    },
    Fear: {
      light: 'rgba(210, 145, 113, 1)',
      dark: 'rgba(210, 145, 113, 1)'
    },
    Anger: {
      light: 'rgba(195,133, 169, 1)',
      dark: 'rgba(195,133, 169, 1)'
    },
    Sadness: {
      light: 'rgba(157, 169, 189, 1)',
      dark: 'rgba(157, 169, 189, 1)'
    },
    Surprise: {
      light: 'rgba(156, 196, 197, 1)',
      dark: 'rgba(156, 196, 197, 1)'
    }
  },
  borderRadius: '.3rem',
  boxShadow: '0px 7px 5px -2px rgba(100, 100, 100, 0.2)'
}

export default theme
