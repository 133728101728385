import { useDebugValue, useState, Dispatch, SetStateAction } from 'react';

interface SubjectErrors {
  [key: string]: string;
}

function useSubjectErrorsObjState(initialValue: SubjectErrors): [SubjectErrors, Dispatch<SetStateAction<SubjectErrors>>] {
  const [subjectErrorsObj, setSubjectErrorsObj] = useState<SubjectErrors>(initialValue);
  useDebugValue(subjectErrorsObj ? subjectErrorsObj : initialValue);
  return [subjectErrorsObj, setSubjectErrorsObj];
}

export default useSubjectErrorsObjState;