const UpdateEmailValidation = (state, numFields) => {
    // Code
    if (state.code.length !== numFields) {
        return {
          error: 'Please enter all 6 digits.',
        field: 'code'
        }
    }
  
    return {
        error: '',
      field: ''
    }
  }
  
  export default UpdateEmailValidation