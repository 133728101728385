// Calculate Fee for Stripe
// P(charge) = P(total) + F(fixed) / 1 - F(percent)
// Variable --> P(fixed): Total fixed fee after VAT/GST is included
// Variable --> P(percent): Percent fee after VAT/GST is included
const getStripePaymentIntentFeeSummary = (paymentGoal) => {
  const parsedPaymentGoal = parseFloat(paymentGoal)

  if (isNaN(parsedPaymentGoal)) {
    throw new Error('Invalid param <paymentGoal> in function getStripeFeeSummary.')
  }

  const fixedFee = 0.3
  const percentFee = 0.029
  const charge = (parsedPaymentGoal + fixedFee) / (1 - percentFee)
  const chargeRounded = charge.toFixed(2)
  const chargeRoundedCents = (parseFloat(chargeRounded) * 100).toFixed()
  const adjustedFee = parseFloat(chargeRounded) - parsedPaymentGoal
  const adjustedFeeRounded = adjustedFee.toFixed(2)

  return {
    fixedFee,
    percentFee,
    charge: chargeRounded,
    chargeCents: chargeRoundedCents,
    fee: adjustedFeeRounded
  }
}

export default getStripePaymentIntentFeeSummary
