const load = (setIsLoading, actions) => {
  const _getActivePolls = () => {
    return actions.getActivePolls()
  }
  
  return _getActivePolls()
    .then((data1) => {
      if (data1.error) {
        console.log('Failed to retrieve active polls.')
      }

      setIsLoading(false)

      console.log('[DEBUG]: ', 'Successfully retrieved active polls.')
    })
    .catch((error) => {
      console.log('[ERROR]: ', error)
    })
}

export default load
