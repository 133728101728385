import styled from 'styled-components'
// import theme from '../../../../res/theme.js'
// import config from '../../../../config.js'

export const MainContainer = styled.div`
  display: block;
  width: 100%;
  max-width: 80rem;
  padding: ${({ padding }) => padding};
  box-sizing: border-box;
  margin: ${({ marginTop }) => marginTop} auto 0 auto;
  font-size: 0;
`

export const LeftContainer = styled.div`
  display: inline-block;
  width: ${({ width }) => width};
  box-sizing: border-box;
  vertical-align: top;
`

export const RightContainer = styled.aside`
  display: ${({ display }) => display};
  width: 39.5%;
  margin: 0 0 0 1.5%;
  vertical-align: top;
`
