import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getOrganizationByMemberID } from '../../../../redux/thunks/organizations/getOrganizationByMemberId'
import { getProfilesByUserIDs } from '../../../../redux/thunks/profiles/getProfilesByUserIDs'
import { create } from 'lodash'

const useLoadMembers = (organizationId, createData) => {
  const [members, setMembers] = useState([])
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getOrganizationByMemberID({ id: organizationId, limit: 10, page: 1 })).then(
      (result) => {
        if (result.error) {
          return
        }
        const userIds = result.data?.members?.map((i) => i?.userId)

        dispatch(getProfilesByUserIDs({ userIds })).then((profileResult) => {
          if (profileResult.error) {
            return
          }
          const profiles = Object.values(profileResult?.profiles)

          const users = result.data?.members?.map((user) => {
            const profile = profiles?.find((profile) => profile?.userId === user?.userId)

            return {
              ...profile,
              ...user
            }
          })

          const membersData = users.map((user) => {
            // console.log('user: ', user)
            const { _id, fname, lname, email } = user

            return createData(_id, fname, lname, email)
          })

          setMembers(membersData)
        })
      }
    )
  }, [dispatch, organizationId])

  return [members, setMembers]
}

export default useLoadMembers
