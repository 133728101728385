import styled from 'styled-components'
import config from '../../../../config'

export const OuterContainer = styled.div`
  background-color: rgba(70, 70, 70, 0.2);
  overflow-y: auto;
  position: fixed;
  top: 5.5rem;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
`

export const InnerContainer = styled.div`
  background-color: #ffffff;
  width: 100%;
  max-width: 40rem;
  overflow-y: auto;
  box-sizing: border-box;
  border-radius: 0.3rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  /** Mobile View */
  @media (max-width: ${config.UI.drawer_breakpoint.max}) {
    max-width: none;
    border-radius: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: none;
  }
`

export const CloseContainer = styled.div`
  font-size: 1.25rem;
  font-weight: ${({ theme }) => theme.typography.fontWeight.bold};
  cursor: pointer;
  position: absolute;
  top: 0.1rem;
  right: 0.1rem;

  /** Mobile View */
  @media (max-width: ${config.UI.drawer_breakpoint.max}) {
    font-size: 1.5rem;
    top: 0.75rem;
    right: 0.75rem;
  }
`

export const BalanceContainer = styled.div`
  background-color: #eee;
  padding: 0.8rem 2rem;
  border-top-right-radius: 0.3rem;
  border-top-left-radius: 0.3rem;

  /** Mobile View */
  @media (max-width: ${config.UI.drawer_breakpoint.max}) {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }
`

export const BalanceLabel = styled.div`
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.small};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  color: ${(props) => props.theme.typography.fontColor.headerCopy};
`

export const BalanceValue = styled.div`
  margin-top: 0.1rem;

  & > img {
    display: inline-block;
    height: 1.3rem;
    margin-top: .2rem;
    margin-right: .5rem;
    vertical-align: middle;
  }

  & > span {
    display: inline-block;
    vertical-align: middle;
    font-family: ${(props) => props.theme.typography.fontFamily};
    font-family: ${(props) => props.theme.typography.fontFamily};
    font-size: ${(props) => props.theme.typography.fontSizes.extraLarge};
    font-weight: ${(props) => props.theme.typography.fontWeight.bold};
    color: ${(props) => props.theme.palette.secondary.main};
  }
`

export const FlowContainer = styled.div`
  padding: 1.5rem 2rem 2rem 2rem;
`
