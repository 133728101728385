//////////////////
// ACTION TYPES //
//////////////////

export const connectOrganizationsActionTypes = {
  SET_CONNECT_ORGANIZATIONS: 'SET_CONNECT_ORGANIZATIONS',
  ADD_CONNECT_ORGANIZATIONS: 'ADD_CONNECT_ORGANIZATIONS',
  CLEAR_CONNECT_ORGANIZATIONS: 'CLEAR_CONNECT_ORGANIZATIONS',
  SET_HAS_MORE_ORGANIZATIONS: 'SET_HAS_MORE_ORGANIZATIONS'
}

/////////////
// ACTIONS //
/////////////

// CATEGORIES
const setOrganizationsList = (payload) => {
  return {
    type: connectOrganizationsActionTypes.SET_CONNECT_ORGANIZATIONS,
    payload
  }
}
const setFetchMoreOrganizations = (payload) => {
  return {
    type: connectOrganizationsActionTypes.SET_HAS_MORE_ORGANIZATIONS,
    payload
  }
}
const addOrganizationsList = (payload) => {
  return {
    type: connectOrganizationsActionTypes.ADD_CONNECT_ORGANIZATIONS,
    payload
  }
}

const clearOrganizationsList = () => {
  return {
    type: connectOrganizationsActionTypes.CLEAR_CONNECT_ORGANIZATIONS
  }
}

export const connectOrganizationsAction = {
  setOrganizationsList,
  clearOrganizationsList,
  addOrganizationsList,
  setFetchMoreOrganizations
}
