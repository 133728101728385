import loadGeneral from './load-general.js'
import loadOrganization from './load-organization.js'
import loadUserHost from './load-user-host.js'
import loadUserGuest from './load-user-guest.js'
import loadUserHidden from './load-user-hidden.js'
import loadCourseFeed from './load-course-feed.js'

function loadHandler(props, state, setState, loadActions) {
  const {
    variant,
    isLoadingComponent,
    userId,
    organizationId,
    afterDate,
    sortKey,
    filterKey,
    tags,
    isQuestion,
    excludeCurrentUser,
    currentProfile,
    courseId,
    promptId
  } = props

  switch (variant) {
    case 'general':
      return loadGeneral(
        isLoadingComponent,
        afterDate,
        isQuestion,
        excludeCurrentUser,
        tags,
        sortKey,
        filterKey,
        currentProfile,
        state,
        setState,
        loadActions
      )

    case 'organization':
      return loadOrganization(
        isLoadingComponent,
        organizationId,
        afterDate,
        isQuestion,
        excludeCurrentUser,
        tags,
        sortKey,
        filterKey,
        currentProfile,
        state,
        setState,
        loadActions
      )

    case 'course-feed':
      return loadCourseFeed(
        isLoadingComponent,
        courseId,
        promptId,
        afterDate,
        isQuestion,
        excludeCurrentUser,
        tags,
        sortKey,
        filterKey,
        currentProfile,
        state,
        setState,
        loadActions
      )

    case 'user-host':
      return loadUserHost(
        isLoadingComponent,
        userId,
        afterDate,
        isQuestion,
        tags,
        currentProfile,
        state,
        setState,
        loadActions
      )

    case 'user-guest':
      return loadUserGuest(
        isLoadingComponent,
        userId,
        afterDate,
        // isQuestion,
        // tags,
        currentProfile,
        state,
        setState,
        loadActions
      )

    case 'user-hidden':
      return loadUserHidden(
        isLoadingComponent,
        afterDate,
        // isQuestion,
        // tags,
        currentProfile,
        state,
        setState,
        loadActions
      )

    default:
      break
  }
}

export default loadHandler
