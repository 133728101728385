import { firebaseAuthActions } from '../../action-creators/firebase_auth.js'

export const firebaseLogin = (params) => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase()

    return firebase
      .auth()
      .signInWithCustomToken(params.firebase_token)
      .then((data) => {
        console.log('[SUCCESS], Logged into Firebase!')

        dispatch(firebaseAuthActions.logIn(true))

        return {
          error: false,
          message: 'Successfully logged into firebase!'
        }
      })
      .catch((err) => {
        const { code, message } = err

        console.log('[ERROR]: Failed to log into firebase.', code, message)

        dispatch(firebaseAuthActions.error(err))

        return {
          error: true,
          message
        }
      })
  }
}
