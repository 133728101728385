import { myCoursesActionTypes } from '../action-creators/my-courses'

// Adds all courses for a user to the store
function myCoursesReducer(state = [], action) {
  switch (action.type) {
    case myCoursesActionTypes.SET_MY_COURSES:
      return [...action.courses]
    case myCoursesActionTypes.CLEAR_MY_COURSES:
      return []
    default:
      return state
  }
}

export default myCoursesReducer
