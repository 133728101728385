import React from 'react'
import { connect } from 'react-redux'
import { mobileDrawerActions } from '../../../../redux/action-creators/mobile-drawer'
import DrawerPrivate from './drawer-private'
import { IconButton, Drawer } from '@mui/material'
import { NavIconsContainer } from './styled/mobile-drawer-comps'
import config from '../../../../config'
import theme from '../../../../res/theme'

const styles = {
  menuIconActive: {
    backgroundColor: theme.palette.secondary.light,
    width: '3rem',
    height: '3rem',
    color: theme.palette.text.secondary,
    '& .material-icons': {
      fontSize: '2.4rem'
    },
    '@media (max-width: 450px)': {
      width: '2.5rem',
      height: '2.5rem',
      '& .material-icons': {
        fontSize: '2rem'
      }
    }
  },
  menuIconInactive: {
    width: '3rem',
    height: '3rem',
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.light
    },
    '& .material-icons': {
      fontSize: '2.4rem'
    },
    '@media (max-width: 450px)': {
      width: '2.5rem',
      height: '2.5rem',
      '& .material-icons': {
        fontSize: '2rem'
      }
    }
  },
  drawer: {
    // top: `${config.UI.header.height} !important`,
    // // [`@media (min-width:${config.UI.drawer_breakpoint.min})`]: {
    // //   display: 'none'
    // // },
    // '& .MuiBackdrop-root': {
    //   position: 'absolute'
    // },
    // '& .MuiDrawer-paperAnchorRight': {
    //   position: 'absolute',
    //   '@media (max-width:450px)': {
    //     left: 0
    //   }
    // }
  }
}

type Props = {
  isAuthenticated: boolean
  mobileDrawer: boolean
  setMobileDrawer: Function
  // currentProfile: { hasPerformedSetup: boolean }
}

/**
 * @function MobileDrawer
 * - Mobile presentation component
 * - Displays dropdown of action bar items depending on authentication status
 */

function MobileDrawer(props: Props) {
  const {
    // isAuthenticated,
    setMobileDrawer,
    mobileDrawer
    // currentProfile
  } = props
  const toggleDrawer = (event: any, newOpen: boolean) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return
    }

    setMobileDrawer(newOpen)
  }

  return (
    <React.Fragment>
      <NavIconsContainer>
        <IconButton
          sx={mobileDrawer ? styles.menuIconActive : styles.menuIconInactive}
          onClick={(e) => toggleDrawer(e, mobileDrawer ? false : true)}>
          <span className="material-icons" role="img">
            menu
          </span>
        </IconButton>
      </NavIconsContainer>

      <Drawer
        anchor={'right'}
        open={mobileDrawer}
        sx={styles.drawer}
        // onClose={(e) => toggleDrawer(e, false)}
      >
        {/* {isAuthenticated ? <DrawerPrivate /> : <DrawerPublic />} */}
        <DrawerPrivate />
      </Drawer>
    </React.Fragment>
  )
}

const mapDispatchToProps = (dispatch: any) => ({
  setMobileDrawer: (open: boolean) => {
    return dispatch(mobileDrawerActions.set(open))
  }
})

const mapStateToProps = (state: any) => ({
  isAuthenticated: state.isAuthenticated,
  mobileDrawer: state.mobileDrawer,
  currentProfile: state.currentProfile
})

export default connect(mapStateToProps, mapDispatchToProps)(MobileDrawer)
