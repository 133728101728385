import styled from 'styled-components'
import config from '../../../../../../../../config.js'

export const Header = styled.p`
  color: ${(props) => props.theme.typography.fontColor.bodyCopy};
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-weight: ${(props) => props.theme.typography.fontWeight.bold};
  font-size: ${(props) => props.theme.typography.fontSizes.tall};

  @media (max-width: ${config.UI.drawer_breakpoint.max}) {
    text-align: center;
  }
`

export const Subheader = styled.p`
  margin-top: 1rem;
  color: ${(props) => props.theme.typography.fontColor.bodyCopy};
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  font-size: ${(props) => props.theme.typography.fontSizes.regular};

  @media (max-width: ${config.UI.drawer_breakpoint.max}) {
    text-align: center;
  }
`

export const AddressContainer = styled.div`
  margin-top: 1rem;
  background-color: #eee;
  padding: 1rem;
  border-radius: 0.3rem;
`

// export const AddressHeader = styled.div`
//   margin-bottom: 0.5rem;
//   color: ${theme.typography.fontColor.bodyCopy};
//   font-family: ${theme.typography.fontFamily};
//   font-weight: ${theme.typography.fontWeight.bold};
//   font-size: ${theme.typography.fontSizes.regular};

//   @media (max-width: ${config.UI.drawer_breakpoint.max}) {
//     text-align: center;
//   }
// `

export const AddressLine = styled.p`
  color: ${(props) => props.theme.typography.fontColor.bodyCopy};
  line-height: 1.5rem;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  font-size: ${(props) => props.theme.typography.fontSizes.regular};

  @media (max-width: ${config.UI.drawer_breakpoint.max}) {
    text-align: center;
  }
`
