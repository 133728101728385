import FormError from '../../../../../alerts/form-error'
import { TryAgainButton, ConfirmButton } from './mui-buttons'
import {
  SectionContainer,
  Header,
  Form,
  ItemContainer,
  AddressLabel,
  AddressCopy,
  ButtonContainer
} from '../styled/location-form-comps'

function ConfirmSmartyStreetsResults(props) {
  const { city, county, state, zipCode, handleBack, handleStep3, error } = props

  return (
    <>
      <Header>Here's what we found.</Header>

      <FormError message={error} style={{ marginTop: '.5rem', marginBottom: '0' }} />
      <Form onSubmit={handleStep3}>
        <SectionContainer>
          <ItemContainer>
            <AddressLabel>City:</AddressLabel>
            <AddressCopy>{city}</AddressCopy>
          </ItemContainer>
          <ItemContainer>
            <AddressLabel>County:</AddressLabel>
            <AddressCopy>{county}</AddressCopy>
          </ItemContainer>
          <ItemContainer>
            <AddressLabel>State:</AddressLabel>
            <AddressCopy>{state}</AddressCopy>
          </ItemContainer>
          <ItemContainer>
            <AddressLabel>Zip Code:</AddressLabel>
            <AddressCopy>{zipCode}</AddressCopy>
          </ItemContainer>
        </SectionContainer>
        <ButtonContainer>
          <TryAgainButton variant="contained" onClick={handleBack}>
            Try again
          </TryAgainButton>
          <ConfirmButton color="primary" variant="contained" onClick={handleStep3}>
            Yes, this is correct
          </ConfirmButton>
        </ButtonContainer>
      </Form>
    </>
  )
}

export default ConfirmSmartyStreetsResults
