import styled from 'styled-components'

export const MainContainer = styled.div`
  margin-bottom: 1.5rem;
  border-radius: 0.3rem;
  /* box-shadow: 0px 5px 5px -2px rgba(0, 0, 0, 0.2); */
`

export const Cards = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`
export const HeadingPrimary = styled.p`
  margin-bottom: 2rem;
  line-height: 2.2rem;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: 1.4rem;
  font-weight: ${(props) => props.theme.typography.fontWeight.bold};
  color: ${(props) => props.theme.palette.text.primary};

  @media screen and (max-width: 1200px) {
    font-size: 1.2rem;
  }
`
export const Loading = styled.div`
  text-align: center;
`

export const NoMore = styled.p`
  font-size: 1.3rem;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-weight: ${(props) => props.theme.typography.fontWeight.semiBold};
  width: 100%;
  text-align: center;
`
