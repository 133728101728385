import config from '../../../config.js'
import { authenticatedFetch } from '../../../utils/functions/authenticatedFetch.js'
import { suggestedProfilesActions } from '../../action-creators/suggested-profiles.js'

/*
 * METHOD TO GET SUGGESTED CONNECTIONS
 */
export const getSuggestedConnections = (params) => {
  let responseCode = ''
  const method = 'GET'
  let url = `${config.baseURL}/api/profile/suggested/?limit=${config.loadLimits.suggestedConnections}`
  const headers = {
    'Content-Type': 'application/json'
  }

  if (params.disableFromInterests) {
    url += `&disableFromInterests=${params.disableFromInterests}`
  }

  if (params.disableFromInsights) {
    url += `&disableFromInsights=${params.disableFromInsights}`
  }

  if (params.disableRandomSuggestions) {
    url += `&disableRandomSuggestions=${params.disableRandomSuggestions}`
  }

  if (params.tagIds && params.tagIds.length > 0) {
    url += `&tagIds=${JSON.stringify(params.tagIds)}`
  }

  // Thunk middleware knows how to handle functions.
  // It passes the dispatch method as an argument to the function,
  // thus making it able to dispatch actions itself.
  return (dispatch) => {
    console.log('Sending get suggested connections request to CivilTalk')

    // Return the fetch so react components calling 'store.dispatch()' can use 'then()'
    return authenticatedFetch(url, {
      method,
      headers,
      credentials: 'include'
    })
      .then((response) => {
        responseCode = response.status

        return response.json()
      })
      .then((data) => {
        if (responseCode === 200) {
          dispatch(suggestedProfilesActions.set(data.profiles))

          return {
            error: false,
            code: responseCode,
            message: data.message,
            userIds: data.userIds,
            profiles: data.profiles
          }
        } else {
          console.log(data.message)

          return {
            error: true,
            code: responseCode,
            message: data.message
          }
        }
      })
      .catch((error) => {
        console.log(error)

        return {
          error: true,
          code: '',
          message: error
        }
      })
  }
}
