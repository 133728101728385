import { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import some from 'lodash/some'
/** REDUX THUNKS */
import { checkFollowingOrganization } from '../../../../../../redux/thunks/follows/checkFollowingOrganization'
import { createOrganizationFollow } from '../../../../../../redux/thunks/follows/createOrganizationFollow'
import { deleteFollowByOrganizationID } from '../../../../../../redux/thunks/follows/deleteFollowByOrganizationID'
/** UTILS */
import getOrganizationImage from '../../../../../../utils/functions/getOrganizationImage'
import makeStyles from '@mui/styles/makeStyles'
import ButtonBase from '@mui/material/ButtonBase'
/** Other */
import LazyLoad from 'react-lazyload'
import { Image, Placeholder } from '../styled/org-header-comps'
import theme from '../../../../../../res/theme'

const useStyles = makeStyles((muiTheme) => ({
  root: {
    display: 'inline-block',
    position: 'relative',
    width: '12.4rem',
    height: '12.4rem',
    verticalAlign: 'middle',
    marginRight: '2.5rem',
    borderRadius: '50%',
    [muiTheme.breakpoints.down('md')]: {
      display: 'block',
      margin: '0 auto'
    },
    [muiTheme.breakpoints.down('sm')]: {
      width: '10rem',
      height: '10rem'
    },
    '&:hover': {
      '& $hoverCopy': {
        opacity: 1
      },
      '& $hoverLabel': {
        opacity: 0.85
      }
    }
  },
  focusVisible: {},
  hoverLabel: {
    position: 'absolute',
    top: '4px',
    left: '4px',
    opacity: 0,
    background: '#777',
    width: '12rem',
    height: '12rem',
    borderRadius: '50%',
    zIndex: '999',
    [muiTheme.breakpoints.down('sm')]: {
      width: '10rem',
      height: '10rem'
    }
  },
  hoverCopy: {
    position: 'absolute',
    left: '52%',
    top: '52%',
    transform: 'translateX(-50%) translateY(-50%)',
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.common.white,
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: '1.3rem',
    zIndex: 1003,
    opacity: 0
  },
  verifiedIcon: {
    fill: theme.palette.common.white,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translateX(-50%) translateY(-50%)',
    fontSize: '1.5rem'
  },
  addIcon: {
    zIndex: 1000,
    color: theme.palette.secondary.main,
    position: 'absolute',
    top: '50%',
    left: '53%',
    fontSize: '5rem',
    transform: `translateX(-50%) translateY(-50%)`
  }
}))

function Avatar(props) {
  const { image, organization, hasEditImagePermissions, handleOpen } = props
  const { _id, publicName } = organization
  const organizationId = _id
  // const [isFollowing, setIsFollowing] = useState(false)
  const refPlaceholder = useRef()
  const classes = useStyles()
  const dispatch = useDispatch()
  const currentUser = useSelector(({ currentUser }) => currentUser)
  const follows = useSelector(({ follows }) => follows)
  const isFollowing = some(follows, { organizationId: _id, follower: currentUser.id })

  useEffect(() => {
    if (organizationId !== '') {
      dispatch(checkFollowingOrganization({ organizationId })).then((data) => {
        // if a follow exists it will be in the redux follows now
      })
    }
  }, [organization, isFollowing, dispatch, organizationId])

  const removePlaceholder = () => {
    refPlaceholder.current.remove()
  }

  const handleFollowing = () => {
    if (!isFollowing) {
      dispatch(
        createOrganizationFollow({
          organizationId,
          name: publicName
        })
      ).then((data) => {
        if (!data.error) {
          console.log('[SUCCESS]: ', 'Successfully followed organization!')

          // setIsFollowing(true)
        } else {
          console.log('[FAIL]: ', 'Failed to follow organization.')
        }
      })
    } else {
      dispatch(
        deleteFollowByOrganizationID({
          organizationId: _id,
          currUserId: currentUser.id,
          name: publicName
        })
      ).then((data) => {
        if (!data.error) {
          console.log('[SUCCESS]: ', 'Successfully unfollowed organization!')

          // setIsFollowing(false)
        } else {
          console.log('[FAIL]: ', 'Failed to unfollow organization.')
        }
      })
    }
  }

  const followLabel = isFollowing ? 'Unfollow' : 'Follow'

  return (
    <ButtonBase
      focusRipple
      focusVisibleClassName={classes.focusVisible}
      className={classes.root}
      onClick={hasEditImagePermissions ? handleOpen : handleFollowing}>
      <>
        <div className={classes.hoverLabel} />
        <span className={classes.hoverCopy}>
          {hasEditImagePermissions ? 'Add / Edit Photo' : followLabel}
        </span>
      </>

      <Placeholder ref={refPlaceholder} />
      <LazyLoad>
        <Image
          onLoad={removePlaceholder}
          onError={removePlaceholder}
          src={getOrganizationImage(image)}
          alt={'organization image'}
          verified={true}
        />
      </LazyLoad>
    </ButtonBase>
  )
}

export default Avatar
