import styled from 'styled-components'
import config from '../../../../../config.js'

export const MobileNameContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`

export const Thumbnail = styled.div`
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
  min-width: 2.85rem;
  width: 2.85rem;
  min-height: 2.85rem;
  height: 2.85rem;
  border-radius: 50%;
  border: 0.15rem solid;
  border-color: ${({ verified }) => (verified === 1 ? '#abcab3' : '#ccc')};

  @media (max-width: ${config.UI.drawer_breakpoint.max}) {
    margin-top: 0.5rem;
    margin-right: 1rem;
  }
`

export const Name = styled.span`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-size: ${({ theme }) => theme.typography.fontSizes.regular};
  font-weight: ${({ read, theme }) =>
    read ? theme.typography.fontWeight.regular : theme.typography.fontWeight.semiBold};
  opacity: ${({ read }) => (read ? 0.7 : 1)};
  color: ${({ theme }) => theme.typography.fontColor.bodyCopy};
  overflow: hidden;
  margin-right: 0.25rem;

  @media (max-width: ${config.UI.drawer_breakpoint.max}) {
    padding-bottom: 0rem;
  }
`

export const SubjectContainer = styled.div`
  flex: 1 1 auto;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  flex-shrink: 1;

  @media (max-width: ${config.UI.drawer_breakpoint.max}) {
    padding-bottom: 0rem;
  }
`

export const SubjectInnerContainer = styled.div`
  display: flex;
  align-items: baseline;
  white-space: nowrap;
  overflow: hidden;
`

export const Subject = styled.div`
  flex-shrink: 1;
  display: inline-flex;
  white-space: nowrap;
  overflow: hidden;
`

export const SubjectCopy = styled.span`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-size: ${({ theme }) => theme.typography.fontSizes.regular};
  font-weight: ${({ theme }) => theme.typography.fontWeight.semiBold};
  color: ${({ theme }) => theme.typography.fontColor.bodyCopy};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const PreviewContainer = styled.div`
  @media (max-width: ${config.UI.drawer_breakpoint.max}) {
    flex: 1 1 auto;
    white-space: nowrap;
    overflow: hidden;
    display: block;
    flex-shrink: 1;
    padding-bottom: 0rem;
  }
`

export const PreviewInnerContainer = styled.div`
  display: flex;
  align-items: baseline;
  white-space: nowrap;
  overflow: hidden;
`

export const Preview = styled.span`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-size: ${({ theme }) => theme.typography.fontSizes.regular};
  font-weight: ${({ read, theme }) =>
    read ? theme.typography.fontWeight.regular : theme.typography.fontWeight.semiBold};
  color: ${({ theme }) => theme.typography.fontColor.bodyCopy};
  opacity: ${({ read }) => (read ? 0.7 : 1)};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
  text-align: left;
  -webkit-font-smoothing: antialiased;
  /** you might need webkit prefix for flex properties */

  & p {
    display: inline-block;
    font-weight: ${({ theme }) => theme.typography.fontWeight.regular};
    opacity: 0.7;
  }

  @media (max-width: ${config.UI.drawer_breakpoint.max}) {
    display: flex;
  }
`

export const MobilePreview = styled.span`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-size: ${({ theme }) => theme.typography.fontSizes.regular};
  font-weight: ${({ read, theme }) =>
    read ? theme.typography.fontWeight.regular : theme.typography.fontWeight.semiBold};
  color: ${({ theme }) => theme.typography.fontColor.bodyCopy};
  opacity: ${({ read }) => (read ? 0.7 : 1)};
  overflow: hidden;
  text-overflow: ellipsis;
  /* white-space: nowrap; */
`

export const TimeStamp = styled.span`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-size: ${({ theme }) => theme.typography.fontSizes.regular};
  color: ${({ theme }) => theme.typography.fontColor.bodyCopy};
  font-weight: ${({ read, theme }) =>
    read ? theme.typography.fontWeight.regular : theme.typography.fontWeight.semiBold};
  opacity: ${({ read }) => (read ? 0.7 : 1)};
`
