import styled from 'styled-components'

export const MainContainer = styled.div`
  overflow-y: auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

export const ContentContainer = styled.div`
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;

  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  -o-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
`

export const Message = styled.h3`
  width: 100%;
  margin-bottom: 2rem;
  text-align: center;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: 4.2rem;
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  color: ${(props) => props.theme.palette.secondary.main};
`
