import { history } from '../../../../history/history'
import UserAvatar from '../../../partials/user-avatar/user-avatar'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Chip from '@mui/material/Chip'
import SchoolIcon from '@mui/icons-material/School'
import ModeEditIcon from '@mui/icons-material/ModeEdit'
import ReportIcon from '@mui/icons-material/Report'
import { styled } from '@mui/material/styles'
import {
  OuterContainer,
  Header,
  TabContainer,
  Tab,
  ResultContainer,
  AvatarContainer,
  ProfileInfoContainer,
  CourseName,
  CourseSeason,
  ProfileLocation,
  NoResults
} from '../styled/my-courses-comps'

function CourseCard({ course, mode, redirect, isAdmin, isInstructor, userId }) {
  console.log('CourseCard isInstructor:', isInstructor)
  const hasEditRights = isAdmin || isInstructor
  const showInstructorBadge = userId == course.instructorId

  return (
    <StyledCard key={course._id}>
      <MuiCourseDetailsContainer>
        <AvatarContainer>
          <UserAvatar
            src={`https://res.cloudinary.com/headliner/image/upload/v1725913777/organizations/uconn-seal.jpg`}
            width={'3.05rem'}
            height={'3.05rem'}
            borderWidth={'0.2rem'}
            verified={1}
          />
        </AvatarContainer>
        <ProfileInfoContainer>
          <Typography
            id={course._id}
            sx={{
              textTransform: 'capitalize',
              fontSize: '1.05rem',
              fontWeight: 600,
              color: 'text.primary'
            }}>
            {course.title}
          </Typography>
          <Typography
            sx={{
              textTransform: 'capitalize',
              fontSize: '1rem',
              color: 'text.primary'
            }}>
            University of Connecticut
          </Typography>
          <Typography
            sx={{
              textTransform: 'capitalize',
              fontSize: '1rem',
              color: 'text.primary'
            }}>{`${course.season} ${course.year}`}</Typography>
          {showInstructorBadge && (
            <Chip label="Instructor" color="secondary" sx={{ margin: '0.5rem 0 0 0' }} />
          )}
        </ProfileInfoContainer>
      </MuiCourseDetailsContainer>
      <MuiButtonContainer>
        {course.status !== 'pending' && (
          <StyledButton
            variant="outlined"
            color="secondary"
            id={course._id}
            startIcon={<SchoolIcon id={course._id} />}
            onClick={(e) => redirect(e.currentTarget.id, 'course')}>
            <Typography sx={{ color: '#333', fontWeight: 600, fontSize: '1rem' }} id={course._id}>
              View Course
            </Typography>
          </StyledButton>
        )}

        {mode === 'report' && hasEditRights && (
          <>
            <StyledButton
              variant="outlined"
              color="secondary"
              id={course._id}
              startIcon={<ModeEditIcon id={course._id} />}
              onClick={(e) => redirect(e.currentTarget.id, 'course-setup')}
              fullWidth>
              <Typography sx={{ color: '#333', fontWeight: 600, fontSize: '1rem' }} id={course._id}>
                Edit Course
              </Typography>
            </StyledButton>

            {course.status !== 'pending' && (
              <StyledButton
                variant="outlined"
                color="secondary"
                id={course._id}
                startIcon={<ReportIcon id={course._id} />}
                onClick={(e) => redirect(e.currentTarget.id, 'report')}
                fullWidth>
                <Typography
                  sx={{ color: '#333', fontWeight: 600, fontSize: '1rem' }}
                  id={course._id}>
                  View Report
                </Typography>
              </StyledButton>
            )}
          </>
        )}
      </MuiButtonContainer>
    </StyledCard>
  )
}

export default CourseCard

/** ********************** */
/** Material-UI Components */
/** ********************** */

const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  boxShadow: '0px 7px 5px -2px rgba(100, 100, 100, 0.1)',
  backgroundColor: theme.palette.common.white,
  borderRadius: '0.25rem',
  padding: '0.8rem 1rem',
  boxSizing: 'border-box',
  position: 'relative',
  marginBottom: '1rem',
  [theme.breakpoints.down(901)]: {
    flexDirection: 'column'
  }
}))

const MuiCourseDetailsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  marginBottom: '1rem'
}))

const MuiButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-end',
  gap: '0.5rem',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-end'
  }
}))

const StyledButton = styled(Button)(({ theme }) => ({
  width: '10rem',
  [theme.breakpoints.down('sm')]: {
    width: '100%'
  }
}))
