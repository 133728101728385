export * from '../thunks/donation-tokens-transactions/history-donation-tokens'

export const transActionHistoyActionTypes = {
  SET_HISTORY: 'SET_HISTORY',
  UPDATE_HISTORY_BY_ORGANIZATIONID: 'UPDATE_HISTORY_BY_ORGANIZATIONID',
  UPDATE_HISTORY_BY_PUBLIC_ID_TRANSFER: 'UPDATE_HISTORY_BY_PUBLIC_ID_TRANSFER',
  UPDATE_HISTORY_BY_PUBLIC_ID_SUBSCRIPTION: 'UPDATE_HISTORY_BY_PUBLIC_ID_SUBSCRIPTION',
  UPDATE_HISTORY_BY_PUBLIC_ID_PURCHACE: 'UPDATE_HISTORY_BY_PUBLIC_ID_PURCHACE',
  UPDATE_HISTORY_BY_PUBLIC_ID_VERIFICATION:'UPDATE_HISTORY_BY_PUBLIC_ID_VERIFICATION',
  CLEAR_TRANSACTION: 'CLEAR_TRANSACTION',
  SET_ROWS_PER_PAGE: 'SET_ROWS_PER_PAGE',
  SET_PAGE: 'SET_PAGE',
  LOADING: 'LOADING',
  ADD_HISTORY: 'ADD_HISTORY'
}

export const setTransActionHistory = (payload) => {
  return (dispatch) => {
    dispatch({
      type: transActionHistoyActionTypes.SET_HISTORY,
      payload
    })
  }
}

export const addTransActionHistory = (payload) => {
  return (dispatch) => {
    dispatch({
      type: transActionHistoyActionTypes.ADD_HISTORY,
      payload
    })
  }
}

export const updateTransActionHistory_donation = (payload) => {
  return (dispatch) => {
    dispatch({
      type: transActionHistoyActionTypes.UPDATE_HISTORY_BY_ORGANIZATIONID,
      payload
    })
  }
}

export const updateTransActionHistory_transfer = (payload) => {
  return (dispatch) => {
    dispatch({
      type: transActionHistoyActionTypes.UPDATE_HISTORY_BY_PUBLIC_ID_TRANSFER,
      payload
    })
  }
}
export const updateTransActionHistory_purchase = (payload) => {
  return (dispatch) => {
    dispatch({
      type: transActionHistoyActionTypes.UPDATE_HISTORY_BY_PUBLIC_ID_PURCHACE,
      payload
    })
  }
}

export const updateTransActionHistory_verification = (payload) => {
  return (dispatch) => {
    dispatch({
      type: transActionHistoyActionTypes.UPDATE_HISTORY_BY_PUBLIC_ID_VERIFICATION,
      payload
    })
  }
}

export const updateTransActionHistory_subscription = (payload) => {
  return (dispatch) => {
    dispatch({
      type: transActionHistoyActionTypes.UPDATE_HISTORY_BY_PUBLIC_ID_SUBSCRIPTION,
      payload
    })
  }
}

export const setTransActionLimits = (payload) => {
  return (dispatch) => {
    dispatch({
      type: transActionHistoyActionTypes.SET_ROWS_PER_PAGE,
      payload
    })
  }
}

export const setTransActionPage = (payload) => {
  return (dispatch) => {
    dispatch({
      type: transActionHistoyActionTypes.SET_PAGE,
      payload
    })
  }
}

export const clearTransActionHistory = () => {
  return (dispatch) => {
    dispatch({
      type: transActionHistoyActionTypes.CLEAR_TRANSACTION
    })
  }
}

export const setLoading = (payload) => {
  return (dispatch) => {
    dispatch({
      type: transActionHistoyActionTypes.LOADING,
      payload
    })
  }
}
