import config from '../../../../config.js'
import _ from 'lodash'
import Chain from '../../../../utils/classes/chain.js'

const load = (isLoadingPage, takeId, currUserId, setIsLoading, setTakeFound, actions) => {
  const { loadLimits } = config

  const _getTakesByIDs = (takeIds) => {
    return actions.getTakesByIDs({ takeIds })
  }

  const _getDiscussions = (takeIds) => {
    return actions.getDiscussions({
      takeIds,
      limit: loadLimits.discussions.perPost,
      isLoadingPage
    })
  }

  const _getRebuttals = (debateIds) => {
    return actions.getRebuttals({
      debateIds,
      limit: loadLimits.rebuttals,
      isLoadingPage
    })
  }

  const _getProfilesByUserIDs = (userIds) => {
    return actions.getProfilesByUserIDs({ userIds })
  }

  const chain = new Chain() // Set variable to log chain status

  return _getTakesByIDs([takeId])
    .then((data1) => {
      if (data1.error) {
        chain.break('Failed to retrieve take by ID.')

        return Promise.resolve()
      }

      if (data1.takes.length === 0) {
        chain.break('No takes found.')

        return Promise.resolve()
      }

      const userIds = data1.takes[0].userId !== currUserId ? [data1.takes[0].userId] : []
      const topicIds = [data1.takes[0].topicId]

      chain.setData('take', data1.takes[0])
      chain.setData('userIds', userIds)
      chain.setData('topicIds', topicIds)

      return _getDiscussions([takeId])
    })
    .then((data2) => {
      if (chain.broken) return Promise.resolve()

      if (data2.error) {
        chain.break('Failed to retrieve discussions.')

        return Promise.resolve()
      }

      let userIds = [...chain.data.userIds]
      let discussionIds = []

      for (var i = 0; i < data2.discussions.length; i++) {
        const { _id, hostUserId, guestUserId } = data2.discussions[i]

        discussionIds.push(_id)

        // Grab take IDs from takes that are not current user
        if (guestUserId !== currUserId) {
          if (!_.includes(userIds, guestUserId)) {
            userIds.push(guestUserId)
          }
        }

        if (hostUserId !== currUserId) {
          if (!_.includes(userIds, hostUserId)) {
            userIds.push(hostUserId)
          }
        }
      }

      chain.setData('userIds', userIds)

      return _getRebuttals(discussionIds)
    })
    .then((data3) => {
      if (chain.broken) return Promise.resolve()

      if (data3.error) {
        chain.break('Failed to retrieve rebuttals.')

        return Promise.resolve()
      }

      return _getProfilesByUserIDs(chain.data.userIds)
    })
    .then((data4) => {
      if (chain.broken) return Promise.resolve()

      if (data4.error && chain.data.userIds.length > 0) {
        chain.break('Failed to retrieve profiles by user IDs.')

        return Promise.resolve()
      }

      chain.break('Successfully loaded take page.')

      return Promise.resolve()
    })
    .then((last_data) => {
      let { broken, log } = chain

      if (broken) {
        console.log('[DEBUG]: ', log)

        setTakeFound(typeof chain.data.take !== 'undefined')
        setIsLoading(false)

        return Promise.resolve()
      }

      throw new Error('Unhandled promise chain logic (Load Take).')
    })
    .catch((error) => {
      console.log('[ERROR]: ', error)
    })
}

export default load
