import { useDebugValue, useState, Dispatch, SetStateAction } from 'react';

interface TitleErrors {
  [key: string]: string;
}

function useTitleErrorsObjState(initialValue: TitleErrors): [TitleErrors, Dispatch<SetStateAction<TitleErrors>>] {
  const [titleErrorsObj, setTitleErrorsObj] = useState<TitleErrors>(initialValue);
  useDebugValue(titleErrorsObj ? titleErrorsObj : initialValue);
  return [titleErrorsObj, setTitleErrorsObj];
}

export default useTitleErrorsObjState;
