import styled, { keyframes } from 'styled-components'
import config from '../../../../config.js'

export const Header = styled.p`
  margin: 0.5rem 0 1.5rem 0;
  text-align: center;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: 2.2rem;
  font-weight: ${(props) => props.theme.typography.fontWeight.medium};
  color: ${(props) => props.theme.typography.fontColor.bodyCopy};

  @media (max-width: ${config.UI.drawer_breakpoint.max}) {
    font-size: 1.6rem;
  }
`

export const Subheader = styled.p`
  max-width: 43rem;
  margin: 1.5rem auto 0 auto;
  text-align: center;
  line-height: ${(props) => props.theme.typography.lineHeight};
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.regular};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  color: ${(props) => props.theme.typography.fontColor.bodyCopy};

  & a:active,
  & a:visited,
  & a:link {
    text-decoration: none;
    color: ${(props) => props.theme.palette.link.primary};
    font-weight: ${(props) => props.theme.typography.fontWeight.semiBold};
  }
  & a:hover {
    text-decoration: underline;
    color: ${(props) => props.theme.palette.link.primary};
    font-weight: ${(props) => props.theme.typography.fontWeight.semiBold};
  }
`

export const StripeImage = styled.img`
  display: block;
  width: auto;
  height: 2rem;
  margin: 2rem auto 0 auto;
`

export const ButtonsContainer = styled.div`
  margin-top: 2.5rem;
  text-align: center;
`

/** Verification Status Comps */
export const VerificationStatusContainer = styled.div`
  text-align: center;
  margin-bottom: 1.5rem;
`

export const VerificationStatusLabel = styled.h3`
  display: inline;
  vertical-align: top;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.regular};
  font-weight: ${(props) => props.theme.typography.fontWeight.semiBold};
  color: ${(props) => props.theme.typography.fontColor.bodyCopy};
  margin-right: 0.5rem;
`

export const VerificationCurrentStatus = styled.h3`
  display: inline;
  vertical-align: top;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.regular};
  font-weight: ${(props) => props.theme.typography.fontWeight.semiBold};
  color: ${(props) =>
    props.verificationStatus === 1 ? props.theme.palette.text.primary : '#990b0b'};
`

/** Verification Status Avatar */
export const VerificationStatusAvatarContainer = styled.div`
  text-align: center;
`

export const VerificationStatusImageContainer = styled.div`
  text-align: center;
  position: relative;
  min-height: 6rem;
`

export const Image = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  background-size: cover; /** covers IE support */
  object-fit: cover;
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  border: 5px solid;
  border-color: ${(props) => (props.verified === 1 ? props.theme.palette.primary.main : '#ccc')};
  background-color: #ccc;
  margin-bottom: 0.75rem;
`

const LoadingAnimation = keyframes`
  0% {
    background-color: #fff;
  }
  50% {
    background-color: #ccc;
  }
  100% {
    background-color: #fff;
  }
`

export const Placeholder = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  margin: 0 auto;
  text-align: center;
  width: 5rem;
  height: 5rem;
  animation: ${LoadingAnimation} 1s infinite;
  border-radius: 50%;
  border: 3px solid;
  border-color: #ccc;
`
