export * from '../thunks/organizations/history-donation-tokens'

export const transActionHistoyActionTypes = {
  SET_ORGANIZATIONS_HISTORY: 'SET_ORGANIZATIONS_HISTORY',
  UPDATE_ORGANIZATIONS_HISTORY_BY_ID: 'UPDATE_ORGANIZATIONS_HISTORY_BY_ID',
  UPDATE_ORGANIZATIONS_HISTORY_BY_PUBLIC_ID_WITH_DRAWAL:
    'UPDATE_ORGANIZATIONS_HISTORY_BY_PUBLIC_ID_WITH_DRAWAL',
  CLEAR_ORGANIZATIONS_TRANSACTION: 'CLEAR_ORGANIZATIONS_TRANSACTION',
  SET_ORGANIZATIONS_ROWS_PER_PAGE: 'SET_ORGANIZATIONS_ROWS_PER_PAGE',
  SET_ORGANIZATIONS_PAGE: 'SET_ORGANIZATIONS_PAGE',
  ORGANIZATIONS_LOADING: 'ORGANIZATIONS_LOADING',
  ADD_ORGANIZATIONS_HISTORY: 'ADD_ORGANIZATIONS_HISTORY'
}

export const setTransActionHistory = (payload) => {
  return (dispatch) => {
    dispatch({
      type: transActionHistoyActionTypes.SET_ORGANIZATIONS_HISTORY,
      payload
    })
  }
}

export const addTransActionHistory = (payload) => {
  return (dispatch) => {
    dispatch({
      type: transActionHistoyActionTypes.ADD_ORGANIZATIONS_HISTORY,
      payload
    })
  }
}

export const updateTransActionHistory_donation = (payload) => {
  return (dispatch) => {
    dispatch({
      type: transActionHistoyActionTypes.UPDATE_ORGANIZATIONS_HISTORY_BY_ID,
      payload
    })
  }
}

export const updateTransActionHistory_withdrawal = (payload) => {
  return (dispatch) => {
    dispatch({
      type: transActionHistoyActionTypes.UPDATE_ORGANIZATIONS_HISTORY_BY_PUBLIC_ID_WITH_DRAWAL,
      payload
    })
  }
}

export const setTransActionLimits = (payload) => {
  return (dispatch) => {
    dispatch({
      type: transActionHistoyActionTypes.SET_ORGANIZATIONS_ROWS_PER_PAGE,
      payload
    })
  }
}

export const setTransActionPage = (payload) => {
  return (dispatch) => {
    dispatch({
      type: transActionHistoyActionTypes.SET_ORGANIZATIONS_PAGE,
      payload
    })
  }
}

export const clearTransActionHistory = () => {
  return (dispatch) => {
    dispatch({
      type: transActionHistoyActionTypes.CLEAR_ORGANIZATIONS_TRANSACTION
    })
  }
}

export const setLoading = (payload) => {
  return (dispatch) => {
    dispatch({
      type: transActionHistoyActionTypes.ORGANIZATIONS_LOADING,
      payload
    })
  }
}
