import { useState, useCallback, useRef, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { debounce } from 'lodash'
import Select from './course-setup-select'
import Input from './course-setup-input'
import CourseMemberCard from './course-member-card'
import CourseFormDialog from './course-form-dialog'
import CourseStepButtonContainer from './course-step-button-container'
/** Material-ui components */
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
// import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import MenuItem from '@mui/material/MenuItem'
import List from '@mui/material/List'
import ListSubheader from '@mui/material/ListSubheader'
import ListItemButton from '@mui/material/ListItemButton'
import { searchOrganizationMembers } from '../../../../redux/thunks/search/searchOrganizationMembers'
import { getProfilesByUserIDs } from '../../../../redux/thunks/profiles/getProfilesByUserIDs'
import {
  ProfileContainer,
  ProfilePicture,
  ProfileInfoContainer,
  ProfileName,
  ProfileEmail
} from '../styled/course-setup-comps'
import getName from '../../../../utils/functions/getName'
import getAvatar from '../../../../utils/functions/getAvatar'

function CourseInstructor({
  courseFields,
  handleSetCourseFields,
  handlePreviousStep,
  handleNextStep,
  activeStep,
  steps,
  setStepUpdated
}) {
  const [query, setQuery] = useState('')
  const [users, setUsers] = useState([])
  const [open, setOpen] = useState(false)
  const loading = open && users.length === 0
  const [selectedInstructor, setSelectedInstructor] = useState({})
  const [submitErrors, setSubmitErrors] = useState([])
  const [formErrors, setFormErrors] = useState(false)
  const inputRef = useRef(null)
  const dispatch = useDispatch()

  function getPublicName() {
    const env = import.meta.env.VITE_NODE_ENV

    console.log('[DEBUG]: env course instructor', env)

    switch (env) {
      case 'local':
        return 'UCONN'
      case 'staging':
        return 'UCONN'
      case 'production':
        return 'UCONN'
      default:
        return 'UCONN'
    }
  }

  const publicName = getPublicName()

  console.log('[DEBUG]: org publicName course instructor', publicName)

  const debouncedFindUsers = useCallback(
    debounce((query) => {
      dispatch(
        searchOrganizationMembers({ query, limit: 5, orgName: publicName, roleName: 'Faculty' })
      ).then((result) => {
        if (!result.error) {
          const userIds = result.data?.users?.map((i) => i?._id)

          dispatch(getProfilesByUserIDs({ userIds })).then((profileResult) => {
            const profiles = Object.values(profileResult?.profiles)

            const users = result.data?.users?.map((user) => {
              const profile = profiles?.find((profile) => profile?.userId === user?._id)

              return {
                ...profile,
                ...user,
                label: getName(profile)
              }
            })
            users && setUsers(users)
          })
        } else {
          console.error(`[ERROR]: while searching for instructor query: ${query}`, result)

          setUsers([])
        }
      })
    }, 1000),
    []
  )

  const handleInputChange = (e) => {
    const query = e.target.value

    setQuery(query)
    if (query) {
      debouncedFindUsers(query)
    } else {
      // setResultOpen(false)
      setUsers([])
      setSelectedInstructor({})
    }
  }

  const handleSelectInstructor = (profileObject) => {
    setStepUpdated(true)

    const newFields = {
      instructor: profileObject
    }

    handleSetCourseFields(newFields)

    setSelectedInstructor(profileObject)

    setFormErrors(false)
    setSubmitErrors([])
  }

  const validateNextStep = () => {
    // allow the user to proceed even if they haven't selected an instructor
    // the course review step will validate that an instructor has been selected before allowing the course status to be set to 'open'
    setFormErrors(false)
    handleNextStep()
  }

  useEffect(() => {
    if (courseFields.instructor) {
      setSelectedInstructor(courseFields.instructor)
    }
  }, [])

  const handleInputChangeAutocomplete = (event, newInputValue) => {
    if (newInputValue === '') {
      setUsers([])
      setSelectedInstructor({}) // Clear the options
    }
  }

  return (
    <MuiBox>
      <Typography variant="h4">Add a Course Instructor</Typography>
      {/* <Input
        label="Search Course Instructors"
        id="instructor"
        name="instructor"
        value={query}
        handleChange={handleInputChange}
        inputRef={inputRef}
        // helperText="Search instructor profiles"
      /> */}
      <Autocomplete
        disablePortal
        id="search-instructor-autocomplete"
        options={users}
        getOptionLabel={(option) => option.label || ''}
        loading={loading}
        open={open}
        onOpen={() => {
          setOpen(true)
        }}
        onClose={() => {
          setOpen(false)
        }}
        // onInputChange={handleInputChange}
        onChange={(e, value) => handleSelectInstructor(value)}
        onInputChange={handleInputChangeAutocomplete}
        sx={{ color: 'black' }}
        renderInput={(params) => {
          return (
            <MuiTextField
              {...params}
              label="Search Instructors"
              value={query}
              onChange={handleInputChange}
              inputRef={inputRef}
              sx={{ color: 'black' }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? <CircularProgress color="secondary" size={24} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )
        }}
        renderOption={(props, option) => (
          <Box component="li" {...props}>
            <CourseMemberCard key={option._id} member={option} label="Instructor" />
          </Box>
        )}
      />
      <Box sx={{ display: 'block', gap: '2rem', width: '100%' }}>
        <MuiFlexColumnBox>
          <MuiList>
            <MuiListSubheader>Your Choice</MuiListSubheader>
            {selectedInstructor?._id && (
              <CourseMemberCard member={selectedInstructor} label="Instructor" isButton={false} />
            )}
          </MuiList>
          {/* <Button
            variant="contained"
            type="submit"
            fullWidth
            disabled={Object.keys(selectedInstructor).length === 0}>
            Save
          </Button> */}
        </MuiFlexColumnBox>
      </Box>
      <CourseFormDialog errors={submitErrors} />
      <CourseStepButtonContainer
        handlePreviousStep={handlePreviousStep}
        handleNextStep={validateNextStep}
        activeStep={activeStep}
        steps={steps}
        formErrors={formErrors}
        editingCourse={courseFields.status !== 'pending'}
      />
    </MuiBox>
  )
}

export default CourseInstructor

// ***************************** //
// *** MUI Styled Components *** //
// ***************************** //

const MuiTextField = styled(TextField)(({ theme }) => ({
  background: theme.palette.background.gray,
  color: theme.palette.text.primary,
  minWidth: '18rem',
  '& .MuiFormLabel': {
    color: theme.palette.text.primary
  },
  '& .MuiInputLabel': {
    color: theme.palette.text.primary
  },
  '& .MuiFormHelperText-root': {
    color: theme.palette.text.primary
  },
  '& label': {
    color: theme.palette.text.primary
  },
  '& label.Mui-focused': {
    color: theme.palette.secondary.main
  },
  '& .MuiInput-underline:after': {
    // borderBottomColor: '#B2BAC2'
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      // borderColor: '#E0E3E7'
    },
    '&:hover fieldset': {
      // borderColor: '#B2BAC2'
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.secondary.main
    }
  }
}))

const MuiBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
  margin: '1rem 0',
  padding: '2rem',
  borderRadius: '0.25rem',
  background: '#fff'
}))

const MuiFlexColumnBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  // alignItems: 'flex-start',
  gap: '1rem'
}))

const MuiList = styled(List)(({ theme }) => ({
  border: '1px solid',
  borderColor: theme.palette.secondary.main,
  // minWidth: '18rem',
  maxWidth: '100%',
  width: '100%',
  padding: 0,
  borderRadius: '0.25rem',
  backgroundColor: theme.palette.background.gray
}))

const MuiListSubheader = styled(ListSubheader)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '1.25rem',
  backgroundColor: theme.palette.secondary.light,
  minWidth: '18rem',
  borderRadius: '0.25rem 0.25rem 0 0'
}))

const MuiListItemButton = styled(ListItemButton)(({ theme }) => ({
  '&.MuiButtonBase-root': {
    backgroundColor: theme.palette.common.white
  }
}))

{
  /* <MuiFlexColumnBox>
<MuiList>
  <MuiListSubheader>Select a profile</MuiListSubheader>
  {profiles}
</MuiList>
</MuiFlexColumnBox> */
}
