import Validator from 'validator'

const UpdateNicknameValidation = state => {
  const { nickname } = state

  // Nickname
  if (nickname.length > 50) {
    return { error: 'Nickname must be less than 50 characters.' }
  } else {
    const splitStrArr = nickname.split(/[\s-]+/)

    for (var i = 0; i < splitStrArr.length; i++) {
      if (splitStrArr[i] === '') continue
      
      if (!Validator.isAlpha(splitStrArr[i])) {
        return {
          error: 'Nickname may only contain letters, spaces, or hyphens (-).'
        }
      }
    }
  }

  return { error: '' }
}

export default UpdateNicknameValidation
