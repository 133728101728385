import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { getAssignedRoles } from '../../../../../../../redux/thunks/assigned-roles/getAssignedRoles'
import { getOrganizationRolesByID } from '../../../../../../../redux/thunks/roles/getOrganizationRolesByID'
import { getOrganizationsByIDs } from '../../../../../../../redux/thunks/organizations/getOrganizationsByIDs'
import Slider from '../../../../../../partials/slider/slider'
import {
  ArticleContainer,
  GreenArticleHeader,
  WhiteBackgroundContainer
} from './styled/my-organizations-comps'

function MyProfileOrganizations({ profileUserId }) {
  const [organizations, setOrganizations] = useState([])
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch()

  useEffect(() => {
    if (loading) {
      dispatch(getAssignedRoles({ userId: profileUserId }))
        .then((data) => {
          if (data.error) {
            console.log('ERROR:', data.message)
            return Promise.resolve()
          }

          const roleIds = data.assignedRoles.map((assignedRole) => assignedRole.roleId)

          return dispatch(getOrganizationRolesByID({ roleIds }))
        })
        .then((data) => {
          if (data.error) {
            console.log('ERROR:', data.message)
            return Promise.resolve()
          }

          const organizationIds = data.roles.map((role) => role.organizationId)

          return dispatch(getOrganizationsByIDs({ organizationIds }))
        })
        .then((data) => {
          if (data.error) {
            console.log('ERROR:', data.message)
            return Promise.resolve()
          }

          setOrganizations([...data.organizations])
        })
        .catch((error) => {
          console.log(error)
        })

      setLoading(false)
    }
  }, [dispatch, loading, profileUserId])

  if (organizations.length === 0) {
    return null
  }

  return (
    <ArticleContainer>
      <GreenArticleHeader>Member of:</GreenArticleHeader>

      <WhiteBackgroundContainer>
        <Slider
          dataType={'organization'}
          organizations={organizations}
          theme={'light'}
          displayFollowActions={true}
          enableFollowLoading={true}
        />
      </WhiteBackgroundContainer>
    </ArticleContainer>
  )
}

export default MyProfileOrganizations
