import { useState, useContext } from 'react'
import filter from 'lodash/filter'
import Permissions from './components/permissions/permissions'
import Details from './components/details/details'
import Members from './components/members/members'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { MainContainer } from './styled/edit-role-comps'
import LoadOrganizationRolesContext from '../../../../../../data-loading/load-organization-assigned-roles/context/context'
import theme from '../../../../../../../res/theme'

const styles = {
  tabs: {
    marginTop: '1rem'
  },
  tab: {
    color: theme.typography.fontColor.bodyCopy,
    borderBottom: '2px solid #eee'
  },
  tabSelected: {
    color: theme.typography.fontColor.bodyCopy,
    fontWeight: 'bold'
  }
}

function EditRole({ selectedRole }) {
  const { assignedRoles } = useContext(LoadOrganizationRolesContext)
  const [tab, setTab] = useState('permissions')
  const members = filter(assignedRoles, { roleId: selectedRole }).length

  const handleTabChange = (event, newTab) => {
    setTab(newTab)
  }

  return (
    <MainContainer>
      <Tabs
        variant="fullWidth"
        value={tab}
        onChange={handleTabChange}
        textColor="secondary"
        indicatorColor="secondary"
        aria-label="secondary tabs example"
        sx={styles.tabs}>
        <Tab
          value="permissions"
          label="Permissions"
          style={tab === 'permissions' ? styles.selected : styles.tab}
        />
        <Tab
          value="details"
          label="Details"
          style={tab === 'details' ? styles.selected : styles.tab}
        />
        <Tab
          value="members"
          label={`Members - ${members}`}
          style={tab === 'members' ? styles.selected : styles.tab}
        />
      </Tabs>

      {tab === 'permissions' && <Permissions selectedRole={selectedRole} />}
      {tab === 'details' && <Details selectedRole={selectedRole} />}
      {tab === 'members' && <Members selectedRole={selectedRole} />}
    </MainContainer>
  )
}

export default EditRole
