const UpdatePasswordValidation = state => {
    // Regex for password
    var passRegex = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[\w~@#$%^&*+=`|{}:;!.?"()[\]-]{6,20}$/)
  
    // Password
    if (!state.currentPassword) {
      return {
        error: 'Please enter your current password'
      }
    } else if (!state.newPassword) {
      return {
        error: 'New password is required'
      }
    } else if (!passRegex.test(state.newPassword)) {
      return {
        error:
          'New password must be 6-20 characters, include a number, and include one uppercase and lowercase character'
      }
    } else if (!state.confirmPassword) {
      return {
        error: 'Password confirmation is required'
      }
    } else if (state.newPassword !== state.confirmPassword) {
      return {
        error: 'Passwords dont match'
      }
    }
  
    return {
      error: ''
    }
  }
  
  export default UpdatePasswordValidation
  