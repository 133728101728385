import React from 'react'
import LogoWhite from '../../media/logo/logo-white.png'
import {
  MainContainer,
  Links,
  FooterInnerContainer,
  Badges,
  LinksGroup
} from './styled/footer-comps'
import config from '../../config'
import { Link } from 'react-router-dom'
import twitterIcon from '../../media/icons/twitter_green_512.png'
import instagramIcon from '../../media/icons/instagram_green_512.png'
import linkedinIcon from '../../media/icons/linkedin_green_512.png'
import facebookIcon from '../../media/icons/facebook_green_512.png'

const Footer = () => {
  return (
    <MainContainer>
      <FooterInnerContainer>
        <div className="footer">
          {/* <a href={config.publicSiteURL}>
            <img src={LogoWhite} alt={'CivilTalk Logo (White)'} style={{ height: '1.7rem' }} />
          </a> */}

          <Badges>
            <div>
              <h6>We&rsquo;re mobile friendly. Download our app.</h6>
            </div>

            <div className="badges-icon">
              <a
                target={'_blank'}
                rel="noreferrer"
                href="https://play.google.com/store/apps/details?id=com.civiltalk.civiltalk.prod&amp;hl=en_US&amp;gl=US&amp;pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                <img
                  className="app-store-img"
                  src="https://res.cloudinary.com/headliner/image/upload/v1638556187/app_store_branding/google-play-badge.png"
                  alt="Get it on Google Play"
                />
              </a>

              <a
                rel="noreferrer"
                href="https://apps.apple.com/us/app/civiltalk/id1509613184"
                target={'_blank'}>
                <img
                  className="app-store-img"
                  src="https://res.cloudinary.com/headliner/image/upload/v1638555529/app_store_branding/download-on-the-app-store-badge.png"
                  alt="Download on the App Store"
                  layout="fill"
                />
              </a>
            </div>
            <div className="footer-social-media">
              <a target={'_blank'} rel="noreferrer" href="https://twitter.com/CivilTalkapp">
                <img src={twitterIcon} alt="Download on the App Store" />
              </a>

              <a target={'_blank'} rel="noreferrer" href="https://www.instagram.com/civiltalkapp">
                <img src={instagramIcon} alt="Download on the App Store" />
              </a>

              <a
                target={'_blank'}
                rel="noreferrer"
                href="https://www.linkedin.com/company/civiltalk">
                <img src={linkedinIcon} alt="Download on the App Store" />
              </a>

              <a target={'_blank'} rel="noreferrer" href="https://www.facebook.com/civiltalkapp">
                <img src={facebookIcon} alt="Download on the App Store" />
              </a>
            </div>
          </Badges>

          <LinksGroup>
            <Links>
              <a href={`${config.publicSiteURL}/university`}>University</a>
              {/* <a href={`${config.publicSiteURL}/videos`}>Video Resources</a> */}
              <a href={`${config.publicSiteURL}/pricing`}>Membership Pricing</a>
              {/* <a href={`${config.publicSiteURL}/sponsor`}>For Business</a> */}
              <a href={'mailto:support@civiltalk.com'}>Customer Support</a>
            </Links>

            <Links>
              {/* <a href={`${config.publicSiteURL}/terms-of-use`}>Terms of Use</a> */}
              <a
                href={`https://res.cloudinary.com/headliner/image/upload/v1706298273/terms_of_service/Civil_Talk_Terms_of_Use.pdf`}
                rel="noopener noreferrer"
                target="_blank">
                Terms of Use
              </a>
              <a
                href={`https://res.cloudinary.com/headliner/image/upload/v1726625541/terms_of_service/CivilTalk_Privacy_Policy_09_16_2024.pdf`}
                rel="noopener noreferrer"
                target="_blank">Privacy Policy</a>
              {/* <a href={`${config.publicSiteURL}/copyright-policy`}>Copyright Policy</a> */}
              <a
                href={`https://res.cloudinary.com/headliner/image/upload/v1706300974/terms_of_service/Copyright_DMCA_Policy.pdf`}
                rel="noopener noreferrer"
                target="_blank">
                Copyright Policy
              </a>
              {/* <a href={`${config.publicSiteURL}/csr`}>Our CSR</a> */}
            </Links>
          </LinksGroup>
        </div>
      </FooterInnerContainer>
    </MainContainer>
  )
}

export default Footer
