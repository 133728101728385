import config from '../../../config'
import { authenticatedFetch } from '../../../utils/functions/authenticatedFetch'

/*
 * METHOD TO SEARCH USERS
 */
export const searchUsers = (params) => {
  let responseCode = ''
  const method = 'GET'
  let url = `${config.baseURL}/api/search/user?query=${params.query}&limit=${5}`
  const headers = {
    'Content-Type': 'application/json'
  }

  return (dispatch) => {
    console.log('Sending search users request to CivilTalk', url)

    // Return the fetch so react components calling 'store.dispatch()' can use 'then()'
    return authenticatedFetch(url, {
      method,
      headers,
      credentials: 'include'
    })
      .then((response) => {
        responseCode = response.status

        return response.json()
      })
      .then((data) => {
        if (responseCode === 200) {
          return {
            error: false,
            code: responseCode,
            message: data.message,
            profiles: data.profiles
          }
        }

        console.log(data.message)

        return {
          error: true,
          code: responseCode,
          message: data.message
        }
      })
      .catch((error) => {
        console.log(error)

        return {
          error: true,
          code: '',
          message: error
        }
      })
  }
}
