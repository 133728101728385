import React, { useRef, useState, useEffect } from 'react'
import { Redirect, withRouter } from 'react-router-dom'
import Posts from '../../partials/posts/posts'
// import RedesignNotice from '../../partials/redesign-notice/redesign-notice'
// import VerificationWidget from '../../partials/verification-widget/verification-widget'
import SponsoredBy from '../../partials/sponsored-by/sponsored-by'
import RelatedTags from '../../partials/related-tags/related-tags'
import SuggestedConnections from '../../partials/suggested-connections/suggested-connections'
// import DonateTokens from '../../partials/donate-tokens/donate-tokens'
import Quote from '../../partials/quote/quote'
import SkeletonTake from '../../skeletons/skeleton-take'
import {
  MainContainer,
  LeftContainer,
  LeftInnerContainer,
  RightContainer,
  HeadingPrimary
} from './styled/tag-comps'
import { getResponsiveStyles } from './responsive-styles/tag'

import useWidth from './hooks/use-width'
import SuggestedOrganizations from '../../partials/suggested-organizations/suggested-organizations'

const Tag = (props) => {
  const { match } = props
  const mainRef = useRef(null)
  const [loaded, setLoaded] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [tagIds, setTagIds] = useState([])
  const [width] = useWidth()
  const [responsiveStyles, setResponsiveStyles] = useState(getResponsiveStyles(width))
  const [fullscreen, setFullScreen] = useState(false)

  const mixinComponents = [
    // <RedesignNotice key={'1'} />,
    <SponsoredBy key={'1'} />,
    // <VerificationWidget key={'2'} />,
    <RelatedTags
      key={'3'}
      tagIds={tagIds && tagIds.length > 0 ? tagIds : null}
      limit={10}
      hideReferenceTag={true}
      hideOnNoResults={true}
    />,
    <SuggestedConnections
      key={'4'}
      tagIds={tagIds && tagIds.length > 0 ? tagIds : null}
      disableRandomSuggestions={true}
      hideOnNoResults={true}
    />,
    <Quote key={'5'} />
  ]

  useEffect(() => {
    setResponsiveStyles(getResponsiveStyles(width))
    if (width !== null && width <= 892) {
      setFullScreen(true)
    }
    if (width !== null && width > 892) {
      setFullScreen(false)
    }
    setLoaded(true)
  }, [width])

  useEffect(() => {
    const { tagId } = match.params

    if (isLoading) {
      setTagIds(tagId ? [tagId] : [])
      setIsLoading(false)
    }
  }, [isLoading, match.params])

  if (isLoading) return null

  if (!isLoading) {
    if (!tagIds.length === 0) {
      return <Redirect to="/page-not-found" />
    }
  }

  return (
    <MainContainer
      ref={mainRef}
      marginTop={responsiveStyles.mainContainer.marginTop}
      padding={responsiveStyles.mainContainer.padding}>
      {loaded && (
        <>
          <LeftContainer width={responsiveStyles.leftContainer.width}>
            <LeftInnerContainer style={fullscreen ? { padding: '1.5rem 1.3rem' } : {}}>
              <HeadingPrimary>Let's see what the community is discussing:</HeadingPrimary>

              {isLoading ? (
                <SkeletonTake />
              ) : (
                <Posts
                  variant={'general'}
                  tags={tagIds}
                  setTags={setTagIds}
                  mixinComponents={fullscreen ? mixinComponents : []}
                />
              )}
            </LeftInnerContainer>
          </LeftContainer>

          <RightContainer display={responsiveStyles.rightContainer.display}>
            {/* <RedesignNotice /> */}
            {/* <VerificationWidget /> */}
            <SponsoredBy />
            <RelatedTags
              tagIds={tagIds && tagIds.length > 0 ? tagIds : null}
              limit={10}
              hideReferenceTag={true}
            />
            <SuggestedOrganizations
              disableRandomSuggestions={true}
              disableFromInsights={false}
              disableFromInterests={false}
              hideOnNoResults={true}
              tagIds={tagIds && tagIds.length > 0 ? tagIds : null}
            />

            <SuggestedConnections
              disableRandomSuggestions={true}
              disableFromInsights={false}
              disableFromInterests={false}
              hideOnNoResults={true}
              tagIds={tagIds && tagIds.length > 0 ? tagIds : null}
            />
            {/* <DonateTokens /> */}
            <Quote />
          </RightContainer>
        </>
      )}
    </MainContainer>
  )
}

export default withRouter(Tag)
