import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import withStyles from '@mui/styles/withStyles'
import { Skeleton } from '@mui/material'
import {
  ImageSkeletonPlaceholder,
  DetailsContainer
} from '../main/profile/components/header/styled/header-comps'

const animation = 'wave'

const styles = (theme) => ({
  name: {
    height: '2rem',
    width: '14rem',
    margin: '2rem 0rem 0rem 10rem',
    [theme.breakpoints.down('md')]: {
      margin: '1rem auto'
    }
  },
  location: {
    height: '1.75rem',
    width: '12rem',
    marginLeft: '10rem',
    [theme.breakpoints.down('md')]: {
      margin: '0 auto'
    }
  }
})

class SkeletonProfileHeader extends Component {
  render() {
    const { classes } = this.props

    return (
      <>
        <ImageSkeletonPlaceholder />
        <DetailsContainer>
          <Skeleton animation={animation} variant="text" component="h1" className={classes.name} />
          <Skeleton
            animation={animation}
            variant="text"
            component="h2"
            className={classes.location}
          />
        </DetailsContainer>
      </>
    )
  }
}

SkeletonProfileHeader.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withRouter(withStyles(styles)(SkeletonProfileHeader))
