import { suggestedOrganizationActionTypes } from '../action-creators/suggested-organization'
// import setNestedPropFromString from "../../utils/functions/setNestedPropFromString.js"

const copyOrganizations = (organizations) => {
  return organizations.map((organization) => copyOrganization(organization))
}

const copyOrganization = (organization) => ({
  ...organization,
  // phone: { ...organization.phone },
  // address: { ...organization.address },
  // tagsIds: [...organization.tagIds]
})

// FOR SUGGESTED PROFILES
const suggestedOrganizationsReducer = (state = {}, action) => {
  switch (action.type) {
    case suggestedOrganizationActionTypes.SET_SUGGESTED_ORGANIZATIONS:
      return copyOrganizations(action.organizations)

    case suggestedOrganizationActionTypes.ADD_SUGGESTED_ORGANIZATION_FOLLOWER:
      const newOrganizations3 = copyOrganizations(state)

      if (newOrganizations3[action.organizationId]) {
        newOrganizations3[action.organizationId].followers = newOrganizations3[action.organizationId].followers + 1
      }

      return newOrganizations3

    case suggestedOrganizationActionTypes.REMOVE_SUGGESTED_ORGANIZATION_FOLLOWER:
      const newOrganizations4 = copyOrganizations(state)

      if (newOrganizations4[action.organizationId]) {
        newOrganizations4[action.organizationId].followers = newOrganizations4[action.organizationId].followers - 1
      }

      return newOrganizations4

    case suggestedOrganizationActionTypes.CLEAR_SUGGESTED_ORGANIZATION:
      return {}

    default:
      return state
  }
}

export default suggestedOrganizationsReducer
