import React, { useRef, useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
import { useSelector } from 'react-redux'
import { history } from '../../../history/history'
import Header from './components/header/header'
import Activity from './components/activity/activity'
import Discover from './components/discover/discover'
import MyCourses from '../my-courses/my-courses'
import InviteFriends from '../../partials/invite-friends/invite-friends'
import Quote from '../../partials/quote/quote'
import WelcomeDialog from './components/welcome/welcome'
import { MainContainer, LeftContainer, RightContainer } from './styled/home-private-comps'
import { getResponsiveStyles } from './responsive-styles/home-private'
import Connect from './components/connect/connect'

const HomePrivate = (props) => {
  const { params } = props.match
  const mainRef = useRef(null)
  const [action, setAction] = useState('activity')
  const [didRecognizeRef, setDidRecognizeRef] = useState(false)
  const [componentWidth, setComponentWidth] = useState(null)
  const [responsiveStyles, setResponsiveStyles] = useState(getResponsiveStyles(componentWidth))
  const showWelcome = useSelector(({ currentProfile }) => currentProfile.showWelcome)

  const handleSetComponentWidth = () => {
    const width = mainRef.current ? mainRef.current.clientWidth : window.innerWidth

    setComponentWidth(width)
  }

  useEffect(() => {
    window.addEventListener('resize', handleSetComponentWidth)

    return () => {
      window.removeEventListener('resize', handleSetComponentWidth)
    }
  }, [])

  useEffect(() => {
    if (!didRecognizeRef) {
      if (mainRef.current !== null && typeof mainRef.current !== 'undefined') {
        setComponentWidth(mainRef.current.clientWidth)
        setDidRecognizeRef(true)
      }
    }
  }, [mainRef, didRecognizeRef])

  useEffect(() => {
    setResponsiveStyles(getResponsiveStyles(componentWidth))
  }, [componentWidth])

  useEffect(() => {
    const possibleActions = ['learn', 'post', 'activity', 'discover', 'connect']

    if (params.action && !_.includes(possibleActions, params.action)) {
      history.push('/page-not-found')
    }

    if (params.action) {
      setAction(params.action)
    }
  }, [params.action])

  const ActivityProps = { action, setAction }

  return (
    <MainContainer
      ref={mainRef}
      marginTop={responsiveStyles.mainContainer.marginTop}
      padding={responsiveStyles.mainContainer.padding}>
      <LeftContainer width={responsiveStyles.leftContainer.width}>
        <Header action={action} setAction={setAction} />

        {action === 'activity' && <Activity {...ActivityProps} />}
        {/* action === 'learn' && <Learn /> **/}
        {/* action === 'post' && <Post /> **/}
        {action === 'my-courses' && <MyCourses />}
        {action === 'discover' && <Discover />}
        {action === 'connect' && <Connect />}
      </LeftContainer>
      <RightContainer display={responsiveStyles.rightContainer.display}>
        <InviteFriends />
        <Quote />
      </RightContainer>
      {showWelcome && <WelcomeDialog showWelcome={showWelcome} />}
    </MainContainer>
  )
}

export default withRouter(HomePrivate)
