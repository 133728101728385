import { mobileDrawerActionTypes } from '../action-creators/mobile-drawer.js'

// FOR Mobile Drawer
const mobileDrawerReducer = (state = false, action) => {
  switch (action.type) {
    case mobileDrawerActionTypes.SET_MOBILE_DRAWER:
      return action.open
    case mobileDrawerActionTypes.CLEAR_MOBILE_DRAWER:
      return false
    default:
      return state
  }
}

export default mobileDrawerReducer
