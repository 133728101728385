import styled from 'styled-components'

export const Header = styled.div`
  box-sizing: border-box;
  padding: 1rem 1rem;
  line-height: 1.6rem;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: 1.6rem;
  font-weight: ${(props) => props.theme.typography.fontWeight.semiBold};
  color: ${(props) => props.theme.palette.text.primary};
`
