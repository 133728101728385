import { appLoadedActionTypes } from '../action-creators/app-loaded.js'

// FOR APP LOADED STATE
const appLoadedReducer = (state = false, action) => {
	switch (action.type) {
		case appLoadedActionTypes.SET_APP_LOADED:
			return true
		case appLoadedActionTypes.CLEAR_APP_LOADED:
			return false
		default:
			return state
	}
}

export default appLoadedReducer