//////////////////
// ACTION TYPES //
//////////////////

export const relatedTagActionTypes = {
  SET_RELATED_TAGS: "SET_RELATED_TAGS",
  CLEAR_RELATED_TAGS: "CLEAR_RELATED_TAGS",
}

/////////////
// ACTIONS //
/////////////

// RELATED TAGS
const set = (tags) => {
  return {
    type: relatedTagActionTypes.SET_RELATED_TAGS,
    tags,
  }
}

const clear = () => {
  return {
    type: relatedTagActionTypes.CLEAR_RELATED_TAGS,
  }
}

export const relatedTagsActions = {
  set,
  clear,
}
