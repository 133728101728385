import { useEffect, useState } from 'react'
import { useParams, withRouter } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import find from 'lodash/find'
import { updateEmail } from '../../../../../../../redux/thunks/organizations/updateEmail'
import FormError from '../../../../../../alerts/form-error'
import makeStyles from '@mui/styles/makeStyles'
import Button from '@mui/material/Button'
import './css/phone-input.css'
import { ItemContainer, InputLabel, InputsContainer, Input } from './styled/email-form-comps'
import config from '../../../../../../../config'
import SendEmailUpdateValidation from '../../../../../my-account/components/account-details/components/email-form/validations/send-email-update-email'

const useStyles = makeStyles((theme) => ({
  actionButton: {
    textTransform: 'capitalize',
    width: '15rem',
    margin: '1rem 0 0 35%',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light
    },
    [`@media (max-width: ${config.UI.drawer_breakpoint.max})`]: {
      width: '65%'
    },
    '@media (max-width: 600px)': {
      width: '100%',
      margin: '1rem 0 0 0'
    }
  },
  smallerActionButton: {
    width: '11rem',
    margin: '1rem 0 0 0',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light
    },
    [`@media (max-width: 1100px)`]: {
      margin: '1rem 0 0 35%',
      width: '65%'
    },
    '@media (max-width: 600px)': {
      width: '100%',
      margin: '1rem 0 0 0'
    }
  },
  resendButton: {
    width: '11rem',
    margin: '1rem 1rem 0 35%',
    [`@media (max-width: 1100px)`]: {
      margin: '1rem 0 0 35%',
      width: '65%'
    },
    [`@media (max-width: ${config.UI.drawer_breakpoint.max})`]: {
      width: '65%'
    },
    '@media (max-width: 600px)': {
      width: '100%',
      margin: '1rem 0 0 0'
    }
  }
}))

function EmailForm() {
  const classes = useStyles()
  const { orgId } = useParams()
  const organizations = useSelector(({ organizations }) => organizations)
  const organization = find(organizations, { _id: orgId })
  const dispatch = useDispatch()
  const [email, setEmail] = useState('')
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState('')

  useEffect(() => {
    if (organization) {
      setEmail(organization.email)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization])

  const handleInputChange = (e) => {
    setEmail(e.target.value)
  }

  const handleUpdateEmail = (event) => {
    setProcessing(true)

    const validationError = SendEmailUpdateValidation({ email }, organization)

    if (validationError.error !== error) {
      console.log('[VALIDATION ERROR]: ', validationError.error)

      setError(validationError.error)
      // *** FormError component will display error to the user *** //
      setProcessing(false)
    } else {
      dispatch(updateEmail({ email, orgId })).then((data) => {
        if (!data.error) {
          console.log('[SUCCESS]: ', 'Successfully updated email!')

          setProcessing(false)
        } else {
          console.log('[FAIL]: ', 'Failed to update email.')

          setProcessing(false)
        }
      })
    }
  }

  if (!organization) return null

  return (
    <ItemContainer>
      <FormError message={error} />

      <InputLabel>Email:</InputLabel>

      <>
        <InputsContainer>
          <Input
            type="email"
            name="email"
            placeholder="Enter new email"
            value={email}
            width="15rem"
            onChange={handleInputChange}
          />
        </InputsContainer>

        <Button
          variant="contained"
          color="primary"
          className={classes.actionButton}
          onClick={handleUpdateEmail}
          disabled={email.trim() === organization?.email || processing}>
          Submit
        </Button>
      </>
    </ItemContainer>
  )
}

export default withRouter(EmailForm)
