import _ from "lodash"

const getFilteredFollows = (follows, filter, userId) => {
  return _.filter(follows, (follow) => {
    if (filter === "followers") {
      return follow.userId === userId
    } else {
      return follow.follower === userId
    }
  })
}

export default getFilteredFollows
