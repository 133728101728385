import React from 'react'
// import { useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
// import { history } from '../../../../../history/history'
// import isVerifiedUser from '../../../../../utils/functions/isVerifiedUser'
// import BillingIAP from './iap/billing-iap'
import BillingStripe from './stripe/billing-stripe'
import Unsubscribed from './unsubscribed/unsubscribed'

function BillingInfo() {
  // const currentProfile = useSelector(({ currentProfile }) => currentProfile)

  return (
    <div>
      {/* <BillingIAP /> */}
      <BillingStripe />
      {/* <Unsubscribed /> */}
    </div>
  )
}

export default withRouter(BillingInfo)
