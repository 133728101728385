import { notificationProfileActionTypes } from '../action-creators/notification-profiles.js'
// import setNestedPropFromString from '../../utils/functions/setNestedPropFromString.js'

const copyProfiles = (profiles) => {
  const keys = Object.keys(profiles)
  let profilesCopy = {}

  for (var i = 0; i < keys.length; i++) {
    profilesCopy[keys[i]] = {
      ...profiles[keys[i]],
      rating: { ...profiles[keys[i]].rating },
      personalAddress: {
        ...profiles[keys[i]].personalAddress,
        loc: profiles[keys[i]].personalAddress.loc
          ? {
              ...profiles[keys[i]].personalAddress.loc,
              coordinates: [...profiles[keys[i]].personalAddress.loc.coordinates]
            }
          : {}
      },
      tooltips: {
        ...profiles[keys[i]].tooltips
      },
      privacySettings: {
        ...profiles[keys[i]].privacySettings
      },
      tagsOfInterest: profiles[keys[i]].tagsOfInterest ? [...profiles[keys[i]].tagsOfInterest] : [],
      tagsOfKnowledge: profiles[keys[i]].tagsOfKnowledge
        ? [...profiles[keys[i]].tagsOfKnowledge]
        : []
    }
  }

  return profilesCopy
}

// FOR LOADED PROFILES
const notificationProfilesReducer = (state = {}, action) => {
  // console.log('*** ACTION ***', action)
  switch (action.type) {
    case notificationProfileActionTypes.SET_NOTIFICATION_PROFILES:
      return copyProfiles(action.profiles)
    case notificationProfileActionTypes.ADD_NOTIFICATION_PROFILES:
      let newProfiles1 = copyProfiles(state)
      const keys1 = Object.keys(action.profiles)

      for (var j = 0; j < keys1.length; j++) {
        newProfiles1[keys1[j]] = action.profiles[keys1[j]]
      }

      return newProfiles1
    case notificationProfileActionTypes.CLEAR_NOTIFICATION_PROFILES:
      return {}
    default:
      return state
  }
}

export default notificationProfilesReducer
