import theme from '../../../../../../../../res/mui-theme'

const common = {
  width: '3.5rem',
  minWidth: '3.5rem',
  height: '2.25rem',
  paddingX: '0',
  '& span': {
    fontSize: '1.2rem !important'
  },
  color: theme.palette.text.primary,
  borderRadius: '.2rem',
  cursor: 'pointer',
  '& > *': {
    pointerEvents: 'none'
  },
  '& .MuiLoadingButton-loadingIndicator': {
    height: 'unset !important'
  },
  '@media (max-width: 1200px)': {
    width: '2.75rem',
    minWidth: '2.75rem'
  }
}

export const styles = {
  unfollow: {
    ...common,
    backgroundColor: theme.palette.secondary.light,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main
    }
  },
  follow: {
    ...common
  }
}
