import styled from 'styled-components'

export const Heading = styled.p`
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.extraLarge};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  color: ${(props) => props.theme.typography.fontColor.bodyCopy};
`

export const Subheading = styled.p`
  margin-top: 1rem;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.small};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  color: ${(props) => props.theme.typography.fontColor.headerCopy};
`

export const RecipientContainer = styled.div`
  margin-top: 2rem;
`

export const RecipientImage = styled.div`
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: block;
  width: 5rem;
  height: 5rem;
  border: ${(props) => `3px solid ${props.theme.palette.primary.main}`};
  border-radius: 50%;
  margin: 0 auto;
`

export const RecipientName = styled.p`
  margin-top: .5rem;
  text-align: center;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: 1.2rem;
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  color: ${(props) => props.theme.typography.fontColor.bodyCopy};
`

export const TokensContainer = styled.div`
  margin-top: 1rem;
  text-align: center;
`

export const Tokens = styled.span`
  display: inline-block;
  vertical-align: top;
  line-height: 2.2rem;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: 2.2rem;
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  color: ${(props) => props.theme.palette.secondary.main};
`

export const TokensLabel = styled.p`
  margin-top: 0.1rem;
  text-align: center;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: 0.8rem;
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  color: #999;
`

export const ButtonContainer = styled.div`
  margin-top: 2rem;
  text-align: center;
`
