//////////////////
// ACTION TYPES //
//////////////////

export const popularTagActionTypes = {
  SET_POPULAR_TAGS: "SET_POPULAR_TAGS",
  CLEAR_POPULAR_TAGS: "CLEAR_POPULAR_TAGS",
}

/////////////
// ACTIONS //
/////////////

// POPULAR TAGS
const set = (tags) => {
  return {
    type: popularTagActionTypes.SET_POPULAR_TAGS,
    tags,
  }
}

const clear = () => {
  return {
    type: popularTagActionTypes.CLEAR_POPULAR_TAGS,
  }
}

export const popularTagsActions = {
  set,
  clear,
}
