import config from '../../../config'
import { authenticatedFetch } from '../../../utils/functions/authenticatedFetch'
import { assignedRolesActions } from '../../action-creators/assigned-roles'

export const getAssignedRolesByRoleIDs = (params = {}, options = {}) => {
  let responseCode = ''
  const method = 'GET'
  const url = `${config.baseURL}/api/assigned-role/role/?roleIds=${JSON.stringify(params.roleIds)}`
  const headers = {
    'Content-Type': 'application/json'
  }

  // Thunk middleware knows how to handle functions.
  // It passes the dispatch method as an argument to the function,
  // thus making it able to dispatch actions itself.
  return (dispatch) => {
    console.log('Sending getAssignedRolesByRoleID request to CivilTalk')

    // Return the fetch so react components calling 'store.dispatch()' can use 'then()'
    return authenticatedFetch(url, {
      method,
      headers,
      credentials: 'include'
    })
      .then((response) => {
        responseCode = response.status

        return response.json()
      })
      .then((data) => {
        if (responseCode === 200) {
          if (!options.disableRedux) {
            dispatch(assignedRolesActions.set(data.assignedRoles))
          }

          return {
            error: false,
            code: responseCode,
            message: data.message,
            assignedRoles: data.assignedRoles
          }
        } else {
          console.log(data.message)

          return {
            error: true,
            code: responseCode,
            message: data.message
          }
        }
      })
      .catch((error) => {
        console.log(error)

        return {
          error: true,
          code: '',
          message: error
        }
      })
  }
}
