//////////////////
// ACTION TYPES //
//////////////////

export const inviteFriendsActionTypes = {
    SET_INVITE_FRIENDS: "SET_INVITE_FRIENDS",
    CLEAR_INVITE_FRIENDS: "CLEAR_INVITE_FRIENDS",
  }
  
  /////////////
  // ACTIONS //
  /////////////
  
  // INVITE FRIENDS MODAL
  const set = (open) => {
    return {
      type: inviteFriendsActionTypes.SET_INVITE_FRIENDS,
      open
    }
  }
  
  const clear = () => {
    return {
      type: inviteFriendsActionTypes.CLEAR_INVITE_FRIENDS,
    }
  }
  
  export const inviteFriendsActions = {
      set,
      clear,
  }
  