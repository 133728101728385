import config from '../../../config.js'
import { authenticatedFetch } from '../../../utils/functions/authenticatedFetch.js'
import { loaderActions } from '../../action-creators/loader.js'
import { logout } from '../users/logout.js'

/*
 * METHOD TO GET COURSE BY ID
 * used on the reports page to get
 * TODO: should probably be renamed to getCourseMembersReportDetails
 * calls the coursesControllers.getCourseMembers API endpoint
 */
export function getCourseByID(params, options = {}) {
  let responseCode = ''
  const method = 'GET'
  const url = `${config.baseURL}/api/course/${params.courseId}/members`
  const headers = {
    'Content-Type': 'application/json'
  }

  // Thunk middleware knows how to handle functions.
  // It passes the dispatch method as an argument to the function,
  // thus making it able to dispatch actions itself.
  return function (dispatch) {
    // First dispatch: the app state is updated to inform
    // that the API call is starting.
    console.log('Sending get course by ID request to CivilTalk')

    dispatch(loaderActions.startLoader())

    // Return the fetch so react components calling 'store.dispatch()' can use 'then()'
    return authenticatedFetch(url, {
      method,
      headers,
      credentials: 'include'
    })
      .then((response) => {
        dispatch(loaderActions.stopLoader())
        responseCode = response.status

        if (responseCode === 401) {
          dispatch(logout())

          return {
            success: false,
            code: responseCode,
            message: 'Unauthorized'
          }
        }

        return response.json()
      })
      .then((data) => {
        if (responseCode === 200) {
          return {
            success: true,
            code: responseCode,
            message: data.message,
            data
          }
        }

        return {
          success: false,
          code: responseCode,
          message: data.message
        }
      })
      .catch((error) => {
        console.error(error)
        dispatch(loaderActions.stopLoader())

        return {
          success: false,
          code: '',
          message: error
        }
      })
  }
}
