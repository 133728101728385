import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { MembersContainer } from './styled/members-comps'
import { getProfilesByUserIDs } from '../../../../../redux/thunks/profiles/getProfilesByUserIDs'
import Profile from '../profile/profile'
import { CircularProgress } from '@mui/material'
import some from 'lodash/some'

function Members({ course }) {
  const [isLoading, setIsLoading] = useState(true)
  const [profiles, setProfiles] = useState({})
  const dispatch = useDispatch()
  const currentUser = useSelector(({ currentUser }) => currentUser)
  const follows = useSelector(({ follows }) => follows)

  useEffect(() => {
    const userIds = course.members

    dispatch(getProfilesByUserIDs({ userIds })).then((data) => {
      if (!data.error) {
        console.log('[SUCCESS]: ', 'Successfully retrieved course member profiles!')
      } else {
        console.error('[FAIL]: ', 'Failed to retrieve course by course ID.', data)
      }

      setProfiles({ ...data.profiles })
      setIsLoading(false)
    })
  }, [])

  return (
    <MembersContainer>
      {isLoading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress
            color="secondary"
            // size={'1rem'}
            sx={{}}
          />
        </div>
      ) : (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {Object.values(profiles).map((profile) => {
            const isFollowing = some(follows, {
              follower: currentUser.id,
              userId: profile.userId
            })

            return <Profile key={profile._id} profile={profile} isFollowing={isFollowing} />
          })}
        </div>
      )}
    </MembersContainer>
  )
}

export default Members
