import styled from 'styled-components'
// import config from '../../../../config.js'

export const ImageUploadContainer = styled.div`
  /* display: flex; */
  margin: 0.5rem 0;
  /* text-align: center; */
`

export const PreviewContainer = styled.div`
  position: relative;
  width: 18rem;

  @media screen and (max-width: 380px) {
    width: 17.85rem;
  }

  @media screen and (max-width: 375px) {
    width: 17.65rem;
  }

  @media screen and (max-width: 370px) {
    width: 17.4rem;
  }

  @media screen and (max-width: 365px) {
    width: 17.25rem;
  }

  @media screen and (max-width: 360px) {
    width: 16.775rem;
  }

  @media screen and (max-width: 355px) {
    width: 14.5rem;
  }
`

export const PostImagePreview = styled.img`
  width: inherit;
  border: 2px solid #777;
  border-radius: 0.2rem;
`
