import styled from 'styled-components'
import theme from '../../../../res/theme.js'
// import config from '../../../../config.js'

// COMMON FORMATTING

export const SectionSubheader = styled.p`
  margin-top: 1rem;
  font-family: ${theme.typography.fontFamily};
  font-size: ${theme.typography.fontSizes.regular};
  font-weight: ${theme.typography.fontWeight.regular};
  color: ${theme.typography.fontColor.bodyCopy};
`
export const SummaryContainer = styled.div`
  margin-top: 1rem;
  min-height: 2rem;
  padding-bottom: 0.2rem;
  ${'' /* border-bottom: 1px solid #eee; */}
  text-align: right;
  position: relative;
`

export const StripeImageStart = styled.img`
  display: block;
  width: auto;
  height: 2rem;
  margin-left: 1rem;
  position: absolute;
  left: 100%;
  top: 0.6rem;

  @media (max-width: 900px) {
    position: static;
    margin: 1rem auto 0 auto;
  }
`

export const StripeImageCard = styled.img`
  display: block;
  width: auto;
  height: 1.8rem;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);

  @media (max-width: 400px) {
    display: block;
    margin: 0 auto;
    position: static;
    transform: none;
  }
`

export const TotalContainer = styled.div`
  display: inline-block;
  ${'' /* border-bottom: 1px solid #eee; */}
  text-align: center;

  @media (max-width: 400px) {
    display: block;
    margin-top: 1rem;
  }
`

export const TotalLabel = styled.span`
  display: inline-block;
  margin-right: 1rem;
  color: ${theme.typography.fontColor.bodyCopy};
  font-family: ${theme.typography.fontFamily};
  font-size: ${theme.typography.fontSizes.regular};
  font-weight: ${theme.typography.fontWeight.semiBold};
`

export const Total = styled.span`
  display: inline-block;
  color: ${theme.typography.fontColor.bodyCopy};
  font-family: ${theme.typography.fontFamily};
  font-size: 1.4rem;
  font-weight: ${theme.typography.fontWeight.bold};
`


