import moment from 'moment'

const getRequirementsSummary = (requirements) => {
  if (!requirements || Object.keys(requirements).length === 0) return ''

  if (requirements?.disabled_reason) {
    if (requirements?.past_due && requirements?.past_due?.length) {
      return 'Your billing account has issues that must be resolved immediately. Please update your settings in your Stripe-hosted dashboard to enable your billing account.'
    }
  }

  if (requirements?.current_deadline) {
    const { current_deadline } = requirements

    if (current_deadline) {
      let returnStr = ''
      const deadlineDate = new Date(requirements.current_deadline)
      const dateStr = moment(deadlineDate).format('LL')
      const timeStr = moment(deadlineDate).format('LT')

      returnStr = returnStr.concat(
        `Your billing account has issues that will need to be resolved by ${dateStr} at ${timeStr}. Please update your settings in your Stripe-hosted dashboard to make sure your billing account does not get disabled.`,
        returnStr
      )

      // if (requirements?.errors?.length) {
      //   returnStr = returnStr.concat(
      //     `\nThese requirements were in part caused by the following errors:\n`
      //   )
      //   returnStr = requirements.errors.reduce((result, error) => {
      //     return result.concat(`=> ${error.requirement}: ${error.reason}\n`)
      //   }, returnStr)
      // }

      return returnStr
    }
  }

  return ''
}

export default getRequirementsSummary
