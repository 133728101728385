import jwtDecode from 'jwt-decode'
import config from '../../../config'
import { currentUserActions } from '../../action-creators/current-user.js'
import { currentProfileActions } from '../../action-creators/current-profile.js'
import { isAuthenticatedActions } from '../../action-creators/is-authenticated.js'
import { myCoursesActions } from '../../action-creators/my-courses'
import { tokenExpActions } from '../../action-creators/token-exp.js'
import { loaderActions } from '../../action-creators/loader.js'
import { firebaseLogin } from '../firebase/firebaseLogin.js'
import { logout } from './logout.js'

/*
 * METHOD TO CHECK IF USER IS LOGGED IN AUTOMATICALLY
 */
export const checkAuth = () => {
  let responseCode = ''
  const method = 'POST'
  const url = `${config.baseURL}/api/user/auto-auth`

  // Thunk middleware knows how to handle functions.
  // It passes the dispatch method as an argument to the function,
  // thus making it able to dispatch actions itself.
  return (dispatch) => {
    console.log('Sending automatic login request to CivilTalk')

    dispatch(loaderActions.startLoader())

    // Return the fetch so react components calling 'store.dispatch()' can use 'then()'
    return fetch(url, { method, credentials: 'include' })
      .then((response) => {
        responseCode = response.status

        if (responseCode !== 200) {
          dispatch(logout(true))

          return {
            error: true,
            code: responseCode,
            message: 'Unauthorized'
          }
        }

        return response.json()
      })
      .then((data) => {
        if (data.error) {
          return {
            error: true,
            code: responseCode,
            message: 'Unauthorized'
          }
        }

        if (responseCode === 200) {
          dispatch(currentUserActions.setUser(data.user))
          dispatch(currentProfileActions.setProfile(data.profile))
          dispatch(isAuthenticatedActions.setAuthentication(true))
          dispatch(tokenExpActions.set(jwtDecode(data.access_token).exp))
          dispatch(myCoursesActions.setCourses(data.profile.courses))
          dispatch(loaderActions.stopLoader())

          return {
            error: false,
            code: responseCode,
            message: 'Automatic authentication successful!',
            firebase_token: data.firebase_token
          }
        }

        return {
          error: true,
          code: responseCode,
          message: 'Error during automatic authentication.'
        }
      })
      .then((nextData1) => {
        if (nextData1.error) {
          return nextData1
        }

        if (nextData1.firebase_token) {
          return dispatch(firebaseLogin({ firebase_token: nextData1.firebase_token }))
        }

        throw new Error('[ERROR]: No firebase token found in response.')
      })
      .catch((error) => {
        console.log(error)

        return {
          error: true,
          code: '',
          message: error
        }
      })
  }
}
