import React, { useEffect, useState, useRef } from 'react'
import { withRouter } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { getCourseByCourseID } from '../../../redux/thunks/courses/getCourseByCourseID'
import { getPromptsByCourseId } from '../../../redux/thunks/prompts/getPromptsByCourseId'
import Header from './components/header/header'
import FormError from '../../alerts/form-error'
import Tabs from './components/tabs/tabs'
import Details from './components/details/details'
import Prompts from './components/prompts/prompts'
import Members from './components/members/members'

import Posts from '../../partials/posts/posts'
// import Tooltip from '../../partials/tooltip/tooltip'
import { useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import {
  ProfileContainer,
  ContentContainer,
  FeedContainer,
  FeedListContainer
} from './styled/course-comps'

function Course(props) {
  const [course, setCourse] = useState({})
  const [prompts, setPrompts] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [apiError, setApiError] = useState('')
  const [componentError, setComponentError] = useState('')
  const [tab, setTab] = useState('public')
  // const mainRef = useRef(null)
  const dispatch = useDispatch()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const currentUser = useSelector(({ currentUser }) => currentUser)
  // const currentProfile = useSelector(({ currentProfile }) => currentProfile)
  const isInstructor = currentUser.id === course.instructorId

  useEffect(() => {
    const mobileView = window.innerWidth <= 1022
    if (mobileView) {
      setTab('details')
    }
  }, [])

  useEffect(() => {
    if (isLoading) {
      const { courseId } = props.match.params

      dispatch(getCourseByCourseID({ courseId }))
        .then((data) => {
          if (!data.error) {
            console.log('[SUCCESS]: ', 'Successfully retrieved course by course ID!')
          } else {
            setApiError(data.message)
            setComponentError('Failed to retrieve course by course ID.')
            console.log('[FAIL]: ', 'Failed to retrieve course by course ID.')
          }

          setCourse({ ...data.course[0] })
          setIsLoading(false)

          return dispatch(getPromptsByCourseId({ courseId }))
        })
        .then((data) => {
          if (!data.error) {
            console.log('[SUCCESS]: ', 'Successfully retrieved prompts by course ID!')

            setPrompts(data.prompts)
          } else {
            setApiError(data.message)
            setComponentError('Failed to retrieve prompts by course ID.')
            console.log('[FAIL]: ', 'Failed to retrieve prompts by course ID.')
          }
        })
        .catch((e) => {
          setIsLoading(false)
        })
    }
  }, [props.match.params.courseId])

  if (isLoading || !course) return null

  return (
    <ProfileContainer>
      <Header course={course} />

      <div style={{ marginLeft: '1rem' }}>
        <FormError message={apiError} />
        <FormError message={componentError} />
      </div>

      <ContentContainer>
        {!isMobile && (
          <Details
            course={course}
            // isCurrentUser={isCurrentUser}
          />
        )}
        <FeedContainer>
          <Tabs tab={tab} setTab={setTab} />

          <FeedListContainer>
            {tab === 'details' && isMobile && (
              <Details
                course={course}
                // isCurrentUser={isCurrentUser}
              />
            )}
            {tab === 'public' && (
              <Posts
                variant={'course-feed'}
                tags={[]}
                // userId={userId}
                hideSorting={true}
                hideFilters={false}
                searchStyle={{ backgroundColor: 'white', boxShadow: 'none !important' }}
                searchVariant={'no-shadow'}
                hideOnNoResults={false}
                mixinComponents={[]}
                courseId={props.match.params.courseId}
                prompts={prompts}
              />
            )}
            {tab === 'prompts' && (
              <div>
                <Prompts
                  prompts={prompts}
                  setPrompts={setPrompts}
                  course={course}
                  isInstructor={isInstructor}
                />
              </div>
            )}
            {tab === 'members' && (
              <div>
                <Members course={course} />
              </div>
            )}
          </FeedListContainer>
        </FeedContainer>
      </ContentContainer>
    </ProfileContainer>
  )
}

export default withRouter(Course)
