import config from '../../../config'
import { loadedRebuttalsActions } from '../../action-creators/loaded-rebuttals.js'
import { actionResponseActions } from '../../action-creators/action-response.js'
import { authenticatedFetch } from '../../../utils/functions/authenticatedFetch'

/*
 * METHOD TO EDIT REBUTTAL
 */
export const editRebuttal = (params) => {
  let responseCode = ''
  const method = 'PUT'
  const url = `${config.baseURL}/api/rebuttal/edit/${params.rebuttalId}`
  const headers = {
    'Content-Type': 'application/json'
  }
  const requestBody = {
    'rebuttal': params.rebuttal,
    'emotion': params.emotion
  }
  const requestBodyJSON = JSON.stringify(requestBody)

  // Thunk middleware knows how to handle functions.
  // It passes the dispatch method as an argument to the function,
  // thus making it able to dispatch actions itself.
  return (dispatch) => {
    console.log('Sending edit rebuttal request to CivilTalk')

    // Return the fetch so react components calling 'store.dispatch()' can use 'then()'
    return authenticatedFetch(url, {
      method,
      body: requestBodyJSON,
      headers,
      credentials: 'include'
    })
      .then((response) => {
        responseCode = response.status

        return response.json()
      })
      .then((data) => {
        if (responseCode === 200) {
          dispatch(
            loadedRebuttalsActions.edit(params.rebuttalId, params.rebuttal /*params.emotion*/)
          )
          dispatch(actionResponseActions.createActionResponse('Response updated!'))

          return {
            error: false,
            code: responseCode,
            message: data.message
          }
        } else {
          console.log(data.message)

          return {
            error: true,
            code: responseCode,
            message: data.message
          }
        }
      })
      .catch((error) => {
        console.log(error)

        return {
          error: true,
          code: '',
          message: error
        }
      })
  }
}
