import { relatedTagActionTypes } from "../action-creators/related-tags.js"
import nestedSort from "../../utils/functions/nestedSort.js"

const copyRelatedTags = (tags) => {
  return tags.map((tag) => ({ ...tag }))
}

// For Related Tags
const relatedTagsReducer = (state = [], action) => {
  switch (action.type) {
    case relatedTagActionTypes.SET_RELATED_TAGS:
      return copyRelatedTags(nestedSort(action.tags, -1, "numPosts"))

    case relatedTagActionTypes.CLEAR_RELATED_TAGS:
      return []

    default:
      return state
  }
}

export default relatedTagsReducer
