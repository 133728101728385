import styled from 'styled-components'

export const MainContainer = styled.div``

export const InputContainer = styled.div`
  margin-top: 1.5rem;
`

export const InputLabel = styled.div`
  display: block;
  width: 100%;
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.typography.fontWeight.strong};
  color: ${({ theme }) => theme.typography.fontColor.bodyCopy};
`

export const RoleLabel = styled.div`
  display: inline-block;
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.typography.fontWeight.strong};
  color: ${({ theme }) => theme.typography.fontColor.bodyCopy};
`

export const InputDesc = styled.p`
  display: block;
  margin-top: 0.3rem;
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-size: 0.8rem;
  font-weight: ${({ theme }) => theme.typography.fontWeight.strong};
  color: ${({ theme }) => theme.typography.fontColor.headerCopy};
`

export const Input = styled.input`
  background-color: rgba(0, 0, 0, 0);
  display: block;
  width: 100%;
  max-width: 15rem;
  margin-top: .7rem;
  padding: 0.8rem 0.8rem;
  box-sizing: border-box;
  line-height: ${(props) => props.theme.typography.lineHeight};
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.regular};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  color: ${(props) => props.theme.typography.fontColor.bodyCopy};
  border: 1px solid #ddd;
  border-radius: 0.2rem;
  box-shadow: 0px 9px 7px -2px rgba(120, 120, 120, 0.1);
`
