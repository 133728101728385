import { useState, useEffect } from 'react' // TODO: useRef
import { withRouter } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'
import { analytics } from '../../../../../firebase/firebase'
// import { history } from '../../../../../history/history'
import { checkFollowingUser } from '../../../../../redux/thunks/follows/checkFollowingUser'
import { createUserFollow } from '../../../../../redux/thunks/follows/createUserFollow'
import { deleteFollowByUserID } from '../../../../../redux/thunks/follows/deleteFollowByUserID'
// import isVerifiedUser from '../../../../../utils/functions/isVerifiedUser'
import SkeletonProfileHeader from '../../../../skeletons/skeleton-profile-header'
// import ReportForm from '../../../../partials/report-form/report-form'
import ProfileAvatar from './profile-avatar'
import ImageUpload from '../../../../partials/image-upload/image-upload'
import useMediaQuery from '@mui/material/useMediaQuery'
import ButtonGroup from '@mui/material/ButtonGroup'
import EditButton from './edit-button'
// import TransferTokensButton from './transfer-tokens-button'
import FollowButton from './follow-button'
import ReportButton from './report-button'
import Tags from '../../../../partials/tags/tags'
import UserAccolades from '../../../../partials/user-accolades/user-accolades'
import getName from '../../../../../utils/functions/getName'
// import { makeStyles, createStyles } from '@mui/material/styles'
// import {
//   Button,
//   ClickAwayListener,
//   Grow,
//   Paper,
//   Popper,
//   MenuList,
//   MenuItem
// } from '@mui/material'
// import MenuIcon from '@mui/icons-material/MoreHoriz'
import {
  MainContainer,
  DetailsContainer,
  Name,
  Location,
  Knowledge,
  Interests,
  DropDownButtonContainer
  // NavItemContainer,
  // NavItem
} from './styled/header-comps'

// TODO: save all dropdown menu logic for later expansion
// const useStyles = makeStyles((theme) =>
//   createStyles({
//     actionButton: {
//       width: '11rem',
//       height: '2.7rem',
//       borderTopRightRadius: '0',
//       borderBottomRightRadius: '0',
//       position: 'absolute',
//       bottom: '3.2rem',
//       right: '5.5rem',
//       color: '#ffffff',
//       boxShadow: 'none',
//       '&:hover': {
//         backgroundColor: '#b31500',
//         color: '#ffffff',
//         boxShadow: 'none'
//       },
//       '&:hover svg': {
//         color: theme.palette.common.white
//       }
//     },
//     dropdownButton: {
//       borderRadius: '.1rem',
//       minHeight: '1.75rem',
//       height: '1.75rem',
//       minWidth: '2.5rem',
//       width: '2.5rem',
//       position: 'absolute',
//       top: '2rem',
//       right: '1.5rem',
//       [theme.breakpoints.down('md')]: {
//         top: '1.25rem',
//         right: '.75rem'
//       },
//       boxShadow: 'none',
//       backgroundColor: 'transparent',
//       color: 'white',
//       '&:hover': {
//         backgroundColor: 'rgba(0,0,0,.3)',
//         boxShadow: 'none'
//       },
//       '& svg': {
//         fontSize: '1.8rem',
//         position: 'absolute',
//         top: '50%',
//         left: '50%',
//         transform: 'translateX(-50%) translateY(-50%)'
//       }
//     },
//     menuList: {
//       padding: '.4rem 0'
//     },
//     dropdownItem: {
//       padding: '.4rem 1rem'
//     },
//     dropdownIcon: {
//       width: '1rem',
//       height: 'auto',
//       marginRight: '.7rem',
//       marginTop: '1px',
//       verticalAlign: 'middle'
//     },
//     actionIcon: {
//       display: 'inline-block',
//       marginRight: '.5rem',
//       verticalAlign: 'middle'
//     },
//     verifiedIcon: {
//       fill: '#ffffff',
//       position: 'absolute',
//       top: '50%',
//       left: '50%',
//       transform: 'translateX(-50%) translateY(-50%)'
//     }
//   })
// )

function Header(props) {
  const { profile, isCurrentUser } = props
  const [open, setOpen] = useState(false)
  // const [error, setError] = useState('')
  // TODO: save all dropdown menu logic for later expansion
  // const classes = useStyles()
  // const ref = useRef(null)
  // const [isReporting, setIsReporting] = useState(false)
  // const [popMenuOpen, setPopMenuOpen] = useState(false)

  const [isLoading, setIsLoading] = useState(true)
  const currentUser = useSelector(({ currentUser }) => currentUser)
  // const currentProfile = useSelector(({ currentProfile }) => currentProfile)
  const follows = useSelector(({ follows }) => follows)
  const dispatch = useDispatch()
  const {
    userId,
    avatar,
    tagsOfKnowledge,
    tagsOfInterest,
    personalAddress,
    privacySettings,
    documentVerified
  } = profile
  const hasLocation = personalAddress.city && personalAddress.state
  const isFollowing = _.some(follows, { follower: currentUser.id, userId })
  const matches = useMediaQuery('(min-width:500px)')

  const handleFollow = () => {
    dispatch(
      createUserFollow({
        userId: profile.userId,
        name: getName(profile)
      })
    ).then((data) => {
      if (!data.error) {
        console.log('[SUCCESS]: ', 'Successfully followed user!')
      } else {
        console.log('[FAIL]: ', 'Failed to follow user.')
      }
    })

    analytics.logEvent('toggle_follow')
  }

  const handleUnfollow = () => {
    dispatch(
      deleteFollowByUserID({
        userId: profile.userId,
        currUserId: currentUser.id,
        name: getName(profile)
      })
    ).then((data) => {
      if (!data.error) {
        console.log('[SUCCESS]: ', 'Successfully unfollowed user!')
      } else {
        console.log('[FAIL]: ', 'Failed to unfollow user.')
      }
    })
  }

  const handleOpenImageUpload = () => {
    setOpen(true)
  }

  const handleCloseImageUpload = () => {
    setOpen(false)
  }

  // TODO: save for later
  // const toggleReportForm = (isReporting) => {
  //   setIsReporting(isReporting)
  //   setPopMenuOpen(false)
  // }

  useEffect(() => {
    if (isLoading) {
      if (!isCurrentUser) {
        dispatch(checkFollowingUser({ userId }))
          .then((data) => {
            if (!data.error) {
              console.log('[SUCCESS]: ', 'Successfully checked if following user!')
            } else {
              console.log('[FAIL]: ', 'Failed to check if following user.')
            }

            setIsLoading(false)
          })
          .catch((e) => {
            setIsLoading(false)
          })
      } else {
        setIsLoading(false)
      }
    }
  }, [dispatch, isCurrentUser, isLoading, userId])

  if (!profile || Object.keys(profile).length === 0 || isLoading) {
    return (
      <MainContainer>
        <SkeletonProfileHeader />
      </MainContainer>
    )
  }

  return (
    <MainContainer>
      {/** isReporting && <ReportForm for={userId} setOpen={toggleReportForm} /> */}

      <ProfileAvatar
        avatar={avatar}
        isCurrentUser={isCurrentUser}
        isFollowing={isFollowing}
        handleFollow={handleFollow}
        handleUnfollow={handleUnfollow}
        handleOpen={handleOpenImageUpload}
        documentVerified={documentVerified}
      />

      <DetailsContainer>
        {isCurrentUser ? <Name>{getName(currentUser)}</Name> : <Name>{getName(profile)}</Name>}

        <Location>
          {hasLocation && privacySettings.showLocation && (
            <span>{`${personalAddress.city}, ${personalAddress.state}`}</span>
          )}
        </Location>

        <Knowledge>
          <b>Topics of Knowledge:</b>{' '}
          {tagsOfKnowledge.length > 0 ? (
            <Tags tagIds={tagsOfKnowledge} tagStyle={{ marginTop: '0', marginBottom: '.7rem' }} />
          ) : (
            <p>None Selected</p>
          )}
        </Knowledge>

        <Interests>
          <b>Topics of Interest:</b>{' '}
          {tagsOfInterest.length > 0 ? (
            <Tags tagIds={tagsOfInterest} tagStyle={{ marginTop: '0', marginBottom: '.7rem' }} />
          ) : (
            <p>None Selected</p>
          )}
        </Interests>

        {/* <UserAccolades userId={userId} style={{ marginTop: '.5rem' }} profile={profile} /> */}
      </DetailsContainer>

      <DropDownButtonContainer>
        {isCurrentUser ? (
          <EditButton />
        ) : (
          <>
            <ButtonGroup
              orientation={matches ? 'horizontal' : 'vertical'}
              sx={{ position: 'absolute', top: '1rem', right: '1rem' }}>
              {/* <TransferTokensButton userId={userId} /> */}
              <FollowButton userId={userId} profile={profile} />
              <ReportButton userId={userId} />
            </ButtonGroup>
          </>
        )}
      </DropDownButtonContainer>

      {isCurrentUser && (
        <ImageUpload
          open={open}
          handleOpen={handleOpenImageUpload}
          handleClose={handleCloseImageUpload}
        />
      )}

      {/** TODO: save for later         
          <Button
          ref={ref}
          variant="contained"
          color="primary"
          onClick={() => setPopMenuOpen(!popMenuOpen)}
          className={classes.dropdownButton}
          aria-owns={popMenuOpen ? 'menu-dropdown' : undefined}
          aria-haspopup="true">
          <MenuIcon className={classes.actionIcon} />
          </Button>
        
        <Popper
        open={popMenuOpen}
        anchorEl={ref.current}
        placement="bottom-end"
        transition
        style={{ zIndex: 100 }}>
        {({ TransitionProps, placement }) => (
          <Grow
          {...TransitionProps}
          id="menu-dropdown"
          style={{
            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
          }}>
          <Paper>
          {!isCurrentUser && (
            <ClickAwayListener onClickAway={() => setPopMenuOpen(false)}>
            <MenuList className={classes.menuList}>
            <MenuItem
            className={classes.dropdownItem}
            onClick={isFollowing ? handleUnfollow : handleFollow}
            disabled={!isVerifiedUser(currentProfile)}>
            <NavItemContainer>
            {isFollowing ? (
              <>
              <span className="material-icons">person_remove</span>
              <NavItem>{`Unfollow ${getName(profile, 'first')}`}</NavItem>
              </>
              ) : (
                <>
                <span className="material-icons">person_add</span>
                <NavItem>{`Follow ${getName(profile, 'first')}`}</NavItem>
                </>
                )}
                </NavItemContainer>
                </MenuItem>
                
                <MenuItem
                className={classes.dropdownItem}
                onClick={() => toggleReportForm(true)}
                disabled={!isVerifiedUser(currentProfile)}>
                <NavItemContainer>
                <span className="material-icons">flag</span>
                
                <NavItem>Report</NavItem>
                </NavItemContainer>
                </MenuItem>
                  </MenuList>
                  </ClickAwayListener>
                  )}
                  
                  {isCurrentUser && (
                    <ClickAwayListener onClickAway={() => setPopMenuOpen(false)}>
                    <MenuList className={classes.menuList}>
                    <MenuItem
                    className={classes.dropdownItem}
                    onClick={() => history.push('/my-account/personal')}>
                    <NavItemContainer>
                    <span className="material-icons">edit</span>
                    
                    <NavItem>Edit Profile</NavItem>
                    </NavItemContainer>
                    </MenuItem>
                    </MenuList>
                    </ClickAwayListener>
                    )}
                    </Paper>
                    </Grow>
                    )}
                    </Popper>
                  */}
    </MainContainer>
  )
}

export default withRouter(Header)
