import { TableHead, TableRow, TableSortLabel, Tooltip, TableCell } from '@mui/material'
import { withStyles } from '@mui/styles'
import theme from '../../../../../../../../res/theme'
import { tableStyles } from '../styles/styles'

const CustomTableCell = withStyles(() => ({
  head: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.fontSizes.short,
    fontWeight: theme.typography.fontWeight.regular,
    color: theme.typography.fontColor.bodyCopy
  },
  body: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.fontSizes.short,
    fontWeight: theme.typography.fontWeight.light
  }
}))(TableCell)

const EnhancedTableHead = (props) => {
  const createSortHandler = (event, property) => {
    props.onRequestSort(event, property)
  }

  const { order, orderBy } = props

  const rows = [
    { id: 'donationTokens', disablePadding: false, label: 'Token' },
    { id: 'type', disablePadding: false, label: 'Transaction Type' },
    { id: 'name_from', disablePadding: false, label: 'from' },
    { id: 'name_to', disablePadding: false, label: 'to' },
    { id: 'whenCreated', disablePadding: false, label: 'Date' }
  ]

  return (
    <TableHead>
      <TableRow>
        {rows?.map((row) => {
          return (
            <CustomTableCell
              sx={tableStyles.Header}
              key={row.id}
              align={row.id === 'donationTokens' ? 'left' : 'right'}
              padding={row.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === row.id ? order : false}>
              <Tooltip title={'Sort by ' + row.label} placement="top-end" enterDelay={300}>
                <TableSortLabel
                  active={orderBy === row.id}
                  direction={order}
                  onClick={(event) => createSortHandler(event, row.id)}>
                  {row.label}
                </TableSortLabel>
              </Tooltip>
            </CustomTableCell>
          )
        })}
      </TableRow>
    </TableHead>
  )
}

export default EnhancedTableHead
