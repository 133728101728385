import React from 'react'
import LoadingButton from '@mui/lab/LoadingButton'
import { CircularProgress } from '@mui/material'
import { some } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useScrollInfinite from '../../../../../hooks/useScrollInfinite'
import { connectOrganizationsAction } from '../../../../../redux/action-creators/connect-organizations'
import { getOrganizationsList } from '../../../../../redux/thunks/connect-organizations/get-connect-organizations'
import ConnectCard from './components/connect-card/connect-card'
import { MainContainer, Cards, HeadingPrimary, Loading } from './styled/connect-comps'

import SuggestedConnections from '../../../../partials/suggested-connections/suggested-connections'
import SuggestedOrganizations from '../../../../partials/suggested-organizations/suggested-organizations'

// TODO: Refactor SuggestedOrganizationsBox
//import SuggestedOrganizationsBox from '../../../../partials/suggested-organizations-box/suggested-organizations-box'

export const styles = {
  button: {
    width: '99px',
    minWidth: '99px',
    height: '28px',
    color: '#333',
    paddingTop: '3px',
    paddingBottom: '4px',
    borderRadius: '4px',
    fontWeight: 400,
    fontSize: '12px',
    marginTop: '1rem',
    '@media screen and (max-width: 400px)': {
      fontSize: '10px',
      width: '68px',
      minWidth: '68px',
      height: '22px',
      paddingTop: '1px',
      paddingBottom: '1px'
    }
  }
}

function Connect() {
  const [loading, setLoading] = useState(false)

  return (
    <MainContainer>
      <SuggestedConnections allowRandomSuggestions={true} hideOnNoResults={true} />
      <SuggestedOrganizations
        disableFromInsights={false}
        disableRandomSuggestions={false}
        disableFromInterests={false}
        hideOnNoResults={true}
      />
    </MainContainer>
  )
}

// const Connect = () => {
//   const {
//     connectOrganizations: { organizationsList, afterDate, hasMore },
//     currentUser,
//     follows
//   } = useSelector((state) => state)

//   const [loading, setLoading] = useState(false)
//   const [finished, setFinished] = useState(false)
//   const dispatch = useDispatch()

//   const fetchOrganizationsList = useCallback(async () => {
//     if (organizationsList?.length === 0) {
//       dispatch(getOrganizationsList({ afterDate, isExist: false }))
//     }
//   }, [afterDate, dispatch, organizationsList?.length])

//   useEffect(() => {
//     fetchOrganizationsList()
//   }, [fetchOrganizationsList])

//   const [moreToLoad] = useScrollInfinite({ id: 'connect-list' })

//   const fetchMore = useCallback(async () => {
//     if (organizationsList?.length !== 0) {
//       if (moreToLoad) {
//         if (!loading) {
//           setLoading(true)
//           await dispatch(getOrganizationsList({ afterDate, isExist: true })).then((res) => {
//             setLoading(false)
//           })
//         }
//       }
//     }
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [afterDate, moreToLoad, organizationsList?.length])

//   useEffect(() => {
//     hasMore && fetchMore()
//   }, [fetchMore, hasMore])

//   const fetchMoreManual = useCallback(async () => {
//     setLoading(true)
//     await dispatch(getOrganizationsList({ afterDate, isExist: true })).then((res) => {
//       if (res?.organizations.length === 0) {
//         setFinished(true)
//       } else {
//         setFinished(false)
//         dispatch(connectOrganizationsAction.setFetchMoreOrganizations(true))
//       }
//       setLoading(false)
//     })
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [afterDate, moreToLoad, organizationsList?.length])

//   return (
//     <MainContainer id="connect-list">
//       <HeadingPrimary>Follow the most recent Non-Profits on CivilTalk</HeadingPrimary>
//       <Cards>
//         {organizationsList?.map((item) => {
//           const isFollowing = some(follows, {
//             follower: currentUser?.id,
//             organizationId: item?._id
//           })
//           return <ConnectCard key={item?._id} data={item} isFollowing={isFollowing} />
//         })}
//       </Cards>
//       {!finished && loading && hasMore && (
//         <Loading>
//           <CircularProgress />
//         </Loading>
//       )}
//       {!finished && !hasMore && (
//         <Loading>
//           <LoadingButton
//             variant="contained"
//             loading={loading}
//             onClick={fetchMoreManual}
//             sx={styles.button}>
//             Load More
//           </LoadingButton>
//         </Loading>
//       )}

//       {/* {finished && <NoMore>no more organizations</NoMore>} */}
//     </MainContainer>
//   )
// }

export default Connect
