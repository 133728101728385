import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import loadDiscussionRoom from './functions/load-discussion-room'
import loadMoreDiscussions from './functions/load-more-discussions'
import { loadedDiscussionsActions } from '../../../redux/action-creators/loaded-discussions'
import { outgoingDiscussionsActions } from '../../../redux/action-creators/outgoing-discussions'
import { getDiscussions } from '../../../redux/thunks/discussions/getDiscussions'
import { getRebuttals } from '../../../redux/thunks/rebuttals/getRebuttals'
import { getTakesByIDs } from '../../../redux/thunks/takes/getTakesByIDs'
import { getTopicsByIDs } from '../../../redux/thunks/topics/getTopicsByIDs'
import { getProfilesByUserIDs } from '../../../redux/thunks/profiles/getProfilesByUserIDs'
import mapFilterSortDiscussionsToRebuttals from './functions/map-filter-sort-discussions-to-rebuttals'
import Tabs from './components/tabs'
import Discussions from './components/discussions'
import MobileDiscussions from './components/mobile-discussions'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'

import {
  MainContainer,
  InnerContainer,
  ListContainer
} from './components/styled/discussion-room-comps'

function DiscussionRoom() {
  const [status, setStatus] = useState({
    pageLoaded: false,
    inboxLoadMore: false,
    sentLoadMore: false,
    error: false
  })

  // *** Tab Controls *** //
  const [tab, setTab] = useState('inbox')

  const handleSetTab = useCallback(
    (tab) => {
      setTab(tab)
    },
    [setTab]
  )

  const handleSetStatus = (status) => {
    setStatus(status)
  }

  // *** Redux State *** //
  const currentUser = useSelector((state) => state.currentUser)
  const loadedDiscussions = useSelector((state) => state.loadedDiscussions)
  const outgoingDiscussions = useSelector((state) => state.outgoingDiscussions)
  const loadedRebuttals = useSelector((state) => state.loadedRebuttals)
  const dispatch = useDispatch()

  // *** Initial Page Load *** //
  const handleLoad = useCallback(
    (afterDate, userId, isLoadingPage, outgoing, status, setStatus, dispatch) => {
      const loadDiscussionRoomActions = {
        getDiscussions: getDiscussions,
        getRebuttals: getRebuttals,
        getTakesByIDs: getTakesByIDs,
        getTopicsByIDs: getTopicsByIDs,
        getProfilesByUserIDs: getProfilesByUserIDs
      }

      loadDiscussionRoom(
        afterDate,
        userId,
        isLoadingPage,
        outgoing,
        status,
        setStatus,
        dispatch,
        loadDiscussionRoomActions
      )
    },
    []
  )

  // *** Initial Page Load *** //
  useEffect(() => {
    handleLoad(Date.now(), currentUser.id, true, true, status, handleSetStatus, dispatch)

    return () => {
      dispatch(loadedDiscussionsActions.clearDiscussions())
      dispatch(outgoingDiscussionsActions.clearDiscussions())
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // *** Map/Filter/Sort the Raw Discussions from Redux -> Inbox *** //
  const mappedInboxDiscussions =
    loadedDiscussions &&
    loadedRebuttals &&
    mapFilterSortDiscussionsToRebuttals(loadedDiscussions, loadedRebuttals, currentUser, 'Inbox')

  const inboxAfterDate =
    mappedInboxDiscussions &&
    mappedInboxDiscussions.length > 0 &&
    mappedInboxDiscussions[mappedInboxDiscussions.length - 1].whenCreated

  // *** Map/Filter/Sort the Raw Discussions from Redux -> Sent *** //
  const mappedSentDiscussions =
    loadedDiscussions &&
    loadedRebuttals &&
    mapFilterSortDiscussionsToRebuttals(outgoingDiscussions, loadedRebuttals, currentUser, 'Sent')

  const sentAfterDate =
    mappedSentDiscussions &&
    mappedSentDiscussions.length > 0 &&
    mappedSentDiscussions[mappedSentDiscussions.length - 1].whenCreated

  // *** Load More Discussions *** //
  const handleLoadMore = useCallback(
    (afterDate, userId, isLoadingPage, outgoing, status, setStatus) => {
      const loadDiscussionRoomActions = {
        getDiscussions: getDiscussions,
        getRebuttals: getRebuttals,
        getTakesByIDs: getTakesByIDs,
        getTopicsByIDs: getTopicsByIDs,
        getProfilesByUserIDs: getProfilesByUserIDs
      }

      loadMoreDiscussions(
        inboxAfterDate,
        currentUser.id,
        false, // isLoadingPage
        false, // outgoing
        status,
        handleSetStatus,
        dispatch,
        loadDiscussionRoomActions
      )
    },
    [currentUser.id, dispatch, inboxAfterDate]
  )

  const handleLoadMoreOutgoing = useCallback(
    (afterDate, userId, isLoadingPage, outgoing, status, setStatus) => {
      const loadDiscussionRoomActions = {
        getDiscussions: getDiscussions,
        getRebuttals: getRebuttals,
        getTakesByIDs: getTakesByIDs,
        getTopicsByIDs: getTopicsByIDs,
        getProfilesByUserIDs: getProfilesByUserIDs
      }

      loadMoreDiscussions(
        sentAfterDate,
        currentUser.id,
        false, // isLoadingPage
        true, // outgoing
        status,
        handleSetStatus,
        dispatch,
        loadDiscussionRoomActions
      )
    },
    [currentUser.id, dispatch, sentAfterDate]
  )

  // MUI STYLES
  const theme = useTheme()
  const mobileBreakpoint = useMediaQuery(theme.breakpoints.down('md')) // 901px

  return (
    <MainContainer>
      <InnerContainer>
        <Tabs handleSetTab={handleSetTab} />
        {tab === 'inbox' && (
          <ListContainer>
            {mobileBreakpoint ? (
              <>
                {mappedInboxDiscussions && (
                  <MobileDiscussions
                    discussions={mappedInboxDiscussions}
                    type="Inbox"
                    handleLoadMore={handleLoadMore}
                    pageLoaded={status.pageLoaded}
                    loadMore={status.inboxLoadMore}
                  />
                )}
              </>
            ) : (
              <>
                {mappedInboxDiscussions && (
                  <Discussions
                    discussions={mappedInboxDiscussions}
                    type="Inbox"
                    handleLoadMore={handleLoadMore}
                    pageLoaded={status.pageLoaded}
                    loadMore={status.inboxLoadMore}
                  />
                )}
              </>
            )}
          </ListContainer>
        )}

        {tab === 'sent' && (
          <ListContainer>
            {mobileBreakpoint ? (
              <>
                {mappedSentDiscussions && (
                  <MobileDiscussions
                    discussions={mappedSentDiscussions}
                    type="Sent"
                    handleLoadMore={handleLoadMoreOutgoing}
                    pageLoaded={status.pageLoaded}
                    loadMore={status.sentLoadMore}
                  />
                )}
              </>
            ) : (
              <>
                {mappedSentDiscussions && (
                  <Discussions
                    discussions={mappedSentDiscussions}
                    type="Sent"
                    handleLoadMore={handleLoadMoreOutgoing}
                    pageLoaded={status.pageLoaded}
                    loadMore={status.sentLoadMore}
                  />
                )}
              </>
            )}
          </ListContainer>
        )}
      </InnerContainer>
    </MainContainer>
  )
}

export default DiscussionRoom
