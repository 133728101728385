import nestedSort from '../../utils/functions/nestedSort.js'

const arraysEqual = (a, b) => {
    if (a === b) return true
    if (a == null || b == null) return false
    if (a.length !== b.length) return false
  
    a = nestedSort(a, 1)
    b = nestedSort(b, 1)
  
    for (var i = 0; i < a.length; ++i) {
      if (a[i] !== b[i]) return false
    }
  
    return true
  }

export default arraysEqual