import styled from 'styled-components'

export const MainContainer = styled.div`
  margin-top: 0.7rem;
  /* border: 1px solid #ddd; */
  border-radius: 0.3rem;
  box-sizing: border-box;
  position: relative;

  & > span {
    font-size: ${(props) => props.theme.typography.fontSizes.larger};
    color: ${(props) =>
      props.variant === 'transparent'
        ? props.theme.palette.text.primary
        : props.theme.typography.fontColor.headerCopy};
    position: absolute;
    top: 50%;
    right: 0.5rem;
    transform: translateY(-50%);
  }
`

export const InputContainer = styled.div`
  display: block;
  margin-top: 0.8rem;
  /* border: 1px solid #ddd; */
  border-radius: 0.3rem;
  box-sizing: border-box;
  position: relative;

  & > span {
    font-size: ${(props) => props.theme.typography.fontSizes.larger};
    color: ${(props) => props.theme.typography.fontColor.headerCopy};
    position: absolute;
    top: 50%;
    right: 0.5rem;
    transform: translateY(-50%);
  }
`

export const Input = styled.input`
  background-color: ${(props) => (props.variant === 'transparent' ? '#eee' : 'transparent')};
  display: block;
  width: 100%;
  padding: 0.7rem 0.5rem;
  vertical-align: middle;
  border: none;
  border-radius: 0.3rem;
  box-sizing: border-box;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.small};
  font-weight: ${(props) =>
    props.variant === 'transparent'
      ? props.theme.typography.fontWeight.light
      : props.theme.typography.fontWeight.regular};
  color: ${(props) =>
    props.variant === 'transparent' ? '#333333' : props.theme.typography.fontColor.bodyCopy};
  transition: background-color 0.3s ease-in-out;

  &:focus,
  &:active {
    outline: none;
  }

  &:focus {
    background-color: ${(props) => (props.variant === 'transparent' ? '#e3e3e3' : 'transparent')};
    border: ${(props) =>
      props.variant === 'transparent' ? 'none' : `2px solid ${props.theme.palette.primary.main}`};
    box-shadow: ${(props) =>
      props.variant !== 'default'
        ? 'none'
        : `0px 0px 1px 4px ${props.theme.palette.primary.light}`};
  }

  &::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: ${(props) =>
      props.variant === 'transparent' ? '#333333' : props.theme.typography.fontColor.headerCopy};
    font-weight: ${(props) =>
      props.variant === 'transparent'
        ? props.theme.typography.fontWeight.light
        : props.theme.typography.fontWeight.regular};
  }
  &:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: ${(props) =>
      props.variant === 'transparent' ? '#333333' : props.theme.typography.fontColor.headerCopy};
    font-weight: ${(props) =>
      props.variant === 'transparent'
        ? props.theme.typography.fontWeight.light
        : props.theme.typography.fontWeight.regular};
    opacity: 1;
  }
  &::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: ${(props) =>
      props.variant === 'transparent' ? '#333333' : props.theme.typography.fontColor.headerCopy};
    font-weight: ${(props) =>
      props.variant === 'transparent'
        ? props.theme.typography.fontWeight.light
        : props.theme.typography.fontWeight.regular};
    opacity: 1;
  }
  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${(props) =>
      props.variant === 'transparent' ? '#333333' : props.theme.typography.fontColor.headerCopy};
    font-weight: ${(props) =>
      props.variant === 'transparent'
        ? props.theme.typography.fontWeight.light
        : props.theme.typography.fontWeight.regular};
  }
  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${(props) =>
      props.variant === 'transparent' ? '#333333' : props.theme.typography.fontColor.headerCopy};
    font-weight: ${(props) =>
      props.variant === 'transparent'
        ? props.theme.typography.fontWeight.light
        : props.theme.typography.fontWeight.regular};
  }

  &::placeholder {
    /* Most modern browsers support this now. */
    color: ${(props) =>
      props.variant === 'transparent' ? '#333333' : props.theme.typography.fontColor.headerCopy};
    font-weight: ${(props) =>
      props.variant === 'transparent'
        ? props.theme.typography.fontWeight.light
        : props.theme.typography.fontWeight.regular};
  }
`

export const Result = styled.div`
  display: block;
  padding: 0.3rem 0.7rem;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.small};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  color: ${(props) => props.theme.typography.fontColor.bodyCopy};
  pointer-events: none;

  & span {
    vertical-align: middle;
  }

  & span.material-icons {
    margin-right: 0.5rem;
    font-size: ${(props) => props.theme.typography.fontSizes.regular};
    font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  }

  & > span:nth-child(2) {
    font-size: ${(props) => props.theme.typography.fontSizes.small};
    font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  }
`
