import styled from 'styled-components'

export const MainContainer = styled.div`
  position: relative;

  & > #search-icon {
    font-size: 1.1rem;
    color: ${(props) => props.theme.typography.fontColor.bodyCopy};
    position: absolute;
    top: 50%;
    right: 0.5rem;
    transform: translateY(-50%);
  }
`

export const InputContainer = styled.div`
  display: block;
  border: 1px solid #ddd;
  border-radius: 0.3rem;
  box-sizing: border-box;
  position: relative;

  & > span {
    font-size: 1.1rem;
    color: ${(props) => props.theme.typography.fontColor.bodyCopy};
    position: absolute;
    top: 50%;
    right: 0.5rem;
    transform: translateY(-50%);
  }
`

export const Input = styled.input`
  background-color: rgba(0, 0, 0, 0);
  display: block;
  width: 100%;
  padding: 0.8rem 0.8rem;
  box-sizing: border-box;
  line-height: ${(props) => props.theme.typography.lineHeight};
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.regular};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  color: ${(props) => props.theme.typography.fontColor.bodyCopy};
  border: 1px solid #ddd;
  border-radius: 0.2rem;
  box-shadow: 0px 9px 7px -2px rgba(120, 120, 120, 0.1);
`

export const Result = styled.div`
  display: block;
  padding: 0.3rem 0.7rem;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.regular};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  color: ${(props) => props.theme.typography.fontColor.bodyCopy};
  pointer-events: none;
`

export const ProfileContainer = styled.div`
  display: block;
  padding: 0.7rem 1rem;
  cursor: pointer;
`

export const ProfilePicture = styled.div`
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: block;
  float: left;
  width: 2.3rem;
  height: 2.3rem;
  margin-right: 1rem;
  border-radius: 50%;
  border: 0.2rem solid;
  border-color: ${(props) => (props.verified === 1 ? '#2eb2fa' : '#ccc')};
  pointer-events: none;
`

export const ProfileInfoContainer = styled.div`
  display: block;
  margin-top: -0.2rem;
  overflow: hidden;
  pointer-events: none;
`

export const ProfileName = styled.p`
  display: block;
  margin-top: .3rem;
  text-transform: capitalize;
  line-height: ${(props) => props.theme.typography.lineHeight};
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.regular};
  font-weight: ${(props) => props.theme.typography.fontWeight.bold};
  color: ${(props) => props.theme.typography.fontColor.bodyCopy};
  pointer-events: none;
`

export const ProfileLocation = styled.p`
  display: block;
  margin-top: 0.1rem;
  text-transform: capitalize;
  line-height: ${(props) => props.theme.typography.lineHeight};
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: 0.9rem;
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  color: ${(props) => props.theme.typography.fontColor.bodyCopy};
  pointer-events: none;
`
