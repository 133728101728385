import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getTermsOfUse } from '../../../../redux/thunks/terms-of-use/getTermsOfUse'
import { MainContainer, ContentContainer } from '../styled/terms-of-use-view-comps'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import CircleIcon from '@mui/icons-material/Circle'

// TODO: Save this object for testing and scripts
// const data = {
//   articles: [
//     {
//       title: 'Acceptance of the Terms of Use',
//       sections: [
//         {
//           type: 'paragraph',
//           content:
//             'These terms of use are entered into by and between website visitors and Free Members (defined below)(collectively “You” and “Your”) and CivilTalk LLC ("Company," “CivilTalk”, "we," or "us"). The following terms and conditions, together with the Privacy Policy and any documents they expressly incorporate by reference (collectively, "Terms of Use"), govern Your access to and use of www.civiltalk.com, including any content, functionality and services offered on or through www.civiltalk.com (the "Website") and the CivilTalk mobile and web applications (collectively the “App”, and together with the Website, the “Services”).'
//         },
//         {
//           type: 'paragraph',
//           content:
//             'Please read the Terms of Use carefully before You start to use the Services. By using the Services or by clicking to accept or agree to the Terms of Use when this option is made available to You, You accept and agree to be bound and abide by these Terms of Use. If You do not want to agree to these Terms of Use, You must not access or use the Services.'
//         },
//         {
//           type: 'paragraph',
//           content:
//             'The Services are offered and available to users who are 18 years of age or older. By using the Services, You represent and warrant that You are of legal age to form a binding contract with the Company and meet all of the foregoing eligibility requirements. If You do not meet all of these requirements, You must not access or use the Services.'
//         },
//         {
//           type: 'paragraph',
//           display: 'bold',
//           content:
//             'Use of the Services by paid account holders, including entities and their authorized users, is governed by the applicable written agreement entered into with CivilTalk and are not governed by these Terms of Use.'
//         }
//       ]
//     },
//     {
//       title: 'Changes to the Terms of Use',
//       sections: [
//         {
//           type: 'paragraph',
//           content:
//             'We may revise and update these Terms of Use from time to time in our sole discretion. All changes are effective immediately when we post them, and apply to Your access to and use of the Services thereafter. However, any changes to the dispute resolution provisions set forth in Governing Law and Jurisdiction will not apply to any disputes for which the parties have actual notice on or prior to the date the change is posted on the Services.'
//         },
//         {
//           type: 'paragraph',
//           content:
//             'Your continued use of the Services following the posting of revised Terms of Use means that You accept and agree to the changes. You are expected to check this page from time to time so You are aware of any changes, as they are binding on You.'
//         }
//       ]
//     },
//     {
//       title: 'Accessing the Services and Account Security',
//       sections: [
//         {
//           type: 'paragraph',
//           content:
//             'We reserve the right to withdraw or amend the Services, and any service or material we provide on the Services, in our sole discretion without notice. We will not be liable if for any reason all or any part of the Services is unavailable at any time or for any period. From time to time, we may restrict access to some parts of the Services, or the entire Services, to You.'
//         },
//         {
//           type: 'list',
//           heading: 'You are responsible for both:',
//           items: [
//             {
//               type: 'list-item',
//               content: 'Making all arrangements necessary for You to have access to the Services.'
//             },
//             {
//               type: 'list-item',
//               content:
//                 'Ensuring that all persons who access the Services through Your internet connection are aware of these Terms of Use and comply with them.'
//             }
//           ]
//         },
//         {
//           type: 'paragraph',
//           content:
//             'To access the Services or some of the resources it offers, You may be asked to provide certain registration details or other information. It is a condition of Your use of the Services that all the information You provide on the Services is correct, current and complete. You agree that all information You provide to register with, use or access the Services or otherwise, is governed by our Privacy Policy, and You consent to all actions we take with respect to Your information consistent with our Privacy Policy.'
//         },
//         {
//           type: 'paragraph',
//           content:
//             'If You choose, or are provided with, a user name, password or any other piece of information as part of our security procedures, You must treat such information as confidential, and You must not disclose it to any other person or entity. You also acknowledge that Your account is personal to You and agree not to provide any other person with access to the Services or portions of it using Your user name, password or other security information. You agree to notify us immediately of any unauthorized access to or use of Your user name or password or any other breach of security. You also agree to ensure that You exit from Your account at the end of each session. You should use particular caution when accessing Your account from a public or shared computer so that others are not able to view or record Your password or other personal information.'
//         },
//         {
//           type: 'paragraph',
//           content:
//             'We have the right to disable any username, password or other identifier, whether chosen by You or provided by us, at any time in our sole discretion for any or no reason, including if, in our opinion, You have violated any provision of these Terms of Use.'
//         }
//       ]
//     },
//     {
//       title: 'Membership Types, Tokens and Payment',
//       sections: [
//         {
//           type: 'paragraph',
//           display: 'bold',
//           content:
//             'Free Members - You may opt to be a "Free Member" of CivilTalk and have a free membership to access the App. Free membership is at no cost to the user; however, certain functionalities of the Services may not be available to users under free membership, for example, Free Members may not post or engage in discussions with other members on the Services, may not be awarded tokens by other users of the Services, and remain anonymous. At the sole discretion of CivilTalk, free members may be converted to a verified membership at no charge and be provided with functionalities such as posting or engaging in discussions with other members on the Services.'
//         },
//         {
//           type: 'paragraph',
//           display: 'bold',
//           content:
//             'Verified Members; Universities - Users that access and use the Services through a verified membership or a university membership are governed by a separate written agreement entered into with CivilTalk. To become a member under one of these categories, please contact CivilTalk at: support@civiltalk.com'
//         },
//         {
//           type: 'list',
//           heading:
//             'Verified Members - Prior to September 1, 2023, users may have opted to be a "Verified Member" of CivilTalk and paid a verification fee. After September 1, 2023, such members:',
//           items: [
//             {
//               type: 'list-item',
//               content:
//                 'have access to functionalities to the Services that are not available to users under free membership, such as, the ability to participate and engage in discussions on the Services and with other users, and participate in community activities featured in the Services, such as polls;'
//             },
//             {
//               type: 'list-item',
//               content: 'are not anonymous.'
//             }
//           ]
//         },
//         {
//           type: 'paragraph',
//           display: 'bold',
//           content:
//             'Tax Exempt Members - Prior to September 1, 2023, charitable organizations that are tax exempt organizations may have opted to be a "Tax Exempt Member" of the Services and pay an annual fee. After September 1, 2023, Tax Exempt Members have access to functionalities of the Services that are not available to users under free membership or verified membership, such as, the ability to receive donated tokens and redeem tokens for cash. After September 1, 2023, any membership fees associated with this type of membership will be waived by CivilTalk.'
//         },
//         {
//           type: 'list',
//           heading:
//             'Tokens - Effective September 1, 2023, CivilTalk will no longer enable the purchasing of tokens. However, certain users may have existing tokens associated with their account. Such tokens:',
//           items: [
//             {
//               type: 'list-item',
//               content:
//                 'can be awarded by a free member to a verified member, or a verified member to another verified member without restriction to the awardee and without being assessed a fee;'
//             },
//             {
//               type: 'list-item',
//               content:
//                 'can be donated by a free member or a verified member to a tax-exempt member without being assessed a fee;'
//             },
//             {
//               type: 'list-item',
//               content:
//                 'can be redeemed by a tax-exempt member for cash without being assessed a fee;'
//             },
//             {
//               type: 'list-item',
//               content: 'do not expire as long as CivilTalk maintains the token feature;'
//             },
//             {
//               type: 'list-item',
//               content: 'cannot be redeemed by a free member or a premium member for cash;'
//             },
//             {
//               type: 'list-item',
//               content:
//                 'cannot be canceled, refunded or returned to CivilTalk for cash or any value;'
//             },
//             {
//               type: 'list-item',
//               content:
//                 "are maintained in a user's account until awarded, donated or redeemed, as applicable"
//             },
//             {
//               type: 'list-item',
//               content:
//                 "will revert to CivilTalk if a user's account is closed or deleted by the user. CivilTalk will donate the tokens to tax-exempt members; and"
//             },
//             {
//               type: 'list-item',
//               content:
//                 'will revert to CivilTalk if, after notice to all users and provision of a stated time period to donate or award a token, CivilTalk discontinues the token feature or enters a period of wind­ down of the business. All tokens reverted to CivilTalk will be donated to Tax-Exempt Members.'
//             }
//           ]
//         },
//         {
//           type: 'paragraph',
//           content:
//             'Tokens that are donated to Tax-Exempt Members may be eligible for certain tax treatment under state and federal tax laws. CivilTalk is not responsible for determining the appropriate treatment of donated tokens. TaxExempt Members should maintain records and receipts of all donated tokens. Members who donate tokens to Tax-Exempt Members may request a receipt directly from the Tax-Exempt Member.'
//         }
//       ]
//     },
//     {
//       title: 'Intellectual Property Rights',
//       sections: [
//         {
//           type: 'paragraph',
//           content:
//             'The Services and its entire contents, features and functionality (including but not limited to all information, software, text, displays, images, video and audio, and the design, selection and arrangement thereof), are owned by the Company, its licensors or other providers of such material and are protected by United States and international copyright, trademark, patent, trade secret and other intellectual property or proprietary rights laws. '
//         },
//         {
//           type: 'list',
//           heading:
//             'These Terms of Use permit You to use the Services for Your personal, non-commercial use only. You must not reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store or transmit any of the material on our Services, except as follows:',
//           items: [
//             {
//               type: 'list-item',
//               content:
//                 'Your computer may temporarily store copies of such materials in RAM incidental to your accessing and viewing those materials.'
//             },
//             {
//               type: 'list-item',
//               content:
//                 'You may store files that are automatically cached by Your Web browser for display enhancement purposes.'
//             },
//             {
//               type: 'list-item',
//               content:
//                 'You may print or download one copy of a reasonable number of pages of the Services for Your own personal, non-commercial use and not for further reproduction, publication or distribution.'
//             },
//             {
//               type: 'list-item',
//               content:
//                 'You may download a single copy of each App to Your computer or mobile device solely for Your own personal, non-commercial use. '
//             }
//           ]
//         },
//         {
//           type: 'list',
//           heading: 'You must not:',
//           items: [
//             {
//               type: 'list-item',
//               content: 'Modify copies of any materials from the Services. '
//             },
//             {
//               type: 'list-item',
//               content:
//                 'Use any illustrations, photographs, video or audio sequences or any graphics separately from the accompanying text.'
//             },
//             {
//               type: 'list-item',
//               content:
//                 'Delete or alter any copyright, trademark or other proprietary rights notices from copies of materials from the Services.'
//             }
//           ]
//         },
//         {
//           type: 'paragraph',
//           content:
//             'You must not access or use for any commercial purposes any part of the Services or any services or materials available through the Services.'
//         },
//         {
//           type: 'paragraph',
//           content:
//             'If You wish to make any use of material on the Services other than that set out in this section, please address Your request to: support@civiltalk.com.'
//         },
//         {
//           type: 'paragraph',
//           content:
//             'If You print, copy, modify, download or otherwise use or provide any other person with access to any part of the Services in breach of the Terms of Use, Your right to use the Services will cease immediately and You must, at our option, return or destroy any copies of the materials You have made. No right, title or interest in or to the Services or any content on the Services is transferred to You, and all rights not expressly granted are reserved by the Company. Any use of the Services not expressly permitted by these Terms of Use is a breach of these Terms of Use and may violate copyright, trademark and other laws.'
//         }
//       ]
//     },
//     {
//       title: 'Trademarks',
//       sections: [
//         {
//           type: 'paragraph',
//           content:
//             'The Company name, the term CIVILTALK, and all related names, logos, product and service names, designs and slogans are trademarks of the Company or its affiliates or licensors. You must not use such marks without the prior written permission of the Company. All other names, logos, product and service names, designs and slogans on the Services are the trademarks of their respective owners. '
//         }
//       ]
//     },
//     {
//       title: 'Prohibited Uses',
//       sections: [
//         {
//           type: 'list',
//           heading:
//             'You may use the Services only for lawful purposes and in accordance with these Terms of Use. You agree not to use the Services:',
//           items: [
//             {
//               type: 'list-item',
//               content:
//                 'In any way that violates any applicable federal, state, local or international law or regulation (including, without limitation, any laws regarding the export of data or software to and from the US or other countries).'
//             },
//             {
//               type: 'list-item',
//               content:
//                 'For the purpose of exploiting, harming or attempting to exploit or harm minors in any way by exposing them to inappropriate content, asking for personally identifiable information or otherwise.'
//             },
//             {
//               type: 'list-item',
//               content:
//                 'To send, knowingly receive, upload, download, use or re-use any material which does not comply with the Content Standards set out in these Terms of Use. '
//             },
//             {
//               type: 'list-item',
//               content:
//                 'To transmit, or procure the sending of, any advertising or promotional material, including any "junk mail," "chain letter," "spam," or any other similar solicitation. '
//             },
//             {
//               type: 'list-item',
//               content:
//                 'To impersonate or attempt to impersonate the Company, a Company employee, another user or any other person or entity (including, without limitation, by using e-mail addresses or screen names associated with any of the foregoing). '
//             },
//             {
//               type: 'list-item',
//               content:
//                 "To engage in any other conduct that restricts or inhibits anyone's use or enjoyment of the Services, or which, as determined by us, may harm the Company or users of the Services, or expose them to liability. "
//             }
//           ]
//         },
//         {
//           type: 'list',
//           heading: 'Additionally, You agree not to:',
//           items: [
//             {
//               type: 'list-item',
//               content:
//                 "Use the Services in any manner that could disable, overburden, damage, or impair the site or interfere with any other party's use of the Services, including their ability to engage in real time activities through the Services."
//             },
//             {
//               type: 'list-item',
//               content:
//                 'Use any robot, spider or other automatic device, process or means to access the Services for any purpose, including monitoring or copying any of the material on the Services.'
//             },
//             {
//               type: 'list-item',
//               content:
//                 'Use any manual process to monitor or copy any of the material on the Services, or for any other purpose not expressly authorized in these Terms of Use, without our prior written consent. '
//             },
//             {
//               type: 'list-item',
//               content:
//                 'Use any device, software or routine that interferes with the proper working of the Services. '
//             },
//             {
//               type: 'list-item',
//               content:
//                 'Introduce any viruses, trojan horses, worms, logic bombs or other material which is malicious or technologically harmful.'
//             },
//             {
//               type: 'list-item',
//               content:
//                 'Attempt to gain unauthorized access to, interfere with, damage or disrupt any parts of the Services, the server on which the Services is stored, or any server, computer or database connected to the Services. '
//             },
//             {
//               type: 'list-item',
//               content:
//                 'Attack the Services via a denial-of-service attack or a distributed denial-of-service attack.'
//             },
//             {
//               type: 'list-item',
//               content: 'Otherwise attempt to interfere with the proper working of the Services.'
//             }
//           ]
//         }
//       ]
//     },
//     {
//       title: 'User Contributions',
//       sections: [
//         {
//           type: 'paragraph',
//           content:
//             'The App contains message boards, chat rooms, personal web pages or profiles, forums, bulletin boards, user networks, and other interactive features (collectively, "Interactive Services") that allow certain members to post, submit, publish, display or transmit to other users or other persons (hereinafter, "post") content or materials (collectively, "User Contributions") on or through the App. '
//         },
//         {
//           type: 'paragraph',
//           content:
//             'All User Contributions must comply with the Content Standards set out in these Terms of Use.'
//         },
//         {
//           type: 'paragraph',
//           content:
//             'Any User Contribution posted to the site will be considered non-confidential and non-proprietary. By providing any User Contribution, You grant us and our licensees, successors and assigns the right to use, reproduce, modify, perform, display, distribute and otherwise disclose to third parties any such material according to your account settings.'
//         },
//         {
//           type: 'list',
//           heading: 'You represent and warrant that:',
//           items: [
//             {
//               type: 'list-item',
//               content:
//                 'You own or control all rights in and to the User Contributions and have the right to grant the license granted above to us and our licensees, successors and assigns. '
//             },
//             {
//               type: 'list-item',
//               content: 'All of Your User Contributions do and will comply with these Terms of Use.'
//             }
//           ]
//         },
//         {
//           type: 'paragraph',
//           content:
//             'You understand and acknowledge that You are responsible for any User Contributions You submit or contribute, and You, not the Company, have fully responsibility for such content, including its legality, reliability, accuracy and appropriateness.'
//         },
//         {
//           type: 'paragraph',
//           content:
//             'We are not responsible, or liable to any third party, for the content or accuracy of any User Contributions posted by You or any other user of the Services.'
//         }
//       ]
//     },
//     {
//       title: 'Monitoring and Enforcement; Termination',
//       sections: [
//         {
//           type: 'list',
//           heading: 'We have the right to:',
//           items: [
//             {
//               type: 'list-item',
//               content:
//                 'Remove or refuse to post any User Contributions for any or no reason in our sole discretion.'
//             },
//             {
//               type: 'list-item',
//               content:
//                 'Take any action with respect to any User Contribution that we deem necessary or appropriate in our sole discretion, including if we believe that such User Contribution violates the Terms of Use, including the Content Standards, infringes any intellectual property right or other right of any person or entity, threatens the personal safety of users of the Services or the public or could create liability for the Company.'
//             },
//             {
//               type: 'list-item',
//               content:
//                 'Disclose Your identity or other information about You to any third party who claims that material posted by You violates their rights, including their intellectual property rights or their right to privacy. '
//             },
//             {
//               type: 'list-item',
//               content:
//                 'Take appropriate legal action, including without limitation, referral to law enforcement, for any illegal or unauthorized use of the Services.'
//             },
//             {
//               type: 'list-item',
//               content:
//                 'Terminate or suspend Your access to all or part of the Services for any or no reason, including without limitation, any violation of these Terms of Use. '
//             }
//           ]
//         },
//         {
//           type: 'paragraph',
//           content:
//             'Without limiting the foregoing, we have the right to fully cooperate with any law enforcement authorities or court order requesting or directing us to disclose the identity or other information of anyone posting any materials on or through the Services. YOU WAIVE AND HOLD HARMLESS THE COMPANY FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY THE COMPANY DURING OR AS A RESULT OF ITS INVESTIGATIONS AND FROM ANY ACTIONS TAKEN AS A CONSEQUENCE OF INVESTIGATIONS BY EITHER THE COMPANY OR LAW ENFORCEMENT AUTHORITIES.'
//         },
//         {
//           type: 'paragraph',
//           content:
//             'However, we do not undertake to review all User Contributions before being posted, and cannot ensure prompt removal of objectionable material after it has been posted. Accordingly, we assume no liability for any action or inaction regarding transmissions, communications or content provided by any user or third party. We have no liability or responsibility to anyone for performance or nonperformance of the activities described in this section.'
//         }
//       ]
//     },
//     {
//       title: 'Content Standards',
//       sections: [
//         {
//           type: 'list',
//           heading:
//             'These content standards apply to any and all User Contributions and use of Interactive Services. User Contributions must in their entirety comply with all applicable federal, state, local and international laws and regulations. Without limiting the foregoing, User Contributions must not:',
//           items: [
//             {
//               type: 'list-item',
//               content:
//                 'Contain any material which is defamatory, obscene, indecent, abusive, offensive, harassing, violent, hateful, inflammatory or otherwise objectionable.'
//             },
//             {
//               type: 'list-item',
//               content:
//                 'Promote sexually explicit or pornographic material, violence, or discrimination based on race, sex, religion, nationality, disability, sexual orientation or age. '
//             },
//             {
//               type: 'list-item',
//               content:
//                 'Infringe any patent, trademark, trade secret, copyright or other intellectual property or other rights of any other person. '
//             },
//             {
//               type: 'list-item',
//               content:
//                 'Violate the legal rights (including the rights of publicity and privacy) of others or contain any material that could give rise to any civil or criminal liability under applicable laws or regulations or that otherwise may be in conflict with these Terms of Use. '
//             },
//             {
//               type: 'list-item',
//               content: 'Be likely to deceive any person.'
//             },
//             {
//               type: 'list-item',
//               content:
//                 'Promote any illegal activity, or advocate, promote or assist any unlawful act.'
//             },
//             {
//               type: 'list-item',
//               content:
//                 'Impersonate any person, or misrepresent an identity or affiliation with any person or organization.'
//             },
//             {
//               type: 'list-item',
//               content:
//                 'Involve commercial activities or sales, such as contests, sweepstakes and other sales promotions, barter or advertising.'
//             },
//             {
//               type: 'list-item',
//               content:
//                 'Give the impression that they emanate from or are endorsed by us or any other person or entity, if this is not the case. '
//             }
//           ]
//         }
//       ]
//     },
//     {
//       title: 'Violation of Content Standards; Copyright Infringement',
//       sections: [
//         {
//           type: 'paragraph',
//           content:
//             'If You believe that any User Contributions violate the Content Standards set forth herein, please contact us at support@civiltalk.com If You believe that any User Contributions violate Your copyright, please see our Copyright Policy for instructions on sending us a notice of copyright infringement. It is the policy of the Company to terminate the user accounts of repeat infringers. '
//         }
//       ]
//     },
//     {
//       title: 'Reliance on Information Posted',
//       sections: [
//         {
//           type: 'paragraph',
//           content:
//             'The information presented on or through the Services is made available solely for general information purposes. We do not warrant the accuracy, completeness or usefulness of this information. Any reliance You place on such information is strictly at Your own risk. We disclaim all liability and responsibility arising from any reliance placed on such materials by You or any other visitor to the Services, or by anyone who may be informed of any of its contents.'
//         },
//         {
//           type: 'paragraph',
//           content:
//             'The Services may include content provided by third parties, including materials provided by other users, bloggers and third-party licensors, syndicators, aggregators and/or reporting services. All statements and/or opinions expressed in these materials, and all articles and responses to questions and other content, other than the content provided by the Company, are solely the opinions and the responsibility of the person or entity providing those materials. These materials do not necessarily reflect the opinion of the Company. We are not responsible, or liable to You or any third party, for the content or accuracy of any materials provided by any third parties. '
//         }
//       ]
//     },
//     {
//       title: 'Changes to the Services',
//       sections: [
//         {
//           type: 'paragraph',
//           content:
//             'Content on the Services is updated from time to time, but its content is not necessarily complete or up-to-date. Any of the material on the Services may be out of date at any given time, and we are under no obligation to update such material. '
//         }
//       ]
//     },
//     {
//       title: 'Information About You and Your Visits to the Services',
//       sections: [
//         {
//           type: 'paragraph',
//           content:
//             'All information we collect on the Services is subject to our Privacy Policy. By using the Services, You consent to all actions taken by us with respect to Your information in compliance with the Privacy Policy. '
//         }
//       ]
//     },
//     {
//       title: 'Linking to the Services and Social Media Features',
//       sections: [
//         {
//           type: 'paragraph',
//           content:
//             'You may link to our homepage, provided You do so in a way that is fair and legal and does not damage our reputation or take advantage of it, but You must not establish a link in such a way as to suggest any form of association, approval or endorsement on our part. '
//         },
//         {
//           type: 'paragraph',
//           content:
//             'The Services from which You are linking, or on which You make certain content accessible, must comply in all respects with the Content Standards set out in these Terms of Use. '
//         },
//         {
//           type: 'paragraph',
//           content:
//             'You agree to cooperate with us in causing any unauthorized framing or linking immediately to cease. We reserve the right to withdraw linking permission without notice. '
//         },
//         {
//           type: 'paragraph',
//           content:
//             'We may disable all or any social media features and any links at any time without notice in our discretion.'
//         }
//       ]
//     },
//     {
//       title: 'Links from the Services',
//       sections: [
//         {
//           type: 'paragraph',
//           content:
//             'If the Services contains links to other sites and resources provided by third parties, these links are provided for Your convenience only. This includes links contained in User Contributions, advertisements, including banner advertisements and sponsored links. We have no control over the contents of those sites or resources and accept no responsibility for them or for any loss or damage that may arise from Your use of them. If You decide to access any of the third party Services linked to the Services, You do so entirely at Your own risk and subject to the terms and conditions of use for such Services. '
//         }
//       ]
//     },
//     {
//       title: 'Geographic Restrictions',
//       sections: [
//         {
//           type: 'paragraph',
//           content:
//             'The owner of the Services is based in the State of California in the United States. We provide the Services for use only by persons located in the United States. We make no claims that the Services or any of its content is accessible or appropriate outside of the United States. Access to the Services may not be legal by certain persons or in certain countries. If You access the Services from outside the United States, You do so on Your own initiative and are responsible for compliance with local laws. '
//         }
//       ]
//     },
//     {
//       title: 'Disclaimer of Warranties',
//       sections: [
//         {
//           type: 'paragraph',
//           content:
//             'You understand that we cannot and do not guarantee or warrant that files available for downloading from the internet or the Services will be free of viruses or other destructive code. You are responsible for implementing sufficient procedures and checkpoints to satisfy Your particular requirements for anti-virus protection and accuracy of data input and output, and for maintaining a means external to our site for any reconstruction of any lost data. WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE SERVICES OR ANY INFORMATION OR ITEMS OBTAINED THROUGH THE SERVICES OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY SERVICES LINKED TO IT.'
//         },
//         {
//           type: 'paragraph',
//           content:
//             'YOUR USE OF THE SERVICES, ITS CONTENT AND ANY INFORMATION, MATERIALS OR ITEMS OBTAINED THROUGH THE SERVICES IS AT YOUR OWN RISK. THE SERVICES, ITS CONTENT AND ANY INFORMATION, MATERIALS OR ITEMS OBTAINED THROUGH THE SERVICES ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER THE COMPANY NOR ANY PERSON ASSOCIATED WITH THE COMPANY MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY OR AVAILABILITY OF THE SERVICES. WITHOUT LIMITING THE FOREGOING, NEITHER THE COMPANY NOR ANYONE ASSOCIATED WITH THE COMPANY REPRESENTS OR WARRANTS THAT THE SERVICES, ITS CONTENT OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES WILL BE ACCURATE, RELIABLE, ERROR-FREE OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR SITE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE SERVICES OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS. '
//         },
//         {
//           type: 'paragraph',
//           content:
//             'THE COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT AND FITNESS FOR PARTICULAR PURPOSE. '
//         },
//         {
//           type: 'paragraph',
//           content:
//             'THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.'
//         }
//       ]
//     },
//     {
//       title: 'Limitation on Liability',
//       sections: [
//         {
//           type: 'paragraph',
//           content:
//             'TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL THE COMPANY, ITS AFFILIATES OR THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE SERVICES, ANY WEBSITES LINKED TO IT, ANY CONTENT ON THE SERVICES OR SUCH OTHER WEBSITES OR ANY INFORMATION OR ITEMS OBTAINED THROUGH THE SERVICES OR SUCH OTHER WEBSITES, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT OR OTHERWISE, EVEN IF FORESEEABLE. '
//         },
//         {
//           type: 'paragraph',
//           content:
//             'THE FOREGOING DOES NOT AFFECT ANY LIABILITY WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.'
//         }
//       ]
//     },
//     {
//       title: 'Indemnification',
//       sections: [
//         {
//           type: 'paragraph',
//           content:
//             "You agree to defend, indemnify and hold harmless the Company, its affiliates, licensors and service providers, and its and their respective officers, directors, employees, contractors, agents, licensors, suppliers, successors and assigns from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses or fees (including reasonable attorneys' fees) arising out of or relating to Your violation of these Terms of Use or Your use of the Services, including, but not limited to, Your User Contributions, any use of the Services's content, materials and products other than as expressly authorized in these Terms of Use, or Your use of any information obtained from the Services."
//         }
//       ]
//     },
//     {
//       title: 'Governing Law and Jurisdiction',
//       sections: [
//         {
//           type: 'paragraph',
//           content:
//             'All matters relating to the Services and these Terms of Use, and any dispute or claim arising therefrom or related thereto (in each case, including non-contractual disputes or claims), shall be governed by and construed in accordance with the internal laws of the State of California without giving effect to any choice or conflict of law provision or rule (whether of the State of California or any other jurisdiction).'
//         },
//         {
//           type: 'paragraph',
//           content:
//             'Any legal suit, action or proceeding arising out of, or related to, these Terms of Use or the Services shall be instituted exclusively in the federal courts of the United States or the courts of the State of Connecticut although we retain the right to bring any suit, action or proceeding against You for breach of these Terms of Use in Your country of residence or any other relevant country. You waive any and all objections to the exercise of jurisdiction over You by such courts and to venue in such courts.'
//         }
//       ]
//     },
//     {
//       title: 'Arbitration',
//       sections: [
//         {
//           type: 'paragraph',
//           content:
//             "At Company's sole discretion, it may require You to submit any disputes arising from these Terms of Use or use of the Services, including disputes arising from or concerning their interpretation, violation, invalidity, nonperformance, or termination, to final and binding arbitration under the Rules of Arbitration of the American Arbitration Association applying California law."
//         }
//       ]
//     },
//     {
//       title: 'Limitation on Time to File Claims',
//       sections: [
//         {
//           type: 'paragraph',
//           content:
//             'ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO THESE TERMS OF USE OR THE SERVICES MUST BE COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES; OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY BARRED.'
//         }
//       ]
//     },
//     {
//       title: 'Waiver and Severability',
//       sections: [
//         {
//           type: 'paragraph',
//           content:
//             'No waiver of by the Company of any term or condition set forth in these Terms of Use shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure of the Company to assert a right or provision under these Terms of Use shall not constitute a waiver of such right or provision.'
//         },
//         {
//           type: 'paragraph',
//           content:
//             'If any provision of these Terms of Use is held by a court or other tribunal of competent jurisdiction to be invalid, illegal or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent such that the remaining provisions of the Terms of Use will continue in full force and effect.'
//         }
//       ]
//     },
//     {
//       title: 'Entire Agreement',
//       sections: [
//         {
//           type: 'paragraph',
//           content:
//             'The Terms of Use and Copyright Policy constitute the sole and entire agreement between You and CivilTalk LLC with respect to the Services and supersede all prior and contemporaneous understandings, agreements, representations and warranties, both written and oral, with respect to the Services.'
//         }
//       ]
//     },
//     {
//       title: 'Your Comments and Concerns',
//       sections: [
//         {
//           type: 'paragraph',
//           content:
//             'The Services are operated by CivilTalk LLC, PO Box 627, Rancho Santa Fe, CA 92067.'
//         },
//         {
//           type: 'paragraph',
//           content:
//             'All notices of copyright infringement claims should be sent to the copyright agent designated in our Copyright Policy in the manner and by the means set forth therein. '
//         },
//         {
//           type: 'paragraph',
//           content:
//             'All other feedback, comments, requests for technical support and other communications relating to the Services should be directed to: support@civiltalk.com.'
//         }
//       ]
//     }
//   ]
// }

const cardStyles = {
  marginTop: '2rem',
  padding: '2rem',
  '@media (max-width: 700px)': {
    '& .MuiPaper-root-MuiCard-root': {
      margin: '0'
    }
  },
  '@media (max-width: 400px)': {
    padding: '1.75rem 1rem',
    '& .MuiPaper-root-MuiCard-root': {
      margin: '0'
    }
  }
}

const titleStyles = {
  marginBottom: '1.5rem',
  '@media (max-width: 700px)': {
    fontSize: '1.5rem',
    fontWeight: 600
  },
  '@media (max-width: 400px)': {
    fontSize: '1.15rem',
    fontWeight: 700
  }
}

const paragraphStyles = {
  marginBottom: '1.5rem',
  '@media (max-width: 400px)': {
    fontSize: '1.1rem',
    fontWeight: 500
  }
}

function TermsOfUseView() {
  const [terms, setTerms] = useState({})
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getTermsOfUse()).then((data) => {
      setTerms(data.terms[0])
    })
  }, [])

  return (
    <MainContainer>
      <ContentContainer>
        {terms &&
          terms.articles &&
          terms.articles.map((article) => {
            const { title, sections } = article

            return (
              <Card sx={cardStyles} key={title}>
                <Typography variant="h4" gutterBottom sx={titleStyles}>
                  {title}
                </Typography>
                {sections.map((section) => {
                  const { type, content } = section

                  if (type === 'paragraph') {
                    return (
                      <Typography variant="h6" gutterBottom key={content} sx={paragraphStyles}>
                        {content}
                      </Typography>
                    )
                  } else if (type === 'list') {
                    return (
                      <div key={section.heading}>
                        <Typography
                          variant="h6"
                          gutterBottom
                          key={section.heading}
                          sx={paragraphStyles}>
                          {section.heading}
                        </Typography>
                        <List>
                          {section.items.map((item) => {
                            return (
                              <ListItem key={item.content} alignItems="flex-start">
                                <ListItemIcon sx={{ minWidth: '2rem' }}>
                                  <CircleIcon fontSize="small" color="secondary" />
                                </ListItemIcon>
                                <Typography variant="h6" sx={paragraphStyles}>
                                  {item.content}
                                </Typography>
                              </ListItem>
                            )
                          })}
                        </List>
                      </div>
                    )
                  }
                })}
              </Card>
            )
          })}
      </ContentContainer>
    </MainContainer>
  )
}

export default TermsOfUseView
