import styled from 'styled-components'
import theme from '../../../../res/theme.js'
// import config from '../../../../config.js'

export const MainContainer = styled.div`
  background-color: white;
  display: block;
  padding: 1.7rem 2rem 2rem 2rem;
  margin-bottom: 1rem;
  border-radius: 0.3rem;
  box-shadow: 0px 5px 5px -2px rgba(0, 0, 0, 0.2);
  border: 1px solid #dcdddd;
`

export const HeadingPrimary = styled.p`
  color: props.theme.text.primary;
  line-height: 2.2rem;
  font-family: ${theme.typography.fontFamily};
  font-size: 1.6rem;
  font-weight: ${theme.typography.fontWeight.bold};
  color: ${theme.typography.fontColor.bodyCopy};

  & > span {
    background-image: -moz-linear-gradient(
      180deg,
      rgba(171, 202, 179, 1) 0%,
      rgba(110, 140, 118, 1) 100%
    );
    background-image: -webkit-linear-gradient(
      180deg,
      rgba(171, 202, 179, 1) 0%,
      rgba(110, 140, 118, 1) 100%
    );
    background-image: -ms-linear-gradient(
      180deg,
      rgba(171, 202, 179, 1) 0%,
      rgba(110, 140, 118, 1) 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`

export const QuoteContainer = styled.div`
  margin-top: 1rem;
  padding: 0 1rem 0 2.3rem;
`

export const QuoteText = styled.p`
  font-family: ${theme.typography.fontFamily};
  font-size: ${theme.typography.fontSizes.small};
  font-weight: ${theme.typography.fontWeight.medium};
  color: ${theme.typography.fontColor.bodyCopy};
  position: relative;

  & > img:nth-child(1) {
    display: block;
    width: 1.5rem;
    height: auto;
    position: absolute;
    top: 0rem;
    left: -2.3rem;
  }
`

export const QuoteName = styled.p`
  text-align: right;
  margin-top: 0.5rem;
  font-family: ${theme.typography.fontFamily};
  font-size: ${theme.typography.fontSizes.smaller};
  font-weight: ${theme.typography.fontWeight.medium};
  color: ${theme.typography.fontColor.headerCopy};
`
