const LoadMoreByType = (
  filter,
  ref,
  notifications,
  setNotifications,
  startAfter,
  setStartAfter,
  limit,
  setLoading
) => {
  // *************************
  // *** LOAD MORE QUERIES ***
  // *************************
  const getMoreUnread = () => {
    ref
      .where('read', '==', false)
      .orderBy('whenCreated', 'desc')
      .startAfter(startAfter || 0)
      .limit(limit)
      .onSnapshot((querySnapshot) => {
        const items = []
        const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1]

        querySnapshot.forEach((doc) => {
          items.push({
            id: doc.id,
            ...doc.data()
          })
        })

        setNotifications([...notifications, ...items])
        setStartAfter(lastVisible)
        setLoading(false)
      })
  }

  const getMoreConnections = () => {
    ref
      .where('type', 'in', ['new-follower', 'new-organization-follower'])
      .orderBy('whenCreated', 'desc')
      .startAfter(startAfter || 0)
      .limit(limit)
      .onSnapshot((querySnapshot) => {
        const items = []
        const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1]

        querySnapshot.forEach((doc) => {
          items.push({
            id: doc.id,
            ...doc.data()
          })
        })

        setNotifications([...notifications, ...items])
        setStartAfter(lastVisible)
        setLoading(false)
      })
  }

  const getMoreActivity = () => {
    ref
      .where('type', 'in', [
        'like',
        'discussion-request',
        'discussion-accepted',
        'discussion-counter-accepted',
        'discussion-countered',
        'discussion-ended',
        'new-rebuttal',
        'organization-donation-received',
        'tokens-transfered'
      ])
      .orderBy('whenCreated', 'desc')
      .startAfter(startAfter || 0)
      .limit(limit)
      .onSnapshot((querySnapshot) => {
        const items = []
        const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1]

        querySnapshot.forEach((doc) => {
          items.push({
            id: doc.id,
            ...doc.data()
          })
        })

        setNotifications([...notifications, ...items])
        setStartAfter(lastVisible)
        setLoading(false)
      })
  }

  const getMoreCommunity = () => {
    ref
      .where('type', 'in', ['following-take', 'following-organization-post'])
      .orderBy('whenCreated', 'desc')
      .startAfter(startAfter || 0)
      .limit(limit)
      .onSnapshot((querySnapshot) => {
        const items = []
        const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1]

        querySnapshot.forEach((doc) => {
          items.push({
            id: doc.id,
            ...doc.data()
          })
        })

        setNotifications([...notifications, ...items])
        setStartAfter(lastVisible)
        setLoading(false)
      })
  }

  const getMoreAccount = () => {
    ref
      .where('type', 'in', [
        'welcome',
        'email-updated',
        'phone-updated',
        'password-updated',
        'nickname-updated',
        'login-reminder',
        'verification-failed',
        'verification-succeeded',
        'assigned-role',
        'donation-token-payment-confirmation'
      ])
      .orderBy('whenCreated', 'desc')
      .startAfter(startAfter || 0)
      .limit(limit)
      .onSnapshot((querySnapshot) => {
        const items = []
        const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1]

        querySnapshot.forEach((doc) => {
          items.push({
            id: doc.id,
            ...doc.data()
          })
        })

        setNotifications([...notifications, ...items])
        setStartAfter(lastVisible)
        setLoading(false)
      })
  }

  // ******************************************************* //
  // *** RETURN MORE RESULTS BASED ON THE CURRENT FILTER *** //
  // ******************************************************* //
  switch (filter) {
    case 'unread':
      return getMoreUnread()
    case 'connections':
      return getMoreConnections()
    case 'activity':
      return getMoreActivity()
    case 'community':
      return getMoreCommunity()
    case 'account':
      return getMoreAccount()
    default:
      return getMoreUnread()
  }
}

export default LoadMoreByType
