import React, { useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import EditRebuttalValidation from '../validations/edit-rebuttal'
import { editRebuttal } from '../../../../redux/thunks/rebuttals/editRebuttal'
import getAvatar from '../../../../utils/functions/getAvatar'
import EmotionPicker, {
  finalEmotionForEmotion,
  subEmotionForEmotion,
  topEmotionForEmotion
} from '../../emotion-picker/emotion-picker'
import { Button, CircularProgress } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import {
  FormContainer,
  TextareaProfilePicture,
  TextareaContainer,
  StyledTextarea,
  SubmitContainer
} from './styled/discussion-comps'
import { EditFormContainer, TextCount } from './styled/edit-rebuttal-form-comps'

const useStyles = makeStyles((theme) =>
  createStyles({
    edit: {
      display: 'inline-block',
      minHeight: '2.5rem',
      height: '2.5rem',
      width: '11rem',
      marginTop: '1rem',
      marginLeft: '1.5rem',
      verticalAlign: 'middle',
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.light
      },
      [theme.breakpoints.down('sm')]: {
        width: '8rem'
      }
    },
    cancel: {
      display: 'inline-block',
      minHeight: '2.5rem',
      height: '2.5rem',
      width: '11rem',
      verticalAlign: 'middle',
      marginTop: '1rem',
      [theme.breakpoints.down('sm')]: {
        width: '8rem'
      }
    },
    circularProgress: {
      display: 'block',
      width: '17px !important',
      height: '17px !important',
      margin: '.3rem auto 0 auto',
      '& svg': {
        color: 'white'
      }
    }
  })
)

function EditRebuttalForm(props) {
  /*
  props
  setState
  state
  */
  const classes = useStyles()
  const currentProfile = useSelector((state) => state.currentProfile)
  const { documentVerified } = currentProfile
  const verified = documentVerified ? 1 : 0
  const dispatch = useDispatch()
  /** MAIN COMPONENT HOOKS */
  const [rebuttal, setRebuttal] = useState('')
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState('')
  /** EMOTIONS HOOKS */
  const [topEmotion, setTopEmotion] = useState('')
  const [subEmotion, setSubEmotion] = useState('')
  const [finalEmotion, setFinalEmotion] = useState('')

  // load current rebuttal into local state
  useEffect(() => {
    if (props.originalRebuttal.rebuttal) {
      setRebuttal(props.originalRebuttal.rebuttal)
    }
  }, [])

  /**
   * Load original emotion into state
   * Only run on first render
   * */
  useEffect(() => {
    if (props.originalRebuttal.emotion) {
      setTopEmotion(topEmotionForEmotion(props.originalRebuttal.emotion))
      setSubEmotion(subEmotionForEmotion(props.originalRebuttal.emotion))
      setFinalEmotion(finalEmotionForEmotion(props.originalRebuttal.emotion))
    }
  }, [])

  const postEmotion = useMemo(() => {
    if (finalEmotion) {
      return finalEmotion
    } else if (subEmotion) {
      return subEmotion
    }
    return topEmotion
  }, [topEmotion, subEmotion, finalEmotion])

  const handleSubmitRebuttalEdit = async () => {
    setProcessing(true)

    const { error } = EditRebuttalValidation({ rebuttal })

    if (error !== '') {
      setProcessing(false)
      setError(error)
    } else {
      const { originalRebuttal } = props

      dispatch(
        editRebuttal({
          rebuttalId: originalRebuttal._id,
          rebuttal: rebuttal,
          emotion: postEmotion
        })
      )
        .then((data) => {
          if (data.error) {
            console.log('[FAIL]: ', 'Failed to edit rebuttal!')

            setProcessing(false)
            setError(data.message)
          } else {
            console.log('[SUCCESS]: ', 'Successfully edited rebuttal!')

            setRebuttal('')
            setProcessing(false)
            setError('')

            props.setState({
              ...props.state,
              status: {
                ...props.state.status,
                isEditingRebuttal: false
              },
              editedRebuttalId: ''
            })
          }
        })
        .catch((err) => {
          setProcessing(false)
          setError(err)
        })
    }
  }

  const handleCancelEdit = () => {
    props.setState({
      ...props.state,
      status: {
        ...props.state.status,
        isEditingRebuttal: false
      },
      editedRebuttalId: ''
    })
  }

  return (
    <EditFormContainer>
      <FormContainer>
        <TextareaProfilePicture
          style={{ backgroundImage: `url(${getAvatar(currentProfile.avatar)})` }}
          verified={verified}
        />

        <TextareaContainer>
          <StyledTextarea
            name="rebuttal"
            value={rebuttal}
            placeholder="Please enter a response in 3000 characters or less..."
            wrap="soft"
            maxLength="3000"
            onChange={(e) => setRebuttal(e.target.value)}></StyledTextarea>
        </TextareaContainer>
        <TextCount>{`${rebuttal.length} / 3000`}</TextCount>
      </FormContainer>
      <p style={{ fontSize: '1rem', color: '#000' }}>Edit your emotion:</p>
      <EmotionPicker
        topEmotion={topEmotion}
        subEmotion={subEmotion}
        finalEmotion={finalEmotion}
        setTopEmotion={setTopEmotion}
        setSubEmotion={setSubEmotion}
        setFinalEmotion={setFinalEmotion}
      />

      <SubmitContainer>
        <Button
          variant="contained"
          color="secondary"
          className={classes.cancel}
          onClick={handleCancelEdit}>
          Cancel
        </Button>

        <Button
          variant="contained"
          color="primary"
          className={classes.edit}
          onClick={handleSubmitRebuttalEdit}
          disabled={
            processing ||
            rebuttal.trim().length === 0 ||
            rebuttal === props.originalRebuttal.rebuttal
          }>
          {processing ? (
            <CircularProgress color="secondary" className={classes.circularProgress} />
          ) : (
            'Save'
          )}
        </Button>
      </SubmitContainer>
    </EditFormContainer>
  )
}

export default EditRebuttalForm
