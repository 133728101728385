//////////////////
// ACTION TYPES //
//////////////////

export const firebaseAuthActionTypes = {
  LOG_IN: 'LOG_IN',
  LOG_OUT: 'LOG_OUT',
  ERROR: 'ERROR'
}

/////////////
// ACTIONS //
/////////////

// ACTION RESPONSE
const logIn = status => {
  return {
    type: firebaseAuthActionTypes.LOG_IN,
    status
  }
}

const logOut = status => {
  return {
    type: firebaseAuthActionTypes.LOG_OUT,
    status
  }
}

const error = error => {
  return {
    type: firebaseAuthActionTypes.ERROR,
    error
  }
}

export const firebaseAuthActions = {
  logIn,
  logOut,
  error
}
