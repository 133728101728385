import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import withStyles from '@mui/styles/withStyles'
import { CheckCircle, Info, Report, ReportProblem, Close } from '@mui/icons-material'

const Container = styled.div`
  background-color: ${({ backgroundColor }) => backgroundColor};
  margin-bottom: 1rem;
  padding: 0.8rem 1rem;
  box-sizing: border-box;
  border-radius: 0.3rem;
  border: ${({ border }) => border};
  position: relative;
`

const Body = styled.p`
  margin: ${({ margin }) => margin};
  overflow: hidden;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.small};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  color: ${({ color }) => color};

  & > a {
    color: ${({ color }) => color};
    cursor: pointer;

    &:link,
    &:visited,
    &:active {
      text-decoration: underline;
      color: ${({ color }) => color};
    }
    &:hover {
      text-decoration: underline;
      color: ${({ color }) => color};
    }
  }
`

const CloseContainer = styled.div`
  display: block;
  width: 3rem;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;

  &:hover {
    background-color: rgba(255, 255, 255, 0.3);
  }
`

const BannerTypes = ['info', 'confirmation', 'warning', 'alert']

const getIcon = (type) => {
  switch (type) {
    case 'info':
      return Info
    case 'confirmation':
      return CheckCircle
    case 'warning':
      return ReportProblem
    case 'alert':
      return Report
    default:
      return null
  }
}

const getColor = (type) => {
  switch (type) {
    case 'info':
      return 'rgba(1, 147, 247, 1)'
    case 'confirmation':
      return 'rgba(51, 204, 51, 1)'
    case 'warning':
      return 'rgba(246, 107, 11, 1)'
    case 'alert':
      return 'rgba(204, 0, 0, .5)'
    default:
      return null
  }
}

const styles = () => ({
  icon: {
    display: 'block',
    float: 'left',
    margin: '-.1rem .8rem 0 0',
    fontSize: '1.5rem'
  },
  closeIcon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translateX(-50%) translateY(-50%)'
  }
})

const WarningBanner = ({
  classes,
  type,
  variant,
  showButton,
  buttonAction,
  customColor,
  htmlBody,
  style
}) => {
  if (BannerTypes.indexOf(type) === -1) {
    console.error('[CLIENT ERROR]: warning-banner component type property is invalid.')

    return null
  } else if (!htmlBody) {
    console.error('[CLIENT ERROR]: warning-banner component requires htmlBody.')

    return null
  } else if (showButton && !buttonAction) {
    console.error(
      '[CLIENT ERROR]: warning-banner component requires buttonAction if showButton is true.'
    )

    return null
  }

  const Icon = getIcon(type)
  const color = customColor || getColor(type)
  const backgroundColor = variant === 'outlined' ? 'rgba(0,0,0,0)' : color
  const border = variant === 'outlined' ? `2px solid ${color}` : 'none'
  const fontColor = variant === 'outlined' ? color : 'white'

  return (
    <Container backgroundColor={backgroundColor} border={border} style={style}>
      <Icon
        className={classes.icon}
        style={variant === 'outlined' ? { color } : { color: 'white' }}
      />
      <Body color={fontColor} margin={showButton ? '0 3rem 0 0' : '0'}>
        {htmlBody}
      </Body>

      {showButton && (
        <CloseContainer onClick={buttonAction}>
          <Close
            className={classes.closeIcon}
            style={variant === 'outlined' ? { color } : { color: 'white' }}
          />
        </CloseContainer>
      )}
    </Container>
  )
}

WarningBanner.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(WarningBanner)
