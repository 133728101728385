import { filterInboxItems, filterSentItems } from './filter-items.js'
import nestedSort from '../../../../utils/functions/nestedSort.js'
import _ from 'lodash'

export const mapFilterSortDiscussionsToRebuttals = (discussions, rebuttals, currentUser, type) => {
  const filterOperation = type === 'Inbox' ? filterInboxItems : filterSentItems

  const discussionsToFilter = filterOperation(discussions, currentUser)

  const mappedDiscussionsToRebuttals = discussionsToFilter.map((discussion) => {
    const filteredRebuttals = _.filter(rebuttals, { debateId: discussion._id })
    const sortedRebuttals = nestedSort(filteredRebuttals, 1, 'whenCreated')

    return {
      ...discussion,
      rebuttals: sortedRebuttals,
      lastRebuttal: sortedRebuttals[sortedRebuttals.length - 1]
    }
  })

  const mapSortDateToInboxDiscussions = mappedDiscussionsToRebuttals.map((discussion) => {
    // const { id } = currentUser
    const {
      // accepted,
      // complete,
      // guestUserId,
      // hostUserId,
      // isCounter,
      // isRequest,
      // lastRebuttal,
      whenCreated
      // whenModified,
      // whenCompleted
    } = discussion

    // const isIncomingRequest = isRequest && hostUserId === id

    // const isIncomingCounter = isCounter && guestUserId === id

    // const ongoing = accepted && !complete

    // const isComplete = complete

    // let sortDate

    // if (isIncomingRequest) sortDate = whenCreated
    // if (isIncomingCounter) sortDate = whenModified
    // if (ongoing) sortDate = lastRebuttal && lastRebuttal.whenCreated
    // if (isComplete) sortDate = whenCompleted

    return {
      ...discussion,
      sortDate: whenCreated
    }
  })

  const mapSortDateToSentDiscussions = mappedDiscussionsToRebuttals.map((discussion) => {
    const { id } = currentUser
    const {
      accepted,
      complete,
      guestUserId,
      hostUserId,
      isCounter,
      isRequest,
      lastRebuttal,
      lastRebuttalUserId,
      whenModified
    } = discussion

    const isOutgoingRequest = isRequest && guestUserId === id

    const isOutgoingCounter = isCounter && hostUserId === id

    const isOtherUsersTurn = accepted && !complete && lastRebuttalUserId === id

    let sortDate

    if (isOutgoingRequest) sortDate = whenModified
    if (isOutgoingCounter) sortDate = whenModified
    if (isOtherUsersTurn) sortDate = lastRebuttal && lastRebuttal.whenCreated

    return {
      ...discussion,
      sortDate
    }
  })

  const discussionsToSort =
    type === 'Inbox' ? mapSortDateToInboxDiscussions : mapSortDateToSentDiscussions

  const sorted = nestedSort(discussionsToSort, -1, 'sortDate')

  return sorted
}

export default mapFilterSortDiscussionsToRebuttals
