export async function authenticatedFetch(url, options = {}) {
  const token = sessionStorage.getItem('ct_access_token')

  const headers = token
    ? {
        ...options.headers,
        Authorization: `Bearer ${token}`
      }
    : options.headers

  const response = await fetch(url, {
    ...options,
    headers
  })

  // Handle authentication errors or other global logic here if needed

  return response
}
