//////////////////
// ACTION TYPES //
//////////////////

export const currentUserActionTypes = {
	SET_CURRENT_USER: 	     	'SET_CURRENT_USER',
	UPDATE_CURRENT_USER: 		'UPDATE_CURRENT_USER',
	CLEAR_CURRENT_USER: 	 	'CLEAR_CURRENT_USER'
}

/////////////
// ACTIONS //
/////////////

// CURRENT USER
const setUser = user => {
	return { 
		type: currentUserActionTypes.SET_CURRENT_USER, 
		user 
	}
}

const updateUser = fields => {
	return { 
		type: currentUserActionTypes.UPDATE_CURRENT_USER, 
		fields 
	}
}

const clearUser = () => {
	return { 
		type: currentUserActionTypes.CLEAR_CURRENT_USER 
	}
}

export const currentUserActions = {
	setUser,
	updateUser,
	clearUser
}