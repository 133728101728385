import { useState, useEffect, useContext } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { createAccountLinkOnboarding } from '../../../../../../../redux/thunks/stripe/create-account-link-onboarding'
import { createAccountLoginLink } from '../../../../../../../redux/thunks/stripe/create-account-login-link'
import StripeIcon from '../../../../../../../media/icons/stripe/png/powered_by_stripe_blurple.png'
import getRequirementsSummary from './functions/get-requirements-summary'
import FormError from '../../../../../../alerts/form-error'
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import {
  MainContainer,
  Heading,
  Subheading,
  ActionsContainer,
  StripeImage
} from './styled/stripe-account-actions-comps'
import LoadOrganizationStripeAccountContext from '../../../../../../data-loading/load-organization-stripe-account/context/context'

const styles = {
  button: {
    display: 'inline-block',
    veerticalAlign: 'middle',
    width: '15rem',
    boxSizing: 'border-box',
    '@media (max-width: 500px)': {
      width: '100%'
    }
  },
  circularProgress: {
    display: 'block',
    width: '17px !important',
    height: '17px !important',
    margin: '.3rem auto 0 auto',
    '& svg': {
      color: 'white'
    }
  }
}

function StripeAccountActions() {
  const { orgId, refreshType } = useParams()
  const { stripeAccount } = useContext(LoadOrganizationStripeAccountContext)
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState('')
  const dispatch = useDispatch()
  const { details_submitted } = stripeAccount
  const currentRequirements = getRequirementsSummary(stripeAccount.requirements)
  const futureRequirements = getRequirementsSummary(stripeAccount.future_requirements)

  console.log({ stripeAccount })

  // If Stripe sent user to refresh their onboarding process,
  // make sure to get new AccountLink and redirect user
  useEffect(() => {
    if (refreshType === 'refresh-account-onboarding') {
      setProcessing(true)

      dispatch(createAccountLinkOnboarding({ organizationId: orgId }))
        .then((data) => {
          if (!data.error) {
            console.log('[SUCCESS]: Successfully refreshed account link (onboarding).')

            window.location.href = data.accountLink.url
          } else {
            console.log('[FAIL]: ', data)

            setError(data.message)
          }

          setProcessing(false)
        })
        .catch((error) => {
          console.log('[ERROR]: ', error)

          setError('Something went wrong. Please try again.')
          setProcessing(false)
        })
    }
  }, [dispatch, orgId, refreshType])

  const handleGenerateAccountLinkOnboarding = () => {
    setProcessing(true)

    dispatch(createAccountLinkOnboarding({ organizationId: orgId }))
      .then((data) => {
        if (!data.error) {
          console.log('[SUCCESS]: Successfully created account link (onboarding).')

          window.location.href = data.accountLink.url
        } else {
          console.log('[FAIL]: ', data)

          setError(data.message)
        }

        setProcessing(false)
      })
      .catch((error) => {
        console.log('[ERROR]: ', error)

        setError('Something went wrong. Please try again.')
        setProcessing(false)
      })
  }

  const handleGenerateLoginLink = () => {
    setProcessing(true)

    dispatch(createAccountLoginLink({ organizationId: orgId }))
      .then((data) => {
        if (!data.error) {
          console.log('[SUCCESS]: Successfully created login link!')

          window.location.href = data.loginLink.url
        } else {
          console.log('[FAIL]: ', data)

          setError(data.message)
        }

        setProcessing(false)
      })
      .catch((error) => {
        console.log('[ERROR]: ', error)

        setError('Something went wrong. Please try again.')
        setProcessing(false)
      })
  }

  return (
    <MainContainer>
      <Heading>{details_submitted ? 'Manage Billing Settings' : 'Start Receiving Payouts'}</Heading>

      {!details_submitted && (
        <Alert severity="error" sx={{ marginTop: '1rem' }}>
          <b>Action Needed: </b>In order to begin receiving donations and requesting payouts, please
          complete your organization's billing setup hosted by Stripe.
        </Alert>
      )}

      {details_submitted && currentRequirements && (
        <Alert severity="error" sx={{ marginTop: '1rem' }}>
          <b>Action Needed: </b>
          {currentRequirements}
        </Alert>
      )}

      {details_submitted && !currentRequirements && futureRequirements && (
        <Alert severity="warning" sx={{ marginTop: '1rem' }}>
          <b>Warning: </b>
          {futureRequirements}
        </Alert>
      )}

      <Subheading>
        Your organization will not be able to receive token donations until setting up a billing
        account. We have partnered with{' '}
        <a href="https://www.stripe.com" target="_blank" rel="noopener noreferrer">
          Stripe
        </a>{' '}
        in order to provide a secure and easy way to start receiving payouts.
      </Subheading>

      <FormError variant="contained" message={error} style={{ margin: '1rem 0 0 0' }} />

      {details_submitted ? (
        <ActionsContainer>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={styles.button}
            onClick={handleGenerateLoginLink}
            disabled={processing}>
            {processing ? (
              <CircularProgress color="secondary" sx={styles.circularProgress} />
            ) : (
              'Login to Dashboard'
            )}
          </Button>

          <a href={'https://www.stripe.com'} target="_blank" rel="noopener noreferrer">
            <StripeImage src={StripeIcon} alt="Powered by Stripe Icon" />
          </a>
        </ActionsContainer>
      ) : (
        <ActionsContainer>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={styles.button}
            onClick={handleGenerateAccountLinkOnboarding}
            disabled={processing}>
            {processing ? (
              <CircularProgress color="secondary" sx={styles.circularProgress} />
            ) : (
              'Set Up Billing'
            )}
          </Button>

          <a href={'https://www.stripe.com'} target="_blank" rel="noopener noreferrer">
            <StripeImage src={StripeIcon} alt="Powered by Stripe Icon" />
          </a>
        </ActionsContainer>
      )}
    </MainContainer>
  )
}

export default StripeAccountActions
