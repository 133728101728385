import React from 'react'
import { useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
// import { analytics } from "../../../firebase/firebase"
import { history } from '../../../../../history/history'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import { Button } from '@mui/material'
import { Header, Subheader, ButtonsContainer } from '../../styled/verification-steps-comps'

const useStyles = makeStyles((theme) =>
  createStyles({
    primaryButton: {
      display: 'inline-block',
      width: '15rem',
      height: '3.5rem',
      borderRadius: '.3rem',
      fontSize: '1.125rem', // 18px
      textTransform: 'none',
      '&:hover': {
        backgroundColor: theme.palette.primary.light
      },
      '@media (max-width: 650px)': {
        display: 'block',
        width: '16rem',
        height: '3rem',
        margin: '1rem auto 0 auto',
        fontSize: '1rem' // 16px
      },
      '@media (max-width: 400px)': {
        width: '100%'
      }
    },
    secondaryButton: {
      display: 'inline-block',
      width: '15rem',
      height: '3.5rem',
      marginRight: '2rem',
      borderRadius: '.3rem',
      // fontSize: theme.typography.fontSizes.short,
      textTransform: 'none',
      // backgroundColor: '#2eb2fa',
      // '&:hover': {
      //   backgroundColor: '#51bffb'
      // },
      '@media (max-width: 650px)': {
        display: 'block',
        width: '16rem',
        height: '3rem',
        margin: '0 auto'
        // fontSize: theme.typography.fontSizes.small
      },
      '@media (max-width: 400px)': {
        width: '100%'
      }
    }
  })
)

function Finish() {
  const classes = useStyles()
  // const currentUser = useSelector(({ currentUser }) => currentUser)
  const currentProfile = useSelector(({ currentProfile }) => currentProfile)
  // look at the config file
  // currentUser.stipe.subscriptionId || currentUser.iap
  // if currentUser.stripe.subscriptionId === price_1JNOmyHvGX36GL6rxMoU5x8V => show the token message

  return (
    <>
      <Header>Thank you for verifying</Header>

      {currentProfile.hasPerformedSetup ? (
        <Subheader>
          We are so excited that you have decided to join our verified community. Please feel free
          to begin engaging and connecting with others in the community.
        </Subheader>
      ) : (
        <Subheader>
          We are so excited that you have decided to join our verified community. Please take a
          moment to fill out your profile so that you can maximize your experience.
        </Subheader>
      )}

      <ButtonsContainer>
        {currentProfile.hasPerformedSetup ? (
          <>
            <Button
              variant="contained"
              color="primary"
              className={classes.primaryButton}
              onClick={() => history.push(`/profile/${currentProfile.userId}`)}>
              Visit Your Profile
            </Button>

            {/**
              <Button
              variant="contained"
              color="primary"
              className={classes.primaryButton}
              onClick={() => history.push(`/home`)}>
              Explore Home
              </Button>
            */}
          </>
        ) : (
          <Button
            variant="contained"
            color="primary"
            className={classes.primaryButton}
            onClick={() => history.push(`/profile/${currentProfile.userId}`)}>
            Visit My Profile
          </Button>
        )}
      </ButtonsContainer>
    </>
  )
}

export default withRouter(Finish)
