import jwtDecode from 'jwt-decode'
import config from '../../../config'
import { currentUserActions } from '../../action-creators/current-user.js'
import { currentProfileActions } from '../../action-creators/current-profile.js'
import { actionResponseActions } from '../../action-creators/action-response.js'
import { isAuthenticatedActions } from '../../action-creators/is-authenticated.js'
import { myCoursesActions } from '../../action-creators/my-courses'
import { tokenExpActions } from '../../action-creators/token-exp.js'
import { firebaseLogin } from '../firebase/firebaseLogin.js'
import { loaderActions } from '../../action-creators/loader.js'
import { getMyAccolades } from '../accolades/getMyAccolades.js'

/*
 * METHOD TO LOGIN AND RECEIVE TOKEN
 */

export const loginWithCode = (params) => {
  let responseCode = ''
  const method = 'POST'
  const url = `${config.baseURL}/api/user/loginWithCode`

  const headers = {
    'Content-Type': 'application/json'
  }

  const requestBody = {
    'code': params.code
  }

  const requestBodyJSON = JSON.stringify(requestBody)

  return (dispatch) => {
    console.log('Sending login with code request to CivilTalk')

    dispatch(loaderActions.startLoader())

    return fetch(url, {
      method,
      body: requestBodyJSON,
      headers,
      credentials: 'include'
    })
      .then((response) => {
        responseCode = response.status

        return response.json()
      })
      .then((data) => {
        if (responseCode === 200 || responseCode === 201) {
          // dispatching non-thunk actions here
          dispatch(currentUserActions.setUser(data.user))
          dispatch(currentProfileActions.setProfile(data.profile))
          dispatch(isAuthenticatedActions.setAuthentication(true))
          dispatch(tokenExpActions.set(jwtDecode(data.access_token).exp))
          dispatch(actionResponseActions.createActionResponse(data.message))
          dispatch(myCoursesActions.setCourses(data.profile.courses))

          // SUCCESS: passing userId and Firebase token to next block
          return {
            error: false,
            code: responseCode,
            userId: data.user.id,
            firebase_token: data.firebase_token
          }
        } else {
          // Passing error state to next block
          return {
            error: true,
            code: responseCode,
            message: data.message
          }
        }
      })
      .then((data) => {
        if (data.error) {
          dispatch(loaderActions.stopLoader())

          return {
            error: true,
            code: responseCode,
            message: 'Something went wrong during sign-up or login'
          }
        }

        // if this fails keep going
        dispatch(getMyAccolades({ userId: data.userId })).then((data) => {
          if (data.error) {
            if (data.error) {
              throw new Error(`ERROR: code:${data.code}: ${data.message}`)
            }
          }
        })

        if (data.firebase_token) {
          // if this fails keep going
          dispatch(firebaseLogin({ firebase_token: data.firebase_token })).then((data) => {
            if (data.error) {
              throw new Error('[ERROR]: No firebase token found in response.')
            }
          })

          // Disable loader now that we are done with login
          dispatch(loaderActions.stopLoader())

          return {
            error: false,
            code: responseCode,
            message: 'Successfully completed sign-up/login'
          }
        }
      })
      .catch((error) => {
        console.log(error)
        dispatch(loaderActions.stopLoader())

        return {
          error: true,
          code: responseCode,
          message: error
        }
      })
  }
}
