import { firebaseAuthActions } from '../../action-creators/firebase_auth.js'

export const firebaseLogout = () => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase()

    firebase
      .auth()
      .signOut()
      .then(data => {
        console.log('[SUCCESS], Signed out of Firebase')

        dispatch(firebaseAuthActions.logOut(false))
      })
      .catch(err => {
        console.log('[ERROR], Something went wrong with Firebase Logout')
        
        dispatch(firebaseAuthActions.error(err))
      })
  }
}
