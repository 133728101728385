//////////////////
// ACTION TYPES //
//////////////////

export const suggestedOrganizationActionTypes = {
  SET_SUGGESTED_ORGANIZATIONS: 'SET_SUGGESTED_ORGANIZATIONS',
  ADD_SUGGESTED_ORGANIZATION_FOLLOWER: 'ADD_SUGGESTED_ORGANIZATION_FOLLOWER',
  REMOVE_SUGGESTED_ORGANIZATION_FOLLOWER: 'REMOVE_SUGGESTED_ORGANIZATION_FOLLOWER',
  CLEAR_SUGGESTED_ORGANIZATION: 'CLEAR_SUGGESTED_ORGANIZATION'
}

/////////////
// ACTIONS //
/////////////

// SUGGESTED ORGANIZATIONS
const set = (organizations) => {

  return {
    type: suggestedOrganizationActionTypes.SET_SUGGESTED_ORGANIZATIONS,
    organizations
  }
}

const addFollower = (organizationId) => {
  return {
    type: suggestedOrganizationActionTypes.ADD_SUGGESTED_ORGANIZATION_FOLLOWER,
    organizationId
  }
}

const removeFollower = (organizationId) => {
  return {
    type: suggestedOrganizationActionTypes.REMOVE_SUGGESTED_ORGANIZATION_FOLLOWER,
    organizationId
  }
}

const clear = () => {
  return {
    type: suggestedOrganizationActionTypes.CLEAR_SUGGESTED_ORGANIZATION
  }
}

export const suggestedOrganizationsActions = {
  set,
  addFollower,
  removeFollower,
  clear
}
