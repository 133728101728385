import Badge from './badge'
import { BadgeListContainer } from '../styled/badges-comps'

function BadgeList(props) {
  const { badges, userId } = props

  return (
    <BadgeListContainer>
      {badges.map((badge) => (
        <Badge key={badge._id} badge={badge} userId={userId} />
      ))}
    </BadgeListContainer>
  )
}

export default BadgeList
