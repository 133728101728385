//////////////////
// ACTION TYPES //
//////////////////

export const loadedTakeActionTypes = {
  SET_LOADED_TAKES: 'SET_LOADED_TAKES',
  ADD_LOADED_TAKES: 'ADD_LOADED_TAKES',
  ADD_LOADED_TAKE_VOTE: 'ADD_LOADED_TAKE_VOTE',
  REMOVE_LOADED_TAKE_VOTE: 'REMOVE_LOADED_TAKE_VOTE',
  HIDE_LOADED_TAKE: 'HIDE_LOADED_TAKE',
  SHOW_LOADED_TAKE: 'SHOW_LOADED_TAKE',
  EDIT_LOADED_TAKE: 'EDIT_LOADED_TAKE',
  UPDATE_LOADED_TAKE_VIEW_COUNT: 'UPDATE_LOADED_TAKE_VIEW_COUNT',
  ARCHIVE_LOADED_TAKE: 'ARCHIVE_LOADED_TAKE',
  CLEAR_LOADED_TAKES: 'CLEAR_LOADED_TAKES'
}

/////////////
// ACTIONS //
/////////////

// LOADED TAKES
const setTakes = (takes, sortBy) => {
  return {
    type: loadedTakeActionTypes.SET_LOADED_TAKES,
    takes,
    sortBy
  }
}

const addTakes = (takes, sortBy) => {
  return {
    type: loadedTakeActionTypes.ADD_LOADED_TAKES,
    takes,
    sortBy
  }
}

const addVote = (vote) => {
  return {
    type: loadedTakeActionTypes.ADD_LOADED_TAKE_VOTE,
    vote
  }
}

const removeVote = (voteId) => {
  return {
    type: loadedTakeActionTypes.REMOVE_LOADED_TAKE_VOTE,
    voteId
  }
}

const hideTake = (takeId) => {
  return {
    type: loadedTakeActionTypes.HIDE_LOADED_TAKE,
    takeId
  }
}

const showTake = (takeId) => {
  return {
    type: loadedTakeActionTypes.SHOW_LOADED_TAKE,
    takeId
  }
}

const editTake = (params) => {
  return {
    type: loadedTakeActionTypes.EDIT_LOADED_TAKE,
    takeId: params.takeId,
    take: params.take,
    image: params.image,
    tagIds: params.tagIds,
    emotion: params.emotion,
    location: params.location,
    visibleTo: params.visibleTo,
    courseId: params.courseId
  }
}

const updateViewCount = (params) => {
  return {
    type: loadedTakeActionTypes.UPDATE_LOADED_TAKE_VIEW_COUNT,
    takeId: params.takeId
  }
}

const archiveTake = (takeId) => {
  return {
    type: loadedTakeActionTypes.ARCHIVE_LOADED_TAKE,
    takeId
  }
}

const clearTakes = () => {
  return {
    type: loadedTakeActionTypes.CLEAR_LOADED_TAKES
  }
}

export const loadedTakesActions = {
  setTakes,
  addTakes,
  addVote,
  removeVote,
  hideTake,
  showTake,
  editTake,
  updateViewCount,
  archiveTake,
  clearTakes
}
