import styled from 'styled-components'
import config from '../../../../../../config'

export const MembersContainer = styled.div`
  background-color: #eaeaea;
  display: block;
  color: ${(props) => props.theme.palette.text.primary};
  line-height: ${(props) => props.theme.typography.lineHeight};
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.tall};
  font-weight: ${(props) => props.theme.typography.fontWeight.semiBold};
  color: ${(props) => props.theme.palette.text.primary};
  margin-top: 0.5rem;
`
