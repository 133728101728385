import { tagResultActionTypes } from "../action-creators/tag-results.js"

const copyTags = (tags) => {
  return tags.map((tag) => ({ ...tag }))
}

// FOR TAG RESULTS
const tagResultsReducer = (state = [], action) => {
  switch (action.type) {
    case tagResultActionTypes.SET_TAG_RESULTS:
      return copyTags(action.tags)

    case tagResultActionTypes.CLEAR_TAG_RESULTS:
      return []
      
    default:
      return state
  }
}

export default tagResultsReducer
