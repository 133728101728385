import React, { useState, useEffect } from 'react'
import { connect, useSelector } from 'react-redux'
import _ from 'lodash'
import { getAccoladesByUserID } from '../../../redux/thunks/accolades/getAccoladesByUserID'
import SkeletonAccolades from '../../skeletons/skeleton-accolades'
import FoundingMemberBadge from '../../../media/accolades/founding-member.png'
import EarlyMemberBadge from '../../../media/accolades/early-member.png'
import UConnCivilTalkCertifiedBadge from '../../../media/accolades/uconn-civiltalk-certified.png'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import Tooltip from '@mui/material/Tooltip'
import Zoom from '@mui/material/Zoom'
import { MainContainer } from './styled/user-accolades-comps'

const getAccoladeMeta = (accolade) => {
  const meta = {
    img: '',
    desc: accolade.desc
  }

  switch (accolade.type) {
    case 'founding-member':
      meta.img = FoundingMemberBadge
      break

    case 'early-member':
      meta.img = EarlyMemberBadge
      break

    case 'uconn-civiltalk-certified':
      meta.img = `https://res.cloudinary.com/headliner/image/upload/v1682643285/badges/uconn-civiltalk-certified.png`
      break

    default:
      break
  }

  return meta
}

//////////////////
// CUSTOM TOOLTIP
//////////////////

const useStyles = makeStyles((theme) =>
  createStyles({
    arrow: {
      color: theme.palette.primary.main
    },
    tooltip: {
      backgroundColor: theme.palette.primary.main,
      padding: '.4rem 1rem',
      color: theme.palette.text.primary,
      fontSize: '1rem',
      fontWeight: 400,
      marginBottom: '1rem'
    }
  })
)

function CustomTooltip(props) {
  const classes = useStyles()

  return <Tooltip arrow TransitionComponent={Zoom} classes={classes} {...props} />
}

//////////////////
// USER ACCOLADES
//////////////////

const UserAccolades = (props) => {
  const { userId, style, getAccoladesByUserID, profile } = props
  const [isLoading, setisLoading] = useState(true)
  const accolades = useSelector((state) => state.accolades)
  const [userAccolades, setUserAccolades] = useState([])

  // added getAccoladesByUserID, userId to deps array
  useEffect(() => {
    getAccoladesByUserID({ userId }).then((data) => {
      if (!data.error) {
        console.log('[SUCCESS]: ', 'Successfully retrieved accolades by user ID!')
      } else {
        console.log('[FAIL]: ', 'Failed to retrieve accolades by user ID.')
      }

      setisLoading(false)
    })
  }, [getAccoladesByUserID, userId])

  // added userId to deps array
  useEffect(() => {
    const filteredAccolades = _.filter(accolades, (accolade) => accolade.userId === userId)

    setUserAccolades(filteredAccolades)
  }, [accolades, userId])

  const hasAccolades = userAccolades.length > 0

  const hasProfessionalExperience =
    profile.professionalExperience.includes('University of Connecticut') ||
    profile.professionalExperience.includes('UConn') ||
    profile.professionalExperience.includes('UCONN') ||
    profile.professionalExperience.includes('Uconn') ||
    profile.professionalExperience.includes('uconn')

  const hasEducationalExperience =
    profile.educationalExperience.includes('University of Connecticut') ||
    profile.educationalExperience.includes('UConn') ||
    profile.educationalExperience.includes('UCONN') ||
    profile.educationalExperience.includes('Uconn') ||
    profile.educationalExperience.includes('uconn')

  const displayHuskyBadge = hasProfessionalExperience || hasEducationalExperience

  const displayInstructorBadge =
    profile.userId === '636bd97a13ce1d0017c1a051' ||
    profile.userId === '6320958cf2b6c4001755c617' ||
    profile.userId === '63dbd4787f7341002b4b7c54'

  if (isLoading) {
    return <SkeletonAccolades style={style || {}} />
  }

  if (
    !hasAccolades &&
    !hasEducationalExperience &&
    !hasProfessionalExperience &&
    !displayInstructorBadge
  )
    return null

  return (
    <div className="user-accolades" style={style || {}}>
      <MainContainer>
        <>
          {hasAccolades &&
            userAccolades.map((accolade) => {
              const accoladeMeta = getAccoladeMeta(accolade)

              return (
                <CustomTooltip title={accolade.desc} placement="top" key={accolade._id}>
                  <img
                    src={accoladeMeta.img}
                    alt={accolade.desc}
                    style={
                      accolade.type === 'uconn-civiltalk-certified'
                        ? { width: '3rem', height: '3rem' }
                        : {}
                    }
                  />
                </CustomTooltip>
              )
            })}
        </>

        {displayHuskyBadge && (
          <>
            <CustomTooltip title={'UConn Member'} placement="top" key={999}>
              <img
                src={
                  'https://res.cloudinary.com/headliner/image/upload/v1673995817/badges/husky-dog-logo-circleR.jpg'
                }
                alt={'UCONN HUSKIE'}
                style={{ borderRadius: '50%' }}
              />
            </CustomTooltip>
          </>
        )}

        {displayInstructorBadge && (
          <>
            <CustomTooltip title={'Instructor'} placement="top" key={9999}>
              <img
                src={
                  'https://res.cloudinary.com/headliner/image/upload/v1676325117/badges/instructor_badge.png'
                }
                alt={'INSTRUCTOR BADGE'}
                style={{
                  borderRadius: '50%'
                }}
              />
            </CustomTooltip>
          </>
        )}
      </MainContainer>
    </div>
  )
}

const mapStateToProps = (state) => ({
  accolades: state.accolades
})

const mapDispatchToProps = (dispatch) => ({
  getAccoladesByUserID: (params) => {
    return dispatch(getAccoladesByUserID(params))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(UserAccolades)
