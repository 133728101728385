import Validator from 'validator'

const CreateReportValidation = state => {
  if (state.offenseType === '') {
    return {
      error: 'Report offense type is required'
    }
  }

  if (!Validator.isLength(state.desc, { min: 1, max: 250 })) {
    return {
      error: 'Report description must be between 1-250 characters long'
    }
  }

  return {
    error: ''
  }
}

export default CreateReportValidation
