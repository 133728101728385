import { getFollowsByFollowingIDs } from '../../../../redux/thunks/follows/getFollowsByFollowingIDs.js'

const getOrganizationFollows = (organizationIds, setIsLoading, dispatch) => {
  if (!organizationIds) {
    throw new Error('Invalid params <organizationIds> in getOrganizationFollows')
  }

  if (!setIsLoading) {
    throw new Error('Invalid params <setIsLoading> in getOrganizationFollows')
  }

  if (!dispatch) {
    throw new Error('Invalid params <dispatch> in getOrganizationFollows')
  }

  dispatch(getFollowsByFollowingIDs({ organizationIds }))
    .then((data) => {
      if (!data.error) {
        console.log('[SUCCESS]: ', 'Successfully retrieved follows by following IDs!')
      } else {
        console.log('[FAIL]: ', 'Failed to retrieve follows by following IDs.')
      }

      setIsLoading(false)
    })
    .catch((e) => {
      setIsLoading(false)
    })
}

export default getOrganizationFollows
