import styled from 'styled-components'
import config from '../../../../../../../../config'

/**
 * Badges
 */

/* Note: These styles overlap with Profile -> Details styles */
export const ArticleContainer = styled.article``

export const GreenArticleHeader = styled.h4`
  background: ${(props) => props.theme.palette.secondary.light};
  line-height: ${(props) => props.theme.typography.lineHeight};
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.small};
  font-weight: ${(props) => props.theme.typography.fontWeight.semiBold};
  color: ${(props) => props.theme.palette.text.primary};
  width: 10rem;
  padding: 0.5rem 1rem;
  margin-top: 1rem;
  display: block;
  border-radius: 0.3rem;
  text-align: center;
`

export const WhiteBackgroundContainer = styled.div`
  background-color: ${(props) => props.theme.palette.background.lightGray};
  padding: 1rem;
  box-sizing: border-box;
  border-radius: 0.3rem;
  margin-top: 1rem;

  @media (max-width: ${config.UI.drawer_breakpoint.max}) {
    padding: 0.7rem;
  }
`
