import React from 'react'
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createPrompt } from '../../../../../../../redux/thunks/prompts/createPrompt'
import UserAvatar from '../../../../../../partials/user-avatar/user-avatar'
import PromptForm from '../../../../../../partials/prompt-form/prompt-form'
import isVerifiedUser from '../../../../../../../utils/functions/isVerifiedUser'
import useMediaQuery from '@mui/material/useMediaQuery'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import CloseIcon from '@mui/icons-material/Close'
import makeStyles from '@mui/styles/makeStyles'
import {
  MainContainer,
  UserAvatarContainer,
  ActionContainer,
  PostFormContainer
} from './styled/start-conversation-comps'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'inline-block',
    minHeight: '3rem',
    width: '18rem',
    verticalAlign: 'middle',
    color: theme.palette.text.primary,
    fontSize: '1rem',
    fontWeight: theme.typography.fontWeightBold,
    // textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.light
    },
    '& .material-icons': {
      display: 'inline-block',
      verticalAlign: 'middle',
      lineHeight: '1rem',
      margin: '.2rem .4rem 0 0',
      fontSize: '1rem'
    },
    '& .start-conversation-text': {
      display: 'inline-block',
      verticalAlign: 'middle'
    },
    // '& .start-conversation-text-inner': {
    //   display: 'inline-block',
    //   verticalAlign: 'middle',
    //   textTransform: 'lowercase'
    // },
    '@media (max-width: 599px)': {
      width: '100%'
    }
  },
  closeIcon: {
    position: 'absolute',
    top: '0.35rem',
    right: '0.5rem'
  }
}))

function StartConversation({ course, prompts, setPrompts }) {
  const dispatch = useDispatch()
  const classes = useStyles()
  const isMobile = useMediaQuery('(max-width: 799px)')
  const isSmallScreen = useMediaQuery('(max-width: 399px)')
  const [open, setOpen] = useState(false)
  const currentProfile = useSelector(({ currentProfile }) => currentProfile)
  const { avatar, documentVerified } = currentProfile
  const verified = documentVerified ? 1 : 0
  const isInstructor = course.instructorId === currentProfile.userId

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleCreatePrompt = (newPrompt) => {
    dispatch(createPrompt())
  }

  useEffect(() => {}, [])

  if (!isInstructor) return null

  return (
    <>
      <MainContainer>
        <ActionContainer>
          <UserAvatarContainer>
            {isSmallScreen ? (
              <UserAvatar
                src={avatar}
                width={'2.5rem'}
                height={'2.5rem'}
                borderWidth={'0.2rem'}
                verified={verified}
              />
            ) : (
              <UserAvatar
                src={avatar}
                width={'2.75rem'}
                height={'2.75rem'}
                borderWidth={'0.2rem'}
                verified={verified}
              />
            )}
          </UserAvatarContainer>

          <Button
            onClick={handleOpen}
            variant="contained"
            className={classes.root}
            disabled={!isVerifiedUser(currentProfile)}>
            {!isVerifiedUser(currentProfile) ? (
              <span className="material-icons">lock</span>
            ) : (
              <span className="material-icons">add</span>
            )}

            <span className="start-conversation-text">Create a Prompt</span>
          </Button>
        </ActionContainer>
      </MainContainer>

      <Dialog open={open} onClose={handleClose} maxWidth={'xl'} fullScreen={isMobile}>
        <PostFormContainer>
          {isMobile && <CloseIcon onClick={handleClose} className={classes.closeIcon} />}
          <PromptForm
            setOpen={setOpen}
            courseId={course._id}
            prompts={prompts}
            setPrompts={setPrompts}
          />
        </PostFormContainer>
      </Dialog>
    </>
  )
}

export default StartConversation
