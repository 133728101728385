//////////////////
// ACTION TYPES //
//////////////////

export const mobileDrawerActionTypes = {
  SET_MOBILE_DRAWER: 'SET_MOBILE_DRAWER',
  CLEAR_MOBILE_DRAWER: 'CLEAR_MOBILE_DRAWER'
}

/////////////
// ACTIONS //
/////////////

// MOBILE DRAWER
const set = open => {
  return {
    type: mobileDrawerActionTypes.SET_MOBILE_DRAWER,
    open
  }
}

const clear = () => {
  return {
    type: mobileDrawerActionTypes.CLEAR_MOBILE_DRAWER
  }
}

export const mobileDrawerActions = {
  set,
  clear
}
