import styled from 'styled-components'
import config from '../../../../../../config'

export const Header = styled.h5`
  color: ${(props) => props.theme.typography.fontColor.bodyCopy};
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-weight: ${(props) => props.theme.typography.fontWeight.bold};
  font-size: ${(props) => props.theme.typography.fontSizes.tall};
  margin-bottom: 1rem;

  @media (max-width: ${config.UI.drawer_breakpoint.max}) {
    margin-top: 1.5rem;
    padding: 0 1rem;
    text-align: center;
    font-size: ${(props) => props.theme.typography.fontSizes.extraLarge};
  }
`

export const SubHeader = styled.h6`
  color: ${(props) => props.theme.typography.fontColor.bodyCopy};
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  font-size: ${(props) => props.theme.typography.fontSizes.regular};
  margin-bottom: 1rem;

  @media (max-width: ${config.UI.drawer_breakpoint.max}) {
    text-align: center;
  }
`

export const Form = styled.form`
  position: relative;
  padding-bottom: 4rem;

  @media (max-width: ${config.UI.drawer_breakpoint.max}) {
    margin: 0 auto;
  }
`

// export const SectionContainer = styled.div`
//   display: inline-block;
//   /* Firefox */
//   width: -moz-calc(100% - 23.5rem);
//   /* WebKit */
//   width: -webkit-calc(100% - 23.5rem);
//   /* Opera */
//   width: -o-calc(100% - 23.5rem);
//   /* Standard */
//   width: calc(100% - 23.5rem);
//   vertical-align: top;
//   border-radius: 0.3rem;

//   @media (max-width: 1200px) {
//     width: calc(100% - 13.5rem);
//   }

//   @media (max-width: 1120px) {
//     width: 100%;
//   }

//   @media (max-width: ${config.UI.drawer_breakpoint.max}) {
//     display: inline-block;
//     box-sizing: border-box;
//     margin-left: 5%;
//   }

//   @media (max-width: 600px) {
//     margin-left: unset;
//   }
// `

export const SectionContainer = styled.div``

export const ItemContainer = styled.div`
  display: block;
  font-size: 0;
  padding: 0.5rem 0;
`

export const InputLabel = styled.label`
  display: inline-block;
  width: 25%;
  padding-right: 1.5rem;
  box-sizing: border-box;
  margin-top: 0.65rem;
  vertical-align: top;
  line-height: ${(props) => props.theme.typography.lineHeight};
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.regular};
  font-weight: ${(props) => props.theme.typography.fontWeight.semiBold};
  color: ${(props) => props.theme.typography.fontColor.bodyCopy};

  @media (max-width: ${config.UI.drawer_breakpoint.max}) {
    display: block;
    width: 100%;
    margin-top: 0;
  }
`

export const InputsContainer = styled.div`
  display: inline-block;
  // width: ${({ width }) => (width ? width : '65%')};
  width: 75%;
  vertical-align: top;
  padding-bottom: 0.5rem;

  @media (max-width: ${config.UI.drawer_breakpoint.max}) {
    display: block;
    width: 100%;
    margin-top: 0.7rem;
  }
`

export const Input = styled.input`
  background-color: ${({ disabled }) =>
    disabled ? 'rgba(150, 150, 150, .1)' : 'rgba(0, 0, 0, 0)'};
  padding: 0.6rem;
  width: ${(props) => (props.width ? props.width : '10.65rem')};
  margin-top: ${({ marginTop }) => marginTop || '0'};
  vertical-align: middle;
  border: 1px solid #ddd;
  border-radius: 0.2rem;
  box-sizing: border-box;
  box-shadow: 0px 9px 7px -2px rgba(120, 120, 120, 0.1);
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.regular};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  color: ${({ theme, disabled }) => (disabled ? '#777' : theme.typography.fontColor.bodyCopy)};

  // Mike - I used fixed width to match the Mui-Select - would rather have used width
  @media (max-width: ${config.UI.drawer_breakpoint.max}) {
    display: block;
    width: 100%;
    margin-top: 0.7rem;
  }
`

export const FinePrint = styled.p`
  color: ${(props) => props.theme.typography.fontColor.bodyCopy};
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  font-size: ${(props) => props.theme.typography.fontSizes.small};
  margin: 1rem 0.5rem;

  @media (max-width: ${config.UI.drawer_breakpoint.max}) {
    text-align: center;
  }
`

export const AddressLabel = styled.label`
  display: inline-block;
  width: 25%;
  padding-right: 1.5rem;
  box-sizing: border-box;
  ${'' /* margin-top: 0.65rem; */}
  vertical-align: top;
  line-height: ${(props) => props.theme.typography.lineHeight};
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.regular};
  font-weight: ${(props) => props.theme.typography.fontWeight.semiBold};
  color: ${(props) => props.theme.typography.fontColor.bodyCopy};
  word-break: normal;

  @media (max-width: ${config.UI.drawer_breakpoint.max}) {
    width: 100%;
  }
`

export const AddressCopy = styled.span`
  display: inline-block;
  width: 75%;
  vertical-align: top;
  padding-bottom: 0.5rem;
  box-sizing: border-box;
  ${'' /* margin-top: 0.7rem; */}
  line-height: ${(props) => props.theme.typography.lineHeight};
  color: ${(props) => props.theme.typography.fontColor.bodyCopy};
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.regular};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};

  @media (max-width: ${config.UI.drawer_breakpoint.max}) {
    width: 100%;
    margin-top: 0.4rem;
  }
`

export const ButtonContainer = styled.div`
  text-align: right;

  @media (max-width: 400px) {
    text-align: center;

    & > button:nth-child(1) {
      display: block;
      width: 100%;
      margin: 1rem auto;
    }

    & > button:nth-child(2) {
      display: block;
      width: 100%;
      margin: 0 auto;
    }
  }
`
