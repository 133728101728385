import { createOrganizationFollow } from '../../../../../../redux/thunks/follows/createOrganizationFollow.js'

const followOrganization = (organization, setProcessing, dispatch) => {
  if (!organization) {
    throw new Error('Invalid params <organization> in followOrganization')
  }

  if (!setProcessing) {
    throw new Error('Invalid params <setProcessing> in followOrganization')
  }

  if (!dispatch) {
    throw new Error('Invalid params <dispatch> in followOrganization')
  }
  setProcessing(true)

  dispatch(
    createOrganizationFollow({
      organizationId: organization._id,
      name: organization.publicName
    })
  )
    .then((data) => {
      if (!data.error) {
        console.log('[SUCCESS]: ', 'Successfully followed organization!')
      } else {
        console.log('[FAIL]: ', 'Failed to follow organization.')
      }

      setProcessing(false)
    })
    .catch((e) => {
      setProcessing(false)
    })
}

export default followOrganization
