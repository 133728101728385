import React, { forwardRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import { markRebuttalAsRead } from '../../../../redux/thunks/rebuttals/markRebuttalAsRead'
import { history } from '../../../../history/history'
import getAvatar from '../../../../utils/functions/getAvatar'
import getName from '../../../../utils/functions/getName'
import truncateString from '../../../../utils/functions/truncateString'
import MuiTableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Skeleton from '@mui/material/Skeleton'
import { useTheme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import useMediaQuery from '@mui/material/useMediaQuery'
import {
  MobileNameContainer,
  Thumbnail,
  Name,
  SubjectContainer,
  SubjectInnerContainer,
  // Subject,
  // SubjectCopy,
  MobilePreview,
  Preview,
  TimeStamp
} from './styled/discussion-thumbnail-comps'

const TableRow = withStyles((theme) => ({
  root: {
    display: 'flex',
    verticalAlign: 'inherit',
    position: 'relative',
    cursor: 'pointer',
    padding: '0.25rem 0',
    '&:first-child': {
      paddingTop: 0
    },
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      padding: 0
    }
  }
}))(MuiTableRow)

const useStyles = makeStyles((theme) =>
  createStyles({
    tableRow: {
      display: 'flex',
      verticalAlign: 'inherit',
      position: 'relative',
      cursor: 'pointer',
      padding: '0.25rem 0',
      '&:first-child': {
        paddingTop: 0
      },
      [theme.breakpoints.down('md')]: {
        display: 'flex',
        padding: 0
      }
    },
    tableCell: {
      flex: '0 0 auto',
      whiteSpace: 'nowrap',
      padding: 0,
      alignItems: 'center',
      lineHeight: '1.4rem',
      borderBottom: 'none',
      borderTop: '1.5px solid rgba(70, 70, 70, 0.5)',
      [theme.breakpoints.down('md')]: {
        display: 'flex',
        borderTop: 'none'
      }
    },
    tableCellMobileAvatar: {
      flex: '0 0 auto',
      whiteSpace: 'nowrap',
      padding: 0,
      marginLeft: '0.5rem',
      alignItems: 'start',
      lineHeight: '1.4rem',
      borderBottom: 'none',
      borderTop: '1.5px solid rgba(70, 70, 70, 0.5)',
      [theme.breakpoints.down('md')]: {
        display: 'flex'
      }
    },
    tableCellMobile: {
      flex: '1 1 auto',
      padding: '0.5rem 0',
      marginRight: '1rem',
      borderBottom: 'none',
      borderTop: '1.5px solid rgba(70, 70, 70, 0.5)'
    },
    spaceBetween: {
      justifyContent: 'space-between'
    },
    thumbnail: {
      paddingRight: '1rem',
      paddingTop: '0.5rem'
    },
    name: {
      display: 'flex',
      flex: '0 0 auto',
      flexBasis: '9.5rem',
      maxWidth: '9.5rem',
      paddingRight: '1rem',
      [theme.breakpoints.down('md')]: {}
    },
    subject: {
      display: 'flex',
      flex: '1 1 auto',
      flexWrap: 'wrap',
      height: 'auto',
      minWidth: 0,
      paddingRight: '1rem',
      [theme.breakpoints.down('md')]: {
        display: 'block'
      }
    },
    timeStamp: {
      display: 'flex',
      justifyContent: 'flex-end',
      [theme.breakpoints.down('md')]: {}
    }
  })
)

// *** Receives a ref from it's parent component (lastDiscussionEl) *** //
const DiscussionThumbnail = forwardRef(({ discussion, type }, ref) => {
  const { _id, complete, guestUserId, hostUserId, lastRebuttal, ratings, sortDate, takeId } =
    discussion
  const classes = useStyles()
  const theme = useTheme()
  const mobileBreakpoint = useMediaQuery(theme.breakpoints.down('md'))
  const dispatch = useDispatch()
  const currentUser = useSelector((state) => state.currentUser)
  const loadedProfiles = useSelector((state) => state.loadedProfiles)

  const userId = hostUserId === currentUser.id ? guestUserId : hostUserId
  const profile = loadedProfiles[userId]

  if (!profile) return null

  const { documentVerified } = profile
  const verified = documentVerified ? 1 : 0

  const parseDate = () => {
    const currentDate = new Date(Date.now())
    const midnight = currentDate.setHours(0, 0, 0, 0)
    const displayDate = sortDate
    const currentYear = currentDate.getFullYear()
    const displayDateYear = sortDate && new Date(displayDate).getFullYear()

    if (displayDate < midnight) {
      if (currentYear !== displayDateYear) {
        return lastRebuttal && moment(displayDate).format('MMM D YYYY')
      } else {
        return lastRebuttal && moment(displayDate).format('MMM D')
      }
    } else {
      return lastRebuttal && moment(displayDate).format(`${'h'}:${'mm'} ${'a'}`)
    }
  }

  const handleClickThumbnail = () => {
    const rebuttalId = lastRebuttal._id

    if (type === 'Inbox') {
      dispatch(markRebuttalAsRead({ rebuttalId }))

      history.push(`/post/${takeId}/${_id}`)
    } else {
      history.push(`/post/${takeId}/${_id}`)
    }
  }

  const isRead = type === 'Inbox' && lastRebuttal && lastRebuttal.read
  const isCompleteAndRated =
    type === 'Inbox' && complete && ratings.hasOwnProperty(currentUser.id) ? true : false
  const markAsRead = complete ? isCompleteAndRated : isRead
  const SentTo = type === 'Sent' ? `To: ` : ''

  // Todo: save for debugging
  // if (type === 'Inbox') {
  //   console.table({
  //     _id,
  //     rebuttal: lastRebuttal && lastRebuttal.rebuttal,
  //     complete,
  //     isRead,
  //     isCompleteAndRated,
  //     markAsRead,
  //     ratings
  //   })
  // }

  return (
    <>
      {mobileBreakpoint ? (
        <TableRow onClick={handleClickThumbnail} ref={ref}>
          <TableCell className={classes.tableCellMobileAvatar}>
            {profile && profile.avatar ? (
              <Thumbnail
                style={{ backgroundImage: `url(${getAvatar(profile.avatar)})` }}
                verified={verified}
              />
            ) : (
              <Thumbnail style={{ backgroundImage: `url(${getAvatar('')})` }} verified={verified} />
            )}
          </TableCell>
          <TableCell className={classes.tableCellMobile}>
            <MobileNameContainer>
              {profile ? (
                <Name read={markAsRead}>{`${SentTo} ${getName(profile)}`}</Name>
              ) : (
                <Name>
                  <Skeleton variant="text" />
                </Name>
              )}

              <TimeStamp read={markAsRead}>{parseDate()}</TimeStamp>
            </MobileNameContainer>
            <SubjectContainer>
              <SubjectInnerContainer>
                {/* <Subject>
                    <SubjectCopy>This is a subject line</SubjectCopy>
                  </Subject> */}
              </SubjectInnerContainer>
            </SubjectContainer>

            <div>
              <div>
                {lastRebuttal ? (
                  <MobilePreview read={markAsRead}>
                    {truncateString(lastRebuttal.rebuttal, 30)}
                  </MobilePreview>
                ) : (
                  <MobilePreview></MobilePreview>
                )}
              </div>
            </div>
          </TableCell>
        </TableRow>
      ) : (
        <TableRow onClick={handleClickThumbnail}>
          <TableCell className={`${classes.tableCell} ${classes.thumbnail}`}>
            {profile && profile.avatar ? (
              <Thumbnail
                style={{ backgroundImage: `url(${getAvatar(profile.avatar)})` }}
                verified={verified}
              />
            ) : (
              <Thumbnail style={{ backgroundImage: `url(${getAvatar('')})` }} verified={verified} />
            )}
          </TableCell>
          <TableCell className={`${classes.tableCell} ${classes.name}`}>
            {profile ? (
              <Name read={markAsRead}>{`${SentTo} ${getName(profile)}`}</Name>
            ) : (
              <Name>loading</Name>
            )}
          </TableCell>
          <TableCell className={`${classes.tableCell} ${classes.subject}`}>
            <SubjectContainer>
              <SubjectInnerContainer>
                {/* <Subject>
                  <SubjectCopy>{isRead.toString()}</SubjectCopy>
                </Subject> */}
                {lastRebuttal ? (
                  <Preview read={markAsRead} type={type}>
                    <p>{truncateString(lastRebuttal.rebuttal, 42)}</p>
                  </Preview>
                ) : (
                  <Preview></Preview>
                )}
              </SubjectInnerContainer>
            </SubjectContainer>
          </TableCell>
          <TableCell className={`${classes.tableCell} ${classes.timeStamp}`}>
            <TimeStamp read={markAsRead}>{parseDate()}</TimeStamp>
          </TableCell>
        </TableRow>
      )}
    </>
  )
})

export default DiscussionThumbnail
