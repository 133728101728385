//////////////////
// ACTION TYPES //
//////////////////

export const loadedTopicActionTypes = {
	SET_LOADED_TOPICS: 					'SET_LOADED_TOPICS',
	ADD_LOADED_TOPICS: 					'ADD_LOADED_TOPICS',
  	CLEAR_LOADED_TOPICS: 				'CLEAR_LOADED_TOPICS'
}

/////////////
// ACTIONS //
/////////////

// LOADED TOPICS
const setTopics = topics => {
	return { 
		type: loadedTopicActionTypes.SET_LOADED_TOPICS, 
		topics
	}
}

const addTopics = topics => {
	return { 
		type: loadedTopicActionTypes.ADD_LOADED_TOPICS, 
		topics 
	}
}

const clearTopics = () => {
  	return { 
    	type: loadedTopicActionTypes.CLEAR_LOADED_TOPICS
  	}
}

export const loadedTopicsActions = {
	setTopics,
	addTopics,
	clearTopics
}