import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import withStyles from '@mui/styles/withStyles'
import { Skeleton } from '@mui/material'

const animation = 'pulse'

const wrapperStyles = {
  backgroundColor: 'rgba(255, 255, 255, 0.2)',
  width: '100%',
  marginBottom: '1.5rem',
  padding: '1.5rem',
  borderRadius: '.3rem',
  boxSizing: 'border-box'
}

const styles = (theme) => ({
  profilePic: {
    display: 'block',
    float: 'left',
    marginRight: '1rem',
    backgroundColor: theme.palette.primary.light
  },
  subheader: {
    marginTop: '.4rem',
    width: '50%'
  },
  body: {
    marginTop: '1rem',
    borderRadius: '.3rem'
  }
})

class SkeletonTake extends Component {
  render() {
    const { classes, count, style, bodyHeight, color } = this.props
    const styleOverrides = style ? style : {}
    const newBodyHeight = bodyHeight ? bodyHeight : 250
    const backgroundColor = color ? color : 'rgba(0, 0, 0, 0.04)'
    const numSkeletons = count ? count : 1
    let skeletons = []

    for (var i = 0; i < numSkeletons; i++) {
      skeletons.push(
        <div key={i} style={{ ...wrapperStyles, ...styleOverrides }}>
          <div
            style={{
              // backgroundColor: 'white',
              // padding: '1.5rem',
              minHeight: '2.5rem',
              boxSizing: 'border-box'
            }}>
            <Skeleton
              animation={animation}
              variant="circular"
              width={'2.5rem'}
              height={'2.5rem'}
              className={classes.profilePic}
              style={{ backgroundColor }}
            />
            <div style={{ overflow: 'hidden' }}>
              <Skeleton
                animation={animation}
                variant="text"
                width={'100%'}
                height={'1rem'}
                style={{ backgroundColor }}
              />
              <Skeleton
                animation={animation}
                variant="text"
                height={'1rem'}
                className={classes.subheader}
                style={{ backgroundColor }}
              />
            </div>
          </div>

          <Skeleton
            animation={animation}
            variant="rectangular"
            height={newBodyHeight}
            className={classes.body}
            style={{ backgroundColor }}
          />
        </div>
      )
    }

    return skeletons
  }
}

SkeletonTake.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withRouter(withStyles(styles)(SkeletonTake))
