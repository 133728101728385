function validateTitle(title, existingTitles = []) {
  const errors = {}

  // Trim spaces
  const trimmedTitle = title.trim()

  // Required field
  if (!trimmedTitle) {
    errors.required = 'Title is required.'
  }

  // Length constraints
  if (trimmedTitle.length < 2) {
    errors.minLength = 'Title must be at least 2 characters.'
  }
  if (trimmedTitle.length > 100) {
    errors.maxLength = 'Title must be less than 100 characters.'
  }

  // Character restrictions
  if (!/^[a-zA-Z0-9\s\.,!?-]*$/.test(trimmedTitle)) {
    errors.invalidChars = 'Title contains invalid characters.'
  }

  // Uniqueness
  // if (existingTitles.includes(trimmedTitle.toLowerCase())) {
  //   // Assuming case-insensitive uniqueness
  //   errors.notUnique = 'Title must be unique.'
  // }

  // Profanity filtering (simple example, consider using a library for robust checks)
  // const profanityList = ['badword1', 'badword2'] // Example list
  // if (profanityList.some((profanity) => trimmedTitle.toLowerCase().includes(profanity))) {
  //   errors.profanity = 'Title contains inappropriate language.'
  // }

  // HTML/Script injection prevention is typically handled during rendering/output, not validation

  return errors
}

export default validateTitle
