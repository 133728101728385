//////////////////
// ACTION TYPES //
//////////////////

export const testimonialActionTypes = {
    SET_TESTIMONIALS: "SET_TESTIMONIALS",
    CLEAR_TESTIMONIALS: "CLEAR_TESTIMONIALS"
  }
  
  /////////////
  // ACTIONS //
  /////////////
  
  // TESTIMONIALS
  export const set = testimonials => {
    return {
      type: testimonialActionTypes.SET_TESTIMONIALS,
      testimonials
    }
  }
  
  export const clear = () => {
    return {
      type: testimonialActionTypes.CLEAR_TESTIMONIALS
    }
  }
  
  export const testimonialsActions = {
    set,
    clear
  }
  