import styled from 'styled-components'
import theme from '../../../../res/theme.js'
import config from '../../../../config.js'
// import background from '../../../../../media/background_images/homepage-main-1.jpg'

export const MainContainer = styled.div`
  max-width: 70rem;
  padding: 0 1rem;
  margin: 1.5rem auto 4rem auto;
  box-sizing: border-box;
`

export const SubscribeContainer = styled.div`
  background-color: #f8f8f8;
  margin: 0 auto;
  padding: 1.5rem 2.5rem 2.5rem 2.5rem;
  box-sizing: border-box;
  border-radius: 0.2rem;
  box-shadow: 0px 7px 5px -2px rgba(100, 100, 100, 0.1);

  @media (max-width: 600px) {
    padding: 2.5rem 1.5rem;
  }
`

export const Header = styled.p`
  margin-top: 0.5rem;
  text-align: center;
  font-family: ${theme.typography.fontFamily};
  font-size: 2.2rem;
  font-weight: ${theme.typography.fontWeight.medium};
  color: ${theme.typography.fontColor.bodyCopy};

  @media (max-width: ${config.UI.drawer_breakpoint.max}) {
    font-size: 1.6rem;
  }
`

export const Subheader = styled.p`
  max-width: 43rem;
  margin: 1.5rem auto 0 auto;
  text-align: center;
  // line-height: ${theme.typography.lineHeight};
  font-family: ${theme.typography.fontFamily};
  font-size: ${theme.typography.fontSizes.regular};
  font-weight: ${theme.typography.fontWeight.regular};
  color: ${theme.typography.fontColor.bodyCopy};

  & a:active,
  & a:visited,
  & a:link {
    text-decoration: none;
    color: ${theme.palette.link.primary};
    font-weight: ${theme.typography.fontWeight.semiBold};
  }
  & a:hover {
    text-decoration: underline;
    color: ${theme.palette.link.primary};
    font-weight: ${theme.typography.fontWeight.semiBold};
  }
`

export const ButtonsContainer = styled.div`
  margin-top: 2rem;
  text-align: center;
`
