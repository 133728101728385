import React, { useState, Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import moment from 'moment'
import { history } from '../../../../../../../../history/history'
import { removePaymentMethod } from '../../../../../../../../redux/thunks/users/removePaymentMethod'
import { updateDefaultPaymentMethod } from '../../../../../../../../redux/thunks/users/updateDefaultPaymentMethod'
import ConfirmationDialog from '../../../../../../../dialogs/dialog-confirmation'
import FormError from '../../../../../../../alerts/form-error'
import withStyles from '@mui/styles/withStyles'
import PropTypes from 'prop-types'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  Tooltip,
  Checkbox
} from '@mui/material'
import { Delete } from '@mui/icons-material'
import theme from '../../../../../../../../res/theme'

/////////////////////
// UTILITY FUNCTIONS
/////////////////////

// Function to determine sort function
function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }

  if (b[orderBy] > a[orderBy]) {
    return 1
  }

  return 0
}

// Function to sort data by 'getSorting' Function
function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index])

  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0])

    if (order !== 0) {
      return order
    }

    return a[1] - b[1]
  })

  return stabilizedThis.map((el) => el[0])
}

// Function to get sorting function given order and data type
function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy)
}

/////////////////////
// TABLE CELLS
/////////////////////

const CustomTableCell = withStyles(() => ({
  head: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.fontSizes.short,
    fontWeight: theme.typography.fontWeight.regular,
    color: theme.typography.fontColor.bodyCopy
  },
  body: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.fontSizes.short,
    fontWeight: theme.typography.fontWeight.light
  }
}))(TableCell)

/////////////////////
// TABLE HEAD
/////////////////////

class EnhancedTableHead extends Component {
  createSortHandler = (property) => (event) => {
    this.props.onRequestSort(event, property)
  }

  render() {
    const { order, orderBy } = this.props

    const rows = [
      { id: 'brand', disablePadding: false, label: 'Brand' },
      { id: 'cardNum', disablePadding: false, label: 'Card Number' },
      { id: 'exp', disablePadding: false, label: 'Expiration' },
      { id: 'created', disablePadding: false, label: 'When Added' },
      { id: 'isDefault', disablePadding: false, label: 'Default' },
      { id: 'delete', disablePadding: false, label: '' }
    ]

    return (
      <TableHead>
        <TableRow>
          <CustomTableCell></CustomTableCell>
          {rows.map((row) => {
            return (
              <CustomTableCell
                key={row.id}
                align={row.id === 'brand' ? 'left' : 'right'}
                padding={row.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === row.id ? order : false}>
                <Tooltip title={'Sort by ' + row.label} placement="top-end" enterDelay={300}>
                  <TableSortLabel
                    active={orderBy === row.id}
                    direction={order}
                    onClick={this.createSortHandler(row.id)}>
                    {row.label}
                  </TableSortLabel>
                </Tooltip>
              </CustomTableCell>
            )
          }, this)}
        </TableRow>
      </TableHead>
    )
  }
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired
}

////////////////////////////////////////////
// PARENT COMPONENT (VERIFICATION HISTORY))
////////////////////////////////////////////

// Global styling passed as props to Topics using withStyles()
const styles = (theme) => ({
  root: {
    width: '100%',
    marginTop: '.5rem',
    overflowX: 'auto',
    // border: 'none',
    boxShadow: 'none'
  },
  table: {
    // minWidth: 1020
  },
  tableWrapper: {
    overflowX: 'auto'
  },
  row: {
    // cursor: 'pointer'
  },
  deleteIcon: {
    marginTop: '.2rem',
    color: '#cc0000',
    fontSize: '1.3rem',
    cursor: 'pointer'
  }
})

const PaymentMethods = ({
  paymentMethods,
  defaultPaymentMethod,
  updateDefaultPaymentMethod,
  removePaymentMethod,
  classes
}) => {
  const [error, setError] = useState('')
  const [paymentMethodId, setPaymentMethodId] = useState(false)
  const [defaultPaymentMethodDialog, setDefaultPaymentMethodDialog] = useState(false)
  const [deleteDialog, setDeleteDialog] = useState(false)
  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState('created')
  const [selected, setSelected] = useState([])
  // const [rowsPerPage, setRowsPerPage] = useState(10)
  // const [page, setPage] = useState(0)
  const rows = []

  const handleClickDefaultCheckbox = (id) => {
    setPaymentMethodId(id)
    setDefaultPaymentMethodDialog(true)
  }

  const handleClickDeleteIcon = (id) => {
    setPaymentMethodId(id)
    setDeleteDialog(true)
  }

  const handleUpdateDefaultPaymentMethod = () => {
    updateDefaultPaymentMethod({ paymentMethodId }).then((data) => {
      if (!data.error) {
        console.log('[SUCCESS]: ', 'Successfully updated default payment method!')

        history.push('/my-account/billing')
      } else {
        console.log('[FAIL]: ', 'Failed to update default payment method.')

        setError(data.message)
      }
    })
  }

  const handleRemovePaymentMethod = () => {
    removePaymentMethod({ paymentMethodId }).then((data) => {
      if (!data.error) {
        console.log('[SUCCESS]: ', 'Successfully removed payment method!')

        history.push('/my-account/billing')
      } else {
        console.log('[FAIL]: ', 'Failed to remove payment method.')

        setError(data.message)
      }
    })
  }

  const handleRequestSort = (event, property) => {
    const newOrderBy = property
    let newOrder = 'desc'

    if (orderBy === property && order === 'desc') {
      newOrder = 'asc'
    }

    setOrder(newOrder)
    setOrderBy(newOrderBy)
  }

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }

    setSelected(newSelected)
  }

  const createData = (brand, cardNum, exp, created, isDefault, id) => {
    return { brand, cardNum, exp, created, isDefault, id }
  }

  for (var i = 0; i < paymentMethods.length; i++) {
    rows.push(
      createData(
        paymentMethods[i].card.brand,
        '**** ' + paymentMethods[i].card.last4,
        paymentMethods[i].card.exp_month + '/' + paymentMethods[i].card.exp_year,
        paymentMethods[i].created,
        paymentMethods[i].id === defaultPaymentMethod,
        paymentMethods[i].id
      )
    )
  }

  return (
    <Paper className={classes.root}>
      <FormError message={error} style={{ marginTop: '1.5rem' }} />

      <ConfirmationDialog
        open={defaultPaymentMethodDialog}
        primaryAction={handleUpdateDefaultPaymentMethod}
        secondaryAction={() => setDefaultPaymentMethodDialog(false)}
        primaryText={'Confirm'}
        secondaryText={'Cancel'}
        title="Change Default Payment Method?"
        body={
          <div>
            <p>Please confirm that you would like us to change your default payment method.</p>
          </div>
        }
      />

      <ConfirmationDialog
        open={deleteDialog}
        primaryAction={handleRemovePaymentMethod}
        secondaryAction={() => setDeleteDialog(false)}
        primaryText={'Confirm'}
        secondaryText={'Cancel'}
        title="Remove Payment Method?"
        body={
          <div>
            <p>Please confirm that you would like us to remove this payment method.</p>
          </div>
        }
      />

      <div className={classes.tableWrapper}>
        <Table className={classes.table} aria-labelledby="tableTitle">
          <EnhancedTableHead
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onRequestSort={(e, prop) => handleRequestSort(e, prop)}
            rowCount={rows.length}
          />

          <TableBody>
            {stableSort(rows, getSorting(order, orderBy))
              // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((n) => {
                const isSelected = selected.indexOf(n.id) !== -1

                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, n.id)}
                    role="checkbox"
                    aria-checked={isSelected}
                    tabIndex={-1}
                    key={n.id}
                    className={classes.row}
                    selected={isSelected}>
                    <CustomTableCell
                      padding="checkbox"
                      className={classes.color}
                      style={{}}></CustomTableCell>
                    <CustomTableCell component="th" scope="row">
                      {n.brand}
                    </CustomTableCell>
                    <CustomTableCell align="right" style={{ fontWeight: 400 }}>
                      {n.cardNum}
                    </CustomTableCell>
                    <CustomTableCell align="right">{n.exp}</CustomTableCell>
                    <CustomTableCell align="right">
                      {moment(n.created * 1000).format('MMM Do YYYY, LT')}
                    </CustomTableCell>
                    <CustomTableCell align="right">
                      {n.isDefault ? (
                        <Checkbox color="primary" className={classes.checkbox} checked={true} />
                      ) : (
                        <Checkbox
                          color="primary"
                          className={classes.checkbox}
                          checked={false}
                          onClick={() => handleClickDefaultCheckbox(n.id)}
                        />
                      )}
                    </CustomTableCell>
                    <CustomTableCell align="right">
                      <Delete
                        className={classes.deleteIcon}
                        onClick={() => handleClickDeleteIcon(n.id)}
                      />
                    </CustomTableCell>
                  </TableRow>
                )
              })}
          </TableBody>
        </Table>
      </div>
    </Paper>
  )
}

const mapDispatchToProps = (dispatch) => ({
  removePaymentMethod: (params) => {
    return dispatch(removePaymentMethod(params))
  },
  updateDefaultPaymentMethod: (params) => {
    return dispatch(updateDefaultPaymentMethod(params))
  }
})

export default withRouter(connect(null, mapDispatchToProps)(withStyles(styles)(PaymentMethods)))
