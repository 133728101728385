// import Validator from 'validator'
import validateTag from './validate-tag.js'

const normalizeTagSearch = (tag) => {
  const isValid = validateTag(tag, false)
  let normalizedTag = tag.trim().toLowerCase()

  if (!isValid) {
    return {
      error: true,
      normalizedTag,
      tag
    }
  }

  if (normalizedTag.charAt(normalizedTag.length - 1) === '-') {
    normalizedTag = normalizedTag.slice(0, normalizedTag.length - 1)
  }

  return {
    error: false,
    normalizedTag,
    tag
  }
}

export default normalizeTagSearch
