import React, { useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { analytics } from '../../../../firebase/firebase'
import CreateRebuttalValidation from '../validations/create-rebuttal'
import { createRebuttal } from '../../../../redux/thunks/rebuttals/createRebuttal'
import { endDiscussionByID } from '../../../../redux/thunks/discussions/endDiscussionByID'
import getAvatar from '../../../../utils/functions/getAvatar'
import EmotionPicker from '../../emotion-picker/emotion-picker'
import { Button, CircularProgress } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import {
  FormContainer,
  TextareaProfilePicture,
  TextareaContainer,
  StyledTextarea,
  SubmitContainer
} from './styled/discussion-comps'
import { CreateFormContainer, TextCount } from './styled/create-rebuttal-form-comps'

const useStyles = makeStyles((theme) =>
  createStyles({
    send: {
      display: 'inline-block',
      minHeight: '2.5rem',
      height: '2.5rem',
      width: '11rem',
      margin: '1rem 0 0 1.5rem',
      verticalAlign: 'middle',
      backgroundColor: 'theme.palette.primary.main',
      '&:hover': {
        backgroundColor: 'theme.palette.primary.light'
      },
      [theme.breakpoints.down('md')]: {
        width: '8rem'
      }
    },
    end: {
      display: 'inline-block',
      minHeight: '2.5rem',
      height: '2.5rem',
      width: '11rem',
      margin: '1rem 0 0 0',
      verticalAlign: 'middle',
      [theme.breakpoints.down('md')]: {
        width: '8rem'
      }
    },
    circularProgress: {
      display: 'block',
      width: '17px !important',
      height: '17px !important',
      margin: '.3rem auto 0 auto',
      '& svg': {
        color: 'white'
      }
    }
  })
)

function CreateRebuttalFormHooks(props) {
  const classes = useStyles()
  const currentProfile = useSelector((state) => state.currentProfile)
  const currentUser = useSelector((state) => state.currentUser)
  const { documentVerified } = currentProfile
  const verified = documentVerified ? 1 : 0
  const dispatch = useDispatch()
  /** MAIN COMPONENT HOOKS */
  const [rebuttal, setRebuttal] = useState('')
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState('')
  /** EMOTIONS HOOKS */
  const [topEmotion, setTopEmotion] = useState('')
  const [subEmotion, setSubEmotion] = useState('')
  const [finalEmotion, setFinalEmotion] = useState('')

  const postEmotion = useMemo(() => {
    if (finalEmotion) {
      return finalEmotion
    } else if (subEmotion) {
      return subEmotion
    }
    return topEmotion
  }, [topEmotion, subEmotion, finalEmotion])

  const handleSubmitRebuttal = async () => {
    setProcessing(true)

    const { error } = CreateRebuttalValidation({ rebuttal })

    if (error !== '') {
      setProcessing(false)
      setError(error)
    } else {
      const { discussion } = props

      dispatch(
        createRebuttal({
          rebuttal,
          emotion: postEmotion,
          discussionId: discussion._id
        })
      )
        .then((data) => {
          if (data.error) {
            console.log('[FAIL]: ', 'Failed to create rebuttal!')
            setProcessing(false)
            setError(data.message)
          } else {
            console.log('[SUCCESS]: ', 'Successfully created rebuttal!')

            setRebuttal('')
            setProcessing(false)
            setError('')
          }
        })
        .catch((error) => {
          console.log('[ERROR]: ', error)
          setProcessing(false)
          setError(error.message)
        })
    }
  }

  const handleEndDiscussion = async () => {
    const { discussion } = props

    setProcessing(true)

    dispatch(
      endDiscussionByID({
        discussionId: discussion._id,
        endedBy: currentUser.id
      })
    ).then((data) => {
      if (data.error) {
        console.log('[FAIL]: ', 'Failed to end discussion!')
        setProcessing(false)
        setError(data.message)
      } else {
        console.log('[SUCCESS]: ', 'Successfully ended discussion!')

        setRebuttal('')
        setProcessing(false)
        setError('')
      }
    })
  }

  return (
    <CreateFormContainer>
      <FormContainer>
        <TextareaProfilePicture
          style={{ backgroundImage: `url(${getAvatar(currentProfile.avatar)})` }}
          verified={verified}
        />

        <TextareaContainer>
          <StyledTextarea
            id="rebuttal-form"
            name="rebuttal"
            placeholder="Keep the discussion going or end and give a civility rating."
            value={rebuttal}
            wrap="soft"
            maxLength="3000"
            onChange={(e) => setRebuttal(e.target.value)}></StyledTextarea>
        </TextareaContainer>
        <TextCount>{`${rebuttal.length} / 3000`}</TextCount>
      </FormContainer>
      <p style={{ fontSize: '1rem', color: '#000' }}>Add your emotion to your response:</p>
      <EmotionPicker
        topEmotion={topEmotion}
        subEmotion={subEmotion}
        finalEmotion={finalEmotion}
        setTopEmotion={setTopEmotion}
        setSubEmotion={setSubEmotion}
        setFinalEmotion={setFinalEmotion}
      />

      <SubmitContainer>
        <Button
          variant="contained"
          color="secondary"
          className={classes.end}
          onClick={handleEndDiscussion}>
          End
        </Button>

        <Button
          variant="contained"
          color="primary"
          className={classes.send}
          onClick={handleSubmitRebuttal}
          disabled={processing || rebuttal.trim().length === 0}>
          {processing ? (
            <CircularProgress color="secondary" className={classes.circularProgress} />
          ) : (
            'Send'
          )}
        </Button>
      </SubmitContainer>
    </CreateFormContainer>
  )
}

export default CreateRebuttalFormHooks
