import { loaderActionTypes } from '../action-creators/loader.js'

// FOR FETCH ACTIONS
const loaderReducer = (state = false, action) => {
	switch (action.type) {
		case loaderActionTypes.START_LOADER:
			return true
		case loaderActionTypes.STOP_LOADER:
			return false
		default:
			return state
	}
}

export default loaderReducer