import React from 'react'
import styled from 'styled-components'

const ErrorContainer = styled.div`
  width: 100%;
  margin-bottom: 1.5rem;
  color: #ff4d4d;
  line-height: 1.2rem;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeight.semiBold};
  word-break: break-word;
`

const FormError = (props) => {
  const message =
    typeof props.message === 'undefined' || props.message === null
      ? 'Something went wrong.'
      : props.message.toString()

  if (props.message !== '') {
    return <ErrorContainer style={props.style || {}}>{message}</ErrorContainer>
  } else {
    return null
  }
}

export default FormError
