//////////////////
// ACTION TYPES //
//////////////////
export const verificationAttemptActionTypes = {
  SET_VERIFICATION_ATTEMPTS: 'SET_VERIFICATION_ATTEMPTS',
  ADD_VERIFICATION_ATTEMPTS: 'ADD_VERIFICATION_ATTEMPTS',
  CANCEL_VERIFICATION_ATTEMPT: 'CANCEL_VERIFICATION_ATTEMPT',
  CLEAR_VERIFICATION_ATTEMPTS: 'CLEAR_VERIFICATION_ATTEMPTS'
}

//////////////
// VERIFICATION ATTEMPTS //
//////////////
const set = verificationAttempts => {
  return {
    type: verificationAttemptActionTypes.SET_VERIFICATION_ATTEMPTS,
    verificationAttempts
  }
}

const add = verificationAttempts => {
  return {
    type: verificationAttemptActionTypes.ADD_VERIFICATION_ATTEMPTS,
    verificationAttempts
  }
}

const cancel = verificationAttemptId => {
  return {
    type: verificationAttemptActionTypes.CANCEL_VERIFICATION_ATTEMPT,
    verificationAttemptId
  }
}

const clear = () => {
  return {
    type: verificationAttemptActionTypes.CLEAR_VERIFICATION_ATTEMPTS
  }
}

export const verificationAttemptsActions = {
  set,
  add,
  cancel,
  clear
}
