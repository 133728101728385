import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
// import _ from 'lodash'
// import moment from 'moment'
// import { getAllUserVerificationAttempts } from '../../../../../redux/thunks/verification-attempts/getAllUserVerificationAttempts'
// import getRemainingVerifications from '../../../../../utils/functions/getRemainingVerifications'
// import nestedSort from '../../../../../utils/functions/nestedSort'
// import load from './functions/load'
import Verify from '../../../../partials/verify/verify'
// import VerificationHistory from './components/verification-history/verification-history'
// import Map from '../../../../partials/google-map/map'
import PropTypes from 'prop-types'
import withStyles from '@mui/styles/withStyles'
// import { Button, CircularProgress, Tooltip } from '@mui/material'
// import { Help, ExpandMore, ExpandLess } from '@mui/icons-material'
import {
  Section
  // VerificationStatusContainer,
  // VerificationStatusLabel,
  // VerificationStatus
  // SectionHeader
  // VerificationsRemaining,
  // VerificationsRemainingText,
  // VerificationsRemainingNumber,
  // SectionSubheader,
  // MapFloatView,
  // MapStackView,
  // MapDetailsContainer,
  // MapContainer,
  // MapDetailsSummaryContainer,
  // MapDetailLabel,
  // MapDetailsPrimary,
  // MapDetailsSecondary,
  // HistoryHidden
} from './styled/identification-comps'

// const getTimeUntilMoreAttempts = (verificationAttempts) => {
//   const attempts = _.filter(verificationAttempts, (verificationAttempt) => {
//     const { countAsAttempt, status, whenCreated } = verificationAttempt

//     const date = new Date()
//     date.setMonth(date.getMonth() - 3)

//     if (countAsAttempt) {
//       if (status === 'succeeded' || status === 'failed') {
//         if (whenCreated >= date.getTime()) {
//           return true
//         }
//       }
//     }

//     return false
//   })

//   if (attempts.length === 0) {
//     return 'Already At Max'
//   }

//   const sortedAttempts = nestedSort(attempts, 1, 'whenCreated')
//   const oldestAttempt = sortedAttempts[0]
//   const oldestAttemptDate = new Date(oldestAttempt.whenCreated)
//   const currentDate = new Date()
//   const futureDate = oldestAttemptDate
//   futureDate.setMonth(futureDate.getMonth() + 3)

//   return moment(currentDate).to(futureDate)
// }

const styles = (theme) => ({
  loader: {
    display: 'inline-block'
  },
  helpIconRemaining: {
    display: 'inline-block',
    margin: '.1rem 0 0 .9rem',
    verticalAlign: 'middle',
    fontSize: '1.1rem',
    color: 'rgba(255, 255, 255, .7)',
    cursor: 'pointer',
    '@media (max-width: 400px)': {
      marginTop: '.5rem'
    }
  },
  helpIcon: {
    display: 'inline-block',
    verticalAlign: 'middle',
    margin: '0 0 .3rem .9rem',
    fontSize: '1.1rem',
    color: theme.palette.secondary.main,
    cursor: 'pointer'
  },
  tooltip: {
    backgroundColor: theme.palette.secondary.light,
    padding: '1rem',
    color: theme.palette.secondary.main,
    fontSize: '.925rem',
    fontWeight: 400,
    marginBottom: '1rem'
  },
  showHideButton: {
    margin: '0 0 0 2rem',
    width: 'auto',
    minHeight: '2rem',
    height: '2rem',
    fontSize: '.9rem',
    '@media (max-width: 550px)': {
      display: 'flex',
      marginLeft: '0',
      marginTop: '1rem'
    }
  },
  showHideIcon: {
    // color: '#cc0000',
    fontSize: '1.1rem',
    margin: '.2rem -.3rem 0 .3rem'
  }
})

class Identification extends Component {
  constructor(props) {
    super(props)

    this.state = {
      status: {
        pageLoaded: false,
        historyOpen: true
      },
      error: ''
    }

    this.handleSetState = this.handleSetState.bind(this)
    this.handleToggleHistory = this.handleToggleHistory.bind(this)
  }

  componentDidMount() {
    // const identificationActions = {
    //   getAllUserVerificationAttempts: this.props.getAllUserVerificationAttempts
    // }
    // // Call function to handle my account load conditions
    // load(this.props.currentUser, this.state, this.handleSetState, identificationActions)
  }

  handleSetState(object) {
    this.setState(object)
  }

  handleToggleHistory() {
    this.setState({
      ...this.state,
      status: {
        ...this.state.status,
        historyOpen: !this.state.status.historyOpen
      }
    })
  }

  render() {
    // const { currentUser } = this.props
    // const { status } = this.state

    // if (!status.pageLoaded) {
    //   return (
    //     <div style={{ textAlign: 'center' }}>
    //       <CircularProgress color="primary" className={classes.loader} />
    //     </div>
    //   )
    // }

    // const fname = currentUser.fname
    // const lname = currentUser.lname
    // const Lat = currentProfile.personalAddress.loc.coordinates
    //   ? currentProfile.personalAddress.loc.coordinates[1]
    //   : 0
    // const Lng = currentProfile.personalAddress.loc.coordinates
    //   ? currentProfile.personalAddress.loc.coordinates[0]
    //   : 0
    // const Address = `${currentProfile.personalAddress.address1}, ${currentProfile.personalAddress.city}, ${currentProfile.personalAddress.state}`
    // const Address1 = currentProfile.personalAddress.address1
    // const Address2 = currentProfile.personalAddress.address2
    // const City = currentProfile.personalAddress.city
    // const State = currentProfile.personalAddress.state
    // const ZipCode = currentProfile.personalAddress.zipCodePlus4
    // const documentExp = new Date(currentUser.documentExp)
    // const zoom = 14
    // const addressFound = Address1 !== ''

    return (
      <div>
        {/* <VerificationsRemaining>
          <VerificationsRemainingText>Verifications Remaining:</VerificationsRemainingText>

          <VerificationsRemainingNumber>
            {getRemainingVerifications(verificationAttempts)}
          </VerificationsRemainingNumber>

          <Tooltip
            classes={{
              tooltip: classes.tooltip
            }}
            title={
              <p>
                For your security and that of our community, you are allowed 3 completed
                verification attempts every 3 months.
                <br />
                <br />
                <b>Please Note:</b> Every successful and failed verification counts towards your
                total attempts. Canceling a verification does not take away for from your total
                attempts.
                <br />
                <br />
                <b>More attempts in</b>: {getTimeUntilMoreAttempts(verificationAttempts)}
              </p>
            }
            placement="bottom-end"
            enterDelay={400}>
            <Help className={classes.helpIconRemaining} />
          </Tooltip>
        </VerificationsRemaining> */}

        <Section style={{ marginTop: 0 }}>
          {/* <SectionHeader>
            {currentProfile.documentVerified ? 'Update Your Identity' : 'Verify Your Identity'}
          </SectionHeader> */}

          {/* <VerificationStatusContainer>
            <VerificationStatusLabel>Verification Status:</VerificationStatusLabel>

            {currentProfile.documentVerified ? (
              <VerificationStatus style={{ backgroundColor: '#2eb2fa' }}>
                Verified
              </VerificationStatus>
            ) : (
              <VerificationStatus style={{ backgroundColor: '#aaa' }}>
                Unverified
              </VerificationStatus>
            )}
          </VerificationStatusContainer> */}

          <Verify />
        </Section>

        {/* {currentProfile.documentVerified && (
          <Section>
            <SectionHeader>My Identification</SectionHeader>

            <SectionSubheader>
              <b>NOTE:</b>&nbsp;&nbsp;If you move and would like to connect with others in your new
              location, please verify a government-issued ID from your new location.
            </SectionSubheader>

            <MapDetailsContainer>
              <MapFloatView>
                <MapContainer>
                  <Map
                    apiKey={import.meta.env.VITE_GOOGLE_GEOCODING_KEY}
                    center={{ lat: Lat, lng: Lng }}
                    zoom={zoom}
                    locations={addressFound ? [{ address: Address, lat: Lat, lng: Lng }] : []}
                  />
                </MapContainer>
              </MapFloatView>

              <MapDetailsSummaryContainer>
                <MapDetailsPrimary>
                  <b>{`${fname} ${lname}`}</b>
                </MapDetailsPrimary>

                <div>
                  <MapDetailLabel>Address:</MapDetailLabel>

                  {addressFound ? (
                    <MapDetailsSecondary>
                      {Address1}
                      {Address2 && <br />}
                      {Address2}
                      <br />
                      {`${City}, ${State}`}
                      <br />
                      {`${ZipCode}`}
                    </MapDetailsSecondary>
                  ) : (
                    <MapDetailsSecondary>
                      <b>
                        No Address
                        <Tooltip
                          classes={{
                            tooltip: classes.tooltip
                          }}
                          title={
                            <p>
                              Some location-based results on the platform may be inaccessible until
                              you provide an address. If you would like to update your address,
                              please update your identification with a government-issued ID.
                            </p>
                          }
                          placement="top"
                          enterDelay={400}>
                          <Help className={classes.helpIcon} />
                        </Tooltip>
                      </b>
                    </MapDetailsSecondary>
                  )}
                </div>

                <div>
                  <MapDetailLabel>Date of Birth:</MapDetailLabel>
                  <MapDetailsSecondary>
                    {moment.utc(currentUser.dob).format('MMM Do YYYY')}
                  </MapDetailsSecondary>
                </div>

                <div>
                  <MapDetailLabel>Document Exp:</MapDetailLabel>
                  <MapDetailsSecondary>
                    {moment.utc(documentExp).format('MMM Do YYYY')}
                  </MapDetailsSecondary>
                </div>
              </MapDetailsSummaryContainer>

              <MapStackView>
                <MapContainer>
                  <Map
                    apiKey={import.meta.env.VITE_GOOGLE_GEOCODING_KEY}
                    center={{ lat: Lat, lng: Lng }}
                    zoom={zoom}
                    locations={addressFound ? [{ address: Address, lat: Lat, lng: Lng }] : []}
                  />
                </MapContainer>
              </MapStackView>
            </MapDetailsContainer>
          </Section>
        )}

        {verificationAttempts.length > 0 && (
          <Section>
            <SectionHeader>Verification History</SectionHeader>

            <Button
              variant="contained"
              color="secondary"
              className={classes.showHideButton}
              onClick={this.handleToggleHistory}>
              {status.historyOpen ? 'Hide History' : 'Show History'}
              {status.historyOpen ? (
                <ExpandLess className={classes.showHideIcon} />
              ) : (
                <ExpandMore className={classes.showHideIcon} />
              )}
            </Button>

            {status.historyOpen ? (
              <VerificationHistory verificationAttempts={verificationAttempts} />
            ) : (
              <HistoryHidden>Your verification history is hidden</HistoryHidden>
            )}
          </Section>
        )} */}
      </div>
    )
  }
}

Identification.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  currentUser: state.currentUser
  // currentProfile: state.currentProfile,
  // verificationAttempts: state.verificationAttempts
})

const mapDispatchToProps = (dispatch) => ({
  // getAllUserVerificationAttempts: (params) => {
  //   return dispatch(getAllUserVerificationAttempts(params))
  // }
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Identification))
)
