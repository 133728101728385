import { organizationsActionTypes } from '../action-creators/organizations.js'
import some from 'lodash/some'

const copyOrganizations = (organizations) => {
  return organizations.map((organization) => copyOrganization(organization))
}

const copyOrganization = (organization) => ({
  ...organization,
  phone: { ...organization.phone },
  address: { ...organization.address },
  tagsIds: [...organization.tagIds]
})

// FOR ORGANIZATIONS
const organizationsReducer = (state = [], action) => {
  switch (action.type) {
    case organizationsActionTypes.SET_ORGANIZATIONS:
      // TODO:  why do we need to copy incoming state if the current state is an empty array?
      return copyOrganizations(action.organizations)
    case organizationsActionTypes.ADD_ORGANIZATIONS:
      var newOrganizations1 = copyOrganizations(state)

      for (let i = 0; i < action.organizations.length; i++) {
        // this is checking that passed in take exists
        const exists = some(newOrganizations1, { _id: action.organizations[i]._id })

        if (!exists) {
          newOrganizations1.push({ ...action.organizations[i] })
        }
      }

      return newOrganizations1
    case organizationsActionTypes.UPDATE_ORGANIZATION:
      const copyOfStateToUpdate = copyOrganizations(state)

      // iterate and find the object to update
      for (let i = 0; i < copyOfStateToUpdate.length; i++) {
        if (copyOfStateToUpdate[i]._id === action.organizationId) {
          copyOfStateToUpdate[i] = { ...action.updatedFields }

          break
        }
      }

      return copyOfStateToUpdate
    case organizationsActionTypes.UPLOAD_ORGANIZATION_IMAGE:
      const copyOfStateToUpdateImage = copyOrganizations(state)
      const updatedItemWithImage = {}

      for (let i = 0; i < copyOfStateToUpdateImage.length; i++) {
        if (copyOfStateToUpdateImage[i]._id === action.organizationId) {
          // we found the object to update
          Object.assign(updatedItemWithImage, copyOfStateToUpdateImage[i], action.updatedFields)
          copyOfStateToUpdateImage[i] = updatedItemWithImage
        }
      }

      return copyOfStateToUpdateImage
    case organizationsActionTypes.CLEAR_ORGANIZATIONS:
      return []
    default:
      return state
  }
}

export default organizationsReducer
