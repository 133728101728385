import config from '../../../config'
import { loadedTakesActions } from '../../action-creators/loaded-takes.js'
import { actionResponseActions } from '../../action-creators/action-response.js'
import { authenticatedFetch } from '../../../utils/functions/authenticatedFetch'

/*
 * METHOD TO EDIT TAKE BY ID
 */
export const editTakeByID = (params) => {
  let responseCode = ''
  const method = 'PUT'
  const url = `${config.baseURL}/api/take/edit/${params.takeId}`

  const formData = new FormData()
  formData.append('take', params.take)
  formData.append('file', params.file)
  formData.append('tagIds', params.tagIds)
  formData.append('emotion', params.emotion)
  formData.append('location', JSON.stringify(params.location))
  formData.append('visibleTo', JSON.stringify(params.visibleTo))
  formData.append('courseId', params.courseId)

  // delete an image
  // if params.image is undefined send image with an empty string
  params.image === undefined && formData.append('image', '')

  const requestBody = formData
  // Thunk middleware knows how to handle functions.
  // It passes the dispatch method as an argument to the function,
  // thus making it able to dispatch actions itself.
  return (dispatch) => {
    console.log('Sending edit take request to CivilTalk')

    // Return the fetch so react components calling 'store.dispatch()' can use 'then()'
    return authenticatedFetch(url, {
      method,
      body: requestBody,
      credentials: 'include'
    })
      .then((response) => {
        responseCode = response.status

        return response.json()
      })
      .then((data) => {
        if (responseCode === 200) {
          const { _id, take, image, tagIds, emotion, location, visibleTo, courseId } = data.take

          console.log('updated courseId', courseId)

          dispatch(
            loadedTakesActions.editTake({
              takeId: _id,
              take,
              image,
              tagIds,
              emotion,
              location,
              visibleTo,
              courseId
            })
          )
          dispatch(actionResponseActions.createActionResponse('Take updated!'))

          return {
            error: false,
            code: responseCode,
            message: data.message
          }
        } else {
          console.log(data.message)

          return {
            error: true,
            code: responseCode,
            message: data.message
          }
        }
      })
      .catch((error) => {
        console.log(error)

        return {
          error: true,
          code: '',
          message: error
        }
      })
  }
}
