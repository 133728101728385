//////////////////
// ACTION TYPES //
//////////////////

export const tokensTransactionModalActionTypes = {
  SET_TOKENS_TRANSACTION_MODAL: 'SET_TOKENS_TRANSACTION_MODAL',
  CLEAR_TOKENS_TRANSACTION_MODAL: 'CLEAR_TOKENS_TRANSACTION_MODAL'
}

/////////////
// ACTIONS //
/////////////

// TOKEN MODAL
const set = (open, options) => {
  return {
    type: tokensTransactionModalActionTypes.SET_TOKENS_TRANSACTION_MODAL,
    open,
    options
  }
}

const clear = () => {
  return {
    type: tokensTransactionModalActionTypes.CLEAR_TOKENS_TRANSACTION_MODAL
  }
}

export const tokensTransactionModalActions = {
  set,
  clear
}
