import styled from 'styled-components'

export const NavItemContainer = styled.div`
  font-size: 0;
`

export const NavItemHeader = styled.span`
  display: inline-block;
  vertical-align: middle;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.regular};
  font-weight: ${(props) => props.theme.typography.fontWeight.bold};
  color: ${(props) => props.theme.palette.text.primary};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`

export const RedNavItem = styled.span`
  display: inline-block;
  vertical-align: middle;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.regular};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  color: ${(props) => props.theme.palette.text.primary};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`

export const NavItem = styled.span`
  display: inline-block;
  vertical-align: middle;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.regular};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  color: ${(props) => props.theme.palette.text.primary};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`
