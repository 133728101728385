import React, { useState, useCallback, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { isLoaded, isEmpty } from 'react-redux-firebase'
import NotificationsMenu from './components/notifications-menu'
import NotificationsList from './components/notifications-list'
import Listener from './listeners/unread-notifications'
import Portal from '../../../partials/portal/portal'
import { IconButton, Tooltip, Badge, ClickAwayListener } from '@mui/material'
import MuiNotificationsIcon from '@mui/icons-material/Notifications'
import makeStyles from '@mui/styles/makeStyles'
import withStyles from '@mui/styles/withStyles'
import createStyles from '@mui/styles/createStyles'
import config from '../../../../config'
import {
  MenuListContainer,
  NotificationsHeaderContainer,
  NotificationsHeader
} from './styled/notifications-dropdown-comps'

const useStyles = makeStyles((theme) => ({
  iconButton: {
    // position: 'absolute',
    // top: '1rem',
    // right: '4rem'
    // [`@media (max-width:${config.UI.drawer_breakpoint.max})`]: {
    //   position: 'absolute',
    //   top: '1rem',
    //   right: '4rem'
    // }
  },
  iconButtonActive: {
    backgroundColor: '#f2f2f2'
    // position: 'absolute',
    // top: '1rem',
    // right: '4rem'
    // [`@media (max-width:${config.UI.drawer_breakpoint.max})`]: {
    //   position: 'absolute',
    //   top: '1rem',
    //   right: '4rem'
    // }
  },
  menuList: {
    paddingTop: 0
  },
  menuItem: {
    height: '5.5rem',
    padding: '1rem 0',
    borderBottom: '1px solid #eaeaea',
    backgroundColor: theme.palette.common.white,
    boxShadow: '-3px -1px 6px 3px rgba(100, 100, 100, 0.1)',
    cursor: 'default',
    '&:hover': {
      backgroundColor: '#f2f2f2'
    }
  },
  icon: {
    // width: '1.4rem',
    color: theme.palette.text.primary,
    // [`@media (max-width:${config.UI.drawer_breakpoint.max})`]: {
    //   width: '2.2rem',
    //   height: '2.2rem'
    // }
    width: '1.5rem',
    height: '1.5rem'
  },
  dropdownItem: {
    padding: '.4rem 1rem'
  }
}))

const StyledBadge = withStyles(() =>
  createStyles({
    badge: {
      position: 'absolute',
      top: 0,
      right: '-0.125rem'
    }
  })
)(Badge)

function Notifications() {
  const classes = useStyles()
  const [filter, setFilter] = useState('unread')
  const [open, setOpen] = useState(false)
  const anchorRef = useRef(null)
  const authRef = useSelector((state) => state.firebase.auth)
  const unreadNotificationsRef =
    useSelector(
      ({
        firestore: {
          data: { userMeta }
        }
      }) => {
        return (
          userMeta &&
          userMeta[authRef.uid] &&
          userMeta[authRef.uid].unreadNotifications &&
          userMeta[authRef.uid].unreadNotifications.all
        )
      }
    ) || 0

  const handleToggle = () => {
    if (open === true) {
      setFilter('unread')
    }

    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (e) => {
    if (anchorRef.current && anchorRef.current.contains(e.target)) {
      return
    }

    setFilter('unread')
    setOpen(false)
  }

  const handleCloseFromInnerComponent = useCallback((e) => {
    return handleClose(e)
  }, [])

  const prevOpen = useRef(open)

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }

    prevOpen.current = open
  }, [open])

  return (
    <>
      {isLoaded(authRef) && !isEmpty(authRef) && <Listener auth={authRef} />}

      <Tooltip title="Notifications">
        <IconButton
          ref={anchorRef}
          aria-controls={open ? 'notifications-dropdown' : undefined}
          aria-haspopup="true"
          className={open ? classes.iconButtonActive : classes.iconButton}
          onClick={handleToggle}
          size="large">
          <StyledBadge
            badgeContent={unreadNotificationsRef}
            color="primary"
            overlap="circular"
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}>
            <MuiNotificationsIcon className={classes.icon} role="img" />
          </StyledBadge>
        </IconButton>
      </Tooltip>

      {open && (
        <Portal>
          <ClickAwayListener onClickAway={handleClose}>
            <MenuListContainer>
              <NotificationsHeaderContainer>
                <NotificationsHeader>{filter}</NotificationsHeader>

                <NotificationsMenu
                  unreadNotifications={unreadNotificationsRef}
                  filter={filter}
                  setFilter={setFilter}
                />
              </NotificationsHeaderContainer>

              <>
                {filter === 'unread' && (
                  <NotificationsList
                    filter={filter}
                    handleCloseFromInnerComponent={handleCloseFromInnerComponent}
                  />
                )}
                {filter === 'connections' && (
                  <NotificationsList
                    filter={filter}
                    handleCloseFromInnerComponent={handleCloseFromInnerComponent}
                  />
                )}
                {filter === 'activity' && (
                  <NotificationsList
                    filter={filter}
                    handleCloseFromInnerComponent={handleCloseFromInnerComponent}
                  />
                )}
                {filter === 'community' && (
                  <NotificationsList
                    filter={filter}
                    handleCloseFromInnerComponent={handleCloseFromInnerComponent}
                  />
                )}
                {filter === 'account' && (
                  <NotificationsList
                    filter={filter}
                    handleCloseFromInnerComponent={handleCloseFromInnerComponent}
                  />
                )}
              </>
            </MenuListContainer>
          </ClickAwayListener>
        </Portal>
      )}
    </>
  )
}

export default Notifications
