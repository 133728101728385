import styled from 'styled-components'

export const MenuItemText = styled.span`
  display: inline-block;
  margin: -0.1rem 0 0 1rem;
  vertical-align: middle;
  ${'' /* line-height: 1.4rem; */}
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.large};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  color: ${(props) => props.theme.typography.fontColor.actionBarCopy};
  text-transform: capitalize;
`

export const HR = styled.hr`
  display: block;
  width: 100%;
  height: 1px;
  margin: 0 auto;
  border: 0;
  color: #999;
  ${'' /* border-top: 1px solid rgba(100, 100, 100, 0.1); */}
`

export const LogoutContainer = styled.div`
  padding: 1rem;
`
