import copyVerificationAttempt from './copy-verification-attempt.js'

const load = (
  setIsLoading,
  setStripe,
  setVerificationAttemptId,
  setLastError,
  setVerificationAttempt,
  setStatus,
  actions
) => {
  // EXECUTABLES ****************
  // ****************************

  const _loadStripe = () => {
    return actions.loadStripe(import.meta.env.VITE_STRIPE_KEY)
  }

  const _checkOngoingVerificationAttempt = () => {
    return actions.checkOngoingVerificationAttempt()
  }

  // EXECUTION ******************
  // ****************************

  return _loadStripe()
    .then((data1) => {
      setStripe(data1)

      return _checkOngoingVerificationAttempt()
    })
    .then((data2) => {
      if (data2.error) {
        console.log('[FAIL]: ', 'Failed to check for ongoing verification attempt.')
      } else {
        if (data2.verificationAttempt) {
          console.log('[SUCCESS]: ', 'Ongoing verification found!')

          setVerificationAttempt(copyVerificationAttempt(data2.verificationAttempt))
          setVerificationAttemptId(data2.verificationAttempt._id)
          setLastError(data2.verificationAttempt.lastError)
          setStatus(data2.verificationAttempt.status)
        } else {
          console.log('[SUCCESS]: ', 'No ongoing verification found')
        }
      }

      setIsLoading(false)

      return Promise.resolve()
    })
    .catch((error) => {
      console.log('[ERROR]: ', error)
    })
}

export default load
