import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { tokensTransactionModalActions } from '../../../../../../../redux/action-creators/tokens-transaction-modal'
import { getProfileByUserID } from '../../../../../../../redux/thunks/profiles/getProfileByUserID'
import getAvatar from '../../../../../../../utils/functions/getAvatar'
import getName from '../../../../../../../utils/functions/getName'
import tokensValidation from './validations/tokens'
import FormError from '../../../../../../alerts/form-error'
import TokenIcon from '../../../../../../../media/icons/svg/token-green.svg'
import {
  ToggleButtonGroup,
  ToggleButton,
  TextField,
  InputAdornment,
  Button,
  CircularProgress
} from '@mui/material'
import {
  Heading,
  Subheading,
  RecipientContainer,
  RecipientImage,
  RecipientName,
  TokensContainer,
  Tokens,
  TokensLabel,
  ButtonContainer
} from './styled/selection-comps'
import theme from '../../../../../../../res/theme'

const styles = {
  toggleButtonGroup: {
    display: 'flex',
    justifyContent: 'center',
    margin: '2rem 0 0 0',
    flexFlow: 'row wrap',
    boxSizing: 'border-box',
    '& > button': {
      display: 'inline-block',
      minWidth: '4rem',
      boxSizing: 'border-box',
      borderWidth: '2px',
      '@media (max-width: 500px)': {
        display: 'block',
        width: '100%',
        margin: '.1rem 0',
        borderWidth: '1px !important',
        borderRadius: '.3rem !important',
        borderColor: `${theme.palette.secondary.main} !important`
      }
    }
  },
  textField: {
    display: 'block',
    width: '20.7rem',
    margin: '1rem auto 0 auto',
    '& > div': {
      width: '20.7rem'
    },
    '& img': {
      height: '1.5rem'
    },
    '@media (max-width: 500px)': {
      width: '100%',
      '& > div': {
        width: '100%'
      }
    }
  },
  cancel: {
    width: '12rem',
    marginRight: '1rem',
    '@media (max-width: 500px)': {
      width: '100%',
      marginRight: '0'
    }
  },
  submit: {
    width: '12rem',
    '@media (max-width: 500px)': {
      width: '100%',
      marginTop: '.5rem'
    }
  },
  circularProgress: {
    display: 'block',
    width: '17px !important',
    height: '17px !important',
    margin: '0 auto'
  }
}

function Selection(props) {
  const { setStep, tokens, setTokens, userId } = props
  const [isLoading, setIsLoading] = useState(true)
  const [profile, setProfile] = useState(null)
  const [tokenSelection, setTokenSelection] = useState('25')
  const [error, setError] = useState('')
  const dispatch = useDispatch()

  useEffect(() => {
    if (isLoading) {
      dispatch(getProfileByUserID({ userId }))
        .then((data) => {
          if (!data.error) {
            console.log('[SUCCESS]: ', 'Successfully retrieved profile by ID.')

            setProfile(data.profile ? data.profile : null)
          } else {
            console.log('[FAIL]: ', 'Failed to retrieve profile by ID.')
          }

          setIsLoading(false)
        })
        .catch((e) => {
          setIsLoading(false)
        })
    }
  }, [dispatch, isLoading, userId])

  useEffect(() => {
    switch (tokenSelection) {
      case '5':
        setTokens(5)
        break
      case '10':
        setTokens(10)
        break
      case '25':
        setTokens(25)
        break
      case '50':
        setTokens(50)
        break
      default:
        setTokens('')
        break
    }
  }, [setTokens, tokenSelection])

  const handleClose = () => {
    dispatch(tokensTransactionModalActions.clear())
  }

  const handleSubmit = () => {
    const { error } = tokensValidation(tokens)

    if (error) {
      console.log('[VALIDATION ERROR]: ', error)

      setError(error)
    } else {
      setStep('confirmation')
    }
  }

  const handleInputChange = (e) => {
    const { value } = e.target
    const parsedValue = parseInt(value)
    const onlyDigitsRegExp = new RegExp(/^[0-9]+$/)

    // If Input is empty, set input to empty string
    if (value === '') {
      return setTokens(value)
    }

    // If Input contains anything but digits, dont update state
    if (!onlyDigitsRegExp.test(value)) {
      return
    }

    // If Input passes as a number, check to make sure it is between proper min and max value.
    // If so set new value in state
    // Else, don't update state
    if (!isNaN(parsedValue)) {
      if (parsedValue >= 1 && parsedValue <= 1000) {
        return setTokens(value)
      } else {
        return
      }
    }

    // Catch All - Set Tokens input to empty string
    return setTokens('')
  }

  if (isLoading) {
    return <CircularProgress color="secondary" sx={styles.circularProgress} />
  }

  return (
    <div>
      <Heading>How many tokens would you like to send?</Heading>
      <Subheading>
        Thank you for being an active part of our community. Awarding members for their fundraising
        efforts or good deeds is a great way to show your support. These awarded tokens will
        undoubtedly go to an organization in need.
      </Subheading>

      {profile && (
        <RecipientContainer>
          <RecipientImage
            style={{
              backgroundImage: `url(${getAvatar(profile.avatar)})`
            }}
          />
          <RecipientName>{getName(profile)}</RecipientName>
        </RecipientContainer>
      )}

      <FormError message={error} style={{ marginTop: '1rem' }} />

      <ToggleButtonGroup
        color="secondary"
        value={tokenSelection}
        exclusive
        onChange={(e, value) => setTokenSelection(value)}
        aria-label="text tokenSelection"
        sx={styles.toggleButtonGroup}>
        <ToggleButton value="5" aria-label="5">
          5
        </ToggleButton>
        <ToggleButton value="10" aria-label="10">
          10
        </ToggleButton>
        <ToggleButton value="25" aria-label="25">
          25
        </ToggleButton>
        <ToggleButton value="50" aria-label="50">
          50
        </ToggleButton>
        <ToggleButton value="custom" aria-label="custom">
          Custom
        </ToggleButton>
      </ToggleButtonGroup>

      {tokenSelection === 'custom' && (
        <TextField
          color="secondary"
          value={tokens}
          focused
          label="Tokens"
          id="outlined-start-adornment"
          sx={styles.textField}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" sx={{ color: theme.palette.secondary.main }}>
                {/* <span className="material-icons">paid</span> */}
                <img src={TokenIcon} alt="Purchase Tokens Icon" />
              </InputAdornment>
            )
          }}
          onChange={handleInputChange}
        />
      )}

      <TokensContainer>
        <Tokens>{tokens || '0'}</Tokens>
      </TokensContainer>

      <TokensLabel>Tokens</TokensLabel>

      <ButtonContainer>
        <Button color="secondary" variant="contained" onClick={handleClose} sx={styles.cancel}>
          Cancel
        </Button>

        <Button
          color="primary"
          variant="contained"
          onClick={handleSubmit}
          sx={styles.submit}
          disabled={isLoading || !tokens || tokens === '0'}>
          Award {tokens || '0'} Tokens
        </Button>
      </ButtonContainer>
    </div>
  )
}

export default Selection
