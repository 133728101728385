import config from '../../../config'
import { authenticatedFetch } from '../../../utils/functions/authenticatedFetch'

export const deleteOrganizationAssignedRoleByID = (params = {}) => {
  let responseCode = ''
  const url = `${config.baseURL}/api/assigned-role/organization?assignedRoleId=${params.assignedRoleId}&organizationId=${params.organizationId}`
  const method = 'DELETE'
  let headers = {
    'Content-Type': 'application/json'
  }

  return (dispatch) => {
    console.log('Sending delete organization assigned role by ID request to CivilTalk')

    return authenticatedFetch(url, {
      method,
      headers,
      credentials: 'include'
    })
      .then((response) => {
        responseCode = response.status

        return response.json()
      })
      .then((data) => {
        if (responseCode === 200) {
          return {
            error: false,
            code: responseCode,
            message: data.message
          }
        }

        console.log(data.message)

        return {
          error: true,
          code: responseCode,
          message: data.message
        }
      })
      .catch((error) => {
        console.log(error)

        return {
          error: true,
          code: '',
          message: error
        }
      })
  }
}
