import { logoutServer } from './logoutServer.js'
import { firebaseLogout } from '../firebase/firebaseLogout.js'
import { clearLocalStorage } from '../../../localStorage'
import { accoladesActions } from '../../action-creators/accolades.js'
import { actionResponseActions } from '../../action-creators/action-response.js'
import { assignedRolesActions } from '../../action-creators/assigned-roles.js'
import { categoriesActions } from '../../action-creators/categories.js'
import { bulletinsActions } from '../../action-creators/bulletins.js'
import { currentProfileActions } from '../../action-creators/current-profile.js'
import { currentUserActions } from '../../action-creators/current-user.js'
import { followsActions } from '../../action-creators/follows.js'
import { isAuthenticatedActions } from '../../action-creators/is-authenticated.js'
import { inviteFriendsActions } from '../../action-creators/invite-friends.js'
import { loadedDiscussionsActions } from '../../action-creators/loaded-discussions.js'
import { loadedProfilesActions } from '../../action-creators/loaded-profiles.js'
import { loadedRebuttalsActions } from '../../action-creators/loaded-rebuttals.js'
import { loadedTakesActions } from '../../action-creators/loaded-takes.js'
import { loadedTopicsActions } from '../../action-creators/loaded-topics.js'
import { loaderActions } from '../../action-creators/loader.js'
import { mobileDrawerActions } from '../../action-creators/mobile-drawer.js'
import { myNotificationsActions } from '../../action-creators/my-notifications.js'
import { notificationOrganizationsActions } from '../../action-creators/notification-organizations.js'
import { notificationProfilesActions } from '../../action-creators/notification-profiles.js'
import { organizationsActions } from '../../action-creators/organizations.js'
import { outgoingDiscussionsActions } from '../../action-creators/outgoing-discussions.js'
import { pollsActions } from '../../action-creators/polls.js'
import { pollVotesActions } from '../../action-creators/poll-votes.js'
import { popularTagsActions } from '../../action-creators/popular-tags.js'
import { postDraftActions } from '../../action-creators/post-draft.js'
import { refreshPromiseActions } from '../../action-creators/refresh-promise.js'
import { relatedTagsActions } from '../../action-creators/related-tags.js'
import { rolesActions } from '../../action-creators/roles.js'
import { searchBarResultsActions } from '../../action-creators/search-bar-results.js'
import { searchResultsActions } from '../../action-creators/search-results.js'
import { suggestedProfilesActions } from '../../action-creators/suggested-profiles.js'
import { tagResultsActions } from '../../action-creators/tag-results.js'
import { tokenExpActions } from '../../action-creators/token-exp.js'
import { tokensTransactionModalActions } from '../../action-creators/tokens-transaction-modal.js'
import { verificationAttemptsActions } from '../../action-creators/verification-attempts.js'

/*
 * METHOD TO LOGOUT
 */
export const logout = (hideActionResponse) => {
  // TODO: BLACKLIST TOKEN
  return (dispatch) => {
    console.log('Logging out...')

    if (!hideActionResponse) {
      dispatch(actionResponseActions.createActionResponse("You've been logged out."))
    }

    // BEG - LOGOUT FIREBASE
    dispatch(firebaseLogout())
    // END

    // BEG - LOGOUT (API REQUEST) - REMOVE REFRESH TOKEN IN DB
    dispatch(logoutServer())
    // END - LOGOUT

    dispatch(isAuthenticatedActions.setAuthentication(false))
    dispatch(accoladesActions.clear())
    dispatch(assignedRolesActions.clear())
    dispatch(categoriesActions.clearCategories())
    dispatch(bulletinsActions.clear())
    dispatch(currentProfileActions.clearProfile())
    dispatch(currentUserActions.clearUser())
    dispatch(followsActions.clear())
    dispatch(inviteFriendsActions.clear())
    dispatch(loadedDiscussionsActions.clearDiscussions())
    dispatch(loadedProfilesActions.clearProfiles())
    dispatch(loadedRebuttalsActions.clear())
    dispatch(loadedTakesActions.clearTakes())
    dispatch(loadedTopicsActions.clearTopics())
    dispatch(mobileDrawerActions.clear())
    dispatch(myNotificationsActions.clearNotifications())
    dispatch(notificationOrganizationsActions.clear())
    dispatch(notificationProfilesActions.clear())
    dispatch(organizationsActions.clear())
    dispatch(outgoingDiscussionsActions.clearDiscussions())
    dispatch(pollsActions.clear())
    dispatch(pollVotesActions.clear())
    dispatch(popularTagsActions.clear())
    dispatch(postDraftActions.clear())
    dispatch(refreshPromiseActions.clear())
    dispatch(relatedTagsActions.clear())
    dispatch(rolesActions.clear())
    dispatch(searchBarResultsActions.clear())
    dispatch(searchResultsActions.clear())
    dispatch(suggestedProfilesActions.clear())
    dispatch(tagResultsActions.clear())
    dispatch(tokenExpActions.clear())
    dispatch(tokensTransactionModalActions.clear())
    dispatch(verificationAttemptsActions.clear())
    dispatch(loaderActions.stopLoader())

    clearLocalStorage()
    sessionStorage.clear()

    return Promise.resolve()
  }
}
