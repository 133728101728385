import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { analytics } from '../../../../firebase/firebase'
import Tags from '../../../partials/tags/tags'
import TagsSearch from '../../../partials/tags-search/tags-search'
import { Button, Select, ListSubheader, MenuItem } from '@mui/material'
import {
  MainContainer,
  SearchAndFilterContainer,
  SearchContainer,
  FilterSortContainer
} from './styled/action-bar-comps'
import theme from '../../../../res/theme'

const styles = {
  selectButton: {
    display: 'inline-block',
    width: '25%',
    minWidth: '4rem',
    maxWidth: '4rem',
    minHeight: '2.65rem',
    height: '2.65rem',
    padding: '0',
    verticalAlign: 'middle',
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.primary.main,
    position: 'relative',
    '&:hover': {
      backgroundColor: theme.palette.primary.light
    },
    '& .material-icons': {
      fontSize: '1.2rem',
      marginTop: '.4rem'
    }
  },
  select: {
    width: '0',
    height: '0',
    visibility: 'hidden'
  },
  selectHeader: {
    padding: '.5rem 1rem',
    lineHeight: '1rem',
    color: theme.palette.text.primary,
    fontWeight: 600,
    fontSize: '1rem' // 16px
  },
  menuItem: {
    minWidth: '10rem',
    padding: '.3rem 1rem',
    color: theme.palette.text.primary,
    fontWeight: 300,
    fontSize: '1rem' // 16px
  }
}

const ActionBar = (props) => {
  const {
    isLoading,
    hideSorting,
    hideFilters,
    hideTags,
    hideTagRemove,
    hideTagsSearch,
    sortBy,
    filter,
    tags,
    searchStyle,
    searchVariant,
    setTags,
    handleSortingChange,
    handleFilterChange,
    handleFilterByPrompt,
    prompts,
    promptId
  } = props

  const style = searchStyle || {}
  const [sortSelectOpen, setSortSelectOpen] = useState(false)
  const [filterSelectOpen, setFilterSelectOpen] = useState(false)
  const currentProfile = useSelector(({ currentProfile }) => currentProfile)
  const follows = useSelector(({ follows }) => follows)
  const { city, state } = currentProfile.personalAddress
  const following = follows.filter((follow) => follow.follower === currentProfile.userId)
  const disableFilters = following.length === 0 && !city && !state

  const handleFilter = (e, filterKey) => {
    handleFilterChange(filterKey)
    setFilterSelectOpen(false)

    analytics.logEvent('filter_home_community_voice')
  }

  const filterByPrompt = (promptId) => {
    handleFilterByPrompt(promptId)

    setFilterSelectOpen(false)
  }

  const handleSort = (e, sortKey) => {
    handleSortingChange(sortKey)
    setFilterSelectOpen(false)

    analytics.logEvent('filter_home_community_voice')
  }

  return (
    <MainContainer>
      {(!hideFilters || !hideSorting || !hideTagsSearch) && (
        <SearchAndFilterContainer>
          {!hideTagsSearch && (
            <SearchContainer>
              <TagsSearch
                tagIds={tags}
                setTagIds={setTags}
                disableNewTags={true}
                style={{
                  ...style,
                  marginTop: '0',
                  border: 'none'
                }}
                variant={searchVariant || 'transparent'}
              />
            </SearchContainer>
          )}

          <FilterSortContainer>
            {/* Sort Select */}
            {!hideSorting && (
              <>
                <Select
                  name="postsSorting"
                  sx={styles.select}
                  open={sortSelectOpen}
                  value={sortBy || ''}
                  onClose={() => setSortSelectOpen(false)}
                  onOpen={() => setSortSelectOpen(true)}>
                  <MenuItem value={''} sx={styles.menuItem} onClick={(e) => handleSort(e, '')}>
                    Most Recent
                  </MenuItem>

                  <MenuItem
                    value={'popularity'}
                    sx={styles.menuItem}
                    onClick={(e) => handleSort(e, 'popularity')}>
                    Popularity
                  </MenuItem>
                </Select>

                <Button
                  variant="contained"
                  color="secondary"
                  disabled={isLoading}
                  onClick={() => setSortSelectOpen(!sortSelectOpen)}
                  sx={styles.selectButton}>
                  <span className="material-icons">swap_vert</span>
                  {/* <span>{filter === '' ? 'Filter By' : filter}</span> */}

                  {sortSelectOpen ? (
                    <span className="material-icons">expand_less</span>
                  ) : (
                    <span className="material-icons">expand_more</span>
                  )}
                </Button>
              </>
            )}

            {/* Filter Select */}
            {!hideFilters && !disableFilters && (
              <>
                <Select
                  name="postsFilter"
                  sx={styles.select}
                  open={filterSelectOpen}
                  value={promptId || ''}
                  onClose={() => setFilterSelectOpen(false)}
                  onOpen={() => setFilterSelectOpen(true)}
                  style={{ marginRight: '.5rem' }}>
                  <MenuItem value={''} sx={styles.menuItem} onClick={(e) => handleFilter(e, '')}>
                    No Filter
                  </MenuItem>

                  {prompts && prompts.length && (
                    <ListSubheader sx={styles.selectHeader}>Prompts</ListSubheader>
                  )}

                  {prompts &&
                    prompts.length &&
                    prompts.map((prompt) => (
                      <MenuItem
                        key={prompt._id}
                        value={prompt._id}
                        sx={styles.menuItem}
                        onClick={() => filterByPrompt(prompt._id)}>
                        {prompt.title}
                      </MenuItem>
                    ))}
                </Select>

                <Button
                  variant="contained"
                  color="secondary"
                  disabled={isLoading}
                  onClick={() => setFilterSelectOpen(!filterSelectOpen)}
                  sx={styles.selectButton}>
                  <span className="material-icons">filter_alt</span>
                  {/* <span>{filter === '' ? 'Filter By' : filter}</span> */}

                  {filterSelectOpen ? (
                    <span className="material-icons">expand_less</span>
                  ) : (
                    <span className="material-icons">expand_more</span>
                  )}
                </Button>
              </>
            )}
          </FilterSortContainer>

          {/* <FilterSortContainer></FilterSortContainer> */}
        </SearchAndFilterContainer>
      )}

      {!hideTags && (
        <Tags
          tagIds={tags}
          setTagIds={setTags}
          sortBy={sortBy}
          handleSort={handleSort}
          filter={filter}
          handleFilter={handleFilter}
          hideTagRemove={hideTagRemove}
          hideTagAdd={true}
          tagStyle={{ padding: '.6rem .5rem', marginTop: '0', marginBottom: '.8rem' }}
        />
      )}
    </MainContainer>
  )
}

export default withRouter(ActionBar)
