import config from '../../../config'
import { loadedTakesActions } from '../../action-creators/loaded-takes.js'
import { currentProfileActions } from '../../action-creators/current-profile.js'
import { actionResponseActions } from '../../action-creators/action-response.js'
import { loaderActions } from '../../action-creators/loader.js'
import { authenticatedFetch } from '../../../utils/functions/authenticatedFetch'

/*
 * METHOD TO CREATE PROMPT
 */
export const createPrompt = (params) => {
  console.log('[DEBUG]: createPrompt params:', params)

  let responseCode = ''
  const method = 'POST'
  const url = `${config.baseURL}/api/prompts/`
  const headers = {
    'Content-Type': 'application/json'
  }

  const requestBody = {
    'title': params.title,
    'prompt': params.prompt,
    'courseId': params.courseId,
    'publishDate': params.publishDate
  }

  const requestBodyJSON = JSON.stringify(requestBody)

  return (dispatch) => {
    console.log('[FETCH]: Sending create prompt request to CivilTalk')

    dispatch(loaderActions.startLoader())

    return authenticatedFetch(url, {
      method,
      body: requestBodyJSON,
      credentials: 'include',
      headers
    })
      .then((response) => {
        dispatch(loaderActions.stopLoader())
        responseCode = response.status

        return response.json()
      })
      .then((data) => {
        console.log(data)
        if (responseCode === 201) {
          console.log('[SUCCESS]: Prompt created successfully', data)
          return {
            error: false,
            code: responseCode,
            message: data.message,
            prompt: data.prompt
          }
        } else {
          console.error('[ERROR]: Failed to create prompt', data)

          return {
            error: true,
            code: responseCode,
            message: data.message
          }
        }
      })
      .then((nextData) => {
        if (nextData.error) {
          return nextData
        } else {
          dispatch(actionResponseActions.createActionResponse('Your new prompt was created!'))

          return nextData
        }
      })
      .catch((error) => {
        console.error('[ERROR], caught while creating prompt', error)

        dispatch(loaderActions.stopLoader())

        return {
          error: true,
          code: '',
          message: error
        }
      })
  }
}
