import React from 'react'
import { Button } from '@mui/material'
import { styled } from '@mui/material/styles'
import {
  MainContainer,
  HeadingPrimary,
  SponsoredByContainer,
  SponsoredByCivilTalkContainer,
  DetailsHeader,
  ButtonsContainer,
  Image,
  CivilTalkImage
} from './styled//sponsored-by-comps'
import MasterCardIcon from '../../../media/icons/mastercard-light.svg'
import { useParams } from 'react-router-dom'
import LazyLoad from 'react-lazyload'

const StyledButton = styled(Button)({
  fontSize: '1.125rem', // 18px
  width: '11rem'
})

const CIVILTALK_LOGO = `https://res.cloudinary.com/headliner/image/upload/v1712948051/logos/civiltalk_logo_transparent.png`
const CIVILTALK_LINK = 'https://www.civiltalk.com/podcast/'
// const MASTERCARD_LINK = 'https://www.mastercard.com'

function SponsoredBy() {
  const params = useParams()

  return (
    <>
      <MainContainer>
        <HeadingPrimary>
          <span>{params.tagId}</span> Sponsored By
        </HeadingPrimary>

        {/* <SponsoredByContainer>
          <LazyLoad>
            <Image src={MasterCardIcon} alt={'Mastercard logo'} width={'7rem'} height={'7rem'} />
          </LazyLoad>

          <DetailsHeader>Mastercard</DetailsHeader>
        </SponsoredByContainer> */}

        <SponsoredByCivilTalkContainer>
          <LazyLoad>
            <CivilTalkImage
              src={CIVILTALK_LOGO}
              alt={'CivilTAlk logo'}
              width={'20rem'}
              height={'6rem'}
            />
          </LazyLoad>

          {/* <DetailsHeader>Mastercard</DetailsHeader> */}
        </SponsoredByCivilTalkContainer>

        <ButtonsContainer>
          <StyledButton
            variant="contained"
            component="a"
            target={'_blank'}
            rel="noreferrer"
            color="secondary"
            href={CIVILTALK_LINK}
            // href={MASTERCARD_LINK}
          >
            View Podcasts
          </StyledButton>
        </ButtonsContainer>
      </MainContainer>
    </>
  )
}

export default SponsoredBy
