import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getAccount } from '../../../redux/thunks/stripe/get-account'
import Context from './context/context'

// Props:
// children --> (Child JSX Elements)
// organizationId --> <String>: Organization ID from which to load Stripe account
// enableAsync --> <Boolean>: If set to true, children will be loaded immediately
// hideOnNoResults --> <Boolean>: If set to true, children will be hidden if no data is returned
const LoadOrganizationStripeAccount = (props) => {
  const { children, organizationId, enableAsync, hideOnNoResults } = props
  const [loadingInitialized, setLoadingInitialized] = useState(false)
  const [displayChildren, setDisplayChildren] = useState(false)
  const [stripeAccount, setStripeAccount] = useState({})
  const dispatch = useDispatch()

  useEffect(() => {
    if (!loadingInitialized) {
      setLoadingInitialized(true)

      console.log('[DEBUG]: Loading organization Stripe account...')

      dispatch(getAccount({ organizationId }))
        .then((data) => {
          if (data.error) {
            throw new Error(
              'Error retrieving organization Stripe account <LoadOrganizationStripeAccount>'
            )
          }

          setStripeAccount(data.account)
          setDisplayChildren(true)

          console.log('[SUCCESS]: Successfully retrieved organization Stripe account.')
        })
        .catch((e) => {
          console.log('[ERROR]: ', e)
        })
    }
  }, [dispatch, loadingInitialized, organizationId])

  // If asynchronous loading is disabled and data isn't finished loading, return null
  if (!enableAsync && !displayChildren) return null
  // If asynchronous loading is disabled, hideOnNoResults is 'true', and no data is returned, return null
  if (!enableAsync && hideOnNoResults && Object.keys(stripeAccount).length === 0) return null

  return <Context.Provider value={{ stripeAccount }}>{children}</Context.Provider>
}

export default LoadOrganizationStripeAccount
