import styled from 'styled-components'
// import config from '../../../../../../../config.js'

export const LinksContainer = styled.nav`
  display: flex;

  & a:not(:last-child) {
    margin-right: 1rem;
  }

  & > a:link,
  & > a:visited,
  & > a:active {
    outline: none;
    text-decoration: none;
  }
`
