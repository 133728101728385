import styled from 'styled-components'
import theme from '../../../../res/theme.js'
// import config from '../../../../config.js'

export const MainContainer = styled.div`
  background-color: white;
  display: block;
  padding: 1.7rem 2rem 2rem 2rem;
  margin-bottom: 1rem;
  border-radius: 0.3rem;
  box-shadow: 0px 5px 5px -2px rgba(0, 0, 0, 0.2);
`

export const HeadingPrimary = styled.p`
  line-height: 2.2rem;
  font-family: ${theme.typography.fontFamily};
  font-size: 1.6rem;
  font-weight: ${theme.typography.fontWeight.bold};
  color: ${theme.typography.fontColor.bodyCopy};

  & > span {
    color: ${({theme}) => theme.palette.secondary.main};
  }
`

export const PollContainer = styled.div`
  margin-top: .8rem;
`

export const Subheading = styled.p`
  font-family: ${theme.typography.fontFamily};
  font-size: ${theme.typography.fontSizes.regular};
  font-weight: ${theme.typography.fontWeight.medium};
  color: ${theme.typography.fontColor.headerCopy};
`

export const VotingContainer = styled.div`
  display: table;
  margin-top: 1rem;
  width: 90%;
  font-size: 0;

  & > div:nth-child(1) {
    background-color: #eee;
    border-top-left-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem;

    &:hover {
      background-color: #f3f3f3;
    }
  }

  & > div:nth-child(2) {
    background-color: #e0e0e0;
    border-top-right-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;

    &:hover {
      background-color: #e4e4e4;
    }
  }
`

export const OptionContainer = styled.div`
  display: table-cell;
  width: 50%;
  height: 100%;
  padding: 1rem 0;
  vertical-align: middle;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;

  & > p:nth-child(1) {
    text-align: center;
    font-family: ${theme.typography.fontFamily};
    font-size: ${theme.typography.fontSizes.regular};
    font-weight: ${theme.typography.fontWeight.medium};
    color: ${({theme}) => theme.palette.secondary.main};
  }

  & > p:nth-child(2) {
    text-align: center;
    font-family: ${theme.typography.fontFamily};
    font-size: 1.6rem;
    font-weight: ${theme.typography.fontWeight.medium};
  }
`
