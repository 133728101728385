import config from '../../../config'
import { authenticatedFetch } from '../../../utils/functions/authenticatedFetch'

/*
 * METHOD TO GET ALL PROMPTS FOR A COURSE
 */
export const getPromptsByCourseId = (params) => {
  let responseCode = ''
  const method = 'GET'
  const url = `${config.baseURL}/api/prompts/${params.courseId}`
  const headers = {
    'Content-Type': 'application/json'
  }

  // Thunk middleware knows how to handle functions.
  // It passes the dispatch method as an argument to the function,
  // thus making it able to dispatch actions itself.
  return (dispatch) => {
    console.log('[FETCH]: Sending get prompts by courseId request to CivilTalk')

    // Return the fetch so react components calling 'store.dispatch()' can use 'then()'
    return authenticatedFetch(url, {
      method,
      headers,
      credentials: 'include'
    })
      .then((response) => {
        responseCode = response.status

        return response.json()
      })
      .then((data) => {
        if (responseCode === 200) {
          console.log('[SUCCESS]: Prompts retrieved successfully')
          return {
            error: false,
            code: responseCode,
            message: data.message,
            prompts: data.prompts
          }
        } else {
          console.error('[ERROR]: Failed to retrieve prompts by course id', data)

          return {
            error: true,
            code: responseCode,
            message: data.message
          }
        }
      })
      .catch((error) => {
        console.error('[ERROR]: caught while retrieving prompts by course id', error)

        return {
          error: true,
          code: '',
          message: error
        }
      })
  }
}
