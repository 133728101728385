import moment from 'moment'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import TextField from '@mui/material/TextField'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { InputLabel } from '@mui/material'
import styled from '@mui/material/styles/styled'

function PublishDatePicker({ publishDate, setPublishDate }) {
  const handleChange = (newDate) => {
    console.log(newDate)
    setPublishDate(newDate)
  }

  return (
    <div className="form-group">
      <InputLabel
        htmlFor="date-time-picker"
        color={'primary'}
        sx={{
          color: 'text.primary',
          fontSize: '1rem',
          fontWeight: 600,
          marginBottom: '1rem'
        }}>
        Choose a date and time to publish your prompt:
      </InputLabel>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DateTimePicker
          id="date-time-picker"
          label="Publish on date"
          value={publishDate}
          onChange={handleChange}
          sx={{
            '& .MuiInputLabel-root': {
              color: 'text.primary'
            }
          }}
          renderInput={(params) => (
            <MuiTextField id="date-time-picker" color="secondary" variant="filled" {...params} />
          )}
        />
      </LocalizationProvider>
    </div>
  )
}

export default PublishDatePicker

// ***************************** //
// *** MUI Styled Components *** //
// ***************************** //
const MuiTextField = styled(TextField)(({ theme }) => ({
  background: theme.palette.background.gray,
  color: theme.palette.text.primary,
  minWidth: '18rem',
  // minWidth:
  '& label': {
    color: theme.palette.text.primary
  },
  '& label.Mui-focused': {
    color: theme.palette.secondary.main
  },
  '& .MuiInput-underline:after': {
    // borderBottomColor: '#B2BAC2'
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      // borderColor: '#E0E3E7'
    },
    '&:hover fieldset': {
      // borderColor: '#B2BAC2'
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.secondary.main
    }
  },
  '& .MuiFormHelperText-root': {
    color: theme.palette.text.primary,
    padding: '0.15rem'
  }
}))
