//////////////////
// ACTION TYPES //
//////////////////

export const videoActionTypes = {
  SET_VIDEOS: 'SET_VIDEOS',
  CLEAR_VIDEOS: 'CLEAR_VIDEOS'
}

/////////////
// ACTIONS //
/////////////

// VIDEOS
export const set = (videos) => {
  return {
    type: videoActionTypes.SET_VIDEOS,
    videos
  }
}

export const clear = () => {
  return {
    type: videoActionTypes.CLEAR_VIDEOS
  }
}

export const videosActions = {
  set,
  clear
}
