import React, { useState } from 'react'
import Selection from './components/selection/selection'
import Confirmation from './components/confirmation/confirmation'
import Complete from './components/complete/complete'

function Transfer(props) {
  const { userId, takeId } = props
  const [step, setStep] = useState('selection')
  const [tokens, setTokens] = useState('')

  return (
    <>
      {step === 'selection' && (
        <Selection setStep={setStep} tokens={tokens} setTokens={setTokens} userId={userId} />
      )}

      {step === 'confirmation' && (
        <Confirmation setStep={setStep} tokens={tokens} userId={userId} takeId={takeId} />
      )}

      {step === 'complete' && <Complete />}
    </>
  )
}

export default Transfer
