import { beginRefresh } from '../thunks/refresh-promise/beginRefresh.js'

/**
 * Checks if token expiration has passed in order to send request to refresh token.
 */
export const jwtRefresh = ({ dispatch, getState }) => {
  return (next) => (action) => {
    // Only refresh if not currently refreshing token and if user is logged in
    if (getState().tokenExp !== 0) {
      // Only worry about refreshing token before async actions
      if (typeof action === 'function') {
        const tokenExpMilli = getState().tokenExp * 1000 - 60000 // - 60000 is to give ~ one minute of buffer to ensure no errors
        const refreshDate = Date.now()
        const tokenHasExpired = tokenExpMilli - refreshDate < 0

        if (tokenHasExpired) {
          console.log('***  ACCESS_TOKEN HAS EXPIRED ***')
          // Make sure we are not already refreshing the token
          if (!getState().refreshPromise) {
            return beginRefresh(dispatch).then(() => next(action))
          }
        }
      }
    }

    return next(action)
  }
}
