import Validator from 'validator'

const UpdateDonationLinkValidation = (donationLink) => {
  if (!donationLink) {
    return { error: 'Donation link is required.' }
  } else if (!Validator.isURL(donationLink)) {
    return { error: 'Please enter a valid URL.' }
  }

  return { error: '' }
}

export default UpdateDonationLinkValidation
