import styled from 'styled-components'
import config from '../../../../../../config.js'

export const MainContainer = styled.div`
  background-color: ${(props) => props.theme.palette.secondary.main}; // browser fallback
  background-image: url('https://res.cloudinary.com/headliner/image/upload/v1648741416/Images%20for%20Homepage%20and%20Learn%20More/people-cropped.jpg'); // browser fallback for non-stacking
  background-image: linear-gradient(90deg, rgba(51, 51, 51, 0.8) 0%, rgba(51, 51, 51, 0.8) 100%),
    url('https://res.cloudinary.com/headliner/image/upload/v1648741416/Images%20for%20Homepage%20and%20Learn%20More/people-cropped.jpg');
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: inline-block;
  width: 23rem;
  min-height: 45rem;
  padding: 3rem 2rem;
  margin-right: 0.5rem;
  vertical-align: top;
  border-radius: 0.3rem;
  box-sizing: border-box;
  position: relative;

  @media (max-width: ${config.UI.drawer_breakpoint.max}) {
    display: none;
  }
`

export const Header = styled.h1`
  margin-bottom: 3rem;
  line-height: 2.2rem;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: 2.2rem;
  font-weight: ${(props) => props.theme.typography.fontWeight.semiBold};
  color: ${(props) => props.theme.palette.text.secondary};
`

export const Step = styled.p`
  background-color: ${(props) => (props.disabled ? '#eee' : props.theme.palette.secondary.light)};
  width: ${({ current }) => (current ? '13rem' : '11rem')};
  border-radius: 0.3rem;
  color: ${(props) => (props.disabled ? '#999' : props.theme.palette.text.primary)};
  text-align: center;
  font-family: ${(props) => props.theme.typography.fontFamily};
  // font-size: ${(props) => props.theme.typography.fontSizes.tall};
  font-size: ${(props) =>
    props.current
      ? props.theme.typography.fontSizes.larger
      : props.theme.typography.fontSizes.tall};
  font-weight: ${(props) =>
    props.current
      ? props.theme.typography.fontWeight.bold
      : props.theme.typography.fontWeight.medium};
  padding: 0.8rem 2rem;
  margin-bottom: 1rem;
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  transition: width 0.3s ease-in-out;
`
