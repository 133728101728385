import styled from 'styled-components'
import config from '../../../../config.js'

export const OuterContainer = styled.div`
  display: block;
  max-width: 80rem;
  margin: ${config.UI.main.marginTop} auto 4rem auto;
  font-size: 0;
  padding: 0 1rem 1rem 1rem;
  box-sizing: border-box;

  /** gutters for tablet view */
  @media (max-width: ${config.UI.drawer_breakpoint.max}) {
    padding: 1rem;
    margin: 0 auto 4rem auto;
  }

  /** mobile view */
  @media (max-width: 600px) {
    margin: 0.5rem auto 2rem auto;
  }
`
