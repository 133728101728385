const load = (setQuote, setIsLoading, actions) => {
  const _getRandomQuote = () => {
    return actions.getRandomQuote()
  }

  return _getRandomQuote()
    .then((data1) => {
      if (data1.error) {
        console.log("Failed to retrieve random quote.")
      } else {
        console.log("[DEBUG]: ", "Successfully retrieved random quote.")

        setQuote(data1.quote)
      }

      setIsLoading(false)
    })
    .catch((error) => {
      console.log("[ERROR]: ", error)
    })
}

export default load
