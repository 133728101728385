export default function ratioReducer(state, action) {
  switch (action.type) {
    case 'square':
      return { ratio: 1 / 1 }
    case 'portrait':
      return { ratio: 4 / 5 }
    case 'landscape':
      return { ratio: 16 / 9 }
    default:
      return { ratio: 1 / 1 }
  }
}
