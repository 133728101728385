import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import _ from 'lodash'
import moment from 'moment'
import config from '../../../config'
import { history } from '../../../history/history'
import { getRebuttals } from '../../../redux/thunks/rebuttals/getRebuttals'
import getAvatar from '../../../utils/functions/getAvatar'
// import getName from '../../../utils/functions/getName'
import CollapsedContent from '../../partials/collapsed-content/collapsed-content'
import CreateRebuttalForm from './components/create-rebuttal-form'
import CreateRebuttalFormHooks from './components/create-rebuttal-form-hooks'
// import EditRebuttalForm from './components/edit-rebuttal-form'
import EditRebuttalForm from './components/edit-rebuttal-form-hooks'
import ActionBar from './components/action-bar'
import PropTypes from 'prop-types'
import withStyles from '@mui/styles/withStyles'
import { Button, CircularProgress } from '@mui/material'
import EditIcon from '@mui/icons-material/Create'
import { colorForEmotion } from '../../../components/partials/emotion-picker/emotion-picker'
import {
  OuterContainer,
  InnerContainer,
  RebuttalUserImage,
  // RebuttalName,
  RebuttalWrapper,
  Rebuttal,
  RebuttalDate,
  LoadMore,
  LoadingMore,
  StatusMessage,
  EmotionCardsContainer,
  EmotionMessage,
  EmotionCard
} from './components/styled/discussion-comps'

const styles = () => ({
  editButton: {
    backgroundColor: '#ddd',
    color: '#333',
    minWidth: '1.4rem',
    width: '1.4rem',
    minHeight: '1.4rem',
    height: '1.4rem',
    cursor: 'pointer',
    borderRadius: '50%',
    position: 'absolute',
    top: '0rem',
    '& svg': {
      marginTop: '-2px',
      color: '#333',
      fontSize: '.8rem'
    },
    '& *': {
      pointerEvents: 'none'
    },
    '&:hover': {
      backgroundColor: '#ccc'
    }
  },
  loadingMore: {
    display: 'inline-block',
    color: '#ffffff'
  }
})

class Discussion extends Component {
  constructor(props) {
    super(props)

    this.state = {
      status: {
        isEditingRebuttal: false,
        loadMore: false,
        isLoadingMore: false
      },
      editedRebuttalId: '',
      error: ''
    }

    this.handleSetState = this.handleSetState.bind(this)
    this.handleEdit = this.handleEdit.bind(this)
    this.handleViewProfile = this.handleViewProfile.bind(this)
    this.handleLoadMoreRebuttals = this.handleLoadMoreRebuttals.bind(this)
  }

  componentDidMount() {
    const { loadedRebuttals, discussion } = this.props
    const rebuttals = _.filter(loadedRebuttals, (rebuttal) => rebuttal.debateId === discussion._id)
    const loadMore = rebuttals.length === config.loadLimits.rebuttals

    this.setState({
      ...this.state,
      status: {
        ...this.state.status,
        loadMore
      }
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.discussion._id !== this.props.discussion._id) {
      const { loadedRebuttals, discussion } = this.props
      const rebuttals = _.filter(
        loadedRebuttals,
        (rebuttal) => rebuttal.debateId === discussion._id
      )
      const loadMore = rebuttals.length === config.loadLimits.rebuttals

      this.setState({
        ...this.state,
        status: {
          ...this.state.status,
          loadMore
        }
      })
    }
  }

  handleSetState(object) {
    this.setState(object)
  }

  handleEdit(event) {
    this.setState({
      ...this.state,
      status: {
        ...this.state.status,
        isEditingRebuttal: true
      },
      editedRebuttalId: event.target.id,
      error: ''
    })
  }

  handleViewProfile(event) {
    // if (event.target.id === this.props.currentUser.id) {
    //   ReactGA.event({
    //     category: 'Own-Profile-Button',
    //     action: 'Visited Own Profile From Discussion'
    //   })
    // }

    history.push('/profile/' + event.target.id)
  }

  handleLoadMoreRebuttals() {
    const { discussion, loadedRebuttals } = this.props
    const { loadLimits } = config
    const rebuttals = _.filter(loadedRebuttals, (rebuttal) => rebuttal.debateId === discussion._id)

    this.setState({
      ...this.state,
      status: {
        ...this.state.status,
        isLoadingMore: true
      }
    })

    this.props
      .getRebuttals({
        debateIds: [discussion._id],
        limit: loadLimits.rebuttals,
        afterDate: rebuttals[0].whenCreated
      })
      .then((data) => {
        if (!data.error) {
          console.log('[SUCCESS]: ', 'Successfully loaded earlier rebuttals!')
        } else {
          console.log('[FAIL]: ', 'Failed to load earlier rebuttals.')
        }

        this.setState({
          ...this.state,
          status: {
            ...this.state.status,
            loadMore: data.rebuttals.length === config.loadLimits.rebuttals,
            isLoadingMore: false
          }
        })
      })
  }

  render() {
    const {
      classes,
      currentProfile,
      currentUser,
      discussion,
      loadedProfiles,
      loadedRebuttals,
      style,
      mode
    } = this.props
    const { status, editedRebuttalId } = this.state
    const {
      _id,
      hostUserId,
      guestUserId,
      complete,
      accepted,
      rejected,
      isRequest,
      isCounter,
      lastRebuttalUserId
    } = discussion
    const rebuttals = _.filter(loadedRebuttals, (rebuttal) => rebuttal.debateId === _id)
    const lastRebuttal = rebuttals[rebuttals.length - 1]

    // Check if it is current user's turn
    const isUsersTurn = lastRebuttalUserId !== currentUser.id

    // Check if user is part of discussion
    const isPartOfDiscussion = hostUserId === currentUser.id || guestUserId === currentUser.id

    const isOngoing = !complete && accepted
    const isOutgoingRequest = guestUserId === currentUser.id && isRequest
    const isOutgoingCounter = hostUserId === currentUser.id && isCounter

    // Check if user is allowed to respond
    const userCanRespond = isPartOfDiscussion && isUsersTurn && isOngoing

    // Create rebuttal components from each rebuttal
    const rebuttalItems = rebuttals.map((rebuttal) => {
      // Primary user will either be the current user, or if not a part of discussion the host user
      const isCurrentUser = rebuttal.userId === currentUser.id
      const isHostUser = rebuttal.userId === hostUserId
      const isPrimaryUser = isCurrentUser || (!isPartOfDiscussion && isHostUser)
      const rebuttalProfile = isCurrentUser ? currentProfile : loadedProfiles[rebuttal.userId]
      const { documentVerified } = rebuttalProfile
      const verified = documentVerified ? 1 : 0

      if (status.isEditingRebuttal && rebuttal._id === editedRebuttalId) {
        return (
          <EditRebuttalForm
            key={rebuttal._id}
            originalRebuttal={rebuttal}
            state={this.state}
            setState={this.handleSetState}
          />
        )
      }

      return (
        <OuterContainer
          key={rebuttal._id}
          marginTop={0}
          textAlign={isPrimaryUser ? 'right' : 'left'}>
          <InnerContainer>
            <RebuttalUserImage
              id={rebuttal.userId}
              onClick={this.handleViewProfile}
              float={isPrimaryUser ? 'right' : 'left'}
              size={'2.35rem'}
              margin={isPrimaryUser ? '.5rem 0 0 .8rem' : '.5rem .8rem 0 0'}
              style={{
                backgroundImage: `url(${getAvatar(rebuttalProfile.avatar)})`
              }}
              verified={verified}
            />

            {/* <RebuttalName id={rebuttal.userId} onClick={this.handleViewProfile}>
            {getName(rebuttalProfile)}
          </RebuttalName> */}

            <RebuttalWrapper>
              <Rebuttal float={isPrimaryUser ? 'right' : 'left'} mode={mode}>
                <CollapsedContent
                  linkify={true}
                  content={rebuttal.rebuttal.trim()}
                  color={mode === 'light' ? '#333' : '#ffffff'}
                />

                <RebuttalDate position={isPrimaryUser ? 'right' : 'left'} mode={mode}>
                  {moment(rebuttal.whenCreated).fromNow(true)}
                </RebuttalDate>
              </Rebuttal>

              {isCurrentUser &&
                lastRebuttal._id === rebuttal._id &&
                !status.isEditingRebuttal &&
                (isOngoing || isOutgoingRequest) && (
                  <Button
                    id={rebuttal._id}
                    className={classes.editButton}
                    onClick={this.handleEdit}
                    style={isPrimaryUser ? { left: '0' } : { right: '0' }}>
                    <EditIcon />
                  </Button>
                )}
            </RebuttalWrapper>
            {rebuttal.emotion && (
              <EmotionCardsContainer>
                <EmotionMessage>with </EmotionMessage>
                <EmotionCard bgColor={colorForEmotion(rebuttal.emotion)}>
                  {rebuttal.emotion}
                </EmotionCard>
              </EmotionCardsContainer>
            )}
          </InnerContainer>
        </OuterContainer>
      )
    })

    return (
      <div style={style || {}}>
        {status.loadMore && !status.isLoadingMore && (
          <LoadMore mode={mode} onClick={this.handleLoadMoreRebuttals}>
            Load more messages
          </LoadMore>
        )}
        {status.isLoadingMore && (
          <LoadingMore>
            <CircularProgress
              variant="indeterminate"
              className={classes.loadingMore}
              thickness={4}
              size={'2rem'}
              style={mode === 'light' ? { color: '#2eb2fa' } : { color: '#fff' }}
            />
          </LoadingMore>
        )}
        {rebuttalItems}
        {complete && <StatusMessage mode={mode}>This discussion has ended</StatusMessage>}
        {rejected && isPartOfDiscussion && (
          <StatusMessage mode={mode}>This request was declined</StatusMessage>
        )}
        {isOutgoingRequest && isPartOfDiscussion && (
          <StatusMessage mode={mode}>This discussion request is pending</StatusMessage>
        )}
        {isOutgoingCounter && isPartOfDiscussion && (
          <StatusMessage mode={mode}>This counter request is pending</StatusMessage>
        )}

        {/* userCanRespond && (
          <CreateRebuttalForm
            discussion={discussion}
            state={this.state}
            setState={this.handleSetState}
          />
        ) */}
        {userCanRespond && <CreateRebuttalFormHooks discussion={discussion} />}
        <ActionBar discussion={discussion} mode={mode} />
      </div>
    )
  }
}

Discussion.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  currentProfile: state.currentProfile,
  currentUser: state.currentUser,
  loadedProfiles: state.loadedProfiles,
  loadedRebuttals: state.loadedRebuttals
})

const mapDispatchToProps = (dispatch) => ({
  getRebuttals: (params) => {
    return dispatch(getRebuttals(params))
  }
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Discussion))
)
