import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import './loader.css'

class Loader extends Component {
  render() {
    if (this.props.loader) {
      return (
        <div className="container">
          <div className="lds-facebook">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      )
    } else {
      return null
    }
  }
}

const mapStateToProps = state => ({
  loader: state.loader
})

export default withRouter(connect(mapStateToProps)(Loader))
