function getOrgTypeString(type) {
  switch (type) {
    case 'sole-proprietorship':
      return 'Sole Proprietorship'
    case 'partnership':
      return 'Partnership'
    case 'limited-liability-company':
      return 'Limited Liability Company'
    case 'international-business':
      return 'International Business'
    case 'corporation':
      return 'Corporation'
    case 's-corporation':
      return 'S Corporation'
    case 'b-corporation':
      return 'B Corporation'
    case 'charitable-organization':
      return 'Charitable Organization — IRC 501(c)(3)'
    case 'church-or-religious':
      return 'Church or Religious Organization — IRC 501(c)(3)'
    case 'private-foundation':
      return 'Private Foundation — IRC 501(c)(3)'
    case 'political-organization':
      return 'Political Organization'
    case 'other-nonprofit':
      return 'Other Nonprofit'
    case 'federal':
      return 'Federal, State or Local Government'
    case 'indian-tribal':
      return 'Indian Tribal Government'
    default:
      return ''
  }
}

export default getOrgTypeString
