import { deleteFollowByOrganizationID } from '../../../../../../redux/thunks/follows/deleteFollowByOrganizationID.js'

const unfollowOrganization = (organization, currentUser, setProcessing, dispatch) => {
  if (!organization) {
    throw new Error('Invalid params <organization> in unfollowOrganization')
  }

  if (!setProcessing) {
    throw new Error('Invalid params <setProcessing> in unfollowOrganization')
  }

  if (!dispatch) {
    throw new Error('Invalid params <dispatch> in unfollowOrganization')
  }

  setProcessing(true)

  dispatch(
    deleteFollowByOrganizationID({
      organizationId: organization._id,
      currUserId: currentUser.id,
      name: organization.publicName
    })
  )
    .then((data) => {
      if (!data.error) {
        console.log('[SUCCESS]: ', 'Successfully unfollowed organization!')
      } else {
        console.log('[FAIL]: ', 'Failed to unfollow organization.')
      }

      setProcessing(false)
    })
    .catch((e) => {
      setProcessing(false)
    })
}

export default unfollowOrganization
