import styled from 'styled-components'
import config from '../../../../config'

export const MainContainer = styled.div`
  background-color: ${(props) => props.theme.palette.common.white};
  display: block;
  margin-bottom: 1rem;
  padding: 1.7rem 2rem 2rem 2rem;
  box-sizing: border-box;
  border: 1px solid #dcdddd;
  border-radius: 0.3rem;
  box-shadow: 0px 7px 5px -2px rgb(100 100 100 / 20%);
  position: relative;
`

export const HeadingPrimary = styled.p`
  line-height: 2.2rem;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: 1.6rem;
  font-weight: ${(props) => props.theme.typography.fontWeight.bold};
  color: ${(props) => props.theme.palette.text.primary};
  margin-bottom: ${(props) => props.marginBottom || '0'};
  > span {
    color: ${(props) => props.theme.palette.secondary.main};
    border-radius: 0.3rem;
  }
`

export const SponsoredByContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 1.5rem 0;

  & img {
    object-fit: contain;
  }
`

export const SponsoredByCivilTalkContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem 0;

  & img {
    object-fit: contain;
  }
`

export const DetailsHeader = styled.p`
  margin-top: 0;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: 1.8rem;
  font-weight: ${(props) => props.theme.typography.fontWeight.medium};
  color: ${(props) => props.theme.palette.text.primary};

  @media screen and (max-width: ${config.UI.smallMobile_breakpoint.max}) {
    font-size: ${(props) => props.theme.typography.fontSizes.extraLarge};
  }
`

export const CopyContainer = styled.div`
  display: block;
`

export const Copy = styled.p`
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.medium};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  color: ${(props) => props.theme.palette.text.primary};
`

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Image = styled.img`
  background-size: cover; /** covers IE support */
  object-fit: cover;
  width: ${(props) => props.width};
  height: ${(props) => props.height};

  @media screen and (max-width: ${config.UI.smallMobile_breakpoint.max}) {
    width: 5rem;
    height: 5rem;
  }
`

export const CivilTalkImage = styled.img`
  background-size: cover; /** covers IE support */
  object-fit: cover;
  width: ${(props) => props.width};
  height: ${(props) => props.height};

  @media screen and (max-width: ${config.UI.smallMobile_breakpoint.max}) {
    width: 14rem;
    height: 5rem;
  }
`
