// *** ACTION TYPES *** //
export const assignedRolesActionTypes = {
  SET_ASSIGNED_ROLES: 'SET_ASSIGNED_ROLES',
  CLEAR_ASSIGNED_ROLES: 'CLEAR_ASSIGNED_ROLES'
}

/* ASSIGNED ROLES ACTIONS */
const set = (assignedRoles) => {
  return {
    type: assignedRolesActionTypes.SET_ASSIGNED_ROLES,
    assignedRoles
  }
}

const clear = () => {
  return {
    type: assignedRolesActionTypes.CLEAR_ASSIGNED_ROLES
  }
}

// ************************** //
export const assignedRolesActions = {
  set,
  clear
}
