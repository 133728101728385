/** Utils */
import getAvatar from '../../../../utils/functions/getAvatar'
import getName from '../../../../utils/functions/getName'
/** Material-ui components */
import ListItem from '@mui/material/ListItem'
import Avatar from '@mui/material/Avatar'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import EmailIcon from '@mui/icons-material/Email'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import { styled } from '@mui/material/styles'
import {
  // ProfileContainer,
  // ProfilePicture,
  ProfileInfoContainer
  // ProfileName
} from '../styled/course-setup-comps'

// member component
function CourseMemberCard({ id, member, label, isButton, buttonType, clickHandler }) {
  return (
    <MuiListItem divider id={id} key={id}>
      <MuiAvatar
        alt={getName(member)}
        src={getAvatar(member.avatar)}
        verified={member.documentVerified}
      />

      <Stack
        direction="column"
        spacing={1}
        sx={{ justifyContent: 'space-around', padding: '0.25rem' }}>
        <Typography
          id={member._id}
          sx={{
            fontSize: '1.05rem',
            fontWeight: 500
          }}>
          {getName(member)}
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
          <EmailIcon sx={{ marginRight: '0.25rem' }} />
          <Typography
            id={member._id}
            sx={{
              fontSize: '1rem'
            }}>
            {member.email}
          </Typography>
        </Box>
        <Chip
          label={label}
          color="secondary"
          sx={{
            width: '8rem'
          }}
        />
      </Stack>
      {isButton && buttonType === 'add' && (
        <Box
          sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%' }}>
          <MuiAddButton variant="contained" onClick={() => clickHandler(id)}>
            <AddCircleOutlineIcon fontSize="medium" sx={{ marginRight: '0.25rem' }} />
            Add
          </MuiAddButton>
        </Box>
      )}
      {isButton && buttonType === 'remove' && (
        <Box
          sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%' }}>
          <MuiAddButton variant="contained" onClick={() => clickHandler(id)}>
            <RemoveCircleOutlineIcon fontSize="medium" sx={{ marginRight: '0.25rem' }} />
            Remove
          </MuiAddButton>
        </Box>
      )}
    </MuiListItem>
  )
}

export default CourseMemberCard

// ***************************** //
// *** MUI Styled Components *** //
// ***************************** //

const MuiListItem = styled(ListItem)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  display: 'flex',
  justifyContent: 'flex-start'
}))

const MuiAvatar = styled(Avatar)<{ verified?: boolean }>(({ theme, verified }) => ({
  border: `2.5px solid`,
  borderColor: verified ? theme.palette.primary.main : theme.palette.grey[300],
  margin: '0 1rem 0 0.25rem',
  width: '3.5rem',
  height: '3.5rem'
}))

const MuiAddButton = styled(Button)(({ theme }) => ({
  // borderRadius: '0.25rem'
}))
