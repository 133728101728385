import React from 'react'
import TokenIcon from '../../../../../media/icons/svg/token-white.svg'
import MuiBadge from '@mui/material/Badge'
import MuiMoreHoriz from '@mui/icons-material/MoreHoriz'
import MuiPersonAdd from '@mui/icons-material/PersonAdd'
import MuiForum from '@mui/icons-material/Forum'
import MuiVoteIcon from '@mui/icons-material/ThumbUpAlt'
import MuiTakeIcon from '@mui/icons-material/EmojiObjects'
import MuiQuestionIcon from '@mui/icons-material/ContactSupport'
import MuiAssignedRoleIcon from '@mui/icons-material/AssignmentInd'
import MuiPurchaseIcon from '@mui/icons-material/ShoppingCart'
import MuiDonationIcon from '@mui/icons-material/VolunteerActivism'
import MuiAwardIcon from '@mui/icons-material/CardGiftcard'
import MuiSettings from '@mui/icons-material/Settings'
import MuiMarkunreadMailbox from '@mui/icons-material/MarkunreadMailbox'
import withStyles from '@mui/styles/withStyles'

export const Badge = withStyles((theme) => ({
  badge: {
    position: 'absolute',
    right: '1.75rem',
    background: theme.palette.secondary.main,
    border: `2px solid ${theme.palette.secondary.main}`,
    width: '1rem',
    height: '1rem',
    padding: '0.9rem',
    borderRadius: '50%',
    [theme.breakpoints.down('md')]: {
      width: '0.5rem',
      height: '0.5rem',
      padding: '0.75rem',
      right: '1.25rem'
    }
  }
}))(MuiBadge)

export const MoreHoriz = withStyles((theme) => ({
  root: {
    fill: theme.palette.text.primary
  }
}))(MuiMoreHoriz)

export const PersonAdd = withStyles((theme) => ({
  root: {
    fill: theme.palette.common.white,
    fontSize: '1.15rem'
  }
}))(MuiPersonAdd)

export const Forum = withStyles((theme) => ({
  root: {
    fill: theme.palette.common.white,
    fontSize: '1.15rem'
  }
}))(MuiForum)

export const VoteIcon = withStyles((theme) => ({
  root: {
    fill: theme.palette.common.white,
    fontSize: '1.15rem'
  }
}))(MuiVoteIcon)

export const TakeIcon = withStyles((theme) => ({
  root: {
    fill: theme.palette.common.white,
    fontSize: '1.15rem'
  }
}))(MuiTakeIcon)

export const QuestionIcon = withStyles((theme) => ({
  root: {
    fill: theme.palette.common.white,
    fontSize: '1.15rem'
  }
}))(MuiQuestionIcon)

export const AssignedRoleIcon = withStyles((theme) => ({
  root: {
    fill: theme.palette.common.white,
    fontSize: '1.15rem'
  }
}))(MuiAssignedRoleIcon)

export const PurchaseIcon = withStyles((theme) => ({
  root: {
    fill: theme.palette.common.white,
    fontSize: '1.15rem'
  }
}))(MuiPurchaseIcon)

export const DonationIcon = withStyles((theme) => ({
  root: {
    fill: theme.palette.common.white,
    fontSize: '1.15rem'
  }
}))(MuiDonationIcon)

export const AwardIcon = withStyles((theme) => ({
  root: {
    fill: theme.palette.common.white,
    fontSize: '1.15rem'
  }
}))(MuiAwardIcon)

export const TokenTransactionIcon = () => {
  return (
    <img
      src={TokenIcon}
      alt="Token Icon"
      style={{
        height: '1rem'
      }}
    />
  )
}

export const Settings = withStyles((theme) => ({
  root: {
    fill: theme.palette.common.white,
    fontSize: '1.15rem'
  }
}))(MuiSettings)

export const MarkunreadMailbox = withStyles((theme) => ({
  root: {
    display: 'inline-block',
    verticalAlign: 'middle',
    fontSize: '1.1rem',
    margin: '.2rem .7rem 0 0'
  }
}))(MuiMarkunreadMailbox)
