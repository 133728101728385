import config from '../../../config'
// import { organizationsActions } from '../../action-creators/organizations'
import { actionResponseActions } from '../../action-creators/action-response.js'
import { loaderActions } from '../../action-creators/loader.js'
import { authenticatedFetch } from '../../../utils/functions/authenticatedFetch'

/**
 * METHOD TO UPDATE PROMPT BY PROMPT ID
 *
 * @param {object} params - an object containing two keys
 * {
 *    promptId: {string}
 *    fieldsToUpdate: {object}
 *  }
 *
 * @return {object} - an object containing {error, code, message}
 */

export const updatePromptById = (params) => {
  let responseCode = ''
  const method = 'PUT'
  const url = `${config.baseURL}/api/prompts/${params.promptId}`
  const headers = {
    'Content-Type': 'application/json'
  }
  const requestBody = JSON.stringify(params.fieldsToUpdate)

  return (dispatch) => {
    dispatch(loaderActions.startLoader())
    console.log('[FETCH]: Sending update prompt request to CivilTalk')

    return authenticatedFetch(url, {
      method,
      headers,
      body: requestBody,
      credentials: 'include'
    })
      .then((response) => {
        dispatch(loaderActions.stopLoader())
        responseCode = response.status

        return response.json()
      })
      .then((data) => {
        if (responseCode === 200) {
          console.log(`[SUCCESS]: Successfully updated prompt with id ${params.promptId}`, data)

          dispatch(actionResponseActions.createActionResponse('Successfully updated prompt!'))

          return {
            error: false,
            code: responseCode,
            message: data.message,
            data: data
          }
        } else {
          console.error(`[ERROR]: Failed to update prompt with id ${params.promptId}`, data)

          return {
            error: true,
            code: responseCode,
            message: data.message
          }
        }
      })
      .catch((error) => {
        console.error(`[ERROR]: caught while updating prompt with id ${params.promptId}`, data)

        dispatch(loaderActions.stopLoader())

        return {
          error: true,
          code: '',
          message: error.message
        }
      })
  }
}
