import styled from 'styled-components'

function SkipLink() {
  return <StyledAnchor href="#main-content">Skip to main content</StyledAnchor>
}

export default SkipLink

// **************************** //

const StyledAnchor = styled.a`
  position: absolute;
  top: -40px;
  left: 0;
  background: #000;
  color: #fff;
  padding: 8px;
  z-index: 100;
  text-decoration: none;

  &:focus {
    top: 0;
  }
`
