import React, { useRef, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'
import { history } from '../../../history/history'
import getAvatar from '../../../utils/functions/getAvatar'
import getName from '../../../utils/functions/getName'
import { createUserFollow } from '../../../redux/thunks/follows/createUserFollow'
import { deleteFollowByUserID } from '../../../redux/thunks/follows/deleteFollowByUserID'
import isVerifiedUser from '../../../utils/functions/isVerifiedUser'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import { Button, CircularProgress } from '@mui/material'
import {
  MainContainer,
  InnerContainer,
  LeftContainer,
  UserImage,
  IconContainer,
  UserName,
  ContentContainer,
  Header,
  Subheader,
  ButtonContainer
} from './styled/connect-with-comps'
import { getResponsiveStyles } from './responsive-styles/connect-with'

const useStyles = makeStyles((theme) =>
  createStyles({
    buttonPrimary: {
      display: 'inline-block',
      margin: '1rem .5rem 0 .5rem',
      fontSize: '0.875rem',
      fontWeight: 700,
      width: '10rem',
      verticalAlign: 'bottom',
      textTransform: 'capitalize',
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.light
      }
    },
    buttonSecondary: {
      display: 'inline-block',
      margin: '1.5rem .5rem 0 .5rem',
      fontSize: '0.875rem',
      width: '10rem',
      verticalAlign: 'bottom',
      textTransform: 'capitalize',
      color: theme.palette.text.secondary,
      backgroundColor: theme.palette.secondary.dark,
      '&:hover': {
        backgroundColor: theme.palette.secondary.light
      }
    },
    circularProgress: {
      display: 'block',
      width: '20px !important',
      height: '20px !important',
      margin: '.45rem auto 0 auto',
      '& svg': {
        color: 'white'
      }
    }
  })
)

function ConnectWith(props) {
  const { profile, sharedInterests } = props
  const classes = useStyles()
  const mainRef = useRef(null)
  const [didRecognizeRef, setDidRecognizeRef] = useState(false)
  const [componentWidth, setComponentWidth] = useState(0)
  const [responsiveStyles, setResponsiveStyles] = useState(getResponsiveStyles(componentWidth))
  const [processing, setProcessing] = useState(false)
  const [closed, setClosed] = useState(false)
  const currentUser = useSelector(({ currentUser }) => currentUser)
  const currentProfile = useSelector(({ currentProfile }) => currentProfile)
  const follows = useSelector(({ follows }) => follows)
  const dispatch = useDispatch()
  const { userId, avatar, documentVerified } = profile
  const verified = documentVerified ? 1 : 0
  const isFollowing = _.some(follows, { follower: currentUser.id, following: userId })

  const handleSetComponentWidth = () => {
    const width = mainRef.current ? mainRef.current.clientWidth : window.innerWidth

    setComponentWidth(width)
  }

  useEffect(() => {
    window.addEventListener('resize', handleSetComponentWidth)

    return () => {
      window.removeEventListener('resize', handleSetComponentWidth)
    }
  }, [])

  useEffect(() => {
    if (!didRecognizeRef) {
      if (mainRef.current !== null && typeof mainRef.current !== 'undefined') {
        setComponentWidth(mainRef.current.clientWidth)
        setDidRecognizeRef(true)
      }
    }
  }, [mainRef, didRecognizeRef])

  useEffect(() => {
    setResponsiveStyles(getResponsiveStyles(componentWidth))
  }, [componentWidth])

  const goToURL = (url) => {
    history.push(url)
  }

  const handleFollow = (e) => {
    e.stopPropagation()
    setProcessing(true)

    dispatch(
      createUserFollow({
        userId,
        name: getName(profile)
      })
    )
      .then((data) => {
        if (!data.error) {
          console.log('[SUCCESS]: ', 'Successfully followed user!')
        } else {
          console.log('[FAIL]: ', 'Failed to follow user.')
        }

        setProcessing(false)
      })
      .catch((e) => {
        setProcessing(false)
      })
  }

  const handleUnfollow = (e) => {
    e.stopPropagation()
    setProcessing(true)

    dispatch(
      deleteFollowByUserID({
        userId,
        currUserId: currentUser.id,
        name: getName(profile)
      })
    )
      .then((data) => {
        if (!data.error) {
          console.log('[SUCCESS]: ', 'Successfully unfollowed user!')
        } else {
          console.log('[FAIL]: ', 'Failed to unfollow user.')
        }

        setProcessing(false)
      })
      .catch((e) => {
        setProcessing(false)
      })
  }

  if (closed) return null

  // Hide if user is limited access
  if (!isVerifiedUser(currentProfile)) {
    return null
  }

  return (
    <MainContainer ref={mainRef}>
      <InnerContainer>
        <LeftContainer
          float={responsiveStyles.leftContainer.float}
          margin={responsiveStyles.leftContainer.margin}>
          <UserImage
            verified={verified}
            style={{ backgroundImage: `url(${getAvatar(avatar)})` }}
            onClick={() => goToURL(`/profile/${userId}`)}>
            {!isFollowing && !processing && (
              <IconContainer onClick={handleFollow}>
                <span className="material-icons">person_add</span>
              </IconContainer>
            )}

            {isFollowing && !processing && (
              <IconContainer onClick={handleUnfollow}>
                <span className="material-icons">person_remove</span>
              </IconContainer>
            )}

            {processing && (
              <IconContainer>
                <CircularProgress color="secondary" className={classes.circularProgress} />
              </IconContainer>
            )}
          </UserImage>

          <br />

          <UserName onClick={() => history.push(`/profile/${userId}`)}>{getName(profile)}</UserName>
        </LeftContainer>

        <ContentContainer margin={responsiveStyles.contentContainer.margin}>
          <Header textAlign={responsiveStyles.header.textAlign}>
            Connect and share great ideas.
          </Header>

          <Subheader textAlign={responsiveStyles.subheader.textAlign}>
            It looks like you and {getName(profile, 'first')} share{' '}
            {sharedInterests.map((id, i) => (
              <span onClick={() => history.push(`/topic/${id}`)}>
                {i === sharedInterests.length - 1 ? id : `${id}, `}
              </span>
            ))}{' '}
            as {sharedInterests.length === 1 ? 'a common interest' : 'common interests'}. Would you
            like to connect with {getName(profile, 'first')}?
          </Subheader>

          <ButtonContainer textAlign={responsiveStyles.buttonContainer.textAlign}>
            <Button
              variant="contained"
              className={classes.buttonPrimary}
              onClick={isFollowing ? handleUnfollow : handleFollow}
              style={componentWidth < 550 ? {} : { marginLeft: '0' }}>
              {processing ? (
                <CircularProgress color="secondary" className={classes.circularProgress} />
              ) : (
                <span>{isFollowing ? 'Unfollow' : 'Connect'}</span>
              )}
            </Button>

            <Button
              variant="contained"
              className={classes.buttonSecondary}
              onClick={() => setClosed(true)}
              style={componentWidth < 550 ? {} : { marginRight: '0' }}>
              Not Now
            </Button>
          </ButtonContainer>
        </ContentContainer>
      </InnerContainer>
    </MainContainer>
  )
}

export default ConnectWith
