import { useContext, useState } from 'react'
import { useSelector } from 'react-redux'
import checkOrgPermissions from '../../../../../utils/functions/checkPermissions'
// import getRolesByOrganizationID from '../../../../../redux/thunks/roles/getRolesByOrganizationID'
// import getAssignedRolesByRoleIDs from '../../../../../redux/thunks/assigned-roles/getAssignedRolesByRoleIDs'
import Avatar from './components/avatar'
import ImageUpload from '../../../../partials/image-upload/image-upload'
import CollapsedContent from '../../../../partials/collapsed-content/collapsed-content'
import Tags from '../../../../partials/tags/tags'
import EditButton from './components/edit-button'
import FollowButton from './components/follow-button'
// import DonateTokensButton from './components/donate-tokens-button'
import {
  MainContainer,
  DetailsContainer,
  Name,
  Location,
  About,
  Knowledge,
  DropDownButtonContainer
  // NavItemContainer,
  // NavItem
} from './styled/org-header-comps'
import LoadOrganizationRolesContext from '../../../../data-loading/load-organization-assigned-roles/context/context'
import { ThemeContext } from 'styled-components'

function OrgHeader(props) {
  const themeContext = useContext(ThemeContext)
  const { roles, assignedRoles } = useContext(LoadOrganizationRolesContext)
  const [open, setOpen] = useState(false)
  const { organization, isMobile } = props
  const { publicName, about, address, image, _id, tagIds } = organization
  const organizationId = _id
  const { id } = useSelector((state) => state.currentUser)

  const handleOpenImageUpload = () => {
    setOpen(true)
  }

  const handleCloseImageUpload = () => {
    setOpen(false)
  }

  // returns a boolean if a user has permission for each permission passed into the permissions array
  const hasOrganizationDetailsPermissions = checkOrgPermissions({
    assignedRoles,
    roles,
    currUserId: id,
    permissions: ['manageOrganizationDetails']
  })

  return (
    <MainContainer>
      <Avatar
        image={image}
        organization={props.organization}
        hasEditImagePermissions={hasOrganizationDetailsPermissions}
        handleOpen={handleOpenImageUpload}
      />
      <DetailsContainer>
        <Name>{publicName}</Name>

        {address.cityName && address.state && (
          <Location>
            <span>
              {address?.cityName}, {address?.state}
            </span>
          </Location>
        )}

        {!isMobile && about && (
          <About>
            <CollapsedContent
              content={about}
              rows={3}
              color={themeContext.palette.common.white}
              fontWeight={themeContext.typography.fontWeight.light}
              fontSize={themeContext.typography.fontSizes.regular}
              lineHeight={themeContext.typography.lineHeight}
              collapsible={true}
            />
          </About>
        )}

        {tagIds?.length > 0 && (
          <Knowledge>
            <Tags tagIds={tagIds} tagStyle={{ marginTop: '0', marginBottom: '.7rem' }} />
          </Knowledge>
        )}
      </DetailsContainer>

      <DropDownButtonContainer>
        {hasOrganizationDetailsPermissions ? (
          <EditButton />
        ) : (
          <FollowButton organization={props.organization} />
        )}

        {/* <DonateTokensButton organization={organization} /> */}
      </DropDownButtonContainer>

      <ImageUpload
        open={open}
        handleOpen={handleOpenImageUpload}
        handleClose={handleCloseImageUpload}
        organizationId={organizationId}
      />
    </MainContainer>
  )
}

export default OrgHeader
