import styled from 'styled-components'

export const NoNotificationsCopy = styled.p`
  margin-left: 2rem;
  line-height: 1.5rem;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.regular};
  font-weight: ${(props) => props.theme.typography.fontWeight.medium};
  color: ${(props) => props.theme.typography.fontColor.bodyCopy};
`
