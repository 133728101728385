import config from '../../../../config'
import Button from '@mui/material/Button'

function LoginButton({ styleProps }) {
  const COGNITO_URL = import.meta.env.VITE_AWS_COGNITO_URL
  const CLIENT_ID = import.meta.env.VITE_AWS_COGNITO_APP_CLIENT_ID
  const REDIRECT_URI = config.cognito.COGNITO_REDIRECT_URI
  const loginURL = `${COGNITO_URL}/login?client_id=${CLIENT_ID}&response_type=code&scope=email+openid+phone+aws.cognito.signin.user.admin+profile&redirect_uri=${REDIRECT_URI}&state=STATE`
  const logoutURL = `${COGNITO_URL}/logout?client_id=${CLIENT_ID}&logout_uri=${REDIRECT_URI}`

  return (
    <Button
      variant="contained"
      href={loginURL}
      sx={styleProps ? styleProps : { width: '15rem' }}
      id="primary-navigation">
      Sign up/Login
    </Button>
  )
}

export default LoginButton
