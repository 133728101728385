interface ValidationErrors {
  required?: string;
  invalidOption?: string;
}

function validateSeasonSelect(season: string): ValidationErrors {
  const errors: ValidationErrors = {};

  // Trim spaces
  const trimmedSeason = season.trim();

  // Required field
  if (!trimmedSeason) {
    errors.required = 'Season is required.';
  }

  // Valid options
  const validSeasons = ['spring', 'summer', 'fall', 'winter'];

  // Invalid option check
  if (trimmedSeason && !validSeasons.includes(trimmedSeason.toLowerCase())) {
    errors.invalidOption = 'Selected season is invalid.';
  }

  return errors;
}

export default validateSeasonSelect;