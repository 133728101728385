import styled from 'styled-components'
import theme from '../../../../../res/theme.js'

export const AcceptDeclineContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 1rem;
  text-align: center;
`

export const ButtonSubText = styled.span`
  margin-left: 0.3rem;
  /* margin-bottom: 0.1rem; */
  text-transform: none;
  /* line-height: ${theme.typography.lineHeight}; */
  font-family: ${theme.typography.fontFamily};
  font-size: ${theme.typography.fontSizeUC};
  font-weight: ${theme.typography.fontWeight.regular};
  color: ${({ theme }) => (theme === 'light' ? '#22335b' : '#ffffff')};

  /**Mobile view */
  @media (max-width: 600px) {
    margin-left: 0;
    font-size: ${theme.typography.fontSize.smaller};
    font-size: 0.65rem;
  }
`

export const RatingContainer = styled.div`
  display: block;
  /* width: 80%; */
  /* margin: 1.5rem auto; */
`

export const RatingMessage = styled.p`
  display: block;
  text-align: center;
  line-height: ${theme.typography.lineHeight};
  font-family: ${theme.typography.fontFamily};
  font-size: ${theme.typography.fontSize};
  font-weight: ${theme.typography.fontWeight.regular};
  color: ${theme.palette.text.primary};
`

export const RatingContentContainer = styled.div`
  display: block;
  margin-top: 1.2rem;
  text-align: center;
`

export const RatingColumnContainer = styled.div`
  display: inline-block;
  margin-right: 2rem;
  text-align: right;
  vertical-align: middle;
`

export const RatingCategoryLabel = styled.span`
  display: block;
  font-family: ${theme.typography.fontFamily};
  font-size: 1.2rem;
  font-weight: ${theme.typography.fontWeight.regular};
  color: ${theme.palette.text.primary};
`
