import styled from 'styled-components'

export const OuterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 20rem;
  margin: 0.25rem 0;
  border-radius: 0.3rem;
  padding: 1rem;
  background: ${(props) => props.theme.palette.common.white};

  & > p {
    padding: 0.5rem;
    border-radius: 0.2rem;
    background: ${(props) => props.theme.palette.secondary.light};
    font-family: ${(props) => props.theme.typography.fontFamily};
    font-size: ${(props) => props.theme.typography.fontSizes.small};
    font-weight: ${(props) => props.theme.typography.fontWeight.regular};
    text-transform: lowercase;
  }
`

export const InnerContainer = styled.div`
  display: flex;
  width: 100%;
`

export const ImageContainer = styled.div`
  background-color: ${(props) => props.theme.palette.common.white};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  /* display: inline-block; */
  width: 3.8rem;
  height: 3.8rem;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  border: 0.2rem solid;
  border-color: ${(props) => (props.verified ? props.theme.palette.primary.main : '#ccc')};
  margin: 0 1rem;
`

export const IconContainer = styled.div`
  background-color: ${(props) => props.theme.palette.secondary.main};
  display: block;
  width: 2.1rem;
  height: 2.1rem;
  border-radius: 50%;
  position: absolute;
  top: -0.6rem;
  left: -0.8rem;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.theme.palette.secondary.light};
  }
`

export const IconSpan = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  color: ${(props) => props.theme.palette.common.white};
  font-size: 1.2rem;
`

export const NameContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

export const Name = styled.p`
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.regular};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  color: ${(props) => props.theme.palette.text.primary};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`
