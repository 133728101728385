import { useDebugValue, useState, Dispatch, SetStateAction } from 'react';


// interface Instructor {
//   // Define properties for the instructor object as needed
// }

// interface Member {
//   // Define properties for the member object as needed
// }

interface CourseAssistant {
  // Define properties for the course assistant object as needed
}

interface Course {
  courseId: string;
  subject: string;
  title: string;
  number: string;
  season: string;
  year: string;
  description: string;
  instructor: any;
  members: any[];
  courseAssistants: any[];
  status: string;
  orgId: string;
}

function useCourseFieldsState(initialValue: Course): [Course, Dispatch<SetStateAction<Course>>] {
  const [courseFields, setCourseFields] = useState<Course>(initialValue);
  useDebugValue(courseFields ? courseFields : initialValue);
  return [courseFields, setCourseFields];
}

export default useCourseFieldsState


