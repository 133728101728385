import _ from 'lodash'
import { outgoingDiscussionActionTypes } from '../action-creators/outgoing-discussions.js'
import nestedSort from '../../utils/functions/nestedSort.js'

const copyDiscussions = (discussions) => {
  return discussions.map((discussion) => {
    let tempRatings = { ...discussion.ratings }
    let ratingKeys = Object.keys(tempRatings)
    let ratings = {}

    for (var i = 0; i < ratingKeys.length; i++) {
      ratings = {
        ...ratings,
        [ratingKeys[i]]: { ...discussion.ratings[ratingKeys[i]] }
      }
    }

    return {
      ...discussion,
      ratings: ratings
    }
  })
}

// FOR OUTGOING DISCUSSIONS
const outgoingDiscussionsReducer = (state = [], action) => {
  switch (action.type) {
    case outgoingDiscussionActionTypes.SET_OUTGOING_DISCUSSIONS:
      return nestedSort(copyDiscussions(action.discussions), -1, 'whenCreated')
    case outgoingDiscussionActionTypes.ADD_OUTGOING_DISCUSSIONS:
      let newDiscussions1 = copyDiscussions(state)

      for (var i = 0; i < action.discussions.length; i++) {
        let discussionExists = _.some(newDiscussions1, { '_id': action.discussions[i]._id })

        if (!discussionExists) {
          newDiscussions1.push(action.discussions[i])
        }
      }

      return nestedSort(newDiscussions1, -1, 'whenCreated')
    case outgoingDiscussionActionTypes.UPDATE_OUTGOING_DISCUSSION:
      let newDiscussions2 = copyDiscussions(state)
      const discussionIndex2 = _.findIndex(newDiscussions2, { '_id': action.discussionId })

      if (discussionIndex2 !== -1) {
        const updatedDiscussion2 = {
          ...newDiscussions2[discussionIndex2],
          ...action.fieldsToUpdate
        }

        newDiscussions2.splice(discussionIndex2, 1, updatedDiscussion2)
      } else {
        console.log('[REDUX ERROR]: ', 'Discussion not found.')
      }

      return newDiscussions2
    case outgoingDiscussionActionTypes.CLEAR_OUTGOING_DISCUSSIONS:
      return []
    default:
      return state
  }
}

export default outgoingDiscussionsReducer
