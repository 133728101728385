import styled from 'styled-components'
import config from '../../../../config.js'

export const MainContainer = styled.div`
  background-color: #eaeaea;
  display: block;
  width: 100%;
  max-width: 80rem;
  ${'' /* min-width: 20rem; */}
  margin: ${config.UI.main.marginTop} auto 4rem auto;
  font-size: 0;
  position: relative;
  box-sizing: border-box;
  overflow-x: hidden;

  /** gutters for tablet view */
  @media (max-width: ${config.UI.drawer_breakpoint.max}) {
    margin-top: 0.5rem;
    width: 100%;
  }
`

export const ContentContainer = styled.div`
  padding: 0 0.5rem;
`

export const FeedContainer = styled.section`
  box-sizing: border-box;
  display: block;
  overflow: hidden;
`

export const FeedListContainer = styled.div`
  min-height: 40rem;
`
