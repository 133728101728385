import config from '../../../config'
import { organizationsActions } from '../../action-creators/organizations.js'
import { actionResponseActions } from '../../action-creators/action-response.js'
import { authenticatedFetch } from '../../../utils/functions/authenticatedFetch'

export const updateOrganizationLocationByID = (params) => {
  let responseCode = ''
  const method = 'PUT'
  const url = `${config.baseURL}/api/organization/location/${params.organizationId}`
  const headers = {
    'Content-Type': 'application/json'
  }

  return (dispatch) => {
    return authenticatedFetch(url, {
      method,
      headers,
      credentials: 'include'
    })
      .then((response) => {
        responseCode = response.status

        return response.json()
      })
      .then((data) => {
        if (responseCode === 200) {
          dispatch(
            organizationsActions.update({
              organizationId: params.organizationId,
              updatedFields: data.organization
            })
          )
          dispatch(
            actionResponseActions.createActionResponse(
              'Successfully updated organization location!'
            )
          )

          return {
            error: false,
            code: responseCode,
            message: data.message
          }
        }

        return {
          error: true,
          code: responseCode,
          message: data.message
        }
      })
      .catch((error) => {
        console.log('[Error]:', error)

        return {
          error: true,
          code: '',
          message: error
        }
      })
  }
}
