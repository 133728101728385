import withStyles from '@mui/styles/withStyles';
import MuiButton from '@mui/material/Button'
import MuiDialog from '@mui/material/Dialog'

export const Dialog = withStyles((theme) => ({
  root: {},
  container: {},
  paper: {}
}))(MuiDialog)

export const HomeButton = withStyles((theme) => ({
  root: {
    display: 'inline-block',
    width: '12rem',
    margin: '0.5rem'
  },
  [theme.breakpoints.down(undefined)]: {
    root: {
      display: 'block',
      marginBottom: '1rem'
    }
  }
}))(MuiButton)

export const ProfileButton = withStyles((theme) => ({
  root: {
    display: 'inline-block',
    width: '12rem',
    margin: '0.5rem'
  },
  [theme.breakpoints.down(undefined)]: {
    root: {
      display: 'block'
    }
  }
}))(MuiButton)
