import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Switch, Route, Redirect, withRouter } from 'react-router-dom'
import './main.css'
import Landing from './landing/landing'
import HomePrivate from './home-private-new/home-private'
import VerificationSteps from './verification-steps/verification-steps'
import Profile from './profile/profile'
import Course from './course/course'
import ConnectionsOrganization from './connections-organization/connections-organization'
import Connections from './connections/connections'
import Post from './post/post'
import Tag from './tag/tag'
import DiscussionRoom from './discussion-room-new/discussion-room'
import MyAccount from './my-account/my-account'
import SearchResults from './search-results/search-results'
import Support from './support/support'
import PageNotFound from '../page-not-found/page-not-found'
import Organization from './organization/organization'
import Organizations from './organizations/organizations'
import OrganizationAccount from './organization-account/organization-account'
import MyCourses from './my-courses/my-courses'
import TermsOfUse from './terms-of-use/terms-of-use'
import Report from './reports/report'
import CourseSetup from './course-setup/course-setup'
import OrganizationAdmin from './organization-admin/organization-admin'

class Main extends Component {
  render() {
    const { isAuthenticated, currentProfile } = this.props
    const { hasAcceptedTerms } = currentProfile
    let mainClass = isAuthenticated ? 'mainLoggedIn' : 'mainNotLoggedIn'

    const CustomRoute = ({ component: Component, ...rest }) => (
      <Route
        {...rest}
        render={(props) => {
          if (isAuthenticated) {
            return <Component {...props} />
          } else {
            return (
              <Redirect
                to={{
                  pathname: '/'
                }}
              />
            )
          }
        }}
      />
    )

    // TODO: save for when we launch terms of use feature
    // This feature requires the profile to contain the hasAcceptedTerms flag
    // const CustomRoute = ({ component: Component, hasAcceptedTerms, isAuthenticated, ...rest }) => (
    //   <Route
    //     {...rest}
    //     render={(props) => {
    //       if (!isAuthenticated) {
    //         return <Redirect to="/" />
    //       } else if (isAuthenticated && !hasAcceptedTerms && rest.path !== '/terms-of-use') {
    //         return <Redirect to="/terms-of-use" />
    //       } else {
    //         return <Component {...props} />
    //       }
    //     }}
    //   />
    // )

    return (
      <main id="main-content" className={mainClass}>
        <Switch>
          <Route exact path="/" component={Landing} />
          <CustomRoute
            path="/terms-of-use"
            component={TermsOfUse}
            isAuthenticated={isAuthenticated}
            hasAcceptedTerms={hasAcceptedTerms}
          />
          <CustomRoute
            exact
            path="/home/:action?/:tagId?"
            component={HomePrivate}
            isAuthenticated={isAuthenticated}
            hasAcceptedTerms={hasAcceptedTerms}
          />
          <CustomRoute
            exact
            path="/verify"
            component={VerificationSteps}
            isAuthenticated={isAuthenticated}
            hasAcceptedTerms={hasAcceptedTerms}
          />
          <CustomRoute
            exact
            path="/profile/:userId"
            component={Profile}
            isAuthenticated={isAuthenticated}
            hasAcceptedTerms={hasAcceptedTerms}
          />
          <CustomRoute
            exact
            path="/course/:courseId"
            component={Course}
            isAuthenticated={isAuthenticated}
            hasAcceptedTerms={hasAcceptedTerms}
          />
          <CustomRoute
            exact
            path="/course-setup/:courseId?"
            component={CourseSetup}
            isAuthenticated={isAuthenticated}
            hasAcceptedTerms={hasAcceptedTerms}
          />
          <CustomRoute
            exact
            path="/connections/organization/:organizationId"
            component={ConnectionsOrganization}
            isAuthenticated={isAuthenticated}
            hasAcceptedTerms={hasAcceptedTerms}
          />
          <CustomRoute
            exact
            path="/connections/:userId/:filter?"
            component={Connections}
            isAuthenticated={isAuthenticated}
            hasAcceptedTerms={hasAcceptedTerms}
          />
          <CustomRoute
            exact
            path="/post/:takeId/:discussionId?"
            component={Post}
            isAuthenticated={isAuthenticated}
            hasAcceptedTerms={hasAcceptedTerms}
          />
          <CustomRoute
            exact
            path="/topic/:tagId?"
            component={Tag}
            isAuthenticated={isAuthenticated}
            hasAcceptedTerms={hasAcceptedTerms}
          />
          <CustomRoute
            path="/discussion-room"
            component={DiscussionRoom}
            isAuthenticated={isAuthenticated}
            hasAcceptedTerms={hasAcceptedTerms}
          />
          <CustomRoute
            exact
            path="/my-account/:tab?/:step?"
            component={MyAccount}
            isAuthenticated={isAuthenticated}
            hasAcceptedTerms={hasAcceptedTerms}
          />
          <CustomRoute
            exact
            path="/search/:query?"
            component={SearchResults}
            isAuthenticated={isAuthenticated}
            hasAcceptedTerms={hasAcceptedTerms}
          />
          <CustomRoute
            exact
            path="/support"
            component={Support}
            isAuthenticated={isAuthenticated}
            hasAcceptedTerms={hasAcceptedTerms}
          />
          <CustomRoute
            exact
            path="/organization/:orgId/settings/:tab/:refreshType?"
            component={OrganizationAccount}
            isAuthenticated={isAuthenticated}
            hasAcceptedTerms={hasAcceptedTerms}
          />
          <CustomRoute
            exact
            path="/organization/:orgId"
            component={Organization}
            isAuthenticated={isAuthenticated}
            hasAcceptedTerms={hasAcceptedTerms}
          />
          <CustomRoute
            exact
            path="/organizations"
            component={Organizations}
            isAuthenticated={isAuthenticated}
            hasAcceptedTerms={hasAcceptedTerms}
          />
          <CustomRoute
            path="/organization-admin"
            component={OrganizationAdmin}
            isAuthenticated={isAuthenticated}
            hasAcceptedTerms={hasAcceptedTerms}
          />
          <CustomRoute
            exact
            path="/my-courses"
            component={MyCourses}
            isAuthenticated={isAuthenticated}
            hasAcceptedTerms={hasAcceptedTerms}
          />
          <CustomRoute
            exact
            path="/report/:courseId"
            component={Report}
            isAuthenticated={isAuthenticated}
            hasAcceptedTerms={hasAcceptedTerms}
          />
          <CustomRoute
            exact
            path="/page-not-found"
            component={PageNotFound}
            isAuthenticated={isAuthenticated}
            hasAcceptedTerms={hasAcceptedTerms}
          />
          <Route path="*" component={PageNotFound} />
        </Switch>
      </main>
    )
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.isAuthenticated,
  currentProfile: state.currentProfile
})

export default withRouter(connect(mapStateToProps)(Main))
