import styled, { keyframes } from 'styled-components'

export const UserAvatarContainer = styled.div`
  position: relative;
`

export const Image = styled.img`
  background-size: cover; /** covers IE support */
  object-fit: cover;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  border-radius: 50%;
  border-width: ${(props) => props.borderWidth};
  border-style: solid;
  border-color: ${(props) => (props.verified === 1 ? props.theme.palette.primary.main : '#ccc')};
  background-color: #ffffff;
`

const LoadingAnimation = keyframes`
  0% {
    background-color: #fff;
  }
  50% {
    background-color: #ccc;
  }
  100% {
    background-color: #fff;
  }
`

export const Placeholder = styled.div`
  /* position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0; */
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  animation: ${LoadingAnimation} 1s infinite;
  border-radius: 50%;
  border-width: ${(props) => props.borderWidth};
  border-style: solid;
  border-color: ${(props) => (props.verified === 1 ? props.theme.palette.primary.main : '#ccc')};
`
