//////////////////
// ACTION TYPES //
//////////////////

export const tokenExpActionTypes = {
  SET_TOKEN_EXP: 'SET_TOKEN_EXP',
  CLEAR_TOKEN_EXP: 'CLEAR_TOKEN_EXP'
}

/////////////
// ACTIONS //
/////////////

// TOKEN EXPIRATION
const set = date => {
  return {
    type: tokenExpActionTypes.SET_TOKEN_EXP,
    date
  }
}

const clear = () => {
  return {
    type: tokenExpActionTypes.CLEAR_TOKEN_EXP
  }
}

export const tokenExpActions = {
  set,
  clear
}
