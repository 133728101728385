import Validator from 'validator'

const CreateRebuttalValidation = (state) => {
	// Rebuttal Validator
	if (Validator.isEmpty(state.rebuttal)) {
		return {
	  		error: 'Your rebuttal is required',
	    	field: 'rebuttal'
	  	}
	} else if (!Validator.isLength(state.rebuttal, {min:1, max: 3000})) {
		return {
	  		error: 'Your rebuttal must be 1-3000 characters long',
	    	field: 'rebuttal'
	  	}
	}

  	return {
  		error: '',
    	field: ''
  	}
}

export default CreateRebuttalValidation