import { useState, useEffect } from 'react'

function useWidth() {
  // initialize the state with undefined width
  const [width, setWidth] = useState(null)

  function handleResize() {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    // add event listener
    window.addEventListener('resize', handleResize)

    // update state with the initial window size
    handleResize()

    // cleanup
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return [width, handleResize]
}

export default useWidth
