import styled from 'styled-components'

export const ActionContainer = styled.div`
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: middle;
  border-radius: 50%;
  position: relative;

  & > .material-icons {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.2rem;
  }

  &:hover {
    background-color: #ddd;
  }

  &:active {
    background-color: #ccc;
  }
`
