import { firebaseAuthActionTypes } from '../action-creators/firebase_auth.js'

const firebaseAuthReducer = (state = {}, action) => {
  switch (action.type) {
    case firebaseAuthActionTypes.LOG_IN:
      return { ...state, authorized: action.status, error: null }
    case firebaseAuthActionTypes.LOG_OUT:
      return { ...state, authorized: false, error: null }
    case firebaseAuthActionTypes.ERROR:
      return { ...state, status: 'error', error: action.error }
    default:
      return state
  }
}

export default firebaseAuthReducer
