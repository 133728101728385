import config from '../../../config'
import { authenticatedFetch } from '../../../utils/functions/authenticatedFetch'
/*
 * METHOD TO LOGOUT AND CLEAR REFRESH TOKEN
 */
export const logoutServer = () => {
  let responseCode = ''
  const method = 'DELETE'
  // const url = 'http://localhost:8000/api/user/logout'
  const url = `${config.baseURL}/api/user/logout`
  const headers = {
    'Content-Type': 'application/json'
  }

  // Thunk middleware knows how to handle functions.
  // It passes the dispatch method as an argument to the function,
  // thus making it able to dispatch actions itself.
  return (dispatch) => {
    console.log('Sending logout request to CivilTalk')

    // Return the fetch so react components calling 'store.dispatch()' can use 'then()'
    return authenticatedFetch(url, {
      method,
      headers,
      credentials: 'include'
    })
      .then((response) => {
        responseCode = response.status

        if (responseCode === 204) return {}

        console.log('logout response', response.json())
        return response.json()
      })
      .then((data) => {
        if (responseCode === 204) {
          console.log('[DEBUG]: ', 'Successfully logged out from server.')
        } else {
          console.log('[DEBUG]: ', 'Unable to logout from server.')
        }
      })
      .catch((error) => {
        console.log('[ERROR]: ', error)
      })
  }
}
