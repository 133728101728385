import React from 'react'
import { useDispatch } from 'react-redux'
import { tokensTransactionModalActions } from '../../../../../../../redux/action-creators/tokens-transaction-modal'
import { Button } from '@mui/material'
import { Heading, Subheading, ButtonContainer } from './styled/complete-comps'

const styles = {
  submit: {
    width: '10rem',
    '@media (max-width: 450px)': {
      width: '100%',
      marginTop: '.5rem'
    }
  }
}

function Complete() {
  const dispatch = useDispatch()

  const handleConfirm = () => {
    dispatch(tokensTransactionModalActions.clear())
  }

  return (
    <div>
      <Heading>Donation successful</Heading>
      <Subheading>
        Thank you for your donation. Your generosity is what helps support our community, so we
        thank you for your continued participation.
      </Subheading>

      <ButtonContainer>
        <Button color="primary" variant="contained" onClick={handleConfirm} sx={styles.submit}>
          Close
        </Button>
      </ButtonContainer>
    </div>
  )
}

export default Complete
