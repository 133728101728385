// import { useState } from 'react'
import TextField from '@mui/material/TextField'
import { styled } from '@mui/material/styles'

function Input({
  label,
  id,
  name,
  value,
  multiline,
  handleChange,
  inputRef,
  error,
  setError,
  errorsObj,
  setErrorsObj,
  helperText,
  setHelperText,
  validationFunc
}) {
  const handleInputChange = (e) => {
    const inputValue = e.target.value

    handleChange(e)

    // Clear validation errors when the user corrects the input
    if (validationFunc) {
      const validationErrors = validationFunc(inputValue)
      const validationError = Object.values(validationErrors)[0]

      if (!validationError) {
        setError(false)
        setErrorsObj({})
        setHelperText('')
      }
    }
  }

  // Validate the users input when they leave the input field
  const handleBlur = () => {
    if (validationFunc) {
      const validationErrors = validationFunc(value)

      const validationError = Object.values(validationErrors)[0]

      if (validationError) {
        setError(true)
        setErrorsObj(validationErrors)
        setHelperText(validationError)
      } else {
        setError(false)
        setErrorsObj({})
        setHelperText('')
      }
    }
  }

  return (
    <MuiTextField
      id={id}
      label={label}
      name={name}
      value={value}
      onChange={handleInputChange}
      onBlur={handleBlur}
      multiline={multiline ? true : false}
      minRows={multiline ? 12 : 1}
      ref={inputRef}
      helperText={helperText && helperText}
      error={!!error}
    />
  )
}

export default Input

// ***************************** //
// *** MUI Styled Components *** //
// ***************************** //
const MuiTextField = styled(TextField)(({ theme }) => ({
  background: theme.palette.background.gray,
  color: theme.palette.text.primary,
  minWidth: '18rem',
  width: '100%',
  // minWidth:
  '& label': {
    color: theme.palette.text.primary
  },
  '& label.Mui-focused': {
    color: theme.palette.secondary.main
  },
  '& .MuiInput-underline:after': {
    // borderBottomColor: '#B2BAC2'
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      // borderColor: '#E0E3E7'
    },
    '&:hover fieldset': {
      // borderColor: '#B2BAC2'
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.secondary.main
    }
  },
  '& .MuiFormHelperText-root': {
    color: theme.palette.text.primary,
    padding: '0.15rem'
  }
}))
