import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
import load from './functions/load'
import { getTakesByIDs } from '../../../redux/thunks/takes/getTakesByIDs'
import { getDiscussions } from '../../../redux/thunks/discussions/getDiscussions'
import { getRebuttals } from '../../../redux/thunks/rebuttals/getRebuttals'
import { getProfilesByUserIDs } from '../../../redux/thunks/profiles/getProfilesByUserIDs'
import SkeletonTake from '../../skeletons/skeleton-take'
import PostComponent from '../../partials/post/post'
import { MainContainer, PostUnavailable } from './styled/post-comps'

const Post = (props) => {
  const { match } = props
  const [isLoading, setIsLoading] = useState(true)
  const [takeFound, setTakeFound] = useState(true)
  const currentUser = useSelector(({ currentUser }) => currentUser)
  const loadedTakes = useSelector(({ loadedTakes }) => loadedTakes)
  const dispatch = useDispatch()

  useEffect(() => {
    if (isLoading) {
      const { takeId } = match.params

      const loadActions = {
        getTakesByIDs: (params) => dispatch(getTakesByIDs(params)),
        getDiscussions: (params) => dispatch(getDiscussions(params)),
        getRebuttals: (params) => dispatch(getRebuttals(params)),
        getProfilesByUserIDs: (params) => dispatch(getProfilesByUserIDs(params))
      }

      load(true, takeId, currentUser.id, setIsLoading, setTakeFound, loadActions)
    }
  }, [currentUser.id, dispatch, isLoading, match.params])

  if (!isLoading) {
    if (!takeFound) {
      return (
        <PostUnavailable>
          We're sorry, the post you've requested has been hidden or deleted.
        </PostUnavailable>
      )
    }
  }

  const take = _.find(loadedTakes, { _id: match.params.takeId })

  return (
    <MainContainer>
      {isLoading || !take ? (
        <SkeletonTake />
      ) : (
        <PostComponent take={take} discussionId={match.params.discussionId} />
      )}
    </MainContainer>
  )
}

export default withRouter(Post)
