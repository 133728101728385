import { useContext } from 'react'
import { useParams, withRouter } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { history } from '../../../../../history/history'
import checkOrgPermissions from '../../../../../utils/functions/checkPermissions'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import { Drawer, List, ListItem, ListItemIcon, ListItemText } from '@mui/material'
import { Header } from './styled/side-nav-mobile-comps'
import LoadOrganizationRolesContext from '../../../../data-loading/load-organization-assigned-roles/context/context'
import './css/drawer.css'

const useStyles = makeStyles(() =>
  createStyles({
    drawer: {
      marginTop: '5.5rem',
      boxSizing: 'border-box',
      zIndex: '1 !important'
    },
    paper: {
      top: '5.5rem',
      left: '0',
      zIndex: '1'
    },
    listItem: {
      padding: '1rem',
      borderBottom: '1px solid #eee',
      position: 'relative',
      '& span': {
        fontSize: '1.2rem'
      },
      '& span.material-icons': {
        fontSize: '1.7rem'
      }
    },
    listItemText: {
      marginBottom: '.4rem'
    },
    icon: {
      minWidth: '1.2rem',
      marginRight: '1.5rem'
    },
    caret: {
      minWidth: '1.2rem',
      position: 'absolute',
      top: '50%',
      right: '.8rem',
      transform: 'translateY(-50%)'
    },
    fullList: {
      width: 'auto'
    }
  })
)
function SideNavMobile(props) {
  const { open, setOpen } = props
  const classes = useStyles()
  const params = useParams()
  const { roles, assignedRoles } = useContext(LoadOrganizationRolesContext)
  const currentUser = useSelector(({ currentUser }) => currentUser)

  const handleSelectTab = (tab) => {
    history.push(`/organization/${params.orgId}/settings/${tab}`)
  }

  const hasAdminPermissions = checkOrgPermissions({
    assignedRoles,
    roles,
    currUserId: currentUser.id,
    permissions: [] // Admin only
  })

  const hasOrganizationDetailsPermissions = checkOrgPermissions({
    assignedRoles,
    roles,
    currUserId: currentUser.id,
    permissions: ['manageOrganizationDetails']
  })

  const hasRoleManagementPermissions = checkOrgPermissions({
    assignedRoles,
    roles,
    currUserId: currentUser.id,
    permissions: ['manageRoles', 'manageAssignedRoles']
  })

  const hasBillingPermissions = checkOrgPermissions({
    assignedRoles,
    roles,
    currUserId: currentUser.id,
    permissions: ['manageBilling']
  })

  return (
    <Drawer
      id={'settings-drawer'}
      anchor={'right'}
      open={open}
      onClose={() => setOpen(false)}
      classes={{ root: classes.drawer, paper: classes.paper }}>
      <div>
        <Header>Organization Account</Header>
        <List>
          {hasAdminPermissions && (
            <ListItem
              button
              key={'account'}
              className={classes.listItem}
              onClick={() => handleSelectTab('account')}>
              <ListItemIcon className={classes.icon}>
                <span className="material-icons">manage_accounts</span>
              </ListItemIcon>

              <ListItemText primary={'Account Details'} className={classes.listItemText} />

              <ListItemIcon className={classes.caret}>
                <span className="material-icons">chevron_right</span>
              </ListItemIcon>
            </ListItem>
          )}

          {hasOrganizationDetailsPermissions && (
            <ListItem
              button
              key={'picture'}
              className={classes.listItem}
              onClick={() => handleSelectTab('picture')}>
              <ListItemIcon className={classes.icon}>
                <span className="material-icons">face</span>
              </ListItemIcon>

              <ListItemText primary={'Profile Picture'} className={classes.listItemText} />

              <ListItemIcon className={classes.caret}>
                <span className="material-icons">chevron_right</span>
              </ListItemIcon>
            </ListItem>
          )}

          {hasOrganizationDetailsPermissions && (
            <ListItem
              button
              key={'details'}
              className={classes.listItem}
              onClick={() => handleSelectTab('details')}>
              <ListItemIcon className={classes.icon}>
                <span className="material-icons">account_box</span>
              </ListItemIcon>

              <ListItemText primary={'Organization Details'} className={classes.listItemText} />

              <ListItemIcon className={classes.caret}>
                <span className="material-icons">chevron_right</span>
              </ListItemIcon>
            </ListItem>
          )}
        </List>

        {hasOrganizationDetailsPermissions && (
          <ListItem
            button
            key={'location'}
            className={classes.listItem}
            onClick={() => handleSelectTab('location')}>
            <ListItemIcon className={classes.icon}>
              <span className="material-icons">travel_explore</span>
            </ListItemIcon>

            <ListItemText primary={'Location Settings'} className={classes.listItemText} />

            <ListItemIcon className={classes.caret}>
              <span className="material-icons">chevron_right</span>
            </ListItemIcon>
          </ListItem>
        )}

        {hasRoleManagementPermissions && (
          <ListItem
            button
            key={'roles'}
            className={classes.listItem}
            onClick={() => handleSelectTab('roles')}>
            <ListItemIcon className={classes.icon}>
              <span className="material-icons">assignment_ind</span>
            </ListItemIcon>

            <ListItemText primary={'Role Management'} className={classes.listItemText} />

            <ListItemIcon className={classes.caret}>
              <span className="material-icons">chevron_right</span>
            </ListItemIcon>
          </ListItem>
        )}

        {hasBillingPermissions && (
          <ListItem
            button
            key={'billing'}
            className={classes.listItem}
            onClick={() => handleSelectTab('billing')}>
            <ListItemIcon className={classes.icon}>
              <span className="material-icons">paid</span>
            </ListItemIcon>

            <ListItemText primary={'Billing & Donations'} className={classes.listItemText} />

            <ListItemIcon className={classes.caret}>
              <span className="material-icons">chevron_right</span>
            </ListItemIcon>
          </ListItem>
        )}
      </div>
    </Drawer>
  )
}

export default withRouter(SideNavMobile)
