import styled from 'styled-components'

export const HR = styled.hr`
  display: block;
  height: 1px;
  border: 0;
  border-top: 1.5px solid rgba(48, 48, 48, 0.1);
`

export const SliderContainer = styled.div`
  position: relative;
  margin: 1rem 0;
`

export const UserContainer = styled.div`
  display: inline-block;
  min-width: 7.5rem;
  width: 7.5rem;
  vertical-align: top;
  cursor: pointer;
`

export const UserImage = styled.div`
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: block;
  width: ${({ selected }) => (selected ? '4.8rem' : '3.8rem')};
  height: ${({ selected }) => (selected ? '4.8rem' : '3.8rem')};
  margin: 0 auto;
  border-radius: 50%;
  pointer-events: none;
  border: 0.2rem solid;
  border-color: ${(props) => (props.verified ? props.theme.palette.primary.main : '#ccc')};

  transition: width.2s ease-in-out, height 0.2s ease-in-out;
`

export const UserName = styled.p`
  display: block;
  width: 90%;
  margin: 0.5rem auto 0 auto;
  text-align: center;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.small};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  /*color: ${({ isClosed }) => (isClosed ? 'rgba(250, 250, 250, .5)' : 'white')}; */
  color: ${(props) => props.theme.typography.fontColor.bodyCopy};
  pointer-events: none;
`

export const LoadMoreContainer = styled.div`
  display: inline-block;
  min-width: 7.5rem;
  width: 7.5rem;
  padding: 0.5rem 1.75rem 0 1.75rem;
  box-sizing: border-box;
  vertical-align: top;
`

export const LoadMore = styled.div`
  background-color: rgba(0, 0, 0, 0.2);
  display: inline-block;
  min-width: 2rem !important;
  width: 3rem !important;
  height: 3rem !important;
  border-radius: 50%;
  cursor: pointer;
  position: relative;

  &:hover {
    background-color: rgba(0, 0, 0, 0.4);
  }
`

export const LoadingMore = styled.div`
  display: inline-block;
  min-width: 2rem !important;
  width: 2rem !important;
  height: 2rem !important;
  margin: 1rem 0 0 2.25rem;
  vertical-align: top;
  position: relative;
`
