import styled from 'styled-components'
import config from '../../../config'

export const MainContainer = styled.footer`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  background-color: #303030;
  padding: 1.5rem 2.5rem;
  height: 100%;
`

export const FooterInnerContainer = styled.div`
  display: block;
  width: 92%;
  max-width: 120rem;
  min-height: 4.9rem;
  margin: 1rem auto;
  text-align: center;

  & .footer {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;

    & a {
      text-decoration: none;
    }

    & h4 {
      color: #fff;
      font-size: ${({ theme }) => theme.typography.fontSizes.footerLogo};
      line-height: ${({ theme }) => theme.typography.fontSizes.footerLogo};
      font-weight: 600;
    }
  }

  @media screen and (max-width: ${config.UI.tablet_breakpoint.max}) {
    margin: 1rem auto;

    & .footer {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      & h4 {
        font-size: 3rem;
        line-height: 3rem;
      }
    }
  }

  @media screen and (max-width: ${config.UI.drawer_breakpoint.max}) {
    min-height: 10rem;
    margin: 1rem auto;

    & .footer {
      justify-content: space-between;
      flex-direction: column;
      align-items: center;

      & h4 {
        margin: 0 auto 1rem;
      }
    }
  }

  @media screen and (max-width: ${config.UI.drawer_breakpoint.max}) {
    & .footer {
      & h4 {
        font-size: 2.5rem;
        line-height: 2.5rem;
      }
    }
  }
`

export const Badges = styled.div`
  & h6 {
    font-size: ${({ theme }) => theme.typography.fontSizes.extraLarge};
    color: #ecb800;
    font-weight: ${({ theme }) => theme.typography.fontWeight.bold};
  }

  & .badges-icon {
    margin: calc(1px + 1.35vw) 0 0;
    display: grid;
    grid-column-gap: calc(1px + 1.5vw);
    grid-auto-flow: column;
    justify-items: center;
    align-items: center;
    justify-content: center;
    align-content: center;

    & a {
      width: calc(1rem + 9.375vw);
      height: calc(0.8rem + 2.5vw);
      position: relative;
      & span,
      & img {
        width: inherit !important;
        height: inherit !important;
      }
    }
  }
  & .footer-social-media {
    margin: calc(1px + 1.35vw) 0 0;
    display: grid;
    column-gap: calc(1px + 1vw);
    grid-auto-flow: column;
    justify-items: center;
    align-items: center;
    justify-content: center;
    align-content: center;

    & a {
      width: 2.5rem;
      height: 2.5rem;
      position: relative;
      cursor: pointer;
      filter: brightness(0) invert(1);
      transform: scale(1) translateY(0);
      transform-origin: center center;
      transition: all 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
      & img {
        width: inherit;
        height: inherit;
      }
      &:hover {
        transform: scale(1.15);
        filter: brightness(1) invert(0);
        transition: all 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
      }

      &:active {
        transform: scale(1.05) translateY(1px);
      }
    }
  }
  @media screen and (max-width: ${config.UI.tablet_breakpoint.max}) {
    & .footer-social-media {
      margin: calc(1px + 1.35vw) 0 0;
      grid-column-gap: calc(1px + 1.5vw);
      & a {
        width: 2rem;
        height: 2rem;
      }
    }
    & h6 {
      font-size: ${({ theme }) => theme.typography.fontSizes.large};
    }
    & .badges-icon {
      & a {
        width: calc(1rem + 14vw);
        height: calc(1rem + 3.5vw);
      }
    }
  }

  @media screen and (max-width: ${config.UI.drawer_breakpoint.max}) {
    & .footer-social-media {
      margin: 1rem 0 0;
      grid-column-gap: calc(1px + 1.5vw);
      & a {
        width: 1.75rem;
        height: 1.75rem;
      }
    }
    & h6 {
      margin-top: 1rem;
      font-size: calc(5px + 2.5vw);
    }
    & .badges-icon {
      margin: calc(1px + 3vw) 0 0;

      & a {
        width: calc(1rem + 19vw);
        height: calc(1rem + 4vw);
        & span,
        & img {
          width: inherit !important;
          height: inherit !important;
        }
      }
    }
  }
`

export const LinksGroup = styled.div`
  display: flex;
  flex-direction: row;
  grid-column-gap: 5.5rem;

  @media screen and (max-width: ${config.UI.tablet_breakpoint.max}) {
    grid-column-gap: 3.5rem;
  }

  @media screen and (max-width: ${config.UI.drawer_breakpoint.max}) {
    padding: 1rem 0;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
`

export const Links = styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-size: ${({ theme }) => theme.typography.fontSizes.extraLarge};
  font-weight: ${({ theme }) => theme.typography.fontWeight.regular};
  color: ${({ theme }) => theme.palette.common.white};
  text-align: left;

  & a {
    color: ${({ theme }) => theme.palette.common.white};
    margin: calc(1px + 0.02vw) 0rem;
    cursor: pointer;

    &:link,
    &:visited,
    &:active {
      text-decoration: none;
      color: ${({ theme }) => theme.palette.common.white};
    }

    &:hover {
      text-decoration: underline;
      color: ${({ theme }) => theme.palette.common.white};
    }
  }

  @media screen and (max-width: ${config.UI.tablet_breakpoint.max}) {
    font-size: ${({ theme }) => theme.typography.fontSizes.larger};
  }

  @media screen and (max-width: ${config.UI.drawer_breakpoint.max}) {
    font-size: calc(0.75rem + 1vw);
    justify-content: center;
    align-items: center;
  }
`
