import styled from 'styled-components'

export const Thumbnail = styled.div`
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: block;
  float: left;
  min-width: 3.3rem;
  width: 3.3rem;
  min-height: 3.3rem;
  height: 3.3rem;
  margin-right: 1.5rem;
  border-radius: 50%;
  border: 0.2rem solid;
  border-color: ${(props) => (props.verified === 1 ? props.theme.palette.primary.main : '#ccc')};

  @media (max-width: 599px) {
    min-width: 2.8rem;
    width: 2.8rem;
    min-height: 2.8rem;
    height: 2.8rem;
    margin-right: 1rem;
  }
`

export const ContentContainer = styled.div`
  display: block;
  overflow: hidden;
  max-width: 220px;
`

export const Header = styled.p`
  line-height: 1.5rem;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.short};
  font-weight: ${(props) => props.theme.typography.fontWeight.medium};
  color: ${(props) => props.theme.palette.text.primary};
  white-space: normal;
  pointer-events: none;

  @media (max-width: 599px) {
    font-size: ${(props) => props.theme.typography.fontSizes.short};
  }
`

export const Subheader = styled.p`
  line-height: 1.5rem;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.short};
  font-weight: ${(props) => props.theme.typography.fontWeight.light};
  color: ${(props) => props.theme.palette.text.primary};
  white-space: normal;
  pointer-events: none;

  @media (max-width: 599px) {
    font-size: ${(props) => props.theme.typography.fontSizes.small};
  }
`

export const MenuContainer = styled.div`
  /* display: block;
  float: right; */
  position: absolute;
  right: 0;
  width: 5rem;
`

export const IndicatorBadge = styled.div`
  background-color: ${(props) => props.theme.palette.primary.main};
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 1.25rem;
  width: 0.75rem;
  height: 0.75rem;

  @media (max-width: 359px) {
    top: 60%;
  }
`
