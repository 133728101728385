import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { history } from '../../../../../history/history'
import { MainContainer, Header, Step } from './styled/side-nav-desktop-comps'

function SideNavDesktop(props) {
  const [section, setSection] = useState('')
  const currentProfile = useSelector(({ currentProfile }) => currentProfile)

  useEffect(() => {
    const { match } = props
    const { tab } = match.params

    if (tab) {
      setSection(tab)
    }
  }, [props])

  const handleSelectTab = (e) => {
    history.push(`/my-account/${e.target.id}`)
  }

  return (
    <MainContainer>
      <Header>My Account</Header>

      <div>
        <Step
          id="account"
          onClick={handleSelectTab}
          style={{ marginTop: 0 }}
          current={section === 'account'}>
          Account Information
        </Step>

        <Step id="picture" onClick={handleSelectTab} current={section === 'picture'}>
          Profile Picture
        </Step>

        <Step id="personal" onClick={handleSelectTab} current={section === 'personal'}>
          Personal Details
        </Step>

        <Step id="location" onClick={handleSelectTab} current={section === 'location'}>
          Location Settings
        </Step>

        <Step id="privacy" onClick={handleSelectTab} current={section === 'privacy'}>
          Privacy Settings
        </Step>

        <Step id="email" onClick={handleSelectTab} current={section === 'email'}>
          Email Settings
        </Step>

        {/* <Step id="identification" onClick={handleSelectTab} current={section === 'identification'}>
          Identity Verification
        </Step> */}

        {/* <Step
          id="billing"
          onClick={handleSelectTab}
          style={{ marginBottom: '0' }}
          current={section === 'billing'}>
          Billing Information
        </Step> */}
      </div>
    </MainContainer>
  )
}

export default withRouter(SideNavDesktop)
