import styled from 'styled-components'

export const Card = styled.div`
  width: 100%;
  min-height: 7rem;
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  @media screen and (max-width: 400px) {
    min-height: 5rem;
    gap: 0.65rem;
  }
`
export const Typo = styled.div`
  width: 100%;
  height: inherit;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.75rem;
  @media screen and (max-width: 400px) {
    gap: 0.5rem;
  }
`
export const InnerContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
`

export const Image = styled.div`
  background-color: ${(props) => props.theme.palette.common.white};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: inline-block;
  min-width: 3.8rem;
  min-height: 3.8rem;
  width: 3.8rem;
  height: 3.8rem;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  border: 0.2rem solid;
  border-color: ${(props) => (props.verified ? props.theme.palette.primary.main : '#ccc')};

  @media screen and (max-width: 750px) {
    min-width: 3rem;
    min-height: 3rem;
    width: 3rem;
    height: 3rem;
  }
  @media screen and (max-width: 400px) {
    min-width: 2.6rem;
    min-height: 2.6rem;
    width: 2.6rem;
    height: 2.6rem;
  }
`
export const Name = styled.p`
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.medium};
  font-weight: ${(props) => props.theme.typography.fontWeight.semiBold};
  color: ${(props) => props.theme.palette.text.primary};
  cursor: pointer;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;

  &:hover {
    text-decoration: underline;
  }
  @media screen and (max-width: 750px) {
    font-size: ${(props) => props.theme.typography.fontSizes.short};
    font-weight: ${(props) => props.theme.typography.fontWeight.bold};
  }
  @media screen and (max-width: 600px) {
    font-weight: ${(props) => props.theme.typography.fontWeight.semiBold};
    font-size: ${(props) => props.theme.typography.fontSizes.smaller};
  }
`
export const Description = styled.p`
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.small};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  color: ${(props) => props.theme.palette.text.primary};
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;

  @media screen and (max-width: 750px) {
    font-size: ${(props) => props.theme.typography.fontSizes.smaller};
  }
`

export const Date = styled.p`
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.small};
  font-weight: ${(props) => props.theme.typography.fontWeight.light};
  color: #464646;
  margin-bottom: 1rem;
  @media screen and (max-width: 750px) {
    font-size: ${(props) => props.theme.typography.fontSizes.smaller};
  }
`
