import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { history } from '../../history/history'
import PropTypes from 'prop-types'
import withStyles from '@mui/styles/withStyles'
import Button from '@mui/material/Button'
import { MainContainer, ContentContainer, Message } from './styled/page-not-found-comps'

const styles = (theme) => ({
  button: {
    display: 'block',
    width: '12rem',
    height: '3.5rem',
    margin: '0 auto',
    textTransform: 'capitalize'
  }
})

class PageNotFound extends Component {
  constructor(props) {
    super(props)

    this.handleClick = this.handleClick.bind(this)
  }

  handleClick() {
    history.push('/')
  }

  render() {
    let { classes } = this.props

    return (
      <MainContainer>
        <ContentContainer>
          <Message>Page not found</Message>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={this.handleClick}>
            CivilTalk Home
          </Button>
        </ContentContainer>
      </MainContainer>
    )
  }
}

PageNotFound.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withRouter(connect()(withStyles(styles)(PageNotFound)))
