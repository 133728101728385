import { TableCell } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import theme from '../../../../../../../../../res/theme'

export const tableStyles = {
  Header: {
    color: '#333',
    '&.MuiTableSortLabel-root:hover': {
      color: '#333'
    },
    '& :active': {
      color: '#333',

      '& svg': {
        opacity: '1',
        fill: '#333'
      }
    },
    '& :focus': {
      color: '#333',

      '& svg': {
        opacity: '1',
        fill: '#333'
      }
    },
    '& :hover': {
      color: '#333',

      '& svg': {
        opacity: '1',
        fill: '#333'
      }
    }
  }
}

export const styles = (theme) => ({
  root: {
    width: '100%',
    marginTop: '.5rem',
    overflowX: 'auto',
    boxShadow: 'none'
  },

  tableWrapper: {
    overflowX: 'auto'
  },
  headerItem: {
    display: 'block',
    margin: '0',
    marginLeft: '.5rem',
    padding: '.5rem',
    float: 'right',
    color: '#6a86c8'
  },
  color: {
    borderLeftStyle: 'solid',
    borderLeftWidth: '20px'
  },
  needsAction: {
    display: 'inline-block',
    margin: '.4rem 0 0 .7rem',
    color: 'rgba(1, 147, 247, 1)'
  },
  circularProgress: {
    display: 'inline-block',
    margin: '.4rem 0 0 .8rem',
    width: '20px !important',
    height: '20px !important',
    '& svg': {
      color: 'rgba(1, 147, 247, 1)'
    }
  }
})

export const CustomTableCell = withStyles(() => ({
  head: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.fontSizes.short,
    fontWeight: theme.typography.fontWeight.regular,
    color: theme.typography.fontColor.bodyCopy
  },
  body: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.fontSizes.short,
    fontWeight: theme.typography.fontWeight.light
  }
}))(TableCell)
