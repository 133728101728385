import { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { useSelector } from 'react-redux'
import getAvatar from '../../../../../utils/functions/getAvatar'
import ImageUpload from '../../../../partials/image-upload/image-upload'
import Button from '@mui/material/Button'
import makeStyles from '@mui/styles/makeStyles'

import { ItemContainer, InputLabel, ImageContainer } from './styled/profile-picture-comps'

const useStyles = makeStyles((theme) => ({
  button: {
    display: 'block',
    width: '60%',
    minWidth: '12rem',
    margin: '2rem auto',
    fontSize: '1rem',
    fontWeight: 400,
    textTransform: 'capitalize',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light
    }
  }
}))

function ProfilePicture() {
  const classes = useStyles()
  const currentProfile = useSelector((state) => state.currentProfile)
  const { avatar, documentVerified } = currentProfile
  const verified = documentVerified ? 1 : 0
  const [open, setOpen] = useState(false)

  const handleOpenImageUpload = () => {
    setOpen(true)
  }

  const handleCloseImageUpload = () => {
    setOpen(false)
  }

  return (
    <>
      <ItemContainer marginTop="0">
        <InputLabel style={{ float: 'none' }}>Profile Picture:</InputLabel>

        <div>
          <ImageContainer
            style={{
              backgroundImage: `url(${getAvatar(avatar)})`
            }}
            verified={verified}></ImageContainer>
        </div>
      </ItemContainer>

      <ItemContainer>
        <Button
          variant="contained"
          color="primary"
          onClick={handleOpenImageUpload}
          className={classes.button}>
          Edit Image
        </Button>
        <ImageUpload
          open={open}
          handleOpen={handleOpenImageUpload}
          handleClose={handleCloseImageUpload}
        />
      </ItemContainer>
    </>
  )
}

export default withRouter(ProfilePicture)
