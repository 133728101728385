import config from '../../../config'
import { bindActionCreators } from 'redux'
import * as actionCreators from '../../action-creators/organization-transaction-history'
import { getOrganizationsByIDs } from '../organizations/getOrganizationsByIDs'
import { authenticatedFetch } from '../../../utils/functions/authenticatedFetch'

export const Organizations_history_DonationTokens = (params) => {
  const { rowsPerPage, afterDate, ispageChanged, orgId, update } = params
  // const test_orgId = '61f81042960595ccfc5ffefe'

  let responseCode = ''
  let AllTypesTaken = []
  const method = 'GET'
  let url = `${config.baseURL}/api/donation-token-transaction/organization/${orgId}/?limit=${rowsPerPage}`

  if (update) {
    if (afterDate) {
      url += `&afterDate=${afterDate}`
    }
  } else {
    if (ispageChanged && afterDate) {
      url += `&afterDate=${afterDate}`
    }
  }
  const headers = {
    'Content-Type': 'application/json'
  }

  // Thunk middleware knows how to handle functions.
  // It passes the dispatch method as an argument to the function,
  // thus making it able to dispatch actions itself.

  return (dispatch) => {
    const {
      setLoading,
      setTransActionHistory,
      addTransActionHistory,
      updateTransActionHistory_donation,
      updateTransActionHistory_withdrawal
    } = bindActionCreators(actionCreators, dispatch)

    setLoading(true)
    !update && setTransActionHistory([])
    return authenticatedFetch(url, {
      method,
      headers,
      credentials: 'include'
    })
      .then((response) => {
        responseCode = response.status

        return response.json()
      })
      .then((data) => {
        if (responseCode === 200) {
          if (!data?.transactions?.length > 0) {
            setLoading(false)
            return
          }
          update ? addTransActionHistory(data?.transactions) : setTransActionHistory(data)
          const AllTypes = data?.transactions?.map((transaction) => {
            return transaction?.type
          })
          const uniqueAllTypes = [...new Set(AllTypes)]

          //donationType

          const donationType = data?.transactions?.filter(
            (transaction) => transaction?.type === 'donation'
          )
          const donationType_orgId = donationType?.map((item) => item.organizationId)
          if (donationType.length > 0) {
            dispatch(getOrganizationsByIDs({ organizationIds: donationType_orgId })).then(
              (organizationsData) => {
                if (responseCode === 200) {
                  for (let i = 0; i < data?.transactions.length; i++) {
                    const transaction = data?.transactions[i]
                    for (let j = 0; j < organizationsData?.organizations.length; j++) {
                      const Persons = organizationsData?.organizations[j]

                      const newValue =
                        Persons._id === transaction.organizationId
                          ? {
                              ...transaction,
                              publicName: Persons.publicName
                            }
                          : transaction
                      const currect = [newValue]?.filter((item) => item?.publicName)

                      if (currect.length > 0) {
                        updateTransActionHistory_donation({
                          ...currect[0],
                          name_to: '',
                          name_from: currect[0].publicName
                        })
                      }
                    }

                    if (i < data?.transactions.length) {
                      const found = AllTypesTaken.find((element) => element === 'donation')
                      !found && AllTypesTaken.push('donation')
                    }
                    if (uniqueAllTypes.length === AllTypesTaken.length) {
                      setLoading(false)
                    }
                  }
                }
              }
            )
          }

          // withdrawalType

          const withdrawalType = data?.transactions?.filter(
            (transaction) => transaction?.type === 'withdrawal'
          )
          if (withdrawalType.length > 0) {
            for (let i = 0; i < data?.transactions.length; i++) {
              for (let j = 0; j < withdrawalType?.length; j++) {
                updateTransActionHistory_withdrawal(withdrawalType[j])
              }

              if (i < data?.transactions.length) {
                const found = AllTypesTaken.find((element) => element === 'withdrawal')
                !found && AllTypesTaken.push('withdrawal')
              }

              if (uniqueAllTypes.length === AllTypesTaken.length) {
                setLoading(false)
              }
            }
          }

          return {
            error: false,
            code: responseCode,
            message: data.message
          }
        }

        return {
          error: true,
          code: responseCode,
          message: data.message
        }
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)

        return {
          error: true,
          code: '',
          message: error
        }
      })
  }
}
