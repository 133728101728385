import styled from 'styled-components'
// import config from '../../../../../../../../config.js'

export const MainContainer = styled.div`
  ${'' /* margin-top: 2rem; */}
`

export const BalanceContainer = styled.div`
  background-color: #eee;
  padding: 0.8rem 2rem;
  border-radius: .3rem;
  position: relative;
`

export const BalanceLabel = styled.div`
  display: block;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.small};
  font-weight: ${(props) => props.theme.typography.fontWeight.bold};
  color: ${(props) => props.theme.typography.fontColor.headerCopy};

  @media (max-width: 575px) {
    text-align: center;
  }
`

export const BalanceValue = styled.div`
  display: block;
  margin-top: 0.1rem;

  & > img {
    display: inline-block;
    height: 1.3rem;
    margin-top: 0.2rem;
    margin-right: 0.5rem;
    vertical-align: middle;
  }

  & > span {
    display: inline-block;
    vertical-align: middle;
    font-family: ${(props) => props.theme.typography.fontFamily};
    font-family: ${(props) => props.theme.typography.fontFamily};
    font-size: ${(props) => props.theme.typography.fontSizes.extraLarge};
    font-weight: ${(props) => props.theme.typography.fontWeight.bold};
    color: ${(props) => props.theme.palette.secondary.main};
  }

  @media (max-width: 575px) {
    text-align: center;
  }
`
