import React from 'react'
import { Button, CircularProgress } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'

const useStyles = makeStyles((theme) =>
  createStyles({
    primaryButton: {
      display: 'inline-block',
      width: '15rem',
      height: '3.5rem',
      borderRadius: '.3rem',
      fontSize: '0.9375rem', // 15px
      textTransform: 'capitalize',
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.light
      },
      '@media (max-width: 650px)': {
        display: 'block',
        width: '16rem',
        height: '3rem',
        margin: '1rem auto 0 auto',
        fontSize: '0.875rem' // 14px
      },
      '@media (max-width: 400px)': {
        width: '100%'
      }
    },
    circularProgress: {
      display: 'block',
      margin: '.2rem auto 0 auto',
      width: '18px !important',
      height: '18px !important',
      '& svg': {
        color: theme.palette.text.primary
      }
    }
  })
)

function BeginVerificationButton(props) {
  const classes = useStyles()
  const { processing, beginVerification, documentVerified } = props

  return (
    <Button
      variant="contained"
      color="primary"
      className={classes.primaryButton}
      onClick={beginVerification}
      disabled={processing}>
      {processing ? (
        <CircularProgress color="primary" className={classes.circularProgress} />
      ) : documentVerified ? (
        'Update Verification'
      ) : (
        'Begin Verification'
      )}
    </Button>
  )
}

export default BeginVerificationButton
