import styled from 'styled-components'
// import config from '../../../../../../config.js'

export const MainContainer = styled.div`
  background-color: ${(props) => props.theme.palette.common.white};
  padding: 1.5rem;
  /* margin-top: 1rem; */
  margin-bottom: 1.5rem;
  /* border-radius: 0.3rem; */
  box-shadow: 0px 5px 5px -2px rgba(0, 0, 0, 0.2);
`

export const HeadingPrimary = styled.p`
  line-height: 2.2rem;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: 1.6rem;
  font-size: 1.05rem;
  font-weight: ${(props) => props.theme.typography.fontWeight.bold};
  color: ${(props) => props.theme.palette.text.primary};

  @media screen and (max-width: 700px) {
    font-size: 1.05rem;
    line-height: unset;
  }

  @media screen and (max-width: 380px) {
    font-size: 0.95rem;
    line-height: unset;
  }
`
