// import _ from 'lodash'
// import config from '../../../../../../../config.js'
// import nestedSort from '../../../../../../../utils/functions/nestedSort.js'

const load = (state, setState, actions) => {
  // Only load payment details if user has payment info on Stripe
  // let paymentPlan = {}
  // let subscription = {}
  let customer = {}
  let paymentMethods = []

  actions
    .getPaymentDetails()
    .then((data1) => {
      if (!data1.error) {
        console.log('[SUCCESS]: ', 'Successfully retrieved payment details.')

        // const latestSubscription = nestedSort(data1.subscriptions, -1, 'created')[0] || {}
        // const stripePlanId = latestSubscription.plan ? latestSubscription.plan.id : ''
        // const existingPlan = _.find(config.stripe.paymentPlans, { id: stripePlanId })

        // paymentPlan = existingPlan ? { ...existingPlan } : {}
        // subscription = latestSubscription ? { ...latestSubscription } : {}
        customer = { ...data1.customer }
        paymentMethods = [...data1.paymentMethods]
      } else {
        console.log('[FAIL]: ', 'Failed to retrieve payment details.')
      }

      setState({
        ...state,
        status: {
          ...state.status,
          pageLoaded: true,
          // updateSubscriptionDialog: false
        },
        customer,
        // subscription,
        // paymentPlan,
        paymentMethods,
        // previewedInvoice: {},
        error: ''
      })
    })
    .catch((e) => {
      console.log('[ERROR]: Failed to load billing details --> ', e)
    })
}

export default load
