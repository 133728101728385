import styled from 'styled-components'
// import config from '../../../../config.js'

export const TakeEditContainer = styled.div`
  margin-bottom: 1rem;
`

export const TextareaContainer = styled.div`
  display: block;
  position: relative;
  margin-top: 1rem;
`

export const StyledTextarea = styled.textarea`
  background-color: #fafafa;
  display: block;
  width: 100%;
  height: 7rem;
  padding: 1.7rem;
  box-sizing: border-box;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  color: ${(props) => props.theme.typography.fontColor.bodyCopy};
  border: 1.5px solid #eaeaea;
  border-radius: 0.2rem;
  box-shadow: 0px 7px 5px -2px rgba(130, 130, 130, 0.1);

  // resize: none;
`

export const TextCount = styled.span`
  display: block;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.small};
  font-weight: ${(props) => props.theme.typography.fontWeight.medium};
  color: ${(props) => props.theme.typography.fontColor.headerCopy};
  text-align: end;
  padding: 0.25rem 0;
`

export const EditSelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

export const SubmitContainer = styled.div`
  display: flex;
`

export const EmotionMessage = styled.p`
  margin-top: 0.5rem;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.smaller};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  color: ${(props) => props.theme.palette.text.primary};
`

export const Label = styled.label`
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.regular};
  font-weight: ${(props) => props.theme.typography.fontWeight.bold};
  color: ${(props) => props.theme.palette.text.primary};
  margin: 0.5rem 0;
`
