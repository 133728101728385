import { accoladeActionTypes } from '../action-creators/accolades.js'

const copyAccolades = (accolades) => {
  return accolades.map((accolade) => {
    return { ...accolade }
  })
}

// FOR ACCOLADES
const accoladesReducer = (state = [], action) => {
  switch (action.type) {
    case accoladeActionTypes.SET_ACCOLADES:
      return copyAccolades(action.accolades)
    case accoladeActionTypes.CLEAR_ACCOLADES:
      return []
    default:
      return state
  }
}

export default accoladesReducer
