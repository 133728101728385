const getName = (obj, subname, options = {}) => {
  if (!obj) return ''

  const { nickname, fname, lname } = obj

  if (!fname && !lname && !options.preventDefaultName) {
    return `CivilTalk Member`
  }

  if (subname === 'last') return lname

  if (nickname) {
    if (subname === 'first') {
      return nickname
    }

    return `${nickname} ${lname}`
  } else {
    if (subname === 'first') {
      return fname
    }

    return `${fname} ${lname}`
  }
}

export default getName
