import styled from 'styled-components'
import theme from '../../../../../../../res/theme.js'
// import config from '../../../../../../config.js'

export const PaymentInfoContainer = styled.div`
  display: block;
  ${'' /* margin-top: 2rem; */}
  font-size: 0;

  & > div:not(:first-child) {
    margin-top: 2rem;
  }
`

export const SectionHeader = styled.span`
  display: inline-block;
  padding-bottom: 0.2rem;
  font-family: ${theme.typography.fontFamily};
  font-size: 1.4rem;
  font-weight: ${theme.typography.fontWeight.bold};
  color: ${theme.typography.fontColor.bodyCopy};
  ${'' /* border-bottom: 2.5px solid #cc0000; */}
`
