import React from 'react'
import { useFirestoreConnect } from 'react-redux-firebase'

/**
 * @function Listeners
 * - Component that sets up firebase listeners
 * - returns empty component
 */

function Listeners(props) {
  const { auth } = props

  /** connect to Firestore using the hook provided by 'react-redux-firebase' */
  /** this is supposed to set and unset each listener for you auto-magically! */
  useFirestoreConnect([{ collection: 'userMeta', doc: auth.uid }])

  return <></>
}

export default Listeners
