import config from '../../../config'
import { currentUserActions } from '../../action-creators/current-user.js'
import { actionResponseActions } from '../../action-creators/action-response.js'
import { loaderActions } from '../../action-creators/loader.js'
import { authenticatedFetch } from '../../../utils/functions/authenticatedFetch'

/*
 * METHOD TO UPDATE PHONE
 */
export const updatePhone = (params) => {
  let responseCode = ''
  const method = 'PUT'
  const url = `${config.baseURL}/api/user/phone`
  const headers = {
    'Content-Type': 'application/json'
  }
  const requestBody = {
    countryCode: params.countryCode,
    phone: params.phone,
    code: params.code
  }
  const requestBodyJSON = JSON.stringify(requestBody)

  // Thunk middleware knows how to handle functions.
  // It passes the dispatch method as an argument to the function,
  // thus making it able to dispatch actions itself.
  return (dispatch) => {
    console.log('Sending update phone request to CivilTalk')

    dispatch(loaderActions.startLoader())

    // Return the fetch so react components calling 'store.dispatch()' can use 'then()'
    return authenticatedFetch(url, {
      method,
      body: requestBodyJSON,
      headers,
      credentials: 'include'
    })
      .then((response) => {
        dispatch(loaderActions.stopLoader())
        responseCode = response.status

        return response.json()
      })
      .then((data) => {
        if (responseCode === 200) {
          dispatch(currentUserActions.setUser(data.user))
          dispatch(actionResponseActions.createActionResponse('Successfully updated phone!'))

          return {
            error: false,
            code: responseCode,
            message: data.message
          }
        } else {
          console.log(data.message)

          return {
            error: true,
            code: responseCode,
            message: data.message
          }
        }
      })
      .catch((error) => {
        console.log(error)

        dispatch(loaderActions.stopLoader())

        return {
          error: true,
          code: '',
          message: error
        }
      })
  }
}
