import jwtDecode from 'jwt-decode'
import { refresh } from '../users/refresh.js'
import { logout } from '../users/logout.js'
import { refreshPromiseActions } from '../../action-creators/refresh-promise.js'
import { tokenExpActions } from '../../action-creators/token-exp.js'

/*
 * METHOD TO BEGIN REFRESHING TOKEN AND SAVE PROMISE IN REDUCER
 * This helps us track if we are currently refreshing a token or not
 */
export const beginRefresh = (dispatch) => {
  const promise = refresh()
    .then((data) => {
      console.log(data.message)

      dispatch(refreshPromiseActions.clear())

      if (data.error) {
        dispatch(logout())

        return Promise.reject({
          message: 'Failed to refresh token.'
        })
      }

      dispatch(tokenExpActions.set(jwtDecode(data.access_token).exp))
      // access_token should automatically be set in cookies
      // sessionStorage.setItem('ct_access_token', data.access_token);
      return Promise.resolve({})
    })
    .catch((error) => {
      console.log('[ERROR]: ', error)

      dispatch(logout())

      return Promise.reject(error)
    })

  dispatch(refreshPromiseActions.set(promise))

  return promise
}
