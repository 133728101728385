import CollapsedContent from '../../../../../partials/collapsed-content/collapsed-content'
import getOrgTypeString from '../../../../../../utils/functions/getOrgTypeString'
import {
  ArticleContainer,
  ArticleHeader,
  RedArticleHeader,
  WhiteBackgroundContainer,
  Row,
  Label,
  ValueContainer,
  Value,
  AboutCopyContainer
} from '../styled/org-details-comps'

function About(props) {
  const { organization, isMobile } = props
  const {
    publicName,
    about,
    missionAndVision,
    website,
    donationLink,
    email,
    phone,
    type,
    address
  } = organization
  const normalizedWebsite = website.indexOf('//') !== -1 ? website : `//${website}`
  const normalizedDonationLink =
    donationLink.indexOf('//') !== -1 ? donationLink : `//${donationLink}`

  return (
    <>
      <ArticleContainer>
        <ArticleHeader>About {publicName}:</ArticleHeader>

        {about && isMobile && (
          <ArticleContainer>
            <RedArticleHeader>Description</RedArticleHeader>
            
            <WhiteBackgroundContainer>
              <AboutCopyContainer>
                <CollapsedContent
                  content={about}
                  rows={5}
                  color={'#333'}
                  fontWeight={400}
                  fontSize={'0.9375rem'}
                  lineHeight={'1.4rem'}
                  collapsible={true}
                />
              </AboutCopyContainer>
              {/**
                <AboutCopyContainer marginTop="0.5rem">
                <CollapsedContent
                content={orgType}
                rows={1}
                color={'#333'}
                fontWeight={400}
                fontSize={'0.9375rem'}
                lineHeight={'1.4rem'}
                collapsible={true}
                />
                </AboutCopyContainer>
              */}
            </WhiteBackgroundContainer>
          </ArticleContainer>
        )}

        {missionAndVision && (
          <ArticleContainer>
            <RedArticleHeader>Mission and Vision</RedArticleHeader>
            <WhiteBackgroundContainer>
              <AboutCopyContainer>
                <CollapsedContent
                  content={missionAndVision}
                  rows={5}
                  color={'#333'}
                  fontWeight={400}
                  fontSize={'0.9375rem'}
                  lineHeight={'1.4rem'}
                  collapsible={true}
                />
              </AboutCopyContainer>
            </WhiteBackgroundContainer>
          </ArticleContainer>
        )}

        <ArticleContainer>
          <RedArticleHeader>Details</RedArticleHeader>
          <WhiteBackgroundContainer>
            {website && (
              <Row>
                <Label>Website:</Label>

                <ValueContainer>
                  <Value>
                    <a href={normalizedWebsite} target="_blank" rel="noreferrer">
                      {website}
                    </a>
                  </Value>
                </ValueContainer>
              </Row>
            )}

            {donationLink && (
              <Row>
                <Label>Donate:</Label>

                <ValueContainer>
                  <Value>
                    <a href={normalizedDonationLink} target="_blank" rel="noreferrer">
                      {donationLink}
                    </a>
                  </Value>
                </ValueContainer>
              </Row>
            )}

            {email && (
              <Row>
                <Label>Email:</Label>

                <ValueContainer>
                  <Value>
                    <a href={`mailto:${email}`}>{email}</a>
                  </Value>
                </ValueContainer>
              </Row>
            )}

            {phone?.nationalFormat && (
              <Row>
                <Label>Phone:</Label>

                <ValueContainer>
                  <Value>{phone?.nationalFormat}</Value>
                </ValueContainer>
              </Row>
            )}

            {address.deliveryLine1 && address.lastLine && (
              <Row>
                <Label>Address:</Label>

                <ValueContainer>
                  <Value>{address.deliveryLine1}</Value>

                  {address.deliveryLine2 && <Value>{`${address?.deliveryLine2}`}</Value>}

                  <Value>{address.lastLine}</Value>
                </ValueContainer>
              </Row>
            )}

            {type && (
              <Row>
                <Label>Type:</Label>

                <ValueContainer>
                  <Value>{getOrgTypeString(type)}</Value>
                </ValueContainer>
              </Row>
            )}
          </WhiteBackgroundContainer>
        </ArticleContainer>
      </ArticleContainer>
    </>
  )
}

export default About
