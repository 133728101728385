import config from '../../../config'
import { followsActions } from '../../action-creators/follows.js'
// import { loadedProfilesActions } from '../../action-creators/loaded-profiles.js'
// import { currentProfileActions } from '../../action-creators/current-profile.js'
import { actionResponseActions } from '../../action-creators/action-response.js'
import { authenticatedFetch } from '../../../utils/functions/authenticatedFetch'

/*
 * METHOD TO CREATE ORGANIZATION FOLLOW
 */
export const createOrganizationFollow = (params) => {
  let responseCode = ''
  const method = 'POST'
  const url = `${config.baseURL}/api/follow/organization`
  const headers = {
    'Content-Type': 'application/json'
  }
  const requestBody = {
    organizationId: params.organizationId
  }
  const requestBodyJSON = JSON.stringify(requestBody)

  // Thunk middleware knows how to handle functions.
  // It passes the dispatch method as an argument to the function,
  // thus making it able to dispatch actions itself.
  return (dispatch) => {
    console.log('Sending create organization follow request to CivilTalk')

    // Return the fetch so react components calling 'store.dispatch()' can use 'then()'
    return authenticatedFetch(url, {
      method,
      body: requestBodyJSON,
      headers,
      credentials: 'include'
    })
      .then((response) => {
        responseCode = response.status

        return response.json()
      })
      .then((data) => {
        if (responseCode === 201) {
          // dispatch(loadedProfilesActions.addFollower(params.userId))
          dispatch(followsActions.add([data.follow]))
          // dispatch(currentProfileActions.addFollowing())
          dispatch(
            actionResponseActions.createActionResponse("You're now following " + params.name)
          )

          return {
            error: false,
            code: responseCode,
            message: data.message,
            follow: data.follow
          }
        } else {
          console.log(data.message)

          return {
            error: true,
            code: responseCode,
            message: data.message
          }
        }
      })
      .catch((error) => {
        console.log(error)

        return {
          error: true,
          code: '',
          message: error
        }
      })
  }
}
