import styled from 'styled-components'
// import config from '../../../../config.js'

export const HeroContainer = styled.div`
  background-color: ${(props) => props.theme.palette.secondary.light};
  position: relative;
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  @media (max-width: 899px) {
    height: 60vh;
  }

  @media (max-width: 599px) {
    height: 50vh;
  }
`

export const InnerContainer = styled.div`
  display: block;
  max-width: 80rem;
  padding: 5rem 0;
  margin: 0 auto;
  text-align: right;
  position: relative;

  @media (min-height: 1000px) {
    padding: 10rem 0;
  }

  @media (max-width: 1100px) {
    text-align: center;
    padding: 5rem 0;
  }

  @media (max-width: 600px) {
    padding: 3rem 0;
  }
`

export const TitleContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  text-align: left;

  @media (max-width: 1500px) {
    padding-left: 5rem;
  }

  @media (max-width: 1100px) {
    display: none;
  }
`

export const Title = styled.h1`
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: 2.5rem;
  font-weight: ${(props) => props.theme.typography.fontWeight.bold};
  color: ${(props) => props.theme.palette.common.white};

  @media (max-width: 1100px) {
    display: none;
  }
`

export const SubTitle = styled.h2`
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: 2.5rem;
  font-weight: ${(props) => props.theme.typography.fontWeight.bold};
  color: ${(props) => props.theme.palette.common.white};

  @media (max-width: 1100px) {
    display: none;
  }
`
