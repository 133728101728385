import React, { useState } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { actionResponseActions } from '../../../../redux/action-creators/action-response'
import getThumbnailUrlFromMedia from '../../../../utils/functions/getThumbnailUrlFromMedia'
import CollapsedContent from '../../../partials/collapsed-content/collapsed-content'
import PropTypes from 'prop-types'
import withStyles from '@mui/styles/withStyles'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Grow from '@mui/material/Grow'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import MenuList from '@mui/material/MenuList'
import MenuItem from '@mui/material/MenuItem'
import MenuIcon from '@mui/icons-material/MoreHoriz'
import VisitPageIcon from '@mui/icons-material/ExitToApp'
import LinkIcon from '@mui/icons-material/Link'
import VideoIcon from '@mui/icons-material/PlayCircleOutline'
import ArticleIcon from '@mui/icons-material/ChromeReaderMode'
import CancelIcon from '@mui/icons-material/Cancel'
import linkIcon from '../../../../media/icons/link-icon.png'
import {
  MediaContainer,
  SelectedMedia,
  NavItemContainer,
  NavItem,
  SelectedMediaTitle,
  SelectedMediaSubtitle,
  UnselectedMediaContainer,
  UnselectedMedia,
  Icon
} from './styled/gallery'

const styles = (theme) => ({
  dropdownButton: {
    backgroundColor: 'rgba(0,0,0,.2)',
    borderRadius: '.1rem',
    minHeight: '1.75rem',
    height: '1.75rem',
    minWidth: '2.5rem',
    width: '2.5rem',
    position: 'absolute',
    top: '1rem',
    right: '1rem',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,.3)',
      boxShadow: 'none'
    },
    '& svg': {
      color: '#ffffff',
      fontSize: '1.5rem',
      position: 'absolute'
    }
  },
  menuList: {
    padding: '.4rem 0',
    width: '12rem'
  },
  dropdownItem: {
    padding: '.4rem 1rem'
  },
  dropdownIcon: {
    width: '1rem',
    height: 'auto',
    marginRight: '.7rem',
    marginTop: '1px',
    verticalAlign: 'middle'
  },
  caret: {
    display: 'inline-block',
    marginRight: '.3rem',
    verticalAlign: 'middle',
    fontSize: '1.2rem'
  },
  mediaTypeIcon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translateX(-50%) translateY(-50%)',
    boxShadow: 'none',
    color: 'white',
    fontSize: '2rem'
  },
  playVideoIcon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translateX(-50%) translateY(-50%)',
    boxShadow: 'none',
    color: 'white',
    fontSize: '5rem',
    cursor: 'pointer'
  },
  iconButton: {
    position: 'absolute',
    top: '-21px',
    right: '-14px',
    zIndex: 100,
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.7)'
    }
  },
  cancelIcon: {
    color: theme.palette.primary.main,
    zIndex: 101
  }
})

// Converts array of media items to array of media source URLS
const getThumbnailUrls = (media) => {
  let thumbnailUrls = []

  for (var i = 0; i < media.length; i++) {
    const mediaItem = media[i]
    const thumbnailUrl = getThumbnailUrlFromMedia(mediaItem)

    thumbnailUrls.push(thumbnailUrl)
  }

  return thumbnailUrls
}

const Gallery = (props) => {
  const { setActionResponse, classes, media, editable, handleRemoveMedia } = props
  const [showSelected, setShowSelected] = useState(false)
  const [selected, setSelected] = useState(0)
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const buttonEl = React.useRef(null)
  const thumbnailUrls = getThumbnailUrls(media)
  let selectedMedia = media[selected]
  let publishedAt = ''
  let videoSrc = ''
  let unselectedMedia = []

  // Grab video src from embedHtml
  if (typeof selectedMedia !== 'undefined') {
    publishedAt = moment(selectedMedia.publishedAt).format('ddd MMM Do YYYY')

    if (selectedMedia.type === 'video') {
      const parser = new DOMParser()
      const parsedIframe = parser.parseFromString(selectedMedia.embedHtml, 'text/html')
      const iFrame = parsedIframe.getElementsByTagName('iframe')

      videoSrc = iFrame[0].src
    }
  }

  const handleShowSelected = (index) => {
    showSelected && index === selected ? setShowSelected(false) : setShowSelected(true)
  }

  // Function called when clicking on menu icon to dropdown menu
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen)
  }

  // Function called when closing dropdown
  const closeDropdown = () => {
    setDropdownOpen(false)
  }

  // Function called when closing dropdown
  const handleClipboardCopy = () => {
    setDropdownOpen(false)
    setActionResponse('Copied to Clipboard!')
  }

  // Function called when selecting an unselected media thumbnail
  // Resets video players and changes selected media item.
  const handleSelectNewMedia = (index) => {
    handleShowSelected(index)
    setSelected(index)
  }

  // remove this media from the parents array of media items..
  const handleRemove = (media) => {
    handleRemoveMedia(media)
  }

  // Create unselected media components
  for (var i = 0; i < media.length; i++) {
    const index = i

    // Create Media Thumbnails
    unselectedMedia.push(
      <MediaContainer key={i}>
        {editable && (
          <IconButton
            className={classes.iconButton}
            onClick={() => handleRemove(media[index])}
            size="large">
            <CancelIcon className={classes.cancelIcon} />
          </IconButton>
        )}
        <UnselectedMedia
          key={index}
          id={index}
          bgURL={thumbnailUrls[index]}
          onClick={() => handleSelectNewMedia(index)}
          selected={selected === index && showSelected}
          style={index < media.length ? { marginRight: '.8rem' } : {}}>
          {media[index].type === 'video' && <VideoIcon className={classes.mediaTypeIcon} />}
          {media[index].type === 'article' && <ArticleIcon className={classes.mediaTypeIcon} />}
        </UnselectedMedia>
        {!editable && (
          <Icon backgroundColor="link" style={{ backgroundImage: `url(${linkIcon})` }} />
        )}
      </MediaContainer>
    )
  }

  return (
    <div>
      {typeof selectedMedia !== 'undefined' && (
        <SelectedMedia
          bgURL={thumbnailUrls[selected]}
          style={
            showSelected ? { paddingTop: '56.25%', width: '100%' } : { paddingTop: '0', width: 0 }
          }>
          {selectedMedia.type === 'article' && (
            <div>
              <Button
                variant="contained"
                color="secondary"
                className={classes.dropdownButton}
                ref={buttonEl}
                aria-owns={dropdownOpen ? 'article-dropdown' : undefined}
                aria-haspopup="true"
                onClick={toggleDropdown}>
                <MenuIcon fontSize="large" />
              </Button>

              <Popper
                open={dropdownOpen}
                anchorEl={buttonEl.current}
                placement="bottom-end"
                transition
                style={{ zIndex: 100 }}>
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    id="menu-dropdown"
                    style={{
                      transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
                    }}>
                    <Paper>
                      <ClickAwayListener onClickAway={closeDropdown}>
                        <MenuList className={classes.menuList}>
                          <CopyToClipboard text={selectedMedia.url} onCopy={handleClipboardCopy}>
                            <MenuItem className={classes.dropdownItem}>
                              <NavItemContainer>
                                <LinkIcon className={classes.dropdownIcon} />
                                <NavItem>Copy URL</NavItem>
                              </NavItemContainer>
                            </MenuItem>
                          </CopyToClipboard>

                          <MenuItem className={classes.dropdownItem}>
                            <NavItemContainer>
                              <VisitPageIcon className={classes.dropdownIcon} />
                              <a
                                href={
                                  selectedMedia.type === 'article'
                                    ? selectedMedia.url
                                    : selectedMedia.contentUrl
                                }
                                target="_blank"
                                rel="noopener noreferrer">
                                <NavItem>Visit Full Article</NavItem>
                              </a>
                            </NavItemContainer>
                          </MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>

              <SelectedMediaTitle>
                <a href={selectedMedia.url} target="_blank" rel="noopener noreferrer">
                  <CollapsedContent
                    content={selectedMedia.name}
                    rows={1}
                    color={'#333'}
                    fontSize={'1.1rem'}
                    fontWeight={400}
                    lineHeight={'1.5rem'}
                  />
                </a>
                <SelectedMediaSubtitle>{'Published ' + publishedAt}</SelectedMediaSubtitle>
              </SelectedMediaTitle>
            </div>
          )}

          {showSelected && selectedMedia.type === 'video' && (
            <iframe
              src={videoSrc}
              frameBorder="0"
              title={selectedMedia.title}
              allow="fullscreen"
              allowFullScreen
              width="100"
              height="400"
            />
          )}
        </SelectedMedia>
      )}

      <UnselectedMediaContainer style={showSelected ? { marginTop: '.8rem' } : { marginTop: '0' }}>
        {unselectedMedia}
      </UnselectedMediaContainer>
    </div>
  )
}

Gallery.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapDispatchToProps = (dispatch) => ({
  setActionResponse: (message) => {
    return dispatch(actionResponseActions.createActionResponse(message))
  }
})

export default connect(null, mapDispatchToProps)(withStyles(styles)(Gallery))
