import config from '../../../config'
import { loadedTakesActions } from '../../action-creators/loaded-takes.js'
import { currentProfileActions } from '../../action-creators/current-profile.js'
import { actionResponseActions } from '../../action-creators/action-response.js'
import { loaderActions } from '../../action-creators/loader.js'
import { authenticatedFetch } from '../../../utils/functions/authenticatedFetch'

/*
 * METHOD TO CREATE HOT TAKE
 */
export const createTake = (params) => {
  const {
    take,
    postImage,
    tagIds,
    emotion,
    isQuestion,
    location,
    visibleTo,
    discussionLimit,
    currentProfile,
    courseId,
    promptId
  } = params

  let responseCode = ''
  const method = 'POST'
  const url = `${config.baseURL}/api/take/`

  const formData = new FormData()
  formData.append('take', take)
  formData.append('emotion', emotion)
  formData.append('file', postImage)
  formData.append('tagIds', tagIds)
  formData.append('isQuestion', isQuestion)
  formData.append('location', JSON.stringify(location))
  formData.append('visibleTo', JSON.stringify(visibleTo))
  formData.append('discussionLimit', discussionLimit)
  formData.append('courseId', courseId)
  formData.append('promptId', promptId)

  const requestBody = formData

  // Thunk middleware knows how to handle functions.
  // It passes the dispatch method as an argument to the function,
  // thus making it able to dispatch actions after the API response.
  return (dispatch) => {
    console.log('Sending create take request to CivilTalk')

    dispatch(loaderActions.startLoader())

    // Return the fetch so react components calling 'store.dispatch()' can use 'then()'
    return authenticatedFetch(url, {
      method,
      body: requestBody, // requestBodyJSON,
      credentials: 'include'
      // headers
    })
      .then((response) => {
        dispatch(loaderActions.stopLoader())
        responseCode = response.status

        return response.json()
      })
      .then((data) => {
        if (responseCode === 201) {
          if (params.enableRedux) {
            dispatch(loadedTakesActions.addTakes([data.take]))
          }

          dispatch(
            currentProfileActions.updateProfile({
              numPublicTakes: currentProfile.numPublicTakes + 1
            })
          )

          return {
            error: false,
            code: responseCode,
            message: data.message,
            take: data.take,
            firstTakeOnTopic: data.firstTakeOnTopic // boolean
          }
        } else {
          console.log(data.message)

          return {
            error: true,
            code: responseCode,
            message: data.message
          }
        }
      })
      .then((nextData) => {
        if (nextData.error) {
          return nextData
        } else {
          // TODO: we no longer track first take on topic as topic is an old concept - schedule for deletion
          if (nextData.firstTakeOnTopic) {
            dispatch(actionResponseActions.createActionResponse(`You just earned 10 points!`))

            return nextData
          } else {
            dispatch(actionResponseActions.createActionResponse('You just earned 20 points!'))

            return nextData
          }
        }
      })
      .catch((error) => {
        console.log(error)

        dispatch(loaderActions.stopLoader())

        return {
          error: true,
          code: '',
          message: error
        }
      })
  }
}
