import { currentUserActionTypes } from '../action-creators/current-user.js'
import setNestedPropFromString from '../../utils/functions/setNestedPropFromString.js'

// FOR CURRENT USER
const currentUserReducer = (state = {}, action) => {
	switch (action.type) {
		case currentUserActionTypes.SET_CURRENT_USER:
			return {...action.user}
		case currentUserActionTypes.UPDATE_CURRENT_USER:
			var newUser = {...state}
			let keys = Object.keys(action.fields)

			for (var i = 0; i < keys.length; i++) {
                setNestedPropFromString(newUser, action.fields[keys[i]], keys[i])
			}

			newUser.whenModified = Date.now()

			return newUser
		case currentUserActionTypes.CLEAR_CURRENT_USER:
			return {}
		default:
			return state
	}
}

export default currentUserReducer