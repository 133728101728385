import { deleteFollowByUserID } from '../../../../../../redux/thunks/follows/deleteFollowByUserID.js'
import getName from '../../../../../../utils/functions/getName.js'

const unfollowUser = (profile, currentUser, setProcessing, dispatch) => {
  if (!profile) {
    throw new Error('Invalid params <profile> in unfollowUser')
  }

  if (!setProcessing) {
    throw new Error('Invalid params <setProcessing> in unfollowUser')
  }

  if (!dispatch) {
    throw new Error('Invalid params <dispatch> in unfollowUser')
  }

  const { userId } = profile
  setProcessing(true)

  dispatch(
    deleteFollowByUserID({
      userId,
      currUserId: currentUser.id,
      name: getName(profile)
    })
  )
    .then((data) => {
      if (!data.error) {
        console.log('[SUCCESS]: ', 'Successfully unfollowed user!')
      } else {
        console.log('[FAIL]: ', 'Failed to unfollow user.')
      }

      setProcessing(false)
    })
    .catch((e) => {
      setProcessing(false)
    })
}

export default unfollowUser
