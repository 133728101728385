const updateLocationValidation = (fields) => {
  const { street, city, state, zipCode } = fields

  if (!street) {
    return {
      error: 'Address line 1 is required.'
    }
  }

  if (!city) {
    return {
      error: 'City is required.'
    }
  }

  if (!state) {
    return {
      error: 'State is required.'
    }
  }

  if (!zipCode) {
    return {
      error: 'Zip code is required.'
    }
  }

  return {
    error: '',
    field: ''
  }
}

export default updateLocationValidation
