import { useEffect, useState } from 'react'
import { useParams, withRouter } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import find from 'lodash/find'
import { updateOrganizationName } from '../../../../../../../redux/thunks/organizations/updateOrganizationName'
import UpdatePublicNameValidation from './validations/update-name'
import FormError from '../../../../../../alerts/form-error'
import makeStyles from '@mui/styles/makeStyles'
import Button from '@mui/material/Button'
import { ItemContainer, InputLabel, InputsContainer, Input } from './styled/public-name-form-comps'
import config from '../../../../../../../config'

const useStyles = makeStyles((theme) => ({
  actionButton: {
    textTransform: 'capitalize',
    width: '15rem',
    margin: '1rem 0 0 35%',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light
    },
    [`@media (max-width: ${config.UI.drawer_breakpoint.max})`]: {
      width: '65%'
    },
    '@media (max-width: 600px)': {
      width: '100%',
      margin: '1rem 0 0 0'
    }
  },
  helpIcon: {
    display: 'inline-block',
    verticalAlign: 'middle',
    margin: '0 0 .2rem .9rem',
    fontSize: '1.1rem',
    color: theme.palette.secondary.main,
    cursor: 'pointer'
  },
  arrow: {
    color: theme.palette.secondary.main
  },
  tooltip: {
    backgroundColor: theme.palette.secondary.light,
    padding: '1rem',
    color: theme.palette.text.primary,
    fontSize: '.925rem',
    fontWeight: 400,
    marginBottom: '1rem'
  }
}))

function PublicNameForm() {
  const classes = useStyles()
  const { orgId } = useParams()
  const organizations = useSelector(({ organizations }) => organizations)
  const organization = find(organizations, { _id: orgId })
  const dispatch = useDispatch()
  const [publicName, setPublicName] = useState('')
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState('')

  useEffect(() => {
    if (organization) {
      setPublicName(organization.publicName)
    }
  }, [organization])

  const handleInputChange = (e) => {
    setPublicName(e.target.value)
  }

  const handleUpdateName = () => {
    setProcessing(true)

    const validationError = UpdatePublicNameValidation(publicName)

    if (validationError.error !== error) {
      console.log('[VALIDATION ERROR]: ', error)

      setError(validationError.error)
      // *** FormError component will display error to the user *** //
    } else {
      dispatch(updateOrganizationName({ publicName, orgId })).then((data) => {
        if (!data.error) {
          console.log('[SUCCESS]: ', 'Successfully updated public name!')

          setProcessing(false)
        } else {
          console.log('[FAIL]: ', 'Failed to update public name.')

          setProcessing(false)
        }
      })
    }
  }

  if (!organization) return null

  return (
    <ItemContainer>
      <FormError message={error} />

      <InputLabel>Public Name:</InputLabel>

      <>
        <InputsContainer>
          <Input
            type="text"
            name="publicName"
            placeholder="Enter new public name"
            value={publicName}
            width="15rem"
            onChange={handleInputChange}
          />
        </InputsContainer>

        <Button
          variant="contained"
          color="primary"
          className={classes.actionButton}
          onClick={handleUpdateName}
          disabled={publicName.trim() === organization?.publicName || processing}>
          Submit
        </Button>
      </>
    </ItemContainer>
  )
}

export default withRouter(PublicNameForm)
