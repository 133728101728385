import { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { analytics } from '../../../firebase/firebase'
import { history } from '../../../history/history'
import { createFeedback } from '../../../redux/thunks/feedback/createFeedback'
import SupportValidation from './validations/support'
import withStyles from '@mui/styles/withStyles'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import {
  MainContainer,
  BackGroundContainer,
  InnerHeroContainer,
  ContentContainer,
  Title,
  TabContainer,
  Tab,
  InputContainer,
  Input,
  Textarea,
  ButtonContainer,
  VideosHint
} from './components/styled/support-comps'
import config from '../../../config'
import FooterComp from './components/footer/footer'

const styles = (theme) => ({
  button: {
    display: 'block',
    width: '12rem',
    height: '3rem',
    position: 'absolute',
    borderRadius: '0',
    fontSize: '1rem',
    maxWidth: '100%',
    right: 0,
    [theme.breakpoints.down('md')]: {
      position: 'static',
      margin: '0 auto'
    }
  }
})

class Support extends Component {
  constructor(props) {
    super(props)

    this.state = {
      fields: {
        type: 0,
        subject: '',
        desc: ''
      },
      tab: 'bug',
      error: ''
    }

    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.handleGoToVideos = this.handleGoToVideos.bind(this)
  }

  handleInputChange(event) {
    let target = event.target
    let name = target.name

    this.setState({
      ...this.state,
      fields: {
        ...this.state.fields,
        [name]: target.value
      }
    })
  }

  handleSubmit(event, source) {
    const { error } = SupportValidation(this.state.fields)

    if (error) {
      console.log('[VALIDATION ERROR]: ', error)

      this.setState({
        ...this.state,
        error
      })
    } else {
      this.props
        .createFeedback({
          type: this.state.fields.type,
          subject: this.state.fields.subject,
          desc: this.state.fields.desc
        })
        .then((data) => {
          if (!data.error) {
            console.log('[SUCCESS]: ', 'Successfully submitted feedback!')

            const oldType = this.state.fields.type

            this.setState({
              ...this.state,
              fields: {
                ...this.state.fields,
                type: oldType,
                subject: '',
                desc: ''
              },
              error: ''
            })
          } else {
            console.log('[FAIL]: ', 'Failed to submit feedback!')

            this.setState({
              ...this.state,
              error: data.message
            })
          }
        })
    }

    analytics.logEvent('send_feedback')
  }

  handleClick(tab) {
    let type = 0

    switch (tab) {
      case 'bug':
        type = 0
        break

      case 'content':
        type = 1
        break

      case 'feedback':
        type = 2
        break

      case 'suggestion':
        type = 3
        break

      default:
        type = 0
        break
    }

    this.setState({
      ...this.state,
      fields: {
        ...this.state.fields,
        type
      },
      tab
    })
  }

  handleGoToVideos() {
    history.push('/videos')
  }

  render() {
    const { classes } = this.props
    const { fields, tab } = this.state

    return (
      <>
        <MainContainer>
          <BackGroundContainer>
            <InnerHeroContainer>
              <ContentContainer>
                <Title>Support and Feedback</Title>

                <TabContainer>
                  <Tab id="bug" onClick={() => this.handleClick('bug')} selected={tab === 'bug'}>
                    Report a Bug
                  </Tab>

                  <Tab
                    id="suggestion"
                    onClick={() => this.handleClick('suggestion')}
                    selected={tab === 'suggestion'}>
                    Make a Suggestion
                  </Tab>
                </TabContainer>

                <InputContainer>
                  <Input
                    type="text"
                    name="subject"
                    placeholder={'Subject'}
                    value={fields.subject}
                    onChange={this.handleInputChange}
                    required
                  />

                  <Textarea
                    name="desc"
                    placeholder="Please provide as much detail as possible so we can help."
                    value={fields.desc}
                    wrap="soft"
                    maxLength="1500"
                    onChange={this.handleInputChange}></Textarea>

                  <ButtonContainer>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      onClick={this.handleSubmit}>
                      Submit
                    </Button>
                  </ButtonContainer>
                </InputContainer>

                <VideosHint>
                  Looking for additional help? Browse our{' '}
                  <a
                    href={`https://docs.google.com/document/d/1UU7PjaIaRRW1Lc6EAwSDSISpIRjFDrqu/edit`}
                    target={'_blank'}
                    rel="noreferrer">
                    User Guide
                  </a>
                </VideosHint>
              </ContentContainer>
            </InnerHeroContainer>
          </BackGroundContainer>
          <FooterComp />
        </MainContainer>
      </>
    )
  }
}

Support.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  currentUser: state.currentUser
})

const mapDispatchToProps = (dispatch) => ({
  createFeedback: (params) => {
    return dispatch(createFeedback(params))
  }
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Support)))
