import React from 'react'
import { useRef } from 'react'
import { history } from '../../../history/history'
import getAvatar from '../../../utils/functions/getAvatar'
import LazyLoad from 'react-lazyload'
import { UserAvatarContainer, Image, Placeholder } from './styled/user-avatar-comps'

function UserAvatar(props) {
  const { src, link, width, height, borderWidth, verified } = props

  const refPlaceholder = useRef()

  const removePlaceholder = () => {
    refPlaceholder.current.remove()
  }

  const handleVisitProfile = () => {
    console.log(`clicked in UserAvatar`)

    if (link) {
      history.push(link)
    }
  }

  return (
    <UserAvatarContainer>
      <Placeholder ref={refPlaceholder} />
      <LazyLoad>
        <Image
          onLoad={removePlaceholder}
          onError={removePlaceholder}
          src={getAvatar(src)}
          alt={'profile image'}
          width={width}
          height={height}
          borderWidth={borderWidth}
          verified={verified}
          onClick={link ? () => history.push(link) : () => {}}
        />
      </LazyLoad>
    </UserAvatarContainer>
  )
}

export default UserAvatar
