import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { isLoaded, isEmpty } from 'react-redux-firebase'
import { actionResponseActions } from '../../redux/action-creators/action-response'
import NotificationListener from './components/notification-listener'
import Snackbar from '@mui/material/Snackbar'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Clear'

function Toaster() {
  const dispatch = useDispatch()
  const auth = useSelector((state) => state.firebase.auth)
  const actionResponse = useSelector((state) => state.actionResponse)

  return (
    <>
      {isLoaded(auth) && !isEmpty(auth) && <NotificationListener />}

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        open={actionResponse === '' ? false : true}
        autoHideDuration={3000}
        onClose={() => dispatch(actionResponseActions.clearActionResponse())}
        ContentProps={{
          'aria-describedby': 'snackbar-message-id'
        }}
        message={<span id="snackbar-message-id">{actionResponse}</span>}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={() => dispatch(actionResponseActions.clearActionResponse())}
            size="large">
            <CloseIcon />
          </IconButton>
        ]}
      />
    </>
  )
}

export default Toaster
