import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import StripeForm from './components/stripe-form/stripe-form'
import theme from '../../../../../../../res/theme.js'

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_KEY)

function Checkout(props) {
  const { setStep, charge, clientSecret, setClientSecret } = props

  const options = {
    // passing the client secret obtained in step 2
    clientSecret,
    // Pass fonts array to be used in custom appearance
    fonts: [
      {
        cssSrc: 'https://use.typekit.net/gmy0vfy.css'
      }
    ],
    // Fully customizable with appearance API.
    appearance: {
      // theme: 'stripe',   // ['stripe', 'night', 'flat', 'none']
      // labels: 'floating',
      variables: {
        colorPrimary: theme.palette.secondary.main,
        // colorBackground: '#ffffff',
        colorText: theme.typography.fontColor.bodyCopy,
        colorDanger: '#cc0000',
        fontFamily: theme.typography.fontFamily,
        spacingUnit: '4px',
        borderRadius: '.3rem'
      }
    }
  }

  // If clientSecret hasn't loaded return null
  if (!clientSecret) {
    return null
  }

  return (
    <>
      <Elements stripe={stripePromise} options={options}>
        <StripeForm
          setStep={setStep}
          charge={charge}
          clientSecret={clientSecret}
          setClientSecret={setClientSecret}
        />
      </Elements>
    </>
  )
}

export default Checkout
