import React, { useRef, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { inviteFriendsActions } from '../../../redux/action-creators/invite-friends'
import isVerifiedUser from '../../../utils/functions/isVerifiedUser'
// import InviteIcon from '../../../media/icons/add-user.png'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import { Button } from '@mui/material'
import {
  MainContainer,
  InnerContainer,
  // BlueCircle,
  ContentContainer,
  Header,
  Subheader,
  ButtonContainer
} from './styled/invite-friends-comps'
import { getResponsiveStyles } from './responsive-styles/invite-friends'

const useStyles = makeStyles((theme) =>
  createStyles({
    buttonLight: {
      display: 'inline-block',
      color: theme.palette.text.primary,
      margin: '1.5rem 0 0 0',
      fontSize: '1rem',
      width: '12rem',
      textTransform: 'capitalize',
      verticalAlign: 'bottom',
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.light
      }
    }
  })
)

function InviteFriends() {
  const classes = useStyles()
  const mainRef = useRef(null)
  const [didRecognizeRef, setDidRecognizeRef] = useState(false)
  const [componentWidth, setComponentWidth] = useState(0)
  const [responsiveStyles, setResponsiveStyles] = useState(getResponsiveStyles(componentWidth))
  const inviteFriends = useSelector(({ inviteFriends }) => inviteFriends)
  const currentProfile = useSelector(({ currentProfile }) => currentProfile)

  const dispatch = useDispatch()

  const handleSetComponentWidth = () => {
    const width = mainRef.current ? mainRef.current.clientWidth : window.innerWidth

    setComponentWidth(width)
  }

  useEffect(() => {
    window.addEventListener('resize', handleSetComponentWidth)

    return () => {
      window.removeEventListener('resize', handleSetComponentWidth)
    }
  }, [])

  useEffect(() => {
    if (!didRecognizeRef) {
      if (mainRef.current !== null && typeof mainRef.current !== 'undefined') {
        setComponentWidth(mainRef.current.clientWidth)
        setDidRecognizeRef(true)
      }
    }
  }, [mainRef, didRecognizeRef])

  useEffect(() => {
    setResponsiveStyles(getResponsiveStyles(componentWidth))
  }, [componentWidth])

  const handleInviteFriends = () => {
    const isInviteModalOpen = inviteFriends

    dispatch(inviteFriendsActions.set(!isInviteModalOpen))
  }

  // Hide if user is not subscribed
  if (!isVerifiedUser(currentProfile)) {
    return null
  }

  return (
    <MainContainer ref={mainRef}>
      <InnerContainer>
        {/**
      <BlueCircle
      float={responsiveStyles.blueCircle.float}
      margin={responsiveStyles.blueCircle.margin}>
      <img src={InviteIcon} alt="Chat Icon" />
      </BlueCircle>
    */}

        <ContentContainer margin={responsiveStyles.contentContainer.margin}>
          <Header textAlign={responsiveStyles.header.textAlign}>Help grow your community.</Header>

          <Subheader textAlign={responsiveStyles.subheader.textAlign}>
          Help Grow Your Community  Choose Civility as a Lifestyle Emotional intelligence skills are the foundation for us to 
          <br />
         <span>Do Good | Be Accountable | Perform Better</span> 
          </Subheader>

          <ButtonContainer textAlign={responsiveStyles.buttonContainer.textAlign}>
            <Button
              variant="contained"
              className={classes.buttonLight}
              onClick={handleInviteFriends}>
              Invite Your Friends
            </Button>
          </ButtonContainer>
        </ContentContainer>
      </InnerContainer>
    </MainContainer>
  )
}

export default InviteFriends
