import React from 'react'
import Links from './components/links/links'
import Dropdown from './components/dropdown/dropdown'
import { MainContainer, WebView, MobileView } from './styled/static-links-comps'

const StaticLinks = () => {
  return (
    <MainContainer>
      <WebView>
        <Links />
      </WebView>

      <MobileView>
        <Dropdown />
      </MobileView>
    </MainContainer>
  )
}

export default StaticLinks
