import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { updateProfileByUserID } from '../../../../../redux/thunks/profiles/updateProfileByUserID'
import updateProfileValidation from './validations/update-profile'
import ArraysEqual from '../../../../../utils/functions/arraysEqual'
import FormError from '../../../../../components/alerts/form-error'
import TagsSearch from '../../../../partials/tags-search/tags-search'
import Tags from '../../../../partials/tags/tags'
import PropTypes from 'prop-types'
import withStyles from '@mui/styles/withStyles'
import { Button } from '@mui/material'
import {
  ItemContainer,
  InputLabel,
  InputsContainer,
  Textarea,
  TextCount
} from './styled/personal-comps'

// -------------------------------------------- //
// --- UTIL to activate submit button       --- //
// -------------------------------------------- //
const isProfileDifferent = (fields, profile) => {
  let fieldKeys = Object.keys(fields)

  for (let i = 0; i < fieldKeys.length; i++) {
    const key = fieldKeys[i]

    if (Object.prototype.toString.call(fields[key]) === '[object Array]') {
      if (!ArraysEqual(fields[key], profile[key])) return true
    } else {
      if (fields[key] !== profile[key]) return true
    }
  }

  return false
}

const styles = (theme) => ({
  button: {
    textTransform: 'capitalize',
    display: 'block',
    width: '50%',
    margin: '0 auto',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light
    },
    '@media (max-width: 500px)': {
      width: '100%'
    }
  }
})

// ------------------------------------ //
// ---   MAIN EXPORTED COMPONENT    --- //
// ------------------------------------ //

class PersonalDetails extends Component {
  constructor(props) {
    super(props)

    this.state = {
      fields: {
        coreBeliefs: '',
        educationalExperience: '',
        professionalExperience: '',
        tagsOfKnowledge: [],
        tagsOfInterest: []
      },
      error: '',
      field: ''
    }

    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleTagsOfKnowledge = this.handleTagsOfKnowledge.bind(this)
    this.handleTagsOfInterest = this.handleTagsOfInterest.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount() {
    const { currentProfile } = this.props
    const {
      coreBeliefs,
      educationalExperience,
      professionalExperience,
      tagsOfKnowledge,
      tagsOfInterest
    } = currentProfile

    this.setState({
      ...this.state,
      fields: {
        ...this.state.fields,
        coreBeliefs,
        educationalExperience,
        professionalExperience,
        tagsOfKnowledge: tagsOfKnowledge ? [...tagsOfKnowledge] : [],
        tagsOfInterest: tagsOfInterest ? [...tagsOfInterest] : []
      }
    })
  }

  handleInputChange(event) {
    const target = event.target
    const name = target.name

    this.setState({
      ...this.state,
      fields: {
        ...this.state.fields,
        [name]: target.value
      }
    })
  }

  handleTagsOfKnowledge(tagsOfKnowledge) {
    this.setState({
      ...this.state,
      fields: {
        ...this.state.fields,
        tagsOfKnowledge
      }
    })
  }

  handleTagsOfInterest(tagsOfInterest) {
    this.setState({
      ...this.state,
      fields: {
        ...this.state.fields,
        tagsOfInterest
      }
    })
  }

  handleSubmit(event) {
    event.preventDefault()
    const {
      coreBeliefs,
      educationalExperience,
      professionalExperience,
      tagsOfKnowledge,
      tagsOfInterest
    } = this.state.fields
    const { error, field } = updateProfileValidation({
      coreBeliefs,
      educationalExperience,
      professionalExperience,
      tagsOfKnowledge,
      tagsOfInterest
    })
    const { avatar } = this.props.currentProfile

    // Check for validation errors and then call action to create take
    if (error !== '') {
      console.log('[VALIDATION ERROR]: ', error)

      this.setState({
        ...this.state,
        error,
        field
      })
    } else {
      this.props
        .updateProfileByUserID({
          fieldsToUpdate: {
            coreBeliefs,
            educationalExperience,
            professionalExperience,
            tagsOfKnowledge,
            tagsOfInterest,
            avatar // send avatar along to check value for pointActions
          }
        })
        .then((data2) => {
          if (!data2.error) {
            console.log('[SUCCESS]: ', 'Successfully updated profile personal details.')
          } else {
            console.log('[FAIL]: ', data2.message)

            this.setState({
              ...this.state,
              error: data2.message
            })
          }
        })
    }
  }

  render() {
    const { classes, currentProfile } = this.props
    const { fields, error, field } = this.state
    const disableSubmit = !isProfileDifferent(fields, currentProfile)

    return (
      <>
        <form onSubmit={this.handleSubmit}>
          <ItemContainer style={{ marginTop: '0' }}>
            <InputLabel style={{ verticalAlign: 'top' }} aria-describedby="core-beliefs">
              Core Beliefs:
            </InputLabel>
            <InputsContainer>
              {field === 'coreBeliefs' && <FormError message={error} />}
              <Textarea
                id="core-beliefs"
                name="coreBeliefs"
                placeholder="What are your core beliefs and values? Sharing your core beliefs may help others better understand your perspective."
                value={fields.coreBeliefs}
                wrap="soft"
                maxLength="300"
                onChange={this.handleInputChange}
              />
              <TextCount>{`${fields.coreBeliefs.length} / 300`}</TextCount>
            </InputsContainer>
          </ItemContainer>

          <ItemContainer>
            <InputLabel style={{ verticalAlign: 'top' }}>Educational Experience:</InputLabel>
            <InputsContainer>
              {field === 'educationalExperience' && <FormError message={error} />}
              <Textarea
                name="educationalExperience"
                placeholder="What have you studied? You're welcome to share academic degrees, related classwork, or any other opportunities that have provided you insight."
                value={fields.educationalExperience}
                wrap="soft"
                maxLength="300"
                onChange={this.handleInputChange}
              />
              <TextCount>{`${fields.educationalExperience.length} / 300`}</TextCount>
            </InputsContainer>
          </ItemContainer>

          <ItemContainer>
            <InputLabel style={{ verticalAlign: 'top' }}>Professional Experience:</InputLabel>
            <InputsContainer>
              {field === 'professionalExperience' && <FormError message={error} />}
              <Textarea
                name="professionalExperience"
                placeholder="What do you do? Share as little or as much information as you'd like. Your occupation may give you unique perspective on certain issues."
                value={fields.professionalExperience}
                wrap="soft"
                maxLength="300"
                onChange={this.handleInputChange}
              />
              <TextCount>{`${fields.professionalExperience.length} / 300`}</TextCount>
            </InputsContainer>
          </ItemContainer>

          <ItemContainer>
            <InputLabel>Topics of Knowledge:</InputLabel>

            <InputsContainer>
              <TagsSearch
                tagIds={fields.tagsOfKnowledge}
                setTagIds={this.handleTagsOfKnowledge}
                // disableNewTags={true}
                style={{ marginTop: '0' }}
                variant={'transparent'}
              />
              <Tags
                tagIds={fields.tagsOfKnowledge}
                setTagIds={this.handleTagsOfKnowledge}
                hideTagAdd={true}
              />
            </InputsContainer>
          </ItemContainer>

          <ItemContainer>
            <InputLabel>Topics of Interest:</InputLabel>

            <InputsContainer>
              <TagsSearch
                tagIds={fields.tagsOfInterest}
                setTagIds={this.handleTagsOfInterest}
                // disableNewTags={true}
                style={{ marginTop: '0' }}
                variant={'transparent'}
              />
              <Tags
                tagIds={fields.tagsOfInterest}
                setTagIds={this.handleTagsOfInterest}
                hideTagAdd={true}
              />
            </InputsContainer>
          </ItemContainer>

          <ItemContainer>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.button}
              disabled={disableSubmit}>
              Save Changes
            </Button>
          </ItemContainer>
        </form>
      </>
    )
  }
}

PersonalDetails.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  currentProfile: state.currentProfile,
  currentUser: state.currentUser
})

const mapDispatchToProps = (dispatch) => ({
  updateProfileByUserID: (params) => {
    return dispatch(updateProfileByUserID(params))
  }
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PersonalDetails))
)
