import config from '../../../config'
import { authenticatedFetch } from '../../../utils/functions/authenticatedFetch'
/*
 * METHOD TO GET SMARTYSTREETS US STREET LOCATION
 */
export const getUSStreetLocation = (params) => {
  let responseCode = ''
  const method = 'GET'
  const url = `${config.baseURL}/api/smartystreets/us-street?street=${encodeURIComponent(
    params.street
  )}&secondary=${encodeURIComponent(params.secondary)}&city=${params.city}&state=${
    params.state
  }&zipCode=${params.zipCode}`
  const headers = {
    'Content-Type': 'application/json'
  }

  return function (dispatch) {
    console.log('Sending get US street location request to CivilTalk')

    return authenticatedFetch(url, {
      method,
      headers,
      credentials: 'include'
    })
      .then((response) => {
        responseCode = response.status

        return response.json()
      })
      .then((data) => {
        if (responseCode === 200) {
          return { error: false, code: responseCode, candidate: data.candidate }
        }

        console.log(data.message)

        return {
          error: true,
          code: responseCode,
          message: data.message
        }
      })
      .catch((error) => {
        console.log(error)

        return {
          error: true,
          code: responseCode,
          message: error
        }
      })
  }
}
