import { myNotificationActionTypes } from '../action-creators/my-notifications.js'
import _ from 'lodash'

const copyNotifications = notifications => {
  return notifications.map(notification => {
    return {
      ...notification,
      payload: { ...notification.payload }
    }
  })
}

// FOR MY NOTIFICATIONS
const myNotificationsReducer = (state = [], action) => {
  switch (action.type) {
    case myNotificationActionTypes.SET_MY_NOTIFICATIONS:
      return copyNotifications(action.notifications)
    case myNotificationActionTypes.ADD_MY_NOTIFICATIONS:
      var newNotifications1 = copyNotifications(state)

      for (var i = 0; i < action.notifications.length; i++) {
        let notificationExists = _.some(newNotifications1, { '_id': action.notifications[i]._id })

        if (!notificationExists) {
          newNotifications1.push(action.notifications[i])
        }
      }

      return newNotifications1
    case myNotificationActionTypes.MARK_MY_NOTIFICATION_AS_READ:
      var newNotifications2 = copyNotifications(state)
      var notificationIndex = _.findIndex(newNotifications2, { '_id': action.notificationId })
      var updatedNotification = {
        ...newNotifications2[notificationIndex],
        read: true,
        whenModified: Date.now()
      }

      if (notificationIndex !== -1) {
        newNotifications2.splice(notificationIndex, 1, updatedNotification)
      } else {
        console.log('[REDUX ERROR]: ', 'Notification not found.')
      }

      return newNotifications2
    case myNotificationActionTypes.CLEAR_MY_NOTIFICATIONS:
      return []
    default:
      return state
  }
}

export default myNotificationsReducer
