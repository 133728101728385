//////////////////
// ACTION TYPES //
//////////////////

export const pollVoteActionTypes = {
  SET_POLL_VOTES: "SET_POLL_VOTES",
  ADD_POLL_VOTES: "ADD_POLL_VOTES",
  CLEAR_POLL_VOTES: "CLEAR_POLL_VOTES",
}

/////////////
// ACTIONS //
/////////////

// POLL VOTES
const set = (pollVotes) => {
  return {
    type: pollVoteActionTypes.SET_POLL_VOTES,
    pollVotes,
  }
}

const add = (pollVotes) => {
  return {
    type: pollVoteActionTypes.ADD_POLL_VOTES,
    pollVotes,
  }
}

const clear = () => {
  return {
    type: pollVoteActionTypes.CLEAR_POLL_VOTES,
  }
}

export const pollVotesActions = {
  set,
  add,
  clear,
}
