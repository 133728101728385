import config from '../../../config'
import { authenticatedFetch } from '../../../utils/functions/authenticatedFetch'

/*
 * Returns a new access_token
 */
export const refresh = () => {
  let responseCode = ''
  const method = 'POST'
  const url = `${config.baseURL}/api/refresh-token`

  return authenticatedFetch(url, { method, credentials: 'include' })
    .then((response) => {
      responseCode = response.status

      if (responseCode !== 200) {
        return {
          error: true,
          code: responseCode,
          message: 'Unauthorized'
        }
      }

      return response.json()
    })
    .then((data) => {
      if (responseCode === 200) {
        return {
          error: false,
          code: responseCode,
          message: 'Token refresh successful!',
          access_token: data.access_token
        }
      }

      return {
        error: true,
        code: responseCode,
        message: data.message
      }
    })
    .catch((error) => {
      console.log(error)

      return {
        error: true,
        code: '',
        message: error
      }
    })
}
