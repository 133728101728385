import config from '../../../config'
import { authenticatedFetch } from '../../../utils/functions/authenticatedFetch'
import { actionResponseActions } from '../../action-creators/action-response.js'
import { loaderActions } from '../../action-creators/loader.js'
import { organizationsActions } from '../../action-creators/organizations'

/*
 * METHOD TO UPDATE ORGANIZATION LEGAL NAME OR PUBLIC NAME
 */
export const updateOrganizationName = (params) => {
  let responseCode = ''
  const method = 'PUT'
  const url = `${config.baseURL}/api/organization/${params.orgId}`
  const headers = {
    'Content-Type': 'application/json'
  }

  const requestBody = {}
  /** check params for legalName OR publicName */
  if (params.legalName) {
    requestBody.legalName = params.legalName
  }
  if (params.publicName) {
    requestBody.publicName = params.publicName
  }

  const requestBodyJSON = JSON.stringify(requestBody)

  // Thunk middleware knows how to handle functions.
  // It passes the dispatch method as an argument to the function,
  // thus making it able to dispatch actions itself.
  return (dispatch) => {
    console.log('Sending update name request to CivilTalk')

    dispatch(loaderActions.startLoader())

    // Return the fetch so react components calling 'store.dispatch()' can use 'then()'
    return authenticatedFetch(url, {
      method,
      body: requestBodyJSON,
      headers,
      credentials: 'include'
    })
      .then((response) => {
        dispatch(loaderActions.stopLoader())
        responseCode = response.status

        return response.json()
      })
      .then((data) => {
        if (responseCode === 200) {
          dispatch(
            organizationsActions.update({
              organizationId: params?.orgId,
              updatedFields: data?.organization
            })
          )
          dispatch(
            actionResponseActions.createActionResponse('Successfully updated organization name!')
          )

          return {
            error: false,
            code: responseCode,
            message: data.message
          }
        } else {
          console.log(data.message)

          return {
            error: true,
            code: responseCode,
            message: data.message
          }
        }
      })
      .catch((error) => {
        console.log(error)

        dispatch(loaderActions.stopLoader())

        return {
          error: true,
          code: '',
          message: error
        }
      })
  }
}
