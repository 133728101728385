import styled from 'styled-components'
// import config from '../../../../../../config.js'

export const Section = styled.div`
  margin-top: 2rem;
`

export const VerificationStatusContainer = styled.div`
  display: block;
  margin-bottom: 1rem;
  font-size: 0;
  text-align: center;
  ${'' /* margin-top: 0.5rem; */}

  & > span:nth-child(2) {
    display: inline-block;
  }
`

export const VerificationStatusLabel = styled.span`
  display: inline-block;
  margin: 0 1rem 0.2rem 1rem;
  vertical-align: middle;
  line-height: ${(props) => props.theme.typography.lineHeight};
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.regular};
  font-weight: ${(props) => props.theme.typography.fontWeight.bold};
  color: ${(props) => props.theme.palette.text.primary};

  @media (max-width: 500px) {
    display: block;
    margin: 0 auto 0 auto;
  }
`

export const VerificationStatus = styled.span`
  display: inline-block;
  margin: 0 1rem;
  text-transform: capitalize;
  vertical-align: middle;
  color: ${(props) => props.theme.palette.text.secondary};
  border-radius: 0.3rem;
  padding: 0.2rem 0.7rem;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-weight: ${(props) => props.theme.typography.fontWeight.light};
  font-size: ${(props) => props.theme.typography.fontSizes.small};

  @media (max-width: 500px) {
    display: block;
    margin: 0.7rem auto 0 auto;
  }
`

// export const SectionHeader = styled.span`
//   display: inline-block;
//   padding-bottom: 0.2rem;
//   font-family: ${theme.typography.fontFamily};
//   font-size: 1.2rem;
//   font-weight: ${theme.typography.fontWeight.strong};
//   color: ${theme.typography.fontColor.bodyCopy};
//   border-bottom: 2.5px solid #cc0000;
// `

// export const SectionSubheader = styled.p`
//   margin-top: 1.5rem;
//   font-family: ${theme.typography.fontFamily};
//   font-size: ${theme.typography.fontSizes.regular};
//   font-weight: ${theme.typography.fontWeight.regular};
//   color: ${theme.typography.fontColor.bodyCopy};
// `

// export const VerificationsRemaining = styled.div`
//   background-color: rgba(1, 147, 247, 1);
//   padding: 0.5rem 1rem;
//   border-radius: 0.2rem;
//   position: absolute;
//   top: 0.5rem;
//   right: 0.5rem;

//   @media (max-width: 550px) {
//     position: static;
//     display: block;
//     padding: 0.7rem 1rem;
//     margin-bottom: 1.5rem;
//   }

//   @media (max-width: 550px) {
//     text-align: center;
//   }
// `

// export const VerificationsRemainingText = styled.span`
//   display: inline-block;
//   margin-right: 1rem;
//   vertical-align: middle;
//   font-family: ${theme.typography.fontFamily};
//   font-size: ${theme.typography.fontSizes.regular};
//   font-weight: ${theme.typography.fontWeight.strong};
//   color: white;

//   @media (max-width: 400px) {
//     display: block;
//     text-align: center;
//     margin-right: 0;
//   }
// `

// export const VerificationsRemainingNumber = styled.span`
//   background-color: white;
//   padding: 0 0.5rem 0.2rem 0.5rem;
//   display: inline-block;
//   vertical-align: middle;
//   border-radius: 0.2rem;
//   font-family: ${theme.typography.fontFamily};
//   font-size: ${theme.typography.fontSizes.regular};
//   font-weight: ${theme.typography.fontWeight.strong};
//   color: rgba(1, 147, 247, 1);

//   @media (max-width: 400px) {
//     margin-top: 0.5rem;
//   }
// `

// export const MapFloatView = styled.div`
//   display: block;

//   @media (max-width: 1200px) {
//     display: none;
//   }
// `

// export const MapStackView = styled.div`
//   display: none;

//   @media (max-width: 1200px) {
//     display: block;
//   }
// `

// export const MapDetailsContainer = styled.div`
//   display: block;
//   margin-top: 2rem;
//   min-height: 20rem;

//   @media (max-width: 1200px) {
//     min-height: auto;
//   }
// `

// export const MapContainer = styled.div`
//   display: block;
//   width: 18rem;
//   height: 18rem;
//   margin-left: 2rem;
//   float: right;
//   position: relative;

//   @media (max-width: 1200px) {
//     float: none;
//     width: 100%;
//     margin: 2.5rem 0 0 0;
//   }
// `

// export const MapDetailsSummaryContainer = styled.div`
//   display: block;
//   padding: 2rem;
//   overflow: hidden;

//   @media (max-width: 1200px) {
//     padding: 0 1.5rem;
//   }

//   @media (max-width: 550px) {
//     padding: 0;
//   }
// `

// export const MapDetailsPrimary = styled.p`
//   margin-bottom: 2rem;
//   line-height: 1.2rem;
//   font-family: ${theme.typography.fontFamily};
//   font-size: ${theme.typography.fontSizes.larger};
//   font-weight: ${theme.typography.fontWeight.strong};
//   color: ${theme.typography.fontColor.bodyCopy};

//   @media (max-width: 1200px) {
//     margin-bottom: 1.5rem;
//   }

//   @media (max-width: 450px) {
//     text-align: center;
//   }
// `

// export const MapDetailLabel = styled.div`
//   display: block;
//   float: left;
//   min-width: 11rem;
//   font-family: ${theme.typography.fontFamily};
//   font-size: ${theme.typography.fontSizes.regular};
//   font-weight: ${theme.typography.fontWeight.bold};
//   color: ${theme.typography.fontColor.bodyCopy};

//   @media (max-width: 550px) {
//     float: none;
//     margin-top: 1rem;
//     min-width: auto;
//   }

//   @media (max-width: 450px) {
//     text-align: center;
//   }
// `

// export const MapDetailsSecondary = styled.p`
//   margin-top: 0.5rem;
//   font-family: ${theme.typography.fontFamily};
//   font-size: ${theme.typography.fontSizes.regular};
//   font-weight: ${theme.typography.fontWeight.regular};
//   color: ${theme.typography.fontColor.bodyCopy};
//   overflow: hidden;

//   @media (max-width: 450px) {
//     text-align: center;
//   }
// `

// export const HistoryHidden = styled.p`
//   background-color: #eee;
//   padding: 1rem 1.5rem;
//   margin-top: 1rem;
//   border-radius: 0.2rem;
//   text-align: center;
//   font-family: ${theme.typography.fontFamily};
//   font-size: ${theme.typography.fontSizes.regular};
//   font-weight: ${theme.typography.fontWeight.regular};
//   color: ${theme.typography.fontColor.bodyCopy};

//   &:hover {
//     background-color: #f8f8f8;
//   }
// `
