import { suggestedProfileActionTypes } from '../action-creators/suggested-profiles.js'
// import setNestedPropFromString from "../../utils/functions/setNestedPropFromString.js"

const copyProfiles = (profiles) => {
  let keys = Object.keys(profiles)
  let profilesCopy = {}

  for (var i = 0; i < keys.length; i++) {
    profilesCopy[keys[i]] = {
      ...profiles[keys[i]],
      rating: { ...profiles[keys[i]].rating },
      personalAddress: {
        ...profiles[keys[i]].personalAddress,
        loc: profiles[keys[i]].personalAddress.loc
          ? {
              ...profiles[keys[i]].personalAddress.loc,
              coordinates: [...profiles[keys[i]].personalAddress.loc.coordinates]
            }
          : {}
      },
      tooltips: {
        ...profiles[keys[i]].tooltips
      },
      privacySettings: {
        ...profiles[keys[i]].privacySettings
      },
      tagsOfInterest: profiles[keys[i]].tagsOfInterest ? [...profiles[keys[i]].tagsOfInterest] : [],
      tagsOfKnowledge: profiles[keys[i]].tagsOfKnowledge
        ? [...profiles[keys[i]].tagsOfKnowledge]
        : []
    }
  }

  return profilesCopy
}

// const copyProfile = (profile) => {
//   return {
//     ...profile,
//     topConcerns: [...profile.topConcerns],
//     rating: { ...profile.rating },
//     officeAddress: {
//       ...profile.officeAddress,
//     },
//     personalAddress: {
//       ...profile.personalAddress,
//       districtIds: [...profile.personalAddress.districtIds],
//       loc: {
//         ...profile.personalAddress.loc,
//         coordinates: [...profile.personalAddress.loc.coordinates],
//       },
//     },
//     professionalDetails: {
//       ...profile.professionalDetails,
//     },
//     tooltips: {
//       ...profile.tooltips,
//     },
//     privacySettings: {
//       ...profile.privacySettings,
//     },
//     tagsOfInterest: [...profile.tagsOfInterest],
//     tagsOfKnowledge: [...profile.tagsOfKnowledge],
//   }
// }

// FOR SUGGESTED PROFILES
const suggestedProfilesReducer = (state = {}, action) => {
  switch (action.type) {
    case suggestedProfileActionTypes.SET_SUGGESTED_PROFILES:
      return copyProfiles(action.profiles)

    case suggestedProfileActionTypes.ADD_SUGGESTED_PROFILE_FOLLOWER:
      const newProfiles3 = copyProfiles(state)

      if (newProfiles3[action.userId]) {
        newProfiles3[action.userId].followers = newProfiles3[action.userId].followers + 1
      }

      return newProfiles3

    case suggestedProfileActionTypes.REMOVE_SUGGESTED_PROFILE_FOLLOWER:
      const newProfiles4 = copyProfiles(state)

      if (newProfiles4[action.userId]) {
        newProfiles4[action.userId].followers = newProfiles4[action.userId].followers - 1
      }

      return newProfiles4

    case suggestedProfileActionTypes.CLEAR_SUGGESTED_PROFILES:
      return {}

    default:
      return state
  }
}

export default suggestedProfilesReducer
