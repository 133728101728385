import { useState, useEffect } from 'react'
import { withRouter, useLocation } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import useMembershipRoles from '../../../hooks/useMembershipRoles'
import { history } from '../../../history/history'
import CourseCard from './components/course-card'
import AddCourseCard from './components/add-course-card'
import UserAvatar from '../../partials/user-avatar/user-avatar'
import SkeletonTrendingIssue from '../../skeletons/skeleton-trending-issue'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import {
  OuterContainer,
  Header,
  TabContainer,
  Tab,
  ResultContainer,
  AvatarContainer,
  ProfileInfoContainer,
  CourseName,
  CourseSeason,
  ProfileLocation,
  NoResults
} from './styled/my-courses-comps'

import useLoadCourses from './hooks/use-load-courses'

function MyCourses(props) {
  const { currentProfile, currentUser } = props
  const [tab, setTab] = useState('current')
  const location = useLocation()
  const dispatch = useDispatch()
  const searchParams = new URLSearchParams(location.search)
  const mode = searchParams.get('mode')

  // set orgId for different development environments before release to production
  const organizationId =
    import.meta.env.VITE_NODE_ENV === 'production'
      ? '65a587332e5f8e0035efe4a4'
      : '658081a4ce604400356103ba'

  const { isAdmin, isInstructor } = useMembershipRoles(organizationId, currentProfile)

  const { openCourses, previousCourses, pendingCourses, isLoading } = useLoadCourses(
    currentProfile,
    currentUser,
    mode,
    organizationId,
    isAdmin,
    isInstructor
  )

  const redirect = (id, path) => {
    history.push(`/${path}/${id}`)
  }

  if (mode === 'report' && !isAdmin && !isInstructor) {
    history.push('/home')
  }

  return (
    <OuterContainer id="course-list">
      <div>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Header>{mode === 'report' ? 'UConn Admin' : 'My Courses'}</Header>
          {mode === 'report' && isAdmin && (
            <StyledButton
              variant="contained"
              startIcon={<AddCircleOutlineIcon />}
              onClick={() => history.push('/course-setup')}>
              {mode === 'report' ? 'Add course' : 'Add course'}
            </StyledButton>
          )}
        </Box>

        <TabContainer>
          <Tab onClick={() => setTab('current')} selected={tab === 'current'}>
            Current
          </Tab>

          <Tab onClick={() => setTab('previous')} selected={tab === 'previous'}>
            Previous
          </Tab>

          {mode === 'report' && (
            <Tab onClick={() => setTab('pending')} selected={tab === 'pending'}>
              Pending
            </Tab>
          )}
        </TabContainer>

        {!isLoading ? (
          <div>
            {tab === 'current' && (
              <>
                {openCourses.length ? (
                  openCourses.map((course) => (
                    <div key={course._id}>
                      <CourseCard
                        course={course}
                        mode={mode}
                        redirect={redirect}
                        isAdmin={isAdmin}
                        isInstructor={isInstructor}
                        userId={currentProfile.userId}
                      />
                    </div>
                  ))
                ) : (
                  <StyledCard
                    key={'no-courses-card'}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flexStart',
                      justifyContent: 'center',
                      height: '6rem'
                    }}>
                    <Typography
                      sx={{
                        textTransform: 'capitalize',
                        fontSize: '1.05rem',
                        fontWeight: 600,
                        color: 'text.primary'
                      }}>
                      {mode === 'report'
                        ? 'No current course reports found.'
                        : 'You are not enrolled in any courses at this time.'}
                    </Typography>
                  </StyledCard>
                )}
              </>
            )}

            {tab === 'previous' && (
              <>
                {previousCourses.length ? (
                  previousCourses.map((course) => (
                    <CourseCard
                      course={course}
                      mode={mode}
                      redirect={redirect}
                      isAdmin={isAdmin}
                      isInstructor={isInstructor}
                      userId={currentProfile.userId}
                    />
                  ))
                ) : (
                  <StyledCard
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flexStart',
                      justifyContent: 'center',
                      height: '6rem'
                    }}>
                    <Typography
                      sx={{
                        textTransform: 'capitalize',
                        fontSize: '1.05rem',
                        fontWeight: 600,
                        color: 'text.primary'
                      }}>
                      {mode === 'report'
                        ? 'No previous course reports found'
                        : 'No previous courses found'}
                    </Typography>
                  </StyledCard>
                )}
              </>
            )}

            {tab === 'pending' && (
              <>
                {pendingCourses.length ? (
                  pendingCourses.map((course) => (
                    <CourseCard
                      course={course}
                      mode={mode}
                      redirect={redirect}
                      isAdmin={isAdmin}
                      isInstructor={isInstructor}
                      userId={currentProfile.userId}
                    />
                  ))
                ) : (
                  <StyledCard
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flexStart',
                      justifyContent: 'center',
                      height: '6rem'
                    }}>
                    <Typography
                      sx={{
                        textTransform: 'capitalize',
                        fontSize: '1.05rem',
                        fontWeight: 600,
                        color: 'text.primary'
                      }}>
                      {mode === 'report'
                        ? 'No pending course reports found'
                        : 'No pending courses found'}
                    </Typography>
                  </StyledCard>
                )}
              </>
            )}
          </div>
        ) : (
          <div>
            <SkeletonTrendingIssue count={3} />
          </div>
        )}
      </div>
    </OuterContainer>
  )
}

const mapStateToProps = (state) => ({
  currentProfile: state.currentProfile,
  currentUser: state.currentUser
})

export default withRouter(connect(mapStateToProps)(MyCourses))

/** ********************** */
/** Material-UI Components */
/** ********************** */
const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  width: '100%',
  boxShadow: '0px 7px 5px -2px rgba(100, 100, 100, 0.1)',
  backgroundColor: theme.palette.common.white,
  borderRadius: '0.25rem',
  padding: '0.8rem 1rem',
  boxSizing: 'border-box',
  position: 'relative',
  marginBottom: '1rem',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    marginBottom: '0.75rem'
  }
}))

const StyledButton = styled(Button)(({ theme }) => ({
  minWidth: '9rem'
}))
