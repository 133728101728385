import { useState, useContext } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import filter from 'lodash/filter'
import { deleteOrganizationRoleByID } from '../../../../../../../redux/thunks/roles/deleteOrganizationRoleByID'
import LoadOrganizationRolesContext from '../../../../../../data-loading/load-organization-assigned-roles/context/context'
import FormError from '../../../../../../alerts/form-error'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import { ActionContainer } from './styled/roles-table-comps'
import theme from '../../../../../../../res/theme'

const styles = {
  table: {
    marginTop: '1rem',
    boxShadow: 'none'
  },
  row: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#eee'
    },
    '& .material-icons': {
      display: 'inline-block',
      verticalAlign: 'middle',
      fontSize: '1.6rem',
      color: theme.typography.fontColor.bodyCopy
    }
  },
  cell: {
    fontSize: theme.typography.fontSizes.regular,
    color: theme.typography.fontColor.bodyCopy
  },
  menuItem: {
    minWidth: '12rem',
    fontSize: '1rem',
    '& .material-icons': {
      display: 'inline-block',
      marginTop: '.1rem',
      marginRight: '.3rem',
      verticalAlign: 'middle',
      fontSize: '1.2rem',
      color: theme.typography.fontColor.bodyCopy
    }
  },
  button: {
    display: 'block',
    width: '15rem',
    marginTop: '2rem',
    boxSizing: 'border-box',
    '@media (max-width: 500px)': {
      width: '100%'
    }
  },
  circularProgress: {
    display: 'block',
    width: '17px !important',
    height: '17px !important',
    margin: '0 auto 0 auto',
    position: 'absolute',
    top: '25%',
    left: '25%',
    transform: 'translate(-50%, -50%)',
    '& svg': {
      color: theme.palette.secondary.main
    }
  }
}

function createData(id, name, members) {
  return { id, name, members }
}

function RolesTable({ setFlow, setSelectedRole }) {
  const { orgId } = useParams()
  const { roles, setRoles, assignedRoles, setAssignedRoles } = useContext(
    LoadOrganizationRolesContext
  )
  const [error, setError] = useState('')
  const [processing, setProcessing] = useState(false)
  const rows = roles.map((role) => {
    const filteredAssignedRoles = filter(assignedRoles, { roleId: role._id })

    return createData(role._id, role.name, filteredAssignedRoles.length)
  })
  const dispatch = useDispatch()

  const handleEdit = (id) => {
    setSelectedRole(id)
    setFlow('edit')
  }

  const handleDelete = (e, id) => {
    e.stopPropagation()
    e.preventDefault()
    setProcessing(true)

    dispatch(
      deleteOrganizationRoleByID({
        roleId: id,
        organizationId: orgId
      })
    )
      .then((data) => {
        if (data.error) {
          console.log('[FAIL]: ', 'Error deleting role')

          setError(data.message)
        } else {
          console.log('[SUCCESS]: ', 'Successfully deleted role')

          let newRoles = roles.filter((role) => role._id !== id)
          let newAssignedRoles = assignedRoles.filter((assignedRole) => assignedRole.roleId !== id)

          setRoles(newRoles)
          setAssignedRoles(newAssignedRoles)
          setError('')
        }

        setProcessing(false)
      })
      .catch((e) => {
        console.log('[ERROR]: ', e)

        setProcessing(false)
        setError(e.message)
      })
  }

  return (
    <>
      <FormError message={error} style={{ margin: '1.5rem 0 0 0' }} />

      <TableContainer component={Paper} sx={styles.table}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                <b>ROLES - {roles.length}</b>
              </TableCell>

              <TableCell align="right">
                <b>MEMBERS</b>
              </TableCell>

              <TableCell align="right">
                <b></b>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={`table-row-${row.id}`}
                sx={styles.row}
                onClick={() => handleEdit(row.id)}>
                <TableCell component="th" scope="row" sx={styles.cell}>
                  <b>{row.name}</b>
                </TableCell>

                <TableCell align="right" sx={styles.cell}>
                  {row.members}
                </TableCell>

                <TableCell align="right" sx={styles.cell}>
                  <ActionContainer onClick={(e) => handleDelete(e, row.id)}>
                    {processing ? (
                      <CircularProgress color="secondary" sx={styles.circularProgress} />
                    ) : (
                      <span className="material-icons">delete</span>
                    )}
                  </ActionContainer>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Button variant="contained" color="primary" onClick={() => setFlow('add')} sx={styles.button}>
        Add Role
      </Button>
    </>
  )
}

export default RolesTable
