import { useCallback, useEffect, useContext } from 'react'
import { MembersContainer } from '../../../course/components/members/styled/members-comps'
import { useDispatch, useSelector } from 'react-redux'
import some from 'lodash/some'
import { CircularProgress } from '@mui/material'
import { getProfilesByUserIDs } from '../../../../../redux/thunks/profiles/getProfilesByUserIDs'
import { getOrganizationByMemberID } from '../../../../../redux/thunks/organizations/getOrganizationByMemberId'
import MembersSearch from '../users-search'
import { removeDuplicates } from '../../../../../utils/functions/removeDuplicates'
import Profile from '../profile/profile'
import { loadMoreChecking } from '../../../../../utils/functions/loadMoreCheck'
import { OrganizationContext } from '../../organization'

const OrganizationList = ({ id, publicName }) => {
  const [{ page, limit, moreToLoad, data, total, requestStatus }, updateEvent] =
    useContext(OrganizationContext)

  const dispatch = useDispatch()

  const getMembers = () => {
    if (!moreToLoad) {
      return
    }

    updateEvent({ requestStatus: 'pending', moreToLoad: false })

    dispatch(getOrganizationByMemberID({ id, limit, page })).then((result) => {
      if (result.error) {
        updateEvent({
          requestStatus: 'rejected',
          moreToLoad: false
        })
        return
      }
      const userIds = result.data?.members?.map((i) => i?.userId)

      dispatch(getProfilesByUserIDs({ userIds })).then((profileResult) => {
        if (profileResult.error) {
          updateEvent({
            requestStatus: 'rejected',
            moreToLoad: false
          })
          return
        }
        const profiles = Object.values(profileResult?.profiles)

        const users = result.data?.members?.map((user) => {
          const profile = profiles?.find((profile) => profile?.userId === user?.userId)

          return {
            ...profile,
            ...user
          }
        })
        const membersData = removeDuplicates([...data, ...(users || [])], 'userId') || []

        updateEvent({
          data: membersData,
          total: result.data.total,
          page: page + 1,
          limit,
          moreToLoad: users.length > 0,
          requestStatus: 'fulfilled'
        })
      })
    })
  }

  const trackScrolling = useCallback(() => {
    const el = document.getElementById('members-list')
    if (
      el &&
      loadMoreChecking(el) &&
      requestStatus === 'fulfilled' &&
      moreToLoad &&
      data.length > 0
    ) {
      updateEvent({ requestStatus: 'pending' })
      getMembers()
    }
  }, [data.length, moreToLoad, requestStatus])

  useEffect(() => {
    window.addEventListener('scroll', trackScrolling)

    return () => {
      window.removeEventListener('scroll', trackScrolling)
    }
  }, [trackScrolling])

  useEffect(() => {
    getMembers()
  }, [])

  const currentUser = useSelector(({ currentUser }) => currentUser)
  const follows = useSelector(({ follows }) => follows)

  return (
    <>
      {(requestStatus === 'fulfilled' || !!total) && <MembersSearch publicName={publicName} />}
      <MembersContainer>
        {requestStatus === 'rejected' && !total && <div>Something went wrong</div>}

        {data.length > 0 && (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {data?.map((profile) => {
              const isFollowing = some(follows, {
                follower: currentUser.id,
                userId: profile.userId
              })

              return <Profile key={profile._id} {...{ profile, isFollowing }} />
            })}
          </div>
        )}

        {requestStatus === 'pending' && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '3rem'
            }}>
            <CircularProgress color="secondary" sx={{}} />
          </div>
        )}
        <div id="members-list" />
      </MembersContainer>
    </>
  )
}

export default OrganizationList
