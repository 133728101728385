export const getResponsiveStyles = (width) => {
  const styles = {
    buttonGroup: {
      width: 'auto'
    },
    submitButton: {
      width: '8rem'
    },
    visibilityButton: {
      width: '4.5rem'
    }
  }

  if (width) {
    if (width <= 400) {
      styles.buttonGroup.width = '100%'
      styles.submitButton.width = '70%'
      styles.visibilityButton.width = '30%'
    }
  }

  return styles
}
