import { useState, useContext } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { createOrganizationRole } from '../../../../../../../redux/thunks/roles/createOrganizationRole'
import OrganizationRoleValidation from './validations/create-organization-role'
import FormError from '../../../../../../alerts/form-error'
import Switch from '@mui/material/Switch'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import {
  MainContainer,
  InputContainer,
  InputLabel,
  InputDesc,
  Input
} from './styled/add-role-comps'
import LoadOrganizationRolesContext from '../../../../../../data-loading/load-organization-assigned-roles/context/context'

const styles = {
  switch: {
    marginLeft: '-.8rem'
  },
  button: {
    display: 'block',
    width: '15rem',
    marginTop: '2rem',
    boxSizing: 'border-box',
    '@media (max-width: 500px)': {
      width: '100%'
    }
  },
  circularProgress: {
    display: 'block',
    width: '17px !important',
    height: '17px !important',
    margin: '0 auto 0 auto',
    '& svg': {
      color: 'white'
    }
  }
}

function AddRole({ setFlow }) {
  const { orgId } = useParams()
  const { roles, setRoles } = useContext(LoadOrganizationRolesContext)
  const [fields, setFields] = useState({
    organizationId: '',
    name: '',
    desc: '',
    ranking: 1,
    admin: false,
    manageAssignedRoles: false,
    manageOrganizationDetails: false,
    managePosts: false,
    manageRoles: false,
    manageBilling: false
  })
  const [error, setError] = useState('')
  const [processing, setProcessing] = useState(false)
  const dispatch = useDispatch()

  const handleSubmit = (e) => {
    e.preventDefault()
    setProcessing(true)

    const { name, desc, ranking } = fields
    const validation = OrganizationRoleValidation({ name, desc, ranking })

    if (validation.error) {
      console.log(validation.error)

      setProcessing(false)
      setError(validation.error)
    } else {
      dispatch(
        createOrganizationRole({
          role: {
            organizationId: orgId,
            name: fields.name,
            desc: fields.desc,
            ranking: parseInt(fields.ranking),
            organizationPermissions: {
              admin: fields.admin,
              manageAssignedRoles: fields.manageAssignedRoles,
              manageOrganizationDetails: fields.manageOrganizationDetails,
              managePosts: fields.managePosts,
              manageRoles: fields.manageRoles,
              manageBilling: fields.manageBilling
            }
          }
        })
      )
        .then((data) => {
          if (data.error) {
            console.log('[FAIL]: ', 'Error creating role')
            setError(data.message)
          } else {
            console.log('[SUCCESS]: ', 'Successfully created role')
            const newRoles = [...roles]
            newRoles.push(data.role)

            setRoles(newRoles)
            setFlow('view')
          }

          setProcessing(false)
        })
        .catch((e) => {
          console.log('[ERROR]: ', e)
          setProcessing(false)
          setError(e.message)
        })
    }
  }

  return (
    <MainContainer>
      <FormError message={error} style={{ margin: '1rem 0 0 0' }} />

      <form onSubmit={handleSubmit}>
        <InputContainer style={{ marginTop: '1rem' }}>
          <InputLabel>Name</InputLabel>
          <InputDesc>Enter the name of the new role.</InputDesc>
          <Input
            type="text"
            name="name"
            placeholder="Enter a name..."
            value={fields.name}
            onChange={(e) => setFields({ ...fields, [e.target.name]: e.target.value })}
          />
        </InputContainer>
        <InputContainer>
          <InputLabel>Description</InputLabel>
          <InputDesc>Describe the role you are creating.</InputDesc>
          <Input
            type="text"
            name="desc"
            placeholder="Enter a description..."
            value={fields.desc}
            onChange={(e) => setFields({ ...fields, [e.target.name]: e.target.value })}
            style={{ maxWidth: 'none' }}
          />
        </InputContainer>
        <InputContainer>
          <InputLabel>Ranking</InputLabel>
          <InputDesc>
            You can optionally enter a numerical ranking for this role (Defaults to 1). Roles using
            different rankings will internally define a hierarchy. Displayed roles will be sorted in
            ascending order (low to high).
          </InputDesc>
          <Input
            type="number"
            name="ranking"
            placeholder="Optionally enter a numerical ranking ranging from 1 to 999..."
            min="1"
            value={fields.ranking}
            onChange={(e) => setFields({ ...fields, [e.target.name]: e.target.value })}
          />
        </InputContainer>
        <InputContainer>
          <InputLabel>Assign All Organization Permissions</InputLabel>
          <InputDesc>
            Enabling this will grant a user with all top-level organization permissions regardless
            of what other permissions are selected.
          </InputDesc>
          <Switch
            color="primary"
            inputProps={{ 'aria-label': 'permission-checkbox' }}
            name="admin"
            checked={fields.admin}
            onChange={(e) => setFields({ ...fields, [e.target.name]: e.target.checked })}
            sx={styles.switch}
          />
        </InputContainer>
        <InputContainer>
          <InputLabel>Allow Management of Assigned Roles</InputLabel>
          <InputDesc>
            Enabling this will grant a user the ability to assign or unassign organization roles to
            or from users.
          </InputDesc>
          <Switch
            color="primary"
            inputProps={{ 'aria-label': 'permission-checkbox' }}
            name="manageAssignedRoles"
            checked={fields.manageAssignedRoles}
            onChange={(e) => setFields({ ...fields, [e.target.name]: e.target.checked })}
            sx={styles.switch}
          />
        </InputContainer>
        <InputContainer>
          <InputLabel>Allow Management of Organization Details</InputLabel>
          <InputDesc>
            Enabling this will grant a user the ability to edit the details of this organization
            including but not limited to profile picture, tags, location, and more.
          </InputDesc>
          <Switch
            color="primary"
            inputProps={{ 'aria-label': 'permission-checkbox' }}
            name="manageOrganizationDetails"
            checked={fields.manageOrganizationDetails}
            onChange={(e) => setFields({ ...fields, [e.target.name]: e.target.checked })}
            sx={styles.switch}
          />
        </InputContainer>
        <InputContainer>
          <InputLabel>Allow Management of Posts</InputLabel>
          <InputDesc>
            Enabling this will grant a user the ability to create, edit, and/or delete this
            organization's posts.
          </InputDesc>
          <Switch
            color="primary"
            inputProps={{ 'aria-label': 'permission-checkbox' }}
            name="managePosts"
            checked={fields.managePosts}
            onChange={(e) => setFields({ ...fields, [e.target.name]: e.target.checked })}
            sx={styles.switch}
          />
        </InputContainer>
        <InputContainer>
          <InputLabel>Allow Management of Roles</InputLabel>
          <InputDesc>
            Enabling this will grant a user the ability to create, edit, and/or delete this
            organization's roles.{' '}
            <b>Note: This should only be assigned to agreed-upon team members.</b>
          </InputDesc>
          <Switch
            color="primary"
            inputProps={{ 'aria-label': 'permission-checkbox' }}
            name="manageRoles"
            checked={fields.manageRoles}
            onChange={(e) => setFields({ ...fields, [e.target.name]: e.target.checked })}
            sx={styles.switch}
          />
        </InputContainer>
        <InputContainer>
          <InputLabel>Allow Management of Billing & Finances</InputLabel>
          <InputDesc>
            Enabling this will grant a user the ability to create token redemption requests as well
            as manage the organization's billing information.{' '}
            <b>Note: This should only be assigned to agreed-upon team members.</b>
          </InputDesc>
          <Switch
            color="primary"
            inputProps={{ 'aria-label': 'permission-checkbox' }}
            name="manageBilling"
            checked={fields.manageBilling}
            onChange={(e) => setFields({ ...fields, [e.target.name]: e.target.checked })}
            sx={styles.switch}
          />
        </InputContainer>

        <Button
          type="submit"
          variant="contained"
          color="primary"
          sx={styles.button}
          disabled={processing}>
          {processing ? (
            <CircularProgress color="secondary" sx={styles.circularProgress} />
          ) : (
            'Add Role'
          )}
        </Button>
      </form>
    </MainContainer>
  )
}

export default AddRole
