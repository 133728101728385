// Calculate Fee for CivilTalk
const getCTPurchaseFeeSummary = (paymentGoal) => {
  const parsedPaymentGoal = parseFloat(paymentGoal)

  if (isNaN(parsedPaymentGoal)) {
    throw new Error(
      "Invalid param <paymentGoal> in function getStripeFeeSummary."
    )
  }

  const fixedFee = 0.1
  const percentFee = 0.01
  const charge = parsedPaymentGoal * (1 + percentFee) + fixedFee
  const chargeRounded = charge.toFixed(2)
  const chargeRoundedCents = (parseFloat(chargeRounded) * 100).toFixed()
  const fee = parseFloat(chargeRounded) - parsedPaymentGoal
  const feeRounded = fee.toFixed(2)

  return {
    fixedFee,
    percentFee,
    charge: chargeRounded,
    chargeCents: chargeRoundedCents,
    fee: feeRounded,
  }
}

export default getCTPurchaseFeeSummary
