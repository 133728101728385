import React, { useState } from 'react'
import Pricing from './components/pricing/pricing'
import Checkout from './components/checkout/checkout'
import Complete from './components/complete/complete'

function Purchase(props) {
  const { zeroBalance } = props
  const [step, setStep] = useState('pricing')
  const [tokens, setTokens] = useState('')
  const [civiltalkFee, setCiviltalkFee] = useState(0)
  const [stripeFee, setStripeFee] = useState(0)
  const [charge, setCharge] = useState(0)
  const [clientSecret, setClientSecret] = useState('')

  return (
    <>
      {step === 'pricing' && (
        <Pricing
          zeroBalance={zeroBalance}
          setStep={setStep}
          tokens={tokens}
          setTokens={setTokens}
          civiltalkFee={civiltalkFee}
          setCiviltalkFee={setCiviltalkFee}
          stripeFee={stripeFee}
          setStripeFee={setStripeFee}
          charge={charge}
          setCharge={setCharge}
          setClientSecret={setClientSecret}
        />
      )}

      {step === 'checkout' && (
        <Checkout
          setStep={setStep}
          tokens={tokens}
          charge={charge}
          clientSecret={clientSecret}
          setClientSecret={setClientSecret}
        />
      )}

      {step === 'complete' && <Complete />}
    </>
  )
}

export default Purchase
