import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getCourseByCourseID } from '../../../../redux/thunks/courses/getCourseByCourseID'
import { getProfilesByUserIDs } from '../../../../redux/thunks/profiles/getProfilesByUserIDs'

function useLoadCourseFieldsByCourseId(urlParamsObject, courseFields, setCourseFields, setLoading) {
  const dispatch = useDispatch()

  async function getCourse(courseId) {
    const course = await dispatch(getCourseByCourseID({ courseId }))

    return course
  }

  async function getInstructorProfile(userIds) {
    try {
      const { profiles } = await dispatch(getProfilesByUserIDs({ userIds: userIds }))

      return profiles
    } catch (error) {
      throw new Error(`[ERROR]: Failed to fetch instructor profile: ${error}`)
    }
  }

  async function getMemberProfiles(userIds) {
    try {
      const { profiles } = await dispatch(getProfilesByUserIDs({ userIds }))

      return profiles
    } catch (error) {
      throw new Error(`[ERROR]: Failed to fetch member profiles: ${error}`)
    }
  }

  useEffect(() => {
    let isMounted = true

    if (urlParamsObject.courseId) {
      console.log(
        `[DEBUG]: Loading existing course id: ${urlParamsObject.courseId} into course setup form`
      )

      const { courseId } = urlParamsObject

      setLoading(true)

      const fetchCourse = async () => {
        try {
          const { course } = await getCourse(courseId)

          if (!isMounted) return // Prevent setting state on an unmounted component

          console.log(
            `[DEBUG]: Loading course id: ${urlParamsObject.courseId} into course setup form`,
            course
          )

          if (!Array.isArray(course) || course.length === 0) {
            setLoading(false)

            throw new Error(
              `[ERROR]: No course found with courseId: ${courseId}, or result of getCourseByCourseID is not an array`
            )
          }

          const {
            courseNumber,
            desc,
            instructorId,
            members,
            orgId,
            season,
            subject,
            title,
            year,
            status,
            // whenCreated,
            // whenModified,
            _id
          } = course[0]

          let instructor = {}

          if (instructorId) {
            try {
              const instructorProfile = await getInstructorProfile([instructorId])

              instructor = Object.values(instructorProfile)[0] // returns an array with a single profile object
            } catch (error) {
              console.error('[ERROR]: while fetching instructor profile:', error) // Log any errors
            }
          }

          let memberProfiles = []

          if (members.length) {
            try {
              memberProfiles = await getMemberProfiles(members)
            } catch (error) {
              console.error(
                `[ERROR]: while fetching member profiles for courseId: ${courseId}:`,
                error
              )

              memberProfiles = []
            }
          }

          const newCourseFields = {
            ...courseFields,
            courseId: _id,
            subject,
            title,
            courseNumber,
            season,
            year,
            desc,
            status,
            instructor,
            members: Object.values(memberProfiles)
          }

          const compareFields = (oldFields, newFields) => {
            const comparison = Object.keys(newFields).map((key) => ({
              field: key,
              oldValue: oldFields[key],
              newValue: newFields[key]
            }))
            console.table(comparison)
          }

          compareFields(courseFields, newCourseFields)

          setCourseFields(newCourseFields)
        } catch (error) {
          console.error(`[ERROR]: while fetching course by courseId: ${courseId}`, error)
        }

        if (isMounted) {
          setLoading(false)
        }
      }

      fetchCourse()
    }

    return () => {
      isMounted = false
    }
  }, [])

  return null
}

export default useLoadCourseFieldsByCourseId
