import CourseCard from './course-card'

const CourseList = ({ courses, legalName, image }) => {
  return (
    <div>
      {courses?.map((course) => (
        <CourseCard course={course} legalName={legalName} key={course._id} image={image} />
      ))}
    </div>
  )
}

export default CourseList
