import React from 'react'
import { withRouter } from 'react-router-dom'
// import { analytics } from "../../../firebase/firebase"
import { history } from '../../../../../history/history'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import { Button } from '@mui/material'
import { Header, Subheader, ButtonsContainer } from '../../styled/verification-steps-comps'

const useStyles = makeStyles((theme) =>
  createStyles({
    secondaryButton: {
      display: 'inline-block',
      width: '15rem',
      height: '3.5rem',
      marginRight: '2rem',
      borderRadius: '.3rem',
      fontSize: '1rem',
      textTransform: 'capitalize',
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.cancelButton.light,
      '&:hover': {
        backgroundColor: theme.palette.cancelButton.dark
      },
      '@media (max-width: 650px)': {
        display: 'block',
        width: '16rem',
        height: '3rem',
        margin: '0 auto',
        fontSize: '0.9375rem' // 15px
      },
      '@media (max-width: 400px)': {
        width: '100%'
      }
    },
    primaryButton: {
      display: 'inline-block',
      width: '15rem',
      height: '3.5rem',
      borderRadius: '.3rem',
      fontSize: '1rem',
      textTransform: 'none',
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.light
      },
      '@media (max-width: 650px)': {
        display: 'block',
        width: '16rem',
        height: '3rem',
        margin: '1rem auto 0 auto',
        fontSize: '0.875rem' // 14px
      },
      '@media (max-width: 400px)': {
        width: '100%'
      }
    }
  })
)

function Start(props) {
  const { setStep } = props
  const classes = useStyles()

  return (
    <>
      <Header>Unlock the full experience</Header>
      <Subheader>
        We hope you've been enjoying your experience so far. Want to unlock the rest of the features
        that make CivilTalk enjoyable by so many? Follow the steps below to become verified and
        receive full access to the site including the ability to read, post and discuss.
      </Subheader>

      <ButtonsContainer>
        <Button
          variant="contained"
          color="secondary"
          className={classes.secondaryButton}
          onClick={() => history.push('/home')}>
          Not Right Now
        </Button>

        <Button
          variant="contained"
          color="primary"
          className={classes.primaryButton}
          onClick={() => setStep(1)}>
          Verify Now
        </Button>
      </ButtonsContainer>
    </>
  )
}

export default withRouter(Start)
