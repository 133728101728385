import { assignedRolesActionTypes } from '../action-creators/assigned-roles'

const copyAssignedRoles = (assignedRoles) => {
  return assignedRoles.map((assignedRole) => ({ ...assignedRole }))
}

const assignedRolesReducer = (state = [], action) => {
  switch (action.type) {
    case assignedRolesActionTypes.SET_ASSIGNED_ROLES:
      return copyAssignedRoles(action.assignedRoles)
    case assignedRolesActionTypes.CLEAR_ASSIGNED_ROLES:
      return []
    default:
      return state
  }
}

export default assignedRolesReducer
