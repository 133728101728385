import { videoActionTypes } from '../action-creators/videos.js'

const copyVideos = (videos) => {
  return videos.map((testimonial) => {
    return { ...testimonial }
  })
}

// TESTIMONIALS
const videosReducer = (state = [], action) => {
  switch (action.type) {
    case videoActionTypes.SET_VIDEOS:
      return copyVideos(action.videos)

    case videoActionTypes.CLEAR_VIDEOS:
      return []

    default:
      return state
  }
}

export default videosReducer
