import {bulletinActionTypes } from '../action-creators/bulletins.js'
import _ from 'lodash'
import nestedSort from '../../utils/functions/nestedSort.js'

const copyBulletins = bulletins => {
    return bulletins.map(bulletin => {
        return { ...bulletin }
    })
}

// FOR BULLETINS
const bulletinsReducer = (state = [], action) => {
    switch (action.type) {
        case bulletinActionTypes.SET_BULLETINS:
            return nestedSort(copyBulletins(action.bulletins), -1, 'whenCreated')
        case bulletinActionTypes.ADD_BULLETINS:
            var newBulletins1 = copyBulletins(state)

            for (var i = 0; i < action.bulletins.length; i++) {
                let doesExist = _.some(newBulletins1, { _id: action.bulletins[i]._id })

                if (!doesExist) {
                    newBulletins1.push(action.bulletins[i])
                }
            }

            return nestedSort(newBulletins1, -1, 'whenCreated')
        case bulletinActionTypes.CLEAR_BULLETINS:
            return []
        default:
            return state
    }
}

export default bulletinsReducer
