import styled from 'styled-components'
// import theme from '../../../../../res/theme.js'
// import config from '../../../../../config.js'

export const MainContainer = styled.div`
  margin-bottom: 0.2rem;
  font-size: 0;
`

export const SearchAndFilterContainer = styled.div`
  margin-bottom: 1rem;
  font-size: 0;
`

export const SearchContainer = styled.div`
  display: inline-block;
  width: 50%;
  vertical-align: middle;
`

export const FilterSortContainer = styled.div`
  display: inline-block;
  height: 2.7rem;
  width: 50%;
  vertical-align: middle;
  text-align: right;
`
