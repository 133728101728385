import styled from 'styled-components'
import config from '../../../../../../config'
import theme from '../../.././../../../res/theme'

export const PromptsContainer = styled.div`
  margin-top: 1rem;
`

export const NoResults = styled.div`
  background-color: rgba(255, 255, 255, 0.7);
  padding: 1rem 2rem;
  margin-bottom: 0.8rem;
  text-align: center;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.regular};
  font-weight: ${(props) => props.theme.typography.fontWeight.medium};
  color: ${(props) => props.theme.typography.fontColor.bodyCopy};
  border-radius: 0.3rem;
`

// ***
// Prompt
// ***
export const MainContainer = styled.div`
  background-color: ${(props) => props.bgColor || props.theme.palette.common.white};
  margin-bottom: 1rem;
  border: 1px solid ${(props) => props.theme.palette.background.mediumGray};
  border-radius: 0.3rem;
  box-shadow: ${(props) => props.theme.boxShadow};
`

export const PaddedContainer = styled.div`
  background-color: '#333';
  padding: 1.2rem 1.5rem 0.6rem 1.5rem;
  position: relative;
  box-sizing: border-box;
`

export const TakeHeaderContainer = styled.header`
  display: block;
`

export const AvatarContainer = styled.div`
  float: left;
  margin-right: 1rem;
  border-radius: 50%;
  cursor: pointer;
  width: 2.69825rem;
  height: 2.69825rem;
`

export const TakeHeaderInnerContainer = styled.div`
  display: block;
  overflow: hidden;
  min-height: 2.5rem;
  margin-top: 0.1rem;
`

export const Name = styled.p`
  display: block;
  margin: -0.1rem 0.5rem 0 0;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeight.medium};
  line-height: 1.2rem;
  cursor: pointer;
  position: relative;
  z-index: 1000;

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: ${config.UI.drawer_breakpoint.max}) {
    font-size: ${(props) => props.theme.typography.fontSizes.short};
  }
`

export const DateString = styled.p`
  color: #464646;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.smaller};
  font-weight: ${(props) => props.theme.typography.fontWeight.light};
  display: flex;
  align-items: center;
`

export const Separator = styled.span`
  display: inline-block;
  width: auto;
  margin: 0 0.75rem;
  vertical-align: middle;
  color: #464646;
  line-height: ${(props) => props.theme.typography.lineHeight};
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.regular};
  font-weight: ${(props) => props.theme.typography.fontWeight.light};

  @media (max-width: ${config.UI.drawer_breakpoint.max}) {
    font-size: ${(props) => props.theme.typography.fontSizes.short};
  }
`

export const UserTake = styled.div`
  display: block;
  margin: 1.2rem 0 1.3rem 0;
`

export const HR = styled.hr`
  border: 0;
  border-top: 1.5px solid rgba(48, 48, 48, 0.1);
  display: block;
  height: 1px;
`

export const ActionsContainer = styled.div`
  display: flex;
  margin-top: 0.5rem;
  justify-content: flex-end;
  align-items: center;
`

// Rebuttal Section

export const TextareaContainer = styled.div`
  display: block;
  margin-top: 0.2rem;
`

export const StyledTextarea = styled.textarea`
  background-color: #fafafa;
  display: block;
  width: 100%;
  height: 7rem;
  padding: 0.7rem;
  box-sizing: border-box;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  color: ${(props) => props.theme.typography.fontColor.bodyCopy};
  border: 2px solid #ddd;
  border-radius: 0.2rem;
  resize: none;

  &:focus,
  &:active {
    outline: none;
  }

  &:focus {
    background-color: rgba(255, 255, 255, 0.3);
    border: 2px solid #2eb2fa;
  }
`

export const SubmitContainer = styled.div`
  display: block;
  width: 100&;
  margin: 1rem 0;
  text-align: right;
  font-size: 0;
`

export const SwitchContainer = styled.span`
  display: inline-block;
  margin-right: 2.5rem;
`

export const SwitchLabel = styled.span`
  display: inline-block;
  vertical-align: middle;
  text-transform: uppercase;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  color: ${(props) => props.theme.typography.fontColor.bodyCopy};
`

export const PostFormContainer = styled.div`
  max-width: 40rem;

  @media (min-width: 800px) {
    min-width: 40rem;
  }
`

/** DROPDOWN MENU */
export const NavItemContainer = styled.div`
  margin: 0;
  padding: 0;
  font-size: 0;
`

export const NavItem = styled.span`
  display: inline-block;
  cursor: pointer;
  font-family: ${theme.typography.fontFamily};
  font-size: ${theme.typography.fontSize};
  font-weight: ${theme.typography.fontWeight.regular};
  text-transform: uppercase;
  vertical-align: middle;
  color: ${theme.typography.fontColor.bodyCopy};

  &:hover {
    text-decoration: underline;
  }
`
