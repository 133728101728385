import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { isLoaded, isEmpty } from 'react-redux-firebase'
import { loadStripe } from '@stripe/stripe-js'
// import { analytics } from "../../../firebase/firebase"
import { checkOngoingVerificationAttempt } from '../../../redux/thunks/verification-attempts/checkOngoingVerificationAttempt'
import { getVerificationAttempt } from '../../../redux/thunks/verification-attempts/getVerificationAttempt'
import { verificationAttemptsActions } from '../../../redux/action-creators/verification-attempts'
import Listeners from './listeners/listeners'
import load from './functions/load'
import copyVerificationAttempt from './functions/copy-verification-attempt'
import Loading from './components/loading'
import Start from './components/start'
import Processing from './components/processing'
import Verified from './components/verified'
import VerificationStatusBanner from './components/verification-status-banner'
import VerificationStatusAvatar from './components/verification-status-avatar'
import { Header } from './styled/verify-comps'

function Verify(props) {
  const { setStep } = props
  const [status, setStatus] = useState(null)
  const [verificationAttempt, setVerificationAttempt] = useState({})
  const [verificationAttemptId, setVerificationAttemptId] = useState('')
  const [lastError, setLastError] = useState('')
  const [success, setSuccess] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [stripe, setStripe] = useState(null)
  const currentProfile = useSelector(({ currentProfile }) => currentProfile)
  const auth = useSelector((state) => state.firebase.auth)
  const verificationAttemptsRef = useSelector(({ firestore: { data } }) => {
    return data.verificationAttempts
  })
  const dispatch = useDispatch()
  const newStatus =
    verificationAttemptId &&
    verificationAttemptsRef &&
    verificationAttemptsRef[verificationAttemptId] &&
    verificationAttemptsRef[verificationAttemptId].status

  // ON LOAD: CHECK IF THERE IS ONGOING VERIFICATION ATTEMPT
  useEffect(() => {
    if (isLoading) {
      const actions = {
        loadStripe,
        checkOngoingVerificationAttempt: () => dispatch(checkOngoingVerificationAttempt())
      }

      load(
        setIsLoading,
        setStripe,
        setVerificationAttemptId,
        setLastError,
        setVerificationAttempt,
        setStatus,
        actions
      )
    }
  }, [dispatch, isLoading])

  useEffect(() => {
    if (newStatus !== status) {
      if (newStatus !== null && typeof newStatus !== 'undefined') {
        if (isLoaded(auth) && !isEmpty(auth) && auth.uid) {
          setStatus(newStatus)
        }
      }
    }
  }, [verificationAttempt, auth, auth.uid, verificationAttemptsRef, status, newStatus])

  // IF LOCAL STATUS CHANGES: Retrieve verification attempt by Verfication Session ID, and set in redux and local state.
  useEffect(() => {
    if (status !== null) {
      if (verificationAttemptId) {
        console.log('Verification Attempt Status Change --> Dispatching event to grab updated data')

        dispatch(getVerificationAttempt({ verificationAttemptId })).then((data) => {
          if (data.error) {
            console.log('[FAIL]: Failed to retrieve verification attempt.')
          } else {
            console.log('[SUCCESS]: Successfully retrieved verification attempt.')

            dispatch(verificationAttemptsActions.add([data.verificationAttempt]))
            setVerificationAttempt({ ...copyVerificationAttempt(data.verificationAttempt) })
            setLastError(data.verificationAttempt.lastError)
          }
        })
      }
    }
  }, [dispatch, verificationAttemptId, status])

  const getStepMeta = () => {
    switch (status) {
      case null:
        if (isLoading) {
          return {
            header: 'Verify your identity',
            step: Loading
          }
        } else {
          return {
            header: 'Verify your identity',
            step: Start
          }
        }
      case 'requires_input':
        if (currentProfile.documentVerified) {
          return {
            header: 'Update your identity',
            step: Start
          }
        } else {
          return {
            header: 'Verify your identity',
            step: Start
          }
        }
      case 'processing':
        return {
          header: "We're processing your identification",
          step: Processing
        }
      case 'verified':
        return {
          header: 'Thank you for verifying',
          step: Verified
        }
      // case 'canceled':
      //   return {
      //     header: 'Unable to verify',
      //     step: Start
      //   }
      default:
        if (currentProfile.documentVerified) {
          return {
            header: 'Update your identity',
            step: Start
          }
        } else {
          return {
            header: 'Verify your identity',
            step: Start
          }
        }
    }
  }

  const CurrentStep = getStepMeta().step

  return (
    <>
      {isLoaded(auth) && !isEmpty(auth) && auth.uid && <Listeners auth={auth} />}

      <VerificationStatusBanner />

      <Header>{getStepMeta().header}</Header>

      <VerificationStatusAvatar />

      <CurrentStep
        setStep={setStep}
        status={status}
        setStatus={setStatus}
        verificationAttempt={verificationAttempt}
        setVerificationAttempt={setVerificationAttempt}
        verificationAttemptId={verificationAttemptId}
        setVerificationAttemptId={setVerificationAttemptId}
        lastError={lastError}
        setLastError={setLastError}
        success={success}
        setSuccess={setSuccess}
        stripe={stripe}
      />
    </>
  )
}

export default withRouter(Verify)
