import styled from 'styled-components'

export const MainContainer = styled.div`
  display: block;
  width: 100%;
  max-width: 80rem;
  padding: ${({ padding }) => padding};
  box-sizing: border-box;
  margin: ${({ marginTop }) => marginTop} auto 0 auto;
  font-size: 0;
`

export const LeftContainer = styled.div`
  display: inline-block;
  width: ${({ width }) => width};
  box-sizing: border-box;
  vertical-align: top;
`

export const LeftInnerContainer = styled.div`
  background-color: ${(props) => props.theme.palette.common.white};
  display: block;
  max-width: 65rem;
  margin: 0 auto;
  padding: 1.2rem 2rem 2rem 2rem;
  box-sizing: border-box;
  border-radius: 0.3rem;
  box-shadow: 0px 5px 5px -2px rgba(0, 0, 0, 0.2);

  @media (max-width: 1100px) {
    max-width: none;
    width: 100%;
    margin: 0;
    border-radius: 0;
  }
`

export const HeadingPrimary = styled.p`
  line-height: 2.2rem;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: 1.6rem;
  font-weight: ${(props) => props.theme.typography.fontWeight.bold};
  color: ${(props) => props.theme.palette.text.primary};
`

export const RightContainer = styled.div`
  display: ${({ display }) => display};
  width: 39.5%;
  margin: 0 0 0 1.5%;
  vertical-align: top;
`
