import { popularTagActionTypes } from "../action-creators/popular-tags.js"
import nestedSort from "../../utils/functions/nestedSort.js"

const copyPopularTags = (tags) => {
  return tags.map((tag) => ({ ...tag }))
}

// For Popular Tags
const popularTagsReducer = (state = [], action) => {
  switch (action.type) {
    case popularTagActionTypes.SET_POPULAR_TAGS:
      return copyPopularTags(nestedSort(action.tags, -1, "numPosts"))

    case popularTagActionTypes.CLEAR_POPULAR_TAGS:
      return []

    default:
      return state
  }
}

export default popularTagsReducer
