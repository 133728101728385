import { useDebugValue, useState, Dispatch, SetStateAction } from 'react';

interface SeasonErrors {
  [key: string]: string;
}

function useSeasonErrorsObjState(initialValue: SeasonErrors): [SeasonErrors, Dispatch<SetStateAction<SeasonErrors>>] {
  const [seasonErrorsObj, setSeasonErrorsObj] = useState<SeasonErrors>(initialValue);
  useDebugValue(seasonErrorsObj ? seasonErrorsObj : initialValue);
  return [seasonErrorsObj, setSeasonErrorsObj];
}

export default useSeasonErrorsObjState;