interface ValidationErrors {
  [key: string]: string;
}

function validateDescription(desc: string): ValidationErrors {
  const errors: ValidationErrors = {};

  // Trim spaces
  const trimmedDesc = desc.trim();

  // Required field
  if (!trimmedDesc) {
    errors.required = 'Description is required.';
  }

  // Length constraints
  if (trimmedDesc.length < 10) {
    errors.minLength = 'Description must be at least 10 characters.';
  }

  // Length constraints
  if (trimmedDesc.length > 5000) {
    errors.maxLength = 'Description must be less than 5000 characters.';
  }

  return errors;
}

export default validateDescription;