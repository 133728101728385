const UpdatePhoneValidation = (phone, organization) => {
  if (!phone) {
    return {
      error: 'Phone is required'
    }
  } else if (`+${phone}` === organization.phone.phoneNumber) {
    return {
      error: 'Your organization is already using this phone number.'
    }
  }

  return {
    error: ''
  }
}

export default UpdatePhoneValidation
