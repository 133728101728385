//////////////////
// ACTION TYPES //
//////////////////

export const currentProfileActionTypes = {
  SET_CURRENT_PROFILE: 'SET_CURRENT_PROFILE',
  UPDATE_CURRENT_PROFILE: 'UPDATE_CURRENT_PROFILE',
  ADD_CURRENT_PROFILE_FOLLOWING: 'ADD_CURRENT_PROFILE_FOLLOWING',
  REMOVE_CURRENT_PROFILE_FOLLOWING: 'REMOVE_CURRENT_PROFILE_FOLLOWING',
  ADD_NUM_FRIENDS_INVITED: 'ADD_NUM_FRIENDS_INVITED',
  CLEAR_CURRENT_PROFILE: 'CLEAR_CURRENT_PROFILE'
}

/////////////
// ACTIONS //
/////////////

// CURRENT PROFILE
const setProfile = profile => {
  return {
    type: currentProfileActionTypes.SET_CURRENT_PROFILE,
    profile
  }
}

const updateProfile = fields => {
  return {
    type: currentProfileActionTypes.UPDATE_CURRENT_PROFILE,
    fields
  }
}

const addFollowing = () => {
  return {
    type: currentProfileActionTypes.ADD_CURRENT_PROFILE_FOLLOWING
  }
}

const removeFollowing = () => {
  return {
    type: currentProfileActionTypes.REMOVE_CURRENT_PROFILE_FOLLOWING
  }
}

const addNumFriendsInvited = () => {
  return {
    type: currentProfileActionTypes.ADD_NUM_FRIENDS_INVITED
  }
}

const clearProfile = () => {
  return {
    type: currentProfileActionTypes.CLEAR_CURRENT_PROFILE
  }
}

export const currentProfileActions = {
  setProfile,
  updateProfile,
  addFollowing,
  removeFollowing,
  addNumFriendsInvited,
  clearProfile
}
