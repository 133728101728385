const CreateTakeValidation = (state) => {
  const { take, tagIds, postEmotion, discussionLimit, courseId, courses } = state

  // take Validation
  if (!take.trim()) {
    return {
      error: 'Please enter the content of your post.'
    }
  } else if (take.length > 3000) {
    return {
      error: 'Your post must be 3000 characters or less.'
    }
  }

  // Tag IDs Validation
  if (tagIds.length === 0) {
    return {
      error: 'Please tag this post to at least one issue.'
    }
  }

  // Tag IDs Validation
  if (tagIds.length > 5) {
    return {
      error: 'You are allowed up to 5 tags per post.'
    }
  }

  // validate emotion exists
  if (!postEmotion.trim()) {
    return {
      error: 'Please choose an emotion before posting.'
    }
  }

  // validate discussionLimit values
  if (discussionLimit === null || typeof discussionLimit !== 'number' || discussionLimit < 0) {
    return {
      error: 'Something went wrong. Please select a valid discussion limit from the dropdown menu.'
    }
  }

  // validate courseId
  if (courses.length && courseId === '') {
    return {
      error: 'You must select an option from the course input.'
    }
  }

  return {
    error: ''
  }
}

export default CreateTakeValidation
