import { tokenExpActionTypes } from '../action-creators/token-exp.js'

// FOR TOKEN EXPIRATION DATE
const tokenExpReducer = (state = 0, action) => {
	switch (action.type) {
		case tokenExpActionTypes.SET_TOKEN_EXP:
			return action.date
		case tokenExpActionTypes.CLEAR_TOKEN_EXP:
			return 0
		default:
			return state
	}
}

export default tokenExpReducer