import config from '../../../config'
import { loadedDiscussionsActions } from '../../action-creators/loaded-discussions.js'
import { actionResponseActions } from '../../action-creators/action-response.js'
import { loaderActions } from '../../action-creators/loader.js'
import { authenticatedFetch } from '../../../utils/functions/authenticatedFetch'

/*
 * METHOD TO CREATE RATING
 */
export const createRating = (params) => {
  let responseCode = ''
  let rating
  const method = 'POST'
  const url = `${config.baseURL}/api/rating/`
  const headers = {
    'Content-Type': 'application/json'
  }
  const requestBody = {
    'debateId': params.discussionId,
    'from': params.from,
    'for': params.for,
    'rating': params.rating
  }
  const requestBodyJSON = JSON.stringify(requestBody)

  // Thunk middleware knows how to handle functions.
  // It passes the dispatch method as an argument to the function,
  // thus making it able to dispatch actions itself.
  return (dispatch) => {
    console.log('Sending create rating request to CivilTalk')

    dispatch(loaderActions.startLoader())

    // Return the fetch so react components calling 'store.dispatch()' can use 'then()'
    return authenticatedFetch(url, {
      method,
      body: requestBodyJSON,
      headers,
      credentials: 'include'
    })
      .then((response) => {
        dispatch(loaderActions.stopLoader())
        responseCode = response.status

        return response.json()
      })
      .then((data) => {
        if (responseCode === 201) {
          dispatch(
            loadedDiscussionsActions.addRating({
              discussionId: params.discussionId,
              userId: params.from,
              ratingId: data.rating._id
            })
          )

          return {
            error: false,
            code: responseCode,
            message: data.message,
            rating: data.rating
          }
        } else {
          console.log(data.message)

          return {
            error: true,
            code: responseCode,
            message: data.message
          }
        }
      })
      .then((nextData) => {
        if (nextData.error) {
          return nextData
        } else {
          if (params.isPrivate) {
            return dispatch(
              actionResponseActions.createActionResponse(`You just earned 10 points!`)
            )
          }

          return dispatch(actionResponseActions.createActionResponse(`You just earned 20 points!`))
        }
      })
      .then((nextData2) => {
        return {
          ...nextData2,
          rating
        }
      })
      .catch((error) => {
        console.log(error)

        dispatch(loaderActions.stopLoader())

        return {
          error: true,
          code: '',
          message: error
        }
      })
  }
}
