import { useRef } from 'react'
// import getAvatar from '../../../../../utils/functions/getAvatar'
import LazyLoad from 'react-lazyload'
import makeStyles from '@mui/styles/makeStyles'
import ButtonBase from '@mui/material/ButtonBase'
import { Image, Placeholder, VerifiedBadgeContainer } from '../styled/header-comps'
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'inline-block',
    position: 'relative',
    width: '12rem',
    height: '12rem',
    marginRight: '2.5rem',
    borderRadius: '50%',
    [theme.breakpoints.down('md')]: {
      display: 'block',
      margin: '0 auto'
    },
    [theme.breakpoints.down('sm')]: {
      width: '10rem',
      height: '10rem'
    },
    '&:hover': {
      '& $hoverCopy': {
        opacity: 1
      },
      '& $hoverLabel': {
        opacity: 0.85
      }
    }
  },
  focusVisible: {},
  hoverLabel: {
    position: 'absolute',
    top: '4px',
    left: '4px',
    opacity: 0,
    background: '#777',
    width: '12rem',
    height: '12rem',
    borderRadius: '50%',
    zIndex: '999',
    [theme.breakpoints.down('sm')]: {
      width: '10rem',
      height: '10rem'
    }
  },
  hoverCopy: {
    position: 'absolute',
    left: '52%',
    top: '52%',
    transform: 'translateX(-50%) translateY(-50%)',
    color: theme.palette.common.white,
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: '1.3rem',
    zIndex: 1003,
    opacity: 0
  },
  verifiedIcon: {
    fill: theme.palette.text.primary,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translateX(-50%) translateY(-50%)',
    fontSize: '1.5rem'
  }
}))

function CourseAvatar() {
  const refPlaceholder = useRef()
  const classes = useStyles()
  const removePlaceholder = () => {
    refPlaceholder.current.remove()
  }

  return (
    <ButtonBase
      focusRipple
      focusVisibleClassName={classes.focusVisible}
      className={classes.root}
      // onClick={isCurrentUser ? handleOpen : handleFollowing}
    >
      <>
        <div className={classes.hoverLabel} />
        <span className={classes.hoverCopy}></span>
      </>

      <Placeholder ref={refPlaceholder} />
      <LazyLoad>
        <Image
          onLoad={removePlaceholder}
          onError={removePlaceholder}
          src={`https://res.cloudinary.com/headliner/image/upload/v1725913777/organizations/uconn-seal.jpg`}
          alt={'course image'}
          //verified={documentVerified}
        />
      </LazyLoad>

      <VerifiedBadgeContainer>
        <VerifiedUserIcon className={classes.verifiedIcon} />
      </VerifiedBadgeContainer>
    </ButtonBase>
  )
}

export default CourseAvatar
