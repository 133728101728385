import styled from 'styled-components'
// import config from '../../../../../config.js'

export const MainContainer = styled.div`
  margin-top: 1rem;
`

export const NoResults = styled.div`
  background-color: rgba(255, 255, 255, 0.7);
  padding: 1rem 2rem;
  margin-bottom: 0.8rem;
  text-align: center;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.regular};
  font-weight: ${(props) => props.theme.typography.fontWeight.medium};
  color: ${(props) => props.theme.typography.fontColor.bodyCopy};
  border-radius: 0.3rem;
`
