import styled from 'styled-components'

export const Container = styled.div`
  margin-top: 2rem;

  & > span {
    display: inline-block;
    padding-bottom: 0.2rem;
    font-family: sofia-pro, sans-serif;
    font-size: 1.4rem;
    font-weight: 700;
    color: #333333;
  }
`

export const NoResults = styled.p`
  background-color: ${(props) => props.theme.palette.common.white};
  width: 100%;
  margin-top: 1rem;
  padding: 3rem 0;
  border-radius: 0.3rem;
  text-align: center;
  line-height: ${(props) => props.theme.typography.lineHeight};
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.regular};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  color: ${(props) => props.theme.palette.text.primary};
`
