import styled from 'styled-components'
// import config from "../../../../../../../../config.js"

export const ItemContainer = styled.div`
  display: block;
  ${'' /* margin-top: 2rem; */}
  font-size: 0;
`

export const InputLabel = styled.p`
  display: inline-block;
  width: 35%;
  padding-right: 1.5rem;
  box-sizing: border-box;
  margin-top: 0.65rem;
  vertical-align: top;
  line-height: ${(props) => props.theme.typography.lineHeight};
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.regular};
  font-weight: ${(props) => props.theme.typography.fontWeight.semiBold};
  color: ${(props) => props.theme.palette.text.primary};

  @media (max-width: 600px) {
    display: block;
    width: 100%;
    margin-top: 0;
  }
`

export const ImageContainer = styled.div`
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: block;
  width: 17rem;
  height: 17rem;
  margin: 2rem auto 0 auto;
  border-radius: 50%;
  border: 4px solid;
  border-color: ${(props) => (props.verified ? props.theme.palette.primary.main : '#ccc')};
  position: relative;

  @media (max-width: 500px) {
    width: 13rem;
    height: 13rem;
  }
`
