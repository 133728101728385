import styled from 'styled-components'

export const MainContainer = styled.div`
  display: inline-block;
  background-color: rgba(255, 255, 255, 0.2);
  padding: 0.25rem 0.1rem;
  border-radius: 500px;
  font-size: 0;

  & > img {
    display: inline-block;
    width: 2.2rem;
    height: 2.2rem;
    margin: 0 0.4rem;
    vertical-align: middle;
    cursor: pointer;
  }
`
