import styled from 'styled-components'
import config from '../../../../../config.js'

export const MenuListContainer = styled.nav`
  background-color: #f8f8f8;
  width: 25rem;
  position: fixed;
  top: ${config.UI.header.height};
  right: 0rem;
  bottom: 0rem;
  overflow-y: auto;
  z-index: 1000;
  box-shadow: -6px 69px 10px 4px rgba(100, 100, 100, 0.25);

  @media (max-width: 500px) {
    width: 100%;
  }
`

export const NotificationsHeaderContainer = styled.div`
  background-color: ${(props) => props.theme.palette.secondary.main};
  width: 100%;
  padding: 1rem 0;
  color: ${(props) => props.theme.palette.common.white};
  box-shadow: -6px 3px 10px 4px rgba(100, 100, 100, 0.25);
  position: relative;
`

export const NotificationsHeader = styled.p`
  margin-left: 1.5rem;
  color: ${(props) => props.theme.palette.common.white};
  line-height: ${(props) => props.theme.typography.lineHeight};
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: 1.1rem;
  font-weight: ${(props) => props.theme.typography.fontWeight.strong};
  text-transform: capitalize;
`
