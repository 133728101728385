import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getRolesByOrganizationID } from '../../../../../../redux/thunks/roles/getRolesByOrganizationID'
import { getAssignedRolesByRoleIDs } from '../../../../.././../redux/thunks/assigned-roles/getAssignedRolesByRoleIDs'
import { getProfilesByUserIDs } from '../../../../../../redux/thunks/profiles/getProfilesByUserIDs'
import { getFollowsByFollowingIDs } from '../../../../../../redux/thunks/follows/getFollowsByFollowingIDs'
import Slider from '../../../../../partials/slider/slider'
import { RolesContainer, AvatarListHeader } from '../styled/org-details-comps'

function OrgLeaders(props) {
  const { _id } = props
  const [isLoading, setIsLoading] = useState(true)
  const [roles, setRoles] = useState([])
  const [assignedRoles, setAssignedRoles] = useState([])
  const [profiles, setProfiles] = useState({})
  const dispatch = useDispatch()
  const organizationId = _id

  useEffect(() => {
    if (organizationId) {
      let tmpRoles = []
      let tmpAssignedRoles = []
      let tmpProfiles = {}

      dispatch(getRolesByOrganizationID({ organizationId }))
        .then((data) => {
          tmpRoles = data?.roles?.filter((role) => role.name.toLowerCase() === 'admin')
          const roleIds = tmpRoles?.map((role) => role._id)

          return dispatch(getAssignedRolesByRoleIDs({ roleIds }))
        })
        .then((data) => {
          tmpAssignedRoles = data?.assignedRoles
          const userIds = tmpAssignedRoles?.map((assignedRole) => assignedRole.userId)

          return dispatch(getProfilesByUserIDs({ userIds }))
        })
        .then((data) => {
          tmpProfiles = data.profiles
          const userIds = Object.keys(tmpProfiles)

          return dispatch(getFollowsByFollowingIDs({ userIds }))
        })
        .then((data) => {
          setRoles([...tmpRoles])
          setAssignedRoles([...tmpAssignedRoles])
          setProfiles({ ...tmpProfiles })
          setIsLoading(false)
        })
        .catch((err) => {
          console.log('[ERROR]: in OrgLeader loading', err)
          setIsLoading(false)
        })
    }
  }, [dispatch, organizationId])

  if (isLoading || Object.keys(profiles).length === 0) return null

  return (
    <RolesContainer>
      {roles.map((role) => {
        const filteredAssignedRoles = assignedRoles.filter(
          (assignedRole) => assignedRole.roleId === role._id
        )

        const mappedProfiles = filteredAssignedRoles.map(
          (assignedRole) => profiles[assignedRole.userId] || null
        )

        if (filteredAssignedRoles.length === 0) return null

        return (
          <div key={role._id}>
            <AvatarListHeader>{role.name}:</AvatarListHeader>

            <Slider
              dataType={'profile'}
              profiles={mappedProfiles}
              theme={'light'}
              displayFollowActions={true}
              enableFollowLoading={false}
            />
          </div>
        )
      })}
    </RolesContainer>
  )
}

export default OrgLeaders
