import styled from 'styled-components'

export const Heading = styled.p`
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.extraLarge};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  color: ${(props) => props.theme.typography.fontColor.bodyCopy};
`

// export const Subheading = styled.p`
//   margin-top: 1rem;
//   font-family: ${(props) => props.theme.typography.fontFamily};
//   font-size: ${(props) => props.theme.typography.fontSizes.small};
//   font-weight: ${(props) => props.theme.typography.fontWeight.regular};
//   color: ${(props) => props.theme.typography.fontColor.headerCopy};
// `

export const ButtonContainer = styled.div`
  margin-top: 2rem;
  text-align: center;
`
