import React, { useState, useEffect, useRef } from 'react'
import { withRouter } from 'react-router-dom'
import config from '../../../config'
import { history } from '../../../history/history'
// import WarningBanner from '../../warning-banner/warning-banner'
import SideNavDesktop from './components/side-nav/side-nav-desktop'
import SideNavMobile from './components/side-nav/side-nav-mobile'
import AccountDetails from './components/account-details/account-details'
import ProfilePicture from './components/profile-picture/profile-picture'
import PersonalDetails from './components/personal/personal'
import LocationForm from './components/location/location-form'
import PrivacySettings from './components/privacy/privacy'
import EmailSettings from './components/email/email'
import Identification from './components/identification/identification'
import BillingInfo from './components/billing/billing'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import { IconButton } from '@mui/material'
import {
  MainContainer,
  SectionContainer,
  SectionHeader,
  SectionContentContainer
} from './styled/my-account-comps'

const useStyles = makeStyles((theme) =>
  createStyles({
    iconButton: {
      backgroundColor: 'rgba(0,0,0,.1)',
      padding: '.5rem',
      color: theme.palette.text.secondary,
      borderRadius: '.5rem',
      position: 'absolute',
      top: '50%',
      left: '.5rem',
      transform: 'translateY(-50%)',
      '& span.material-icons': {
        fontSize: '1.8rem'
      },
      '&:hover': {
        backgroundColor: 'rgba(0,0,0,.2)'
      }
    }
  })
)

function MyAccount(props) {
  const tab = useRef(props.match.params.tab)
  const classes = useStyles()
  const [sideNavMobileOpen, setSideNavMobileOpen] = useState(false)
  const [backButton, setBackButton] = useState(false)
  const fullscreen = window.innerWidth <= parseInt(config.UI.drawer_breakpoint.max.split('px')[0])

  const handleWindowResize = () => {
    const newFullscreen =
      window.innerWidth <= parseInt(config.UI.drawer_breakpoint.max.split('px')[0])

    if (newFullscreen) {
      setBackButton(true)
    } else {
      setBackButton(false)
    }

    if (tab.current) {
      setSideNavMobileOpen(false)
    } else {
      if (newFullscreen) {
        setSideNavMobileOpen(true)
      } else {
        history.push('/my-account/account')
      }
    }
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize)

    return () => window.removeEventListener('resize', handleWindowResize)
  }, [])

  useEffect(() => {
    const sections = [
      '',
      'account',
      'picture',
      'personal',
      'location',
      'identification',
      // 'notification',
      'privacy',
      'email'
      // 'billing'
    ]

    if (tab.current) {
      if (sections.indexOf(tab.current) === -1) {
        history.push('/page-not-found')
      }
    } else {
      if (fullscreen) {
        setSideNavMobileOpen(true)
      } else {
        history.push('/my-account/account')
      }
    }
  }, [fullscreen, props])

  const getContentHeader = () => {
    switch (tab.current) {
      case 'account':
        return 'Account Details'
      case 'picture':
        return 'Profile Picture'
      case 'personal':
        return 'Personal Details'
      case 'location':
        return 'Location Settings'
      case 'identification':
        return 'Identification Information'
      // case 'notification':
      //   return 'Notification Settings'
      case 'privacy':
        return 'Privacy Settings'
      case 'email':
        return 'Email Settings'
      // case 'billing':
      //   return 'Billing Information'
      default:
        return 'Account Details'
    }
  }

  return (
    <MainContainer>
      <SideNavMobile open={sideNavMobileOpen} setOpen={setSideNavMobileOpen} />
      <SideNavDesktop />

      {tab.current && (
        <>
          <SectionContainer>
            <SectionHeader>
              {(backButton || fullscreen) && (
                <IconButton
                  className={classes.iconButton}
                  onClick={() => history.push('/my-account')}
                  size="large">
                  <span className="material-icons">chevron_left</span>
                </IconButton>
              )}

              {getContentHeader()}
            </SectionHeader>

            {/* {currentUser.stripe && currentUser.stripe.customerId === '' && (
              <WarningBanner
                type="info"
                htmlBody={
                  <React.Fragment>
                    <b>Attention Beta User:&nbsp;</b> On 4/30/2021 user accounts who have not
                    provided a payment method will be closed. Please add a payment method by
                    navigating to{' '}
                    <a href="/my-account/billing">My Account &gt; Billing Information</a>.
                  </React.Fragment>
                }
                style={{ margin: '.5rem 0' }}
              />
            )}

            {!currentProfile.documentVerified && (
              <WarningBanner
                type="info"
                htmlBody={
                  <React.Fragment>
                    <b>Attention Beta User:&nbsp;</b> On 4/30/2021 user accounts who have not
                    verified their identity will be closed. Please verify your identity by
                    navigating to{' '}
                    <a href="/my-account/identification">My Account &gt; Identification</a>.
                  </React.Fragment>
                }
                style={{ margin: '.5rem 0' }}
              />
            )}

            {(!currentUser.phone || currentUser.phone.phoneNumber === '') && (
              <WarningBanner
                type="info"
                htmlBody={
                  <React.Fragment>
                    {' '}
                    <b>Attention Beta User:&nbsp;</b> On 4/30/2021 user accounts who have not added
                    a phone number will be closed. Please add your phone number by navigating to{' '}
                    <a href="/my-account/account">My Account &gt; Account Information</a>.
                  </React.Fragment>
                }
                style={{ margin: '.5rem 0' }}
              />
            )} */}

            <SectionContentContainer>
              {tab.current === 'account' && <AccountDetails />}
              {tab.current === 'picture' && <ProfilePicture />}
              {tab.current === 'personal' && <PersonalDetails />}
              {tab.current === 'location' && <LocationForm />}
              {tab.current === 'identification' && <Identification />}
              {tab.current === 'privacy' && <PrivacySettings />}
              {tab.current === 'email' && <EmailSettings />}
              {/* {tab.current === 'billing' && <BillingInfo />} */}
            </SectionContentContainer>
          </SectionContainer>
        </>
      )}
    </MainContainer>
  )
}

export default withRouter(MyAccount)
