import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
// import DropDownButton from './drop-down-button'
import DiscussionThumbnail from './discussion-thumbnail'
import ThumbnailSkeleton from './skeletons/discussion-thumbnail-skeleton'
// import MuiTableRow from '@mui/material/TableRow'
// import TableCell from '@mui/material/TableCell'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import MuiNavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import MuiNavigateNextIcon from '@mui/icons-material/NavigateNext'
import MuiIconButton from '@mui/material/IconButton'
// import MuiButton from '@mui/material/Button'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
// import MuiTablePagination from '@mui/material/TablePagination'
// import MuiPagination from '@mui/lab/Pagination'
// import MuiTableFooter from '@mui/material/TableFooter'
import { useTheme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import useMediaQuery from '@mui/material/useMediaQuery'
import {
  MainContainer,
  InnerContainer,
  LeftContainer,
  RightContainer,
  Header,
  NoResults
} from './styled/discussions-comps'

const useStyles = makeStyles((theme) =>
  createStyles({
    expandIcon: {
      color: theme.palette.common.white
    },
    details: {
      // display: 'block',
      padding: 0
    },
    tableContainer: {
      background: '#ffffff',
      padding: '0.5rem 1.5rem',
      width: 'unset',
      [theme.breakpoints.down('md')]: {
        padding: '0.5rem'
      }
    },
    tableRoot: {
      display: 'block',
      tableLayout: 'fixed',
      width: '100%',
      borderCollapse: 'collapse',
      boxSizing: 'border-box',
      textIndent: 'initial',
      borderSpacing: 0
    },
    tableBody: {
      display: 'block',
      verticalAlign: 'middle',
      '& > tr:first-child': {
        '& > td': {
          borderTop: 'none'
        }
      },
      [theme.breakpoints.down('sm')]: {
        '& > tr:last-child': {
          '& > td': {
            borderBottom: '1.5px solid rgba(70, 70, 70, 0.5)'
          }
        }
      }
    },
    tableRow: {
      display: 'flex',
      verticalAlign: 'inherit',
      position: 'relative',
      cursor: 'pointer',
      padding: '0.25rem 0',
      '&:first-child': {
        paddingTop: 0
      },
      [theme.breakpoints.down('md')]: {
        display: 'flex',
        padding: 0
      }
    },
    tablePagination: {
      display: 'block',
      border: 0
    },
    tableCellMobile: {
      flex: '1 1 auto',
      padding: '0.5rem 0',
      marginRight: '1rem',
      borderBottom: 'none',
      borderTop: '1.5px solid rgba(70, 70, 70, 0.5)'
    }
  })
)

// const Button = withStyles((theme) => ({
//   root: {
//     backgroundColor: '#00aeef',
//     color: theme.palette.common.white,
//     width: '10rem',
//     fontSize: '0.875rem'
//   }
// }))(MuiButton)

const ExpandIconButton = withStyles((theme) => ({
  root: {
    width: '1.6rem',
    height: '1.6rem',
    borderRadius: '50%',
    backgroundColor: theme.palette.secondary.main,

    '&:hover': {
      backgroundColor: theme.palette.secondary.light
    }
  }
}))(MuiIconButton)

const PaginationButton = withStyles((theme) => ({
  root: {
    width: '1.6rem',
    height: '1.6rem',
    borderRadius: '50%',
    backgroundColor: theme.palette.secondary.main,
    margin: '0 0.5rem',

    '&:hover': {
      backgroundColor: theme.palette.secondary.light
    }
  }
}))(MuiIconButton)

const BackIcon = withStyles((theme) => ({
  root: {
    color: theme.palette.common.white
  }
}))(MuiNavigateBeforeIcon)

const NextIcon = withStyles((theme) => ({
  root: {
    color: theme.palette.common.white
  }
}))(MuiNavigateNextIcon)

// const Pagination = withStyles((theme) => ({
//   root: {
//     display: 'block',
//     border: 0,
//     padding: 0
//   }
// }))(MuiPagination)

// const TablePagination = withStyles((theme) => ({
//   root: {
//     display: 'block',
//     border: 0,
//     padding: 0
//   },
//   caption: {
//     fontSize: '1rem',
//     fontFamily: theme.fontFamily,
//     fontWeight: 600,
//     color: '#333'
//   },
//   input: {
//     fontSize: '1rem',
//     fontFamily: theme.fontFamily,
//     fontWeight: 600,
//     color: '#333'
//   },
//   selectRoot: {
//     marginLeft: 0,
//     marginRight: '1rem',
//     [theme.breakpoints.down('xs')]: {
//       marginRight: '1.5rem'
//     }
//   },
//   selectIcon: {
//     width: '1.5rem',
//     height: '1.5rem'
//   },
//   actions: {
//     marginLeft: 0,
//     [theme.breakpoints.down('xs')]: {
//       marginLeft: '1.5rem'
//     }
//   }
// }))(MuiTablePagination)

// const TableFooter = withStyles((theme) => ({
//   root: {
//     display: 'flex',
//     justifyContent: 'flex-end',
//     [theme.breakpoints.down('xs')]: {
//       justifyContent: 'space-around'
//     }
//   }
// }))(MuiTableFooter)

// const TableRow = withStyles((theme) => ({
//   root: {
//     display: 'flex',
//     verticalAlign: 'inherit',
//     position: 'relative',
//     cursor: 'pointer',
//     padding: '0.25rem 0',
//     '&:first-child': {
//       paddingTop: 0
//     },
//     [theme.breakpoints.down('sm')]: {
//       display: 'flex',
//       padding: 0
//     }
//   }
// }))(MuiTableRow)

const Accordion = withStyles({
  root: {
    backgroundColor: 'unset',
    overflow: 'hidden'
  },
  expanded: {}
})(MuiAccordion)

const AccordionSummary = withStyles({
  root: {
    padding: 0,
    '&$expanded': {}
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    margin: 0,
    '&$expanded': {
      margin: 0
    }
  },
  expanded: {}
})(MuiAccordionSummary)

const AccordionDetails = withStyles((theme) => ({
  root: {}
}))(MuiAccordionDetails)

// ******************************************************************* //
// *** Component                                                   *** //
// ******************************************************************* //

function Discussions({ discussions, type, handleLoadMore, loadMore, pageLoaded }) {
  /** Discussions filter by Read/Unread */
  // const [filter, setFilter] = useState(false)
  const filter = false
  // *** Redux State *** //
  const currentUser = useSelector((state) => state.currentUser)

  // *** Todo:  save for now *** //
  const filteredDiscussions = filter
    ? discussions.filter((discussion) => {
        const { complete, lastRebuttal, ratings } = discussion

        const unreadOngoing = lastRebuttal && !lastRebuttal.read
        const isCompleteAndNotRated =
          complete && ratings.hasOwnProperty(currentUser.id) ? false : true
        const unread = complete ? isCompleteAndNotRated : unreadOngoing

        return unread
      })
    : discussions

  // *** Todo:  save for now *** //
  // const handleFilteredDiscussions = (event) => {
  //   event.stopPropagation()
  //   setFilter(!filter)
  // }

  // *** Accordion Controls *** //
  const [expanded, setExpanded] = useState(true)
  const handleSetExpanded = (e) => {
    e.stopPropagation()
    e.preventDefault()
    setExpanded(!expanded)
  }

  // *** Todo:  save for now *** //
  /** Pagination Controls */
  const [page, setPage] = useState(0)
  const count = discussions.length
  // const [rowsPerPage, setRowsPerPage] = useState(10)
  const rowsPerPage = 10
  const nextPage = page + 1

  const currentItemsLength = filteredDiscussions.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  ).length

  // console.log('*** currentItemsLength', currentItemsLength)

  const nextItemsLength = filteredDiscussions.slice(
    nextPage * rowsPerPage,
    nextPage * rowsPerPage + rowsPerPage
  ).length

  // console.log('*** nextItemsLength', nextItemsLength)

  const noMoreDiscussions = currentItemsLength < 10 && nextItemsLength === 0

  useEffect(() => {
    if (
      !noMoreDiscussions &&
      count > 0 &&
      typeof nextItemsLength !== 'undefined' &&
      nextItemsLength < rowsPerPage
    ) {
      // console.log('******* LOADING MORE DISCUSSIONS ********')
      handleLoadMore(type)
    }
  }, [noMoreDiscussions, count, page, rowsPerPage, nextItemsLength, handleLoadMore, type])

  const handlePageForward = (event, currentPage) => {
    event.stopPropagation()

    !noMoreDiscussions && setPage((currentPage += 1))
  }

  const handlePageBack = (event, currentPage) => {
    event.stopPropagation()
    const newPage = currentPage !== 0 ? (currentPage -= 1) : 0
    setPage(newPage)
  }

  // *** Todo:  save for now *** //
  // const handleChangeRowsPerPage = (event) => {
  //   event.stopPropagation()
  //   setRowsPerPage(parseInt(event.target.value, 10))
  //   setPage(0)
  // }

  // MUI STYLES
  const classes = useStyles()
  const theme = useTheme()
  const mobileBreakpoint = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <MainContainer>
      <InnerContainer>
        <Accordion
          classes={{}}
          expanded={expanded}
          onChange={(e) => handleSetExpanded(e)}
          elevation={0}>
          <AccordionSummary
            aria-label="Expand"
            aria-controls="expand-contract-content"
            id="expand-contract-header">
            <LeftContainer>
              <Header>{type}</Header>
              <ExpandIconButton>
                {!expanded ? (
                  <ExpandMoreIcon className={classes.expandIcon} />
                ) : (
                  <ExpandLessIcon className={classes.expandIcon} />
                )}
              </ExpandIconButton>
            </LeftContainer>

            <RightContainer>
              {!mobileBreakpoint && typeof discussions !== 'undefined' && count !== 0 && (
                <>
                  {/** Todo:  save for now *** type === 'Inbox' && (
                    <Button onClick={handleFilteredDiscussions}>
                      Show {filter ? 'Unread' : 'All'}
                    </Button>
                  ) */}
                  <PaginationButton onClick={(e) => handlePageBack(e, page)}>
                    <BackIcon />
                  </PaginationButton>
                  <PaginationButton onClick={(e) => handlePageForward(e, page)}>
                    <NextIcon />
                  </PaginationButton>
                </>
              )}
              {/* <DropDownButton type={type} handleLoadMore={handleLoadMore} loadMore={loadMore} /> */}
            </RightContainer>
          </AccordionSummary>

          <AccordionDetails className={classes.details}>
            <TableContainer className={classes.tableContainer}>
              <Table classes={{ root: classes.tableRoot }}>
                <TableHead></TableHead>
                <TableBody className={classes.tableBody}>
                  {!pageLoaded ? (
                    <>
                      <ThumbnailSkeleton />
                    </>
                  ) : (
                    <>
                      {filteredDiscussions.length === 0 ? (
                        <NoResults>No Discussions</NoResults>
                      ) : (
                        (typeof filteredDiscussions !== 'undefined' &&
                        count !== 0 &&
                        rowsPerPage > 0
                          ? filteredDiscussions.slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                          : filteredDiscussions
                        ).map((discussion, index) => {
                          return (
                            <DiscussionThumbnail
                              discussion={discussion}
                              key={discussion._id}
                              type={type}
                            />
                          )
                        })
                      )}
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>
      </InnerContainer>
    </MainContainer>
  )
}

export default Discussions
