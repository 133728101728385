import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import load from './functions/load'
import { getOrganizationFollows } from '../../../redux/thunks/follows/getOrganizationFollows'
import { getProfilesByUserIDs } from '../../../redux/thunks/profiles/getProfilesByUserIDs'
import { getOrganizationsByIDs } from '../../../redux/thunks/organizations/getOrganizationsByIDs'
import { getFollowsByFollowingIDs } from '../../../redux/thunks/follows/getFollowsByFollowingIDs'
import loadMoreCheck from '../../../utils/functions/loadMoreCheck'
import Center from './components/center'
import { OuterContainer } from './components/styled/connections-organization-comps'

const getFilteredFollows = (follows, organizationId) => {
  return follows.filter((follow) => follow.organizationId === organizationId)
}

class ConnectionsOrganization extends Component {
  constructor(props) {
    super(props)

    this.state = {
      status: {
        isLoading: true,
        isLoadingMore: false,
        moreToLoad: true
      }
    }

    this.handleSetState = this.handleSetState.bind(this)
    this.loadConnections = this.loadConnections.bind(this)
    this.trackScrolling = this.trackScrolling.bind(this)
  }

  componentDidMount() {
    this.loadConnections(true, Date.now())
      .then(() => {
        document.addEventListener('scroll', this.trackScrolling)
      })
      .catch(() => {
        console.log('[Error]: ', 'Failed to load posts component.')
      })
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.trackScrolling)
  }

  handleSetState(object) {
    this.setState(object)
  }

  loadConnections(isLoadingPage, afterDate) {
    const { match } = this.props

    const actions = {
      getOrganizationFollows: this.props.getOrganizationFollows,
      getProfilesByUserIDs: this.props.getProfilesByUserIDs,
      getOrganizationsByIDs: this.props.getOrganizationsByIDs,
      getFollowsByFollowingIDs: this.props.getFollowsByFollowingIDs
    }

    // Call function to handle profile load conditions
    return load(
      isLoadingPage,
      afterDate,
      match.params.organizationId, // urlUserID
      this.state, // state
      this.handleSetState, // setState
      actions // actions
    )
  }

  trackScrolling() {
    const { follows, match } = this.props
    const { status } = this.state
    const el = document.getElementById('connections-list')

    if (status.moreToLoad) {
      const filteredFollows = getFilteredFollows(follows, match.params.organizationId)

      if (el !== null && typeof el !== 'undefined' && filteredFollows.length > 0) {
        if (!status.isLoading && !status.isLoadingMore) {
          if (loadMoreCheck(el)) {
            document.removeEventListener('scroll', this.trackScrolling)

            const afterDate = filteredFollows[filteredFollows.length - 1].whenCreated

            this.setState({
              ...this.state,
              status: {
                ...this.state.status,
                isLoadingMore: true
              }
            })

            this.loadConnections(false, afterDate)
              .then(() => {
                document.addEventListener('scroll', this.trackScrolling)
              })
              .catch((error) => {
                console.log('[ERROR]: ', error)
              })
          }
        }
      }
    } else {
      document.removeEventListener('scroll', this.trackScrolling)
    }
  }

  render() {
    return (
      <OuterContainer id="connections-list">
        <Center state={this.state} />
        {/* <Right /> */}
      </OuterContainer>
    )
  }
}

const mapStateToProps = (state) => ({
  follows: state.follows
})

const mapDispatchToProps = (dispatch) => ({
  getOrganizationFollows: (params) => {
    return dispatch(getOrganizationFollows(params))
  },
  getProfilesByUserIDs: (params) => {
    return dispatch(getProfilesByUserIDs(params))
  },
  getOrganizationsByIDs: (params) => {
    return dispatch(getOrganizationsByIDs(params))
  },
  getFollowsByFollowingIDs: (params) => {
    return dispatch(getFollowsByFollowingIDs(params))
  }
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ConnectionsOrganization))
