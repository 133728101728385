import styled from 'styled-components'
import config from '../../../../../../../../config.js'

export const Header = styled.p`
  color: ${(props) => props.theme.typography.fontColor.bodyCopy};
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-weight: ${(props) => props.theme.typography.fontWeight.bold};
  font-size: ${(props) => props.theme.typography.fontSizes.tall};

  @media (max-width: ${config.UI.drawer_breakpoint.max}) {
    text-align: center;
  }
`

export const Subheader = styled.p`
  margin-top: 1rem;
  color: ${(props) => props.theme.typography.fontColor.bodyCopy};
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  font-size: ${(props) => props.theme.typography.fontSizes.regular};

  @media (max-width: ${config.UI.drawer_breakpoint.max}) {
    text-align: center;
  }
`

export const CurrentAddressContainer = styled.div`
  margin-top: 1rem;
  background-color: #eee;
  padding: 1rem;
  border-radius: 0.3rem;
`

export const CurrentAddressHeader = styled.div`
  margin-bottom: 0.5rem;
  color: ${(props) => props.theme.typography.fontColor.bodyCopy};
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-weight: ${(props) => props.theme.typography.fontWeight.bold};
  font-size: ${(props) => props.theme.typography.fontSizes.regular};

  @media (max-width: ${config.UI.drawer_breakpoint.max}) {
    text-align: center;
  }
`

export const CurrentAddressLine = styled.p`
  color: ${(props) => props.theme.typography.fontColor.bodyCopy};
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  font-size: ${(props) => props.theme.typography.fontSizes.regular};

  @media (max-width: ${config.UI.drawer_breakpoint.max}) {
    text-align: center;
  }
`

export const Form = styled.form`
  margin-top: 1rem;
`

export const InputContainer = styled.div`
  display: block;
  margin-top: 1rem;
`

export const InputLabel = styled.p`
  display: inline-block;
  width: 35%;
  vertical-align: middle;
  box-sizing: border-box;
  line-height: ${(props) => props.theme.typography.lineHeight};
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.regular};
  font-weight: ${(props) => props.theme.typography.fontWeight.semiBold};
  color: ${(props) => props.theme.typography.fontColor.bodyCopy};

  & > span {
    margin-left: 0.2rem;
    color: #cc0000;
  }

  @media (max-width: 600px) {
    display: block;
    width: 100%;
    margin-top: 0;
  }
`

export const Input = styled.input`
  background-color: ${({ disabled }) =>
    disabled ? 'rgba(150, 150, 150, .1)' : 'rgba(0, 0, 0, 0)'};
  display: inline-block;
  padding: 0.6rem;
  width: 65%;
  margin-top: ${({ marginTop }) => marginTop || '0'};
  vertical-align: middle;
  border: 1px solid #ddd;
  border-radius: 0.2rem;
  box-sizing: border-box;
  box-shadow: 0px 9px 7px -2px rgba(120, 120, 120, 0.1);
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.regular};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  color: ${({ theme, disabled }) => (disabled ? '#777' : theme.typography.fontColor.bodyCopy)};

  @media (max-width: ${config.UI.drawer_breakpoint.max}) {
    display: block;
    width: 100%;
    margin-top: 0.7rem;
  }
`

export const SelectContainer = styled.div`
  display: inline-block;
  width: 65%;

  @media (max-width: ${config.UI.drawer_breakpoint.max}) {
    display: block;
    width: 100%;
    margin-top: 0.7rem;
  }
`
