import styled from 'styled-components'
import config from '../../../../../../../../config.js'

export const ItemContainer = styled.div`
  display: block;
  margin-top: 2rem;
  font-size: 0;
`

export const InputLabel = styled.p`
  display: inline-block;
  width: 35%;
  padding-right: 1.5rem;
  box-sizing: border-box;
  margin-top: 0.65rem;
  vertical-align: top;
  line-height: ${(props) => props.theme.typography.lineHeight};
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.regular};
  font-weight: ${(props) => props.theme.typography.fontWeight.semiBold};
  color: ${(props) => props.theme.typography.fontColor.bodyCopy};

  @media (max-width: 600px) {
    display: block;
    width: 100%;
    margin-top: 0;
  }
`

export const InputsContainer = styled.div`
  display: inline-block;
  width: 65%;
  vertical-align: top;
  padding-bottom: 0.5rem;

  @media (max-width: 600px) {
    display: block;
    width: 100%;
    margin-top: 0.7rem;
  }
`

export const Input = styled.input`
  background-color: ${({ disabled }) =>
    disabled ? 'rgba(150, 150, 150, .1)' : 'rgba(0, 0, 0, 0)'};
  padding: 0.6rem;
  width: ${(props) => (props.width ? props.width : '10.65rem')};
  margin-top: ${({ marginTop }) => marginTop || '0'};
  vertical-align: middle;
  border: 1px solid #ddd;
  border-radius: 0.2rem;
  box-sizing: border-box;
  box-shadow: 0px 9px 7px -2px rgba(120, 120, 120, 0.1);
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.regular};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  color: ${({ theme, disabled }) => (disabled ? '#777' : theme.typography.fontColor.bodyCopy)};

  @media (max-width: ${config.UI.drawer_breakpoint.max}) {
    width: 100%;
  }

  @media (max-width: 600px) {
    display: block;
    width: 100%;
    margin-top: 0.7rem;
  }
`
