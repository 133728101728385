import styled from 'styled-components'
import config from '../../../../../../../../config'

export const MainContainer = styled.div`
  background-color: ${(props) => props.theme.palette.common.white};
  padding-bottom: 2rem;
  margin-bottom: 1.5rem;
  border-bottom: 1px solid ${(props) => props.theme.palette.background.heavyGray};
  padding: 1.2rem 1.5rem 0.6rem 1.5rem;
  position: relative;
  box-sizing: border-box;
  border-radius: 0.3rem;
  box-shadow: ${(props) => props.theme.boxShadow};

  @media (max-width: ${config.UI.drawer_breakpoint.max}) {
    padding-bottom: 1.5rem;
  }
`

export const ActionContainer = styled.div``

export const UserAvatarContainer = styled.div`
  display: inline-block;
  margin-right: 2rem;
  vertical-align: middle;

  @media (max-width: ${config.UI.drawer_breakpoint.max}) {
    margin-right: 1.5rem;
  }

  @media (max-width: 599px) {
    display: none;
  }
`

export const PostFormContainer = styled.div`
  max-width: 40rem;

  @media (min-width: 800px) {
    min-width: 40rem;
  }
`
