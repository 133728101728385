import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import some from 'lodash/some'
import { analytics } from '../../../../../../firebase/firebase'
/** REDUX */
import { checkFollowingOrganization } from '../../../../../../redux/thunks/follows/checkFollowingOrganization'
import { createOrganizationFollow } from '../../../../../../redux/thunks/follows/createOrganizationFollow'
import { deleteFollowByOrganizationID } from '../../../../../../redux/thunks/follows/deleteFollowByOrganizationID'
/** MUI */
import MuiTooltip from '@mui/material/Tooltip'
import Button from '@mui/material/Button'
import makeStyles from '@mui/styles/makeStyles'
import withStyles from '@mui/styles/withStyles'
import { PersonAddSpan } from '../styled/org-header-comps'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    top: '1.5rem',
    right: '1.5rem',
    padding: 0,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.secondary.main,
    borderRadius: '50%',
    minWidth: '0',
    minHeight: '0',
    height: '2.5rem',
    width: '2.5rem',
    [theme.breakpoints.down('md')]: {
      top: '1rem',
      right: '1rem',
      height: '2.5rem',
      width: '2.5rem'
    },
    [theme.breakpoints.down('sm')]: {
      top: '0.75rem',
      right: '1rem',
      height: '2.35rem',
      width: '2.35rem'
    },
    [theme.breakpoints.down(undefined)]: {
      top: '0.5rem',
      right: '0.5rem',
      height: '2.35rem',
      width: '2.35rem'
    }
  },
  label: {
    borderRadius: '50%'
  }
}))

const Tooltip = withStyles((theme) => ({
  tooltip: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.secondary.main,
    fontSize: '1rem'
  }
}))(MuiTooltip)

function FollowButton({ organization }) {
  const { _id, publicName } = organization
  const organizationId = _id
  // const [isFollowing, setIsFollowing] = useState(false)
  const classes = useStyles()
  const dispatch = useDispatch()
  const currentUser = useSelector(({ currentUser }) => currentUser)
  const follows = useSelector(({ follows }) => follows)
  const isFollowing = some(follows, { organizationId: _id, follower: currentUser.id })

  useEffect(() => {
    if (organizationId !== '') {
      dispatch(checkFollowingOrganization({ organizationId })).then((data) => {
        // if a follow exists it will be in the redux follows now
      })
    }
  }, [organization, isFollowing, dispatch, organizationId])

  const handleFollow = () => {
    dispatch(
      createOrganizationFollow({
        organizationId: _id,
        name: publicName
      })
    ).then((data) => {
      if (!data.error) {
        console.log('[SUCCESS]: ', 'Successfully followed organization!')

        // setIsFollowing(true)
      } else {
        console.log('[FAIL]: ', 'Failed to follow organization.')
      }
    })

    analytics.logEvent('toggle_follow')
  }

  const handleUnfollow = () => {
    dispatch(
      deleteFollowByOrganizationID({
        organizationId: _id,
        currUserId: currentUser.id,
        name: publicName
      })
    ).then((data) => {
      if (!data.error) {
        console.log('[SUCCESS]: ', 'Successfully unfollowed organization!')

        // setIsFollowing(false)
      } else {
        console.log('[FAIL]: ', 'Failed to unfollow organization.')
      }
    })
  }

  return (
    <Tooltip title={isFollowing ? 'Unfollow' : 'Follow'}>
      <Button
        onClick={isFollowing ? handleUnfollow : handleFollow}
        classes={{ root: classes.root, label: classes.label }}
        variant="contained">
        {isFollowing ? (
          <PersonAddSpan className="material-icons" style={{ fontSize: '1.3rem' }}>
            person_remove
          </PersonAddSpan>
        ) : (
          <PersonAddSpan className="material-icons" style={{ fontSize: '1.3rem' }}>
            person_add
          </PersonAddSpan>
        )}
      </Button>
    </Tooltip>
  )
}

export default FollowButton
