import config from '../../../config'
import { authenticatedFetch } from '../../../utils/functions/authenticatedFetch'
import { myCoursesActions } from '../../action-creators/my-courses'

export const getMyCourses = (params) => {
  const { userId } = params

  const method = 'GET'
  const headers = {
    'Content-Type': 'application/json'
  }
  const url = `${config.baseURL}/api/course/my-courses/${userId}`

  const fetchOptions = {
    method,
    headers,
    credentials: 'include'
  }

  return async (dispatch) => {
    console.log('[FETCH]: get my courses request to CivilTalk')

    try {
      const response = await authenticatedFetch(url, fetchOptions)

      const data = await response.json()

      if (response.status === 200) {
        console.log('[SUCCESS]: retrieved my courses', data)

        dispatch(myCoursesActions.setCourses(data.courses))
        return {
          error: false,
          code: response.status,
          message: data.message,
          courses: data.courses
        }
      } else {
        console.error('[ERROR]: failed to get my courses', data)
        return {
          error: true,
          code: response.status,
          message: data.message
        }
      }
    } catch (error) {
      console.error('[ERROR]: caught while getting my courses:', error)

      return {
        error: true,
        code: '500',
        message: error.message || error
      }
    }
  }
}
