function validateFourDigitNumber(input) {
  const errors = {}

  // Trim spaces
  const trimmedInput = input.trim()

  // Required field
  if (!trimmedInput) {
    errors.required = 'Input is required.'
  }

  // Length constraint
  if (trimmedInput.length !== 4) {
    errors.length = 'Input must be exactly 4 digits.'
  }

  // Digit-only constraint
  if (!/^\d{4}$/.test(trimmedInput)) {
    errors.invalidChars = 'Input must contain only digits.'
  }

  return errors
}

export default validateFourDigitNumber
