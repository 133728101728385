import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { history } from '../../../history/history'
import load from './functions/load'
import getFilteredFollows from './functions/get-filtered-follows'
import { getUserFollows } from '../../../redux/thunks/follows/getUserFollows'
import { getProfilesByUserIDs } from '../../../redux/thunks/profiles/getProfilesByUserIDs'
import { getOrganizationsByIDs } from '../../../redux/thunks/organizations/getOrganizationsByIDs'
import { getFollowsByFollowingIDs } from '../../../redux/thunks/follows/getFollowsByFollowingIDs'
import loadMoreCheck from '../../../utils/functions/loadMoreCheck'
import Center from './components/center'
import { OuterContainer } from './components/styled/connections-comps'
import includes from 'lodash/includes'

class Connections extends Component {
  constructor(props) {
    super(props)

    this.state = {
      status: {
        isLoading: true,
        isLoadingMore: false,
        moreToLoad: true,
        filter: 'followers'
      }
    }

    this.handleSetState = this.handleSetState.bind(this)
    this.loadConnections = this.loadConnections.bind(this)
    this.trackScrolling = this.trackScrolling.bind(this)
    this.handleFilterSelect = this.handleFilterSelect.bind(this)
  }

  componentDidMount() {
    const { match } = this.props

    if (match.params.filter && !includes(['followers', 'following'], match.params.filter)) {
      history.push(`/page-not-found`)
    } else {
      const filter = !match.params.filter ? 'followers' : match.params.filter

      this.setState(
        {
          ...this.state,
          status: {
            ...this.state.status,
            filter
          }
        },
        () =>
          this.loadConnections(true, Date.now(), filter)
            .then(() => {
              document.addEventListener('scroll', this.trackScrolling)
            })
            .catch(() => {
              console.log('[Error]: ', 'Failed to load posts component.')
            })
      )
    }
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.trackScrolling)
  }

  handleSetState(object) {
    this.setState(object)
  }

  loadConnections(isLoadingPage, afterDate, filter) {
    const { match } = this.props

    const connectionsActions = {
      getUserFollows: this.props.getUserFollows,
      getProfilesByUserIDs: this.props.getProfilesByUserIDs,
      getOrganizationsByIDs: this.props.getOrganizationsByIDs,
      getFollowsByFollowingIDs: this.props.getFollowsByFollowingIDs
    }

    // Call function to handle profile load conditions
    return load(
      isLoadingPage,
      afterDate,
      match.params.userId, // urlUserID
      filter,
      this.state, // state
      this.handleSetState, // setState
      connectionsActions // actions
    )
  }

  trackScrolling() {
    const { follows, match } = this.props
    const { status } = this.state
    const el = document.getElementById('connections-list')

    if (status.moreToLoad) {
      const filteredFollows = getFilteredFollows(follows, status.filter, match.params.userId)

      if (el !== null && typeof el !== 'undefined' && filteredFollows.length > 0) {
        if (!status.isLoading && !status.isLoadingMore) {
          if (loadMoreCheck(el)) {
            document.removeEventListener('scroll', this.trackScrolling)

            const afterDate = filteredFollows[filteredFollows.length - 1].whenCreated

            this.setState({
              ...this.state,
              status: {
                ...this.state.status,
                isLoadingMore: true
              }
            })

            this.loadConnections(false, afterDate, status.filter)
              .then(() => {
                document.addEventListener('scroll', this.trackScrolling)
              })
              .catch((error) => {
                console.log('[ERROR]: ', error)
              })
          }
        }
      }
    } else {
      document.removeEventListener('scroll', this.trackScrolling)
    }
  }

  handleFilterSelect(filter) {
    document.removeEventListener('scroll', this.trackScrolling)

    this.setState(
      {
        ...this.state,
        status: {
          ...this.state.status,
          isLoading: true,
          isLoadingMore: false,
          moreToLoad: false,
          filter
        }
      },
      () =>
        this.loadConnections(true, Date.now(), filter)
          .then(() => {
            document.addEventListener('scroll', this.trackScrolling)
          })
          .catch((error) => {
            console.log('[ERROR]: ', error)
          })
    )
  }

  render() {
    return (
      <OuterContainer id="connections-list">
        <Center state={this.state} handleFilterSelect={this.handleFilterSelect} />
        {/* <Right /> */}
      </OuterContainer>
    )
  }
}

const mapStateToProps = (state) => ({
  follows: state.follows
})

const mapDispatchToProps = (dispatch) => ({
  getUserFollows: (params) => {
    return dispatch(getUserFollows(params))
  },
  getProfilesByUserIDs: (params) => {
    return dispatch(getProfilesByUserIDs(params))
  },
  getOrganizationsByIDs: (params) => {
    return dispatch(getOrganizationsByIDs(params))
  },
  getFollowsByFollowingIDs: (params) => {
    return dispatch(getFollowsByFollowingIDs(params))
  }
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Connections))
