export const getResponsiveStyles = (width) => {
  const styles = {
    tagCardsContainer: {
      marginRight: '0.5rem'
    },
    tagCard: {
      display: 'inline-block'
    }
  }

  if (width) {
    if (width <= 200) {
      styles.tagCardsContainer.marginRight = '0'
      styles.tagCard.display = 'block'
    }
  }

  return styles
}
