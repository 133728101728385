import { rolesActionTypes } from '../action-creators/roles'

const rolesReducer = (state = [], action) => {
  switch (action.type) {
    case rolesActionTypes.SET_ROLES:
      return action.roles
    case rolesActionTypes.CLEAR_ROLES:
      return []
    default:
      return state
  }
}

export default rolesReducer
