import styled from 'styled-components'
import config from '../../../../config.js'

export const MainContainer = styled.div`
  display: block;
  max-width: 80rem;
  margin: ${config.UI.main.marginTop} auto 0 auto;
  padding: 0 1rem;
  box-sizing: border-box;

  @media (max-width: ${config.UI.drawer_breakpoint.max}) {
    width: 100%;
    margin: 0;
    padding: 0.5rem;
  }
`

export const SectionContainer = styled.div`
  display: inline-block;
  min-height: 45rem;
  /* Firefox */
  width: -moz-calc(100% - 23.5rem);
  /* WebKit */
  width: -webkit-calc(100% - 23.5rem);
  /* Opera */
  width: -o-calc(100% - 23.5rem);
  /* Standard */
  width: calc(100% - 23.5rem);
  vertical-align: top;
  border-radius: 0.3rem;

  @media (max-width: ${config.UI.drawer_breakpoint.max}) {
    display: inline-block;
    width: 100%;
    box-sizing: border-box;
  }
`

export const SectionHeader = styled.div`
  background-color: ${(props) => props.theme.palette.secondary.main};
  padding: 1rem 4rem;
  border-radius: 0.3rem;
  text-align: center;
  line-height: 1.9rem;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: 1.6rem;
  font-weight: ${(props) => props.theme.typography.fontWeight.semiBold};
  color: ${(props) => props.theme.palette.text.secondary};
  position: relative;
`

export const SectionContentContainer = styled.div`
  background-color: ${(props) => props.theme.palette.common.white};
  min-height: 40.7rem;
  padding: 2rem 3rem;
  margin-top: 0.5rem;
  margin-bottom: 2rem;
  border-radius: 0.3rem;
  box-sizing: border-box;
  position: relative;

  @media (max-width: 600px) {
    padding: 1.5rem;
  }
`
