import find from 'lodash/find'

/**
 * @function checkOrgPermissions
 * @param {array} assignedRoles - array of assignedRole objects
 * @param {array} roles - array of role objects
 * @param {string} currUserId - the currentUsers id
 * @param {array} permissions - an array of permissions to check against the organization permissions property of the roles object
 * @returns {boolean}
 */
function checkOrgPermissions(
  options = {
    assignedRoles: null,
    roles: null,
    currUserId: null,
    permissions: null // permissions => ['managePosts', 'manageOrganizationDetails', 'manageRoles']
  }
) {
  if (!options || Object.keys(options).length === 0) {
    throw new Error('Invalid params <options> in checkOrgPermissions')
  }

  const { assignedRoles, roles, currUserId, permissions } = options

  if (!assignedRoles) {
    throw new Error('Invalid params <options.assignedRoles> in checkOrgPermissions')
  }

  if (!roles) {
    throw new Error('Invalid params <options.roles> in checkOrgPermissions')
  }

  if (!currUserId) {
    throw new Error('Invalid params <options.currUserId> in checkOrgPermissions')
  }

  if (!permissions) {
    throw new Error('Invalid params <options.permissions> in checkOrgPermissions')
  }

  const mappedAssignedRoles = assignedRoles.map((assignedRole) => {
    // using the roleId as a key to merge each role with the corresponding assigned role
    const { roleId } = assignedRole

    const role = find(roles, { _id: roleId })

    if (!role) {
      return null
    }

    return {
      ...assignedRole,
      role
    }
  })

  const myAssignedRoles = mappedAssignedRoles.filter(
    (assignedRole) => assignedRole && assignedRole.userId === currUserId
  )

  if (myAssignedRoles.length === 0) {
    return false
  }

  for (let i = 0; i < myAssignedRoles.length; i++) {
    const assignedRole = myAssignedRoles[i]

    if (!assignedRole) {
      continue
    }

    if (assignedRole.role.organizationPermissions.admin) {
      return true
    }

    for (let j = 0; j < permissions.length; j++) {
      const permission = permissions[j]

      if (assignedRole.role.organizationPermissions[permission]) {
        return true
      }
    }
  }

  return false
}

export default checkOrgPermissions
