import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import debounce from 'lodash/debounce'
import { searchUsers } from '../../../redux/thunks/search/searchUsers'
import getAvatar from '../../../utils/functions/getAvatar'
import getName from '../../../utils/functions/getName'
import { ClickAwayListener, Grow, Paper, Popper, MenuList, MenuItem } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import {
  MainContainer,
  Input,
  Result,
  ProfileContainer,
  ProfilePicture,
  ProfileInfoContainer,
  ProfileName,
  ProfileLocation
} from './styled/search-user-comps'

const styles = {
  popper: {
    width: '100%'
  },
  menuList: {
    padding: '.2rem 0'
  },
  menuItem: {
    padding: '0 ',
    height: 'auto',
    minHeight: 'auto',
    whiteSpace: 'normal'
  }
}

const SearchUser = ({ setUserId, setError, style }) => {
  const inputRef = useRef(null)
  const [didRecognizeRef, setDidRecognizeRef] = useState(false)
  const [profiles, setProfiles] = useState([])
  const [searchQuery, setSearchQuery] = useState('')
  const [searchResultsOpen, setSearchResultsOpen] = useState(false)
  const accessToken = useSelector(({ accessToken }) => accessToken)
  const dispatch = useDispatch()

  const handleSearch = useRef(
    debounce((query) => {
      dispatch(searchUsers({ token: accessToken, query, limit: 10 })).then((data) => {
        if (!data.error) {
          console.log('[SUCCESS]: ', 'User search succeeded!')

          setProfiles([...data.profiles])
        } else {
          console.log('[FAIL]: ', 'Failed to search users.')

          setError(data.message)
        }

        setSearchResultsOpen(true)
      })
    }, 300)
  ).current

  const handleSelectUser = (e) => {
    e.preventDefault()

    setUserId(e.target.id)
    setSearchQuery('')
  }

  useEffect(() => {
    if (!didRecognizeRef) {
      if (inputRef.current !== null && typeof inputRef.current !== 'undefined') {
        setDidRecognizeRef(true)
      }
    }
  }, [inputRef, didRecognizeRef])

  useEffect(() => {
    if (searchQuery && searchQuery.length > 0) {
      handleSearch(searchQuery)
    } else {
      setSearchResultsOpen(false)
    }
  }, [searchQuery, handleSearch])

  const searchResults = profiles.map((profile) => {
    const { userId, avatar, personalAddress, privacySettings, documentVerified } = profile
    const showLocation =
      personalAddress.city !== '' && personalAddress.state !== '' && privacySettings.showLocation
    const verified = documentVerified ? 1 : 0

    return (
      <MenuItem id={userId} key={userId} sx={styles.menuItem} onClick={handleSelectUser}>
        <ProfileContainer id={userId}>
          <ProfilePicture
            id={userId}
            style={{
              backgroundImage: `url(${getAvatar(avatar)})`
            }}
            verified={verified}
          />

          <ProfileInfoContainer id={userId}>
            <ProfileName id={userId} style={!showLocation ? { marginTop: '.5rem' } : {}}>
              {getName(profile)}
            </ProfileName>

            {showLocation && (
              <ProfileLocation id={userId}>
                {personalAddress.city + ', ' + personalAddress.state}
              </ProfileLocation>
            )}
          </ProfileInfoContainer>
        </ProfileContainer>
      </MenuItem>
    )
  })

  return (
    <MainContainer style={style || {}}>
      <SearchIcon id="search-icon" />

      <Input
        ref={inputRef}
        type="text"
        placeholder={'e.g. Jane Smith'}
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />

      {didRecognizeRef && (
        <Popper
          open={searchResultsOpen}
          anchorEl={inputRef.current}
          placement="bottom-start"
          transition
          disablePortal
          sx={styles.popper}
          style={{ zIndex: 100, right: '0' }}>
          {({ TransitionProps }) => (
            <Grow {...TransitionProps} id="nav-menu-dropdown">
              <Paper>
                <ClickAwayListener onClickAway={() => setSearchResultsOpen(false)}>
                  <MenuList sx={styles.menuList}>
                    {searchResults}

                    {searchResults.length === 0 && (
                      <MenuItem sx={styles.menuItem}>
                        <Result>
                          <span>
                            No results found for <b>{searchQuery.trim()}</b>
                          </span>
                        </Result>
                      </MenuItem>
                    )}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      )}
    </MainContainer>
  )
}

export default SearchUser
