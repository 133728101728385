const OrganizationRoleValidation = (state) => {
  // Name
  if (!state.name) {
    return {
      error: 'Name is required.'
    }
  } else if (state.name.length > 50) {
    return {
      error: 'Name must be 50 characters or less.'
    }
  }

  // Description
  if (!state.desc) {
    return {
      error: 'Description is required.'
    }
  } else if (state.desc.length > 250) {
    return {
      error: 'Description must be 250 characters or less.'
    }
  }

  // Ranking
  if (!state.ranking) {
    return {
      error: 'Ranking is required.'
    }
  } else if (isNaN(state.ranking)) {
    return {
      error: 'Ranking must be a number.'
    }
  } else if (parseInt(state.ranking) < 1 || parseInt(state.ranking) > 999) {
    return {
      error: 'Ranking must be a number ranging from 1 to 999.'
    }
  }

  return {
    error: ''
  }
}

export default OrganizationRoleValidation
