function CreatePromptValidation({ title, prompt, courseId, publishDate }) {
  // validate title
  if (!title.trim()) {
    return {
      error: 'Please enter a title for your prompt.'
    }
  } else if (title.length > 300) {
    return {
      error: 'Your prompt title must be 300 characters or less.'
    }
  }

  // validate prompt
  if (!prompt.trim()) {
    return {
      error: 'Please enter the content of your prompt.'
    }
  } else if (prompt.length > 3000) {
    return {
      error: 'Your prompt must be 3000 characters or less.'
    }
  }

  // validate courseId
  if (!courseId.trim()) {
    return {
      error: 'No courseId associated with this prompt. Please contact support.'
    }
  }

  // validate publishDate
  if (!publishDate) {
    return {
      error: 'No publishDate associated with this prompt. Please contact support.'
    }
  }

  return {
    error: ''
  }
}

export default CreatePromptValidation
