import styled from "styled-components"
import theme from "../../../../../../res/theme.js"
// import config from '../../../../config.js'

export const NavItemContainer = styled.div`
  margin: 0;
  padding: 0;
  font-size: 0;
`

export const NavItem = styled.span`
  display: inline-block;
  cursor: pointer;
  font-family: ${theme.typography.fontFamily};
  font-size: ${theme.typography.fontSize};
  font-weight: ${theme.typography.fontWeight.regular};
  text-transform: uppercase;
  vertical-align: middle;
  color: ${theme.typography.fontColor.bodyCopy};

  &:hover {
    text-decoration: underline;
  }
`
