import { useState, useEffect, useContext } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import find from 'lodash/find'
import { updateOrganizationRoleByID } from '../../../../../../../../../redux/thunks/roles/updateOrganizationRoleByID'
import FormError from '../../../../../../../../alerts/form-error'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import { MainContainer, InputContainer, InputLabel, InputDesc, Input } from './styled/details-comps'
import LoadOrganizationRolesContext from '../../../../../../../../data-loading/load-organization-assigned-roles/context/context'

const areUpdatesPending = (role, fields) => {
  if (!role) return false

  const keys = Object.keys(fields)

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i]
    const value = fields[key]

    if (role[key] !== value) {
      return true
    }
  }

  return false
}

const styles = {
  button: {
    display: 'block',
    width: '15rem',
    marginTop: '2rem',
    boxSizing: 'border-box',
    '@media (max-width: 500px)': {
      width: '100%'
    }
  },
  circularProgress: {
    display: 'block',
    width: '17px !important',
    height: '17px !important',
    margin: '0 auto 0 auto',
    '& svg': {
      color: 'white'
    }
  }
}

const Details = ({ selectedRole }) => {
  const { orgId } = useParams()
  const { roles, setRoles } = useContext(LoadOrganizationRolesContext)
  const [isLoading, setIsLoading] = useState(true)
  const [fields, setFields] = useState({
    name: '',
    desc: '',
    ranking: 1
  })
  const [error, setError] = useState('')
  const [processing, setProcessing] = useState(false)
  const dispatch = useDispatch()
  const role = find(roles, { _id: selectedRole })
  const updatesPending = areUpdatesPending(role, fields)

  const handleSubmit = (e) => {
    e.preventDefault()
    setProcessing(true)

    dispatch(
      updateOrganizationRoleByID({
        roleId: selectedRole,
        organizationId: orgId,
        fieldsToUpdate: {
          name: fields.name,
          desc: fields.desc,
          ranking: parseInt(fields.ranking)
        }
      })
    )
      .then((data) => {
        if (data.error) {
          console.log('[FAIL]: ', 'Error updating role')

          setError(data.message)
        } else {
          console.log('[SUCCESS]: ', 'Successfully updated role')

          const newRoles = [...roles]
          const roleIndex = newRoles.findIndex((role) => role._id === selectedRole)
          newRoles[roleIndex] = data.role

          setRoles(newRoles)
        }

        setProcessing(false)
      })
      .catch((e) => {
        console.log('[ERROR]: ', e)

        setProcessing(false)
        setError(e.message)
      })
  }

  useEffect(() => {
    if (isLoading && role) {
      const { name, desc, ranking } = role

      setFields({
        ...fields,
        name,
        desc,
        ranking
      })
      setIsLoading(false)
    }
  }, [fields, isLoading, role, selectedRole])

  return (
    <MainContainer>
      <FormError message={error} style={{ margin: '1rem 0 0 0' }} />

      <form onSubmit={handleSubmit}>
        <InputContainer style={{ marginTop: '1rem' }}>
          <InputLabel>Name</InputLabel>
          <InputDesc>Enter the name of the new role.</InputDesc>
          <Input
            type="text"
            name="name"
            placeholder="Enter a name..."
            value={fields.name}
            onChange={(e) => setFields({ ...fields, [e.target.name]: e.target.value })}
          />
        </InputContainer>

        <InputContainer>
          <InputLabel>Description</InputLabel>
          <InputDesc>Describe the role you are creating.</InputDesc>
          <Input
            type="text"
            name="desc"
            placeholder="Enter a description..."
            value={fields.desc}
            onChange={(e) => setFields({ ...fields, [e.target.name]: e.target.value })}
            style={{ maxWidth: 'none' }}
          />
        </InputContainer>

        <InputContainer>
          <InputLabel>Ranking</InputLabel>
          <InputDesc>
            You can optionally enter a numerical ranking for this role (Defaults to 1). Roles using
            different rankings will internally define a hierarchy. Displayed roles will be sorted in
            ascending order (low to high).
          </InputDesc>
          <Input
            type="number"
            name="ranking"
            placeholder="Optionally enter a numerical ranking ranging from 1 to 999..."
            min="1"
            value={fields.ranking}
            onChange={(e) => setFields({ ...fields, [e.target.name]: e.target.value })}
          />
        </InputContainer>

        <Button
          type="submit"
          variant="contained"
          color="primary"
          sx={styles.button}
          disabled={processing || !updatesPending}>
          {processing ? (
            <CircularProgress color="secondary" sx={styles.circularProgress} />
          ) : (
            'Update Role'
          )}
        </Button>
      </form>
    </MainContainer>
  )
}

export default Details
