import styled from 'styled-components'
import config from '../../../../../config.js'

export const MainContainer = styled.div`
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
`

export const WebView = styled.div`
  display: block;
  text-align: right;
  margin-right: 0;

  @media (max-width: ${config.UI.drawer_breakpoint.max}) {
    display: none;
  }
`

export const MobileView = styled.div`
  display: block;
  text-align: right;
  position: absolute;
  top: 0;
  right: 0;
  transform: translateY(-50%);

  @media (min-width: ${config.UI.drawer_breakpoint.min}) {
    display: none;
  }
`
