import { useState } from 'react'
import { useSelector } from 'react-redux'
import FoundingMemberBadge from '../../../../../../../../media/accolades/founding-member.png'
import EarlyMemberBadge from '../../../../../../../../media/accolades/early-member.png'
import ButtonBase from '@mui/material/ButtonBase'
import BadgeTooltip from './badge-tooltip'
import DownloadModal from './download-modal'
import { BadgeContainer, Name } from '../styled/badges-comps'

const getBadgeMeta = (badge) => {
  const meta = {
    img: '',
    desc: badge.desc
  }

  switch (badge.type) {
    case 'founding-member':
      meta.img = FoundingMemberBadge
      break

    case 'early-member':
      meta.img = EarlyMemberBadge
      break

    case 'uconn-civiltalk-certified':
      meta.img = `https://res.cloudinary.com/headliner/image/upload/v1682643285/badges/uconn-civiltalk-certified.png`
      break

    case 'uconn-husky':
      meta.img = `https://res.cloudinary.com/headliner/image/upload/v1673995817/badges/husky-dog-logo-circleR.jpg`
      break

    case 'instructor-badge':
      meta.img = `https://res.cloudinary.com/headliner/image/upload/v1676325117/badges/instructor_badge.png`

    default:
      break
  }

  return meta
}

// ************ //
// *** MAIN *** //
// ************ //

function Badge(props) {
  const { badge, userId } = props
  const [openModal, setOpenModal] = useState(false)
  const currentUser = useSelector((state) => state.currentUser)
  const { img } = getBadgeMeta(badge)

  const isProfileOwner = userId === currentUser.id

  const handleModal = () => {
    setOpenModal(!openModal)
  }

  return (
    <>
      <BadgeContainer>
        <ButtonBase
          onClick={handleModal}
          disabled={badge.type !== 'uconn-civiltalk-certified' || !isProfileOwner}
          style={
            badge.type === 'uconn-civiltalk-certified'
              ? { marginBottom: '0.5rem' }
              : { marginBottom: '1rem' }
          }>
          <img
            src={img}
            alt={badge.desc}
            style={
              badge.type === 'uconn-civiltalk-certified'
                ? { width: '9.5rem', height: '9.5rem', borderRadius: '50%' }
                : { width: '7rem', height: '7rem', borderRadius: '50%', border: '3px solid gray' }
            }
          />
        </ButtonBase>
        <Name>{badge.desc}</Name>
      </BadgeContainer>
      {badge.type === 'uconn-civiltalk-certified' && (
        <DownloadModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          img={img}
          type={badge.type}
          desc={badge.desc}
        />
      )}
    </>
  )
}

export default Badge
