import {
  ArticleContainer,
  ArticleHeader,
  RedArticleHeader,
  WhiteBackgroundContainer,
  SponsoredByTitle,
  SponsoredByItem,
  SponsoredByContainer,
  Image
} from '../styled/org-details-comps'
import MasterCardIcon from '../../../../../../media/icons/mastercard-light.svg'
import LazyLoad from 'react-lazyload'

const CIVILTALK_LOGO = `https://res.cloudinary.com/headliner/image/upload/v1712948051/logos/civiltalk_logo_transparent.png`
const CIVILTALK_LINK = 'https://www.civiltalk.com'

const SponsoredBy = () => {
  return (
    <ArticleContainer>
      <RedArticleHeader>Sponsored By</RedArticleHeader>
      <WhiteBackgroundContainer>
        <SponsoredByContainer>
          <SponsoredByItem>
            <LazyLoad>
              {/* <Image src={MasterCardIcon} alt={'Mastercard logo'} width={'7rem'} height={'7rem'} /> */}
              <Image src={CIVILTALK_LOGO} alt={'Mastercard logo'} width={'20rem'} height={'7rem'} />
            </LazyLoad>
            {/* <SponsoredByTitle>Mastercard</SponsoredByTitle> */}
          </SponsoredByItem>
        </SponsoredByContainer>
      </WhiteBackgroundContainer>
    </ArticleContainer>
  )
}

export default SponsoredBy
