// redux thunk to create a course
import * as Sentry from '@sentry/react'
import config from '../../../config'
import { actionResponseActions } from '../../action-creators/action-response.js'
import { loaderActions } from '../../action-creators/loader.js'
import { authenticatedFetch } from '../../../utils/functions/authenticatedFetch'

/*
 * METHOD TO CREATE COURSE
 */
export const createCourse = (params) => {
  let responseCode = ''
  const method = 'POST'
  const url = `${config.baseURL}/api/course/`
  const headers = {
    'Content-Type': 'application/json'
  }

  const requestBody = {
    course: params
  }

  console.log('[DEBUG]: createCourse params', params)
  console.log('[DEBUG]: createCourse requestBody', requestBody)

  const requestBodyJSON = JSON.stringify(requestBody)

  return (dispatch) => {
    console.log('[FETCH]: Sending create course request to CivilTalk')

    // dispatch(loaderActions.startLoader())

    return authenticatedFetch(url, {
      method,
      body: requestBodyJSON,
      credentials: 'include',
      headers
    })
      .then((response) => {
        // dispatch(loaderActions.stopLoader())
        responseCode = response.status

        return response.json()
      })
      .then((data) => {
        if (responseCode === 201) {
          console.log(`[SUCCESS]:`, data.message)
          return {
            error: false,
            code: responseCode,
            message: data.message,
            course: data.course
          }
        } else {
          console.error(`[ERROR]: while creating course`, data)

          // Log error to Sentry
          Sentry.captureException(data)

          return {
            error: true,
            code: responseCode,
            message: data.message || 'Error creating course',
            errors: data || {}
          }
        }
      })
      .catch((error) => {
        console.error(`[ERROR]: caught creating course`, error)

        // Log error to Sentry
        Sentry.captureException(error)

        dispatch(loaderActions.stopLoader())

        return {
          error: true,
          code: '',
          message: error
        }
      })
  }
}
