import styled from 'styled-components'
import config from '../../../../config'

export const EmotionCardsContainer = styled.div`
  display: flex;
  font-size: 0;
  align-items: center;
`

export const EmotionMessage = styled.p`
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.small};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  color: ${(props) => props.theme.palette.text.primary};
  margin-right: 0.5rem;
  vertical-align: middle;
  box-sizing: border-box;

  @media (max-width: ${config.UI.drawer_breakpoint.max}) {
    display: none;
  }
`

export const EmotionCard = styled.div`
  display: block;
  /* margin: -0.1rem 4rem 0 0; */
  padding: 0.4rem;
  padding-right: 0.4rem;
  vertical-align: middle;
  box-sizing: border-box;
  border-radius: 0.3rem;
  background-color: ${(props) => props.bgColor || props.theme.palette.common.white};
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.small};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  color: ${(props) => props.theme.palette.text.primary};
  ${'' /* white-space: nowrap; */}
  word-break: break-word;

  & > div {
    display: block;
    margin-top: 0.15rem;
    margin-right: 0.3rem;
    padding: 0.1rem;
    float: left;
    border-radius: 0.3rem;
    font-size: ${(props) => props.theme.typography.fontSizes.small};
    font-weight: ${(props) => props.theme.typography.fontWeight.medium};
    color: ${(props) => props.color || props.theme.palette.secondary.main};
    cursor: pointer;

    &:hover {
      background-color: ${(props) => props.color || props.theme.palette.secondary.main};
      color: ${(props) => props.bgColor || props.theme.palette.common.white};
    }
  }
`
