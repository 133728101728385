import config from '../../../config'
import { authenticatedFetch } from '../../../utils/functions/authenticatedFetch'
import { verificationAttemptsActions } from '../../action-creators/verification-attempts.js'

/*
 * METHOD TO BEGIN VERIFICATION ATTEMPT
 */
export const beginVerificationAttempt = () => {
  let responseCode = ''
  const method = 'POST'
  const url = `${config.baseURL}/api/verification-attempt`
  const headers = {
    'Content-Type': 'application/json'
  }

  return (dispatch) => {
    console.log('Sending begin verification attempt request to CivilTalk')

    return authenticatedFetch(url, {
      method,
      headers,
      credentials: 'include'
    })
      .then((response) => {
        responseCode = response.status

        return response.json()
      })
      .then((data) => {
        if (responseCode === 201 || responseCode === 200) {
          dispatch(verificationAttemptsActions.add([data.verificationAttempt]))

          return {
            error: false,
            code: responseCode,
            message: data.message,
            verificationAttempt: data.verificationAttempt,
            stripeClientSecret: data.stripeClientSecret
          }
        }

        console.log(data.message)

        return {
          error: true,
          code: responseCode,
          message: data.message
        }
      })
      .catch((error) => {
        console.log(error)

        return {
          error: true,
          code: '',
          message: error
        }
      })
  }
}
