import { history } from '../../../../../history/history'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import {
  OuterContainer,
  Header,
  TabContainer,
  Tab,
  ResultContainer,
  AvatarContainer,
  ProfileInfoContainer,
  CourseName,
  CourseSeason,
  ProfileLocation,
  NoResults
} from '../../../my-courses/styled/my-courses-comps'
import UserAvatar from '../../../../partials/user-avatar/user-avatar'

const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  width: '100%',
  boxShadow: '0px 7px 5px -2px rgba(100, 100, 100, 0.1)',
  backgroundColor: theme.palette.common.white,
  borderRadius: '0.25rem',
  padding: '0.8rem 1rem',
  boxSizing: 'border-box',
  position: 'relative',
  marginBottom: '1rem',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    marginBottom: '0.75rem'
  }
}))

const StyledButton = styled(Button)(({ theme }) => ({
  minWidth: '9rem',
  width: '100%'
}))

function CourseCard({ course, legalName, image }) {
  return (
    <StyledCard key={course._id}>
      <Box
        sx={{
          display: 'flex',
          '@media (max-width:600px)': {
            paddingBottom: '1rem'
          }
        }}>
        <AvatarContainer>
          <UserAvatar
            src={image}
            width={'3.05rem'}
            height={'3.05rem'}
            borderWidth={'0.2rem'}
            verified={1}
          />
        </AvatarContainer>
        <ProfileInfoContainer>
          <Typography
            id={course._id}
            onClick={(e) => redirect(e.target.id)}
            sx={{
              textTransform: 'capitalize',
              fontSize: '1.05rem',
              fontWeight: 600,
              color: 'text.primary'
            }}>
            {course.title}
          </Typography>
          <Typography
            sx={{
              textTransform: 'capitalize',
              fontSize: '1rem',
              color: 'text.primary'
            }}>
            {legalName}
          </Typography>
          <Typography
            sx={{
              textTransform: 'capitalize',
              fontSize: '1rem',
              color: 'text.primary'
            }}>{`${course.season} ${course.year}`}</Typography>
        </ProfileInfoContainer>
      </Box>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          flex: 1
        }}></div>
    </StyledCard>
  )
}

export default CourseCard
