import React, { useState } from 'react'
import Selection from './components/selection/selection'
import Confirmation from './components/confirmation/confirmation'
import Complete from './components/complete/complete'

function Donate(props) {
  const { organizationId, takeId } = props
  const [step, setStep] = useState('selection')
  const [tokens, setTokens] = useState('')

  return (
    <>
      {step === 'selection' && (
        <Selection
          setStep={setStep}
          tokens={tokens}
          setTokens={setTokens}
          organizationId={organizationId}
        />
      )}

      {step === 'confirmation' && (
        <Confirmation
          setStep={setStep}
          tokens={tokens}
          organizationId={organizationId}
          takeId={takeId}
        />
      )}

      {step === 'complete' && <Complete />}
    </>
  )
}

export default Donate
