import React from 'react'
import { useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { history } from '../../../../../history/history'
import './css/drawer.css'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import { Drawer, List, ListItem, ListItemIcon, ListItemText } from '@mui/material'
import { Header } from './styled/side-nav-mobile-comps'

const useStyles = makeStyles(() =>
  createStyles({
    drawer: {
      marginTop: '5.5rem',
      boxSizing: 'border-box',
      zIndex: '1 !important'
    },
    paper: {
      top: '5.5rem',
      left: '0',
      zIndex: '1'
    },
    list: {
      paddingBottom: '8rem'
    },
    listItem: {
      padding: '1rem',
      borderBottom: '1px solid #eee',
      position: 'relative',
      '& span': {
        fontSize: '1.2rem'
      },
      '& span.material-icons': {
        fontSize: '1.7rem'
      }
    },
    listItemText: {
      marginBottom: '.4rem'
    },
    icon: {
      minWidth: '1.2rem',
      marginRight: '1.5rem'
    },
    caret: {
      minWidth: '1.2rem',
      position: 'absolute',
      top: '50%',
      right: '.8rem',
      transform: 'translateY(-50%)'
    },
    fullList: {
      width: 'auto'
    }
  })
)

function SideNavMobile(props) {
  const { open, setOpen } = props
  const classes = useStyles()
  const currentProfile = useSelector(({ currentProfile }) => currentProfile)

  const handleSelectTab = (tab) => {
    history.push(`/my-account/${tab}`)
  }

  return (
    <Drawer
      id={'settings-drawer'}
      anchor={'right'}
      open={open}
      onClose={() => setOpen(false)}
      classes={{ root: classes.drawer, paper: classes.paper }}
      ModalProps={{
        disableScrollLock: true
      }}>
      <div>
        <Header>My Account</Header>

        <List className={classes.list}>
          <ListItem
            button
            key={'account'}
            className={classes.listItem}
            onClick={() => handleSelectTab('account')}>
            <ListItemIcon className={classes.icon}>
              <span className="material-icons">manage_accounts</span>
            </ListItemIcon>

            <ListItemText primary={'Account Details'} className={classes.listItemText} />

            <ListItemIcon className={classes.caret}>
              <span className="material-icons">chevron_right</span>
            </ListItemIcon>
          </ListItem>

          <ListItem
            button
            key={'picture'}
            className={classes.listItem}
            onClick={() => handleSelectTab('picture')}>
            <ListItemIcon className={classes.icon}>
              <span className="material-icons">face</span>
            </ListItemIcon>

            <ListItemText primary={'Profile Picture'} className={classes.listItemText} />

            <ListItemIcon className={classes.caret}>
              <span className="material-icons">chevron_right</span>
            </ListItemIcon>
          </ListItem>

          <ListItem
            button
            key={'personal'}
            className={classes.listItem}
            onClick={() => handleSelectTab('personal')}>
            <ListItemIcon className={classes.icon}>
              <span className="material-icons">account_box</span>
            </ListItemIcon>

            <ListItemText primary={'Personal Details'} className={classes.listItemText} />

            <ListItemIcon className={classes.caret}>
              <span className="material-icons">chevron_right</span>
            </ListItemIcon>
          </ListItem>

          <ListItem
            button
            key={'location'}
            className={classes.listItem}
            onClick={() => handleSelectTab('location')}>
            <ListItemIcon className={classes.icon}>
              <span className="material-icons">travel_explore</span>
            </ListItemIcon>

            <ListItemText primary={'Location Settings'} className={classes.listItemText} />

            <ListItemIcon className={classes.caret}>
              <span className="material-icons">chevron_right</span>
            </ListItemIcon>
          </ListItem>

          {/* <ListItem
            button
            key={'notification'}
            className={classes.listItem}
            onClick={() => handleSelectTab('notification')}>
            <ListItemIcon className={classes.icon}>
              <span className="material-icons">notifications</span>
            </ListItemIcon>

            <ListItemText primary={'Notification Settings'} className={classes.listItemText} />

            <ListItemIcon className={classes.caret}>
              <span className="material-icons">chevron_right</span>
            </ListItemIcon>
          </ListItem> */}

          <ListItem
            button
            key={'privacy'}
            className={classes.listItem}
            onClick={() => handleSelectTab('privacy')}>
            <ListItemIcon className={classes.icon}>
              <span className="material-icons">policy</span>
            </ListItemIcon>

            <ListItemText primary={'Privacy Settings'} className={classes.listItemText} />

            <ListItemIcon className={classes.caret}>
              <span className="material-icons">chevron_right</span>
            </ListItemIcon>
          </ListItem>

          <ListItem
            button
            key={'email'}
            className={classes.listItem}
            onClick={() => handleSelectTab('email')}>
            <ListItemIcon className={classes.icon}>
              <span className="material-icons">markunread</span>
            </ListItemIcon>

            <ListItemText primary={'Email Settings'} className={classes.listItemText} />

            <ListItemIcon className={classes.caret}>
              <span className="material-icons">chevron_right</span>
            </ListItemIcon>
          </ListItem>

          {/* <ListItem
            button
            key={'identification'}
            className={classes.listItem}
            onClick={() => handleSelectTab('identification')}>
            <ListItemIcon className={classes.icon}>
              <span className="material-icons">fact_check</span>
            </ListItemIcon>

            <ListItemText primary={'Identity Verification'} className={classes.listItemText} />

            <ListItemIcon className={classes.caret}>
              <span className="material-icons">chevron_right</span>
            </ListItemIcon>
          </ListItem> */}

          {/* <ListItem
            button
            key={'billing'}
            className={classes.listItem}
            onClick={() => handleSelectTab('billing')}
            disabled={!currentProfile.documentVerified}>
            <ListItemIcon className={classes.icon}>
              <span className="material-icons">credit_card</span>
            </ListItemIcon>

            <ListItemText primary={'Billing Information'} className={classes.listItemText} />

            <ListItemIcon className={classes.caret}>
              <span className="material-icons">chevron_right</span>
            </ListItemIcon>
          </ListItem> */}
        </List>
      </div>
    </Drawer>
  )
}

export default withRouter(SideNavMobile)
