import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getRolesByOrganizationID } from '../../../redux/thunks/roles/getRolesByOrganizationID'
import { getAssignedRolesByRoleIDs } from '../../../redux/thunks/assigned-roles/getAssignedRolesByRoleIDs'
import Context from './context/context'

/**
 *
 * @param {ReactNode} children - (Child JSX Elements)
 * @param {string} organizationId - Organization ID from which to load roles and assigned roles
 * @param {boolean} enableAsync - If set to true, children will be loaded immediately
 * @param {boolean} disableRedux -  If set to true, data will not be stored in redux
 * @param {boolean} hideOnNoResults - If set to true, children will be hidden if no data is returned
 *
 */
const LoadOrganizationAssignedRoles = (props) => {
  const { children, organizationId, enableAsync, disableRedux, hideOnNoResults } = props
  const [loadingInitialized, setLoadingInitialized] = useState(false)
  const [displayChildren, setDisplayChildren] = useState(false)
  const [roles, setRoles] = useState([])
  const [assignedRoles, setAssignedRoles] = useState([])
  const dispatch = useDispatch()

  // Check if user has an organization permission inculded in array passed in as props
  useEffect(() => {
    if (!loadingInitialized) {
      let returnedRoles = []

      setLoadingInitialized(true)

      console.log('[DEBUG]: Loading organization roles and assigned roles...')

      dispatch(getRolesByOrganizationID({ organizationId }, { disableRedux }))
        .then((data) => {
          if (data.error) {
            throw new Error(
              'Error retrieving roles by organization ID <LoadOrganizationAssignedRoles>'
            )
          }

          returnedRoles = data.roles

          return dispatch(
            getAssignedRolesByRoleIDs(
              { roleIds: returnedRoles.map((role) => role._id) },
              { disableRedux }
            )
          )
        })
        .then((data) => {
          if (data.error) {
            throw new Error(
              'Error retrieving assigned roles by role IDs <LoadOrganizationAssignedRoles>'
            )
          }

          setRoles(returnedRoles)
          setAssignedRoles(data.assignedRoles)
          setDisplayChildren(true)

          console.log('[SUCCESS]: Successfully retrieved organization roles and assigned roles.')
        })
        .catch((e) => {
          console.log('[ERROR]: ', e)
        })
    }
  }, [disableRedux, dispatch, loadingInitialized, organizationId])

  // If asynchronous loading is disabled and data isn't finished loading, return null
  if (!enableAsync && !displayChildren) return null
  // If asynchronous loading is disabled, hideOnNoResults is 'true', and no data is returned, return null
  if (!enableAsync && hideOnNoResults && (roles.length === 0 || assignedRoles.length === 0))
    return null

  return (
    <Context.Provider value={{ roles, setRoles, assignedRoles, setAssignedRoles }}>
      {children}
    </Context.Provider>
  )
}

export default LoadOrganizationAssignedRoles
