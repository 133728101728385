export const getResponsiveStyles = (width) => {
    const styles = {
      leftContainer: {
        float: 'left',
        margin: '0 2rem 0 0'
      },
      contentContainer: {
        margin: '0'
      },
      header: {
        textAlign: 'left'
      },
      subheader: {
        textAlign: 'left'
      },
      buttonContainer: {
        textAlign: 'left'
      }
    }
  
    if (width) {
      if (width <= 550) {
        styles.leftContainer.float = 'none'
        styles.leftContainer.margin = '0 auto'
        styles.contentContainer.margin = '1.5rem 0 0 0'
        styles.header.textAlign = 'center'
        styles.subheader.textAlign = 'center'
        styles.buttonContainer.textAlign = 'center'
      }
    }
  
    return styles
  }
  