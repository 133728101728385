//////////////////
// ACTION TYPES //
//////////////////

export const searchBarResultActionTypes = {
  SET_PROFILE_SEARCH_BAR_RESULTS: 'SET_PROFILE_SEARCH_BAR_RESULTS',
  SET_TAG_SEARCH_BAR_RESULTS: 'SET_TAG_SEARCH_BAR_RESULTS',
  SET_ORGANIZATION_SEARCH_BAR_RESULTS: 'SET_ORGANIZATION_SEARCH_BAR_RESULTS',
  ADD_PROFILE_SEARCH_BAR_RESULTS: 'ADD_PROFILE_SEARCH_BAR_RESULTS',
  ADD_TAG_SEARCH_BAR_RESULTS: 'ADD_TAG_SEARCH_BAR_RESULTS',
  ADD_ORGANIZATION_SEARCH_BAR_RESULTS: 'ADD_ORGANIZATION_SEARCH_BAR_RESULTS',
  CLEAR_SEARCH_BAR_RESULTS: 'CLEAR_SEARCH_BAR_RESULTS'
}

/////////////
// ACTIONS //
/////////////

// SEARCH BAR RESULTS
const setProfiles = (profiles) => {
  return {
    type: searchBarResultActionTypes.SET_PROFILE_SEARCH_BAR_RESULTS,
    profiles
  }
}

const setTags = (tags) => {
  return {
    type: searchBarResultActionTypes.SET_TAG_SEARCH_BAR_RESULTS,
    tags
  }
}

const setOrgs = (organizations) => {
  return {
    type: searchBarResultActionTypes.SET_ORGANIZATION_SEARCH_BAR_RESULTS,
    organizations
  }
}

const addProfiles = (profiles) => {
  return {
    type: searchBarResultActionTypes.ADD_PROFILE_SEARCH_BAR_RESULTS,
    profiles
  }
}

const addTags = (tags) => {
  return {
    type: searchBarResultActionTypes.ADD_TAG_SEARCH_BAR_RESULTS,
    tags
  }
}

const addOrgs = (organizations) => {
  return {
    type: searchBarResultActionTypes.ADD_ORGANIZATION_SEARCH_BAR_RESULTS,
    organizations
  }
}

const clear = () => {
  return {
    type: searchBarResultActionTypes.CLEAR_SEARCH_BAR_RESULTS
  }
}

export const searchBarResultsActions = {
  setProfiles,
  setTags,
  setOrgs,
  addProfiles,
  addTags,
  addOrgs,
  clear
}
