import React, { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { tokensTransactionModalActions } from '../../../../../../../redux/action-creators/tokens-transaction-modal'
import { createTokensPaymentIntent } from '../../../../../../../redux/thunks/stripe/create-tokens-payment-intent'
import getCTPurchaseFeeSummary from '../../../../../../../utils/functions/getCTPurchaseFeeSummary'
import getStripePaymentIntentFeeSummary from '../../../../../../../utils/functions/getStripePaymentIntentFeeSummary'
import createTokensPaymentIntentValidation from './validations/create-tokens-payment-intent'
import FormError from '../../../../../../alerts/form-error'
import TokenIcon from '../../../../../../../media/icons/svg/token-green.svg'
import {
  ToggleButtonGroup,
  ToggleButton,
  TextField,
  InputAdornment,
  Button,
  CircularProgress,
  Tooltip
} from '@mui/material'
import {
  Heading,
  Subheading,
  PriceItemsContainer,
  PriceItemLabel,
  PriceItemValue,
  TotalPriceContainer,
  USD,
  TotalPrice,
  PriceLabel,
  ButtonContainer
} from './styled/pricing-comps'
import theme from '../../../../../../../res/theme'

const styles = {
  toggleButtonGroup: {
    display: 'flex',
    justifyContent: 'center',
    margin: '2rem 0 0 0',
    flexFlow: 'row wrap',
    boxSizing: 'border-box',
    '& > button': {
      display: 'inline-block',
      minWidth: '4rem',
      boxSizing: 'border-box',
      borderWidth: '2px',
      '@media (max-width: 450px)': {
        display: 'block',
        width: '100%',
        margin: '.1rem 0',
        borderWidth: '1px !important',
        borderRadius: '.3rem !important',
        borderColor: `${theme.palette.secondary.main} !important`
      }
    }
  },
  textField: {
    display: 'block',
    width: '20.7rem',
    margin: '1rem auto 0 auto',
    '& > div': {
      width: '20.7rem'
    },
    '& img': {
      height: '1.5rem'
    },
    '@media (max-width: 450px)': {
      width: '100%',
      '& > div': {
        width: '100%'
      }
    }
  },
  cancel: {
    width: '10rem',
    marginRight: '1rem',
    '@media (max-width: 450px)': {
      width: '100%',
      marginRight: '0'
    }
  },
  submit: {
    width: '10rem',
    '@media (max-width: 450px)': {
      width: '100%',
      marginTop: '.5rem'
    }
  },
  circularProgress: {
    display: 'block',
    width: '17px !important',
    height: '17px !important',
    margin: '.1rem auto 0 auto'
  }
}

function Pricing(props) {
  const {
    zeroBalance,
    setStep,
    tokens,
    setTokens,
    civiltalkFee,
    setCiviltalkFee,
    stripeFee,
    setStripeFee,
    charge,
    setCharge,
    setClientSecret
  } = props
  const [tokenSelection, setTokenSelection] = useState('25')
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState('')
  const dispatch = useDispatch()

  const handleClose = () => {
    dispatch(tokensTransactionModalActions.clear())
  }

  const handleSubmit = () => {
    setProcessing(true)

    const { error } = createTokensPaymentIntentValidation(tokens)

    if (error) {
      console.log('[VALIDATION ERROR]: ', error)

      setError(error)
      setProcessing(false)
    } else {
      dispatch(createTokensPaymentIntent({ tokens }))
        .then((data) => {
          if (!data.error) {
            console.log('[SUCCESS]: ', 'Successfully created tokens payment intent!')

            setClientSecret(data.clientSecret)
            setStep('checkout')
          } else {
            console.log('[FAIL]: ', 'Failed to create tokens payment intent.')

            setError(data.message)
            setProcessing(false)
          }
        })
        .catch((e) => {
          setProcessing(false)
        })
    }
  }

  const handleInputChange = (e) => {
    const { value } = e.target
    const parsedValue = parseInt(value)
    const onlyDigitsRegExp = new RegExp(/^[0-9]+$/)

    // If Input is empty, set input to empty string
    if (value === '') {
      return setTokens(value)
    }

    // If Input contains anything but digits, dont update state
    if (!onlyDigitsRegExp.test(value)) {
      return
    }

    // If Input passes as a number, check to make sure it is between proper min and max value.
    // If so set new value in state
    // Else, don't update state
    if (!isNaN(parsedValue)) {
      if (parsedValue >= 1 && parsedValue <= 1000) {
        return setTokens(value)
      } else {
        return
      }
    }

    // Catch All - Set Tokens input to empty string
    return setTokens('')
  }

  useEffect(() => {
    switch (tokenSelection) {
      case '5':
        setTokens(5)
        break
      case '10':
        setTokens(10)
        break
      case '25':
        setTokens(25)
        break
      case '50':
        setTokens(50)
        break
      default:
        setTokens('')
        break
    }
  }, [setTokens, tokenSelection])

  useEffect(() => {
    if (tokens) {
      const civiltalkFeeSummary = getCTPurchaseFeeSummary(tokens)
      const paymentIntentFeeSummary = getStripePaymentIntentFeeSummary(
        parseFloat(civiltalkFeeSummary.charge)
      )

      setCiviltalkFee(civiltalkFeeSummary.fee)
      setStripeFee(paymentIntentFeeSummary.fee)
      setCharge(paymentIntentFeeSummary.charge)
    } else {
      setStripeFee(0)
      setCharge(0)
    }
  }, [stripeFee, setCiviltalkFee, setStripeFee, setCharge, tokens])

  return (
    <div>
      <Heading>
        {zeroBalance
          ? "You're out of tokens. Would you like to purchase more?"
          : 'How many tokens would you like to purchase?'}
      </Heading>

      <Subheading>
        <b>Note:</b> Tokens may only be donated to organizations or awarded to others users.
        Redeeming donated tokens is only available to administrators of organizations.
      </Subheading>

      <FormError message={error} style={{ marginTop: '1rem' }} />

      <ToggleButtonGroup
        color="secondary"
        value={tokenSelection}
        exclusive
        onChange={(e, value) => setTokenSelection(value)}
        aria-label="text tokenSelection"
        sx={styles.toggleButtonGroup}>
        <ToggleButton value="5" aria-label="5">
          5
        </ToggleButton>
        <ToggleButton value="10" aria-label="10">
          10
        </ToggleButton>
        <ToggleButton value="25" aria-label="25">
          25
        </ToggleButton>
        <ToggleButton value="50" aria-label="50">
          50
        </ToggleButton>
        <ToggleButton value="custom" aria-label="custom">
          Custom
        </ToggleButton>
      </ToggleButtonGroup>

      {tokenSelection === 'custom' && (
        <TextField
          color="secondary"
          value={tokens}
          focused
          label="Tokens"
          id="outlined-start-adornment"
          sx={styles.textField}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" sx={{ color: theme.palette.secondary.main }}>
                {/* <span className="material-icons">paid</span> */}
                <img src={TokenIcon} alt="Purchase Tokens Icon" />
              </InputAdornment>
            )
          }}
          onChange={handleInputChange}
        />
      )}

      <PriceItemsContainer>
        <div>
          <PriceItemLabel>Tokens ($1 / token)</PriceItemLabel>
          <PriceItemValue>${tokens || '0'}</PriceItemValue>
        </div>

        <div>
          <PriceItemLabel>
            <span>Fees</span>
            <Tooltip
              title={
                <div>
                  <p>
                    CivilTalk charges a fixed fee of 10 cents and a 1% percent fee for all token
                    purchases.
                    <br />
                    <br />
                    CivilTalk Fee = <b>${civiltalkFee}</b>
                    <br />
                    <br />
                    Our payment provider (Stripe) charges a fixed fee of 30 cents and a 2.9% fee for
                    all transactions.
                    <br />
                    <br />
                    Stripe Fee = <b>${stripeFee}</b>
                  </p>
                </div>
              }>
              <span className="material-icons">help</span>
            </Tooltip>
          </PriceItemLabel>
          <PriceItemValue>
            $
            {stripeFee && civiltalkFee
              ? (parseFloat(stripeFee) + parseFloat(civiltalkFee)).toFixed(2)
              : '0'}
          </PriceItemValue>
        </div>
      </PriceItemsContainer>

      <TotalPriceContainer>
        <USD>$</USD>
        <TotalPrice>{charge || '0'}</TotalPrice>
      </TotalPriceContainer>

      <PriceLabel>Total</PriceLabel>

      <ButtonContainer>
        <Button color="secondary" variant="contained" onClick={handleClose} sx={styles.cancel}>
          Cancel
        </Button>

        <Button
          color="primary"
          variant="contained"
          onClick={handleSubmit}
          sx={styles.submit}
          disabled={processing || !tokens || tokens === '0'}>
          {processing ? (
            <CircularProgress color="primary" sx={styles.circularProgress} />
          ) : (
            'Checkout'
          )}
        </Button>
      </ButtonContainer>
    </div>
  )
}

export default Pricing
