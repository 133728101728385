import { useDebugValue, useState, Dispatch, SetStateAction } from 'react';

interface DescriptionErrors {
  [key: string]: string;
}

function useDescriptionErrorsObjState(initialValue: DescriptionErrors): [DescriptionErrors, Dispatch<SetStateAction<DescriptionErrors>>] {
  const [descriptionErrorsObj, setDescriptionErrorsObj] = useState<DescriptionErrors>(initialValue);
  useDebugValue(descriptionErrorsObj ? descriptionErrorsObj : initialValue);
  return [descriptionErrorsObj, setDescriptionErrorsObj];
}

export default useDescriptionErrorsObjState;