const subjects = [
  'Any Subject',
  'AAAS - Asian and Asian American Studies',
  'ACCT - Accounting',
  'AELI - American English Language Institute',
  'AFRA - Africana Studies',
  'AGNR - Agriculture and Natural Resources',
  'AH - Allied Health Sciences',
  'AHNR - Agriculture, Health and Natural Resources',
  'AIRF - Air Force Studies',
  'ALDS - Applied Linguistics and Discourse Studies',
  'AMES - Advanced Manufacturing for Energy Systems',
  'AMST - American Studies',
  'ANES - Anesthesiology',
  'ANSC - Animal Science',
  'ANTH - Anthropology',
  'ARAB - Arabic',
  'ARE - Agricultural and Resource Economics',
  'ARIS - Arabic and Islamic Studies',
  'ART - Art',
  'ARTH - Art History',
  'ASLN - American Sign Language',
  'BADM - Business Administration',
  'BASC - Basics (Social Work)',
  'BIOL - Biological Sciences',
  'BIST - Biostatistics',
  'BLAW - Business Law',
  'BME - Biomedical Engineering',
  'BSCI - Dental Basic Medical Sciences',
  'BUSN - Business',
  'CAMS - Classics and Ancient Mediterranean Studies',
  'CDSC - Clinical Dental Science',
  'CE - Civil Engineering',
  'CEWP - COOP Education Work Placement',
  'CHEG - Chemical Engineering',
  'CHEM - Chemistry',
  'CHIN - Chinese',
  'CLCS - Comparative Literary and Cultural Studies',
  'CLIN - Clinical',
  'CLTR - Clinical and Translational Research',
  'COGS - Cognitive Science',
  'COMM - Communication',
  'CORG - Community Organization (Social Work)',
  'CSE - Computer Science and Engineering',
  'DENT - Dental Science',
  'DERE - Dental Residency and Fellowship Program',
  'DERM - Dermatology',
  'DGS - Diagnostic Genetic Sciences',
  'DIET - Dietetics',
  'DMD - Digital Media and Design',
  'DRAM - Dramatic Arts',
  'DSEL - Direct Service Elective (Social Work)',
  'ECE - Electrical and Computer Engineering',
  'ECON - Economics',
  'EDCI - Education Curriculum and Instruction',
  'EDLR - Educational Leadership',
  'EEB - Ecology and Evolutionary Biology',
  'EGEN - Education',
  'EMBA - Executive MBA',
  'EMED - Emergency Medicine',
  'ENGL - English',
  'ENGR - Engineering',
  'ENVE - Environmental Engineering',
  'ENVS - Environmental Sciences',
  'EPSY - Educational Psychology',
  'ERTH - Earth Sciences',
  'ES - European Studies',
  'EVST - Environmental Studies',
  'EXPL - Exploratory',
  'FDSC - Foundational Dental Science',
  'FED - Field Education (Social Work)',
  'FMED - Family Medicine',
  'FNCE - Finance',
  'FREN - French',
  'GEOG - Geography',
  'GERM - German',
  'GPS - General and Professional Studies',
  'GRAD - Graduate School',
  'HBEL - Human Behavior Elective',
  'HCHE - Hartford Consortium For Higher Education',
  'HCMI - Healthcare Management and Insurance Studies',
  'HDFS - Human Development and Family Sciences',
  'HEJS - Hebrew and Judaic Studies',
  'HIST - History',
  'HRTS - Human Rights',
  'IGFP - Individuals, Groups, and Families (Social Work)',
  'ILCS - Italian Literature and Cultural Studies',
  'IMED - Internal Medicine',
  'IMS - Institute of Materials Science',
  'INDS - India Studies',
  'INTD - Interdepartmental',
  'INTS - International Studies',
  'IRIS - Irish',
  'IS - Independent Study (Social Work)',
  'ISG - Institute for Systems Genomics',
  'JAPN - Japanese',
  'JOUR - Journalism',
  'KINS - Kinesiology',
  'KORE - Korean',
  'LAND - Landscape Architecture',
  'LAW - Law',
  'LCL - Literatures, Cultures, and Languages',
  'LING - Linguistics',
  'LLAS - Latino and Latin American Studies',
  'M - School of Medicine',
  'MARN - Marine Sciences',
  'MAST - Maritime Studies',
  'MATH - Mathematics',
  'MCB - Molecular and Cell Biology',
  'ME - Mechanical Engineering',
  'MEDS - Medical Science',
  'MEM - Management and Engineering for Manufacturing',
  'MENT - Management and Entrepreneurship',
  'MFGE - Manufacturing Engineering',
  'MGRK - Modern Greek',
  'MISI - Military Science',
  'MKTG - Marketing',
  'MLSC - Medical Laboratory Sciences',
  'MSE - Materials Science and Engineering',
  'MUSI - Music',
  'NEUR - Neurology',
  'NRE - Natural Resources and the Environment',
  'NURS - Nursing',
  'NUSC - Nutritional Sciences',
  'OBGY - OBGYN',
  'OFFC - Off Campus',
  'OMFS - OMFS',
  'OPIM - Operations and Information Management',
  'ORTH - Orthopaedic',
  'OSH - Occupational Safety and Health',
  'PATH - Pathobiology',
  'PEDS - Pediatrics',
  'PHAR - Pharmacy - PHAR',
  'PHIL - Philosophy',
  'PHRX - Pharmacy - PHRX',
  'PHYS - Physics',
  'PLSC - Plant Science',
  'PNB - Physiology and Neurobiology',
  'POLS - Political Science',
  'POLY - Polymer Science and Engineering',
  'POPR - Policy Practice (Social Work)',
  'PORT - Portuguese',
  'PP - Public Policy',
  'PSCH - Psychiatry',
  'PSYC - Psychological Sciences',
  'PT - Physical Therapy',
  'PTHO - Pathology',
  'PUBH - Public Health',
  'RADS - Radiology',
  'RSCH - Research (Social Work)',
  'SAAG - Agriculture (RH)',
  'SAAS - Animal Science (RH)',
  'SANR - Natural Resources and the Environment (RH)',
  'SAPB - Pathobiology (RH)',
  'SAPL - Plant Science (RH)',
  'SARE - Agricultural and Resource Economics (RH)',
  'SE - Systems Engineering',
  'SLHS - Speech, Language and Hearing Science',
  'SOCI - Sociology',
  'SOWK - Social Work',
  'SPAN - Spanish',
  'SPSS - Sustainable Plant and Soil Systems',
  'SPTP - Special Topics (Social Work)',
  'SSW - Social Work',
  'STAT - Statistics',
  'SURG - Surgery',
  'SWEL - Social Work Elective',
  'TRNS - Transformation',
  'TRST - Translation Studies',
  'UNIV - University',
  'URBN - Urban and Community Studies',
  "WGSS - Women's, Gender, and Sexuality Studies"
]

export default subjects
