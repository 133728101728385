import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import getAvatar from '../../../../../utils/functions/getAvatar'
import getName from '../../../../../utils/functions/getName'
import followUser from './functions/follow-user'
import unfollowUser from './functions/unfollow-user'

import {
  OuterContainer,
  InnerContainer,
  IconContainer,
  IconSpan,
  ImageContainer,
  NameContainer,
  Name
} from './styled/profile-comps'
import { CircularProgress } from '@mui/material'

function Profile({ profile, isFollowing }) {
  const { userId, documentVerified, avatar, role } = profile
  const history = useHistory()
  const dispatch = useDispatch()
  const currentUser = useSelector(({ currentUser }) => currentUser)
  const [processing, setProcessing] = useState(false)
  const displayFollowActions = true
  const isCurrentUser = profile.userId === currentUser.id

  const handleFollowUser = (e, profile) => {
    e.stopPropagation()

    followUser(profile, setProcessing, dispatch)
  }

  const handleUnfollowUser = (e, profile) => {
    e.stopPropagation()

    unfollowUser(profile, currentUser, setProcessing, dispatch)
  }

  return (
    <OuterContainer key={userId}>
      <InnerContainer>
        <ImageContainer
          verified={documentVerified}
          style={{ backgroundImage: `url(${getAvatar(avatar)})` }}
          onClick={() => history.push(`/profile/${userId}`)}>
          {!isFollowing && displayFollowActions && !isCurrentUser && (
            <IconContainer onClick={(e) => handleFollowUser(e, profile)}>
              {processing ? (
                <CircularProgress
                  size={'1rem'}
                  sx={{
                    display: 'block',
                    color: 'common.white',
                    position: 'absolute',
                    top: '25%',
                    left: '25%'
                  }}
                />
              ) : (
                <IconSpan className="material-icons">person_add</IconSpan>
              )}
            </IconContainer>
          )}

          {isFollowing && displayFollowActions && !isCurrentUser && (
            <IconContainer onClick={(e) => handleUnfollowUser(e, profile)}>
              {processing ? (
                <CircularProgress
                  size={'1rem'}
                  sx={{
                    display: 'block',
                    color: 'common.white',
                    position: 'absolute',
                    top: '25%',
                    left: '25%'
                  }}
                />
              ) : (
                <IconSpan className="material-icons">person_remove</IconSpan>
              )}
            </IconContainer>
          )}
        </ImageContainer>

        <NameContainer>
          <Name onClick={() => history.push(`/profile/${userId}`)}>{getName(profile)}</Name>
        </NameContainer>
      </InnerContainer>

      {role.toLowerCase() !== 'members' && <p>{role}</p>}
    </OuterContainer>
  )
}

export default Profile
