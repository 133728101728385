import styled, { keyframes } from 'styled-components'
import config from '../../../../../../config.js'

export const MainContainer = styled.header`
  /* browser fallback for non-stacking or image fails to load */
  background-color: ${(props) => props.theme.palette.secondary.main};
  /* browser fallback for non-stacking */
  background-image: url('https://res.cloudinary.com/headliner/image/upload/v1679885963/LandingPage/pang-yuhao-_kd5cxwZOK4-unsplash.jpg');
  background-image: linear-gradient(90deg, rgba(51, 51, 51, 0.8) 0%, rgba(51, 51, 51, 0.8) 100%),
    url('https://res.cloudinary.com/headliner/image/upload/v1679885963/LandingPage/pang-yuhao-_kd5cxwZOK4-unsplash.jpg');
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  position: relative;
  margin: 0 0.5rem 1rem 0.5rem;
  padding: 2rem 2.5rem;
  align-items: center;
  border-radius: 0.3rem;
  box-sizing: border-box;
  min-height: 19rem;

  @media (max-width: ${config.UI.drawer_breakpoint.max}) {
    display: block;
  }
`

export const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover; /** covers IE support */
  object-fit: cover;
  width: 12rem;
  height: 12rem;
  border-radius: 50%;
  border: 4px solid;
  border-color: ${(props) => (props.verified ? props.theme.palette.primary.main : '#ccc')};
  background-color: ${(props) => props.theme.palette.common.white};

  @media (max-width: 600px) {
    width: 10rem;
    height: 10rem;
  }
`

const LoadingAnimation = keyframes`
  0% {
    background-color: #fff;
  }
  50% {
    background-color: #ccc;
  }
  100% {
    background-color: #fff;
  }
`

export const Placeholder = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  animation: ${LoadingAnimation} 1s infinite;
  border-radius: 50%;
  border: 4px solid;
  background-color: ${(props) => props.theme.palette.common.white};
`

export const ImageSkeletonPlaceholder = styled.div`
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: inline-block;
  width: 12.5rem;
  height: 12.5rem;
  margin-right: 2.5rem;
  border-radius: 50%;
  vertical-align: middle;
  position: relative;

  @media (max-width: ${config.UI.drawer_breakpoint.max}) {
    display: block;
    margin: 0 auto;
  }

  @media (max-width: 600px) {
    width: 10rem;
    height: 10rem;
  }
`

export const VerifiedBadgeContainer = styled.label`
  background-color: ${(props) => props.theme.palette.primary.main};
  display: block;
  width: 2.3rem;
  height: 2.3rem;
  border-radius: 50%;
  position: absolute;
  bottom: 0.3rem;
  right: 0.3rem;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  z-index: 1;

  @media (max-width: 600px) {
    bottom: 0.1rem;
    right: 0.1rem;
  }

  & input {
    position: fixed;
    top: -100px;
  }

  & span.material-icons {
    background-color: ${(props) => props.theme.palette.text.primary};
    font-size: 1.1rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
`

export const DetailsContainer = styled.div`
  display: inline-block;
  margin-top: -0.5rem;
  vertical-align: middle;
  /* Firefox */
  width: -moz-calc(100% - 15rem);
  /* WebKit */
  width: -webkit-calc(100% - 15rem);
  /* Opera */
  width: -o-calc(100% - 15rem);
  /* Standard */
  width: calc(100% - 15rem);

  @media (max-width: ${config.UI.drawer_breakpoint.max}) {
    display: block;
    width: 100%;
    margin-top: 0.5rem;

    & .user-accolades {
      text-align: center;
      margin-top: 1rem !important;
    }
  }
`

export const Name = styled.h1`
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: 1.85rem;
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  color: ${(props) => props.theme.palette.common.white};
  margin-bottom: 0.25rem;

  @media (max-width: ${config.UI.drawer_breakpoint.max}) {
    font-size: 1.75rem;
    text-align: center;
  }
`

export const Location = styled.h2`
  line-height: ${(props) => props.theme.typography.lineHeight};
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.extraLarge};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  color: ${(props) => props.theme.palette.common.white};

  margin-bottom: 1.3rem;

  @media (max-width: ${config.UI.drawer_breakpoint.max}) {
    font-size: ${(props) => props.theme.typography.fontSizes.extraLarge};
    font-weight: ${(props) => props.theme.typography.fontWeight.light};
    text-align: center;
    margin-bottom: 0;
  }
`

export const Knowledge = styled.div`
  min-height: 2.3rem;
  line-height: ${(props) => props.theme.typography.lineHeight};
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.short};
  font-weight: ${(props) => props.theme.typography.fontWeight.light};
  color: ${(props) => props.theme.palette.common.white};
  font-size: 0;

  & > b {
    display: inline-block;
    min-width: 10rem;
    margin: 0.2rem 1rem 0 0;
    vertical-align: top;
    font-size: ${(props) => props.theme.typography.fontSizes.short};
  }

  & > p {
    display: inline-block;
    line-height: 1.7rem;
    font-size: ${(props) => props.theme.typography.fontSizes.short};
  }

  & > div {
    display: inline-block;
    /* Firefox */
    width: -moz-calc(100% - 11rem);
    /* WebKit */
    width: -webkit-calc(100% - 11rem);
    /* Opera */
    width: -o-calc(100% - 11rem);
    /* Standard */
    width: calc(100% - 11rem);
    vertical-align: top;
  }

  @media (max-width: ${config.UI.drawer_breakpoint.max}) {
    display: none;
  }
`

export const Interests = styled.div`
  min-height: 2.3rem;
  line-height: ${(props) => props.theme.typography.lineHeight};
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.short};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  color: ${(props) => props.theme.palette.common.white};
  font-size: 0;

  & > b {
    display: inline-block;
    min-width: 10rem;
    margin: 0.2rem 1rem 0 0;
    vertical-align: top;
    font-size: ${(props) => props.theme.typography.fontSizes.short};
  }

  & > p {
    display: inline-block;
    line-height: 1.7rem;
    font-size: ${(props) => props.theme.typography.fontSizes.short};
  }

  & > div {
    display: inline-block;
    /* Firefox */
    width: -moz-calc(100% - 11rem);
    /* WebKit */
    width: -webkit-calc(100% - 11rem);
    /* Opera */
    width: -o-calc(100% - 11rem);
    /* Standard */
    width: calc(100% - 11rem);
    vertical-align: top;
  }

  @media (max-width: ${config.UI.drawer_breakpoint.max}) {
    display: none;
  }
`

export const DropDownButtonContainer = styled.div`
  display: block;
`

export const NavItemContainer = styled.div`
  display: block;
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: 0;

  & span.material-icons {
    font-size: 1.2rem;
    height: auto;
    margin-right: 0.7rem;
    margin-top: 1px;
    vertical-align: middle;
  }
`

export const NavItem = styled.span`
  display: inline-block;
  cursor: pointer;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  text-transform: capitalize;
  vertical-align: middle;
  color: ${(props) => props.theme.palette.text.primary};

  &:hover {
    text-decoration: underline;
  }
`

/** Follow Button */

export const PersonAddSpan = styled.span`
  font-size: 1.5rem;

  @media (max-width: 900px) {
    font-size: 1.35rem;
  }
`

/** Report Button */
export const ReportSpan = styled.span`
  font-size: 1.5rem;

  @media (max-width: 900px) {
    font-size: 1.35rem;
  }
`
