import React, { useRef, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import some from 'lodash/some'
import SlickSlider from 'react-slick'
import getUserFollows from './functions/get-user-follows'
import getOrganizationFollows from './functions/get-organization-follows'
import User from './components/user/user'
import Organization from './components/organization/organization'
import Badge from './components/badge/badge'
import makeStyles from '@mui/styles/makeStyles'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material'
import { MainContainer } from './styled/slider-comps'
import './css/slider.css'

// const dotStyling = {
//   display: 'inline-block',
//   backgroundColor: '#eaeaea',
//   height: '0.5rem',
//   width: '0.5rem',
//   margin: '1rem 0.5rem 0 .5rem',
//   borderRadius: '50%',
//   transition: 'background-color 0.3s ease-in-out'
// }

const useStyles = makeStyles((theme) => ({
  prevLight: {
    color: '#555',
    fontSize: '1.5rem',
    cursor: 'pointer',
    position: 'absolute',
    top: '3.5rem',
    left: '-25px',
    transform: 'translateY(-50%)',
    transition: 'color 0.3s ease-in-out',
    '&:hover': {
      color: theme.palette.secondary.main
    }
  },
  nextLight: {
    color: '#555',
    fontSize: '1.5rem',
    cursor: 'pointer',
    position: 'absolute',
    top: '3.5rem',
    right: '-25px',
    transform: 'translateY(-50%)',
    transition: 'color 0.3s ease-in-out',
    '&:hover': {
      color: theme.palette.secondary.main
    }
  },
  prevDark: {
    color: '#fff',
    fontSize: '1.5rem',
    cursor: 'pointer',
    position: 'absolute',
    top: '3.5rem',
    left: '-25px',
    transform: 'translateY(-50%)',
    transition: 'color 0.3s ease-in-out',
    '&:hover': {
      color: '#ccc'
    }
  },
  nextDark: {
    color: '#fff',
    fontSize: '1.5rem',
    cursor: 'pointer',
    position: 'absolute',
    top: '3.5rem',
    right: '-25px',
    transform: 'translateY(-50%)',
    transition: 'color 0.3s ease-in-out',
    '&:hover': {
      color: '#ccc'
    }
  }
}))

const Slider = (props) => {
  const {
    dataType, // profile OR organization
    profiles,
    organizations,
    badges,
    theme, // dark OR light
    displayFollowActions, // hide or show follow button - default is hide
    enableFollowLoading // if true - the component will load the follows reducer with the corresponding follows
  } = props
  const classes = useStyles()
  const mainRef = useRef(null)
  const sliderRef = useRef(null)
  const [didRecognizeRef, setDidRecognizeRef] = useState(false)
  const [componentWidth, setComponentWidth] = useState(null)
  const [slideIndex, setSlideIndex] = useState(0)
  const [scrollable, setScrollable] = useState(false)
  const [slidesToScroll, setSlidesToScroll] = useState(null)
  const [slidesToShow, setSlidesToShow] = useState(null)
  const [showLeftArrow, setShowLeftArrow] = useState(false)
  const [showRightArrow, setShowRightArrow] = useState(false)
  const [numPages, setNumPages] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  const dispatch = useDispatch()
  const currentUser = useSelector(({ currentUser }) => currentUser)
  const follows = useSelector(({ follows }) => follows)
  const itemWidthPx = 7.5 * 16
  const showSlider =
    didRecognizeRef &&
    componentWidth !== null &&
    slidesToScroll !== null &&
    slidesToShow !== null &&
    numPages !== null

  const handleSetComponentWidth = () => {
    const width = mainRef.current ? mainRef.current.clientWidth : window.innerWidth

    setComponentWidth(width)
  }

  useEffect(() => {
    window.addEventListener('resize', handleSetComponentWidth)

    return () => {
      window.removeEventListener('resize', handleSetComponentWidth)
    }
  }, [])

  useEffect(() => {
    if (isLoading) {
      if (dataType === 'profile' && enableFollowLoading) {
        const userIds = profiles.map((profile) => profile.userId)

        getUserFollows(userIds, setIsLoading, dispatch)
      } else if (dataType === 'organization' && enableFollowLoading) {
        const organizationIds = organizations.map((organization) => organization?._id)

        getOrganizationFollows(organizationIds, setIsLoading, dispatch)
      } else {
        setIsLoading(false)
      }
    }
  }, [dataType, dispatch, enableFollowLoading, isLoading, organizations, profiles])

  useEffect(() => {
    if (!didRecognizeRef) {
      if (mainRef.current !== null && typeof mainRef.current !== 'undefined') {
        setComponentWidth(mainRef.current.clientWidth)
        setDidRecognizeRef(true)
      }
    }
  }, [mainRef, didRecognizeRef, isLoading])

  useEffect(() => {
    if (didRecognizeRef) {
      const numItems = (profiles && profiles.length) || (organizations && organizations.length)
      const slidesPerPage = Math.floor(componentWidth / itemWidthPx)
      const newScrollable = numItems > slidesPerPage

      setScrollable(newScrollable)
      setSlidesToShow(slidesPerPage)
      setSlidesToScroll(slidesPerPage)
    }
  }, [itemWidthPx, didRecognizeRef, componentWidth, profiles, organizations])

  useEffect(() => {
    setShowLeftArrow(scrollable && slideIndex > 0)
    setShowRightArrow(scrollable && slideIndex < numPages - 1)
  }, [scrollable, numPages, slideIndex])

  useEffect(() => {
    const numItems =
      (profiles && profiles.length) ||
      (organizations && organizations.length) ||
      (badges && badges.length)

    if (slidesToShow > 0) {
      setNumPages(Math.ceil(numItems / slidesToShow))
    } else {
      setNumPages(1)
    }
  }, [organizations, profiles, badges, slidesToShow])

  const prev = () => {
    sliderRef.current.slickPrev()
  }

  const next = () => {
    sliderRef.current.slickNext()
  }

  const sliderSettings = {
    // dots: scrollable,
    // appendDots: (dots) => (
    //   <div style={{ position: 'static' }}>
    //     <ul style={{ margin: '0' }}>{dots}</ul>
    //   </div>
    // ),
    // customPaging: (i) => (
    //   <div
    //     style={
    //       i === slideIndex || (slideIndex === -1 && i === 0)
    //         ? { ...dotStyling, backgroundColor: '#4063af' }
    //         : { ...dotStyling }
    //     }
    //   />
    // ),
    arrows: false,
    draggable: true,
    infinite: false,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: slidesToScroll,
    initialSlide: 0,
    beforeChange: (current, next) => {
      setSlideIndex(Math.ceil(next / slidesToShow))
    }
  }

  if (isLoading) return null

  return (
    <MainContainer ref={mainRef} className="slick-slider-reusable">
      {showSlider && (
        <>
          <SlickSlider ref={sliderRef} {...sliderSettings}>
            {dataType === 'profile' &&
              profiles.map((profile) => {
                if (!profile) return null

                const isFollowing = some(follows, {
                  follower: currentUser.id,
                  userId: profile.userId
                })

                return (
                  <User
                    profile={profile}
                    isFollowing={isFollowing}
                    key={profile.userId}
                    theme={theme}
                    displayFollowActions={displayFollowActions}
                  />
                )
              })}

            {dataType === 'organization' &&
              organizations.map((organization) => {
                if (!organization) return null

                const isFollowing = some(follows, {
                  follower: currentUser.id,
                  organizationId: organization._id
                })

                return (
                  <Organization
                    organization={organization}
                    isFollowing={isFollowing}
                    key={organization._id}
                    theme={theme}
                    displayFollowActions={displayFollowActions}
                  />
                )
              })}

            {dataType === 'badges' &&
              badges.map((badge) => {
                if (!badge) return null

                return <Badge badge={badge} key={badge.userId} theme={theme} />
              })}
          </SlickSlider>

          {showLeftArrow && (
            <KeyboardArrowLeft
              className={theme === 'light' ? classes.prevLight : classes.prevDark}
              onClick={prev}
            />
          )}
          {showRightArrow && (
            <KeyboardArrowRight
              className={theme === 'light' ? classes.nextLight : classes.nextDark}
              onClick={next}
            />
          )}
        </>
      )}
    </MainContainer>
  )
}

export default Slider
