import styled from 'styled-components'

export const MediaContainer = styled.div`
  position: relative;
  margin: 0.75rem 0 0.65rem 0.75rem;
`

// IMPORTANT NOTE: please keeping padding: 56.25% logic to keep videos and articles at correct ratio
export const SelectedMedia = styled.div`
  background-image: url(${(props) => props.bgURL});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding-top: 0;
  width: 0;
  overflow: hidden;
  position: relative;
  transition: padding-top 0.3s ease-in-out, width 0.3s ease-in-out;

  & iframe {
    background-color: #000;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
`

export const NavItemContainer = styled.div`
  display: block;
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: 0;
`

export const NavItem = styled.span`
  display: inline-block;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: 0.75rem;
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  text-transform: uppercase;
  vertical-align: middle;
  color: #555;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`

export const SelectedMediaTitle = styled.div`
  display: inline-block;
  background-color: ${(props) => props.theme.palette.secondary.light};
  padding: 1rem;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: 1.1rem;
  font-weight: ${(props) => props.theme.typography.fontWeight.strong};
  color: ${(props) => props.theme.palette.text.primary};
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;

  & a:link,
  & a:focus,
  & a:active,
  & a:visited {
    color: ${(props) => props.theme.palette.text.primary};
    text-decoration: none;
  }

  & a:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`

export const SelectedMediaSubtitle = styled.p`
  margin-top: 0.5rem;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: 0.8rem;
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  color: ${(props) => props.theme.palette.text.primary};
`

export const UnselectedMediaContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 0;
  transition: margin-top 0.3s ease-in-out;
`

export const UnselectedMedia = styled.div`
  background-color: #151f38;
  background-image: ${(props) => (props.bgURL ? 'url(' + props.bgURL + ')' : 'none')};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: ${(props) => (props.selected ? '4.3rem' : '4.5rem')};
  width: ${(props) => (props.selected ? '7.8rem' : '8rem')};
  border: ${(props) =>
    props.selected ? `.1rem solid ${props.theme.palette.primary.main}` : 'none'};
  position: relative;
  cursor: ${(props) => (props.bgURL ? 'pointer' : 'auto')};
  transition: border 0.2s ease-in-out, width 0.2s ease-in-out, height 0.2s ease-in-out;
`

export const Icon = styled.div`
  border-radius: 50%;
  background-color: ${(props) =>
    props.link
      ? props.theme.palette.secondary.main
      : props.theme.palette.primary.main}; /* Gold for link and Green for checked */
  background-repeat: no-repeat;
  background-position: center;
  background-size: 63%;
  height: 1.25rem;
  width: 1.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  bottom: -0.25rem;
  right: 0.65rem;
`
