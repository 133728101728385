import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { history } from '../../../../history/history'
import { updateProfileByUserID } from '../../../../redux/thunks/profiles/updateProfileByUserID'
import FormError from '../../../alerts/form-error'
import AppBar from '@mui/material/AppBar'
import Checkbox from '@mui/material/Checkbox'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

const appBarStyles = {
  top: 'auto',
  bottom: 0,
  height: '7rem',
  alignItems: 'center',
  bgcolor: 'secondary.main'
}

function TermsOfUseForm() {
  const [checked, setChecked] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [message, setMessage] = useState('')
  const dispatch = useDispatch()

  const handleChangeCheckbox = () => {
    setChecked(!checked)
  }

  const handleSubmit = (e) => {
    setLoading(true)
    e.preventDefault()
    dispatch(updateProfileByUserID({ fieldsToUpdate: { hasAcceptedTerms: true } })).then((data) => {
      if (!data.error) {
        console.log(`*************************`)
        console.log(`*** GO HOME      ********`)
        console.log(`*************************`)
        history.push('/home')
      } else {
        console.log('ERROR:', data)
        setError(true)
        setMessage(data.message)
      }
    })
    setLoading(false)
  }

  return (
    <AppBar position="fixed" sx={appBarStyles}>
      {error && <FormError message={message} style={{ textAlign: 'center' }} />}
      <form onSubmit={(e) => handleSubmit(e)}>
        <FormControl component="fieldset">
          {/* <FormLabel component="legend">Terms of Service Agreement</FormLabel> */}
          <FormGroup aria-label="position" row>
            <FormControlLabel
              sx={{ fontSize: '1rem' }}
              value="test"
              control={<Checkbox onChange={handleChangeCheckbox} />}
              label={<Typography variant="h6">I agree to the terms of use</Typography>}
              labelPlacement="end"
            />
          </FormGroup>
          <Button
            variant="contained"
            disabled={checked === false || loading === true}
            type="submit">
            Submit
          </Button>
        </FormControl>
      </form>
    </AppBar>
  )
}

export default TermsOfUseForm
