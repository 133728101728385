//////////////////
// ACTION TYPES //
//////////////////

export const appLoadedActionTypes = {
  SET_APP_LOADED: "SET_APP_LOADED",
  CLEAR_APP_LOADED: "CLEAR_APP_LOADED",
}

/////////////
// ACTIONS //
/////////////

// APP LOADED
const set = () => {
  return {
    type: appLoadedActionTypes.SET_APP_LOADED,
  }
}

const clear = () => {
  return {
    type: appLoadedActionTypes.CLEAR_APP_LOADED,
  }
}

export const appLoadedActions = {
    set,
    clear,
}
