import config from '../../../config'
import { authenticatedFetch } from '../../../utils/functions/authenticatedFetch'

/*
 * METHOD TO GET ORGANIZATION BY ID
 */
export const getOrganizationByMemberID = (params) => {
  let responseCode = ''
  const method = 'GET'
  const url = `${config.baseURL}/api/organization/members/${params?.id}?limit=${
    params.limit ?? 10
  }&page=${params.page ?? 1}`
  const headers = {
    'Content-Type': 'application/json'
  }

  // Thunk middleware knows how to handle functions.
  // It passes the dispatch method as an argument to the function,
  // thus making it able to dispatch actions itself.
  return (dispatch) => {
    // Return the fetch so react components calling 'store.dispatch()' can use 'then()'
    return authenticatedFetch(url, {
      method,
      headers,
      credentials: 'include'
    })
      .then((response) => {
        responseCode = response.status

        return response.json()
      })

      .then((data) => {
        if (responseCode === 200) {
          // dispatch(organizationPostActions.set(newOrganizationInfo))

          return {
            error: false,
            code: responseCode,
            message: data?.message,
            data
          }
        } else {
          console.log(data?.message)

          return {
            error: true,
            code: responseCode,
            message: data?.message
          }
        }
      })
      .catch((error) => {
        console.log(error)

        return {
          error: true,
          code: '',
          message: error
        }
      })
  }
}
