import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { getUSStreetLocation } from '../../../../../../../redux/thunks/smartystreets/getUSStreetLocation'
import updateLocationValidation from './validations/update-location'
import FormError from '../../../../../../alerts/form-error'
import SelectState from '../../../../../../partials/select-state/select-state'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import { Button, CircularProgress } from '@mui/material'
import {
  Header,
  Subheader,
  CurrentAddressContainer,
  CurrentAddressLine,
  Form,
  InputContainer,
  InputLabel,
  Input,
  SelectContainer
} from './styled/start-comps'

const useStyles = makeStyles((theme) => {
  return createStyles({
    submit: {
      display: 'block',
      width: '50%',
      margin: '0 auto',
      marginTop: '2rem',
      boxSizing: 'border-box',
      fontSize: '1rem',
      color: 'white',
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.light
      },
      '@media (max-width: 450px)': {
        width: '100%'
      }
    },
    circularProgress: {
      display: 'block',
      width: '17px !important',
      height: '17px !important',
      margin: '0 auto',
      '& svg': {
        color: theme.palette.text.primary
      }
    }
  })
})

const Start = ({ setStep, setCandidate, organization }) => {
  const classes = useStyles()
  const [street, setStreet] = useState('')
  const [secondary, setSecondary] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')
  const [zipCode, setZipCode] = useState('')
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState('')
  const dispatch = useDispatch()
  const { address } = organization

  const handleSubmit = (e) => {
    e.preventDefault()
    setProcessing(true)

    const { error } = updateLocationValidation({ street, city, state, zipCode })

    // Check for validation errors and then call action to create take
    if (error) {
      console.log('[VALIDATION ERROR]: ', error)

      setProcessing(false)
      setError(error)
    } else {
      dispatch(getUSStreetLocation({ street, secondary, city, state, zipCode }))
        .then((data) => {
          if (!data.error) {
            console.log('[SUCCESS]: ', 'Successfully verified US street address.')

            setCandidate({ ...data.candidate })
            setProcessing(false)
            setStep('confirm-address')
          } else {
            console.log('[FAIL]: ', data.message)

            setProcessing(false)
            setError(data.message)
          }
        })
        .catch((e) => {
          setProcessing(false)
          setError(e.message)
        })
    }
  }

  if (!organization) return null

  return (
    <>
      <Header>Want to update your organization's address?</Header>

      {address.deliveryLine1 && address.lastLine && (
        <>
          <Subheader>Current address:</Subheader>

          <CurrentAddressContainer>
            <CurrentAddressLine>{address.deliveryLine1}</CurrentAddressLine>
            {address.deliveryLine2 && (
              <CurrentAddressLine>{address.deliveryLine2}</CurrentAddressLine>
            )}
            <CurrentAddressLine>{address.lastLine}</CurrentAddressLine>
          </CurrentAddressContainer>
        </>
      )}

      <FormError message={error} style={{ marginTop: '1rem', marginBottom: '0' }} />

      <Subheader>Please fill out the form below to update your location.</Subheader>

      <Form onSubmit={handleSubmit}>
        <InputContainer>
          <InputLabel>
            Address Line 1<span>*</span>
          </InputLabel>

          <Input
            type="text"
            name="street"
            placeholder="Enter address line 1"
            value={street}
            onChange={(e) => setStreet(e.target.value)}
          />
        </InputContainer>

        <InputContainer>
          <InputLabel>Address Line 2</InputLabel>

          <Input
            type="text"
            name="secondary"
            placeholder="Enter a address line 2"
            value={secondary}
            onChange={(e) => setSecondary(e.target.value)}
          />
        </InputContainer>

        <InputContainer>
          <InputLabel>
            City<span>*</span>
          </InputLabel>

          <Input
            type="text"
            name="city"
            placeholder="Enter a city"
            value={city}
            onChange={(e) => setCity(e.target.value)}
          />
        </InputContainer>

        <InputContainer>
          <InputLabel>
            State<span>*</span>
          </InputLabel>

          <SelectContainer>
            <SelectState state={state} setState={setState} />
          </SelectContainer>
        </InputContainer>

        <InputContainer>
          <InputLabel>
            Zip Code<span>*</span>
          </InputLabel>

          <Input
            type="text"
            name="zipCode"
            placeholder="Enter a zip code"
            value={zipCode}
            onChange={(e) => setZipCode(e.target.value)}
          />
        </InputContainer>

        <Button type="submit" variant="contained" color="primary" className={classes.submit}>
          {processing ? (
            <CircularProgress color="secondary" className={classes.circularProgress} />
          ) : (
            'Submit'
          )}
        </Button>
      </Form>
    </>
  )
}

export default Start
