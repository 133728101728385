import _ from 'lodash'

const linkifyString = (str, color) => {
  const urlRegex = new RegExp(
    /(https?:\/\/)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi
  )

  return str.replace(urlRegex, url => {
    let newURL = url

    if (!_.includes(newURL, '//')) {
      newURL = '//' + url
    }

    return `<a href="${newURL}" target="_blank" rel="noopener noreferrer" style="color:${color}">${url}</a>`
  })
}

export default linkifyString
