import { TabsContainer, Tab, TabContent, MobileDetailsTabContainer } from './styled/tabs-comps'

function Tabs(props) {
  const { tab, setTab, isCurrentUser, numPublicTakes, numDiscussionReq, profile, numHiddenTakes } =
    props

  return (
    <TabsContainer>
      <MobileDetailsTabContainer>
        <div>
          <Tab
            selected={tab === 'details'}
            onClick={() => setTab('details')}
            focused={tab === 'details'}>
            Details
          </Tab>
        </div>
      </MobileDetailsTabContainer>

      <Tab selected={tab === 'public'} onClick={() => setTab('public')} focused={tab === 'public'}>
        Posts
        <TabContent>{`(${numPublicTakes})`}</TabContent>
      </Tab>

      <Tab selected={tab === 'guest'} onClick={() => setTab('guest')} focused={tab === 'guest'}>
        Discussions
        <TabContent>{`(${numDiscussionReq})`}</TabContent>
      </Tab>

      {profile && isCurrentUser && (
        <div>
          <Tab
            selected={tab === 'hidden'}
            onClick={() => setTab('hidden')}
            focused={tab === 'hidden'}
            style={{ marginRight: 0 }}>
            History
            {isCurrentUser && <TabContent>{`(${numHiddenTakes})`}</TabContent>}
          </Tab>
        </div>
      )}
    </TabsContainer>
  )
}

export default Tabs
