import styled from 'styled-components'

export const MainContainer = styled.div`
  background-color: white;
  display: block;
  width: 100%;
  max-width: 40rem;
  padding: 2rem;
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media (max-width: 900px) {
    height: 100%;
    max-width: none;
    top: 0;
    left: 0;
    transform: translate(0, 0);
  }
`

export const Heading = styled.p`
  color: ${(props) => props.theme.typography.fontColor.bodyCopy};
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-weight: ${(props) => props.theme.typography.fontWeight.bold};
  font-size: 1.4rem;
`

export const Subheading = styled.p`
  display: block;
  margin-top: 0.5rem;
  color: ${(props) => props.theme.typography.fontColor.headerCopy};
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  font-size: ${(props) => props.theme.typography.fontSizes.regular};
`

export const InputContainer = styled.div`
  display: block;
  margin-top: 2rem;
`

export const Input = styled.input`
  background-color: rgba(0, 0, 0, 0);
  display: block;
  width: 100%;
  padding: 0.8rem 0.8rem;
  box-sizing: border-box;
  line-height: ${(props) => props.theme.typography.lineHeight};
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.regular};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  color: ${(props) => props.theme.typography.fontColor.bodyCopy};
  border: 1px solid #ddd;
  border-radius: 0.2rem;
  box-shadow: 0px 9px 7px -2px rgba(120, 120, 120, 0.1);
`

export const MemberContainer = styled.div`
  margin-top: 2rem;
  padding: 0.5rem 0.5rem 0.3rem 0.5rem;
  box-sizing: border-box;
  position: relative;
`

export const AvatarContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin-right: 1rem;
  border-radius: 50%;
`

export const ProfileName = styled.span`
  display: inline-block;
  margin-bottom: 0.2rem;
  vertical-align: middle;
  text-transform: capitalize;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.regular};
  font-weight: ${(props) => props.theme.typography.fontWeight.semiBold};
  line-height: 1rem;
  color: ${(props) => props.theme.typography.fontColor.bodyCopy};
`

export const ActionContainer = styled.div`
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: middle;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  cursor: pointer;

  & > .material-icons {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.2rem;
  }

  &:hover {
    background-color: #ddd;
  }

  &:active {
    background-color: #ccc;
  }
`

export const ProfileNotFound = styled.p`
  display: block;
  margin-top: 2rem;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.regular};
  font-weight: ${(props) => props.theme.typography.fontWeight.semiBold};
  line-height: 1rem;
  color: ${(props) => props.theme.typography.fontColor.bodyCopy};
`

export const ButtonsContainer = styled.div`
  display: block;
  margin-top: 2rem;
  text-align: center;
`
