import { useState, useEffect, useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getProfilesByUserIDs } from '../../../../../../../../../redux/thunks/profiles/getProfilesByUserIDs.js'
import { deleteOrganizationAssignedRoleByID } from '../../../../../../../../../redux/thunks/assigned-roles/deleteOrganizationAssignedRoleByID.js'
import FormError from '../../../../../../../../alerts/form-error'
import getName from '../../../../../../../../../utils/functions/getName.js'
import UserAvatar from '../../../../../../../../partials/user-avatar/user-avatar'
import AddMemberModal from './components/add-member-modal/add-member-modal'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import {
  MainContainer,
  MemberContainer,
  AvatarContainer,
  ProfileName,
  ActionContainer
} from './styled/members-comps.js'
import LoadOrganizationRolesContext from '../../../../../../../../data-loading/load-organization-assigned-roles/context/context.js'
import theme from '../../../../../../../../../res/theme.js'

const styles = {
  button: {
    display: 'block',
    width: '15rem',
    marginTop: '2rem',
    boxSizing: 'border-box',
    '@media (max-width: 500px)': {
      width: '100%'
    }
  },
  circularProgress: {
    display: 'block',
    width: '17px !important',
    height: '17px !important',
    margin: '0 auto 0 auto',
    position: 'absolute',
    top: '25%',
    left: '25%',
    transform: 'translate(-50%, -50%)',
    '& svg': {
      color: theme.palette.secondary.main
    }
  }
}

const Members = ({ selectedRole }) => {
  const { orgId } = useParams()
  const { assignedRoles, setAssignedRoles } = useContext(LoadOrganizationRolesContext)
  const [isLoading, setIsLoading] = useState(true)
  const [modalOpen, setModalOpen] = useState(false)
  const [error, setError] = useState('')
  const [processing, setProcessing] = useState(false)
  const loadedProfiles = useSelector(({ loadedProfiles }) => loadedProfiles)
  const dispatch = useDispatch()
  const filteredAssignedRoles = assignedRoles.filter(
    (assignedRole) => assignedRole.roleId === selectedRole
  )

  const handleDelete = (e) => {
    const assignedRoleId = e.target.id

    setProcessing(true)

    dispatch(
      deleteOrganizationAssignedRoleByID({
        assignedRoleId,
        organizationId: orgId
      })
    )
      .then((data) => {
        if (data.error) {
          console.log('[FAIL]: ', 'Error deleting assigned role')

          setError(data.message)
        } else {
          console.log('[SUCCESS]: ', 'Successfully deleted assigned role')

          const newAssignedRoles = assignedRoles.filter(
            (assignedRole) => assignedRole._id !== assignedRoleId
          )

          setAssignedRoles(newAssignedRoles)
        }

        setProcessing(false)
      })
      .catch((e) => {
        console.log('[ERROR]: ', e)

        setProcessing(false)
        setError(e.message)
      })
  }

  useEffect(() => {
    if (isLoading) {
      dispatch(
        getProfilesByUserIDs({
          userIds: filteredAssignedRoles.map(({ userId }) => userId)
        })
      )
        .then((data) => {
          if (data.error) {
            console.log('[FAIL]: ', 'Error retrieving profiles by ID.')
            setError(data.message)
          } else {
            console.log('[SUCCESS]: ', 'Successfully retrieved profiles by ID')
          }

          setIsLoading(false)
        })
        .catch((e) => {
          console.log('[ERROR]: ', e)
          setError(e.message)
        })
    }
  }, [dispatch, filteredAssignedRoles, isLoading])

  return (
    <MainContainer>
      <FormError message={error} style={{ margin: '1.3rem 0 1rem 0' }} />

      {filteredAssignedRoles.map((assignedRole) => {
        const profile = loadedProfiles[assignedRole.userId]

        if (!profile) return null

        const { avatar, userId, documentVerified } = profile
        const verified = documentVerified ? 1 : 0

        return (
          <MemberContainer key={`member-${userId}`}>
            <AvatarContainer>
              <UserAvatar
                src={avatar}
                width={'2rem'}
                height={'2rem'}
                borderWidth={'0.2rem'}
                verified={verified}
              />
            </AvatarContainer>

            <ProfileName>{getName(profile)}</ProfileName>

            <ActionContainer id={assignedRole._id} onClick={handleDelete}>
              {processing ? (
                <CircularProgress color="secondary" sx={styles.circularProgress} />
              ) : (
                <span id={assignedRole._id} className="material-icons">
                  close
                </span>
              )}
            </ActionContainer>
          </MemberContainer>
        )
      })}

      {modalOpen && <AddMemberModal setOpen={setModalOpen} selectedRole={selectedRole} />}

      <Button
        type="submit"
        variant="contained"
        color="primary"
        sx={styles.button}
        disabled={isLoading || processing}
        onClick={() => setModalOpen(true)}>
        Add Member
      </Button>
    </MainContainer>
  )
}

export default Members
