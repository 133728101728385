import styled from 'styled-components'
// import config from "../../../../../../config.js"

export const ItemContainer = styled.div`
  display: block;
  margin-top: 2rem;
  font-size: 0;
`

export const InputLabel = styled.p`
  display: inline-block;
  width: 35%;
  padding-right: 1.5rem;
  box-sizing: border-box;
  margin-top: 0.65rem;
  vertical-align: top;
  line-height: ${(props) => props.theme.typography.lineHeight};
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.regular};
  font-weight: ${(props) => props.theme.typography.fontWeight.semiBold};
  color: ${(props) => props.theme.typography.fontColor.bodyCopy};

  @media (max-width: 600px) {
    display: block;
    width: 100%;
    margin-top: 0;
  }
`

export const InputsContainer = styled.div`
  display: inline-block;
  position: relative;
  width: 65%;
  vertical-align: top;
  padding-bottom: 0.5rem;

  @media (max-width: 600px) {
    display: block;
    width: 100%;
    margin-top: 0.7rem;
  }
`

export const Textarea = styled.textarea`
  background-color: rgba(0, 0, 0, 0);
  display: inline-block;
  width: 100%;
  height: 12rem;
  padding: 0.8rem 0.8rem;
  vertical-align: middle;
  box-sizing: border-box;
  line-height: ${(props) => props.theme.typography.lineHeight};
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.regular};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  color: ${(props) => props.theme.typography.fontColor.bodyCopy};
  border: 1px solid #ddd;
  border-radius: 0.2rem;
  box-shadow: 0px 9px 7px -2px rgba(120, 120, 120, 0.1);
`

export const TextCount = styled.span`
  display: inline-block;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.small};
  font-weight: ${(props) => props.theme.typography.fontWeight.medium};
  color: ${(props) => props.theme.typography.fontColor.headerCopy};
  position: absolute;
  bottom: 0.75rem;
  right: 0.75rem;
`
