//////////////////
// ACTION TYPES //
//////////////////

export const postDraftActionTypes = {
  SET_POST_DRAFT: "SET_POST_DRAFT",
  CLEAR_POST_DRAFT: "CLEAR_POST_DRAFT",
}

/////////////
// ACTIONS //
/////////////

// POST DRAFT
const set = (post) => {
  return {
    type: postDraftActionTypes.SET_POST_DRAFT,
    post,
  }
}

const clear = () => {
  return {
    type: postDraftActionTypes.CLEAR_POST_DRAFT,
  }
}

export const postDraftActions = {
  set,
  clear,
}
