import { analytics } from '../../firebase/firebase'

const logEventAsync = (event) => {
  return new Promise((resolve, reject) => {
    if (event) {
      analytics.logEvent(event)
      resolve({ result: 'successfully logged ' + event })
    }

    const msg = 'ERROR: could not log event in logEventAsync'
    reject(new Error(msg))
  })
}

export default logEventAsync
