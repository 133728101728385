import Placeholder from '../../media/placeholders/profile/avatar-placeholder.png'

const getAvatar = avatar => {
	if (avatar !== '') {
		return avatar
	} else {
		return Placeholder
	}
}

export default getAvatar