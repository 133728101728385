import React from 'react'
import { withRouter } from 'react-router-dom'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import { CircularProgress } from '@mui/material'
import { Subheader } from '../styled/verify-comps'

const useStyles = makeStyles((theme) =>
  createStyles({
    circularProgress: {
      display: 'block',
      margin: '2rem auto 0 auto',
      '& svg': {
        color: theme.palette.primary.main
      }
    }
  })
)

function Start() {
  const classes = useStyles()

  return (
    <>
      <Subheader>Please wait while we check for existing verification attempts.</Subheader>

      <CircularProgress color="primary" className={classes.circularProgress} />
    </>
  )
}

export default withRouter(Start)
