import React, { useRef, useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'
import './css/discussion-slider.css'
import Slider from 'react-slick'
import config from '../../../../../config'
import getAvatar from '../../../../../utils/functions/getAvatar'
import getName from '../../../../../utils/functions/getName'
import nestedSort from '../../../../../utils/functions/nestedSort'
import { getDiscussions } from '../../../../../redux/thunks/discussions/getDiscussions'
import { getRebuttals } from '../../../../../redux/thunks/rebuttals/getRebuttals'
import loadMoreDiscussions from './functions/load-more-discussions'
import Discussion from '../../../discussion/discussion'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import { CircularProgress } from '@mui/material'
import LeftCaret from '@mui/icons-material/KeyboardArrowLeft'
import RightCaret from '@mui/icons-material/KeyboardArrowRight'
import {
  HR,
  SliderContainer,
  UserContainer,
  UserImage,
  UserName,
  LoadMoreContainer,
  LoadMore,
  LoadingMore
} from './styled/discussion-slider-comps'

// const dotsStyling = {
//   position: 'static'
// }

// const dotStyling = {
//   display: 'inline-block',
//   backgroundColor: '#eaeaea',
//   height: '0.5rem',
//   width: '0.5rem',
//   margin: '1rem 0.5rem 0 .5rem',
//   borderRadius: '50%',
//   transition: 'background-color 0.3s ease-in-out'
// }

const useStyles = makeStyles((theme) =>
  createStyles({
    discussionIcon: {
      color: '#ffffff',
      fontSize: '1.8rem',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translateX(-50%) translateY(-50%)'
    },
    loadMoreIcon: {
      fontSize: '1.3rem',
      color: '#333',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translateX(-50%) translateY(-50%)'
    },
    prev: {
      color: '#333',
      fontSize: '1.4rem',
      cursor: 'pointer',
      position: 'absolute',
      top: '2rem',
      left: '-20px',
      transform: 'translateY(-50%)',
      transition: 'color 0.3s ease-in-out',
      '&:hover': {
        color: theme.palette.secondary.main
      }
    },
    next: {
      color: '#333',
      fontSize: '1.4rem',
      cursor: 'pointer',
      position: 'absolute',
      top: '2rem',
      right: '-20px',
      transform: 'translateY(-50%)',
      transition: 'color 0.3s ease-in-out',
      '&:hover': {
        color: theme.palette.secondary.main
      }
    },
    loadingMore: {
      color: '#333'
    }
  })
)

const Discussions = (props) => {
  const { take, defaultDiscussionId } = props
  const classes = useStyles()
  const mainRef = useRef(null)
  const sliderRef = useRef(null)
  const [didRecognizeRef, setDidRecognizeRef] = useState(false)
  const [componentWidth, setComponentWidth] = useState(null)
  const [hideLoadMore, setHideLoadMore] = useState(false)
  const [isLoadingMore, setIsLoadingMore] = useState(false)
  const [moreToLoad, setMoreToLoad] = useState(false)
  const [discussions, setDiscussions] = useState([])
  const [selectedDiscussionId, setSelectedDiscussionId] = useState('')
  const [selectedDiscussion, setSelectedDiscussion] = useState(
    _.find(discussions, { _id: defaultDiscussionId }) || {}
  )
  const [slideIndex, setSlideIndex] = useState(0)
  const [scrollable, setScrollable] = useState(false)
  const [slidesToScroll, setSlidesToScroll] = useState(null)
  const [slidesToShow, setSlidesToShow] = useState(null)
  const [showLeftArrow, setShowLeftArrow] = useState(false)
  const [showRightArrow, setShowRightArrow] = useState(false)
  const [numPages, setNumPages] = useState(null)
  const currentUser = useSelector(({ currentUser }) => currentUser)
  const currentProfile = useSelector(({ currentProfile }) => currentProfile)
  const loadedProfiles = useSelector(({ loadedProfiles }) => loadedProfiles)
  const loadedDiscussions = useSelector(({ loadedDiscussions }) => loadedDiscussions)
  const dispatch = useDispatch()
  const itemWidthPx = 7.5 * 16
  const showSlider =
    didRecognizeRef &&
    componentWidth !== null &&
    slidesToScroll !== null &&
    slidesToShow !== null &&
    numPages !== null

  const handleSetComponentWidth = () => {
    const width = mainRef.current ? mainRef.current.clientWidth : window.innerWidth

    setComponentWidth(width)
  }

  useEffect(() => {
    window.addEventListener('resize', handleSetComponentWidth)

    return () => {
      window.removeEventListener('resize', handleSetComponentWidth)
    }
  }, [])

  useEffect(() => {
    if (!didRecognizeRef) {
      if (mainRef.current !== null && typeof mainRef.current !== 'undefined') {
        setComponentWidth(mainRef.current.clientWidth)
        setDidRecognizeRef(true)
      }
    }
  }, [mainRef, didRecognizeRef])

  useEffect(() => {
    if (didRecognizeRef) {
      const slidesPerPage = Math.floor(componentWidth / itemWidthPx)
      const newScrollable =
        moreToLoad && !hideLoadMore
          ? discussions.length + 1 > slidesPerPage
          : discussions.length > slidesPerPage

      setScrollable(newScrollable)
      setSlidesToShow(slidesPerPage)
      setSlidesToScroll(slidesPerPage)
    }
  }, [itemWidthPx, discussions.length, didRecognizeRef, componentWidth, moreToLoad, hideLoadMore])

  useEffect(() => {
    setShowLeftArrow(scrollable && slideIndex > 0)
    setShowRightArrow(scrollable && slideIndex < numPages - 1)
  }, [scrollable, numPages, slideIndex])

  useEffect(() => {
    if (slidesToShow > 0) {
      if (moreToLoad && !hideLoadMore) {
        setNumPages(Math.ceil(discussions.length + 1 / slidesToShow))
      } else {
        setNumPages(Math.ceil(discussions.length / slidesToShow))
      }
    } else {
      setNumPages(1)
    }
  }, [discussions.length, hideLoadMore, moreToLoad, slidesToShow])

  useEffect(() => {
    const involvedDiscussions = _.filter(loadedDiscussions, (discussion) => {
      return discussion.guestUserId === currentUser.id && discussion.takeId === take._id
    })
    const spectatorDiscussions = _.filter(loadedDiscussions, (discussion) => {
      return discussion.guestUserId !== currentUser.id && discussion.takeId === take._id
    })
    const sortedDiscussions = involvedDiscussions.concat(spectatorDiscussions)

    setDiscussions(sortedDiscussions)
  }, [currentUser.id, loadedDiscussions, take._id])

  useEffect(() => {
    setMoreToLoad(
      discussions.length > 0 && discussions.length % config.loadLimits.discussions.perPost === 0
    )
  }, [discussions, take._id, take.tagIds, take.take])

  useEffect(() => {
    setSelectedDiscussionId(defaultDiscussionId)
    setSelectedDiscussion(_.find(discussions, { _id: defaultDiscussionId }) || {})
  }, [defaultDiscussionId, discussions])

  const handleClickDiscussion = (event) => {
    event.preventDefault()
    event.stopPropagation()

    const { id } = event.target

    setSelectedDiscussionId(id === selectedDiscussionId ? '' : id)
    setSelectedDiscussion(id === selectedDiscussionId ? {} : _.find(discussions, { _id: id }))
  }

  const prev = () => {
    sliderRef.current.slickPrev()
  }

  const next = () => {
    sliderRef.current.slickNext()
  }

  const handleLoadMore = useCallback(() => {
    const sortedDiscussions = nestedSort(discussions, -1, 'whenCreated')
    const afterDate = sortedDiscussions[sortedDiscussions.length - 1].whenCreated

    const loadActions = {
      getDiscussions: (params) => dispatch(getDiscussions(params)),
      getRebuttals: (params) => dispatch(getRebuttals(params))
    }

    loadMoreDiscussions(afterDate, take._id, setIsLoadingMore, setHideLoadMore, loadActions)
  }, [discussions, dispatch, take._id])

  const sliderSettings = {
    // dots: scrollable,
    // appendDots: (dots) => (
    //   <div style={dotsStyling}>
    //     <ul style={{ margin: '0' }}> {dots} </ul>
    //   </div>
    // ),
    // customPaging: (i) => (
    //   <div
    //     style={
    //       i === slideIndex || (slideIndex === -1 && i === 0)
    //         ? { ...dotStyling, backgroundColor: '#4063af' }
    //         : { ...dotStyling }
    //     }
    //   />
    // ),
    arrows: false,
    draggable: true,
    infinite: false,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: slidesToScroll,
    initialSlide: 0,
    beforeChange: (current, next) => {
      setSlideIndex(Math.ceil(next / slidesToShow))
    }
  }

  // Render each Discussion Profile component
  const discussionsThumbnails = discussions.map((discussion) => {
    const { _id, guestUserId, complete, rejected } = discussion
    const isGuestUser = guestUserId === currentUser.id
    const guestProfile = isGuestUser ? currentProfile : loadedProfiles[guestUserId]
    const isClosed = complete || rejected
    const verificationStatus = guestProfile && guestProfile.documentVerified
    const verified = verificationStatus === true ? 1 : 0

    // Don't load component unless data is available
    if (typeof guestProfile === 'undefined') return null

    return (
      <UserContainer
        key={_id}
        id={_id}
        style={selectedDiscussionId !== '' ? { marginBottom: '0' } : {}}
        onClick={handleClickDiscussion}>
        <UserImage
          selected={selectedDiscussionId === _id}
          style={{ backgroundImage: `url(${getAvatar(guestProfile.avatar)})` }}
          verified={verified}
        />

        <UserName isClosed={isClosed}>{isGuestUser ? 'You' : getName(guestProfile)}</UserName>
      </UserContainer>
    )
  })

  return (
    <div ref={mainRef}>
      {discussions.length > 0 && (
        <>
          <HR style={{ marginBottom: '1.2rem' }} />

          <SliderContainer className="slick-slider-discussions">
            {showSlider && (
              <Slider ref={sliderRef} {...sliderSettings}>
                {discussionsThumbnails}

                {moreToLoad && !hideLoadMore && !isLoadingMore && (
                  <LoadMoreContainer>
                    <LoadMore onClick={handleLoadMore}>
                      <RightCaret className={classes.loadMoreIcon} />
                    </LoadMore>
                  </LoadMoreContainer>
                )}

                {isLoadingMore && (
                  <LoadingMore>
                    <CircularProgress
                      variant="indeterminate"
                      className={classes.loadingMore}
                      thickness={4}
                      size={'2rem'}
                    />
                  </LoadingMore>
                )}
              </Slider>
            )}

            {showLeftArrow && <LeftCaret className={classes.prev} onClick={prev} />}
            {showRightArrow && <RightCaret className={classes.next} onClick={next} />}
          </SliderContainer>

          {selectedDiscussionId !== '' && <HR style={{ marginTop: '1.2rem', padding: '0' }} />}

          {selectedDiscussion.takeId === take._id && Object.keys(selectedDiscussion).length > 0 && (
            <Discussion
              take={take}
              discussion={selectedDiscussion}
              mode="light"
              style={{ margin: '1rem 0' }}
            />
          )}
        </>
      )}
    </div>
  )
}

export default Discussions
