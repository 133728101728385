import { useMemo, useContext } from 'react'
import { TabsContainer, Tab } from './styled/org-tabs-comps'
import { OrganizationContext } from '../../organization'
import { useSelector } from 'react-redux'
import { useMediaQuery } from '@mui/material'

function OrgTabs({ tab, setTab, totalCourses }) {
  const [{ total }] = useContext(OrganizationContext)
  const organizations = useSelector((state) => state.organizations)
  const isMobile = useMediaQuery('(max-width: 1050px)')

  const tabs = useMemo(() => {
    return [
      { label: 'Details', code: 'details' },
      { label: 'Posts', code: 'posts' },
      { label: 'Members', code: 'members', total: organizations?.[0].memberCount ?? total },
      { label: 'Courses', code: 'courses', total: totalCourses }
    ]
  }, [total])

  return (
    <TabsContainer>
      {tabs.map((t) => {
        if (!isMobile && t.label === 'Details') {
          return null
        }

        return (
          <Tab
            key={t.code}
            selected={tab === t.code}
            onClick={() => setTab(t.code)}
            focused={tab === t.code}>
            {t.label} {t.total ? `(${t.total})` : ''}
          </Tab>
        )
      })}
    </TabsContainer>
  )
}

export default OrgTabs
