import { useMemo } from 'react'

interface Profile {
  memberships: {
    [key: string]: {
      roles: string[]
    }
  };
}

interface UseUserRolesResult {
  isAdmin: boolean;
  isInstructor: boolean;
  roles: string[];
}

function useMembershipRoles (organizationId: string, profile: Profile): UseUserRolesResult {
  const { memberships } = profile

  const membership = memberships && memberships[organizationId]

  const { roles = [] } = membership || {}

  const isAdmin = useMemo(() => roles.includes('Admin'), [roles])
  const isInstructor = useMemo(() => roles.includes('Faculty'), [roles])

  // console.log(`*****************`)
  // console.log('useUserRoles')
  // console.log('isAdmin', isAdmin)
  // console.log('isInstructor', isInstructor)
  // console.log(`*****************`)

  return { isAdmin, isInstructor, roles }
}

export default useMembershipRoles
