import { useState, useEffect, useRef, useContext } from 'react'
import { useSelector } from 'react-redux'
import { useParams, withRouter, Redirect } from 'react-router-dom'
import config from '../../../config'
import { history } from '../../../history/history'
import checkOrgPermissions from '../../../utils/functions/checkPermissions'
import LoadOrganizationAssignedRoles from '../../data-loading/load-organization-assigned-roles/load-organization-assigned-roles'
import LoadOrganizationStripeAccount from '../../data-loading/load-organization-stripe-account/load-organization-stripe-account'
import LoadOrganizations from '../../data-loading/load-organizations/load-organizations'
import SideNavDesktop from './components/side-nav/side-nav-desktop'
import SideNavMobile from './components/side-nav/side-nav-mobile'
import AccountDetails from './components/account-details/account-details'
import ProfilePicture from './components/profile-picture/profile-picture'
import OrganizationDetails from './components/organization-details/organization-details'
import LocationForm from './components/location/location-form'
import RoleManagement from './components/role-management/role-management'
import Billing from './components/billing/billing'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import { IconButton } from '@mui/material'
import {
  MainContainer,
  SectionContainer,
  SectionHeader,
  SectionContentContainer
} from './styled/my-account-comps'
import LoadOrganizationRolesContext from '../../data-loading/load-organization-assigned-roles/context/context'

const useStyles = makeStyles(() =>
  createStyles({
    iconButton: {
      backgroundColor: 'rgba(0,0,0,.1)',
      padding: '.5rem',
      color: 'white',
      borderRadius: '.5rem',
      position: 'absolute',
      top: '50%',
      left: '.5rem',
      transform: 'translateY(-50%)',
      '& span.material-icons': {
        fontSize: '1.8rem'
      },
      '&:hover': {
        backgroundColor: 'rgba(0,0,0,.2)'
      }
    }
  })
)

function OrganizationAccountContent(props) {
  const { tab } = props
  const { orgId } = useParams()
  const { roles, assignedRoles } = useContext(LoadOrganizationRolesContext)
  const classes = useStyles()
  const [sideNavMobileOpen, setSideNavMobileOpen] = useState(false)
  const [backButton, setBackButton] = useState(false)
  const currentUser = useSelector(({ currentUser }) => currentUser)
  const fullscreen = window.innerWidth <= parseInt(config.UI.drawer_breakpoint.max.split('px')[0])

  const handleWindowResize = () => {
    const newFullscreen =
      window.innerWidth <= parseInt(config.UI.drawer_breakpoint.max.split('px')[0])

    if (newFullscreen) {
      setBackButton(true)
    } else {
      setBackButton(false)
    }

    if (tab.current) {
      setSideNavMobileOpen(false)
    } else {
      if (newFullscreen) {
        setSideNavMobileOpen(true)
      } else {
        history.push(`/organization/${orgId}/settings/account`)
      }
    }
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize)

    return () => window.removeEventListener('resize', handleWindowResize)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const sections = ['account', 'picture', 'details', 'location', 'roles', 'billing']

    if (tab.current) {
      if (sections.indexOf(tab.current) === -1) {
        history.push('/page-not-found')
      }
    } else {
      if (fullscreen) {
        setSideNavMobileOpen(true)
      } else {
        history.push(`/organization/${orgId}/settings/account`)
      }
    }
  }, [fullscreen, orgId, props, tab])

  const getContentHeader = () => {
    switch (tab.current) {
      case 'account':
        return 'Account Details'
      case 'picture':
        return 'Profile Picture'
      case 'details':
        return 'Organization Details'
      case 'location':
        return 'Location Settings'
      case 'roles':
        return 'Role Management'
      case 'billing':
        return 'Billing & Donations'
      default:
        return 'Account Details'
    }
  }

  const hasProperPermissions = () => {
    switch (tab.current) {
      case 'account':
        return checkOrgPermissions({
          assignedRoles,
          roles,
          currUserId: currentUser.id,
          permissions: [] // Admin only
        })
      case 'picture':
        return checkOrgPermissions({
          assignedRoles,
          roles,
          currUserId: currentUser.id,
          permissions: ['manageOrganizationDetails']
        })
      case 'details':
        return checkOrgPermissions({
          assignedRoles,
          roles,
          currUserId: currentUser.id,
          permissions: ['manageOrganizationDetails']
        })
      case 'location':
        return checkOrgPermissions({
          assignedRoles,
          roles,
          currUserId: currentUser.id,
          permissions: ['manageOrganizationDetails']
        })
      case 'roles':
        return checkOrgPermissions({
          assignedRoles,
          roles,
          currUserId: currentUser.id,
          permissions: [] // Admin Only
        })
      case 'billing':
        return checkOrgPermissions({
          assignedRoles,
          roles,
          currUserId: currentUser.id,
          permissions: ['manageBilling']
        })
      default:
        return checkOrgPermissions({
          assignedRoles,
          roles,
          currUserId: currentUser.id,
          permissions: [] // Admin Only
        })
    }
  }

  if (!hasProperPermissions()) {
    return <Redirect to="/page-not-found" />
  }

  return (
    <MainContainer>
      <SideNavMobile open={sideNavMobileOpen} setOpen={setSideNavMobileOpen} />
      <SideNavDesktop />

      {tab.current && (
        <SectionContainer>
          <SectionHeader>
            {(backButton || fullscreen) && (
              <IconButton
                className={classes.iconButton}
                onClick={() => setSideNavMobileOpen((prev) => !prev)}
                size="large">
                <span className="material-icons">chevron_left</span>
              </IconButton>
            )}

            {getContentHeader()}
          </SectionHeader>

          <SectionContentContainer>
            {tab.current === 'account' && <AccountDetails />}
            {tab.current === 'picture' && <ProfilePicture />}
            {tab.current === 'details' && <OrganizationDetails />}
            {tab.current === 'location' && <LocationForm />}
            {tab.current === 'roles' && <RoleManagement />}
            {tab.current === 'billing' && (
              <LoadOrganizationStripeAccount organizationId={orgId}>
                <Billing />
              </LoadOrganizationStripeAccount>
            )}
          </SectionContentContainer>
        </SectionContainer>
      )}
    </MainContainer>
  )
}

function OrganizationAccount(props) {
  const tab = useRef(props.match.params.tab)
  const { orgId } = useParams()

  return (
    <LoadOrganizationAssignedRoles organizationId={orgId} disableRedux={true}>
      <LoadOrganizations organizationIds={[orgId]} enableAsync={true}>
        <OrganizationAccountContent tab={tab} />
      </LoadOrganizations>
    </LoadOrganizationAssignedRoles>
  )
}

export default withRouter(OrganizationAccount)
