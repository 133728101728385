import React, { useState, useEffect, useRef, forwardRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import find from 'lodash/find'
import { markNotificationAsRead } from '../../../../../redux/thunks/notifications/markNotificationAsRead'
import { history } from '../../../../../history/history'
import getNotificationMeta from '../../../../../utils/functions/getNotificationMeta'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import {
  MenuItem,
  ClickAwayListener,
  IconButton,
  Popper,
  Grow,
  Paper,
  MenuList,
  useMediaQuery
} from '@mui/material'
import {
  Badge,
  MoreHoriz,
  PersonAdd,
  Forum,
  VoteIcon,
  TakeIcon,
  QuestionIcon,
  AssignedRoleIcon,
  PurchaseIcon,
  DonationIcon,
  AwardIcon,
  TokenTransactionIcon,
  Settings,
  MarkunreadMailbox
} from './notification-badge-icons'
import {
  Thumbnail,
  ContentContainer,
  Header,
  Subheader,
  MenuContainer,
  IndicatorBadge
} from '../styled/notification-comps'

const useStyles = makeStyles((theme) =>
  createStyles({
    menuItem: {
      backgroundColor: theme.palette.common.white,
      minHeight: '6.5rem',
      padding: '1rem 1.5rem',
      borderBottom: '1px solid #eaeaea',
      boxShadow: '-3px -1px 6px 3px rgba(100, 100, 100, 0.1)',
      position: 'relative',
      cursor: 'pointer',
      [theme.breakpoints.down('md')]: {
        padding: '0.75rem'
      }
    },
    readMenuItem: {
      backgroundColor: '#c7c7cc',
      opacity: 0.8,
      minHeight: '6.5rem',
      padding: '1rem 1.5rem',
      borderBottom: '1px solid #eaeaea',
      boxShadow: '-3px -1px 6px 3px rgba(100, 100, 100, 0.1)',
      position: 'relative',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: '#c7c7cc',
        opacity: 0.7
      },
      [theme.breakpoints.down('md')]: {
        padding: '0.75rem'
      }
    },
    dropdownButton: {
      marginLeft: '2rem',
      [theme.breakpoints.down(undefined)]: {
        marginTop: '0.5rem'
      }
    },
    menuIcon: {
      color: theme.palette.text.primary
    }
  })
)

// ****************************************************** //
// If this component is the last to be rendered in a list
// it receives a ref from it's parent component
// (lastNotificationElementRef)
// ****************************************************** //
const Notification = forwardRef((props, ref) => {
  const classes = useStyles()
  const isMobile = useMediaQuery('(max-width: 599px)')
  const anchorRef = useRef(null)
  const { notification, handleCloseFromInnerComponent } = props

  // Menu Controls
  const [open, setOpen] = useState(false)

  const handleToggle = (e) => {
    e.stopPropagation()
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (e) => {
    if (anchorRef.current && anchorRef.current.contains(e.target)) {
      return
    }

    setOpen(false)
  }

  const prevOpen = useRef(open)
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }

    prevOpen.current = open
  }, [open])

  // Redux
  const notificationProfiles = useSelector((state) => state.notificationProfiles)
  const notificationOrganizations = useSelector((state) => state.notificationOrganizations)
  const dispatch = useDispatch()

  if (!notification || Object.keys(notification).length === 0) return null

  const { id, payload } = notification
  const { userId, organizationId } = payload
  const profile = notificationProfiles[userId]
  const organization = find(notificationOrganizations, { _id: organizationId })

  if (userId && !profile) return null
  if (organizationId && !organization) return null

  const notificationMeta = getNotificationMeta(notification, { profile, organization })
  const verificationStatus = profile && profile.documentVerified
  const verified = verificationStatus === true ? 1 : 0

  // Functions
  const handleNotificationLink = (e) => {
    if (notification.read) {
      notificationMeta.link !== '' && history.push(notificationMeta.link)

      return handleCloseFromInnerComponent
    }

    dispatch(
      markNotificationAsRead({
        notificationId: id
      })
    ).then(() => {
      notificationMeta.link !== '' && history.push(notificationMeta.link)

      isMobile && handleCloseFromInnerComponent(e)
    })
  }

  const handleMarkAsRead = (e) => {
    e.stopPropagation()
    // console.log('Dispatching markNotificationAsRead from the Notification Component', id)
    dispatch(
      markNotificationAsRead({
        notificationId: id
      })
    )
    setOpen(false)
  }

  const handleBadgeIcon = (type) => {
    switch (type) {
      case 'welcome':
        return <Settings />
      case 'email-updated':
        return <Settings />
      case 'phone-updated':
        return <Settings />
      case 'password-updated':
        return <Settings />
      case 'nickname-updated':
        return <Settings />
      case 'login-reminder':
        return <Settings />
      case 'verification-failed':
        return <Settings />
      case 'verification-succeeded':
        return <Settings />
      case 'like':
        return <VoteIcon />
      case 'following-take':
        return <TakeIcon />
      case 'following-organization-post':
        return <TakeIcon />
      case 'discussion-request':
        return <Forum />
      case 'discussion-accepted':
        return <Forum />
      case 'discussion-counter-accepted':
        return <Forum />
      case 'discussion-countered':
        return <Forum />
      case 'discussion-ended':
        return <Forum />
      case 'new-rebuttal':
        return <Forum />
      case 'new-follower':
        return <PersonAdd />
      case 'new-organization-follower':
        return <PersonAdd />
      case 'assigned-role':
        return <AssignedRoleIcon />
      case 'organization-donation-received':
        return <TokenTransactionIcon />
      case 'donation-token-payment-confirmation':
        return <TokenTransactionIcon />
      case 'tokens-transfered':
        return <TokenTransactionIcon />
      default:
        return null
    }
  }

  const badgeIcon = handleBadgeIcon(notification.type)

  return (
    <MenuItem
      key={id}
      ref={ref}
      disableRipple
      className={notification.read ? classes.readMenuItem : classes.menuItem}
      onClick={(e) => handleNotificationLink(e)}>
      <Badge
        badgeContent={notification.isQuestion ? <QuestionIcon /> : badgeIcon}
        overlap="circular"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}>
        <Thumbnail
          verified={verified}
          style={{ backgroundImage: `url(${notificationMeta.image})` }}
        />
      </Badge>

      <ContentContainer>
        <Header>{notificationMeta.title}</Header>
        <Subheader>{notificationMeta.date}</Subheader>
      </ContentContainer>

      <MenuContainer>
        {!notification.read && <IndicatorBadge />}
        <IconButton
          variant="contained"
          color="primary"
          className={classes.dropdownButton}
          ref={anchorRef}
          aria-owns={open ? 'notification-menu-dropdown' : undefined}
          aria-haspopup="true"
          onClick={(e) => handleToggle(e)}
          size="large">
          <MoreHoriz className={classes.menuIcon} />
        </IconButton>

        <Popper
          open={open}
          disablePortal={false}
          anchorEl={anchorRef.current}
          placement="bottom-end"
          transition
          style={{ zIndex: 1000 }}>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="notifications-menu-dropdown"
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
              }}>
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList>
                    {notification.read ? (
                      <MenuItem onClick={(e) => handleMarkAsRead(e)}>
                        <MarkunreadMailbox />
                        Mark as unread
                      </MenuItem>
                    ) : (
                      <MenuItem onClick={(e) => handleMarkAsRead(e)}>
                        <MarkunreadMailbox />
                        Mark as read
                      </MenuItem>
                    )}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </MenuContainer>
    </MenuItem>
  )
})

export default Notification
