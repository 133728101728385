import styled from 'styled-components'
import theme from '../../../../../../res/theme'
// import config from '../../../../../../config'

export const InnerContainer = styled.div`
  margin: 0 auto;
  padding: 2rem 6rem 3rem 6rem;

  @media (max-width: 900px) {
    padding: 2rem 4rem 3rem 4rem;
  }

  @media (max-width: 600px) {
    padding: 2rem 2rem 3rem 2rem;
  }
`

export const LogoCondensed = styled.img`
  cursor: pointer;
  display: block;
  height: 7rem;
  width: auto;
  margin: 0 auto;
  text-align: center;

  @media (max-width: 359px) {
    height: 4rem;
    width: 4rem;
  }
`

export const Title = styled.h1`
  color: ${theme.typography.fontColor.bodyCopy};
  font-family: ${theme.typography.fontFamily};
  font-size: 2rem;
  font-weight: ${theme.typography.fontWeight.regular};
  text-align: center;
  padding-bottom: 1rem;

  @media (max-width: 599px) {
    font-size: 1.5rem;
  }

  @media (max-width: 359px) {
    font-size: 1.35rem;
  }
`

export const Content = styled.p`
  margin-top: 1rem;
  color: ${theme.typography.fontColor.bodyCopy};
  font-family: ${theme.typography.fontFamily};
  font-size: ${theme.typography.fontSizes.regular};
  font-weight: ${theme.typography.fontWeight.regular};
  text-align: center;
`

// export const SingleLineContent = styled.p`
//   color: ${theme.typography.fontColor.bodyCopy};
//   font-family: ${theme.typography.fontFamily};
//   font-size: ${theme.typography.fontSizes.regular};
//   font-weight: ${theme.typography.fontWeight.regular};
//   text-align: center;
//   padding: 0 2rem;
// `

export const ButtonsContainer = styled.div`
  display: block;
  margin: 2rem auto 0 auto;
  text-align: center;
`

export const Link = styled.span`
  color: ${theme.palette.link.primary};
  cursor: pointer;
  display: inline;

  &:hover {
    text-decoration: underline;
  }
`
