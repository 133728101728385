export default class Chain {
	constructor(state) {
	    this.broken = false
	    this.data = {}
	    this.state = state
	    this.log = ''
	}

	// Set the chain's data
	setData(key, data) {
		this.data[key] = data
	}

	// Set the chain's data
	setState(state) {
		this.state = state
	}

	// Break chain and add response and/or log message
	break(log) {
		this.broken = true
		this.log = log
	}
}