import { IconButton, MenuList, MenuItem, Typography, Box } from '@mui/material'
import { styled } from '@mui/material/styles'

export const MuiMenuList = styled(MenuList)(({ theme }) => ({
  minWidth: '14rem'
}))

export const MuiMenuItem = styled(MenuItem)(({ theme }) => ({
  padding: '0.5rem'
}))

export const MuiTypography = styled(Typography)(({ theme }) => ({
  fontSize: '1.15rem'
}))

export const MuiIconButton = styled(IconButton)(({ theme }) => ({
  color: '#333',
  borderRadius: '0.3rem',
  padding: '0.2rem',
  '& .material-icons': {
    fontSize: '3rem'
  }
}))

export const MuiBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'stretch',
  padding: '1rem 0.5rem'
}))
