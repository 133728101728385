import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import Tooltip from '@mui/material/Tooltip'
import Zoom from '@mui/material/Zoom'

const useStyles = makeStyles((theme) =>
  createStyles({
    arrow: {
      color: theme.palette.primary.main
    },
    tooltip: {
      backgroundColor: theme.palette.primary.main,
      padding: '.4rem 1rem',
      color: theme.palette.text.primary,
      fontSize: '1rem',
      fontWeight: 400,
      marginBottom: '1rem'
    }
  })
)

function BadgeTooltip(props) {
  const classes = useStyles()

  return <Tooltip arrow TransitionComponent={Zoom} classes={classes} {...props} />
}

export default BadgeTooltip
