import styled from 'styled-components'
import config from '../../../../../config.js'

export const MainContainer = styled.aside`
  display: inline-block;
  width: 27.5%;
  min-width: 18rem;
  height: 100%;
  min-height: 40rem;
  box-sizing: border-box;
  padding: 3.5rem 2.5rem 4rem 2.5rem;
  border-radius: 0.25rem;
  position: relative;
  background-color: ${({ theme }) => theme.palette.background.darkGray};
`

export const MobileContainer = styled.div`
  border-radius: 0;
  margin: 0;
  background-color: ${({ theme }) => theme.palette.background.darkGray};
`

export const Header = styled.h1`
  color: #ffffff;
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-size: 2.5rem;
  font-weight: ${({ theme }) => theme.typography.fontWeight.medium};
  margin-bottom: 2rem;

  /** Laptop View */
  @media (max-width: 1439px) {
    font-size: 2.25rem;
  }

  /** Tablet View */
  @media (max-width: ${config.UI.drawer_breakpoint.max}) {
    font-size: 2.25rem;
    padding: 1.5rem 0 0.5rem 1.5rem;
    margin: 0 0 0.5rem 0.5rem;
  }

  /** Mobile View */
  @media (max-width: ${config.UI.mobile_breakpoint.max}) {
    font-size: 1.85rem;
  }
  @media (max-width: ${config.UI.smallMobile_breakpoint.max}) {
    text-align: center;
  }
`

export const TabList = styled.ul`
  margin: 0 auto;
  list-style: none;

  @media (max-width: ${config.UI.drawer_breakpoint.max}) {
    /** Modern Browser Support */
    display: flex;
    align-items: flex-start;
    overflow-x: hidden;

    & > li:first-child {
      margin-left: 0.5rem;
    }
  }
  @media (max-width: ${config.UI.smallMobile_breakpoint.max}) {
    justify-content: center;
    & > li:first-child {
      margin-left: 0rem;
    }
  }
`

export const Tab = styled.li`
  background-color: ${({ theme }) => theme.palette.secondary.light};
  cursor: pointer;
  padding: 0.85rem 2rem;
  text-align: center;
  width: ${(props) => props.width};
  font-size: ${(props) => props.fontSize};
  color: ${({ theme }) => theme.typography.fontColor.bodyCopy};
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-weight: ${({ theme }) => theme.typography.fontWeight.bold};
  margin-bottom: 1rem;

  @media (max-width: ${config.UI.drawer_breakpoint.max}) {
    font-size: ${({ theme }) => theme.typography.fontSizes.regular};
    margin: 0 0 1rem 0.5rem;
    width: 8rem;
    padding: ${({ focused }) => (focused ? '0.7rem 1.25rem 0.7rem 1.25rem' : '0.5rem 1rem')};
  }
`
