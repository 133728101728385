import _ from 'lodash'
import { loadedDiscussionActionTypes } from '../action-creators/loaded-discussions.js'
import nestedSort from '../../utils/functions/nestedSort.js'

const copyDiscussions = discussions => {
  return discussions.map(discussion => {
    let tempRatings = { ...discussion.ratings }
    let ratingKeys = Object.keys(tempRatings)
    let ratings = {}

    for (var i = 0; i < ratingKeys.length; i++) {
      ratings = {
        ...ratings,
        [ratingKeys[i]]: { ...discussion.ratings[ratingKeys[i]] }
      }
    }

    return {
      ...discussion,
      ratings: ratings
    }
  })
}

// FOR LOADED DISCUSSIONS
const loadedDiscussionsReducer = (state = [], action) => {
  switch (action.type) {
    case loadedDiscussionActionTypes.SET_LOADED_DISCUSSIONS:
      return nestedSort(copyDiscussions(action.discussions), -1, 'whenCreated')
    case loadedDiscussionActionTypes.ADD_LOADED_DISCUSSIONS:
      let newDiscussions1 = copyDiscussions(state)

      for (var i = 0; i < action.discussions.length; i++) {
        let discussionExists = _.some(newDiscussions1, { '_id': action.discussions[i]._id })

        if (!discussionExists) {
          newDiscussions1.push(action.discussions[i])
        }
      }

      return nestedSort(newDiscussions1, -1, 'whenCreated')
    case loadedDiscussionActionTypes.UPDATE_LOADED_DISCUSSION:
        let newDiscussions2 = copyDiscussions(state)
        const discussionIndex2 = _.findIndex(newDiscussions2, { '_id': action.discussionId })
  
        if (discussionIndex2 !== -1) {
          const updatedDiscussion2 = {
            ...newDiscussions2[discussionIndex2],
            ...action.fieldsToUpdate
          }
  
          newDiscussions2.splice(discussionIndex2, 1, updatedDiscussion2)
        } else {
          console.log('[REDUX ERROR]: ', 'Discussion not found.')
        }
  
        return newDiscussions2
    case loadedDiscussionActionTypes.ACCEPT_LOADED_DISCUSSION:
      let newDiscussions3 = copyDiscussions(state)
      const discussionIndex3 = _.findIndex(newDiscussions3, { '_id': action.discussionId })

      if (discussionIndex3 !== -1) {
        const updatedDiscussion3 = {
          ...newDiscussions3[discussionIndex3],
          isRequest: false,
          isCounter: false,
          accepted: true,
          whenModified: Date.now()
        }

        newDiscussions3.splice(discussionIndex3, 1, updatedDiscussion3)
      } else {
        console.log('[REDUX ERROR]: ', 'Discussion not found.')
      }

      return newDiscussions3
    case loadedDiscussionActionTypes.COUNTER_LOADED_DISCUSSION:
      let newDiscussions4 = copyDiscussions(state)
      const discussionIndex4 = _.findIndex(newDiscussions4, { '_id': action.discussionId })

      if (discussionIndex4 !== -1) {
        const updatedDiscussion4 = {
          ...newDiscussions4[discussionIndex4],
          isRequest: false,
          isCounter: true,
          private: !newDiscussions4[discussionIndex4].private,
          whenModified: Date.now()
        }

        newDiscussions4.splice(discussionIndex4, 1, updatedDiscussion4)
      } else {
        console.log('[REDUX ERROR]: ', 'Discussion not found.')
      }

      return newDiscussions4
    case loadedDiscussionActionTypes.DECLINE_LOADED_DISCUSSION:
      let newDiscussions5 = copyDiscussions(state)
      const discussionIndex5 = _.findIndex(newDiscussions5, { '_id': action.discussionId })

      if (discussionIndex5 !== -1) {
        const updatedDiscussion5 = {
          ...newDiscussions5[discussionIndex5],
          isRequest: false,
          isCounter: false,
          rejected: true,
          whenModified: Date.now()
        }

        newDiscussions5.splice(discussionIndex5, 1, updatedDiscussion5)
      } else {
        console.log('[REDUX ERROR]: ', 'Discussion not found.')
      }

      return newDiscussions5
    case loadedDiscussionActionTypes.END_LOADED_DISCUSSION:
      let newDiscussions6 = copyDiscussions(state)
      const discussionIndex6 = _.findIndex(newDiscussions6, { '_id': action.discussionId })

      if (discussionIndex6 !== -1) {
        const updatedDiscussion6 = {
          ...newDiscussions6[discussionIndex6],
          endedBy: action.endedBy,
          complete: true,
          whenModified: Date.now(),
          whenCompleted: Date.now()
        }

        newDiscussions6.splice(discussionIndex6, 1, updatedDiscussion6)
      } else {
        console.log('[REDUX ERROR]: ', 'Discussion not found.')
      }

      return newDiscussions6
    case loadedDiscussionActionTypes.ADD_LOADED_DISCUSSION_RATING:
      let newDiscussions7 = copyDiscussions(state)
      const discussionIndex7 = _.findIndex(newDiscussions7, { '_id': action.discussionId })

      if (discussionIndex7 !== -1) {
        newDiscussions7[discussionIndex7].ratings[action.userId] = {
          rating: action.ratingId
        }
      } else {
        console.log('[REDUX ERROR]: ', 'Discussion not found.')
      }

      return newDiscussions7
    case loadedDiscussionActionTypes.CLEAR_LOADED_DISCUSSIONS:
      return []
    default:
      return state
  }
}

export default loadedDiscussionsReducer
