import styled from 'styled-components'
import config from '../../../../../../../../config'

export const ArticleContainer = styled.article``

export const ArticleHeader = styled.h3`
  line-height: ${(props) => props.theme.typography.lineHeight};
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.tall};
  font-weight: ${(props) => props.theme.typography.fontWeight.semiBold};
  color: ${(props) => props.theme.palette.text.primary};
  margin-bottom: 0.75rem;
`

export const RedArticleHeader = styled.h4`
  background: ${(props) => props.theme.palette.secondary.light};
  line-height: ${(props) => props.theme.typography.lineHeight};
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.small};
  font-weight: ${(props) => props.theme.typography.fontWeight.semiBold};
  color: ${(props) => props.theme.palette.text.primary};
  width: 10rem;
  padding: 0.5rem 1rem;
  margin: 1rem 0;
  display: block;
  border-radius: 0.3rem;
  text-align: center;
`

export const WhiteBackgroundContainer = styled.div`
  background-color: ${(props) => props.theme.palette.background.lightGray};
  padding: 1rem;
  box-sizing: border-box;
  border-radius: 0.3rem;
  margin-bottom: 1.2rem;

  @media (max-width: ${config.UI.drawer_breakpoint.max}) {
    padding: 0.7rem;
  }
`

export const SponsoredByContainer = styled.div`
  display: flex;
  align-items: center;

  & img {
    object-fit: contain;
  }
`

export const SponsoredByItem = styled.div`
  display: flex;
  align-items: center;
`

export const SponsoredByTitle = styled.p`
  margin-top: 0;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: 1.8rem;
  font-weight: ${(props) => props.theme.typography.fontWeight.medium};
  color: ${(props) => props.theme.palette.text.primary};
`

export const Image = styled.img`
  background-size: cover; /** covers IE support */
  object-fit: cover;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
`

export const SponsoredByCivilTalkContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem 0;

  & img {
    object-fit: contain;
  }
`

export const CivilTalkImage = styled.img`
  background-size: cover; /** covers IE support */
  object-fit: cover;
  width: ${(props) => props.width};
  height: ${(props) => props.height};

  @media screen and (max-width: ${config.UI.smallMobile_breakpoint.max}) {
    width: 14rem;
    height: 5rem;
  }
`
