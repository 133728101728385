import React from 'react'
import { useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
import Slider from '../slider/slider'
import load from './functions/load'
import { getSuggestedConnections } from '../../../redux/thunks/organizations/getSuggestedOrganizations'
import { updateProfileByUserID } from '../../../redux/thunks/profiles/updateProfileByUserID'
import SkeletonConnection from '../../skeletons/skeleton-connection'
import Tags from '../tags/tags'
import makeStyles from '@mui/styles/makeStyles'
import { IconButton } from '@mui/material'
import { Close } from '@mui/icons-material'
import {
  MainContainer,
  HeadingPrimary,
  HeadingSecondary,
  Subheading
} from './styled/suggested-connections-comps'

const useStyles = makeStyles((theme) => ({
  iconButton: {
    position: 'absolute',
    top: '.5rem',
    right: '.5rem'
  }
}))

const SuggestedOrganizations = (props) => {
  const {
    tagIds,
    disableFromInterests,
    disableFromInsights,
    disableRandomSuggestions,
    hideOnNoResults,
    hidePrimaryHeading,
    hideSecondaryHeading,
    hideSubheading,
    customHeading,
    customSecondaryHeading,
    customSubheading,
    showCloseButton,
    style
  } = props

  const classes = useStyles()
  const newStyles = style || {}
  const [tags, setTags] = useState(tagIds && [...tagIds])
  const [organizationIds, setOrganizationIds] = useState({
    random: [],
    fromInterests: [],
    fromInsights: []
  })
  const [organizations, setOrganizations] = useState({
    random: [],
    fromInterests: [],
    fromInsights: []
  })
  const [isLoading, setIsLoading] = useState(true)
  const currentProfile = useSelector(({ currentProfile }) => currentProfile)
  const suggestedOrganizations = useSelector(({ suggestedOrganizations }) => suggestedOrganizations)
  // const currentOrganization = useSelector(({ organizations }) => organizations[0])
  const dispatch = useDispatch()
  const showFromInterests =
    !disableFromInterests &&
    currentProfile.tagsOfInterest.length > 0 &&
    organizationIds.fromInterests.length > 0
  const showFromInsights =
    !disableFromInsights &&
    currentProfile.tagsOfKnowledge &&
    organizationIds.fromInsights.length > 0
  const showRandom =
    !disableRandomSuggestions &&
    organizationIds.random.length > 0 &&
    (organizationIds.fromInterests.length === 0 || organizationIds.fromInsights.length === 0)

  const loadConnections = useCallback(
    (tags) => {
      const loadActions = {
        getSuggestedConnections: (params) => dispatch(getSuggestedConnections(params))
      }

      load(
        tags,
        disableFromInterests,
        disableFromInsights,
        disableRandomSuggestions,
        setIsLoading,
        setOrganizationIds,
        setTags,
        loadActions
      )
    },
    [disableFromInsights, disableFromInterests, disableRandomSuggestions, dispatch]
  )

  useEffect(() => {
    if (isLoading) {
      loadConnections(tags)
    }
  }, [isLoading, loadConnections, tags])

  useEffect(() => {
    const newOrganizations = {
      random: organizationIds.random.map((id) => {
        const organization = _.find(suggestedOrganizations, { _id: id })

        if (typeof organization === 'undefined') return null

        return organization
      }),
      fromInterests: organizationIds.fromInterests.map((id) => {
        const organization = _.find(suggestedOrganizations, { _id: id })

        if (typeof organization === 'undefined') return null

        return organization
      }),
      fromInsights: organizationIds.fromInsights.map((id) => {
        const organization = _.find(suggestedOrganizations, { _id: id })

        if (typeof organization === 'undefined') return null

        return organization
      })
    }

    setOrganizations(newOrganizations)
  }, [organizationIds, suggestedOrganizations])

  useEffect(() => {
    if (!isLoading) {
      loadConnections(tagIds && [...tagIds])
    }
  }, [isLoading, loadConnections, setTags, tagIds])

  const handleUpdateProfile = () => {
    dispatch(
      updateProfileByUserID({
        fieldsToUpdate: {
          showProfileSuggestedConnections: false
        }
      })
    ).then((data) => {
      if (!data.error) {
        console.log('[SUCCESS]: ', 'Successfully updated profile!')
      } else {
        console.log('[FAIL]: ', 'Failed to update profile.')
      }
    })
  }

  if (hideOnNoResults && !isLoading && !showFromInterests && !showFromInsights && !showRandom) {
    return null
  }

  return (
    <MainContainer style={{ ...newStyles }}>
      {showCloseButton && (
        <IconButton className={classes.iconButton} onClick={handleUpdateProfile} size="large">
          <Close />
        </IconButton>
      )}

      {!hidePrimaryHeading && <HeadingPrimary>Suggested Organizations</HeadingPrimary>}
      {customHeading && <HeadingPrimary>{customHeading}</HeadingPrimary>}

      {isLoading ? (
        <SkeletonConnection count={3} style={{ marginTop: '1.5rem' }} />
      ) : (
        <>
          {showFromInterests && (
            <>
              {tagIds ? (
                <>
                  {!hideSecondaryHeading && <HeadingSecondary>Based on:</HeadingSecondary>}
                  {customSecondaryHeading && (
                    <HeadingSecondary>{customSecondaryHeading}</HeadingSecondary>
                  )}

                  <Tags tagIds={tags || []} tagStyle={{ padding: '.4rem .5rem' }} />
                </>
              ) : (
                <>
                  {!hideSecondaryHeading && (
                    <HeadingSecondary>Based on your interests in:</HeadingSecondary>
                  )}
                  {/** !hideSubheading && <Subheading>Based on your interests in:</Subheading> */}
                  {customSecondaryHeading && (
                    <HeadingSecondary>{customSecondaryHeading}</HeadingSecondary>
                  )}
                  {customSubheading && <Subheading>{customSubheading}</Subheading>}

                  <Tags
                    tagIds={currentProfile?.tagsOfInterest}
                    tagStyle={{ padding: '.4rem .5rem' }}
                  />
                </>
              )}
              <Slider
                dataType={'organization'}
                organizations={organizations?.fromInterests}
                theme={'light'}
                displayFollowActions={true}
                enableFollowLoading={true}
              />
            </>
          )}

          {showFromInsights && !tagIds && (
            <>
              {tagIds ? (
                <>
                  {!hideSecondaryHeading && (
                    <HeadingSecondary>Share with others interested in:</HeadingSecondary>
                  )}
                  {customSecondaryHeading && (
                    <HeadingSecondary>{customSecondaryHeading}</HeadingSecondary>
                  )}

                  <Tags tagIds={tags || []} tagStyle={{ padding: '.4rem .5rem' }} />
                </>
              ) : (
                <>
                  {!hideSecondaryHeading && (
                    <HeadingSecondary>Based on your insights on:</HeadingSecondary>
                  )}

                  {customSecondaryHeading && (
                    <HeadingSecondary>{customSecondaryHeading}</HeadingSecondary>
                  )}
                  {customSubheading && <Subheading>{customSubheading}</Subheading>}

                  <Tags
                    tagIds={currentProfile?.tagsOfKnowledge}
                    tagStyle={{ padding: '.4rem .5rem' }}
                  />
                </>
              )}

              <Slider
                dataType={'organization'}
                organizations={organizations?.fromInsights}
                theme={'light'}
                displayFollowActions={true}
                enableFollowLoading={true}
              />
            </>
          )}

          {showRandom && (
            <>
              {!hideSecondaryHeading && <HeadingSecondary>Connect with:</HeadingSecondary>}
              {/** !hideSubheading && <Subheading>Connect with some new community members:</Subheading> */}

              {customSecondaryHeading && (
                <HeadingSecondary>{customSecondaryHeading}</HeadingSecondary>
              )}
              {customSubheading && <Subheading>{customSubheading}</Subheading>}

              <Slider
                dataType={'organization'}
                organizations={organizations?.random}
                theme={'light'}
                displayFollowActions={true}
                enableFollowLoading={true}
              />
            </>
          )}

          {!showFromInterests && !showFromInsights && !showRandom && !isLoading && (
            <Subheading style={{ marginTop: '1.5rem' }}>No suggested connections found</Subheading>
          )}
        </>
      )}
    </MainContainer>
  )
}

export default withRouter(SuggestedOrganizations)
