import config from '../../../../config.js'

export const getResponsiveStyles = (width) => {
  const styles = {
    mainContainer: {
      marginTop: config.UI.main.marginTop,
      padding: '0 1rem'
    },
    leftContainer: {
      width: '59%'
    },
    rightContainer: {
      display: 'inline-block'
    }
  }

  if (width) {
    if (width <= 875) {
      styles.mainContainer.marginTop = '0'
      styles.mainContainer.padding = '0'
      styles.leftContainer.width = '100%'
      styles.rightContainer.display = 'none'
    }
  }

  return styles
}
