import Validator from 'validator'

const validateTag = (tag, enforceMinimum) => {
  const maxLength = 50

  // Return false if tag is not a string
  if (typeof tag !== 'string') return false

  const trimmed = tag.trim()

  // Return false if there are leading or trailing spaces
  if (tag.length !== trimmed.length) return false

  const removedWhiteSpace = tag.split(' ')

  // Return false if there are spaces in tag
  if (removedWhiteSpace.length > 1) return false

  // Return false if there are trailing or leading hyphens
  // if (tag.charAt(0) === '-' || tag.charAt(tag.length - 1) === '-') return false

  // Return false if there are leading hyphens
  if (tag.charAt(0) === '-') return false

  const removedHyphens = trimmed.split('-').join('')

  // Return false if length without hyphens is 0
  if (enforceMinimum && removedHyphens.length === 0) return false

  // Return false if combined characters is greater than max characters
  if (removedHyphens.length > maxLength) return false

  // Return false if tag without hyphens contains any non-alpha characters (nums, special chars, etc...)
  if (!Validator.isAlphanumeric(removedHyphens)) return false

  // Return false if tag contains uppercase characters
  // if (tag.toLowerCase() !== tag) return false

  // Return false if tag has consecutive hyphens
  for (var i = 0; i < tag.length; i++) {
    if (tag.charAt(i) === '-' && i < tag.length - 1) {
      if (tag.charAt(i + 1) === '-') return false
    }
  }

  return true
}

export default validateTag
