import styled from 'styled-components'
import config from '../../../../../config.js'

export const MainContainer = styled.div`
  display: block;
  max-width: 90%;
  width: 80rem;
  height: 100%;
  margin: ${config.UI.main.marginTop} auto 0 auto;

  /**Mobile view */
  @media (max-width: ${config.UI.drawer_breakpoint.max}) {
    margin: 0 auto;
    width: 100%;
    max-width: unset;
  }
`

export const InnerContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  @media (max-width: ${config.UI.drawer_breakpoint.max}) {
    flex-direction: column;
  }
`

export const ListContainer = styled.div`
  display: inline-block;
  vertical-align: top;
  width: 58rem;

  /** Large Laptop Layout width change */
  @media (max-width: 1440px) {
    width: 70%;
  }

  /** Laptop Layout width change */
  @media (max-width: 1298px) {
    width: 68%;
  }

  /** Laptop Layout width change */
  @media (max-width: 1044px) {
    width: 66%;
  }

  /** Mobile view */
  @media (max-width: ${config.UI.drawer_breakpoint.max}) {
    width: 100%;
    max-width: 100%;
  }
`
