import { useDebugValue, useState, Dispatch, SetStateAction } from 'react';

interface YearErrors {
  [key: string]: string;
}

function useYearErrorsObjState(initialValue: YearErrors): [YearErrors, Dispatch<SetStateAction<YearErrors>>] {
  const [yearErrorsObj, setYearErrorsObj] = useState<YearErrors>(initialValue);
  useDebugValue(yearErrorsObj ? yearErrorsObj : initialValue);
  return [yearErrorsObj, setYearErrorsObj];
}

export default useYearErrorsObjState;