//////////////////
// ACTION TYPES //
//////////////////

export const suggestedProfileActionTypes = {
  SET_SUGGESTED_PROFILES: "SET_SUGGESTED_PROFILES",
  ADD_SUGGESTED_PROFILE_FOLLOWER: "ADD_SUGGESTED_PROFILE_FOLLOWER",
  REMOVE_SUGGESTED_PROFILE_FOLLOWER: "REMOVE_SUGGESTED_PROFILE_FOLLOWER",
  CLEAR_SUGGESTED_PROFILES: "CLEAR_SUGGESTED_PROFILES",
}

/////////////
// ACTIONS //
/////////////

// SUGGESTED PROFILES
const set = (profiles) => {
  return {
    type: suggestedProfileActionTypes.SET_SUGGESTED_PROFILES,
    profiles,
  }
}

const addFollower = (userId) => {
  return {
    type: suggestedProfileActionTypes.ADD_SUGGESTED_PROFILE_FOLLOWER,
    userId,
  }
}

const removeFollower = (userId) => {
  return {
    type: suggestedProfileActionTypes.REMOVE_SUGGESTED_PROFILE_FOLLOWER,
    userId,
  }
}

const clear = () => {
  return {
    type: suggestedProfileActionTypes.CLEAR_SUGGESTED_PROFILES,
  }
}

export const suggestedProfilesActions = {
  set,
  addFollower,
  removeFollower,
  clear,
}
