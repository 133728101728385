import Placeholder from '../../media/placeholders/organization/organization-placeholder.png'

export const getOrganizationImageWithFallback = (image) => {
  if (image) {
    return image
  } else {
    return Placeholder
  }
}

const getOrganizationImage = (image) => {
  if (image !== '' && image !== 'undefined') {
    return image
  } else {
    return Placeholder
  }
}

export default getOrganizationImage
