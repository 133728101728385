import { connectOrganizationsActionTypes } from '../action-creators/connect-organizations'

const initState = {
  organizationsList: [],
  afterDate: '',
  hasMore: true
}

const connectOrganizationsReducer = (state = initState, action) => {
  switch (action.type) {
    case connectOrganizationsActionTypes.SET_CONNECT_ORGANIZATIONS:
      const last_afterDate =
        action.payload.length !== 0 ? action.payload?.at(-1)?.whenCreated : state.afterDate

      return {
        ...state,
        organizationsList: action.payload,
        afterDate: last_afterDate,
        hasMore: action.payload.length === 0 ? false : true
      }

    case connectOrganizationsActionTypes.ADD_CONNECT_ORGANIZATIONS:
      const update_last_afterDate =
        action.payload.length !== 0 ? action.payload?.at(-1)?.whenCreated : state.afterDate

      return {
        ...state,
        organizationsList: [...state.organizationsList, ...action.payload],
        afterDate: update_last_afterDate,
        hasMore: action.payload.length === 0 ? false : true
      }
    case connectOrganizationsActionTypes.SET_HAS_MORE_ORGANIZATIONS:
      return {
        ...state,
        hasMore: action.payload
      }

    case connectOrganizationsActionTypes.CLEAR_CONNECT_ORGANIZATIONS:
      return initState
    default:
      return state
  }
}

export default connectOrganizationsReducer
