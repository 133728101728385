import Chain from '../../../../utils/classes/chain.js'
import config from '../../../../config.js'

const loadConnections = (isLoadingPage, afterDate, userId, filter, state, setState, actions) => {
  const { loadLimits } = config

  // EXECUTABLES

  const _getUserFollows = () => {
    return actions.getUserFollows({
      userId,
      afterDate,
      filter,
      limit: loadLimits.follows,
      isLoadingPage
    })
  }

  const _getProfilesByUserIDs = (userIds) => {
    return actions.getProfilesByUserIDs({ userIds })
  }

  const _getOrganizationsByIDs = (organizationIds) => {
    return actions.getOrganizationsByIDs({ organizationIds })
  }

  const _getFollowsByFollowingIDs = (userIds, organizationIds) => {
    return actions.getFollowsByFollowingIDs({ userIds, organizationIds, isLoadingPage: false })
  }

  // PRE_EXECUTION

  const chain = new Chain(state)

  // EXECUTION

  return _getUserFollows()
    .then((data1) => {
      if (data1.error) {
        chain.setState({
          ...chain.state,
          status: {
            ...chain.state.status,
            isLoading: false,
            isLoadingMore: false
          }
        })

        chain.break('Failed to retrieve follows by user IDs.')

        return Promise.resolve()
      }

      // Set initial userId to the currently viewed user ID
      const userIds = [userId]
      const organizationIds = []

      for (let i = 0; i < data1.follows.length; i++) {
        const follow = data1.follows[i]

        if (follow.userId) {
          if (follow.follower === userId) {
            userIds.push(follow.userId)
          } else {
            userIds.push(follow.follower)
          }
        } else if (follow.organizationId) {
          organizationIds.push(follow.organizationId)
        }
      }

      chain.setData('userIds', userIds)
      chain.setData('organizationIds', organizationIds)
      chain.setData('follows', data1.follows)

      return _getProfilesByUserIDs(userIds)
    })
    .then((data2) => {
      if (chain.broken) return Promise.resolve()

      if (data2.error) {
        chain.setState({
          ...chain.state,
          status: {
            ...chain.state.status,
            isLoading: false,
            isLoadingMore: false
          }
        })

        chain.break('Failed to get profiles by user IDs.')

        return Promise.resolve()
      }

      return _getOrganizationsByIDs(chain.data.organizationIds)
    })
    .then((data3) => {
      if (chain.broken) return Promise.resolve()

      if (data3.error) {
        chain.setState({
          ...chain.state,
          status: {
            ...chain.state.status,
            isLoading: false,
            isLoadingMore: false
          }
        })

        chain.break('Failed to get organizations by IDs.')

        return Promise.resolve()
      }

      return _getFollowsByFollowingIDs(chain.data.userIds, chain.data.organizationIds)
    })
    .then((data4) => {
      if (chain.broken) return Promise.resolve()

      if (data4.error) {
        chain.setState({
          ...chain.state,
          status: {
            ...chain.state.status,
            isLoading: false,
            isLoadingMore: false
          }
        })

        chain.break('Failed to get follows by following IDs.')

        return Promise.resolve()
      }

      chain.setState({
        ...chain.state,
        status: {
          ...chain.state.status,
          isLoading: false,
          isLoadingMore: false,
          moreToLoad: chain.data.follows.length === loadLimits.follows
        }
      })

      chain.break('Successfully loaded connections page.')

      return Promise.resolve()
    })
    .then((last_data) => {
      let { broken, log } = chain

      if (broken) {
        console.log('[DEBUG]: ', log)

        setState(chain.state)

        return Promise.resolve()
      }

      throw new Error('Unhandled promise chain logic.')
    })
    .catch((error) => {
      console.log(error)
    })
}

export default loadConnections
