import { currentProfileActionTypes } from '../action-creators/current-profile.js'
import setNestedPropFromString from '../../utils/functions/setNestedPropFromString.js'

const copyProfile = (profile) => {
  return {
    ...profile,
    rating: { ...profile.rating },
    personalAddress: {
      ...profile.personalAddress,
      loc: profile.personalAddress.loc
        ? {
            ...profile.personalAddress.loc,
            coordinates: [...profile.personalAddress.loc.coordinates]
          }
        : {}
    },
    tooltips: {
      ...profile.tooltips
    },
    privacySettings: {
      ...profile.privacySettings
    },
    tagsOfInterest: profile.tagsOfInterest ? [...profile.tagsOfInterest] : [],
    tagsOfKnowledge: profile.tagsOfKnowledge ? [...profile.tagsOfKnowledge] : [],
    courses: profile.courses ? [...profile.courses] : []
  }
}

// FOR CURRENT PROFILE
const currentProfileReducer = (state = {}, action) => {
  switch (action.type) {
    case currentProfileActionTypes.SET_CURRENT_PROFILE:
      return copyProfile(action.profile)
    case currentProfileActionTypes.UPDATE_CURRENT_PROFILE:
      let newProfile = copyProfile(state)
      let keys = Object.keys(action.fields)

      for (var i = 0; i < keys.length; i++) {
        setNestedPropFromString(newProfile, action.fields[keys[i]], keys[i])
      }

      newProfile.whenModified = Date.now()

      return newProfile
    case currentProfileActionTypes.ADD_CURRENT_PROFILE_FOLLOWING:
      var newProfile2 = copyProfile(state)

      newProfile2.following = newProfile2.following + 1

      return newProfile2
    case currentProfileActionTypes.REMOVE_CURRENT_PROFILE_FOLLOWING:
      var newProfile3 = copyProfile(state)

      newProfile3.following = newProfile3.following - 1

      return newProfile3
    case currentProfileActionTypes.ADD_NUM_FRIENDS_INVITED:
      var newProfile4 = copyProfile(state)

      newProfile4.numFriendsInvited = newProfile4.numFriendsInvited + 1

      return newProfile4
    case currentProfileActionTypes.CLEAR_CURRENT_PROFILE:
      return {}
    default:
      return state
  }
}

export default currentProfileReducer
