import Validator from 'validator'

const UpdatePublicNameValidation = (publicName) => {
  if (publicName.length > 150) {
    return { error: 'Public name must be less than 50 characters.' }
  } else {
    const splitStrArr = publicName?.split(/[\s-]+/)

    for (var i = 0; i < splitStrArr?.length; i++) {
      if (splitStrArr[i] === '') continue

      if (!Validator.isAlpha(splitStrArr[i])) {
        return {
          error: 'Public name may only contain letters, spaces, or hyphens (-).'
        }
      }
    }
  }

  return { error: '' }
}

export default UpdatePublicNameValidation
