import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { isLoaded, isEmpty } from 'react-redux-firebase'
import { tokensTransactionModalActions } from '../../../redux/action-creators/tokens-transaction-modal'
import Listener from './listeners/user-meta'
import Purchase from './components/purchase/purchase'
import Donate from './components/donate/donate'
import Transfer from './components/transfer/transfer'
import Portal from '../../partials/portal/portal'
import TokenIcon from '../../../media/icons/svg/token-green.svg'
import { CircularProgress, ClickAwayListener, IconButton } from '@mui/material'
import {
  OuterContainer,
  InnerContainer,
  CloseContainer,
  BalanceContainer,
  BalanceLabel,
  BalanceValue,
  FlowContainer
} from './styled/tokens-transaction-modal-comps'
import theme from '../../../res/theme'
// import config from '../../config'

const styles = {
  circularProgress: {
    display: 'block',
    width: '20px',
    height: '20px',
    margin: '0 auto',
    '& svg': {
      color: theme.palette.primary.main
    }
  }
}

function TokensTransactionModal() {
  const [isLoading, setIsLoading] = useState(true)
  const [flow, setFlow] = useState('')
  const [userId, setUserId] = useState('')
  const [organizationId, setOrganizationId] = useState('')
  const [takeId, setTakeId] = useState('')
  const tokensTransactionModal = useSelector(({ tokensTransactionModal }) => tokensTransactionModal)
  const authRef = useSelector(({ firebase }) => firebase.auth)
  const userMetaTokensRef = useSelector(
    ({
      firestore: {
        data: { userMeta }
      }
    }) => {
      return userMeta && userMeta[authRef.uid] && userMeta[authRef.uid].tokens
    }
  ) || { balance: 0 }
  const dispatch = useDispatch()

  const handleClose = () => {
    dispatch(tokensTransactionModalActions.clear())
  }

  useEffect(() => {
    if (isLoading) {
      setFlow(tokensTransactionModal.initialState)
      setUserId(tokensTransactionModal.userId)
      setOrganizationId(tokensTransactionModal.organizationId)
      setTakeId(tokensTransactionModal.takeId)
      setIsLoading(false)
    }
  }, [
    isLoading,
    tokensTransactionModal.initialState,
    tokensTransactionModal.userId,
    tokensTransactionModal.organizationId,
    tokensTransactionModal.takeId
  ])

  if (isLoading || flow === '') {
    return (
      <OuterContainer>
        <InnerContainer>
          <CircularProgress color="secondary" sx={styles.circularProgress} />
        </InnerContainer>
      </OuterContainer>
    )
  }

  return (
    <Portal>
      {isLoaded(authRef) && !isEmpty(authRef) && <Listener auth={authRef} />}

      <OuterContainer>
        <ClickAwayListener onClickAway={handleClose}>
          <InnerContainer>
            <CloseContainer>
              <IconButton onClick={handleClose}>
                <span className="material-icons">close</span>
              </IconButton>
            </CloseContainer>

            <BalanceContainer>
              <BalanceLabel>Current Balance</BalanceLabel>
              <BalanceValue>
                <img src={TokenIcon} alt="Purchase Tokens Icon" />
                <span>{userMetaTokensRef.balance} Tokens</span>
              </BalanceValue>
            </BalanceContainer>

            <FlowContainer>
              {(flow === 'purchase' || userMetaTokensRef.balance <= 0) && (
                <Purchase zeroBalance={userMetaTokensRef.balance <= 0} />
              )}
              {flow === 'donate' && userMetaTokensRef.balance > 0 && (
                <Donate organizationId={organizationId} takeId={takeId} />
              )}
              {flow === 'transfer' && userMetaTokensRef.balance > 0 && (
                <Transfer userId={userId} takeId={takeId} />
              )}
            </FlowContainer>
          </InnerContainer>
        </ClickAwayListener>
      </OuterContainer>
    </Portal>
  )
}

export default TokensTransactionModal
