import styled from 'styled-components'
import config from '../../../../config.js'

export const MainContainer = styled.article`
  background-color: ${(props) => props.theme.palette.common.white};
  margin-bottom: 1rem;
  border: 1px solid ${(props) => props.theme.palette.background.mediumGray};
  border-radius: 0.3rem;
  box-shadow: ${(props) => props.theme.boxShadow};
`

export const InfoBarContainer = styled.div`
  background-color: ${(props) => props.theme.palette.secondary.light};
  padding: 0.3rem 1.5rem;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;

  & > span {
    font-family: ${(props) => props.theme.typography.fontFamily};
    font-size: ${(props) => props.theme.typography.fontSizes.small};
    font-weight: ${(props) => props.theme.typography.fontWeight.semiBold};
    color: ${(props) => props.theme.palette.text.primary};
  }
`

export const PaddedContainer = styled.div`
  padding: 1.2rem 1.5rem 0.6rem 1.5rem;
  position: relative;
  box-sizing: border-box;
`

export const TakeHeaderContainer = styled.header`
  display: block;
`

export const AvatarContainer = styled.div`
  float: left;
  margin-right: 1rem;
  border-radius: 50%;
  cursor: pointer;
  width: 2.69825rem;
  height: 2.69825rem;
`

export const TakeHeaderInnerContainer = styled.div`
  display: block;
  overflow: hidden;
  min-height: 2.5rem;
  margin-top: 0.1rem;
`

export const Name = styled.p`
  display: block;
  margin: -0.1rem 0.5rem 0 0;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeight.medium};
  line-height: 1.2rem;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: ${config.UI.drawer_breakpoint.max}) {
    font-size: ${(props) => props.theme.typography.fontSizes.short};
  }
`

export const DateString = styled.p`
  // margin-top: 0.2rem;
  // line-height: ${(props) => props.theme.typography.lineHeight};
  color: #464646;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.smaller};
  font-weight: ${(props) => props.theme.typography.fontWeight.light};
`

export const Separator = styled.span`
  display: inline-block;
  width: auto;
  margin: 0 0.75rem;
  vertical-align: middle;
  color: #464646;
  line-height: ${(props) => props.theme.typography.lineHeight};
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.regular};
  font-weight: ${(props) => props.theme.typography.fontWeight.light};

  @media (max-width: ${config.UI.drawer_breakpoint.max}) {
    font-size: ${(props) => props.theme.typography.fontSizes.short};
  }
`

export const UserTake = styled.div`
  display: block;
  margin: 1.2rem 0 1.3rem 0;
`

export const PromptTitleContainer = styled.div`
  display: block;
  margin: 0.5rem 0.5rem 0;
`

export const PromptContentContainer = styled.div`
  display: block;
  margin: 0.5rem 0.5rem 0;
`

export const TakeImageContainer = styled.div`
  /* background-color: ${({ theme }) => theme.palette.common.black}; */
  border-radius: 0.2rem;
  box-sizing: initial;
  display: flex;
`

export const TakeImage = styled.img`
  object-fit: contain;
  max-width: -webkit-fill-available;
  max-width: -moz-available;
  margin-bottom: 0.5rem;
  border-radius: 0.2rem;
  /* max-height: -webkit-fill-available; */
  /* height: -webkit-fill-available; */
`

export const HR = styled.hr`
  border: 0;
  border-top: 1.5px solid rgba(48, 48, 48, 0.1);
  display: block;
  height: 1px;
`

export const ActionsContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  margin-top: 0.5rem;
  justify-content: space-around;
  align-items: center;
`

export const ActionButtonText = styled.span`
  color: ${({ disabled }) => (disabled ? '#999' : '#333')};
`

// Rebuttal Section

export const TextareaContainer = styled.div`
  display: block;
  margin-top: 0.2rem;
`

export const StyledTextarea = styled.textarea`
  background-color: #fafafa;
  display: block;
  width: 100%;
  height: 7rem;
  padding: 0.7rem;
  box-sizing: border-box;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  color: ${(props) => props.theme.typography.fontColor.bodyCopy};
  border: 2px solid #ddd;
  border-radius: 0.2rem;
  resize: none;

  &:focus,
  &:active {
    outline: none;
  }

  &:focus {
    background-color: rgba(255, 255, 255, 0.3);
    border: 2px solid #2eb2fa;
  }
`

export const SubmitContainer = styled.div`
  display: block;
  width: 100&;
  margin: 1rem 0;
  text-align: right;
  font-size: 0;
`

export const SwitchContainer = styled.span`
  display: inline-block;
  margin-right: 2.5rem;
`

export const SwitchLabel = styled.span`
  display: inline-block;
  vertical-align: middle;
  text-transform: uppercase;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  color: ${(props) => props.theme.typography.fontColor.bodyCopy};
`

export const DiscussionLimitMessageContainer = styled.div`
  padding: 1rem 0;
  display: flex;
  align-items: flex-start;
  background: ${(props) => props.theme.palette.primary.main};
  border-radius: 0.25rem;
`

export const DiscussionLimitMessage = styled.span`
  margin-left: 0.5rem;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.regular};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  color: ${(props) => props.theme.typography.fontColor.bodyCopy};
`
