import { useDebugValue, useState, Dispatch, SetStateAction } from 'react';

interface NumberErrors {
  [key: string]: string;
}

function useNumberErrorsObjState(initialValue: NumberErrors): [NumberErrors, Dispatch<SetStateAction<NumberErrors>>] {
  const [numberErrorsObj, setNumberErrorsObj] = useState<NumberErrors>(initialValue);
  useDebugValue(numberErrorsObj ? numberErrorsObj : initialValue);
  return [numberErrorsObj, setNumberErrorsObj];
}

export default useNumberErrorsObjState;