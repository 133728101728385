import getName from './getName.js'
import getAvatar from './getAvatar.js'
import getOrganizationImage from './getOrganizationImage.js'
import CondensedLogo from '../../media/logo/condensed-green.png'
import moment from 'moment'

const getNotificationMeta = (notification, metadata = {}) => {
  const { userId, payload, whenCreated } = notification
  const { profile, organization } = metadata
  const fname = getName(profile, 'first')
  const date = moment(new Date(whenCreated.seconds * 1000)).fromNow()
  let meta = {}

  switch (notification.type) {
    case 'welcome':
      meta.title = 'Welcome to the Community'
      meta.buttonText = 'Get Started'
      meta.link = `/profile/${userId}`
      meta.image = CondensedLogo
      break

    case 'email-updated':
      meta.title = `Your email has been updated`
      meta.buttonText = 'My Profile'
      meta.link = `/my-account`
      meta.image = CondensedLogo
      break

    case 'phone-updated':
      meta.title = `Your phone has been updated`
      meta.buttonText = 'My Profile'
      meta.link = `/my-account`
      meta.image = CondensedLogo
      break

    case 'password-updated':
      meta.title = `Your password has been updated`
      meta.buttonText = 'My Profile'
      meta.link = `/my-account`
      meta.image = CondensedLogo
      break

    case 'nickname-updated':
      meta.title = `Your nickname has been updated`
      meta.buttonText = 'My Profile'
      meta.link = `/profile/${userId}`
      meta.image = CondensedLogo
      break

    case 'login-reminder':
      meta.title = `Check out what's new on Civiltalk!`
      meta.link = `/home`
      meta.image = CondensedLogo
      break

    case 'verification-failed':
      meta.title = `Your verification attempt failed`
      meta.link = `/my-account/identification`
      meta.image = CondensedLogo
      break

    case 'verification-succeeded':
      meta.title = `Your verification attempt was successful!`
      meta.link = `/my-account/identification/verify`
      meta.image = CondensedLogo
      break

    case 'like':
      meta.title = `${fname} liked your post`
      meta.fname = fname
      meta.lname = profile.lname
      meta.buttonText = 'View Post'
      meta.link = `/post/${payload.takeId}`
      meta.image = getAvatar(profile.avatar)
      break

    case 'following-take':
      if (notification.isQuestion === true) {
        meta.title = `${fname} asked a question`
      } else {
        meta.title = `${fname} shared a new post`
      }

      meta.fname = fname
      meta.lname = profile.lname
      meta.buttonText = 'View Post'
      meta.link = `/post/${payload.takeId}`
      meta.image = getAvatar(profile.avatar)
      break

    case 'following-organization-post':
      meta.title = `${organization.publicName} shared a new post`
      meta.fname = fname
      meta.lname = profile.lname
      meta.buttonText = 'View Post'
      meta.link = `/post/${payload.takeId}`
      meta.image = getOrganizationImage(organization.image)
      break

    case 'discussion-request':
      meta.title = `${fname} wants to discuss`
      meta.fname = fname
      meta.lname = profile.lname
      meta.buttonText = 'View Take'
      meta.link = `/post/${payload.takeId}/${payload.discussionId}`
      meta.image = getAvatar(profile.avatar)
      break

    case 'discussion-accepted':
      meta.title = `${fname} accepted your request`
      meta.fname = fname
      meta.lname = profile.lname
      meta.buttonText = 'View Take'
      meta.link = `/post/${payload.takeId}/${payload.discussionId}`
      meta.image = getAvatar(profile.avatar)
      break

    case 'discussion-counter-accepted':
      meta.title = `${fname} accepted your counter`
      meta.fname = fname
      meta.lname = profile.lname
      meta.buttonText = 'View Take'
      meta.link = `/post/${payload.takeId}/${payload.discussionId}`
      meta.image = getAvatar(profile.avatar)
      break

    case 'discussion-countered':
      meta.title = `${fname} countered your request`
      meta.fname = fname
      meta.lname = profile.lname
      meta.buttonText = 'View Take'
      meta.link = `/post/${payload.takeId}/${payload.discussionId}`
      meta.image = getAvatar(profile.avatar)
      break

    case 'discussion-ended':
      meta.title = `${fname} ended your discussion`
      meta.fname = fname
      meta.lname = profile.lname
      meta.buttonText = 'View Take'
      meta.link = `/post/${payload.takeId}/${payload.discussionId}`
      meta.image = getAvatar(profile.avatar)
      break

    case 'new-rebuttal':
      meta.title = `${fname} sent a response`
      meta.fname = fname
      meta.lname = profile.lname
      meta.buttonText = 'View Take'
      meta.link = `/post/${payload.takeId}/${payload.discussionId}`
      meta.image = getAvatar(profile.avatar)
      break

    case 'new-follower':
      meta.title = `${fname} started following you`
      meta.fname = fname
      meta.lname = profile.lname
      meta.buttonText = 'View Profile'
      meta.link = `/profile/${payload.userId}`
      meta.image = getAvatar(profile.avatar)
      break

    case 'new-organization-follower':
      meta.title = `${fname} started following ${organization.publicName}`
      meta.fname = fname
      meta.lname = profile.lname
      meta.buttonText = 'View Profile'
      meta.link = `/profile/${payload.userId}`
      meta.image = getAvatar(profile.avatar)
      break

    case 'assigned-role':
      if (payload.isOrganization) {
        meta.title = `${organization.publicName} has assigned you the role of ${payload.roleName}`
        meta.link = `/organization/${payload.organizationId}`
        meta.buttonText = 'View Organization'
        meta.image = getOrganizationImage(organization.image)
      } else {
        meta.title = `The CivilTalk Team has assigned you the administrative role of ${payload.roleName}. You now have access to the CivilTalk Admin Panel.`
        meta.link = `/`
        meta.buttonText = 'View Profile'
        meta.image = CondensedLogo
      }

      break

    case 'organization-donation-received':
      meta.title = `Congratulations, ${organization.publicName} just received a donation of ${payload.donationTokens} tokens!`
      meta.buttonText = 'View Profile'
      meta.link = `/organization/${payload.organizationId}`
      meta.image = getOrganizationImage(organization.image)
      break

    case 'donation-token-payment-confirmation':
      meta.title = `Thank you for your purchase! ${payload.donationTokens} tokens have been added to your balance.`
      meta.link = `/profile/${userId}`
      meta.buttonText = 'View Profile'
      meta.image = CondensedLogo
      break

    case 'tokens-transfered':
      meta.title = `Congratulations, a user awarded you ${payload.donationTokens} tokens!`
      meta.buttonText = 'View Profile'
      meta.link = `/profile/${userId}`
      meta.image = CondensedLogo
      break

    default:
      meta.title = ''
      meta.fname = ''
      meta.lname = ''
      meta.buttonText = ''
      meta.link = ''
      meta.image = ''
      break
  }

  return { ...meta, date }
}

export default getNotificationMeta
