import { categoryActionTypes } from '../action-creators/categories.js'
import nestedSort from '../../utils/functions/nestedSort.js'

// FOR CATEGORIES
const categoriesReducer = (state = [], action) => {
	switch (action.type) {
		case categoryActionTypes.SET_CATEGORIES:
            return nestedSort(action.categories.map(category => ({...category})), 1, 'name')
		case categoryActionTypes.CLEAR_CATEGORIES:
			return []
		default:
			return state
	}
}

export default categoriesReducer