import styled from 'styled-components'

export const OuterContainer = styled.div`
  display: inline-block;
  min-width: 7.5rem;
  ${'' /* width: 7.5rem; */}
  vertical-align: top;
  text-align: left;
`

export const InnerContainer = styled.div`
  display: inline-block;
  width: 90%;
  padding-top: 1.5rem;
  box-sizing: border-box;
  text-align: center;
`

export const Image = styled.div`
  background-color: ${(props) => props.theme.palette.common.white};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: inline-block;
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  // border: 0.2rem solid;
  // border-color: ${(props) => (props.verified ? props.theme.palette.primary.main : '#ccc')};
`

export const IconContainer = styled.div`
  background-color: ${(props) => props.theme.palette.secondary.main};
  display: block;
  width: 2.1rem;
  height: 2.1rem;
  border-radius: 50%;
  position: absolute;
  top: -0.6rem;
  left: -0.8rem;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.theme.palette.secondary.light};
  }
`

export const IconSpan = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  color: ${(props) => props.theme.palette.common.white};
  font-size: 1.2rem;
`

export const Name = styled.p`
  display: inline-block;
  margin-top: 0.5rem;
  text-align: center;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.small};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  color: ${(props) => props.theme.palette.text.primary};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`
