import styled from 'styled-components'
import config from '../../../../../config.js'

export const MainContainer = styled.section`
  display: block;
`

export const InnerContainer = styled.div`
  display: block;
  /* width: 100%; */
  margin-left: 1rem;

  @media (max-width: ${config.UI.drawer_breakpoint.max}) {
    /* margin-left: 0.5rem;
    margin-right: 0.5rem; */
    margin-left: 0;
  }
`

export const LeftContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  box-sizing: border-box;

  @media (max-width: ${config.UI.drawer_breakpoint.max}) {
    margin-left: 1rem;
  }
`

export const RightContainer = styled.div`
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  box-sizing: border-box;

  @media (max-width: ${config.UI.drawer_breakpoint.max}) {
    margin-right: 1rem;
  }
`

export const Header = styled.header`
  color: ${({ theme }) => theme.typography.fontColor};
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-size: ${({ theme }) => theme.typography.fontSizes.tall};
  font-weight: ${({ theme }) => theme.typography.fontWeight.semiBold};
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.5rem;
  width: 3.25rem;
`

export const Count = styled.div`
  color: ${({ theme }) => theme.typography.fontColor.actionBarCopy};
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-size: ${({ theme }) => theme.typography.fontSizes.regular};
  font-weight: ${({ theme }) => theme.typography.fontWeight.regular};
  display: inline-block;
  margin-right: 1rem;
`

export const ListContainer = styled.table`
  border-radius: 0.25rem;
  box-shadow: 3px 3px 7px 1px rgba(130, 130, 130, 0.1);
  background-color: #ffffff;
  padding: 0.5rem 0;
  width: 100%;
  border-collapse: collapse;

  & > tbody {
    padding: 0.5rem 1.5rem;
    border-collapse: collapse;
    background-color: #ffffff;
    margin: 0.5rem 1.5rem;
  }

  /** Hide the border-top on the first row */
  & > tbody tr:nth-child(1) {
    border: none;
  }
`

export const NoResults = styled.div`
  display: block;
  padding: 1rem;
  color: ${({ theme }) => theme.typography.fontColor.actionBarCopy};
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-size: ${({ theme }) => theme.typography.fontSizes.regular};
  font-weight: ${({ theme }) => theme.typography.fontWeight.regular};
`
