//////////////////
// ACTION TYPES //
//////////////////
export const outgoingDiscussionActionTypes = {
  SET_OUTGOING_DISCUSSIONS: 'SET_OUTGOING_DISCUSSIONS',
  ADD_OUTGOING_DISCUSSIONS: 'ADD_OUTGOING_DISCUSSIONS',
  UPDATE_OUTGOING_DISCUSSIONS: 'UPDATE_OUTGOING_DISCUSSIONS',
  CLEAR_OUTGOING_DISCUSSIONS: 'CLEAR_OUTGOING_DISCUSSIONS'
}

/////////////
// ACTIONS //
/////////////

// DISCUSSIONS
const setDiscussions = (discussions) => {
  return {
    type: outgoingDiscussionActionTypes.SET_OUTGOING_DISCUSSIONS,
    discussions
  }
}

const addDiscussions = (discussions) => {
  return {
    type: outgoingDiscussionActionTypes.ADD_OUTGOING_DISCUSSIONS,
    discussions
  }
}

const update = (discussionId, fieldsToUpdate) => {
  return {
    type: outgoingDiscussionActionTypes.UPDATE_OUTGOING_DISCUSSIONS,
    discussionId,
    fieldsToUpdate
  }
}

const clearDiscussions = () => {
  return {
    type: outgoingDiscussionActionTypes.CLEAR_OUTGOING_DISCUSSIONS
  }
}

export const outgoingDiscussionsActions = {
  setDiscussions,
  addDiscussions,
  update,
  clearDiscussions
}
