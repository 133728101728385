import { TabsContainer, Tab, TabContent, MobileDetailsTabContainer } from './styled/tabs-comps'

function Tabs(props) {
  const {
    tab,
    setTab
    // isCurrentUser,
    // numPublicTakes,
    // numDiscussionsWithOthers,
    // profile,
    // numHiddenTakes
  } = props

  return (
    <TabsContainer>
      <MobileDetailsTabContainer>
        <div>
          <Tab
            selected={tab === 'details'}
            onClick={() => setTab('details')}
            focused={tab === 'details'}>
            Details
          </Tab>
        </div>
      </MobileDetailsTabContainer>

      <Tab selected={tab === 'public'} onClick={() => setTab('public')} focused={tab === 'public'}>
        Class Posts
        {/* <TabContent>{`(${numPublicTakes})`}</TabContent> */}
      </Tab>
      <Tab
        selected={tab === 'prompts'}
        onClick={() => setTab('prompts')}
        focused={tab === 'prompts'}>
        Prompts
      </Tab>
      <Tab
        selected={tab === 'members'}
        onClick={() => setTab('members')}
        focused={tab === 'members'}>
        Members
        {/* <TabContent>{`(${numDiscussionsWithOthers})`}</TabContent> */}
      </Tab>
    </TabsContainer>
  )
}

export default Tabs
