import { useDebugValue, useState, Dispatch, SetStateAction } from 'react';

function useYearErrorState(): [boolean, Dispatch<SetStateAction<boolean>>] {
  const [yearError, setYearError] = useState(false);

  useDebugValue(yearError);

  return [yearError, setYearError];
}

export default useYearErrorState;