import styled from 'styled-components'
import config from '../../../../config.js'
import theme from '../../../../res/theme.js'

export const OuterContainer = styled.div`
  background-color: rgba(70, 70, 70, 0.4);
  overflow-y: auto;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
`

export const InnerContainer = styled.div`
  background-color: #fff;
  width: 30rem;
  padding: 2rem;
  box-sizing: border-box;
  border-radius: 0.2rem;
  position: absolute;
  top: 50%;
  left: 50%;

  transform: translateX(-50%) translateY(-50%);

  @media (max-width: ${config.UI.drawer_breakpoint.max}) {
    width: 19rem;
  }
`

export const SuccessHeader = styled.p`
  font-family: ${theme.typography.fontFamily};
  font-size: 1.8rem;
  font-weight: ${theme.typography.fontWeight.regular};
  text-align: center;
  color: #777;
`

export const SuccessSubheader = styled.p`
  margin-top: 2rem;
  font-family: ${theme.typography.fontFamily};
  font-size: 1.4rem;
  font-weight: ${theme.typography.fontWeight.regular};
  text-align: center;
  color: #777;
`

export const Header = styled.div`
  font-family: ${theme.typography.fontFamily};
  font-size: 1.4rem;
  font-weight: ${theme.typography.fontWeight.regular};
  color: #555;
`

export const Form = styled.form`
  margin-top: 2rem;
  width: 100%;
  background-color: #fff;
`

export const ButtonsContainer = styled.div`
  margin-top: 2rem;
  text-align: center;
`
