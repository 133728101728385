import styled from 'styled-components'
import config from '../../../../../../../../config.js'

export const Header = styled.p`
  text-align: center;
  color: ${(props) => props.theme.typography.fontColor.bodyCopy};
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-weight: ${(props) => props.theme.typography.fontWeight.bold};
  font-size: ${(props) => props.theme.typography.fontSizes.extraLarge};

  @media (max-width: ${config.UI.drawer_breakpoint.max}) {
    text-align: center;
  }
`

// export const Subheader = styled.p`
//   margin-top: 1rem;
//   color: ${theme.typography.fontColor.bodyCopy};
//   font-family: ${theme.typography.fontFamily};
//   font-weight: ${theme.typography.fontWeight.regular};
//   font-size: ${theme.typography.fontSizes.regular};

//   @media (max-width: ${config.UI.drawer_breakpoint.max}) {
//     text-align: center;
//   }
// `
