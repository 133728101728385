import styled from 'styled-components'
import config from '../../../../config'

export const MainContainer = styled.div`
  background-color: ${(props) => props.theme.palette.common.white};
  border-radius: 0.3rem;
  margin-bottom: 1.5rem;
  padding: 1rem 0.5rem;
`

export const Prompt = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  background-color: ${(props) =>
    props.isOrg ? props.theme.palette.secondary.main : props.theme.palette.secondary.main};
  padding: 0.5rem 1.5rem;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;

  & > span {
    font-family: ${(props) => props.theme.typography.fontFamily};
    font-size: ${(props) => props.theme.typography.fontSizes.tall};
    font-weight: ${(props) => props.theme.typography.fontWeight.bold};
    color: ${(props) => props.theme.palette.common.white};
  }
`

export const FormContainer = styled.div`
  padding: 1rem 1.5rem;
  border-left: 1px solid;
  border-right: 1px solid;
  border-bottom: 1px solid;
  border-color: ${(props) => props.theme.palette.text.primary};
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  display: flex;
  flex-direction: column;
`

export const AvatarContainer = styled.div`
  display: flex;
`

export const TakeHeaderInnerContainer = styled.div`
  display: block;
  overflow: hidden;
  min-height: 2.5rem;
  margin: 0.1rem 0 1rem 0;
`

export const Name = styled.p`
  display: block;
  margin: -0.1rem 0.5rem 0 0.5rem;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeight.medium};
  line-height: 1.2rem;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: ${config.UI.drawer_breakpoint.max}) {
    font-size: ${(props) => props.theme.typography.fontSizes.short};
  }
`

export const DateString = styled.p`
  // margin-top: 0.2rem;
  // line-height: ${(props) => props.theme.typography.lineHeight};
  color: #464646;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.smaller};
  font-weight: ${(props) => props.theme.typography.fontWeight.light};
`

export const Separator = styled.span`
  display: inline-block;
  width: auto;
  margin: 0 0.75rem;
  vertical-align: middle;
  color: #464646;
  line-height: ${(props) => props.theme.typography.lineHeight};
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.regular};
  font-weight: ${(props) => props.theme.typography.fontWeight.light};

  @media (max-width: ${config.UI.drawer_breakpoint.max}) {
    font-size: ${(props) => props.theme.typography.fontSizes.short};
  }
`

export const FormInputContainer = styled.div`
  min-height: 3rem;
  padding-bottom: 1rem;
  border-bottom: 1.5px solid #eee;
  position: relative;
`

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

export const Textarea = styled.textarea`
  display: block;
  width: 100%;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.regular};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  color: ${(props) => props.theme.palette.text.primary};
  border: none;
  resize: none;

  &:active,
  &:focus {
    outline: none;
  }

  &::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: ${(props) => props.theme.typography.fontColor.headerCopy};
    font-size: ${(props) => props.theme.typography.fontSizes.small};
    font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  }
  &:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: ${(props) => props.theme.typography.fontColor.headerCopy};
    font-size: ${(props) => props.theme.typography.fontSizes.small};
    font-weight: ${(props) => props.theme.typography.fontWeight.regular};
    opacity: 1;
  }
  &::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: ${(props) => props.theme.typography.fontColor.headerCopy};
    font-size: ${(props) => props.theme.typography.fontSizes.small};
    font-weight: ${(props) => props.theme.typography.fontWeight.regular};
    opacity: 1;
  }
  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${(props) => props.theme.typography.fontColor.headerCopy};
    font-size: ${(props) => props.theme.typography.fontSizes.small};
    font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  }
  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${(props) => props.theme.typography.fontColor.headerCopy};
    font-size: ${(props) => props.theme.typography.fontSizes.small};
    font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  }

  &::placeholder {
    /* Most modern browsers support this now. */
    color: ${(props) => props.theme.typography.fontColor.headerCopy};
    font-size: ${(props) => props.theme.typography.fontSizes.small};
    font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  }
`

export const TextCount = styled.span`
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.small};
  font-weight: ${(props) => props.theme.typography.fontWeight.medium};
  color: ${(props) => props.theme.typography.fontColor.headerCopy};
  text-align: right;
`

export const TagMessage = styled.p`
  margin-top: 0.5rem;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.smaller};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  color: ${(props) => props.theme.palette.text.primary};
`

export const RelatedTagsHeader = styled.p`
  margin-top: 0.5rem;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.short};
  font-weight: ${(props) => props.theme.typography.fontWeight.semiBold};
  color: ${(props) => props.theme.palette.text.primary};
  margin-bottom: -0.5rem;

  & > span {
    font-weight: ${(props) => props.theme.typography.fontWeight.bold};
    color: ${(props) => props.theme.palette.primary.main};
  }
`

export const EmotionMessage = styled.p`
  margin-top: 0.5rem;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.smaller};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  color: ${(props) => props.theme.palette.text.primary};
`

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
`
