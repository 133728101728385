import config from '../../../../config.js'
import Chain from '../../../../utils/classes/chain.js'

const loadSearchResults = (query, state, setState, actions) => {
  const { loadLimits } = config

  // EXECUTABLES

  const _getSearchResults = (query) => {
    return actions.search({ query, limit: loadLimits.searchResults })
  }

  const _getFollowsByFollowingIDs = (userIds, organizationIds) => {
    return actions.getFollowsByFollowingIDs({ userIds, organizationIds, isLoadingPage: true })
  }

  // PRE_EXECUTION

  query = typeof query === 'undefined' ? ' ' : query
  const chain = new Chain(state) // Set variable to log chain status

  // EXECUTION

  return _getSearchResults(query)
    .then((data1) => {
      if (data1.error) {
        chain.setState({
          ...chain.state,
          status: {
            ...chain.state.status,
            pageLoaded: true
          }
        })

        chain.break('Failed to load search results')

        return Promise.resolve()
      }

      chain.setData('profiles', data1.profiles)
      chain.setData('tags', data1.tags)
      chain.setData('organizations', data1.organizations)

      const userIds = data1.profiles.map(({ userId }) => userId)
      const organizationIds = data1.organizations.map(({ _id }) => _id)

      return _getFollowsByFollowingIDs(userIds, organizationIds)
    })
    .then((data2) => {
      let tab = 'people'
      if (chain.data.profiles.length === 0) tab = 'tags'
      if (chain.data.tags.length === 0 && chain.data.profiles.length === 0) tab = 'organizations'
      if (chain.data.organizations.length === 0 && chain.data.tags.length === 0) tab = 'people'

      chain.setState({
        ...chain.state,
        status: {
          ...chain.state.status,
          tab,
          pageLoaded: true
        }
      })

      chain.break('Successfully loaded search page.')

      return Promise.resolve()
    })
    .then((last_data) => {
      let { broken, log } = chain

      if (broken) {
        console.log('[DEBUG]: ', log)

        setState(chain.state)

        return Promise.resolve()
      }

      throw new Error('Unhandled promise chain logic.')
    })
    .catch((error) => {
      console.log(error)
    })
}

export default loadSearchResults
