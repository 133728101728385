import styled from 'styled-components'
import _ from 'lodash'

import withStyles from '@mui/styles/withStyles'
import TableCell from '@mui/material/TableCell'
import theme from '../../../../res/theme'

export const CourseContainer = styled.div`
  display: block;
  margin-top: 1rem;
`

export const CustomTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#ddd',
    fontFamily: 'sofia-pro, sans-serif',
    fontSize: '1rem',
    fontWeight: '600',
    textTransform: 'uppercase',
    color: theme.palette.text.primary
  },
  root: {
    fontSize: '1rem',
    fontWeight: '300'
  }
}))(TableCell)

export const styles = (theme) => ({
  root: {
    width: '100%',
    marginTop: '1rem',
    overflowX: 'auto'
  },
  table: {
    minWidth: 1020
  },
  tableWrapper: {
    overflowX: 'auto'
  },
  button: {},

  refresh: {
    display: 'inline-block',
    margin: '0 0 0 .5rem',
    padding: '.5rem',
    verticalAlign: 'middle',
    color: theme.palette.secondary.main
  },
  headerItem: {
    display: 'block',
    margin: '0',
    marginLeft: '.5rem',
    padding: '.5rem',
    float: 'right',
    color: theme.palette.secondary.main
  },
  color: {
    borderLeftStyle: 'solid',
    borderLeftWidth: '20px',
    width: '0'
  },
  needsAction: {
    display: 'inline-block',
    margin: '.4rem 0 0 .4rem',
    color: 'rgba(1, 147, 247, 1)'
  },
  checkbox: {
    color: theme.palette.secondary.main
  }
})

export const MainContainer = styled.div`
  background-color: #f5f5f5;
  display: block;
  padding: 1rem 1.5rem;
  overflow-y: auto;
  position: absolute;
  top: 3.7rem;
  right: 0;
  bottom: 0;
  left: 0;
`

export const PageContainer = styled.div`
  display: block;
  width: 80rem;
  max-width: 95%;
  margin: 1.5rem auto 4rem;
`

export const MainHeaderContainer = styled.div`
  display: block;
  min-height: 40px;
  font-size: 0;

  & .bank-info {
    margin: 1.25rem 0;
  }
`

export const MainHeaderTitle = styled.span`
  font-family: sofia-pro, sans-serif;
  display: inline-block;
  margin-top: 3px;
  margin-right: 1rem;
  vertical-align: middle;
  text-transform: uppercase;
  font-size: 1.6rem;
  font-weight: ${theme.typography.fontWeight.normal};
  color: #333;

  &:before {
    content: '';
    display: inline-block;
    height: 37px;
    vertical-align: middle;
  }
`

export const Form = styled.form`
  display: block;
  width: 100%;
  max-width: 42rem;
  margin: 0;
`

export const FormLabel = styled.p`
  display: block;
  font-family: sofia-pro, sans-serif;
  font-size: 1rem;
  font-weight: ${theme.typography.fontWeight.normal};
  color: #333;
`

export const FormData = styled.p`
  display: block;
  font-family: sofia-pro, sans-serif;
  max-width: 42rem;
  margin: 1rem 0 2rem 0;
  font-size: 0.9rem;
  font-weight: ${theme.typography.fontWeight.normal};
  color: #666;
`

export const FormPhoto = styled.div`
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: block;
  width: 42rem;
  height: 15rem;
  margin-top: 1rem;
  border-radius: 0.3rem;
`

export const FormTextField = styled.input`
  font-family: sofia-pro, sans-serif;
  background-color: white;
  display: block;
  width: 100%;
  max-width: 42rem;
  height: 2.8rem;
  margin: 0.7rem 0 2rem 0;
  padding: 0 0.7rem;
  box-sizing: border-box;
  color: #888;
  font-size: 0.9rem;
  font-weight: ${theme.typography.fontWeight.normal};
  border: 1.5px solid #eaeaea;
  border-radius: 0.3rem;
  box-shadow: 0px 7px 5px -2px rgba(130, 130, 130, 0.1);
`
