import styled from 'styled-components'
import config from '../../../../../config.js'

export const SearchContainer = styled.div`
  ${'' /* display: ${(props) => (props.showProfile ? 'none' : 'inline-block')};*/}
  display: inline-block;
  margin-right: 1rem;
  vertical-align: middle;
  position: relative;

  /* @media (max-width: ${config.UI.drawer_breakpoint.max}) {
    display: block;
    width: 24rem;
    margin: 0.5rem auto;
    box-sizing: border-box;
  } */

  display: block;
  width: 24rem;
  margin: 0.5rem auto;
  box-sizing: border-box;

  @media (max-width: 450px) {
    display: block;
    width: 97%;
    margin: 0.5rem 1.5%;
    box-sizing: border-box;
  }
`

export const SearchInput = styled.input`
  background-color: rgba(200, 200, 200, 0.2);
  width: 18rem;
  width: 100%;
  padding: 0.5rem 1rem 0.5rem 2.8rem;
  padding: 1rem 1rem 1rem 3.8rem;
  border: none;
  border-radius: 0.3rem;
  box-sizing: border-box;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.large};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  color: #333;
  transition: background-color 0.3s ease-in-out, width 0.3s ease-in-out;

  &:hover {
    background-color: rgba(200, 200, 200, 0.4);
  }

  &:focus {
    outline: 0;
    border: 1.5px solid #e5e5e5;
    width: 20rem;
    width: 100%;
  }

  /* @media (max-width: ${config.UI.drawer_breakpoint.max}) {
    width: 100%;
    padding: 1rem 1rem 1rem 3.8rem;
    font-size: ${(props) => props.theme.typography.fontSizes.large};

    &:focus {
      width: 100%;
      border: none;
    }
  } */
`

export const MobileResultsContainer = styled.div`
  background-color: #eee;
  padding: 0.5rem;
  margin-top: 0.5rem;
  border-radius: 0.2rem;

  /* @media (min-width: ${config.UI.drawer_breakpoint.min}) {
    display: none;
  } */
`

export const ProfileContainer = styled.div`
  display: block;
  padding: 0.5rem 1rem;
  cursor: pointer;

  @media (max-width: ${config.UI.drawer_breakpoint.max}) {
    padding: 0.8rem 1rem;
  }
`

export const ProfilePicture = styled.div`
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: block;
  float: left;
  /* width: 2.3rem;
  height: 2.3rem; */
  width: 3.3rem;
  height: 3.3rem;
  margin-right: 1rem;
  border-radius: 50%;
  border: 0.2rem solid;
  border-color: ${(props) => (props.verified === 1 ? props.theme.palette.primary.main : '#ccc')};

  /* @media (max-width: ${config.UI.drawer_breakpoint.max}) {
    width: 3.3rem;
    height: 3.3rem;
  } */
`

export const ProfileInfoContainer = styled.div`
  display: block;
  /* margin-top: -0.2rem; */
  margin-top: 0.2rem;
  overflow: hidden;

  /* @media (max-width: ${config.UI.drawer_breakpoint.max}) {
    margin-top: 0.2rem;
  } */
`

export const ProfileName = styled.p`
  display: block;
  text-transform: capitalize;
  line-height: 1.4rem;
  font-family: ${(props) => props.theme.typography.fontFamily};
  /* font-size: ${(props) => props.theme.typography.fontSizes.regular};
  font-weight: ${(props) => props.theme.typography.fontWeight.bold}; */
  color: ${(props) => props.theme.palette.text.primary};

  /* @media (max-width: ${config.UI.drawer_breakpoint.max}) {
  } */
  font-size: ${(props) => props.theme.typography.fontSizes.large};
  font-weight: ${(props) => props.theme.typography.fontWeight.semiBold};
`

export const ProfileLocation = styled.p`
  display: block;
  /* margin-top: 0.1rem; */
  text-transform: capitalize;
  line-height: ${(props) => props.theme.typography.lineHeight};
  font-family: ${(props) => props.theme.typography.fontFamily};
  /* font-size: ${(props) => props.theme.typography.fontSizes.short}; */
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  color: ${(props) => props.theme.palette.text.primary};

  /* @media (max-width: ${config.UI.drawer_breakpoint.max}) {
  } */
  font-size: ${(props) => props.theme.typography.fontSizes.regular};
  margin-top: 0.5rem;
`

export const AllResults = styled.p`
  display: block;
  padding: 0.5rem 1rem;
  line-height: ${(props) => props.theme.typography.lineHeight};
  font-family: ${(props) => props.theme.typography.fontFamily};
  /* font-size: ${(props) => props.theme.typography.fontSizes.medium}; */
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  color: ${(props) => props.theme.palette.text.primary};
  cursor: pointer;

  /* @media (max-width: ${config.UI.drawer_breakpoint.max}) {
  } */
  font-size: ${(props) => props.theme.typography.fontSizes.large};
`
