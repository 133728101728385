//////////////////
// ACTION TYPES //
//////////////////

export const pollActionTypes = {
  SET_POLLS: "SET_POLLS",
  CLEAR_POLLS: "CLEAR_POLLS",
}

/////////////
// ACTIONS //
/////////////

// POLLS
const set = (polls) => {
  return {
    type: pollActionTypes.SET_POLLS,
    polls,
  }
}

const clear = () => {
  return {
    type: pollActionTypes.CLEAR_POLLS,
  }
}

export const pollsActions = {
  set,
  clear,
}
