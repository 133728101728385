import styled from 'styled-components'

export const CourseSelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem 0 0.5rem 0;
`

export const CourseSelectLabel = styled.label`
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.regular};
  font-weight: ${(props) => props.theme.typography.fontWeight.bold};
  color: ${(props) => props.theme.palette.text.primary};
  margin-bottom: 0.5rem;
`
