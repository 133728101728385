import styled from 'styled-components'

export const TagCardsContainer = styled.div`
  display: block;
  font-size: 0;
  margin-bottom: 1rem;

  & > div:not(:last-child) {
    margin-right: ${({ marginRight }) => marginRight};
  }
`

export const TagCard = styled.div`
  display: ${({ display }) => display};
  background-color: ${(props) => props.theme.palette.secondary.light};
  margin-top: 0.7rem;
  padding: 0.4rem;
  padding-right: 0.4rem;
  vertical-align: middle;
  box-sizing: border-box;
  border-radius: 0.3rem;

  & > div {
    display: block;
    margin-top: 0.05rem;
    margin-right: 0.3rem;
    padding: 0.1rem;
    float: left;
    border-radius: 0.3rem;
    font-size: ${(props) => props.theme.typography.fontSizes.small};
    font-weight: ${(props) => props.theme.typography.fontWeight.medium};
    color: ${(props) => props.theme.palette.text.primary};
    cursor: pointer;

    &:hover {
      background-color: ${(props) => props.theme.palette.secondary.main};
      color: ${(props) => props.theme.palette.common.white};
    }
  }

  & > p {
    display: block;
    font-family: ${(props) => props.theme.typography.fontFamily};
    font-size: ${(props) => props.theme.typography.fontSizes.small};
    font-weight: ${(props) => props.theme.typography.fontWeight.regular};
    line-height: 1.1rem;
    color: ${(props) => props.theme.palette.text.primary};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
`
