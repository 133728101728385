import { useState } from 'react'
import LogoCondensedImage from '../../../media/logo/condensed-green.png'
import { InnerContainer, LogoCondensed } from './styled/terms-of-use-comps'
import { Dialog, MuiAppBar, MuiToolbar } from './styled/mui-styled-components'
import Typography from '@mui/material/Typography'
import TermsOfUseView from './components/terms-of-use-view'
import TermsOfUseForm from './components/terms-of-use-form'

function TermsOfUse() {
  const [open] = useState(true)

  return (
    <Dialog
      open={open}
      // onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      disablePortal
      fullScreen
      sx={{ bgcolor: '#eaeaea' }}>
      <MuiAppBar sx={{ position: 'relative', bgcolor: 'secondary.main', height: '3rem' }}>
        <MuiToolbar sx={{ display: 'flex', justifyContent: 'end' }}></MuiToolbar>
      </MuiAppBar>

      <InnerContainer>
        <div>
          <LogoCondensed src={LogoCondensedImage} alt="CivilTalk Logo Condensed (CT)" />
        </div>

        <Typography
          variant="h3"
          gutterBottom
          id="alert-dialog-title"
          sx={{
            textAlign: 'center',
            '@media (max-width: 700px)': {
              fontSize: '2rem'
            }
          }}>
          Welcome to CivilTalk
        </Typography>

        <Typography
          variant="h5"
          gutterBottom
          paragraph
          id="alert-dialog-description"
          sx={{
            textAlign: 'center',
            '@media (max-width: 700px)': {
              fontSize: '1.2rem'
            }
          }}>
          At CivilTalk, we prioritize your privacy and are committed to safeguarding your
          information.
        </Typography>
        <Typography
          variant="h5"
          gutterBottom
          paragraph
          id="alert-dialog-description"
          sx={{
            textAlign: 'center',
            '@media (max-width: 700px)': {
              fontSize: '1.2rem'
            }
          }}>
          We want to assure you that we will never sell your data to third parties.
        </Typography>
        <Typography
          variant="h5"
          gutterBottom
          paragraph
          id="alert-dialog-description"
          sx={{
            textAlign: 'center',
            '@media (max-width: 700px)': {
              fontSize: '1.2rem'
            }
          }}>
          As a private company dedicated to serving our community, we uphold the highest standards
          of confidentiality, ensuring that your trust in us is well-placed and respected.
        </Typography>
      </InnerContainer>

      <TermsOfUseView />

      <TermsOfUseForm />
    </Dialog>
  )
}

export default TermsOfUse
