import styled from 'styled-components'
import theme from '../../../res/theme.js'
import config from '../../../config'

export const OuterContainer = styled.div`
  background-color: rgba(70, 70, 70, 0.2);
  overflow-y: scroll;
  position: fixed;
  top: 5rem;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
`

export const CloseButton = styled.div`
  font-size: 1.25rem;
  font-weight: ${theme.typography.fontWeight.bold};
  /* color: #22335b; */
  cursor: pointer;
  position: absolute;
  top: 0.6rem;
  right: 0.6rem;
  /** Mobile View */
  @media (max-width: ${config.UI.drawer_breakpoint.max}) {
    font-size: 1.5rem;
    top: 0.75rem;
    right: 0.75rem;
  }
`

export const Header = styled.h1`
  margin-bottom: 1rem;
  font-family: ${theme.typography.fontFamily};
  font-size: 2rem;
  font-weight: ${theme.typography.fontWeight.medium};
  text-align: center;
  color: ${theme.typography.fontColor.bodyCopy};
  /** Mobile View */
  @media (max-width: ${config.UI.drawer_breakpoint.max}) {
    font-size: 1.4rem;
    font-weight: ${theme.typography.fontWeight.semiBold};
  }
  /** Small Phone */
  @media (max-width: 374px) {
    font-size: 1rem;
    font-weight: ${theme.typography.fontWeight.semiBold};
  }
`

export const Subheader = styled.p`
  margin-bottom: 2.5rem;
  font-family: ${theme.typography.fontFamily};
  font-size: 1.4rem;
  font-weight: ${theme.typography.fontWeight.regular};
  text-align: center;
  color: ${theme.typography.fontColor.bodyCopy};
  /** Mobile View */
  @media (max-width: ${config.UI.drawer_breakpoint.max}) {
    font-size: 1.2rem;
    font-weight: ${theme.typography.fontWeight.semiBold};
    margin-bottom: 1rem;
  }
  /** Small Phone */
  @media (max-width: 374px) {
    font-size: 1rem;
    font-weight: ${theme.typography.fontWeight.semiBold};
    margin-bottom: 1rem;
  }
`

export const FormContainer = styled.div`
  display: block;
  margin-top: 1rem;
  font-size: 0;
  & > input {
    background-color: rgba(0, 0, 0, 0);
    display: inline-block;
    padding: 1rem;
    border: 1px solid #ddd;
    border-radius: 0.2rem;
    box-sizing: border-box;
    line-height: ${theme.typography.lineHeight};
    font-family: ${theme.typography.fontFamily};
    font-size: ${theme.typography.fontSizes.regular};
    font-weight: ${theme.typography.fontWeight.regular};
    color: ${theme.typography.fontColor.bodyCopy};
    box-shadow: 0px 9px 7px -2px rgba(120, 120, 120, 0.1);
    /** Mobile View */
    @media (max-width: ${config.UI.drawer_breakpoint.max}) {
      padding: 0.5rem;
    }
    /** Small Phone */
    @media (max-width: 374px) {
      padding: 0.4rem;
    }
  }
  & > input:nth-child(1) {
    width: 38%;
    margin-right: 2%;
    @media (max-width: 500px) {
      display: block;
      width: 100%;
    }
  }
  & > input:nth-child(2) {
    width: 60%;
    @media (max-width: 500px) {
      display: block;
      width: 100%;
      margin-top: 1rem;
    }
  }
`

export const PreviewContainer = styled.div`
  background-color: #eee;
  margin-top: 1rem;
  padding: 1rem;
  border-radius: 0.3rem;
  /** Mobile View */
  @media (max-width: ${config.UI.drawer_breakpoint.max}) {
    padding: 0.5rem 1rem;
  }
`

export const PreviewSummaryContentContainer = styled.div`
  display: inline-block;
  vertical-align: middle;

  @media (max-width: 500px) {
    display: block;
    text-align: center;
    margin-top: 0.5rem;
  }
`

export const PreviewTextGroup = styled.p`
  line-height: 1.4rem;
  font-family: ${theme.typography.fontFamily};
  font-size: ${theme.typography.fontSizes.regular};
  font-weight: ${theme.typography.fontWeight.regular};
  color: ${theme.typography.fontColor.bodyCopy};
  margin-bottom: 0.5rem;
`

export const PreviewProfilePicture = styled.div`
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: inline-block;
  margin-right: 1rem;
  width: 3.5rem;
  height: 3.5rem;
  vertical-align: middle;
  border-radius: 50%;

  @media (max-width: 500px) {
    display: block;
    margin: 0 auto;
  }
`

export const AvatarContainer = styled.div`
  display: inline-block;
  margin-right: 1rem;
  vertical-align: middle;
  border-radius: 50%;

  @media (max-width: 500px) {
    display: block;
    margin: 0 auto;
  }
`

export const ButtonsContainer = styled.div`
  display: block;
  height: 3.2rem;
  margin-top: 1.5rem;
  padding-bottom: 1rem;
  /** Mobile View */
  @media (max-width: ${config.UI.drawer_breakpoint.max}) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0.25rem;
  }
`

export const SuccessContainer = styled.div`
  /** Mobile View */
  @media (max-width: ${config.UI.drawer_breakpoint.max}) {
    padding-bottom: 2.5rem;
  }
`
