import styled from 'styled-components'

export const MainContainer = styled.div``

export const BackButton = styled.div`
  display: inline-block;
  margin-bottom: 1rem;
  text-decoration: underline;
  cursor: pointer;
  color: ${(props) => props.theme.palette.secondary.main};
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  font-size: ${(props) => props.theme.typography.fontSizes.regular};
`

export const Heading = styled.p`
  color: ${(props) => props.theme.typography.fontColor.bodyCopy};
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-weight: ${(props) => props.theme.typography.fontWeight.bold};
  font-size: 1.4rem;
`

export const Subheading = styled.p`
  display: block;
  margin-top: 1rem;
  color: ${(props) => props.theme.typography.fontColor.bodyCopy};
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  font-size: ${(props) => props.theme.typography.fontSizes.regular};
`
