import { useEffect, useState } from 'react'
import moment from 'moment'
import withStyles from '@mui/styles/withStyles'
import {
  Table,
  TableBody,
  TableRow,
  TablePagination,
  Paper
  // CircularProgress
} from '@mui/material'
import * as TransAction_actionCreators from '../../../../../../../../redux/action-creators/transAction-history'
import { bindActionCreators } from 'redux'
import { useDispatch, useSelector } from 'react-redux'
import { Container, NoResults } from './styled/transaction-history'
import { CustomTableCell, styles } from './styles/styles'
import { stableSort, getSorting } from './utils/utility'
import EnhancedTableHead from './components/EnhancedTableHead'

const TransActionHistory = ({ classes }) => {
  const dispatch = useDispatch()
  const { setTransActionLimits, setTransActionPage, history_DonationTokens } = bindActionCreators(
    TransAction_actionCreators,
    dispatch
  )
  const [ispageChanged, setispageChanged] = useState(false)
  const {
    transActionHistory: { rowsPerPage, page, history, afterDate, loading, totalRecords },
    transActionHistory,
    currentProfile
  } = useSelector((state) => state)

  useEffect(() => {
    // eslint-disable-next-line no-unused-vars
    let mount = true
    history_DonationTokens({ rowsPerPage, afterDate, ispageChanged, currentProfile })

    return () => {
      mount = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsPerPage])

  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState('whenCreated')
  const rows = []

  const handleRequestSort = (event, property) => {
    const newOrderBy = property
    let newOrder = 'desc'

    if (orderBy === property && order === 'desc') {
      newOrder = 'asc'
    }

    setOrder(newOrder)
    setOrderBy(newOrderBy)
  }

  const createData = (donationTokens, type, name_from, name_to, whenCreated, _id) => {
    return { donationTokens, type, name_from, name_to, whenCreated, _id }
  }
  if (history) {
    for (let i = 0; i < history?.length; i++) {
      rows.push(
        createData(
          history[i]?.donationTokens,
          history[i]?.type,
          history[i]?.name_from || '',
          history[i]?.name_to || '',
          history[i]?.whenCreated,
          history[i]?._id
        )
      )
    }
  }

  const changePageHandler = async (e, page) => {
    await setispageChanged(true)
    await setTransActionPage(page)

    const nextPageContent = history?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)

    if (transActionHistory.page < page) {
      if (nextPageContent.length <= 0) {
        await history_DonationTokens({ rowsPerPage, afterDate, currentProfile, update: true })
      }
    }
  }

  const changeLimitHandler = (page) => {
    setispageChanged(false)
    setTransActionLimits(page)
  }

  useEffect(() => {
    return () => {
      setTransActionPage(0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  return (
    <Container>
      <span>Transaction History</span>
      {!loading && (
        <>
          {rows.length > 0 ? (
            <Paper className={classes.root}>
              <div className={classes.tableWrapper}>
                <Table className={classes.table} aria-labelledby="tableTitle">
                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={(e, prop) => handleRequestSort(e, prop)}
                    rowCount={rows.length - 1}
                  />

                  <TableBody>
                    <>
                      {stableSort(rows, getSorting(order, orderBy))
                        ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        ?.map((n, inx) => {
                          const transactionType =
                            n.type === 'transfer'
                              ? 'award'
                              : n.type === 'subscription'
                              ? 'received'
                              : n.type

                          return (
                            <>
                              {n?.name_to && (
                                <TableRow hover tabIndex={-1} key={inx} className={classes.row}>
                                  <CustomTableCell
                                    component="th"
                                    scope="row"
                                    padding="normal"
                                    style={{ fontWeight: 400 }}>
                                    {n.donationTokens}
                                  </CustomTableCell>

                                  <CustomTableCell align="right">{transactionType}</CustomTableCell>

                                  <CustomTableCell align="right">{n?.name_from}</CustomTableCell>

                                  <CustomTableCell align="right">{n?.name_to}</CustomTableCell>

                                  <CustomTableCell align="right">
                                    {moment(n.whenCreated).format('MMM Do YYYY, LT')}
                                  </CustomTableCell>
                                </TableRow>
                              )}
                            </>
                          )
                        })}
                    </>
                  </TableBody>
                </Table>
              </div>

              {totalRecords && (
                <TablePagination
                  component="div"
                  count={totalRecords}
                  rowsPerPage={rowsPerPage}
                  rowsPerPageOptions={[]}
                  page={page}
                  backIconButtonProps={{
                    'aria-label': 'Previous Page'
                  }}
                  nextIconButtonProps={{
                    'aria-label': 'Next Page'
                  }}
                  onPageChange={(e, page) => changePageHandler(e, page)}
                  onRowsPerPageChange={(e) => changeLimitHandler(e.target.value)}
                />
              )}
            </Paper>
          ) : (
            <NoResults>No results</NoResults>
          )}
        </>
      )}
    </Container>
  )
}

export default withStyles(styles)(TransActionHistory)
