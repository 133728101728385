import Chain from '../../../../utils/classes/chain.js'

const load = (userId, setIsLoading, setManagingOrganizationIds, dispatch, actions) => {
  // EXECUTABLES

  const _getAssignedRoles = () => {
    return dispatch(actions.getAssignedRoles({ userId }))
  }

  const _getOrganizationRolesByID = (roleIds) => {
    return dispatch(actions.getOrganizationRolesByID({ roleIds }))
  }

  const _getFollowing = () => {
    return dispatch(actions.getFollowing({ type: 'organization' }, { setData: true }))
  }

  const _getOrganizationsByIDs = (organizationIds) => {
    return dispatch(actions.getOrganizationsByIDs({ organizationIds }))
  }

  // PRE_EXECUTION

  const chain = new Chain()
  let organizationIds = []

  // EXECUTION

  return _getAssignedRoles()
    .then((data1) => {
      if (data1.error) {
        chain.break('Failed to retrieve user assigned roles.')

        return Promise.resolve()
      }

      // Set initial userId to the currently viewed user ID
      const roleIds = data1.assignedRoles.map((assignedRole) => assignedRole.roleId)

      return _getOrganizationRolesByID(roleIds)
    })
    .then((data2) => {
      if (chain.broken) return Promise.resolve()

      if (data2.error) {
        chain.break('Failed to retrieve organization roles by IDs.')

        return Promise.resolve()
      }

      organizationIds = data2.roles.map((role) => role.organizationId)
      setManagingOrganizationIds([...organizationIds])

      return _getFollowing()
    })
    .then((data3) => {
      if (chain.broken) return Promise.resolve()

      if (data3.error) {
        chain.break('Failed to get current user organization follows.')

        return Promise.resolve()
      }

      organizationIds = organizationIds.concat(data3.follows.map((follow) => follow.organizationId))

      return _getOrganizationsByIDs(organizationIds)
    })
    .then((data4) => {
      if (chain.broken) return Promise.resolve()

      if (data4.error) {
        chain.break('Failed to get organizations by IDs.')

        return Promise.resolve()
      }

      chain.break('Successfully loaded my organizations.')

      return Promise.resolve()
    })
    .then((last_data) => {
      let { broken, log } = chain

      if (broken) {
        console.log('[DEBUG]: ', log)

        setIsLoading(false)

        return Promise.resolve()
      }

      throw new Error('Unhandled promise chain logic.')
    })
    .catch((error) => {
      console.log(error)
    })
}

export default load
