import { useState, useCallback, useRef, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { debounce, set } from 'lodash'
import Select from './course-setup-select'
import Input from './course-setup-input'
import CourseMemberCard from './course-member-card'
import CourseStepButtonContainer from './course-step-button-container'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { styled } from '@mui/material/styles'
import MenuItem from '@mui/material/MenuItem'
import List from '@mui/material/List'
import ListSubheader from '@mui/material/ListSubheader'
import ListItemButton from '@mui/material/ListItemButton'
import { searchOrganizationMembers } from '../../../../redux/thunks/search/searchOrganizationMembers'
import { getProfilesByUserIDs } from '../../../../redux/thunks/profiles/getProfilesByUserIDs'
import {
  ProfileContainer,
  ProfilePicture,
  ProfileInfoContainer,
  ProfileName,
  ProfileEmail
} from '../styled/course-setup-comps'
import getName from '../../../../utils/functions/getName'
import getAvatar from '../../../../utils/functions/getAvatar'

function CourseMembersForm({
  courseFields,
  handleSetCourseFields,
  handlePreviousStep,
  handleNextStep,
  activeStep,
  steps,
  setStepUpdated
}) {
  const [query, setQuery] = useState('')
  const [users, setUsers] = useState([])
  const [formErrors, setFormErrors] = useState(false)
  const [members, setMembers] = useState([])
  const inputRef = useRef(null)
  const dispatch = useDispatch()

  function getPublicName() {
    const env = import.meta.env.VITE_NODE_ENV

    console.log('[DEBUG]: env course members', env)

    switch (env) {
      case 'local':
        return 'UCONN'
      case 'staging':
        return 'UCONN'
      case 'production':
        return 'UCONN'
      default:
        return 'UCONN'
    }
  }

  const publicName = getPublicName()

  console.log('[DEBUG]: org publicName course members', publicName)

  const debouncedFindUsers = useCallback(
    debounce((query) => {
      dispatch(searchOrganizationMembers({ query, limit: 10, orgName: publicName })).then(
        (result) => {
          if (!result.error) {
            const userIds = result.data?.users?.map((i) => i?._id)

            dispatch(getProfilesByUserIDs({ userIds })).then((profileResult) => {
              const profiles = Object.values(profileResult?.profiles)

              const users = result.data?.users.map((user) => {
                const profile = profiles?.find((profile) => profile?.userId === user?._id)

                return {
                  ...profile,
                  ...user
                }
              })

              console.log(`[DEBUG]: users returned while searching for members`, users)

              users && setUsers(users)
            })
          } else {
            setUsers([])
          }
        }
      )
    }, 1000),
    []
  )

  const handleInputChange = (e) => {
    const query = e.target.value

    setQuery(query)
    if (query) {
      debouncedFindUsers(query)
    } else {
      // setResultOpen(false)
    }
  }

  const handleSelectMember = (id) => {
    setStepUpdated(true)
    // pick from users
    const selected = users.find((user) => user._id === id)

    // check for duplicates
    // const duplicate = members.find((member) => member._id === id)

    const updatedMembers = [...members, selected]

    // remove the selected member from the search results
    setUsers(users.filter((user) => user._id !== id))

    handleSetCourseFields({ ...courseFields, members: updatedMembers })

    setMembers(updatedMembers)
  }

  const handleRemoveMember = (id) => {
    setStepUpdated(true)
    const updatedMembers = members.filter((member) => member._id !== id)

    setMembers(updatedMembers)
    handleSetCourseFields({ ...courseFields, members: updatedMembers })
  }

  useEffect(() => {
    setMembers(courseFields.members)
  }, [])

  const profiles = users?.map((profile) => {
    const { _id, avatar, personalAddress, documentVerified, email } = profile

    const verified = documentVerified ? 1 : 0

    return (
      <CourseMemberCard
        id={_id}
        member={profile}
        label="Member"
        isButton={true}
        buttonType={'add'}
        clickHandler={handleSelectMember}
      />
    )
  })

  return (
    <MuiBox>
      <Input
        label="Search Members"
        id="search"
        name="search"
        value={query}
        handleChange={handleInputChange}
        inputRef={inputRef}
        helperText="Search member profiles"
      />
      {/* <Button type="file" variant="contained">
        Upload .csv
      </Button> */}
      <Box sx={{ display: 'flex', gap: '2rem' }}>
        <MuiFlexColumnBox>
          <MuiList>
            <MuiListSubheader key={'search-subheader-key'}>Search Results</MuiListSubheader>
            {users.length && profiles}
          </MuiList>
        </MuiFlexColumnBox>
        <MuiFlexColumnBox>
          <MuiList>
            <MuiListSubheader key={'subheader-key'}>Selected Course Members</MuiListSubheader>
            {members.map((member) => (
              <CourseMemberCard
                id={member._id}
                member={member}
                label="Member"
                isButton={true}
                buttonType={'remove'}
                clickHandler={handleRemoveMember}
              />
            ))}
          </MuiList>
        </MuiFlexColumnBox>
      </Box>
      <CourseStepButtonContainer
        handlePreviousStep={handlePreviousStep}
        handleNextStep={handleNextStep}
        activeStep={activeStep}
        steps={steps}
        formErrors={formErrors}
        editingCourse={courseFields.status !== 'pending'}
      />
    </MuiBox>
  )
}

export default CourseMembersForm

// ***************************** //
// *** MUI Styled Components *** //
// ***************************** //

const MuiBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
  margin: '1rem 0',
  padding: '2rem',
  borderRadius: '0.25rem',
  background: '#fff'
}))

const MuiFlexColumnBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  // alignItems: 'flex-start',
  gap: '1rem',
  width: '100%'
}))

const MuiList = styled(List)(({ theme }) => ({
  border: '1px solid',
  borderColor: theme.palette.secondary.main,
  minWidth: '18rem',
  width: '100%',
  padding: 0,
  borderRadius: '0.25rem',
  minHeight: '21.5rem',
  backgroundColor: theme.palette.background.gray
}))

const MuiListSubheader = styled(ListSubheader)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '1.25rem',
  backgroundColor: theme.palette.secondary.light,
  minWidth: '18rem',
  borderRadius: '0.25rem 0.25rem 0 0'
}))

const MuiListItemButton = styled(ListItemButton)(({ theme }) => ({
  '&.MuiButtonBase-root': {
    backgroundColor: theme.palette.common.white
  }
}))

const MuiAddButton = styled(Button)(({ theme }) => ({
  // borderRadius: '0.25rem'
}))
