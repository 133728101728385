import styled from 'styled-components'
import config from '../../../../../config'

export const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  /** Mobile Display */
`
export const BackGroundContainer = styled.div`
  background-color: ${(props) => props.theme.palette.secondary.main};
  position: relative;
  background-image: linear-gradient(90deg, rgba(51, 51, 51, 0.8) 0%, rgba(51, 51, 51, 0.8) 100%),
    url(https://res.cloudinary.com/headliner/image/upload/v1679885963/LandingPage/pang-yuhao-_kd5cxwZOK4-unsplash.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  @media (max-width: ${config.UI.drawer_breakpoint.max}) {
    padding: 2rem;
  }
`
export const InnerHeroContainer = styled.div`
  display: block;
  max-width: 80%;
  margin: 0 auto;
  text-align: left;
  box-sizing: border-box;
  padding: 5rem 0 1rem;
  /** Mobile Display */
  @media (max-width: ${config.UI.drawer_breakpoint.max}) {
    max-width: 100%;
    padding: 0.5rem 0;
    margin: 0;
    text-align: center;
  }
`

export const ContentContainer = styled.div`
  display: inline-block;
  vertical-align: top;
  text-align: left;
  width: 55%;
  max-width: 60%;

  @media (max-width: ${config.UI.tablet_breakpoint.max}) {
    max-width: 100%;
    width: 100%;
    text-align: center;
  }
`

export const Title = styled.h1`
  display: block;
  margin-bottom: 1.5rem;
  color: ${({ theme }) => theme.palette.common.white};
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-size: 2.8rem;
  font-weight: ${({ theme }) => theme.typography.fontWeight.bold};

  /** Mobile Display */
  @media (max-width: ${config.UI.drawer_breakpoint.max}) {
    text-align: center;
    font-size: 2rem;
  }
  @media (max-width: ${config.UI.mobile_breakpoint.max}) {
    text-align: center;
    font-size: 1.25rem;
  }
`
export const TabContainer = styled.div`
  display: flex;
  align-items: center;
  min-height: 4.1rem;

  /** Customize scrollbar in Chrome, Safari and Edge */
  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar-track {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    display: none;
  }
  @media (max-width: ${config.UI.tablet_breakpoint.max}) {
    justify-content: center;
  }
`

export const Tab = styled.div`
  user-select: none;
  background: ${({ theme }) => theme.palette.secondary.light};
  padding: ${(props) => (props.selected ? '1.2rem 4rem' : '0.8rem 1.5rem')};
  text-align: center;
  display: inline-block;
  margin-right: 0.25rem;
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-size: ${({ theme, selected }) =>
    selected ? theme.typography.fontSizes.regular : theme.typography.fontSizes.regular};
  font-weight: ${({ theme, selected }) =>
    selected ? theme.typography.fontWeight.semiBold : theme.typography.fontWeight.medium};
  color: ${({ theme }) => theme.typography.fontColor.bodyCopy};
  cursor: pointer;
  position: relative;
  transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);

  &#bug {
    margin-right: ${(props) => (props.selected ? '1rem' : '0.25rem')};
    transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  &#suggestion {
    margin-left: ${(props) => (props.selected ? '1rem' : '0.25rem')};
    transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  /** Mobile Focused Padding Dimensions */
  @media (max-width: ${config.UI.drawer_breakpoint.max}) {
    font-size: ${({ theme }) => theme.typography.fontSizes.small};
    width: 8rem;
    padding: ${(props) => (props.selected ? '1rem 1rem' : '0.75rem 1rem')};
  }

  @media (max-width: ${config.UI.mobile_breakpoint.max}) {
    display: grid;
    align-items: center;
    width: 10rem;
    padding: ${({ selected }) => (selected ? '1rem .5rem' : '0.5rem 0.3rem')};
    height: 1.5rem;
    font-size: ${({ theme }) => theme.typography.fontSizes.smaller};

    &#bug {
      margin-right: ${(props) => (props.selected ? '0.5rem' : '0.25rem')};
    }

    &#suggestion {
      margin-left: ${(props) => (props.selected ? '0.5rem' : '0.25rem')};
    }
  }

  @media (max-width: ${config.UI.smallMobile_breakpoint.max}) {
    width: 50%;
  }
`

export const InputContainer = styled.div`
  display: block;
  margin-top: 1.5rem;
`

export const Input = styled.input`
  outline: none;
  border: none;
  background-color: ${({ theme }) => theme.palette.background.midGray};
  display: block;
  width: 52.5%;
  padding: 1.125rem 1.5rem;
  box-sizing: border-box;
  line-height: ${({ theme }) => theme.typography.lineHeight};
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-size: ${({ theme }) => theme.typography.fontSizes.regular};
  font-weight: ${({ theme }) => theme.typography.fontWeight.regular};
  color: ${({ theme }) => theme.typography.fontColor.bodyCopy};
  box-shadow: 0px 7px 5px -2px rgba(130, 130, 130, 0.1);

  @media (max-width: ${config.UI.mobile_breakpoint.max}) {
    width: 100%;
    font-size: ${({ theme }) => theme.typography.fontSizes.small};
  }
`

export const Textarea = styled.textarea`
  outline: none;
  border: none;
  background-color: ${({ theme }) => theme.palette.background.midGray};
  display: block;
  width: 100%;
  height: 9rem;
  margin-top: 1rem;
  padding: 1.5rem 1.5rem;
  box-sizing: border-box;
  line-height: ${({ theme }) => theme.typography.lineHeight};
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-size: ${({ theme }) => theme.typography.fontSizes.regular};
  font-weight: ${({ theme }) => theme.typography.fontWeight.regular};
  color: ${({ theme }) => theme.typography.fontColor.bodyCopy};
  box-shadow: 0px 7px 5px -2px rgba(130, 130, 130, 0.1);
  resize: none;
  @media (max-width: ${config.UI.mobile_breakpoint.max}) {
    font-size: ${({ theme }) => theme.typography.fontSizes.small};
  }
`

export const VideosHint = styled.h3`
  display: block;
  margin-bottom: 1.5rem;
  margin-top: 8.5rem;
  color: ${({ theme }) => theme.palette.primary.main};
  box-sizing: border-box;
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-size: ${({ theme }) => theme.typography.fontSizes.tall};
  font-weight: ${({ theme }) => theme.typography.fontWeight.medium};

  & a {
    display: inline-block;
    text-decoration: underline;
    cursor: pointer;
    color: inherit;
    &:link,
    &:visited,
    &:active {
      color: inherit;
      outline: none;
    }
  }

  @media (max-width: ${config.UI.drawer_breakpoint.max}) {
    text-align: center;
    margin-top: 2rem;
  }
`

export const ButtonContainer = styled.div`
  width: 100%;
  margin-top: 1rem;
  position: relative;
`
