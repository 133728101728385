import styled from 'styled-components'
// import config from "../../../../../../config.js"

export const MainContainer = styled.div`
  background-color: white;
  display: block;
  padding: 2rem;
  margin-bottom: 1rem;
  border-radius: 0.3rem;
  box-sizing: border-box;
  box-shadow: 0px 5px 5px -2px rgba(0, 0, 0, 0.2);
`

export const InnerContainer = styled.div`
  display: block;
  min-height: 6rem;
`

export const LeftContainer = styled.div`
  display: block;
  width: 6rem;
  float: ${({ float }) => float};
  margin: ${({ margin }) => margin};
  text-align: center;
`

export const UserImage = styled.div`
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: inline-block;
  width: 5.8rem;
  height: 5.8rem;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  border: 0.2rem solid;
  border-color: ${({ theme, verified }) => (verified === 1 ? theme.palette.primary.main : '#ccc')};
`

export const IconContainer = styled.div`
  background-color: ${(props) => props.theme.palette.primary.main};
  display: block;
  width: 2.1rem;
  height: 2.1rem;
  border-radius: 50%;
  position: absolute;
  top: -0.3rem;
  left: -0.3rem;
  cursor: pointer;

  & > span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-51%);
    color: ${(props) => props.theme.palette.text.primary};
    font-size: 1.2rem;
  }

  &:hover {
    background-color: ${(props) => props.theme.palette.primary.light};
  }
`

export const UserName = styled.p`
  display: inline-block;
  margin-top: 0.8rem;
  text-align: center;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.small};
  font-weight: ${(props) => props.theme.typography.fontWeight.semiBold};
  color: ${(props) => props.theme.typography.fontColor.bodyCopy};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`

export const ContentContainer = styled.div`
  display: block;
  overflow: hidden;
  margin: ${({ margin }) => margin};
`

export const Header = styled.p`
  text-align: ${({ textAlign }) => textAlign};
  line-height: 1.6rem;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.larger};
  font-weight: ${(props) => props.theme.typography.fontWeight.bold};
  color: ${(props) => props.theme.typography.fontColor.bodyCopy};
`

export const Subheader = styled.p`
  margin-top: 1rem;
  text-align: ${({ textAlign }) => textAlign};
  line-height: 1.3rem;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.short};
  font-weight: ${(props) => props.theme.typography.fontWeight.medium};
  color: ${(props) => props.theme.typography.fontColor.bodyCopy};

  & > span {
    font-family: ${(props) => props.theme.typography.fontFamily};
    font-size: ${(props) => props.theme.typography.fontSizes.short};
    font-weight: ${(props) => props.theme.typography.fontWeight.bold};
    color: ${(props) => props.theme.palette.secondary.main};
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
`

export const ButtonContainer = styled.div`
  ${'' /* margin-top: 1.5rem; */}
  text-align: ${({ textAlign }) => textAlign};
`
