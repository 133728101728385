//////////////////
// ACTION TYPES //
//////////////////
export const loadedDiscussionActionTypes = {
    SET_LOADED_DISCUSSIONS: 'SET_LOADED_DISCUSSIONS',
    ADD_LOADED_DISCUSSIONS: 'ADD_LOADED_DISCUSSIONS',
    UPDATE_LOADED_DISCUSSION: 'UPDATE_LOADED_DISCUSSION',
    ACCEPT_LOADED_DISCUSSION: 'ACCEPT_LOADED_DISCUSSION',
    COUNTER_LOADED_DISCUSSION: 'COUNTER_LOADED_DISCUSSION',
    DECLINE_LOADED_DISCUSSION: 'DECLINE_LOADED_DISCUSSION',
    END_LOADED_DISCUSSION: 'END_LOADED_DISCUSSION',
    ADD_LOADED_DISCUSSION_RATING: 'ADD_LOADED_DISCUSSION_RATING',
    CLEAR_LOADED_DISCUSSIONS: 'CLEAR_LOADED_DISCUSSIONS'
}

/////////////
// ACTIONS //
/////////////

// DISCUSSIONS
const setDiscussions = discussions => {
    return {
        type: loadedDiscussionActionTypes.SET_LOADED_DISCUSSIONS,
        discussions
    }
}

const addDiscussions = discussions => {
    return {
        type: loadedDiscussionActionTypes.ADD_LOADED_DISCUSSIONS,
        discussions
    }
}

const update = (discussionId, fieldsToUpdate) => {
    return {
        type: loadedDiscussionActionTypes.UPDATE_LOADED_DISCUSSION,
        discussionId, 
        fieldsToUpdate
    }
}

const acceptDiscussion = discussionId => {
    return {
        type: loadedDiscussionActionTypes.ACCEPT_LOADED_DISCUSSION,
        discussionId
    }
}

const counterDiscussion = discussionId => {
    return {
        type: loadedDiscussionActionTypes.COUNTER_LOADED_DISCUSSION,
        discussionId
    }
}

const declineDiscussion = discussionId => {
    return {
        type: loadedDiscussionActionTypes.DECLINE_LOADED_DISCUSSION,
        discussionId
    }
}

const endDiscussion = params => {
    return {
        type: loadedDiscussionActionTypes.END_LOADED_DISCUSSION,
        discussionId: params.discussionId,
        endedBy: params.endedBy
    }
}

const addRating = params => {
    return {
        type: loadedDiscussionActionTypes.ADD_LOADED_DISCUSSION_RATING,
        discussionId: params.discussionId,
        userId: params.userId,
        ratingId: params.ratingId
    }
}

const clearDiscussions = () => {
    return {
        type: loadedDiscussionActionTypes.CLEAR_LOADED_DISCUSSIONS
    }
}

export const loadedDiscussionsActions = {
    setDiscussions,
    addDiscussions,
    update,
    acceptDiscussion,
    counterDiscussion,
    declineDiscussion,
    endDiscussion,
    addRating,
    clearDiscussions
}
