import styled, { keyframes } from 'styled-components'
import config from '../../../../../../config.js'

export const MainContainer = styled.div`
  background-color: ${(props) => props.theme.palette.secondary.main}; // browser fallback
  background-image: url('https://res.cloudinary.com/headliner/image/upload/v1679697318/LandingPage/duy-pham-Cecb0_8Hx-o-unsplash_1.jpg'); // browser fallback for non-stacking
  background-image: linear-gradient(90deg, rgba(51, 51, 51, 0.8) 0%, rgba(51, 51, 51, 0.8) 100%),
    url('https://res.cloudinary.com/headliner/image/upload/v1679697318/LandingPage/duy-pham-Cecb0_8Hx-o-unsplash_1.jpg');

  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  position: relative;
  margin: 0 0.5rem 1rem 0.5rem;
  padding: 2rem 2.5rem;
  align-items: center;
  border-radius: 0.3rem;
  box-sizing: border-box;
  min-height: 19rem;

  @media (max-width: ${config.UI.drawer_breakpoint.max}) {
    display: block;
  }
`
/** Avatar comps */
export const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover; /** covers IE support */
  object-fit: cover;
  width: 12rem;
  height: 12rem;
  border-radius: 50%;
  border: 4px solid;
  border-color: ${(props) => (props.verified ? props.theme.palette.primary.main : '#ccc')};
  background-color: ${(props) => props.theme.palette.common.white};

  @media (max-width: 600px) {
    width: 10rem;
    height: 10rem;
  }
`

const LoadingAnimation = keyframes`
  0% {
    background-color: #fff;
  }
  50% {
    background-color: #ccc;
  }
  100% {
    background-color: #fff;
  }
`

export const Placeholder = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  animation: ${LoadingAnimation} 1s infinite;
  border-radius: 50%;
  border: 4px solid;
  background-color: ${(props) => props.theme.palette.common.white};
`

export const ImageSkeletonPlaceholder = styled.div`
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: inline-block;
  width: 12.5rem;
  height: 12.5rem;
  margin-right: 2.5rem;
  /* border: 4px solid #00aeef; */
  border-radius: 50%;
  vertical-align: middle;
  position: relative;

  @media (max-width: ${config.UI.drawer_breakpoint.max}) {
    display: block;
    margin: 0 auto;
  }

  @media (max-width: 600px) {
    width: 10rem;
    height: 10rem;
  }
`

export const DetailsContainer = styled.div`
  display: inline-block;
  ${'' /* margin-top: -0.5rem; */}
  vertical-align: middle;
  /* Firefox */
  width: -moz-calc(100% - 15rem);
  /* WebKit */
  width: -webkit-calc(100% - 15rem);
  /* Opera */
  width: -o-calc(100% - 15rem);
  /* Standard */
  width: calc(100% - 15rem);

  @media (max-width: ${config.UI.drawer_breakpoint.max}) {
    display: block;
    width: 100%;
    margin-top: 0.5rem;

    & .user-accolades {
      text-align: center;
      margin-top: 1rem !important;
    }
  }
`

export const Name = styled.h1`
  font-family: ${(props) => props.theme.typography.fontFamily};
  line-height: 1.8rem;
  font-size: 2rem;
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  color: ${(props) => props.theme.palette.common.white};

  @media (max-width: ${config.UI.drawer_breakpoint.max}) {
    font-size: 1.75rem;
    text-align: center;
    margin-top: 1.25rem;
  }
`

export const Location = styled.h2`
  margin-top: 0.5rem;
  line-height: ${(props) => props.theme.typography.lineHeight};
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.short};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  color: ${(props) => props.theme.palette.common.white};

  @media (max-width: ${config.UI.drawer_breakpoint.max}) {
    font-size: ${(props) => props.theme.typography.fontSizes.short};
    font-weight: ${(props) => props.theme.typography.fontWeight.light};
    text-align: center;
  }
`

export const About = styled.h2`
  margin-top: 1.5rem;
`

export const Status = styled.h4`
  line-height: ${(props) => props.theme.typography.lineHeight};
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: 0.9rem;
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  color: #ffffff;
  margin-bottom: 1.3rem;

  @media (max-width: ${config.UI.drawer_breakpoint.max}) {
    display: none;
  }
`

export const Knowledge = styled.div`
  margin-top: 1.5rem;
  min-height: 2.3rem;
  line-height: ${(props) => props.theme.typography.lineHeight};
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.short};
  font-weight: ${(props) => props.theme.typography.fontWeight.light};
  color: ${(props) => props.theme.palette.common.white};
  font-size: 0;

  & > p {
    line-height: 1.7rem;
    font-size: ${(props) => props.theme.typography.fontSizes.short};
  }

  @media (max-width: ${config.UI.drawer_breakpoint.max}) {
    text-align: center;
  }
`

export const DropDownButtonContainer = styled.div`
  display: block;
`

export const NavItemContainer = styled.div`
  display: block;
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: 0;

  & span.material-icons {
    font-size: 1.2rem;
    height: auto;
    margin-right: 0.7rem;
    margin-top: 1px;
    vertical-align: middle;
  }
`

export const NavItem = styled.span`
  display: inline-block;
  cursor: pointer;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  text-transform: capitalize;
  vertical-align: middle;
  color: ${(props) => props.theme.palette.text.primary};

  &:hover {
    text-decoration: underline;
  }
`

/** Follow Button */

export const PersonAddSpan = styled.span`
  font-size: 1.5rem;

  @media (max-width: 900px) {
    font-size: 1.35rem;
  }
`

/** Report Button */
export const ReportSpan = styled.span`
  font-size: 1.5rem;

  @media (max-width: 900px) {
    font-size: 1.35rem;
  }
`
