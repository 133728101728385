import { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CourseMemberCard from '../../course-setup/components/course-member-card'
import useLoadMembers from '../hooks/use-load-members'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListSubheader from '@mui/material/ListSubheader'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'

// TABLE Components
import * as React from 'react'
import PropTypes from 'prop-types'
import { alpha } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Toolbar from '@mui/material/Toolbar'
import TableFooter from '@mui/material/TableFooter'
import Checkbox from '@mui/material/Checkbox'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import DeleteIcon from '@mui/icons-material/Delete'
import FilterListIcon from '@mui/icons-material/FilterList'
import { visuallyHidden } from '@mui/utils'
import { create } from 'lodash'

function createData(id, fname, lname, email) {
  return {
    id,
    fname,
    lname,
    email
  }
}

function ManageMemberships() {
  // set orgId for different development environments before release to production
  const organizationId =
    import.meta.env.VITE_NODE_ENV === 'production'
      ? '65a587332e5f8e0035efe4a4'
      : '658081a4ce604400356103ba'

  const [members, setMembers] = useLoadMembers(organizationId, createData)
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(5)

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - members.length) : 0

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  console.log('members: ', members)
  return (
    <MuiPaperOuter>
      <Typography
        variant="h4"
        sx={{
          backgroundColor: 'hsl(131, 25%, 75%)',
          padding: '1rem 2rem',
          borderRadius: '0.25rem 0.25rem 0 0',
          fontSize: '1.5rem',
          fontWeight: '500'
        }}>
        Manage Memberships
      </Typography>

      <MuiPaperInner>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="members table">
            <TableHead>
              <TableRow>
                <TableCell>First Name</TableCell>
                <TableCell>Last Name</TableCell>
                <TableCell>Email</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? members.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : members
              ).map((row) => (
                <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    {row.fname}
                  </TableCell>
                  <TableCell>{row.lname}</TableCell>
                  <TableCell>{row.email}</TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                  colSpan={3}
                  count={members.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  slotProps={{
                    select: {
                      inputProps: {
                        'aria-label': 'rows per page'
                      },
                      native: true
                    }
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  // ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </MuiPaperInner>
    </MuiPaperOuter>
  )
}

export default ManageMemberships

// ***************************** //
// *** MUI Styled Components *** //
// ***************************** //

const MuiPaperOuter = styled(Paper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  // margin: '1rem 0',
  // padding: '2rem',
  borderRadius: '0.25rem',
  background: '#fff',
  minHeight: '40rem'
}))

const MuiPaperInner = styled(Paper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
  padding: '2rem',
  borderRadius: '0.25rem',
  maxHeight: '40rem',
  overflowY: 'auto',
  overflowX: 'hidden'
}))

const MuiList = styled(List)(({ theme }) => ({
  border: '1px solid',
  borderColor: theme.palette.secondary.main,
  width: '100%',
  padding: 0,
  borderRadius: '0.25rem',
  minHeight: '21.5rem',
  backgroundColor: theme.palette.background.gray
}))

const MuiListSubheader = styled(ListSubheader)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '1.25rem',
  backgroundColor: theme.palette.secondary.light,
  minWidth: '18rem',
  borderRadius: '0.25rem 0.25rem 0 0'
}))

const MuiListItem = styled(ListItem)(({ theme }) => ({
  '&.MuiButtonBase-root': {
    backgroundColor: theme.palette.common.white
  }
}))

const MuiAddButton = styled(Button)(({ theme }) => ({
  // borderRadius: '0.25rem'
}))
