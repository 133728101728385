import { SubmitButton } from './mui-buttons'
import {
  SectionContainer,
  Header,
  SubHeader,
  Form,
  ItemContainer,
  AddressLabel,
  AddressCopy,
  ButtonContainer
} from '../styled/location-form-comps'

function CurrentLocation(props) {
  const { city, county, state, zipCode, handleStep1 } = props

  return (
    <>
      <Header>Do you want to update your location?</Header>
      <SubHeader>Here's what we have right now.</SubHeader>

      <Form>
        <SectionContainer>
          <ItemContainer>
            <AddressLabel>City:</AddressLabel>
            <AddressCopy>{city}</AddressCopy>
          </ItemContainer>
          <ItemContainer>
            <AddressLabel>County:</AddressLabel>
            <AddressCopy>{county}</AddressCopy>
          </ItemContainer>
          <ItemContainer>
            <AddressLabel>State:</AddressLabel>
            <AddressCopy>{state}</AddressCopy>
          </ItemContainer>
          <ItemContainer>
            <AddressLabel>Zip Code:</AddressLabel>
            <AddressCopy>{zipCode}</AddressCopy>
          </ItemContainer>
        </SectionContainer>
        <ButtonContainer>
          <SubmitButton color="primary" variant="contained" onClick={handleStep1}>
            Yes, Update
          </SubmitButton>
        </ButtonContainer>
      </Form>
    </>
  )
}

export default CurrentLocation
