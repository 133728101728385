import { transActionHistoyActionTypes } from '../action-creators/organization-transaction-history'

const OrganizationtransActionHistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case transActionHistoyActionTypes.SET_ORGANIZATIONS_HISTORY:
      const newState = action?.payload.transactions
      const newAfterDate = newState?.at(-1)
      return {
        ...state,
        history: action.payload.transactions,
        afterDate: newAfterDate?.whenCreated,
        totalRecords: action.payload.totalRecords
      }

    case transActionHistoyActionTypes.UPDATE_ORGANIZATIONS_HISTORY_BY_ID:
      const updatehistory = state?.history?.map((history) => {
        return history?._id === action?.payload?._id ? action?.payload : history
      })

      return { ...state, history: [...updatehistory] }

    case transActionHistoyActionTypes.ADD_ORGANIZATIONS_HISTORY:
      const newHistory = action.payload
      const newAfterDateHistory = newHistory.at(-1)

      return {
        ...state,
        history: [...state.history, ...action.payload],
        afterDate: newAfterDateHistory?.whenCreated
      }

    case transActionHistoyActionTypes.UPDATE_ORGANIZATIONS_HISTORY_BY_PUBLIC_ID_WITH_DRAWAL:
      const updatehistory_withdrawal = state?.history?.map((history) => {
        return history?._id === action.payload?._id
          ? { ...history, name_from: 'You', name_to: 'CivilTalk' }
          : history
      })
      return { ...state, history: [...updatehistory_withdrawal] }

    case transActionHistoyActionTypes.SET_ORGANIZATIONS_ROWS_PER_PAGE:
      return { ...state, rowsPerPage: action.payload }

    case transActionHistoyActionTypes.SET_ORGANIZATIONS_PAGE:
      return { ...state, page: action.payload }

    case transActionHistoyActionTypes.ORGANIZATIONS_LOADING:
      return { ...state, loading: action.payload }

    case transActionHistoyActionTypes.CLEAR_ORGANIZATIONS_TRANSACTION:
      return { ...state, history: [], page: 0, rowsPerPage: 5 }

    default:
      return state
  }
}

export default OrganizationtransActionHistoryReducer

const initialState = {
  history: [],
  rowsPerPage: 5,
  page: 0,
  loading: false,
  afterDate: '',
  totalRecords: 0
}
