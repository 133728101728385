import styled from 'styled-components'

export const MainContainer = styled.div`
  background-color: ${(props) => props.theme.palette.common.white};
  display: block;
  margin-bottom: 1rem;
  padding: 1.7rem 2rem 2rem 2rem;
  border-radius: 0.3rem;
  box-shadow: 0px 5px 5px -2px rgba(0, 0, 0, 0.2);
  border: 1px solid #dcdddd;
`

export const HeadingPrimary = styled.p`
  line-height: 2.2rem;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: 1.6rem;
  font-weight: ${(props) => props.theme.typography.fontWeight.bold};
  color: ${(props) => props.theme.palette.text.primary};
`

export const Subheading = styled.p`
  margin-top: 0;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.regular};
  font-weight: ${(props) => props.theme.typography.fontWeight.medium};
  color: ${(props) => props.theme.typography.fontColor.headerCopy};
`
