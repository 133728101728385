import config from '../../../config'
import { organizationsActions } from '../../action-creators/organizations.js'
import { notificationOrganizationsActions } from '../../action-creators/notification-organizations.js'
import { authenticatedFetch } from '../../../utils/functions/authenticatedFetch'

/*
 * METHOD TO GET ORGANIZATIONs BY IDs
 */
export const getOrganizationsByIDs = (params = {}, options = {}) => {
  let responseCode = ''
  const method = 'GET'
  const url = `${config.baseURL}/api/organization/${JSON.stringify(params.organizationIds)}`
  const headers = {
    'Content-Type': 'application/json'
  }

  // Thunk middleware knows how to handle functions.
  // It passes the dispatch method as an argument to the function,
  // thus making it able to dispatch actions itself.
  return (dispatch) => {
    console.log('Sending get organizations by IDs request to CivilTalk')

    // Return the fetch so react components calling 'store.dispatch()' can use 'then()'
    return authenticatedFetch(url, {
      method,
      headers,
      credentials: 'include'
    })
      .then((response) => {
        responseCode = response.status

        return response.json()
      })
      .then((data) => {
        if (responseCode === 200) {
          if (!options.disableRedux) {
            if (params.type === 'notifications') {
              if (params.isLoadingPage) {
                dispatch(notificationOrganizationsActions.set(data.organizations))
              } else {
                dispatch(notificationOrganizationsActions.add(data.organizations))
              }
            } else {
              if (params.isLoadingPage) {
                dispatch(organizationsActions.set(data.organizations))
              } else {
                dispatch(organizationsActions.add(data.organizations))
              }
            }
          }

          return {
            error: false,
            code: responseCode,
            message: data.message,
            organizations: data.organizations
          }
        } else {
          console.log(data.message)

          return {
            error: true,
            code: responseCode,
            message: data.message
          }
        }
      })
      .catch((error) => {
        console.log(error)

        return {
          error: true,
          code: '',
          message: error
        }
      })
  }
}
