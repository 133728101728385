const SupportValidation = (state) => {
	// Type
  if (state.type === '') {
  	return {
    	error: 'Please select a feedback category'
  	}
  }

  // Subject
  if (state.subject === '') {
  	return {
    	error: 'Please enter a subject'
  	}
  }   

  // Description
  if (state.desc === '') {
  	return {
    	error: 'Please enter a description'
  	}
  } 

  return {
  	error: ''
  }
}

export default SupportValidation