import config from '../../../config'
import { authenticatedFetch } from '../../../utils/functions/authenticatedFetch'

export const getCoursesByOrgID = (params) => {
  const { organizationId } = params

  const method = 'GET'
  const headers = {
    'Content-Type': 'application/json'
  }
  const url = `${config.baseURL}/api/course/organization/${organizationId}`

  const fetchOptions = {
    method,
    headers,
    credentials: 'include'
  }

  return async (dispatch) => {
    console.log('Sending get courses by organization ID request to CivilTalk')

    try {
      const response = await authenticatedFetch(url, fetchOptions)

      const data = await response.json()

      if (response.status === 200) {
        return {
          error: false,
          code: response.status,
          message: data.message,
          courses: data.courses
        }
      } else {
        console.log('Request failed with status:', response.status)
        return {
          error: true,
          code: response.status,
          message: data.message
        }
      }
      // save in case we want to add courses to the redux store later
      // dispatch(setCourses(response.data))
    } catch (error) {
      console.error('Error getting courses by organization ID:', error)

      return {
        error: true,
        code: '500',
        message: error.message || error
      }
    }
  }
}
