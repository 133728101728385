//////////////////
// ACTION TYPES //
//////////////////

export const organizationPostActionTypes = {
    SET_ORGANIZATION_POST: 'SET_ORGANIZATION_POST',
  CLEAR_ORGANIZATION_POSTS: 'CLEAR_ORGANIZATION_POSTS'
}

/////////////
// ACTIONS //
/////////////

// CURRENT ORGANIZATION
const set = (payload) => {
  return {
    type: organizationPostActionTypes.SET_ORGANIZATION_POST,
    payload
  }
}

const clear = () => {
  return {
    type: organizationPostActionTypes.CLEAR_ORGANIZATION_POSTS
  }
}

export const organizationPostActions = {
  set,
  clear
}
