import styled from 'styled-components'
import config from '../../../../../../config.js'

export const MainContainer = styled.div``

export const HR = styled.hr`
  border: 0;
  border-top: 1.5px solid rgba(48, 48, 48, 0.1);
  display: block;
  height: 1px;
`

export const InnerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 3rem;
`

export const CourseContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem;
  border: 1px solid ${(props) => props.theme.palette.background.mediumGray};
  border-radius: 0.3rem;
  box-shadow: ${(props) => props.theme.boxShadow};
`

export const Course = styled.span`
  color: ${(props) => props.theme.palette.text.primary};
  line-height: ${(props) => props.theme.typography.lineHeight};
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.small};
  font-weight: ${(props) => props.theme.typography.fontWeight.semiBold};
`

export const FlexStartPlaceholder = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

export const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const DiscussionWidget = styled.div`
  display: flex;
  margin-right: 0.8rem;
  justify-content: flex-start;
  align-items: center;
  color: ${(props) => props.theme.typography.fontColor.bodyCopy};
  line-height: ${(props) => props.theme.typography.lineHeight};
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.regular};
  font-weight: ${(props) => props.theme.typography.fontWeight.semiBold};
`

export const Count = styled.div`
  color: ${(props) => props.theme.palette.text.primary};
  line-height: ${(props) => props.theme.typography.lineHeight};
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.regular};
  font-weight: ${(props) => props.theme.typography.fontWeight.semiBold};

  @media (max-width: ${config.UI.drawer_breakpoint.max}) {
    font-size: 0.875rem;
  }
`

export const LikesWidget = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: ${(props) => props.theme.typography.fontColor.bodyCopy};
  line-height: ${(props) => props.theme.typography.lineHeight};
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.regular};
  font-weight: ${(props) => props.theme.typography.fontWeight.semiBold};
`
