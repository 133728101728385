import _ from 'lodash'

export const filterInboxItems = (discussions, currentUser) => {
  const { id } = currentUser
  const inboxItems = _.filter(discussions, (discussion) => {
    const {
      accepted,
      complete,
      guestUserId,
      hostUserId,
      isCounter,
      isRequest,
      lastRebuttalUserId
    } = discussion

    const isIncomingRequest = isRequest && hostUserId === id

    const isIncomingCounter = isCounter && guestUserId === id

    const isUsersTurn = accepted && !complete && lastRebuttalUserId !== id

    const isComplete = complete

    return isIncomingRequest || isIncomingCounter || isUsersTurn || isComplete
  })

  return inboxItems
}

export const filterSentItems = (discussions, currentUser) => {
  const { id } = currentUser
  const sentItems = _.filter(discussions, (discussion) => {
    const {
      accepted,
      complete,
      guestUserId,
      hostUserId,
      isCounter,
      isRequest,
      lastRebuttalUserId
    } = discussion

    const isOutgoingRequest = isRequest && guestUserId === id

    const isOutgoingCounter = isCounter && hostUserId === id

    const isOtherUsersTurn = accepted && !complete && lastRebuttalUserId === id

    // Todo:  test this one
    const isNotComplete = complete && !complete

    return isOutgoingRequest || isOutgoingCounter || isOtherUsersTurn || isNotComplete
  })

  return sentItems
}
