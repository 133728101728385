import React, { useEffect, useState } from 'react'
import withStyles from '@mui/styles/withStyles'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import InputBase from '@mui/material/InputBase'
import MenuItem from '@mui/material/MenuItem'
import config from '../../../../../../config'

const StyledInputLabel = withStyles((theme) => ({
  root: {
    fontSize: '1rem'
  },
  shrink: {
    display: 'none'
  }
}))(InputLabel)

const StyledInput = withStyles((theme) => ({
  root: {
    backgroundColor: 'rgba(0, 0, 0, 0)',
    padding: '0.3rem 0.6rem',
    width: '15rem',
    marginTop: '0',
    verticalAlign: 'middle',
    border: '1px solid #ddd',
    borderRadius: '0.2rem',
    boxSizing: 'border-box',
    boxShadow: '0px 9px 7px -2px rgba(120, 120, 120, 0.1)',
    fontSize: '1rem',
    fontWeight: 400,
    [`@media(max-width: ${config.UI.drawer_breakpoint.max})`]: {
      width: '100%'
    }
  }
}))(InputBase)

// const useStyles = makeStyles((theme) => ({}))

function MuiSelectWrapper({ selectedItem, items, handleUpdate, labelId, iD }) {
  const [item, setItem] = useState('')
  const [open, setOpen] = useState(false)
  // const classes = useStyles()

  // Initial Data Load
  useEffect(() => {
    setItem(selectedItem !== '' ? selectedItem : '')
  }, [selectedItem])

  const handleChange = (e) => {
    setItem(e.target.value)
    handleUpdate(e.target.value)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  return (
    <FormControl variant="outlined" style={{ width: '100%' }}>
      <StyledInputLabel id="state-label">Enter your state</StyledInputLabel>
      <Select
        labelId={labelId}
        id={iD}
        value={item}
        onClose={handleClose}
        onOpen={handleOpen}
        onChange={handleChange}
        open={open}
        // label="State"
        input={<StyledInput />}>
        <MenuItem key={'no-input'} value={''}>
          --
        </MenuItem>

        {items.map((item) => {
          return (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}

export default MuiSelectWrapper
