import config from './config.js'

let DEBUG = config.debug

// Load local storage
export const loadPersistedStorage = () => {
  try {
    // DEBUG local storage
    // DEBUG && console.log("[LOCAL STORAGE]: ", JSON.parse(localStorage.getItem('civiltalkState')))

    const serializedState = localStorage.getItem('civiltalkState')

    if (serializedState === {} || serializedState === null) {
      return undefined
    }

    return JSON.parse(serializedState)
  } catch (error) {
    DEBUG && console.log(error)

    return undefined
  }
}

// Save auth info to local storage
export const saveToLocalStorage = (state) => {
  try {
    const serializedState = JSON.stringify(state)

    localStorage.setItem('civiltalkState', serializedState)

    // DEBUG local storage
    // DEBUG && console.log("[LOCAL STORAGE]: ", JSON.parse(localStorage.getItem('civiltalkState')))
  } catch (error) {
    DEBUG && console.log('[ERROR]: ', 'Local storage write error: ' + error)
  }
}

export const clearLocalStorage = () => {
  const localState = JSON.parse(localStorage.getItem('civiltalkState'))
  let state = {}

  if (typeof localState === 'undefined' || localState === null || localState === {}) {
    state = {
      testToken: ''
    }
  } else {
    let testToken = JSON.parse(localStorage.getItem('civiltalkState')).testToken

    state = {
      testToken: typeof testToken === 'undefined' ? '' : testToken
    }
  }

  try {
    const serializedState = JSON.stringify(state)

    localStorage.setItem('civiltalkState', serializedState)

    // DEBUG local storage
    // DEBUG && console.log("[LOCAL STORAGE]: ", JSON.parse(localStorage.getItem('civiltalkState')))
  } catch (error) {
    DEBUG && console.log('[ERROR]: ', 'Local storage write error: ' + error)
  }
}
