import { createUserFollow } from '../../../../../../redux/thunks/follows/createUserFollow'
import getName from '../../../../../../utils/functions/getName'

const followUser = (profile, setProcessing, dispatch) => {
  if (!profile) {
    throw new Error('Invalid params <profile> in followUser')
  }

  if (!setProcessing) {
    throw new Error('Invalid params <setProcessing> in followUser')
  }

  if (!dispatch) {
    throw new Error('Invalid params <dispatch> in followUser')
  }

  const { userId } = profile
  setProcessing(true)

  dispatch(
    createUserFollow({
      userId,
      name: getName(profile)
    })
  )
    .then((data) => {
      if (!data.error) {
        console.log('[SUCCESS]: ', 'Successfully followed user!')
      } else {
        console.log('[FAIL]: ', 'Failed to follow user.')
      }

      setProcessing(false)
    })
    .catch((e) => {
      setProcessing(false)
    })
}

export default followUser
