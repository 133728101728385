import config from '../../../config'
import { authenticatedFetch } from '../../../utils/functions/authenticatedFetch'

export const createOrganizationRole = (params = {}) => {
  let responseCode = ''
  let url = `${config.baseURL}/api/role/organization`
  const method = 'POST'
  let headers = {
    'Content-Type': 'application/json'
  }
  const requestBody = {
    role: params.role
  }
  const requestBodyJSON = JSON.stringify(requestBody)

  return (dispatch) => {
    console.log('Sending create organization role request to CivilTalk')

    return authenticatedFetch(url, {
      method,
      body: requestBodyJSON,
      headers,
      credentials: 'include'
    })
      .then((response) => {
        responseCode = response.status

        return response.json()
      })
      .then((data) => {
        if (responseCode === 201) {
          return {
            error: false,
            code: responseCode,
            message: data.message,
            role: data.role
          }
        }

        console.log(data.message)

        return {
          error: true,
          code: responseCode,
          message: data.message
        }
      })
      .catch((error) => {
        console.log(error)

        return {
          error: true,
          code: '',
          message: error
        }
      })
  }
}
