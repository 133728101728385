import React from 'react'
import styled from 'styled-components'
import Gallery from './components/gallery'

const GalleryContainer = styled.div`
  background-color: #f2f2f2;
  margin-bottom: 1rem;
  border-radius: 0.2rem;
`

const MediaGallery = (props) => {
  const { style, media, editable, handleRemoveMedia } = props
  let styles = style ? style : {}

  if (media.length === 0) return null

  return (
    <GalleryContainer style={styles}>
      <Gallery media={media} editable={editable} handleRemoveMedia={handleRemoveMedia} />
    </GalleryContainer>
  )
}

export default MediaGallery
