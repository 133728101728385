import { useState, useContext } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import find from 'lodash/find'
import RolesTable from './components/roles-table/roles-table'
import EditRole from './components/edit-role/edit-role'
import AddRole from './components/add-role/add-role'
import { MainContainer, BackButton, Heading, Subheading } from './styled/role-management-comps'
import LoadOrganizationRolesContext from '../../../../data-loading/load-organization-assigned-roles/context/context'

const getHeading = (flow, roleName) => {
  if (flow === 'edit') {
    return `Edit Role - ${roleName}`
  } else if (flow === 'add') {
    return 'Add a Role'
  } else if (flow === 'view') {
    return 'Roles'
  }
}

function RoleManagement() {
  const { orgId } = useParams()
  const { roles } = useContext(LoadOrganizationRolesContext)
  const [flow, setFlow] = useState('view')
  const [selectedRole, setSelectedRole] = useState('')
  const organizations = useSelector(({ organizations }) => organizations)
  const organization = find(organizations, { _id: orgId })
  const role = find(roles, { _id: selectedRole }) || {}

  const handleBack = () => {
    setSelectedRole('')
    setFlow('view')
  }

  if (!organization) return null

  return (
    <MainContainer>
      {flow !== 'view' && <BackButton onClick={handleBack}>Back</BackButton>}

      <Heading>{getHeading(flow, role.name)}</Heading>

      {flow === 'view' && (
        <Subheading>
          Manage your organization's roles in order to assign permissions to your members.
        </Subheading>
      )}

      {flow === 'view' && <RolesTable setFlow={setFlow} setSelectedRole={setSelectedRole} />}
      {flow === 'edit' && <EditRole selectedRole={selectedRole} />}
      {flow === 'add' && <AddRole setFlow={setFlow} />}
    </MainContainer>
  )
}

export default RoleManagement
