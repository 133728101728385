// *********************************************** //
// *** TODO: DEAD CODE - remove this component *** //
// *********************************************** //

import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { analytics } from '../../../../firebase/firebase'
import CreateRebuttalValidation from '../validations/create-rebuttal'
import { createRebuttal } from '../../../../redux/thunks/rebuttals/createRebuttal'
import { endDiscussionByID } from '../../../../redux/thunks/discussions/endDiscussionByID'
import getAvatar from '../../../../utils/functions/getAvatar'
import PropTypes from 'prop-types'
import EmotionPicker from '../../emotion-picker/emotion-picker'
import withStyles from '@mui/styles/withStyles'
import { Button, CircularProgress } from '@mui/material'
import {
  FormContainer,
  TextareaProfilePicture,
  TextareaContainer,
  StyledTextarea,
  SubmitContainer
} from './styled/discussion-comps'
import { CreateFormContainer, TextCount } from './styled/create-rebuttal-form-comps'

const styles = (theme) => ({
  send: {
    display: 'inline-block',
    minHeight: '2.5rem',
    height: '2.5rem',
    width: '11rem',
    margin: '1rem 0 0 1.5rem',
    verticalAlign: 'middle',
    backgroundColor: 'theme.palette.primary.main',
    '&:hover': {
      backgroundColor: 'theme.palette.primary.light'
    },
    [theme.breakpoints.down('md')]: {
      width: '8rem'
    }
  },
  end: {
    display: 'inline-block',
    minHeight: '2.5rem',
    height: '2.5rem',
    width: '11rem',
    margin: '1rem 0 0 0',
    verticalAlign: 'middle',
    [theme.breakpoints.down('md')]: {
      width: '8rem'
    }
  },
  circularProgress: {
    display: 'block',
    width: '17px !important',
    height: '17px !important',
    margin: '.3rem auto 0 auto',
    '& svg': {
      color: 'white'
    }
  }
})

class CreateRebuttalForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      fields: {
        rebuttal: '',
        topEmotion: '',
        subEmotion: '',
        finalEmotion: ''
      },
      status: {
        processing: false
      },
      error: ''
    }

    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSubmitRebuttal = this.handleSubmitRebuttal.bind(this)
    this.handleEnd = this.handleEnd.bind(this)
    this.setTopEmotion = this.setTopEmotion.bind(this)
    this.setSubEmotion = this.setSubEmotion.bind(this)
    this.setFinalEmotion = this.setFinalEmotion.bind(this)
    this.selectedEmotion = this.selectedEmotion.bind(this)
  }

  componentDidMount() {
    if (this.props.originalRebuttal) {
      this.setState({
        ...this.state,
        fields: {
          ...this.state.fields,
          rebuttal: this.props.originalRebuttal.rebuttal
        }
      })
    }
  }

  handleInputChange(event) {
    const target = event.target
    const name = target.name

    this.setState({
      ...this.state,
      fields: {
        ...this.state.fields,
        [name]: target.value
      }
    })
  }

  handleSubmitRebuttal() {
    this.setState({ ...this.state, status: { ...this.state.status, processing: true } })

    const { error } = CreateRebuttalValidation(this.state.fields)

    // Check for validation errors and then call action to create take
    if (error !== '') {
      console.log('[VALIDATION ERROR]: ', error)

      this.setState({
        ...this.state,
        status: {
          ...this.state.status,
          processing: false
        },
        error
      })
    } else {
      const { discussion } = this.props
      const { fields } = this.state

      this.props
        .createRebuttal({
          rebuttal: fields.rebuttal,
          discussionId: discussion._id
        })
        .then((data) => {
          if (data.error) {
            console.log('[FAIL]: ', 'Failed to create rebuttal!')

            this.setState({
              ...this.state,
              status: {
                ...this.state.status,
                processing: false
              },
              error: data.message
            })
          } else {
            console.log('[SUCCESS]: ', 'Successfully created rebuttal!')

            this.setState({
              ...this.state,
              fields: {
                ...this.state.fields,
                rebuttal: ''
              },
              status: {
                ...this.state.status,
                processing: false
              },
              error: ''
            })
          }
        })
        .catch((e) => {
          this.setState({ ...this.state, status: { ...this.state.status, processing: false } })
        })
    }
  }

  setTopEmotion(emotion) {
    this.setState({ ...this.state, topEmotion: emotion })
  }

  setSubEmotion(emotion) {
    this.setState({ ...this.state, subEmotion: emotion })
  }

  setFinalEmotion(emotion) {
    this.setState({ ...this.state, finalEmotion: emotion })
  }

  selectedEmotion() {
    const { topEmotion, subEmotion, finalEmotion } = this.state

    if (finalEmotion !== '') {
      return finalEmotion
    } else if (subEmotion !== '') {
      return subEmotion
    } else if (topEmotion !== '') {
      return topEmotion
    } else {
      return ''
    }
  }

  handleEnd() {
    const { currentUser, discussion } = this.props

    this.props
      .endDiscussionByID({
        discussionId: discussion._id,
        endedBy: currentUser.id
      })
      .then((data) => {
        if (!data.error) {
          console.log('[SUCCESS]: ', 'Successfully ended discussion!')
        } else {
          console.log('[FAIL]: ', 'Failed to end discussion.')
        }
      })

    analytics.logEvent('end_discussion')
  }

  render() {
    const { classes, currentProfile } = this.props
    const { documentVerified } = currentProfile
    const verified = documentVerified ? 1 : 0
    const { fields, status } = this.state

    return (
      <CreateFormContainer>
        <FormContainer>
          <TextareaProfilePicture
            style={{ backgroundImage: `url(${getAvatar(currentProfile.avatar)})` }}
            verified={verified}
          />

          <TextareaContainer>
            <StyledTextarea
              id="rebuttal-form"
              name="rebuttal"
              placeholder="Keep the discussion going or end and give a civility rating."
              value={fields.rebuttal}
              wrap="soft"
              maxLength="3000"
              onChange={this.handleInputChange}></StyledTextarea>
          </TextareaContainer>
          <TextCount>{`${fields.rebuttal.length} / 3000`}</TextCount>
        </FormContainer>
        <p style={{ fontSize: '1rem', color: '#000' }}>Add your emotion to your response:</p>
        <EmotionPicker
          topEmotion={fields.topEmotion}
          subEmotion={fields.subEmotion}
          finalEmotion={fields.finalEmotion}
          setTopEmotion={this.setTopEmotion}
          setSubEmotion={this.setSubEmotion}
          setFinalEmotion={this.setFinalEmotion}
        />

        <SubmitContainer>
          <Button
            variant="contained"
            color="secondary"
            className={classes.end}
            onClick={this.handleEnd}>
            End
          </Button>

          <Button
            variant="contained"
            color="primary"
            className={classes.send}
            onClick={this.handleSubmitRebuttal}
            disabled={status.processing || fields.rebuttal.trim().length === 0}>
            {status.processing ? (
              <CircularProgress color="secondary" className={classes.circularProgress} />
            ) : (
              'Send'
            )}
          </Button>
        </SubmitContainer>
      </CreateFormContainer>
    )
  }
}

CreateRebuttalForm.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  currentUser: state.currentUser,
  currentProfile: state.currentProfile
})

const mapDispatchToProps = (dispatch) => ({
  createRebuttal: (params) => {
    return dispatch(createRebuttal(params))
  },
  endDiscussionByID: (params) => {
    return dispatch(endDiscussionByID(params))
  }
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CreateRebuttalForm))
)
