import config from '../../../config'
import { loadedRebuttalsActions } from '../../action-creators/loaded-rebuttals.js'
import objectToQueryString from '../../../utils/functions/object-to-query-string.js'
import { authenticatedFetch } from '../../../utils/functions/authenticatedFetch'

export const getRebuttals = (params) => {
  let responseCode = ''
  const method = 'GET'
  let url = `${config.baseURL}/api/rebuttal/`
  const headers = {
    'Content-Type': 'application/json'
  }

  // Use objecttoQueryString util function to turn params into a query string and append it to URL
  let queryStringObject = { ...params }
  delete queryStringObject.token
  delete queryStringObject.isLoadingPage
  url += objectToQueryString(queryStringObject)

  // Thunk middleware knows how to handle functions.
  // It passes the dispatch method as an argument to the function,
  // thus making it able to dispatch actions itself.
  return (dispatch) => {
    console.log('Sending get rebuttals request to CivilTalk API')

    // Return the fetch so react components calling 'store.dispatch()' can use 'then()'
    return authenticatedFetch(url, {
      method,
      headers,
      credentials: 'include'
    })
      .then((response) => {
        responseCode = response.status

        return response.json()
      })
      .then((data) => {
        if (responseCode === 200) {
          if (params.isLoadingPage) {
            if (params.outgoing) {
              dispatch(loadedRebuttalsActions.add(data.rebuttals))
            } else {
              dispatch(loadedRebuttalsActions.set(data.rebuttals))
            }
          } else {
            dispatch(loadedRebuttalsActions.add(data.rebuttals))
          }

          return {
            error: false,
            code: responseCode,
            message: data.message,
            rebuttals: data.rebuttals
          }
        } else {
          console.log(data.message)

          return {
            error: true,
            code: responseCode,
            message: data.message
          }
        }
      })
      .catch((error) => {
        console.log(error)

        return {
          error: true,
          code: '',
          message: error
        }
      })
  }
}
