import { postDraftActionTypes } from '../action-creators/post-draft.js'

// FOR POST DRAFT STATE
const postDraftReducer = (state = '', action) => {
	switch (action.type) {
		case postDraftActionTypes.SET_POST_DRAFT:
			return action.post
		case postDraftActionTypes.CLEAR_POST_DRAFT:
			return ''
		default:
			return state
	}
}

export default postDraftReducer