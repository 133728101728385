import { followActionTypes } from '../action-creators/follows.js'
import nestedSort from '../../utils/functions/nestedSort.js'
import _ from 'lodash'

const copyFollows = (follows) => {
  return follows.map((follow) => ({ ...follow }))
}

// FOR FOLLOWS
const followsReducer = (state = [], action) => {
  switch (action.type) {
    case followActionTypes.SET_FOLLOWS:
      return nestedSort(copyFollows(action.follows), -1, 'whenCreated')

    case followActionTypes.ADD_FOLLOWS:
      const newFollows1 = copyFollows(state)

      for (let i = 0; i < action.follows.length; i++) {
        const followExists = _.some(newFollows1, { _id: action.follows[i]._id })

        if (!followExists) {
          newFollows1.push({ ...action.follows[i] })
        } 
      }

      return nestedSort(newFollows1, -1, 'whenCreated')

    case followActionTypes.REMOVE_USER_FOLLOW:
      let newFollows2 = copyFollows(state)
      let followIndex2 = _.findIndex(newFollows2, {
        'follower': action.follower,
        'userId': action.userId
      })

      if (followIndex2 !== -1) {
        newFollows2.splice(followIndex2, 1)
      } else {
        console.log('[REDUX ERROR]: ', 'Follow not found.')
      }

      return nestedSort(newFollows2, -1, 'whenCreated')

    case followActionTypes.REMOVE_ORGANIZATION_FOLLOW:
      let newFollows3 = copyFollows(state)
      let followIndex3 = _.findIndex(newFollows3, {
        'follower': action.follower,
        'organizationId': action.organizationId
      })

      if (followIndex3 !== -1) {
        newFollows3.splice(followIndex3, 1)
      } else {
        console.log('[REDUX ERROR]: ', 'Follow not found.')
      }

      return nestedSort(newFollows3, -1, 'whenCreated')

    case followActionTypes.CLEAR_FOLLOWS:
      return []

    default:
      return state
  }
}

export default followsReducer
