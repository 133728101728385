import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { history } from '../../../../../history/history'
import getAvatar from '../../../../../utils/functions/getAvatar'
import getName from '../../../../../utils/functions/getName'
// import getStylesFromTheme from '../../functions/get-styles-from-theme'
import followUser from './functions/follow-user'
import unfollowUser from './functions/unfollow-user'
import { CircularProgress } from '@mui/material'
import {
  OuterContainer,
  InnerContainer,
  IconContainer,
  IconSpan,
  Image,
  Name
} from './styled/user-comps'

const User = (props) => {
  const { profile, isFollowing, displayFollowActions } = props
  // const style = getStylesFromTheme(theme)
  const [processing, setProcessing] = useState(false)
  const currentUser = useSelector(({ currentUser }) => currentUser)
  const dispatch = useDispatch()
  const { avatar, userId, documentVerified } = profile
  const isCurrentUser = profile.userId === currentUser.id

  const handleFollowUser = (e, profile) => {
    e.stopPropagation()

    followUser(profile, setProcessing, dispatch)
  }

  const handleUnfollowUser = (e, profile) => {
    e.stopPropagation()

    unfollowUser(profile, currentUser, setProcessing, dispatch)
  }

  return (
    <OuterContainer key={userId}>
      <InnerContainer>
        <Image
          verified={documentVerified}
          style={{ backgroundImage: `url(${getAvatar(avatar)})` }}
          onClick={() => history.push(`/profile/${userId}`)}>
          {!isFollowing && displayFollowActions && !isCurrentUser && (
            <IconContainer onClick={(e) => handleFollowUser(e, profile)}>
              {processing ? (
                <CircularProgress
                  size={'1rem'}
                  sx={{
                    display: 'block',
                    color: 'common.white',
                    position: 'absolute',
                    top: '25%',
                    left: '25%'
                  }}
                />
              ) : (
                <IconSpan className="material-icons">person_add</IconSpan>
              )}
            </IconContainer>
          )}

          {isFollowing && displayFollowActions && !isCurrentUser && (
            <IconContainer onClick={(e) => handleUnfollowUser(e, profile)}>
              {processing ? (
                <CircularProgress
                  size={'1rem'}
                  sx={{
                    display: 'block',
                    color: 'common.white',
                    position: 'absolute',
                    top: '25%',
                    left: '25%'
                  }}
                />
              ) : (
                <IconSpan className="material-icons">person_remove</IconSpan>
              )}
            </IconContainer>
          )}
        </Image>

        <br />

        <Name onClick={() => history.push(`/profile/${userId}`)}>{getName(profile)}</Name>
      </InnerContainer>
    </OuterContainer>
  )
}

export default User
