import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import _ from 'lodash'
import { createView } from '../../../redux/thunks/views/createView'
import styled from 'styled-components'
import shave from 'shave'
import linkifyString from '../../../utils/functions/linkifyString'
import theme from '../../../res/theme'

const SingleLineClamp = styled.div`
  display: 'block';
  width: '100%';
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: ${({ lineHeight }) => lineHeight};
  font-family: ${theme.typography.fontFamily};
  font-size: ${({ fontSize }) => fontSize};
  font-weight: ${({ fontWeight }) => fontWeight};
  color: ${({ color }) => color};
`

const getStyles = (color, fontSize, fontWeight, lineHeight) => {
  return {
    width: '100%',
    whiteSpace: 'pre-line',
    wordBreak: 'break-word',
    lineHeight: lineHeight,
    fontFamily: theme.typography.fontFamily,
    fontSize: fontSize,
    fontWeight: fontWeight,
    color: color
  }
}

const getReadMoreStyles = (color, fontSize, lineHeight) => {
  return {
    background: 'none',
    outline: 'none',
    border: 'none',
    lineHeight,
    fontFamily: theme.typography.fontFamily,
    fontSize,
    fontWeight: theme.typography.fontWeight.strong,
    textDecoration: 'underline',
    color,
    cursor: 'pointer'
  }
}

const CollapsedContent = ({
  color,
  collapsible,
  linkify,
  content,
  fontSize,
  fontWeight,
  lineHeight,
  rows,
  viewsParams
}) => {
  const [showReadMore, setShowReadMore] = useState(false)
  const [readMore, setReadMore] = useState(false)
  const clampedEl = React.useRef()
  const expandedEl = React.useRef()
  const expandedDisplay = readMore ? 'block' : 'none'
  const clampedDisplay = !readMore ? 'block' : 'none'
  const dispatch = useDispatch()

  // Configure passed in props
  rows = rows || 5
  collapsible = collapsible === false ? false : true
  linkify = linkify || false

  // Styles for Content
  color = color || '#ffffff'
  fontSize = fontSize || theme.typography.fontSize
  fontWeight = fontWeight || theme.typography.fontWeight.regular
  lineHeight = lineHeight || theme.typography.lineHeight

  useEffect(() => {
    if (rows > 1) {
      if (
        clampedEl.current !== null &&
        typeof clampedEl.current !== 'undefined' &&
        expandedEl.current !== null &&
        typeof expandedEl.current !== 'undefined'
      ) {
        if (!readMore) {
          shave(clampedEl.current, parseFloat(lineHeight.split('rem')[0]) * 16 * rows, {
            character: '...'
          })
        }

        // Check if content has been clamped, if so, show 'read more' button else, hide the 'read more' button
        if (
          collapsible &&
          clampedEl.current.childNodes &&
          clampedEl.current.childNodes.length > 1
        ) {
          const isClamped = _.some(
            clampedEl.current.childNodes,
            (child) => child.toString() === '[object HTMLSpanElement]'
          )

          setShowReadMore(isClamped)
        } else {
          setShowReadMore(false)
        }

        // Linkify our text here if linkify prop === true
        if (linkify) {
          // Replace urls in text content with a tag links to urls
          if (clampedEl.current.childNodes && expandedEl.current.childNodes) {
            // Find the Nodes whose textContent we want to "Linkify" (Replace URLS with HTML a-tag links)
            const textNodeExpanded = _.find(
              expandedEl.current.childNodes,
              (child) =>
                child.toString() === '[object Text]' ||
                child.toString() === '[object HTMLParagraphElement]'
            )
            const textNodeClamped = _.find(
              clampedEl.current.childNodes,
              (child) =>
                child.toString() === '[object Text]' ||
                child.toString() === '[object HTMLParagraphElement]'
            )

            if (textNodeExpanded && textNodeClamped) {
              // Create new elements that will hold HTML with a-tag replacing URLS
              const newNodeExpanded = document.createElement('p')
              const newNodeClamped = document.createElement('p')

              // Set new elements display to inline so ellipsis stays on same line
              newNodeExpanded.style.display = 'inline'
              newNodeClamped.style.display = 'inline'

              // Set new elements' innerHTML to new string with a-tag-replaced URLs
              newNodeExpanded.innerHTML = linkifyString(textNodeExpanded.textContent, color)
              newNodeClamped.innerHTML = linkifyString(textNodeClamped.textContent, color)

              // Replace existing child node with new element with a-tags
              expandedEl.current.replaceChild(newNodeExpanded, textNodeExpanded)
              clampedEl.current.replaceChild(newNodeClamped, textNodeClamped)
            }
          }
        }
      }
    }
  }, [rows, content, readMore, collapsible, linkify, lineHeight, color])

  const handleReadMore = () => {
    if (!readMore && viewsParams) {
      dispatch(createView(viewsParams))
    }

    setReadMore(!readMore)
  }

  return (
    <React.Fragment>
      {rows > 1 ? (
        <React.Fragment>
          <div
            ref={expandedEl}
            style={{
              ...getStyles(color, fontSize, fontWeight, lineHeight),
              display: expandedDisplay
            }}>
            {content}
          </div>

          <div
            ref={clampedEl}
            style={{
              ...getStyles(color, fontSize, fontWeight, lineHeight),
              display: clampedDisplay
            }}>
            {content}
          </div>
        </React.Fragment>
      ) : (
        <SingleLineClamp
          fontSize={fontSize}
          lineHeight={lineHeight}
          fontWeight={fontWeight}
          color={color}>
          {content}
        </SingleLineClamp>
      )}

      {showReadMore && collapsible && (
        <span
          // onClick={() => setReadMore(!readMore)}
          onClick={handleReadMore}
          style={getReadMoreStyles(color, fontSize, lineHeight)}>
          {readMore ? 'Read Less' : 'Read More'}
        </span>
      )}
    </React.Fragment>
  )
}

export default CollapsedContent
