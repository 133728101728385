import React from 'react'
import states from '../../../utils/constants/states'
import withStyles from '@mui/styles/withStyles'
import Select from '@mui/material/Select'
import InputBase from '@mui/material/InputBase'
import MenuItem from '@mui/material/MenuItem'
import config from '../../../config'

const StyledInput = withStyles(() => ({
  root: {
    backgroundColor: 'rgba(0, 0, 0, 0)',
    padding: '0.3rem 0.6rem',
    width: '100%',
    marginTop: '0',
    verticalAlign: 'middle',
    border: '1px solid #ddd',
    borderRadius: '0.2rem',
    boxSizing: 'border-box',
    boxShadow: '0px 9px 7px -2px rgba(120, 120, 120, 0.1)',
    fontSize: '1rem',
    fontWeight: 400,
    color: '#333',
    [`@media(max-width: ${config.UI.drawer_breakpoint.max})`]: {
      width: '100%'
    }
  }
}))(InputBase)

const SelectState = ({ state, setState, style = {} }) => {
  return (
    <Select
      value={state}
      placeHolder="E.g. New York"
      onChange={(e) => setState(e.target.value)}
      input={<StyledInput />}
      style={{ ...style }}>
      <MenuItem key="state-select-default" value="" disabled>
        Select a state
      </MenuItem>

      {states.map((item) => {
        return (
          <MenuItem key={item} value={item}>
            {item}
          </MenuItem>
        )
      })}
    </Select>
  )
}

export default React.memo(SelectState)
