import styled from 'styled-components'
import theme from '../../../../../../../res/theme.js'
// import config from '../../../../../../../config.js'

export const SectionHeader = styled.span`
  display: inline-block;
  padding-bottom: 0.2rem;
  font-family: ${theme.typography.fontFamily};
  font-size: 1.4rem;
  font-weight: ${theme.typography.fontWeight.bold};
  color: ${theme.typography.fontColor.bodyCopy};
  ${'' /* border-bottom: 2.5px solid #cc0000; */}
`

export const DetailContainer = styled.div`
  display: block;
  margin-top: 0.5rem;
`

export const DetailLabel = styled.p`
  display: block;
  width: 12rem;
  float: left;
  line-height: ${theme.typography.lineHeight};
  font-family: ${theme.typography.fontFamily};
  font-size: ${theme.typography.fontSizes.regular};
  font-weight: ${theme.typography.fontWeight.bold};
  color: ${theme.typography.fontColor.bodyCopy};

  @media (max-width: 500px) {
    float: none;
    margin-top: 1rem;
  }
`

export const Detail = styled.p`
  display: block;
  min-width: 2rem;
  text-transform: capitalize;
  line-height: ${theme.typography.lineHeight};
  font-family: ${theme.typography.fontFamily};
  font-size: ${theme.typography.fontSizes.regular};
  font-weight: ${theme.typography.fontWeight.regular};
  color: ${theme.typography.fontColor.bodyCopy};

  & span.active-flag {
    display: inline-block;
    color: white;
    border-radius: 0.3rem;
    padding: 0.2rem 0.7rem;
    font-size: ${theme.typography.fontSizes.small};
    font-weight: ${theme.typography.fontWeight.light};
  }

  & span.cancel {
    margin-left: 1rem;
    display: inline-block;
    color: ${theme.palette.link.primary};
    font-size: ${theme.typography.fontSizes.small};
    font-weight: ${theme.typography.fontWeight.light};
    text-decoration: underline;
    cursor: pointer;
  }

  @media (max-width: 500px) {
    margin-top: 0.5rem;
  }
`
