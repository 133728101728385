import { isAuthenticatedActionTypes } from '../action-creators/is-authenticated.js'

// FOR AUTHENTICATION
const isAuthenticatedReducer = (state = false, action) => {
  switch (action.type) {
    case isAuthenticatedActionTypes.SET_AUTHENTICATION:
      return action.isAuthenticated
    default:
      return state
  }
}

export default isAuthenticatedReducer
