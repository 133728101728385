//////////////////
// ACTION TYPES //
//////////////////
export const loadedRebuttalActionTypes = {
  SET_LOADED_REBUTTALS: 'SET_LOADED_REBUTTALS',
  ADD_LOADED_REBUTTALS: 'ADD_LOADED_REBUTTALS',
  READ_LOADED_REBUTTAL: 'READ_LOADED_REBUTTAL',
  EDIT_LOADED_REBUTTAL: 'EDIT_LOADED_REBUTTAL',
  CLEAR_LOADED_REBUTTALS: 'CLEAR_LOADED_REBUTTALS'
}

/////////////
// ACTIONS //
/////////////

// REBUTTALS
const set = (rebuttals) => {
  return {
    type: loadedRebuttalActionTypes.SET_LOADED_REBUTTALS,
    rebuttals
  }
}

const add = (rebuttals) => {
  return {
    type: loadedRebuttalActionTypes.ADD_LOADED_REBUTTALS,
    rebuttals
  }
}

const read = (rebuttalId) => {
  return {
    type: loadedRebuttalActionTypes.READ_LOADED_REBUTTAL,
    rebuttalId
  }
}

const edit = (rebuttalId, rebuttal, emotion) => {
  return {
    type: loadedRebuttalActionTypes.EDIT_LOADED_REBUTTAL,
    rebuttalId,
    rebuttal,
    emotion
  }
}

const clear = () => {
  return {
    type: loadedRebuttalActionTypes.CLEAR_LOADED_REBUTTALS
  }
}

export const loadedRebuttalsActions = {
  set,
  add,
  read,
  edit,
  clear
}
