import { refreshPromiseActionTypes } from '../action-creators/refresh-promise.js'

// FOR REFRESH PROMISE
const refreshPromiseReducer = (state = null, action) => {
	switch (action.type) {
		case refreshPromiseActionTypes.SET_REFRESH_PROMISE:
			return action.promise
		case refreshPromiseActionTypes.CLEAR_REFRESH_PROMISE:
			return null
		default:
			return state
	}
}

export default refreshPromiseReducer