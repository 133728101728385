import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { donateDonationTokens } from '../../../../../../../redux/thunks/donation-tokens-transactions/donate-donation-tokens'
import FormError from '../../../../../../alerts/form-error'
import { Button, CircularProgress } from '@mui/material'
import { Heading, Subheading, ButtonContainer } from './styled/confirmation-comps'

const styles = {
  back: {
    width: '12rem',
    marginRight: '1rem',
    '@media (max-width: 500px)': {
      width: '100%',
      marginRight: '0'
    }
  },
  submit: {
    width: '12rem',
    '@media (max-width: 500px)': {
      width: '100%',
      marginTop: '.5rem'
    }
  },
  circularProgress: {
    display: 'block',
    width: '17px !important',
    height: '17px !important',
    margin: '0 auto'
  }
}

function Confirmation(props) {
  const { setStep, tokens, organizationId, takeId } = props
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState('')
  const dispatch = useDispatch()

  const handleBack = () => {
    setStep('selection')
  }

  const handleSubmit = () => {
    setProcessing(true)

    dispatch(donateDonationTokens({ donationTokens: tokens, organizationId, takeId }))
      .then((data) => {
        if (!data.error) {
          console.log('[SUCCESS]: ', 'Successfully submitted donation.')

          setStep('complete')
        } else {
          console.log('[FAIL]: ', 'Failed to submit donation.')

          setProcessing(false)
          setError(data.message)
        }
      })
      .catch((e) => {
        setProcessing(false)
      })
  }

  return (
    <div>
      <Heading>
        Please confirm your donation of <b>{tokens}</b> tokens
      </Heading>
      <Subheading>
        Upon confirming, your donation of <b>{tokens}</b> tokens will be sent and your token balance
        will be adjusted accordingly.
      </Subheading>

      <FormError message={error} style={{ marginTop: '1rem' }} />

      <ButtonContainer>
        <Button color="secondary" variant="contained" onClick={handleBack} sx={styles.back}>
          Go Back
        </Button>

        <Button
          color="primary"
          variant="contained"
          onClick={handleSubmit}
          sx={styles.submit}
          disabled={processing}>
          {processing ? (
            <CircularProgress color="primary" sx={styles.circularProgress} />
          ) : (
            `Confirm and Donate`
          )}
        </Button>
      </ButtonContainer>
    </div>
  )
}

export default Confirmation
