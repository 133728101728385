import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import withStyles from '@mui/styles/withStyles';
import { Skeleton } from '@mui/material';

const animation = 'pulse'

const styles = () => ({
  badge: {
    display: 'block',
    float: 'left',
    marginTop: '.2rem',
    marginRight: '.8rem'
  }
})

class SkeletonTrendingIssue extends Component {
  render() {
    const { classes, count, style } = this.props
    const customStyles = style ? style : {}
    const numSkeletons = count ? count : 1
    let skeletons = []

    for (var i = 0; i < numSkeletons; i++) {
      skeletons.push(
        <div
          key={i}
          style={{
            ...customStyles,
            backgroundColor: 'white',
            padding: '.8rem',
            minHeight: '2rem',
            marginBottom: '.1rem',
            boxSizing: 'border-box'
          }}>
          <Skeleton
            animation={animation}
            variant="circular"
            height={'.5rem'}
            width={'.5rem'}
            className={classes.badge}
          />
          <div style={{ overflow: 'hidden' }}>
            <Skeleton animation={animation} variant="text" height={'.7rem'} />
            <Skeleton
              animation={animation}
              variant="text"
              height={'.7rem'}
              width={'60%'}
              style={{ marginTop: '.2rem' }}
            />
          </div>
        </div>
      )
    }

    return skeletons
  }
}

SkeletonTrendingIssue.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withRouter(withStyles(styles)(SkeletonTrendingIssue))
