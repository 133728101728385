import styled from 'styled-components'
import config from '../../../../config'
import theme from '../../../../res/theme'

export const MainContainer = styled.article`
  display: block;
  max-width: 80rem;
  padding: 0 1rem;
  margin: 0 auto 10rem auto;
  box-sizing: border-box;
  background: #eaeaea;

  @media (max-width: 400px) {
    padding: 0 0.25rem;
  }
`

// export const Container = styled.div`
//   background-color: white;
//   display: block;
//   float: left;
//   width: 17rem;
//   margin-right: 2rem;
//   margin-top: calc(${config.UI.header.height} + 1rem);
//   padding: 1.5rem;
//   border-radius: 0.2rem;
//   box-shadow: ${theme.boxShadow};
//   @media (max-width: 750px) {
//     float: none;
//     width: 100%;
//     margin-right: 0;
//     box-sizing: border-box;
//   }
// `

export const SideNavItem = styled.p`
  margin: 0.9rem 0 0.4rem 0;
  line-height: ${theme.typography.lineHeight};
  font-size: ${theme.typography.fontSize};
  font-weight: ${theme.typography.fontWeight.regular};
  color: #6f8c76;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  & a:visited,
  & a:active,
  & a:link {
    text-decoration: none;
  }
`
export const SideNavHeader = styled.h2`
  line-height: ${theme.typography.lineHeight};
  font-size: ${theme.typography.fontSizes.larger};
  font-weight: ${theme.typography.fontWeight.bold};
  color: #333;
`
export const ContentContainer = styled.div``
