import styled from 'styled-components'

export const MainContainer = styled.div`
  background-color: ${(props) => props.theme.palette.secondary.main};
  display: block;
  max-width: 65rem;
  margin: 1.5rem auto;
  padding: 2rem;
  box-sizing: border-box;
  border-radius: 0.3rem;
  box-shadow: 0px 5px 5px -2px rgba(0, 0, 0, 0.2);

  @media (max-width: 1100px) {
    max-width: none;
    width: 100%;
    margin: 0;
    padding: 1.3rem;
    border-radius: 0;
  }
`

export const PostUnavailable = styled.div`
  background-color: ${(props) => props.theme.palette.secondary.main};
  display: block;
  max-width: 65rem;
  margin: 1.5rem auto;
  padding: 2rem;
  box-sizing: border-box;
  border-radius: 0.3rem;
  box-shadow: 0px 5px 5px -2px rgba(0, 0, 0, 0.2);
  text-align: center;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: 1.2rem;
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  color: ${(props) => props.theme.palette.text.primary};

  @media (max-width: 1100px) {
    max-width: none;
    width: 100%;
    margin: 0;
    padding: 1.3rem;
    border-radius: 0;
  }
`
