import { useState, useEffect } from 'react'

import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Button from '@mui/material/Button'

interface CourseFormDialogProps {
  errors: string[]
}

function CourseFormDialog({ errors }: CourseFormDialogProps) {
  const [open, setOpen] = useState(errors.length > 0)

  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    setOpen(errors.length > 0)
  }, [errors])

  return (
    <Dialog open={open}>
      <DialogTitle
        id="submit-errors-dialog-title"
        sx={{
          fontSize: '1.25rem',
          fontWeight: 600,
          color: '#333',
          backgroundColor: 'secondary.light'
        }}>
        Course Setup Errors
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="submit-errors-dialog-content"
          sx={{ fontSize: '1.05rem', fontWeight: 500, color: '#333', padding: '1rem' }}>
          Please correct the following errors before continuing:
        </DialogContentText>
        <List>
          {errors.map((error, index) => (
            <ListItem key={index} sx={{ fontSize: '1rem' }}>
              -{error}
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose} autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CourseFormDialog
