import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { getPaymentDetails } from '../../../../../../redux/thunks/users/getPaymentDetails'
import WarningBanner from '../../../../../warning-banner/warning-banner'
import PaymentMethods from './components/payment-methods/payment-methods'
import AddPaymentMethod from '../../../../../partials/add-payment-method/add-payment-method'
import load from './functions/load'
import PropTypes from 'prop-types'
import withStyles from '@mui/styles/withStyles'
import { CircularProgress } from '@mui/material'
import { PaymentInfoContainer, SectionHeader } from './styled/billing-comps'
import TransactionHistory from './components/transaction-history/transaction-history'

const styles = () => ({
  loader: {
    display: 'inline-block'
  },
  addPaymentMethodButton: {
    marginTop: '1.5rem',
    width: '15rem'
  },
  updateCardButton: {
    marginTop: '2rem'
  },
  actionButton: {
    display: 'inline-block',
    width: '16rem',
    marginTop: '1.5rem',
    '@media (max-width: 450px)': {
      width: '100%'
    }
  },
  circularProgress: {
    display: 'block',
    margin: '.2rem auto 0 auto',
    width: '18px !important',
    height: '18px !important',
    '& svg': {
      color: 'white'
    }
  }
})

class BillingStripe extends Component {
  constructor(props) {
    super(props)

    this.state = {
      status: {
        pageLoaded: false
      },
      customer: {},
      paymentMethods: [],
      error: ''
    }

    this.handleLoad = this.handleLoad.bind(this)
    this.handleSetState = this.handleSetState.bind(this)
  }

  componentDidMount() {
    this.handleLoad()
  }

  handleLoad() {
    const billingActions = {
      getPaymentDetails: this.props.getPaymentDetails
    }

    // Call function to handle my account load conditions
    load(this.state, this.handleSetState, billingActions)
  }

  handleSetState(object) {
    this.setState(object)
  }

  render() {
    const { classes } = this.props
    const { status, customer, paymentMethods } = this.state

    // console.log({
    //   customer,
    //   paymentMethods
    // })

    if (!status.pageLoaded) {
      return (
        <div style={{ margin: '2rem 0', textAlign: 'center' }}>
          <CircularProgress color="primary" className={classes.loader} />
        </div>
      )
    }

    if (Object.keys(customer).length === 0) {
      return (
        <>
          <WarningBanner
            variant="contained"
            type="alert"
            htmlBody={
              <React.Fragment>
                We were unable to load your billing information. Please contact support.
              </React.Fragment>
            }
            style={{ margin: '0 0 0 0' }}
          />
        </>
      )
    }

    /////////////////////////
    // Cards Details
    /////////////////////////
    const defaultPaymentMethod =
      customer.invoice_settings.default_payment_method || customer.default_source

    return (
      <>
        <PaymentInfoContainer>
          <SectionHeader>
            {paymentMethods.length > 0 ? 'Payment Methods' : 'Add a Payment Method'}
          </SectionHeader>

          {paymentMethods.length > 0 && (
            <PaymentMethods
              paymentMethods={paymentMethods}
              defaultPaymentMethod={defaultPaymentMethod}
            />
          )}

          <AddPaymentMethod style={{ marginTop: '1rem' }} />
        </PaymentInfoContainer>

        <TransactionHistory />
      </>
    )
  }
}

BillingStripe.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapDispatchToProps = (dispatch) => ({
  getPaymentDetails: (params) => {
    return dispatch(getPaymentDetails(params))
  }
})

export default withRouter(connect(null, mapDispatchToProps)(withStyles(styles)(BillingStripe)))
