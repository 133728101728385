import styled from 'styled-components'

export const Heading = styled.p`
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.extraLarge};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  color: ${(props) => props.theme.typography.fontColor.bodyCopy};
`

export const Subheading = styled.p`
  margin-top: 1rem;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.small};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  color: ${(props) => props.theme.typography.fontColor.headerCopy};
`

export const PriceItemsContainer = styled.div`
  display: block;
  max-width: 20.8rem;
  margin: 0.5rem auto 0 auto;

  @media (max-width: 450px) {
    max-width: none;
  }
`

export const PriceItemLabel = styled.span`
  display: inline-block;
  width: 60%;
  vertical-align: top;
  text-align: left;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.small};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  color: ${(props) => props.theme.typography.fontColor.headerCopy};

  & > a:link,
  & > a:visited,
  & > a:active {
    color: ${(props) => props.theme.palette.secondary.main};
    outline: none;
    ${'' /* text-decoration: none; */}
  }

  & > span {
    display: inline-block;
    vertical-align: middle;
  }

  & > .material-icons {
    margin-top: 0.1rem;
    margin-left: 0.2rem;
    font-size: 1rem;
    color: #bbb;
    cursor: pointer;
  }
`

export const PriceItemValue = styled.span`
  display: inline-block;
  width: 40%;
  vertical-align: top;
  text-align: right;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.small};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  color: ${(props) => props.theme.typography.fontColor.bodyCopy};
`

export const TotalPriceContainer = styled.div`
  margin-top: 1.5rem;
  text-align: center;
`

export const USD = styled.span`
  display: inline-block;
  margin: 0.3rem 0.1rem 0 0;
  vertical-align: top;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.small};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  color: ${(props) => props.theme.palette.secondary.main};
`

export const TotalPrice = styled.span`
  display: inline-block;
  vertical-align: top;
  line-height: 2.2rem;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: 2.2rem;
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  color: ${(props) => props.theme.palette.secondary.main};
`

export const PriceLabel = styled.p`
  margin-top: 0.1rem;
  text-align: center;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: 0.8rem;
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  color: #999;
`

export const ButtonContainer = styled.div`
  margin-top: 2rem;
  text-align: center;
`
