import { transActionHistoyActionTypes } from '../action-creators/transAction-history'

const transActionHistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case transActionHistoyActionTypes.SET_HISTORY:
      const newState = action.payload.transactions
      const newAfterDate = newState?.at(-1)

      return {
        ...state,
        history: action.payload.transactions,
        afterDate: newAfterDate?.whenCreated,
        totalRecords: action.payload.totalRecords
      }

    case transActionHistoyActionTypes.ADD_HISTORY:
      const newHistory = action.payload
      const newAfterDateHistory = newHistory.at(-1)

      return {
        ...state,
        history: [...state.history, ...action.payload],
        afterDate: newAfterDateHistory?.whenCreated
      }

    case transActionHistoyActionTypes.UPDATE_HISTORY_BY_ORGANIZATIONID:
      const updatehistory = state?.history?.map((history) => {
        return history?.organizationId === action?.payload?.organizationId
          ? action?.payload
          : history
      })

      return { ...state, history: [...updatehistory] }

    case transActionHistoyActionTypes.UPDATE_HISTORY_BY_PUBLIC_ID_PURCHACE:
      const updatehistory_purchase = state?.history?.map((history) => {
        return history?._id === action?.payload?._id
          ? { ...history, name_from: 'CivilTalk', name_to: 'You' }
          : history
      })

      return { ...state, history: [...updatehistory_purchase] }

    case transActionHistoyActionTypes.UPDATE_HISTORY_BY_PUBLIC_ID_SUBSCRIPTION:
      const updatehistory_subscription = state?.history?.map((history) => {
        return history?._id === action.payload?._id
          ? { ...history, name_from: 'You', name_to: 'CivilTalk' }
          : history
      })
      return { ...state, history: [...updatehistory_subscription] }

    case transActionHistoyActionTypes.UPDATE_HISTORY_BY_PUBLIC_ID_VERIFICATION:
      const updatehistory_verification = state?.history?.map((history) => {
        return history?._id === action.payload?._id
          ? { ...history, name_from: 'You', name_to: 'CivilTalk' }
          : history
      })
      return { ...state, history: [...updatehistory_verification] }

    case transActionHistoyActionTypes.UPDATE_HISTORY_BY_PUBLIC_ID_TRANSFER:
      const currect = [action?.payload]?.filter((item) => item?.type === 'transfer')

      const updatehistory_byID = state?.history?.map((history) => {
        const you_Transfered = {
          ...currect[0],
          name_from: 'You',
          name_to: currect[0]?.transferedGuy
        }
        const Transfered_To_You = {
          ...currect[0],
          name_from: currect[0]?.publicName,
          name_to: 'You'
        }
        const newState = currect[0]?.transferedGuy ? you_Transfered : Transfered_To_You
        return history?._id === newState?._id ? newState : history
      })

      return { ...state, history: [...updatehistory_byID] }

    case transActionHistoyActionTypes.SET_ROWS_PER_PAGE:
      return { ...state, rowsPerPage: action.payload }

    case transActionHistoyActionTypes.SET_PAGE:
      return { ...state, page: action.payload }

    case transActionHistoyActionTypes.LOADING:
      return { ...state, loading: action.payload }

    case transActionHistoyActionTypes.CLEAR_TRANSACTION:
      return { ...state, history: [], page: 0, rowsPerPage: 5 }

    default:
      return state
  }
}

export default transActionHistoryReducer

const initialState = {
  history: [],
  rowsPerPage: 5,
  page: 0,
  loading: false,
  afterDate: '',
  totalRecords: 0
}
