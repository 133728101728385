import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import theme from '../../../res/theme'
import ReportValidation from './validations/create-report'
import { createReport } from '../../../redux/thunks/reports/createReport'
import Modal from '../../modal/modal'
import FormError from '../../alerts/form-error'
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
  adaptV4Theme
} from '@mui/material/styles'
import withStyles from '@mui/styles/withStyles'
import PropTypes from 'prop-types'
import CloseIcon from '@mui/icons-material/Close'
import { Button, IconButton, TextField, MenuItem } from '@mui/material'
import {
  OuterContainer,
  InnerContainer,
  SuccessHeader,
  SuccessSubheader,
  Header,
  Form,
  ButtonsContainer
} from './styled/report-form-comps'

// --- theme and styles to wrap the Inputs --- //
const inputTheme = createTheme(
  adaptV4Theme({
    palette: {
      primary: { main: '#cc0000' },
      secondary: { main: '#4064af' },
      error: { main: '#cc0000' }
    },
    typography: {
      fontFamily: theme.typography.fontFamily,
      fontSize: 13,
      fontWeight: 600
    }
  })
)

const styles = () => ({
  select: {
    boxShadow: '0px 7px 20px 2px rgba(100, 100, 100, 0.1)',
    borderColor: '#4f5c88'
  },
  textarea: {
    marginTop: '1rem',
    boxShadow: '0px 7px 20px 2px rgba(100, 100, 100, 0.1)',
    borderColor: '#4f5c88'
  },
  submit: {
    width: '12rem'
  },
  close: {
    width: '12rem',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none'
    }
  },
  iconButton: {
    position: 'absolute',
    top: '-2rem',
    right: '-2rem'
  }
})

class ReportForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      fields: {
        for: '',
        offenseType: '',
        desc: ''
      },
      status: {
        showSuccess: false
      },
      error: ''
    }

    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount() {
    this.setState({
      ...this.state,
      fields: {
        ...this.state.fields,
        for: this.props.for
      }
    })
  }

  handleInputChange(event) {
    const target = event.target
    const name = target.name

    this.setState({
      ...this.state,
      fields: {
        ...this.state.fields,
        [name]: target.value
      }
    })
  }

  handleSubmit(event) {
    event.preventDefault()

    const validation = ReportValidation(this.state.fields)
    const error = validation.error

    // Check for validation errors and then call action to create take
    if (error !== '') {
      console.log('[VALIDATION ERROR]: ', error)

      this.setState({
        ...this.state,
        error: error
      })
    } else {
      this.props.createReport({ fields: this.state.fields }).then((data) => {
        if (data.error) {
          console.log('[FAIL]: ', 'Failed to create report!')

          this.setState({
            ...this.state,
            error: data.message
          })
        } else {
          console.log('[SUCCESS]: ', 'Successfully created report!')

          this.setState({
            ...this.state,
            fields: {
              ...this.state.fields,
              for: '',
              offenseType: '',
              desc: ''
            },
            status: {
              showSuccess: true
            },
            error: ''
          })
        }
      })
    }
  }

  render() {
    const { classes } = this.props
    const { fields, status, error } = this.state

    return (
      <Modal>
        <OuterContainer>
          <InnerContainer>
            {status.showSuccess ? (
              <div>
                <SuccessHeader>Success!</SuccessHeader>
                <SuccessSubheader>Your Report Has Been Submitted.</SuccessSubheader>

                <ButtonsContainer style={{ marginTop: '2.5rem' }}>
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.close}
                    onClick={() => this.props.setOpen(false)}>
                    Close
                  </Button>
                </ButtonsContainer>
              </div>
            ) : (
              <div style={{ position: 'relative' }}>
                <IconButton
                  className={classes.iconButton}
                  onClick={() => this.props.setOpen(false)}
                  size="large">
                  <CloseIcon />
                </IconButton>

                <Header>Please provide us with some details.</Header>

                <Form onSubmit={this.handleSubmit} noValidate>
                  <FormError message={error} />

                  <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={inputTheme}>
                      <TextField
                        name="offenseType"
                        label="Reported Offense"
                        variant="outlined"
                        color="secondary"
                        select
                        required
                        fullWidth
                        value={fields.offenseType}
                        onChange={this.handleInputChange}
                        className={classes.select}>
                        <MenuItem value="Unrelated Topics">Unrelated Topics</MenuItem>
                        <MenuItem value="Misinformation">Misinformation</MenuItem>
                        <MenuItem value="Fake or Hacked Account">Fake or Hacked Account</MenuItem>
                        <MenuItem value="Inappropriate Content">Inappropriate Content</MenuItem>
                        <MenuItem value="Bullying">Bullying</MenuItem>
                        <MenuItem value="Other">Other</MenuItem>
                      </TextField>

                      <TextField
                        name="desc"
                        label="Description"
                        placeholder="Please enter a description in 250 characters or less."
                        variant="outlined"
                        color="secondary"
                        required
                        fullWidth
                        multiline
                        rows={4}
                        value={fields.desc}
                        onChange={this.handleInputChange}
                        className={classes.textarea}
                      />
                    </ThemeProvider>
                  </StyledEngineProvider>

                  <ButtonsContainer>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      disabled={fields.offenseType === '' || fields.desc === ''}>
                      Submit Report
                    </Button>
                  </ButtonsContainer>
                </Form>
              </div>
            )}
          </InnerContainer>
        </OuterContainer>
      </Modal>
    )
  }
}

ReportForm.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapDispatchToProps = (dispatch) => ({
  createReport: (params) => {
    return dispatch(createReport(params))
  }
})

export default withRouter(connect(null, mapDispatchToProps)(withStyles(styles)(ReportForm)))
