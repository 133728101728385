import styled from 'styled-components'
import theme from '../../../../../../res/theme.js'
// import config from "../../../../../../../../config.js"

export const InputContainer = styled.div`
  margin-top: 1rem;
`

export const InputLabel = styled.p`
  line-height: ${theme.typography.lineHeight};
  font-family: ${theme.typography.fontFamily};
  font-size: ${theme.typography.fontSizes.regular};
  font-weight: ${theme.typography.fontWeight.semiBold};
  color: ${theme.typography.fontColor.bodyCopy};
`

export const InputSublabel = styled.p`
  margin-top: 0.5rem;
  line-height: ${theme.typography.lineHeight};
  font-family: ${theme.typography.fontFamily};
  font-size: ${theme.typography.fontSizes.small};
  font-weight: ${theme.typography.fontWeight.regular};
  color: ${theme.typography.fontColor.headerCopy};
`

export const SwitchContainer = styled.div`
  margin-top: 0.3rem;
`
