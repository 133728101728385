import { useEffect, useState, useContext } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { isLoaded, isEmpty } from 'react-redux-firebase'
import { getPendingWithdrawalRequests } from '../../../../../../../redux/thunks/withdrawal-requests/getPendingWithdrawalRequests'
import { createWithdrawalRequest } from '../../../../../../../redux/thunks/withdrawal-requests/createWithdrawalRequest'
import TokenIcon from '../../../../../../../media/icons/svg/token-green.svg'
import FormError from '../../../../../../alerts/form-error'
import Listener from './listeners/organization-meta'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import {
  MainContainer,
  BalanceContainer,
  BalanceLabel,
  BalanceValue
} from './styled/token-balance-comps'
import LoadOrganizationStripeAccountContext from '../../../../../../data-loading/load-organization-stripe-account/context/context'

const styles = {
  button: {
    display: 'block',
    height: 'auto',
    // width: '15rem',
    boxSizing: 'border-box',
    position: 'absolute',
    top: '50%',
    right: '1rem',
    transform: 'translateY(-50%)',
    boxShadow: 'none',
    '@media (max-width: 575px)': {
      position: 'static',
      transform: 'none',
      width: '100%',
      margin: '1rem auto 0 auto'
    }
  },
  circularProgress: {
    display: 'block',
    width: '17px !important',
    height: '17px !important',
    margin: '0 auto',
    '& svg': {
      color: 'white'
    }
  }
}

function TokenBalance() {
  const { orgId } = useParams()
  const { stripeAccount } = useContext(LoadOrganizationStripeAccountContext)
  const theme = useTheme()
  const [isLoading, setIsLoading] = useState(true)
  const [pendingWithdrawalRequests, setPendingWithdrawalRequests] = useState([])
  const [processing, setProcessing] = useState(false)
  const [withdrawModalOpen, setWithdrawModalOpen] = useState(false)
  const [error, setError] = useState('')
  const authRef = useSelector(({ firebase }) => firebase.auth)
  const organizationMetaTokensRef = useSelector(
    ({
      firestore: {
        data: { organizationMeta }
      }
    }) => {
      return organizationMeta && organizationMeta[orgId] && organizationMeta[orgId].tokens
    }
  ) || { balance: 0 }
  const dispatch = useDispatch()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  console.log({ pendingWithdrawalRequests })

  useEffect(() => {
    if (isLoading) {
      dispatch(getPendingWithdrawalRequests({ organizationId: orgId }))
        .then((data) => {
          if (!data.error) {
            console.log('[SUCCESS]: Successfully retrieved pending withdrawal requests.')

            setPendingWithdrawalRequests([...data.withdrawalRequests])
          } else {
            console.log('[FAIL]: Failed to retrieve pending withdrawal requests.')
          }

          setIsLoading(false)
        })
        .catch((error) => {
          console.log('[ERROR]: ', error)

          setIsLoading(false)
        })
    }
  }, [dispatch, isLoading, orgId])

  const handleCreateWithdrawalRequest = () => {
    setProcessing(true)

    dispatch(createWithdrawalRequest({ organizationId: orgId }))
      .then((data) => {
        if (!data.error) {
          console.log('[SUCCESS]: Successfully created withdrawal request.')

          setPendingWithdrawalRequests([data.withdrawalRequest])
          setWithdrawModalOpen(false)
        } else {
          console.log('[FAIL]: ', data)

          setError(data.message)
        }

        setProcessing(false)
      })
      .catch((error) => {
        console.log('[ERROR]: ', error)

        setError('Something went wrong. Please try again.')
        setProcessing(false)
      })
  }

  const handleClose = () => {
    setWithdrawModalOpen(false)
    setError('')
  }

  return (
    <MainContainer>
      {isLoaded(authRef) && !isEmpty(authRef) && <Listener organizationId={orgId} />}

      <Dialog
        fullScreen={fullScreen}
        open={withdrawModalOpen}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title">
        <DialogTitle id="responsive-dialog-title" sx={{ padding: '1.5rem 2rem 1rem 2rem' }}>
          {'Please confirm your token redemption request.'}
        </DialogTitle>

        <DialogContent sx={{ padding: '.5rem 2rem' }}>
          <DialogContentText sx={{ color: '#333' }}>
            <FormError variant="contained" message={error} style={{ margin: '0 0 1rem 0' }} />
            Once you submit your request to redeem your tokens, the CivilTalk team will need to
            review and fulfill your request.
            <br />
            <br />
            Please allow <b>1-3</b> business days for this request to be fulfilled, at which point
            all admins should receive a notification confirming the updated status. If your request
            was accepted, you should see your new funds appear in your Stripe-hosted dashboard.
            <br />
            <br />
            As a reminder, you can manage your funds and billing settings by logging in to your
            Stripe-hosted dashboard.
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ padding: '1rem 2rem 1.5rem 2rem' }}>
          <Button
            variant="contained"
            color="secondary"
            autoFocus
            onClick={handleClose}
            disabled={processing}>
            Cancel
          </Button>

          <Button
            variant="contained"
            onClick={handleCreateWithdrawalRequest}
            autoFocus
            disabled={processing}>
            {processing ? (
              <CircularProgress color="secondary" sx={styles.circularProgress} />
            ) : (
              'Confirm'
            )}
          </Button>
        </DialogActions>
      </Dialog>

      <BalanceContainer>
        <BalanceLabel>Current Balance</BalanceLabel>
        <BalanceValue>
          <img src={TokenIcon} alt="Purchase Tokens Icon" />
          <span>{organizationMetaTokensRef.balance} Tokens</span>
        </BalanceValue>

        <Button
          type="submit"
          variant="outlined"
          color="secondary"
          sx={styles.button}
          onClick={() => setWithdrawModalOpen(true)}
          disabled={
            !stripeAccount.charges_enabled ||
            pendingWithdrawalRequests.length > 0 ||
            organizationMetaTokensRef.balance <= 0
          }>
          {isLoading ? (
            <CircularProgress color="secondary" sx={styles.circularProgress} />
          ) : (
            <div>
              {pendingWithdrawalRequests.length > 0
                ? `Request Pending (${pendingWithdrawalRequests[0].donationTokens} Tokens)`
                : 'Redeem Tokens'}
            </div>
          )}
        </Button>
      </BalanceContainer>
    </MainContainer>
  )
}

export default TokenBalance
