import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { isLoaded, isEmpty } from 'react-redux-firebase'
import { history } from '../../../../history/history'
// import { getPermissions } from '../../../../redux/thunks/assigned-roles/getPermissions'
import useMembershipRoles from '../../../../hooks/useMembershipRoles'
import { logout } from '../../../../redux/thunks/users/logout'
import TokenIcon from '../../../../media/icons/svg/token-black.svg'
import { mobileDrawerActions } from '../../../../redux/action-creators/mobile-drawer'
import { tokensTransactionModalActions } from '../../../../redux/action-creators/tokens-transaction-modal'
import { inviteFriendsActions } from '../../../../redux/action-creators/invite-friends'
import Listener from './listeners/user-meta'
import SearchBarMobile from '../search-bar/search-bar-mobile'
import DiscussionsMobile from '../discussions-mobile/discussions-mobile'
import { ClickAwayListener } from '@mui/material'
import { Button } from '@mui/material'
import {
  Home,
  AccountCircle,
  Business,
  School,
  Settings,
  PersonAdd,
  Help,
  Report
} from '@mui/icons-material'
import { MenuItemText, LogoutContainer } from './styled/drawer-private-comps'
import theme from '../../../../res/theme'

const styles = {
  menuItem: {
    backgroundColor: theme.palette.common.white,
    display: 'block',
    padding: '0 1rem .2rem 1rem',
    minHeight: '4rem',
    boxSizing: 'border-box',
    textAlign: 'left',
    fontSize: '1.1875rem', // 19px
    color: theme.palette.text.primary,
    borderRadius: '0',
    border: '0',
    borderTop: '1px solid rgba(0, 0, 0, 0.1)',
    boxShadow: 'none',
    width: '25rem',
    // [`@media (max-width:${config.UI.drawer_breakpoint.max})`]: {
    //   width: '25rem'
    // },
    '@media (max-width: 450px)': {
      width: '100%'
    },
    '&:hover': {
      backgroundColor: '#eee'
    }
  },
  buyTokens: {
    padding: '1rem 1rem',
    width: '100%',
    minHeight: '3.5rem',
    boxSizing: 'border-box',
    '& img': {
      height: '1.4rem',
      marginTop: '.1rem',
      marginRight: '.5rem'
    },
    '@media (max-width: 450px)': {
      width: '100%'
    }
  },
  verify: {
    padding: '1rem 1rem',
    width: '100%',
    minHeight: '3.5rem',
    boxSizing: 'border-box',
    '@media (max-width: 450px)': {
      width: '100%'
    },
    '& .material-icons': {
      lineHeight: '1.1rem',
      margin: '.1rem .4rem 0 0',
      fontSize: '1.2rem'
    }
  },
  menuIcon: {
    display: 'inline-block',
    width: '1.8rem',
    height: '1.8rem',
    verticalAlign: 'middle',
    color: theme.palette.text.primary
  },
  logout: {
    width: '100%',
    height: '3.5rem'
  }
}

/**
 * @function DrawerPrivate
 * - Mobile Dropdown Menu child component
 * - Displays dropdown menu of private action items. (login / forgot password)
 */

function DrawerPrivate() {
  const inviteFriends = useSelector(({ inviteFriends }) => inviteFriends)
  const currentUser = useSelector(({ currentUser }) => currentUser)
  const currentProfile = useSelector(({ currentProfile }) => currentProfile)
  // set orgId for different development environments before release to production
  const orgId =
    import.meta.env.VITE_NODE_ENV === 'production'
      ? '65a587332e5f8e0035efe4a4'
      : '658081a4ce604400356103ba'
  const { isAdmin, isInstructor } = useMembershipRoles(orgId, currentProfile)
  const authRef = useSelector(({ firebase }) => firebase.auth)
  const userMetaTokensRef = useSelector(
    ({
      firestore: {
        data: { userMeta }
      }
    }) => {
      return userMeta && userMeta[authRef.uid] && userMeta[authRef.uid].tokens
    }
  ) || { balance: 0 }
  const dispatch = useDispatch()

  const handleBuyTokens = () => {
    dispatch(tokensTransactionModalActions.set(true, { initialState: 'purchase' }))
    dispatch(mobileDrawerActions.clear())
  }

  const handleLogout = () => {
    dispatch(mobileDrawerActions.clear())
    dispatch(logout())
  }

  const handleGoToURL = (e, url) => {
    e.stopPropagation()

    dispatch(mobileDrawerActions.clear())
    history.push(url)
  }

  const closeDrawer = (e) => {
    if (e.type === 'keydown' && (e.key === 'Tab' || e.key === 'Shift')) {
      return
    }

    dispatch(mobileDrawerActions.clear())
  }

  const handleInviteFriends = () => {
    const isInviteModalOpen = inviteFriends

    dispatch(inviteFriendsActions.set(!isInviteModalOpen))
  }

  return (
    <>
      {isLoaded(authRef) && !isEmpty(authRef) && <Listener auth={authRef} />}

      <ClickAwayListener onClickAway={(e) => closeDrawer(e)}>
        <div id="mobile-menu-dropdown" style={{ height: '100%' }}>
          <div style={{ padding: '.5rem 1rem 0rem 1rem' }}>
            <Button
              disabled
              variant="contained"
              color="primary"
              sx={styles.buyTokens}
              onClick={handleBuyTokens}>
              <img src={TokenIcon} alt="Purchase Tokens Icon" />
              <span>{userMetaTokensRef.balance} Tokens</span>
            </Button>
          </div>

          <SearchBarMobile />

          <Button
            variant="contained"
            color="secondary"
            sx={styles.menuItem}
            onClick={(e) => handleGoToURL(e, '/home')}>
            <Home sx={styles.menuIcon} />
            <MenuItemText>Home</MenuItemText>
          </Button>

          <Button
            variant="contained"
            color="secondary"
            sx={styles.menuItem}
            onClick={(e) => handleGoToURL(e, `/profile/${currentUser.id}`)}>
            <AccountCircle sx={styles.menuIcon} />
            <MenuItemText>Profile</MenuItemText>
          </Button>

          <Button
            variant="contained"
            color="secondary"
            sx={styles.menuItem}
            onClick={(e) => handleGoToURL(e, '/discussion-room')}>
            <DiscussionsMobile />
            <MenuItemText>Discussion Room</MenuItemText>
          </Button>

          <Button
            variant="contained"
            color="secondary"
            sx={styles.menuItem}
            onClick={(e) => handleGoToURL(e, '/organizations')}>
            <Business sx={styles.menuIcon} />
            <MenuItemText>My Organizations</MenuItemText>
          </Button>

          <Button
            variant="contained"
            color="secondary"
            sx={styles.menuItem}
            onClick={(e) => handleGoToURL(e, '/my-courses')}>
            <School sx={styles.menuIcon} />
            <MenuItemText>My Courses</MenuItemText>
          </Button>

          {(isAdmin || isInstructor) && (
            <Button
              variant="contained"
              color="secondary"
              sx={styles.menuItem}
              onClick={(e) => handleGoToURL(e, '/organization-admin/dashboard')}>
              <Report sx={styles.menuIcon} />
              <MenuItemText>UConn Dashboard</MenuItemText>
            </Button>
          )}

          <Button
            variant="contained"
            color="secondary"
            sx={styles.menuItem}
            onClick={handleInviteFriends}>
            <PersonAdd sx={styles.menuIcon} />
            <MenuItemText>Invite Your Friends</MenuItemText>
          </Button>

          <Button
            variant="contained"
            color="secondary"
            sx={styles.menuItem}
            onClick={(e) => handleGoToURL(e, '/support')}>
            <Help sx={styles.menuIcon} />
            <MenuItemText>Support</MenuItemText>
          </Button>

          <Button
            variant="contained"
            color="secondary"
            sx={styles.menuItem}
            onClick={(e) => handleGoToURL(e, '/my-account')}>
            <Settings sx={styles.menuIcon} />
            <MenuItemText>My Account</MenuItemText>
          </Button>

          <LogoutContainer>
            <Button variant="outlined" color="secondary" sx={styles.logout} onClick={handleLogout}>
              Logout
            </Button>
          </LogoutContainer>
        </div>
      </ClickAwayListener>
    </>
  )
}

export default DrawerPrivate
