import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import withStyles from '@mui/styles/withStyles';
import { Skeleton } from '@mui/material';

const animation = 'pulse'

const wrapperStyles = {
  backgroundColor: 'rgba(0,0,0,0)'
}

const styles = () => ({
  question: {
    backgroundColor: 'rgba(250, 250, 250, .2)',
    display: 'block'
  },
  option: {
    display: 'inline-block',
    marginTop: '1rem'
  }
})

class SkeletonPoll extends Component {
  render() {
    const { classes, count, style, color } = this.props
    const styleOverrides = style || {}
    const backgroundColor = color || 'rgba(0, 0, 0, 0.04)'
    const numSkeletons = count || 1
    let skeletons = []

    for (var i = 0; i < numSkeletons; i++) {
      skeletons.push(
        <div key={i} style={{ ...wrapperStyles, ...styleOverrides }}>
          <Skeleton
            animation={animation}
            variant="text"
            width={'100%'}
            height={'1.2rem'}
            className={classes.question}
            style={{ backgroundColor }}
          />
          <Skeleton
            animation={animation}
            variant="text"
            width={'80%'}
            height={'1.2rem'}
            className={classes.question}
            style={{ backgroundColor }}
          />
          <div style={{ fontSize: '0' }}>
            <Skeleton
              animation={animation}
              variant="rectangular"
              width={'40%'}
              height={'5rem'}
              className={classes.option}
              style={{ backgroundColor }}
            />
            <Skeleton
              animation={animation}
              variant="rectangular"
              width={'40%'}
              height={'5rem'}
              className={classes.option}
              style={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
            />
          </div>
        </div>
      )
    }

    return skeletons
  }
}

SkeletonPoll.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withRouter(withStyles(styles)(SkeletonPoll))
