import styled from 'styled-components'
import theme from '../../../../res/theme.js'
// import config from "../../../../config.js"

export const MainContainer = styled.div`
  background-color: white;
  display: block;
  padding: 2rem;
  margin-bottom: 1rem;
  border-radius: 0.3rem;
  box-sizing: border-box;
  box-shadow: 0px 5px 5px -2px rgba(0, 0, 0, 0.2);
  border: 1px solid #dcdddd;
`

export const InnerContainer = styled.div`
  display: block;
  min-height: 6rem;
  text-align: center;
`

// export const BlueCircle = styled.div`
//   background-color: rgb(29, 73, 132);
//   display: block;
//   float: ${({ float }) => float};
//   width: 6rem;
//   height: 6rem;
//   margin: ${({ margin }) => margin};
//   border-radius: 50%;
//   position: relative;

//   & > img:nth-child(1) {
//     width: 4rem;
//     height: auto;
//     position: absolute;
//     top: 47%;
//     left: 53%;
//     transform: translateX(-50%) translateY(-50%);
//   }
// `

export const ContentContainer = styled.div`
  display: block;
  overflow: hidden;
  margin: ${({ margin }) => margin};
`

export const Header = styled.p`
  text-align: ${({ textAlign }) => textAlign};
  line-height: 1.6rem;
  font-family: ${theme.typography.fontFamily};
  font-size: 1.4rem;
  font-weight: ${theme.typography.fontWeight.bold};
  color: ${theme.typography.fontColor.bodyCopy};
`

export const Subheader = styled.p`
  margin-top: 1rem;
  text-align: ${({ textAlign }) => textAlign};
  line-height: 1.3rem;
  font-family: ${theme.typography.fontFamily};
  font-size: ${theme.typography.fontSizes.regular};
  font-weight: ${theme.typography.fontWeight.regular};
  color: ${theme.typography.fontColor.bodyCopy};
`

export const ButtonContainer = styled.div`
  ${'' /* margin-top: 1.5rem; */}
  text-align: ${({ textAlign }) => textAlign};
`
