import React from 'react'
import { useReducer, useContext, useEffect, useCallback, useState, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { updateProfileImage } from '../../../../redux/thunks/profiles/updateProfileImage'
import { updateOrgProfileByID } from '../../../../redux/thunks/organizations/updateOrgImageByID'
import getCroppedImg from '../functions/getCroppedImage'
import ReactEasyCropper from 'react-easy-crop'
import getAvatar from '../../../../utils/functions/getAvatar'
import UserAvatar from '../../user-avatar/user-avatar'
import FormError from '../../../alerts/form-error'
import ButtonBase from '@mui/material/ButtonBase'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Slider from '@mui/material/Slider'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto'
import makeStyles from '@mui/styles/makeStyles'
import {
  ContentContainer,
  CropperContainer,
  ButtonContainer,
  UploadButtonContainer,
  FormControlsContainer
} from '../styled/image-upload-comps'
import { ThemeContext } from 'styled-components'
import ratioReducer from '../functions/ratioReducer'

const useStyles = makeStyles((theme) => ({
  button: {
    display: 'block',
    minWidth: '12rem',
    margin: '2rem auto',
    fontSize: '1.125rem', // 18px
    textTransform: 'capitalize',
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light
    }
  },
  cancelButton: {
    display: 'inline-block',
    width: '8rem',
    marginRight: '0.5rem',
    fontSize: '1.125rem', // 18px
    textTransform: 'capitalize',
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.cancelButton.light,
    '&:hover': {
      backgroundColor: theme.palette.cancelButton.dark
    }
  },
  saveButton: {
    display: 'inline-block',
    minWidth: '8rem',
    marginLeft: '0.5rem',
    fontSize: '1.125rem', // 18px
    textTransform: 'capitalize',
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light
    }
  },
  slider: {
    width: '50%',
    color: theme.palette.secondary.main
  }
}))

function ImageEditor({
  handleClose,
  organizationId,
  postForm,
  setPostImage,
  postImagePreview,
  setPostImagePreview,
  isImageDifferent,
  setIsImageDifferent,
  setShowTakeImage
}) {
  const classes = useStyles()
  const themeContext = useContext(ThemeContext)
  const imageFileRef = useRef(null)
  const [image, setImage] = useState(null)
  const [imagePreview, setImagePreview] = useState('')
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [state, dispatch] = useReducer(ratioReducer, { ratio: 1 / 1 })
  // const [rotation, setRotation] = useState(0)
  const rotation = 0
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [error, setError] = useState('')
  const dispatchRef = useDispatch()

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const submitCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(imagePreview, croppedAreaPixels, rotation)
      const file = croppedImage
      /*
        if postForm
        - return to PostForm component

        else 
        - fire api call for profile image 
      */
      if (postForm) {
        setPostImage(file)

        const reader = new FileReader()

        reader.onloadend = () => {
          setPostImagePreview(reader.result.toString())
          setShowTakeImage(true)
        }

        reader.readAsDataURL(file)
        handleClose()
      } else {
        if (organizationId) {
          // uploading to an organization
          dispatchRef(updateOrgProfileByID({ file, organizationId }))
            .then((data) => {
              if (!data.error) {
                console.log('[SUCCESS]: ', 'Successfully uploaded new organization image')

                // closing the dialog
                handleClose()
              } else {
                console.log('[FAIL]: ', 'Failed to upload organization image.')
                setError(data.message)
              }
            })
            .catch((err) => console.log('ERROR: error uploading organization image', err))
        } else {
          // uploading to a profile
          dispatchRef(updateProfileImage({ file }))
            .then((data) => {
              if (!data.error) {
                console.log('[SUCCESS]: ', 'Successfully uploaded new profile image')

                // closing the dialog
                handleClose()
              } else {
                console.log('[FAIL]: ', 'Failed to upload profile image.')
                setError(data.message)
              }
            })
            .catch((err) => console.log('ERROR: error uploading profile image', err))
        }
      }
    } catch (e) {
      console.error(e)
    }
  }, [
    croppedAreaPixels,
    dispatchRef,
    handleClose,
    imagePreview,
    organizationId,
    postForm,
    setPostImage,
    setPostImagePreview,
    setShowTakeImage
  ])

  const handleInputClick = (e) => {
    e.preventDefault()

    imageFileRef.current.click()
  }

  const handleFileSelect = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0]

      if (file.size > 3000000) {
        setError('Please select a smaller file.')
      } else if (file && file.type.substr(0, 5) === 'image') {
        setImage(file)
      } else {
        setImage(null)
      }
    } else {
      console.log('[ERROR]:')
    }
  }

  const handleCancel = () => {
    setImage('')
    setImagePreview('')
    handleClose()
  }

  const handleChangeRadioButtonGroup = (event) => {
    dispatch({ type: event.target.value })
  }

  useEffect(() => {
    if (image) {
      const reader = new FileReader()

      reader.onloadend = () => {
        setImagePreview(reader.result.toString())
        postForm && setIsImageDifferent(true)
      }

      reader.readAsDataURL(image)
    } else {
      setImagePreview(null)
    }
  }, [image, postForm, setIsImageDifferent])

  useEffect(() => {
    if (postForm) {
      dispatch({ type: 'portrait' })
    }
  }, [postForm])

  return (
    <ContentContainer>
      <FormError message={error} />
      <input
        style={{ display: 'none' }}
        accept="image/*"
        type="file"
        name="file"
        id="file"
        ref={imageFileRef}
        onChange={handleFileSelect}
      />
      {!imagePreview ? (
        <>
          {postForm ? (
            <UploadButtonContainer>
              <ButtonBase
                onClick={handleInputClick}
                sx={{
                  backgroundColor: 'secondary.light',
                  padding: '1rem',
                  borderRadius: '0.2rem',
                  boxShadow: '0px 7px 5px -2px rgba(100, 100, 100, 0.2)',
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'column'
                }}>
                <AddAPhotoIcon
                  sx={{ fontSize: '2.5rem', margin: '0.5rem 1rem', color: 'text.primary' }}
                />
                <Box sx={{ fontSize: '0.875rem', color: 'text.primary' }}>Upload Photo</Box>
              </ButtonBase>
            </UploadButtonContainer>
          ) : (
            <>
              <UserAvatar
                src={imagePreview ? imagePreview : getAvatar('')}
                width={'17rem'}
                height={'17rem'}
                borderWidth={'0.2rem'}
                verified={true}
              />
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={handleInputClick}>
                Choose an Image
              </Button>
            </>
          )}
        </>
      ) : (
        <>
          <CropperContainer>
            <ReactEasyCropper
              image={imagePreview}
              crop={crop}
              zoom={zoom}
              minZoom={0.5}
              restrictPosition={false}
              cropShape={postForm ? 'rect' : 'round'}
              zoomWithScroll={false}
              aspect={state.ratio}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
              style={{
                containerStyle: {},
                mediaStyle: {},
                cropAreaStyle: {
                  border: `0.25rem solid ${themeContext.palette.primary.main}`,
                  '&.CropAreaGrid': {
                    '&:before': {
                      border: `0.25rem solid ${themeContext.palette.primary.main}`
                    }
                  }
                }
              }}
              classes={{
                CropAreaGrid: {
                  '&:before': {
                    border: `0.25rem solid ${themeContext.palette.primary.main}`
                  }
                }
              }}
              // disableAutomaticStylesInjection
            />
          </CropperContainer>

          <FormControlsContainer>
            <Slider
              value={zoom}
              min={0.5}
              max={3}
              step={0.00000001}
              aria-labelledby="Zoom"
              className={classes.slider}
              onChange={(e, zoom) => setZoom(zoom)}
            />
            {postForm && (
              <FormControl sx={{ paddingTop: '1rem' }}>
                <RadioGroup
                  row
                  aria-labelledby="aspect-ratio-controlled-radio-buttons-group"
                  name="aspect-ratio-controlled-radio-buttons-group"
                  defaultValue={postForm ? 'portrait' : 'square'}
                  onChange={handleChangeRadioButtonGroup}>
                  <FormControlLabel
                    value="square"
                    control={
                      <Radio
                        sx={{
                          color: '#333',
                          '&.Mui-checked': {
                            color: 'primary.main'
                          }
                        }}
                      />
                    }
                    label="Square"
                    labelPlacement="top"
                  />
                  <FormControlLabel
                    value="portrait"
                    control={
                      <Radio
                        sx={{
                          color: '#333',
                          '&.Mui-checked': {
                            color: 'primary.main'
                          }
                        }}
                      />
                    }
                    label="Portrait"
                    labelPlacement="top"
                  />
                  <FormControlLabel
                    value="landscape"
                    control={
                      <Radio
                        sx={{
                          color: '#333',
                          '&.Mui-checked': {
                            color: 'primary.main'
                          }
                        }}
                      />
                    }
                    label="Landscape"
                    labelPlacement="top"
                  />
                </RadioGroup>
              </FormControl>
            )}
            <ButtonContainer>
              <Button
                color="secondary"
                variant="contained"
                className={classes.cancelButton}
                onClick={handleCancel}>
                Cancel
              </Button>
              <Button
                color="primary"
                variant="contained"
                className={classes.saveButton}
                onClick={submitCroppedImage}>
                Save Image
              </Button>
            </ButtonContainer>
          </FormControlsContainer>
        </>
      )}
    </ContentContainer>
  )
}

export default ImageEditor
