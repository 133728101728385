//////////////////
// ACTION TYPES //
//////////////////
export const bulletinActionTypes = {
    SET_BULLETINS: 'SET_BULLETINS',
    ADD_BULLETINS: 'ADD_BULLETINS',
    CLEAR_BULLETINS: 'CLEAR_BULLETINS'
}

//////////////
// BULLETIN //
//////////////
const set = bulletins => {
    return {
        type: bulletinActionTypes.SET_BULLETINS,
        bulletins
    }
}

const add = bulletins => {
    return {
        type: bulletinActionTypes.ADD_BULLETINS,
        bulletins
    }
}

const clear = () => {
    return {
        type: bulletinActionTypes.CLEAR_BULLETINS
    }
}

export const bulletinsActions = {
    set,
    add,
    clear
}
