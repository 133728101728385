import config from '../../../config'
import { currentProfileActions } from '../../action-creators/current-profile.js'
import { actionResponseActions } from '../../action-creators/action-response.js'
import { authenticatedFetch } from '../../../utils/functions/authenticatedFetch'

export const updateProfileLocation = (params) => {
  let responseCode = ''
  const method = 'PUT'
  const url = `${config.baseURL}/api/profile/location/`
  const headers = {
    'Content-Type': 'application/json'
  }

  const body = JSON.stringify(params.fieldsToUpdate)

  return (dispatch) => {
    return authenticatedFetch(url, {
      method,
      body,
      headers,
      credentials: 'include'
    })
      .then((response) => {
        responseCode = response.status

        return response.json()
      })
      .then((data) => {
        if (responseCode === 200) {
          dispatch(currentProfileActions.setProfile(data.profile))
          dispatch(actionResponseActions.createActionResponse(data.message))

          return {
            error: false,
            code: responseCode,
            message: data.message
          }
        } else {
          return {
            error: true,
            code: responseCode,
            message: data.message
          }
        }
      })
      .catch((error) => {
        console.log('[Error]:', error)

        return {
          error: true,
          code: '',
          message: error
        }
      })
  }
}
