import Validator from 'validator'

const CreateRebuttalValidation = (state) => {
  // Rebuttal Validator
  if (Validator.isEmpty(state.rebuttal)) {
    return {
      error: 'Your discussion request is required'
    }
  } else if (!Validator.isLength(state.rebuttal, { min: 1, max: 3000 })) {
    return {
      error: 'Your discussion request must be 1-3000 characters long'
    }
  }

  // Emotion Validator
  if (Validator.isEmpty(state.postEmotion)) {
    return {
      error: 'Choose an emotion in order to request discussion.'
    }
  }

  return {
    error: ''
  }
}

export default CreateRebuttalValidation
