import styled from 'styled-components'
// import config from '../../../../../../config.js'

export const MainContainer = styled.div`
  /* browser fallback for non-stacking or image fails to load */
  background-color: ${(props) => props.theme.palette.secondary.main};
  /* browser fallback for non-stacking */
  background-image: url('https://res.cloudinary.com/headliner/image/upload/v1679885963/LandingPage/pang-yuhao-_kd5cxwZOK4-unsplash.jpg');
  background-image: linear-gradient(90deg, rgba(51, 51, 51, 0.8) 0%, rgba(51, 51, 51, 0.8) 100%),
    url('https://res.cloudinary.com/headliner/image/upload/v1679885963/LandingPage/pang-yuhao-_kd5cxwZOK4-unsplash.jpg');
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: ${({ padding }) => padding};
  box-sizing: border-box;
  position: relative;
  border-radius: 0.3rem 0.3rem 0 0;
`

export const Heading = styled.h1`
  line-height: 2.2rem;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: 2rem;
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  color: white;
  position: relative;
  z-index: 1;

  @media (max-width: 449px) {
    font-size: 1.75rem;
  }
`

export const Subheading = styled.h2`
  margin-top: 0.8rem;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.regular};
  font-weight: ${(props) => props.theme.typography.fontWeight.light};
  color: white;
  position: relative;
  z-index: 1;

  @media (max-width: 449px) {
    margin-top: 0.5rem;
  }
`

export const MenuContainer = styled.nav`
  display: block;
  height: ${({ height }) => height};
  margin-top: 1.5rem;
  text-align: left;
  font-size: 0;
  transition: height 0.3s ease-in-out;

  &:before {
    display: inline-block;
    content: '';
    width: 0;
    height: 100%;
    vertical-align: middle;
  }

  & > div:nth-child(n + 2) {
    margin-left: ${({ marginLeft }) => marginLeft};
  }
`

export const MenuItemContainer = styled.div`
  background-color: ${({ bgColor }) => bgColor};
  display: inline-block;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  vertical-align: middle;
  /* border-radius: 0.3rem; */
  position: relative;
  z-index: 1;
  cursor: pointer;
  transition: height 0.3s ease-in-out, background-color 0.2s ease-in-out;

  &:hover {
    background-color: ${(props) => props.theme.palette.secondary.main};
  }
`

export const MenuItemPositionContainer = styled.div`
  display: inline-block;
  width: 85%;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
`

export const MenuItemText = styled.h3`
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.regular};
  font-weight: ${(props) => props.theme.typography.fontWeight.bold};
  color: ${(props) => props.theme.palette.text.primary};

  @media (max-width: 449px) {
    font-size: ${(props) => props.theme.typography.fontSizes.short};
  }

  @media (max-width: 339px) {
    font-size: ${(props) => props.theme.typography.fontSizes.small};
  }
`
