import Chain from '../../../../utils/classes/chain.js'

const load = (
  tagIds,
  disableFromInterests,
  disableFromInsights,
  disableRandomSuggestions,
  setIsLoading,
  setOrganizationIds,
  setTags,
  actions
) => {
  const _getSuggestedConnections = () => {
    return actions.getSuggestedConnections({
      disableFromInterests,
      disableFromInsights,
      disableRandomSuggestions,
      tagIds
    })
  }

  const chain = new Chain()

  return _getSuggestedConnections()
    .then((data1) => {
      if (data1.error) {
        chain.break('Failed to get suggested organizations.')

        return Promise.resolve()
      }

      setOrganizationIds({
        ...data1.organizationIds,
        random: [...data1.organizationIds.random],
        fromInterests: [...data1.organizationIds.fromInterests],
        fromInsights: [...data1.organizationIds.fromInsights]
      })

      const organizationIds = [
        ...data1.organizationIds.random,
        ...data1.organizationIds.fromInterests,
        ...data1.organizationIds.fromInsights
      ]

      if (organizationIds.length === 0) {
        chain.break('No suggested organizations found.')

        return Promise.resolve()
      }

      chain.break('Successfully retrieved suggested organizations.')

      return Promise.resolve()
    })
    .then((last_data) => {
      let { broken, log } = chain

      if (broken) {
        console.log('[DEBUG]: ', log)

        setTags(tagIds)
        setIsLoading(false)

        return Promise.resolve()
      }

      throw new Error('Unhandled promise chain logic.')
    })
    .catch((error) => {
      console.log('[ERROR]: ', error)
    })
}

export default load
