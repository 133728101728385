import React, { useEffect, useState, useReducer, createContext } from 'react'
import { Redirect, withRouter } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import find from 'lodash/find'
import { getOrganizationsByIDs } from '../../../redux/thunks/organizations/getOrganizationsByIDs'
import LoadOrganizationAssignedRoles from '../../data-loading/load-organization-assigned-roles/load-organization-assigned-roles'
import OrgHeader from './components/org-header/org-header'
import OrgDetails from './components/org-details/org-details'
import OrgTabs from './components/org-tabs/org-tabs'
import Posts from '../../partials/posts/posts'
import OrgPostFormContainer from './components/org-post-form/org-post-form-container'
import { useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import {
  MainContainer,
  ContentContainer,
  FeedContainer,
  FeedListContainer
} from './styled/organization-comps'
import OrganizationList from './components/org-list'
import CourseList from './components/org-courses'
import { set } from 'lodash'

const initState = {
  data: [],
  total: null,
  page: 1,
  limit: 10,
  moreToLoad: true,
  /** type RequestStatus = "idle" | "pending" | "fulfilled" | "rejected" */
  requestStatus: 'idle'
}

export const OrganizationContext = createContext(initState)

function Organization(props) {
  const { orgId } = props.match.params
  const [isLoading, setIsLoading] = useState(true)
  const [organizationsFound, setOrganizationsFound] = useState(true)
  const [organization, setOrganization] = useState({})
  /** type Tab = "posts" | "members" | "courses"  */
  const [tab, setTab] = useState('posts')
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const isDesktop = useMediaQuery(theme.breakpoints.up('1050px'))
  const dispatch = useDispatch()
  const organizations = useSelector((state) => state.organizations)
  const mixinComponents = []

  const [state, updateEvent] = useReducer((prev, next) => {
    return { ...prev, ...next }
  }, initState)

  useEffect(() => {
    if (isLoading) {
      dispatch(getOrganizationsByIDs({ organizationIds: [orgId] })).then((data) => {
        if (!data.error) {
          //  SET COURSES: courses: data?.organizations?.[0]?.course || []

          console.log('[SUCCESS]: ', 'Successfully retrieved organizations by IDs!')
        } else {
          console.log('[FAIL]: ', 'Failed to retrieve organizations by IDs.')
        }

        // find the current org by Id
        setOrganizationsFound(
          typeof data.organizations !== 'undefined' && data.organizations.length > 0
        )
        setIsLoading(false)
      })
    }
  }, [dispatch, isLoading, orgId])

  /** Wait until the organizations reducer has been populated to setOrganization  */
  useEffect(() => {
    if (typeof organizations !== 'undefined' && organizations.length > 0) {
      setOrganization(find(organizations, { _id: orgId }) || {})
    }
  }, [organization, organizations, orgId])

  // Redirect user if organization is not found
  if (!organizationsFound && !isLoading) {
    return <Redirect to="/page-not-found" />
  }

  useEffect(() => {
    // if isDesktop and tab is details, set tab to posts
    const handleTabChange = () => {
      setTab('posts')
    }

    if (isDesktop && tab === 'details') {
      handleTabChange()
    }
  }, [isDesktop, tab, setTab])

  // avoid rendering without data
  if (isLoading || !organization) return null

  return (
    <OrganizationContext.Provider value={[state, updateEvent]}>
      <MainContainer>
        <LoadOrganizationAssignedRoles
          organizationId={organization._id}
          disableRedux={true}
          enableAsync={true}>
          <OrgHeader organization={organization} isMobile={isMobile} />

          <ContentContainer>
            {!isMobile && <OrgDetails organization={organization} isMobile={isMobile} />}

            <FeedContainer>
              <OrgTabs
                tab={tab}
                setTab={setTab}
                totalCourses={organization?.courses?.length ?? 0}
              />

              <FeedListContainer>
                {tab === 'details' && (
                  <Box
                    sx={{
                      '@media (min-width: 1051px)': {
                        display: 'none'
                      }
                    }}>
                    <OrgDetails organization={organization} isMobile={isMobile} />
                  </Box>
                )}

                {tab === 'posts' && Object.keys(organization).length > 0 && (
                  <>
                    <OrgPostFormContainer _id={organization._id} />
                    <Posts
                      variant={'organization'}
                      tags={[]}
                      organizationId={organization._id}
                      searchStyle={{ backgroundColor: 'white', boxShadow: 'none !important' }}
                      searchVariant={'no-shadow'}
                      hideFilters={true}
                      mixinComponents={mixinComponents}
                    />
                  </>
                )}

                {tab === 'members' && (
                  <OrganizationList
                    id={orgId}
                    publicName={organization?.publicName || 'Organization'}
                  />
                )}

                {tab === 'courses' && (
                  <CourseList
                    courses={organization?.courses}
                    image={organization?.image}
                    legalName={organization?.legalName}
                  />
                )}
              </FeedListContainer>
            </FeedContainer>
          </ContentContainer>
        </LoadOrganizationAssignedRoles>
      </MainContainer>
    </OrganizationContext.Provider>
  )
}

export default withRouter(Organization)
