import { useState, useEffect } from 'react'
import Input from './course-setup-input'
import Select from './course-setup-select'
import CourseFormDialog from './course-form-dialog'
import CourseStepButtonContainer from './course-step-button-container'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
/** Validations */
import validateSubjectSelect from '../validations/validate-subject-select'
import validateTitle from '../validations/validate-title-input'
import validateFourDigitNumber from '../validations/validate-number-input'
import validateSeasonSelect from '../validations/validate-seasons-select'
import validateYearSelect from '../validations/validate-year-select'
import validateDescription from '../validations/validate-description-input'

/** Hooks */
/** subject state hooks */
import useSubjectErrorState from '../hooks/use-subject-error-state'
import useSubjectErrorsObjState from '../hooks/use-subject-errors-obj-state'
import useSubjectHelperTextState from '../hooks/use-subject-helper-text'
/** title state hooks */
import useTitleErrorState from '../hooks/use-title-error-state'
import useTitleErrorsObjState from '../hooks/use-title-errors-obj-state'
import useTitleHelperTextState from '../hooks/use-title-helper-text'
/** courseNumber state hooks */
import useNumberErrorState from '../hooks/use-number-error-state'
import useNumberErrorsObjState from '../hooks/use-number-errors-obj-state'
import useNumberHelperTextState from '../hooks/use-number-helper-text-state'
/** season state hooks */
import useSeasonErrorState from '../hooks/use-season-error-state'
import useSeasonErrorsObjState from '../hooks/use-season-errors-obj-state'
import useSeasonHelperTextState from '../hooks/use-season-helper-text-state'
/** year state hooks */
import useYearErrorState from '../hooks/use-year-error-state'
import useYearErrorsObjState from '../hooks/use-year-errors-obj-state'
import useYearHelperTextState from '../hooks/use-year-helper-text-state'
/** description state hooks */
import useDescriptionErrorState from '../hooks/use-description-error-state'
import useDescriptionErrorsObjState from '../hooks/use-description-errors-obj-state'
import useDescriptionHelperTextState from '../hooks/use-description-helper-text-state'

function CourseBasics({
  courseFields,
  handleSetCourseFields,
  handlePreviousStep,
  handleNextStep,
  activeStep,
  steps,
  setStepUpdated
}) {
  /** subject state hooks */
  const [subjectError, setSubjectError] = useSubjectErrorState('')
  const [subjectErrorsObj, setSubjectErrorsObj] = useSubjectErrorsObjState({})
  const [subjectHelperText, setSubjectHelperText] = useSubjectHelperTextState('')
  /** title state hooks */

  const [titleError, setTitleError] = useTitleErrorState(false)
  const [titleErrorsObj, setTitleErrorsObj] = useTitleErrorsObjState({})
  const [titleHelperText, setTitleHelperText] = useTitleHelperTextState('')
  /** number state hooks */
  const [numberError, setNumberError] = useNumberErrorState(false)
  const [numberErrorsObj, setNumberErrorsObj] = useNumberErrorsObjState({})
  const [numberHelperText, setNumberHelperText] = useNumberHelperTextState('')
  /** season state hooks */
  const [seasonError, setSeasonError] = useSeasonErrorState(false)
  const [seasonErrorsObj, setSeasonErrorsObj] = useSeasonErrorsObjState({})
  const [seasonHelperText, setSeasonHelperText] = useSeasonHelperTextState('')
  /** year state hooks */
  const [yearError, setYearError] = useYearErrorState(false)
  const [yearErrorsObj, setYearErrorsObj] = useYearErrorsObjState({})
  const [yearHelperText, setYearHelperText] = useYearHelperTextState('')
  /** description state hooks */
  const [descriptionError, setDescriptionError] = useDescriptionErrorState(false)
  const [descriptionErrorsObj, setDescriptionErrorsObj] = useDescriptionErrorsObjState({})
  const [descriptionHelperText, setDescriptionHelperText] = useDescriptionHelperTextState('')

  const [submitErrors, setSubmitErrors] = useState([])
  const [formErrors, setFormErrors] = useState(false)

  const validateNextStep = () => {
    const errorsExist = () => {
      let errors = false
      if (
        subjectError ||
        titleError ||
        numberError ||
        seasonError ||
        yearError ||
        descriptionError
      ) {
        errors = true
      }
      return errors
    }

    if (errorsExist()) {
      console.log('*** Errors exist ***')
      let errorsArr = []

      const errorObjects = [
        subjectErrorsObj,
        titleErrorsObj,
        numberErrorsObj,
        seasonErrorsObj,
        yearErrorsObj,
        descriptionErrorsObj
      ]

      for (const errorObj of errorObjects) {
        const messages = []

        for (const key in errorObj) {
          messages.push(errorObj[key])
        }
        errorsArr = errorsArr.concat(messages)
      }

      console.log('*** errorsArr ***', errorsArr)
      setSubmitErrors([...errorsArr])

      setFormErrors(true)

      return
    }

    handleNextStep()
  }

  const handleChange = (e) => {
    if (!e.target) {
      console.error('Event target is undefined')
      return
    }

    const { name, value } = e.target
    handleSetCourseFields({ [name]: value })
    setStepUpdated(true)
  }

  useEffect(() => {
    function checkErrors() {
      if (
        subjectError ||
        titleError ||
        numberError ||
        seasonError ||
        yearError ||
        descriptionError
      ) {
        return true
      }
      return false
    }

    function checkInputCompletion() {
      const { subject, title, courseNumber, season, year, desc } = courseFields

      if (subject && title && courseNumber && season && year && desc) {
        return true
      }
      return false
    }

    if (checkErrors() || !checkInputCompletion()) {
      setFormErrors(true)
    } else {
      setFormErrors(false)
    }
  }, [
    subjectError,
    titleError,
    numberError,
    seasonError,
    yearError,
    descriptionError,
    courseFields
  ])

  return (
    <MuiBox
    // component={'form'}
    // noValidate
    // autoComplete="off"
    // onSubmit={(e) => handleSubmit(e)}
    >
      <Select
        label="Subject"
        id="subject"
        name="subject"
        value={courseFields.subject}
        handleChange={handleChange}
        error={subjectError}
        setError={setSubjectError}
        errorsObj={subjectErrorsObj}
        setErrorsObj={setSubjectErrorsObj}
        helperText={subjectHelperText}
        setHelperText={setSubjectHelperText}
        validationFunc={validateSubjectSelect}
      />
      <Input
        label="Course Title"
        id="title"
        name="title"
        value={courseFields.title}
        handleChange={handleChange}
        error={titleError}
        setError={setTitleError}
        errorsObj={titleErrorsObj}
        setErrorsObj={setTitleErrorsObj}
        helperText={titleHelperText}
        setHelperText={setTitleHelperText}
        validationFunc={validateTitle}
      />
      <Input
        label="Course Number"
        name="courseNumber"
        value={courseFields.courseNumber}
        handleChange={handleChange}
        error={numberError}
        setError={setNumberError}
        errorsObj={numberErrorsObj}
        setErrorsObj={setNumberErrorsObj}
        helperText={numberHelperText}
        setHelperText={setNumberHelperText}
        validationFunc={validateFourDigitNumber}
      />
      ß
      <Select
        label="Season"
        id="season"
        name="season"
        value={courseFields.season}
        handleChange={handleChange}
        error={seasonError}
        setError={setSeasonError}
        errorsObj={seasonErrorsObj}
        setErrorsObj={setSeasonErrorsObj}
        helperText={seasonHelperText}
        setHelperText={setSeasonHelperText}
        validationFunc={validateSeasonSelect}
      />
      <Select
        label="Year"
        id="year"
        name="year"
        value={courseFields.year}
        handleChange={handleChange}
        error={yearError}
        setError={setYearError}
        errorsObj={yearErrorsObj}
        setErrorsObj={setYearErrorsObj}
        helperText={yearHelperText}
        setHelperText={setYearHelperText}
        validationFunc={validateYearSelect}
      />
      <Box sx={{ position: 'relative', width: '100%' }}>
        <Input
          label="Course Description"
          name="desc"
          multiline
          value={courseFields.desc}
          handleChange={handleChange}
          error={descriptionError}
          setError={setDescriptionError}
          errorsObj={descriptionErrorsObj}
          setErrorsObj={setDescriptionErrorsObj}
          helperText={descriptionHelperText}
          setHelperText={setDescriptionHelperText}
          validationFunc={validateDescription}
        />
        <MuiTextCount>{courseFields.desc.length}/5000</MuiTextCount>
      </Box>
      <CourseFormDialog errors={submitErrors} />
      <CourseStepButtonContainer
        handlePreviousStep={handlePreviousStep}
        handleNextStep={validateNextStep}
        activeStep={activeStep}
        steps={steps}
        formErrors={formErrors}
        editingCourse={courseFields.status !== 'pending'}
      />
    </MuiBox>
  )
}

export default CourseBasics

// ***************************** //
// *** MUI Styled Components *** //
// ***************************** //

const MuiBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
  margin: '1rem 0',
  padding: '2rem',
  borderRadius: '0.25rem',
  background: '#fff',
  position: 'relative'
}))

const MuiFlexColumnBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem'
}))

const MuiTextCount = styled(Typography)(({ theme }) => ({
  display: 'inline-block',
  color: theme.palette.text.primary,
  position: 'absolute',
  bottom: '0',
  right: '1rem'
}))
