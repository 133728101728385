import { loadedProfileActionTypes } from '../action-creators/loaded-profiles.js'
import setNestedPropFromString from '../../utils/functions/setNestedPropFromString.js'

const copyProfiles = (profiles) => {
  let keys = Object.keys(profiles)
  let profilesCopy = {}

  for (var i = 0; i < keys.length; i++) {
    profilesCopy[keys[i]] = {
      ...profiles[keys[i]],
      rating: { ...profiles[keys[i]].rating },
      personalAddress: {
        ...profiles[keys[i]].personalAddress,
        loc: profiles[keys[i]].personalAddress.loc
          ? {
              ...profiles[keys[i]].personalAddress.loc,
              coordinates: [...profiles[keys[i]].personalAddress.loc.coordinates]
            }
          : {}
      },
      tooltips: {
        ...profiles[keys[i]].tooltips
      },
      privacySettings: {
        ...profiles[keys[i]].privacySettings
      },
      tagsOfInterest: profiles[keys[i]].tagsOfInterest ? [...profiles[keys[i]].tagsOfInterest] : [],
      tagsOfKnowledge: profiles[keys[i]].tagsOfKnowledge
        ? [...profiles[keys[i]].tagsOfKnowledge]
        : [],
      courses: profiles[keys[i]].courses ? [...profiles[keys[i]].courses] : []
    }
  }

  return profilesCopy
}

const copyProfile = (profile) => {
  return {
    ...profile,
    rating: { ...profile.rating },
    personalAddress: {
      ...profile.personalAddress,
      loc: profile.personalAddress.loc
        ? {
            ...profile.personalAddress.loc,
            coordinates: [...profile.personalAddress.loc.coordinates]
          }
        : { coordinates: [0, 0] }
    },
    tooltips: {
      ...profile.tooltips
    },
    privacySettings: {
      ...profile.privacySettings
    },
    tagsOfInterest: profile.tagsOfInterest ? [...profile.tagsOfInterest] : [],
    tagsOfKnowledge: profile.tagsOfKnowledge ? [...profile.tagsOfKnowledge] : [],
    courses: profile.courses ? [...profile.courses] : []
  }
}

// FOR LOADED PROFILES
const loadedProfilesReducer = (state = {}, action) => {
  switch (action.type) {
    case loadedProfileActionTypes.SET_LOADED_PROFILES:
      return copyProfiles(action.profiles)

    case loadedProfileActionTypes.ADD_LOADED_PROFILES:
      var newProfiles1 = copyProfiles(state)

      let keys1 = Object.keys(action.profiles)

      for (var j = 0; j < keys1.length; j++) {
        newProfiles1[keys1[j]] = copyProfile(action.profiles[keys1[j]])
      }

      return newProfiles1

    case loadedProfileActionTypes.UPDATE_LOADED_PROFILE:
      var newProfiles2 = copyProfiles(state)
      let keys2 = Object.keys(action.fields)

      for (var k = 0; k < keys2.length; k++) {
        setNestedPropFromString(newProfiles2[action.userId], action.fields[keys2[k]], keys2[k])
      }

      newProfiles2[action.userId].whenModified = Date.now()

      return newProfiles2

    case loadedProfileActionTypes.ADD_LOADED_PROFILE_FOLLOWER:
      var newProfiles3 = copyProfiles(state)

      if (newProfiles3[action.userId]) {
        newProfiles3[action.userId].followers = newProfiles3[action.userId].followers + 1
      }

      return newProfiles3

    case loadedProfileActionTypes.REMOVE_LOADED_PROFILE_FOLLOWER:
      var newProfiles4 = copyProfiles(state)

      if (newProfiles4[action.userId]) {
        newProfiles4[action.userId].followers = newProfiles4[action.userId].followers - 1
      }

      return newProfiles4
    case loadedProfileActionTypes.CLEAR_LOADED_PROFILES:
      return {}
    default:
      return state
  }
}

export default loadedProfilesReducer
