import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import find from 'lodash/find'
import Start from './components/start/start'
import ConfirmAddress from './components/confirm-address/confirm-address'
import Finish from './components/finish/finish'

function LocationForm() {
  const { orgId } = useParams()
  const [step, setStep] = useState('start')
  const [candidate, setCandidate] = useState({})
  const organizations = useSelector(({ organizations }) => organizations)
  const organization = find(organizations, { _id: orgId })

  if (!organization) return null

  return (
    <>
      {step === 'start' && (
        <Start setStep={setStep} setCandidate={setCandidate} organization={organization} />
      )}
      {step === 'confirm-address' && (
        <ConfirmAddress setStep={setStep} candidate={candidate} organizationId={orgId} />
      )}
      {step === 'finish' && <Finish setStep={setStep} />}
    </>
  )
}

export default LocationForm
