//////////////////
// ACTION TYPES //
//////////////////

export const organizationsActionTypes = {
  SET_ORGANIZATIONS: 'SET_ORGANIZATIONS',
  ADD_ORGANIZATIONS: 'ADD_ORGANIZATIONS',
  UPDATE_ORGANIZATION: 'UPDATE_ORGANIZATION',
  UPLOAD_ORGANIZATION_IMAGE: 'UPLOAD_ORGANIZATION_IMAGE',
  // SUBTRACT_ORGANIZATION_FOLLOWING: 'SUBTRACT_ORGANIZATION_FOLLOWING',
  CLEAR_ORGANIZATIONS: 'CLEAR_ORGANIZATIONS'
}

/////////////
// ACTIONS //
/////////////

// CURRENT ORGANIZATION
const set = (organizations) => {
  return {
    type: organizationsActionTypes.SET_ORGANIZATIONS,
    organizations
  }
}

const add = (organizations) => {
  return {
    type: organizationsActionTypes.ADD_ORGANIZATIONS,
    organizations
  }
}

const update = (params) => {
  return {
    type: organizationsActionTypes.UPDATE_ORGANIZATION,
    organizationId: params.organizationId,
    updatedFields: params.updatedFields
  }
}

const upload = (params) => {
  return {
    type: organizationsActionTypes.UPLOAD_ORGANIZATION_IMAGE,
    organizationId: params.organizationId,
    updatedFields: params.updatedFields
  }
}

const clear = () => {
  return {
    type: organizationsActionTypes.CLEAR_ORGANIZATIONS
  }
}

export const organizationsActions = {
  set,
  add,
  update,
  upload,
  // removeFollowing,
  clear
}
