import React from 'react'
import styled from 'styled-components'
import CondensedLogo from '../../media/logo/condensed-green.png'

const Container = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  & > img {
    height: 8rem;
    width: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
`

const AppLoader = () => {
  return (
    <Container>
      <img src={CondensedLogo} alt="CivilTalk Logo" />
    </Container>
  )
}

export default AppLoader
