// *** ACTION TYPES *** //
export const rolesActionTypes = {
  SET_ROLES: 'SET_ROLES',
  CLEAR_ROLES: 'CLEAR_ROLES'
}

/* ROLES ACTIONS */
const set = (roles) => {
  return {
    type: rolesActionTypes.SET_ROLES,
    roles
  }
}

const clear = () => {
  return {
    type: rolesActionTypes.CLEAR_ROLES
  }
}

// ************************** //
export const rolesActions = {
  set,
  clear
}
