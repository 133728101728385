import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'

function DownloadModal(props) {
  const { openModal, setOpenModal, img, type, desc } = props

  const handleClose = () => {
    setOpenModal(false)
  }

  const handleDownload = (e) => {
    e.preventDefault()

    fetch(e.target.href, {
      method: 'GET',
      headers: {}
    })
      .then((response) => {
        response.arrayBuffer().then((buffer) => {
          const url = window.URL.createObjectURL(new Blob([buffer], { type: 'image/png' }))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'CivilTalk_Certified_UConn_Badge.png') //or any other extension
          document.body.appendChild(link)
          link.click()
        })
      })
      .catch((error) => {
        console.log('[ERROR]: file download error', error)
      })
  }

  return (
    <Dialog open={openModal} onClose={handleClose}>
      <DialogContent>
        <img
          src={img}
          alt={desc}
          style={{ width: '15rem', height: '15rem', borderRadius: '50%' }}
        />

        <DialogActions>
          <Button
            color="secondary"
            variant="contained"
            onClick={handleClose}
            sx={{ marginRight: '1rem' }}>
            Cancel
          </Button>

          <Button color="primary" variant="contained" onClick={handleDownload}>
            Download
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}

export default DownloadModal
