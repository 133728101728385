import { verificationAttemptActionTypes } from '../action-creators/verification-attempts.js'
import _ from 'lodash'
import nestedSort from '../../utils/functions/nestedSort.js'

const copyVerificationAttempts = (verificationAttempts) => {
  return verificationAttempts.map((verificationAttempt) => {
    return {
      ...verificationAttempt,
      stripe: { ...verificationAttempt.stripe }
    }
  })
}

// FOR VERIFICATION ATTEMPTS
const verificationAttemptsReducer = (state = [], action) => {
  switch (action.type) {
    case verificationAttemptActionTypes.SET_VERIFICATION_ATTEMPTS:
      return nestedSort(copyVerificationAttempts(action.verificationAttempts), -1, 'whenCreated')
    case verificationAttemptActionTypes.ADD_VERIFICATION_ATTEMPTS:
      let verificationAttempts1 = copyVerificationAttempts(state)

      for (var i = 0; i < action.verificationAttempts.length; i++) {
        const existingObjIndex = _.findIndex(verificationAttempts1, {
          _id: action.verificationAttempts[i]._id
        })

        if (existingObjIndex === -1) {
          verificationAttempts1.push(action.verificationAttempts[i])
        } else {
          verificationAttempts1[existingObjIndex] = action.verificationAttempts[i]
        }
      }

      return nestedSort(verificationAttempts1, -1, 'whenCreated')
    case verificationAttemptActionTypes.CANCEL_VERIFICATION_ATTEMPT:
      let verificationAttempts2 = copyVerificationAttempts(state)
      const existingObjIndex = _.findIndex(verificationAttempts2, {
        _id: action.verificationAttemptId
      })

      if (existingObjIndex > -1) {
        verificationAttempts2[existingObjIndex].status = 'canceled'
        verificationAttempts2[existingObjIndex].isComplete = true
      } else {
        console.log('[REDUX ERROR]: Could not cancel verification attempt.')
      }

      return nestedSort(verificationAttempts2, -1, 'whenCreated')
    case verificationAttemptActionTypes.CLEAR_VERIFICATION_ATTEMPTS:
      return []
    default:
      return state
  }
}

export default verificationAttemptsReducer
