import React, { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { markAllNotificationsAsRead } from '../../../../../redux/thunks/notifications/markAllNotificationsAsRead'
import PropTypes from 'prop-types'
import {
  IconButton,
  ClickAwayListener,
  Popper,
  Grow,
  Paper,
  MenuList,
  MenuItem
} from '@mui/material'
import { Mail, Drafts, MoreHoriz, PersonAdd, Forum, Public, Settings } from '@mui/icons-material'
import withStyles from '@mui/styles/withStyles'
import {
  NavItemContainer,
  NavItemHeader,
  RedNavItem,
  NavItem
} from '../styled/notifications-menu-comps'

const styles = (theme) => ({
  dropdownButton: {
    minHeight: '2rem',
    height: '2rem',
    minWidth: '2rem',
    width: '2rem',
    position: 'absolute',
    right: '0.5rem',
    top: '50%',
    transform: 'translateY(-50%)'
  },
  menuIcon: {
    color: theme.palette.common.white,
    fontSize: '1.5rem',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translateX(-50%) translateY(-50%)'
  },
  menuList: {
    padding: 0,
    width: '14rem'
  },
  menuItem: {
    padding: '.4rem 1rem'
  },
  menuItemHeader: {
    padding: '.4rem 1rem'
  },
  endMenuItem: {
    padding: '.4rem 1rem',
    borderTop: '1px solid rgba(0,0,0, 0.08)'
  },
  selectedMenuItem: {
    backgroundColor: 'rgba(34, 51, 91, 0.3)',
    padding: '.4rem 1rem'
  },
  dropdownIcon: {
    display: 'inline-block',
    verticalAlign: 'middle',
    fontSize: '1.1rem',
    margin: '.2rem .7rem 0 0'
  },
  redDropdownIcon: {
    color: theme.palette.text.primary,
    display: 'inline-block',
    verticalAlign: 'middle',
    fontSize: '1.1rem',
    margin: '.2rem .7rem 0 0'
  }
})

function NotificationsMenu(props) {
  const { classes, filter, setFilter } = props
  const dispatch = useDispatch()
  // Menu Controls
  const [open, setOpen] = useState(false)
  const anchorRef = useRef(null)

  const handleToggle = (e) => {
    e.stopPropagation()
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (e) => {
    if (anchorRef.current && anchorRef.current.contains(e.target)) {
      return
    }

    setOpen(false)
  }

  const prevOpen = useRef(open)
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }

    prevOpen.current = open
  }, [open])

  const handleFilterChange = (updatedFilter) => {
    setOpen(false)
    setFilter(updatedFilter)
  }

  const handleMarkAllNotificationsAsRead = () => {
    dispatch(markAllNotificationsAsRead()).then((data) => {
      if (!data.error) {
        console.log('[SUCCESS]: ', 'Successfully marked all notifications as read!')

        setOpen(false)
      } else {
        console.log('[FAIL]: ', 'Failed to mark all notifications as read.')
      }
    })
  }

  return (
    <>
      <IconButton
        variant="contained"
        color="primary"
        className={classes.dropdownButton}
        ref={anchorRef}
        aria-owns={open ? 'notifications-menu-dropdown' : undefined}
        aria-haspopup="true"
        onClick={(e) => handleToggle(e)}
        size="large">
        <MoreHoriz className={classes.menuIcon} />
      </IconButton>

      <Popper
        open={open}
        disablePortal={true}
        anchorEl={anchorRef.current}
        placement="bottom-end"
        transition
        style={{ zIndex: 1000 }}>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            id="notifications-menu-dropdown"
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
            }}>
            <Paper>
              <ClickAwayListener onClickAway={(e) => handleClose(e)}>
                <MenuList className={classes.menuList}>
                  <MenuItem className={classes.menuItemHeader}>
                    <NavItemHeader>Filter by:</NavItemHeader>
                  </MenuItem>
                  <MenuItem
                    className={filter === 'unread' ? classes.selectedMenuItem : classes.menuItem}
                    onClick={() => handleFilterChange('unread')}>
                    <Mail className={classes.dropdownIcon} />
                    <NavItem>Unread</NavItem>
                  </MenuItem>
                  <MenuItem
                    className={
                      filter === 'connections' ? classes.selectedMenuItem : classes.menuItem
                    }
                    onClick={() => handleFilterChange('connections')}>
                    <PersonAdd className={classes.dropdownIcon} />
                    <NavItem>Connections</NavItem>
                  </MenuItem>
                  <MenuItem
                    className={filter === 'activity' ? classes.selectedMenuItem : classes.menuItem}
                    onClick={() => handleFilterChange('activity')}>
                    <Forum className={classes.dropdownIcon} />
                    <NavItem>Activity</NavItem>
                  </MenuItem>
                  <MenuItem
                    className={filter === 'community' ? classes.selectedMenuItem : classes.menuItem}
                    onClick={() => handleFilterChange('community')}>
                    <Public className={classes.dropdownIcon} />
                    <NavItem>Community</NavItem>
                  </MenuItem>
                  <MenuItem
                    className={filter === 'account' ? classes.selectedMenuItem : classes.menuItem}
                    onClick={() => handleFilterChange('account')}>
                    <Settings className={classes.dropdownIcon} />
                    <NavItem>Account</NavItem>
                  </MenuItem>
                  <MenuItem className={classes.endMenuItem}>
                    <NavItemContainer onClick={handleMarkAllNotificationsAsRead}>
                      <Drafts className={classes.redDropdownIcon} />
                      <RedNavItem>Mark all as read</RedNavItem>
                    </NavItemContainer>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  )
}

NotificationsMenu.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(NotificationsMenu)
