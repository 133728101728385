import { history } from '../../../../../../history/history'
import MuiTooltip from '@mui/material/Tooltip'
import Button from '@mui/material/Button'
import makeStyles from '@mui/styles/makeStyles'
import withStyles from '@mui/styles/withStyles'
import { useParams } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    top: '1.5rem',
    right: '1.5rem',
    padding: 0,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.secondary.main,
    borderRadius: '50%',
    minWidth: '0',
    minHeight: '0',
    height: '2.5rem',
    width: '2.5rem',
    [theme.breakpoints.down('md')]: {
      top: '1rem',
      right: '1rem',
      height: '2.5rem',
      width: '2.5rem'
    },
    [theme.breakpoints.down('sm')]: {
      top: '0.75rem',
      right: '0.75rem',
      height: '2.35rem',
      width: '2.35rem'
    },
    [theme.breakpoints.down(undefined)]: {
      top: '0.5rem',
      right: '0.5rem',
      height: '2.35rem',
      width: '2.35rem'
    }
  },
  label: {
    borderRadius: '50%'
  }
}))

const Tooltip = withStyles((theme) => ({
  tooltip: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.secondary.main,
    fontSize: '1rem'
  }
}))(MuiTooltip)

function EditButton() {
  const params = useParams()
  const classes = useStyles()

  return (
    <Tooltip title="Edit org details">
      <Button
        onClick={() => history.push(`/organization/${params.orgId}/settings/details`)}
        classes={{ root: classes.root, label: classes.label }}
        variant="contained">
        <span className="material-icons" style={{ fontSize: '1.3rem' }}>
          settings
        </span>
      </Button>
    </Tooltip>
  )
}

export default EditButton
