import config from '../../../config'
import { loadedTakesActions } from '../../action-creators/loaded-takes.js'
import objectToQueryString from '../../../utils/functions/object-to-query-string.js'
import { authenticatedFetch } from '../../../utils/functions/authenticatedFetch'

/**
 * Return an array of take objects.
 * @param {Object} params
 * @param {string} params.token - JWT Bearer Token
 * @param {string} params.afterDate - Javascript date timestamp
 * @param {string} params.filterKey - Key to filter takes by (location)
 * @param {object} params.profileAddress - Object literal holding user address info (city, county, state, zipCode, districtIds)
 * @param {array} params.userIds - an array of userIds
 * @param {num} params.limit - limit number of returns
 * @param {boolean} params.isLoadingPage - current state of page load TODO: do we need this param???
 * @param {Object} params.currentProfile
 * @param {string} params.courseId - used to load course feed
 * @@param {string} params.promptId - used to filter course feed by prompt (courseId will be empty string if promptId is sent)
 */

export const getTakes = (params) => {
  let responseCode = ''
  const method = 'GET'
  let url = `${config.baseURL}/api/take/`
  const headers = {
    'Content-Type': 'application/json'
  }

  // Use objectToQueryString util function to turn params into a query string and append it to URL
  const queryStringObject = { ...params }
  delete queryStringObject.token
  delete queryStringObject.isLoadingPage
  url += objectToQueryString(queryStringObject)

  // Thunk middleware knows how to handle functions.
  // It passes the dispatch method as an argument to the function,
  // thus making it able to dispatch actions itself.
  return (dispatch) => {
    console.log('Sending get takes request to CivilTalk API')

    // Return the fetch so react components calling 'store.dispatch()' can use 'then()'
    return authenticatedFetch(url, {
      method,
      headers,
      credentials: 'include'
    })
      .then((response) => {
        responseCode = response.status

        return response.json()
      })
      .then((data) => {
        if (responseCode === 200) {
          if (params.isLoadingPage) {
            dispatch(loadedTakesActions.setTakes(data.takes, params.sortBy))
          } else {
            dispatch(loadedTakesActions.addTakes(data.takes, params.sortBy))
          }

          return {
            error: false,
            code: responseCode,
            message: data.message,
            takes: data.takes
          }
        } else {
          console.log(data.message)

          return {
            error: true,
            code: responseCode,
            message: data.message
          }
        }
      })
      .catch((error) => {
        console.log(error)

        return {
          error: true,
          code: '',
          message: error
        }
      })
  }
}
