import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import Start from './components/start'
import Card from './components/card'

function AddPaymentMethod(props) {
  const { style } = props
  const [step, setStep] = useState('start')

  return (
    <div style={style || {}}>
      {step === 'start' && <Start setStep={setStep} />}
      {step === 'card' && <Card setStep={setStep} />}
    </div>
  )
}

export default withRouter(AddPaymentMethod)
