import { testimonialActionTypes } from "../action-creators/testimonials.js"

const copyTestimonials = (testimonials) => {
  return testimonials.map((testimonial) => {
    return { ...testimonial }
  })
}

// TESTIMONIALS
const testimonialsReducer = (state = [], action) => {
  switch (action.type) {
    case testimonialActionTypes.SET_TESTIMONIALS:
      return copyTestimonials(action.testimonials)

    case testimonialActionTypes.CLEAR_TESTIMONIALS:
      return []

    default:
      return state
  }
}

export default testimonialsReducer
