import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { updateProfileByUserID } from '../../../../../redux/thunks/profiles/updateProfileByUserID'
import { history } from '../../../../../history/history'
import LogoCondensedImage from '../../../../../media/logo/condensed-green.png'
import {
  InnerContainer,
  LogoCondensed,
  Title,
  Content,
  Link,
  // SingleLineContent,
  ButtonsContainer
} from './styled/welcome-comps'
import { Dialog, HomeButton, ProfileButton } from './styled/mui-styled-components'

function WelcomeDialog({ showWelcome }) {
  const [open] = useState(true)
  const { userId } = useSelector((state) => state.currentProfile)
  const dispatch = useDispatch()

  const handleClose = () => {
    dispatch(updateProfileByUserID({ fieldsToUpdate: { showWelcome: false } }))
  }

  const handleGoToProfile = () => {
    dispatch(updateProfileByUserID({ fieldsToUpdate: { showWelcome: false } }))

    history.push(`/profile/${userId}`)
  }

  const handleGoToSupport = () => {
    history.push('/support')
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      disablePortal
      maxWidth={'md'}>
      <InnerContainer>
        <div>
          <LogoCondensed src={LogoCondensedImage} alt="CivilTalk Logo Condensed (CT)" />
        </div>

        <Title id="alert-dialog-title">Welcome to CivilTalk</Title>

        <Content id="alert-dialog-description">
          We hope you enjoy your experience. If you have any questions or feedback for us, go to our{' '}
          <Link onClick={handleGoToSupport}>Support</Link> page anytime. We're continuously working
          hard to make CivilTalk better for you.
        </Content>

        <ButtonsContainer>
          <HomeButton onClick={handleClose} color="secondary" variant="contained">
            Okay
          </HomeButton>

          <ProfileButton onClick={handleGoToProfile} color="primary" variant="contained">
            Go to My Profile
          </ProfileButton>
        </ButtonsContainer>
      </InnerContainer>
    </Dialog>
  )
}

export default WelcomeDialog
