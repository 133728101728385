import { tokensTransactionModalActionTypes } from '../action-creators/tokens-transaction-modal.js'

const acceptableInitialStates = ['purchase', 'donate', 'transfer', 'subscribe']

// FOR Token Modal
const tokensTransactionModalReducer = (
  state = { open: false, initalState: '', userId: '', organizationId: '', takeId: '' },
  action
) => {
  switch (action.type) {
    case tokensTransactionModalActionTypes.SET_TOKENS_TRANSACTION_MODAL:
      const { initialState, userId, organizationId, takeId } = action.options
      const normalizedInitialState =
        acceptableInitialStates.indexOf(initialState) === -1 ? '' : initialState

      return {
        open: action.open,
        initialState: normalizedInitialState,
        userId: userId || '',
        organizationId: organizationId || '',
        takeId: takeId || ''
      }

    case tokensTransactionModalActionTypes.CLEAR_TOKENS_TRANSACTION_MODAL:
      return { open: false, initalState: '', userId: '', organizationId: '', takeId: '' }
    default:
      return state
  }
}

export default tokensTransactionModalReducer
