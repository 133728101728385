//////////////////
// ACTION TYPES //
//////////////////

export const notificationProfileActionTypes = {
  SET_NOTIFICATION_PROFILES: 'SET_NOTIFICATION_PROFILES',
  ADD_NOTIFICATION_PROFILES: 'ADD_NOTIFICATION_PROFILES',
  CLEAR_NOTIFICATION_PROFILES: 'CLEAR_NOTIFICATION_PROFILES'
}

/////////////
// ACTIONS //
/////////////

// LOADED PROFILES
const set = profiles => {
  return {
    type: notificationProfileActionTypes.SET_NOTIFICATION_PROFILES,
    profiles
  }
}

const add = profiles => {
  return {
    type: notificationProfileActionTypes.ADD_NOTIFICATION_PROFILES,
    profiles
  }
}

const clear = () => {
  return {
    type: notificationProfileActionTypes.CLEAR_NOTIFICATION_PROFILES
  }
}

export const notificationProfilesActions = {
  set,
  add,
  clear
}
