import { getFollowsByFollowingIDs } from '../../../../redux/thunks/follows/getFollowsByFollowingIDs.js'

const getUserFollows = (userIds, setIsLoading, dispatch) => {
  if (!userIds) {
    throw new Error('Invalid params <userIds> in getUserFollows')
  }

  if (!setIsLoading) {
    throw new Error('Invalid params <setIsLoading> in getUserFollows')
  }

  if (!dispatch) {
    throw new Error('Invalid params <dispatch> in getUserFollows')
  }

  dispatch(getFollowsByFollowingIDs({ userIds }))
    .then((data) => {
      if (!data.error) {
        console.log('[SUCCESS]: ', 'Successfully retrieved follows by following IDs!')
      } else {
        console.log('[FAIL]: ', 'Failed to retrieve follows by following IDs.')
      }

      setIsLoading(false)
    })
    .catch((e) => {
      setIsLoading(false)
    })
}

export default getUserFollows
