//////////////////
// ACTION TYPES //
//////////////////
export const myAccoladesActionTypes = {
  SET_MY_ACCOLADES: 'SET_MY_ACCOLADES',
  CLEAR_MY_ACCOLADES: 'CLEAR_MY_ACCOLADES'
}

///////////////
// MY ACCOLADES //
///////////////

const set = (accolades) => {
  return {
    type: myAccoladesActionTypes.SET_MY_ACCOLADES,
    accolades
  }
}

const clear = () => {
  return {
    type: myAccoladesActionTypes.CLEAR_MY_ACCOLADES
  }
}

export const myAccoladesActions = {
  set,
  clear
}
