import styled from 'styled-components'

/** wraps the inner contents of a MUI dialog component */
export const DialogFormContainer = styled.div`
  max-width: 60rem;

  @media (min-width: 901px) {
    padding: 2rem 0;
  }
`

export const ContentContainer = styled.div`
  display: block;
  font-size: 0;
  text-align: center;
  width: 45rem;
  padding: 4rem 0 2rem 0;

  @media (max-width: 799px) {
    width: 100%;
    padding: 0;
  }
`

export const CropperContainer = styled.div`
  position: relative;
  width: 80%;
  height: 25rem;
  background: #ffffff;
  margin: 0 auto;
`

export const ButtonContainer = styled.div`
  display: block;
  padding: 2rem 0 4rem 0;
  height: 4rem;
`

export const UploadButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 1rem 0;
`

export const FormControlsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1rem;
`
