import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { history } from '../../../../history/history'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
// import { history } from '../../../../history/history'
import { createSetupIntent } from '../../../../redux/thunks/users/createSetupIntent'
import { updateDefaultPaymentMethod } from '../../../../redux/thunks/users/updateDefaultPaymentMethod'
// import getName from '../../../../utils/functions/getName'
import FormError from '../../../alerts/form-error'
import ConfirmationDialog from '../../../dialogs/dialog-confirmation'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import { Button, CircularProgress } from '@mui/material'
import StripeIcon from '../../../../media/icons/stripe/png/powered_by_stripe_blurple.png'
import '../css/add-payment-method.css'
import {
  SummaryContainer,
  // TotalContainer,
  // TotalLabel,
  // Total,
  StripeImageCard
} from '../styled/add-payment-method-comps'

const useStyles = makeStyles(() =>
  createStyles({
    primaryButton: {
      display: 'inline-block',
      width: '12rem',
      marginTop: '1rem',
      textTransform: 'none'
    },
    circularProgress: {
      display: 'block',
      margin: '.2rem auto 0 auto',
      width: '18px !important',
      height: '18px !important',
      '& svg': {
        color: 'white'
      }
    }
  })
)

function Card() {
  const classes = useStyles()
  const [dialogOpen, setDialogOpen] = useState(false)
  const [disabled, setDisabled] = useState(true)
  const [processing, setProcessing] = useState(false)
  const currentUser = useSelector(({ currentUser }) => currentUser)
  const dispatch = useDispatch()
  const stripe = useStripe()
  const elements = useElements()
  const [error, setError] = useState('')

  const handleCardChange = (e) => {
    setDisabled(e.empty)
    setError(e.error ? e.error.message : '')
  }

  const handleUpdateDefaultPaymentMethod = (paymentMethodId) => {
    dispatch(updateDefaultPaymentMethod({ paymentMethodId }))
      .then((data) => {
        if (!data.error) {
          console.log('[SUCCESS]: ', 'Successfully updated default payment method.')

          history.push('/my-account/billing')
        } else {
          console.log('[FAIL]: ', 'Failed to update default payment method --> ', data.message)

          setError('Something went wrong. Please refresh your browser.')
          setProcessing(false)
        }
      })
      .catch((e) => {
        console.log('[ERROR]: ', e)

        processing(false)
      })
  }

  const handleCreateSetupIntent = () => {
    setProcessing(true)

    dispatch(createSetupIntent())
      .then((data) => {
        if (!data.error) {
          console.log('[SUCCESS]: ', 'Successfully created Stripe setup intent.')

          stripe
            .confirmCardSetup(data.clientSecret, {
              payment_method: {
                card: elements.getElement(CardElement),
                billing_details: {
                  email: currentUser.email,
                  phone: currentUser.phone.phoneNumber
                }
              }
            })
            .then((result) => {
              if (result.error) {
                console.log('[FAIL]: ', 'Failed to confirm Stripe setup intent.')

                setError(result?.error?.message || '')
                setProcessing(false)
              } else {
                console.log('[SUCCESS]: ', 'Successfully confirmed Stripe setup intent.')
                console.log({ result })

                handleUpdateDefaultPaymentMethod(result.setupIntent.payment_method)
              }
            })
        } else {
          console.log('[FAIL]: ', 'Failed to create Stripe setup intent.')

          setProcessing(false)
          setError('Something went wrong. Please refresh your browser and try again.')
        }
      })
      .catch((e) => {
        console.log('[ERROR]: ', e)

        setProcessing(false)
        setError(e?.message || 'Something went wrong.')
      })
  }

  // const handleConfirm = () => {
  //   setProcessing(true)

  //   stripe
  //     .createPaymentMethod({
  //       type: 'card',
  //       card: elements.getElement(CardElement),
  //       billing_details: {
  //         name: getName(currentUser),
  //         email: currentUser.email,
  //         phone: currentUser.phone.phoneNumber
  //       }
  //     })
  //     .then((data) => {
  //       if (!data.error) {
  //         console.log('[SUCCESS]: ', 'Successfully created Stripe payment method.')
  //         console.log({ data })

  //         setError('')
  //         handleCreateSetupIntent(data.paymentMethod.id)
  //       } else {
  //         console.log('[FAIL]: ', 'Failed to create Stripe payment method.')

  //         setError(data.message || data.error?.message || '')
  //         setProcessing(false)
  //       }
  //     })
  //     .catch((e) => {
  //       console.log('[ERROR]: ', e)

  //       processing(false)
  //     })
  // }

  const handleSubmit = (e) => {
    e.preventDefault()

    setDialogOpen(true)
  }

  return (
    <div>
      <ConfirmationDialog
        open={dialogOpen}
        primaryAction={handleCreateSetupIntent}
        secondaryAction={() => setDialogOpen(false)}
        primaryText={currentUser.stripe.customerId ? 'Confirm' : 'Confirm and Pay'}
        secondaryText={'Cancel'}
        title={
          currentUser.stripe.customerId ? 'Payment method confirmation' : 'Payment Confirmation'
        }
        body={
          currentUser.stripe.customerId ? (
            <span>
              Please confirm that you would like us to add the provided payment method to your
              account.
            </span>
          ) : (
            <span>
              Please confirm that you would like to pay and subscribe to CivilTalk using the
              provided payment method.
            </span>
          )
        }
      />

      <form id="payment-form" onSubmit={handleSubmit}>
        <FormError message={error} style={{ marginTop: '1.5rem' }} />

        <div
          style={{
            display: 'block',
            marginTop: '1rem',
            maxWidth: '25rem',
            position: 'relative'
          }}>
          <CardElement
            id="card-element"
            options={{
              style: {
                base: {
                  color: '#333',
                  fontFamily: 'sofia-pro, arial',
                  fontSmoothing: 'antialiased',
                  fontSize: '15px',
                  '::placeholder': {
                    color: '#333'
                  }
                },
                invalid: {
                  color: '#cc0000',
                  iconColor: '#cc0000'
                }
              }
            }}
            onChange={handleCardChange}
          />

          <SummaryContainer>
            <a href={'https://www.stripe.com'} target="_blank" rel="noopener noreferrer">
              <StripeImageCard src={StripeIcon} alt="Powered by Stripe Icon" />
            </a>
          </SummaryContainer>
        </div>

        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={classes.primaryButton}
          disabled={processing || disabled}>
          {processing ? (
            <CircularProgress color="secondary" className={classes.circularProgress} />
          ) : (
            'Submit'
          )}
        </Button>
      </form>
    </div>
  )
}

export default withRouter(Card)
