import config from '../../../config'
import { authenticatedFetch } from '../../../utils/functions/authenticatedFetch'
import { searchBarResultsActions } from '../../action-creators/search-bar-results.js'
import { searchResultsActions } from '../../action-creators/search-results.js'

/*
 * METHOD TO SEARCH BY TERMS
 */
export const search = (params) => {
  let responseCode = ''
  const method = 'GET'
  let url = `${config.baseURL}/api/search/`
  const headers = {
    'Content-Type': 'application/json'
  }

  // adding the search term to the url for the API request
  if (typeof params.query !== 'undefined' && params.query !== null) {
    url += '?query=' + params.query.toString()
  }

  // // adding a limit to the url for the API request
  if (typeof params.limit !== 'undefined' && params.limit !== null) {
    url += '&limit=' + params.limit
  }

  // Thunk middleware knows how to handle functions.
  // It passes the dispatch method as an argument to the function,
  // thus making it able to dispatch actions itself.
  return (dispatch) => {
    console.log('Sending search request to CivilTalk', url)

    // Return the fetch so react components calling 'store.dispatch()' can use 'then()'
    return authenticatedFetch(url, {
      method,
      headers,
      credentials: 'include'
    })
      .then((response) => {
        responseCode = response.status

        return response.json()
      })
      .then((data) => {
        if (responseCode === 200) {
          if (params.isFromSearchBar) {
            dispatch(searchBarResultsActions.setProfiles(data.profiles))
            dispatch(searchBarResultsActions.setTags(data.tags))
            dispatch(searchBarResultsActions.setOrgs(data.organizations))
          } else {
            dispatch(searchResultsActions.setProfiles(data.profiles))
            dispatch(searchResultsActions.setTags(data.tags))
            dispatch(searchResultsActions.setOrgs(data.organizations))
          }

          return {
            error: false,
            code: responseCode,
            message: data.message,
            profiles: data.profiles,
            tags: data.tags,
            organizations: data.organizations
          }
        } else {
          console.log(data.message)

          return {
            error: true,
            code: responseCode,
            message: data.message
          }
        }
      })
      .catch((error) => {
        console.log(error)

        return {
          error: true,
          code: '',
          message: error
        }
      })
  }
}
