import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { updateOrganizationLocationByID } from '../../../../../../../redux/thunks/organizations/updateOrganizationLocationByID'
import FormError from '../../../../../../alerts/form-error'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import { Button, CircularProgress } from '@mui/material'
import { Header, AddressContainer, AddressLine } from './styled/confirm-address-comps'
import theme from '../../../../../../../res/theme'

const useStyles = makeStyles(() => {
  return createStyles({
    submit: {
      display: 'block',
      width: '50%',
      margin: '0 auto',
      marginTop: '2rem',
      boxSizing: 'border-box',
      fontSize: '1rem',
      color: 'white',
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.light
      },
      '@media (max-width: 450px)': {
        width: '100%'
      }
    },
    circularProgress: {
      display: 'block',
      width: '17px !important',
      height: '17px !important',
      margin: '0 auto',
      '& svg': {
        color: 'white'
      }
    }
  })
})

const ConfirmAddress = ({ setStep, candidate, organizationId }) => {
  const classes = useStyles()
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState('')
  const dispatch = useDispatch()

  const handleSubmit = (e) => {
    e.preventDefault()
    setProcessing(true)

    dispatch(updateOrganizationLocationByID({ organizationId }))
      .then((data) => {
        if (!data.error) {
          console.log('[SUCCESS]: ', 'Successfully updated organization location.')

          setProcessing(false)
          setStep('finish')
        } else {
          console.log('[FAIL]: ', data.message)

          setProcessing(false)
          setError(data.message)
        }
      })
      .catch((e) => {
        setProcessing(false)
        setError(e.message)
      })
  }

  return (
    <>
      <FormError message={error} style={{ marginBottom: '0' }} />

      <Header>Please confirm that this address looks correct:</Header>

      <>
        <AddressContainer>
          {/* <AddressHeader>Please confirm that this address looks correct:</AddressHeader> */}

          <AddressLine>{candidate.deliveryLine1}</AddressLine>
          {candidate.deliveryLine2 && <AddressLine>{candidate.deliveryLine2}</AddressLine>}
          <AddressLine>{candidate.lastLine}</AddressLine>
        </AddressContainer>
      </>

      <Button variant="contained" color="primary" className={classes.submit} onClick={handleSubmit}>
        {processing ? (
          <CircularProgress color="secondary" className={classes.circularProgress} />
        ) : (
          'Confirm'
        )}
      </Button>
    </>
  )
}

export default ConfirmAddress
