import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import { Button } from '@mui/material'
import { Header } from './styled/finish-comps'
import theme from '../../../../../../../res/theme'

const useStyles = makeStyles(() => {
  return createStyles({
    submit: {
      display: 'block',
      width: '50%',
      margin: '0 auto',
      marginTop: '2rem',
      boxSizing: 'border-box',
      fontSize: '1rem',
      color: 'white',
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.light
      },
      '@media (max-width: 450px)': {
        width: '100%'
      }
    }
  })
})

const Finish = ({ setStep }) => {
  const classes = useStyles()

  return (
    <>
      <Header>Your location has been successfully updated!</Header>

      <Button
        variant="contained"
        color="primary"
        className={classes.submit}
        onClick={() => setStep('start')}>
        Return to Settings
      </Button>
    </>
  )
}

export default Finish
