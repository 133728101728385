import config from '../../../config'
import { loadedTakesActions } from '../../action-creators/loaded-takes.js'
import objectToQueryString from '../../../utils/functions/object-to-query-string.js'
import { authenticatedFetch } from '../../../utils/functions/authenticatedFetch'

/*
 * METHOD TO GET TAKE BY GUEST USER ID (Discussions)
 */
export const getTakesByGuestUserID = (params) => {
  let responseCode = ''
  const method = 'GET'
  let url = `${config.baseURL}/api/take/guest/`
  const headers = {
    'Content-Type': 'application/json'
  }

  // Use objecttoQueryString util function to turn params into a query string and append it to URL
  let queryStringObject = { ...params }
  delete queryStringObject.token
  delete queryStringObject.isLoadingPage
  url += objectToQueryString(queryStringObject)

  // Thunk middleware knows how to handle functions.
  // It passes the dispatch method as an argument to the function,
  // thus making it able to dispatch actions itself.
  return (dispatch) => {
    console.log('Sending get takes by guest user ID request to CivilTalk API')

    // Return the fetch so react components calling 'store.dispatch()' can use 'then()'
    return authenticatedFetch(url, {
      method,
      headers,
      credentials: 'include'
    })
      .then((response) => {
        responseCode = response.status

        return response.json()
      })
      .then((data) => {
        if (responseCode === 200) {
          if (params.isLoadingPage) {
            dispatch(loadedTakesActions.setTakes(data.takes))
          } else {
            dispatch(loadedTakesActions.addTakes(data.takes))
          }

          return {
            error: false,
            code: responseCode,
            message: data.message,
            takes: data.takes
          }
        } else {
          console.log(data.message)

          return {
            error: true,
            code: responseCode,
            message: data.message
          }
        }
      })
      .catch((error) => {
        console.log(error)

        return {
          error: true,
          code: '',
          message: error
        }
      })
  }
}
