const updateProfileValidation = (fields) => {
  const { coreBeliefs, educationalExperience, professionalExperience } = fields

  // CORE BELIEFS
  if (coreBeliefs.length >= 300) {
    return {
      error: 'Core beliefs can only be 300 characters long.',
      field: 'coreBeliefs'
    }
  }

  if (coreBeliefs.trim().length < 1) {
    return {
      error: 'Core beliefs is required.',
      field: 'coreBeliefs'
    }
  }

  // EDUCATIONAL EXPERIENCE
  if (educationalExperience.length > 300) {
    return {
      error: 'Educational experience can only be 300 characters long.',
      field: 'educationalExperience'
    }
  }

  if (educationalExperience.trim().length < 1) {
    return {
      error: 'Educational experience is required.',
      field: 'educationalExperience'
    }
  }

  // PROFESSIONAL EXPERIENCE
  if (professionalExperience.length > 300) {
    return {
      error: 'Professional experience can only be 300 characters long.',
      field: 'professionalExperience'
    }
  }

  if (professionalExperience.trim().length < 1) {
    return {
      error: 'Professional Experience is required.',
      field: 'professionalExperience'
    }
  }

  // TOPIC OF KNOWLEDGE
  // if (!topicOfKnowledge) {
  //   return {
  //     error: 'Topic of Knowledge must be an Object ID.',
  //     field: 'topicOfKnowledge'
  //   }
  // }

  return {
    error: '',
    field: ''
  }
}

export default updateProfileValidation
