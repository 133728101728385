import styled from 'styled-components'
import theme from '../../../../../../res/theme.js'
// import config from "../../../../../../config.js"

export const MainContainer = styled.div`
  background-color: white;
  display: block;
  padding: 2rem;
  margin-bottom: 1rem;
  border-radius: 0.3rem;
  box-sizing: border-box;
  box-shadow: 0px 5px 5px -2px rgba(0, 0, 0, 0.2);
  text-align: center;
`

export const InnerContainer = styled.div`
  display: block;
  min-height: 6rem;
  text-align: center;
`

export const ContentContainer = styled.div`
  display: block;
  overflow: hidden;
  margin: ${({ margin }) => margin};
  text-align: center;
`

export const Header = styled.p`
  text-align: ${({ textAlign }) => textAlign};
  line-height: 1.6rem;
  font-family: ${theme.typography.fontFamily};
  font-size: ${theme.typography.fontSizes.larger};
  font-weight: ${theme.typography.fontWeight.bold};
  color: ${theme.typography.fontColor.bodyCopy};
  text-align: center;
`

export const Subheader = styled.p`
  margin-top: 1rem;
  text-align: ${({ textAlign }) => textAlign};
  line-height: 1.3rem;
  font-family: ${theme.typography.fontFamily};
  font-size: ${theme.typography.fontSizes.short};
  font-weight: ${theme.typography.fontWeight.medium};
  color: ${theme.typography.fontColor.bodyCopy};
  text-align: center;
`

export const ButtonContainer = styled.div`
  text-align: center;
`
