import styled from 'styled-components'

export const Header = styled.h1`
  margin-bottom: 1rem;
  line-height: 2rem;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: 2rem;
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  color: ${(props) => props.theme.typography.fontColor.bodyCopy};

  @media (max-width: 600px) {
    line-height: 1.6rem;
    font-size: 1.6rem;
  }
`

export const TabContainer = styled.div`
  margin-bottom: 1rem;

  /* overflow-x: scroll; */
  overflow-y: hidden;

  /** Customize scrollbar in Chrome, Safari and Edge */
  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar-track {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    display: none;
  }

  /** mobile view */
  @media (max-width: 450px) {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
`

export const Tab = styled.div`
  background: ${(props) => props.theme.palette.secondary.light};
  padding: ${(props) => (props.selected ? '0.85rem 2rem 0.85rem 2rem' : '0.65rem 1.25rem')};
  border-radius: 0.3rem;
  text-align: center;
  display: inline-block;
  margin-right: 0.25rem;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.tall};
  font-weight: ${(props) => props.theme.typography.fontWeight.bold};
  color: ${(props) => props.theme.palette.text.primary};
  cursor: pointer;
  position: relative;

  &:hover {
    opacity: 0.5;
  }

  @media (max-width: 450px) {
    width: 8rem;
  }
`

export const ResultContainer = styled.div`
  box-shadow: 0px 7px 5px -2px rgba(100, 100, 100, 0.1);
  background-color: ${(props) => props.theme.palette.common.white};
  padding: 0.8rem 1rem;
  box-sizing: border-box;
  position: relative;

  &:not(:last-child) {
    border-bottom: 1px solid #eee;
  }
`

// export const ProfilePicture = styled.div`
//   background-repeat: no-repeat;
//   background-position: center;
//   background-size: cover;
//   display: block;
//   float: left;
//   width: 3.25rem;
//   height: 3.25rem;
//   margin-right: 1rem;
//   border-radius: 50%;
//   cursor: pointer;
// `

export const AvatarContainer = styled.div`
  display: block;
  float: left;
  margin-right: 1rem;
  border-radius: 50%;
  cursor: pointer;
`

export const ProfileInfoContainer = styled.div`
  display: block;
  width: 55%;
  margin-top: 0.1rem;
  min-height: 3.2rem;
  overflow: hidden;
`

export const ProfileName = styled.span`
  display: inline-block;
  text-transform: capitalize;
  line-height: 1.4rem;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.regular};
  font-weight: ${(props) => props.theme.typography.fontWeight.semiBold};
  color: ${(props) => props.theme.typography.fontColor.bodyCopy};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`

export const ProfileLocation = styled.p`
  display: block;
  margin-top: 0.1rem;
  text-transform: capitalize;
  line-height: 1.4rem;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.regular};
  font-weight: ${(props) => props.theme.typography.fontWeight.light};
  color: ${(props) => props.theme.typography.fontColor.bodyCopy};
`

export const ActionContainer = styled.div`
  display: block;
  position: absolute;
  top: 50%;
  right: 1rem;
  box-shadow: 0px 7px 5px -2px rgba(100, 100, 100, 0.1);
  transform: translateY(-50%);

  /** mobile view */
  @media (max-width: 600px) {
    right: 0.8rem;
  }
`

export const NoResults = styled.p`
  background-color: ${(props) => props.theme.palette.common.white};
  margin-top: 1rem;
  padding: 3rem 0;
  border-radius: 0.3rem;
  text-align: center;
  line-height: ${(props) => props.theme.typography.lineHeight};
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  color: ${(props) => props.theme.typography.fontColor.bodyCopy};
`
