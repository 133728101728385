import React from 'react'
import { useSelector } from 'react-redux'
import { isLoaded, isEmpty } from 'react-redux-firebase'
import { useLocation } from 'react-router-dom'
import { history } from '../../../../history/history'
import Listeners from './listeners'
import { IconButton, Tooltip, Badge } from '@mui/material'
import { QuestionAnswer } from '@mui/icons-material'
import makeStyles from '@mui/styles/makeStyles'
import withStyles from '@mui/styles/withStyles'
import createStyles from '@mui/styles/createStyles'

const useStyles = makeStyles((theme) => ({
  iconButton: {
    [`@media (max-width: 359)`]: {
      padding: 0
    }
  },
  iconButtonActive: {
    backgroundColor: theme.palette.common.white
  },
  icon: {
    width: '1.5rem',
    height: '1.5rem',
    color: theme.palette.text.primary,
    [`@media (max-width: 450px`]: {
      width: '1.8rem',
      height: '1.8rem'
    }
  },
  displayNone: {
    display: 'none'
  }
}))

const StyledBadge = withStyles(() =>
  createStyles({
    badge: {
      position: 'absolute',
      top: 0,
      right: '-0.125rem'
    }
  })
)(Badge)

function Discussions() {
  const classes = useStyles()
  const authRef = useSelector((state) => state.firebase.auth)
  const unreadDiscussions = useSelector(
    ({
      firestore: {
        data: { userMeta }
      }
    }) =>
      (userMeta &&
        userMeta[authRef.uid] &&
        userMeta[authRef.uid].unreadDiscussions &&
        Object.values(userMeta[authRef.uid].unreadDiscussions).reduce((t, n) => t + n)) ||
      0
  )

  const location = useLocation()
  const isPathActive = location.pathname === `/discussion-room`

  return (
    <>
      {isLoaded(authRef) && !isEmpty(authRef) && <Listeners auth={authRef} />}

      <>
        <Tooltip title="Discussions">
          <IconButton
            className={isPathActive ? classes.iconButtonActive : classes.iconButton}
            onClick={() => history.push('/discussion-room')}
            size="large">
            <StyledBadge
              badgeContent={unreadDiscussions}
              color="primary"
              overlap="circular"
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}>
              <QuestionAnswer className={classes.icon} role="img" />
            </StyledBadge>
          </IconButton>
        </Tooltip>
      </>
    </>
  )
}

export default Discussions
