import { pollActionTypes } from "../action-creators/polls.js"

const copyPolls = (polls) => {
  return polls.map((poll) => ({ ...poll }))
}

// FOR POLLS
const pollsReducer = (state = [], action) => {
  switch (action.type) {
    case pollActionTypes.SET_POLLS:
      return copyPolls(action.polls)

    case pollActionTypes.CLEAR_POLLS:
      return []
      
    default:
      return state
  }
}

export default pollsReducer
