import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import debounce from 'lodash/debounce'
import { analytics } from '../../../../firebase/firebase'
import { history } from '../../../../history/history'
import { searchBarResultsActions } from '../../../../redux/action-creators/search-bar-results'
import { mobileDrawerActions } from '../../../../redux/action-creators/mobile-drawer'
import { search } from '../../../../redux/thunks/search/search'
import getAvatar from '../../../../utils/functions/getAvatar'
import getName from '../../../../utils/functions/getName'
import getOrganizationImage from '../../../../utils/functions/getOrganizationImage'
import PropTypes from 'prop-types'
import withStyles from '@mui/styles/withStyles'
import { MenuItem, Divider } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import {
  SearchContainer,
  SearchInput,
  MobileResultsContainer,
  ProfileContainer,
  ProfilePicture,
  ProfileInfoContainer,
  ProfileName,
  ProfileLocation,
  AllResults
} from './styled/search-bar-comps'
import config from '../../../../config'

const styles = (theme) => ({
  searchIcon: {
    display: 'block',
    // height: '1.5rem',
    // width: '1.5rem',
    color: theme.palette.text.primary,
    position: 'absolute',
    // top: '.5rem',
    // left: '.8rem',
    // [`@media (max-width: ${config.UI.drawer_breakpoint.max})`]: {
    //   height: '2.1rem',
    //   width: '2.1rem',
    //   top: '.9rem',
    //   left: '1rem'
    // }
    height: '2.1rem',
    width: '2.1rem',
    top: '.9rem',
    left: '1rem'
  },
  dropdownHeader: {
    padding: '.2rem 1rem',
    textTransform: 'uppercase',
    fontSize: '1.25rem',
    color: theme.palette.text.primary,
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0)',
      cursor: 'default'
    }
  },
  dropdownItem: {
    padding: '0',
    height: 'auto',
    whiteSpace: 'normal'
  },
  divider: {
    margin: '.3rem'
  }
})

class SearchBarMobile extends Component {
  constructor(props) {
    super(props)

    this.state = {
      search: '',
      open: false
    }

    this.popperRef = React.createRef()

    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSearch = this.handleSearch.bind(this)
    this.handleGoToProfile = this.handleGoToProfile.bind(this)
    this.handleGoToTag = this.handleGoToTag.bind(this)
    this.handleGoToOrganization = this.handleGoToOrganization.bind(this)
    this.handleGoToSearchResults = this.handleGoToSearchResults.bind(this)
    this.handleClose = this.handleClose.bind(this)
  }

  handleInputChange(event) {
    const { value } = event.target

    this.setState(
      {
        ...this.state,
        search: value
      },
      () => {
        if (this.state.search && this.state.search.length > 1) {
          this.handleSearch()
        } else {
          this.handleClose()
        }
      }
    )
  }

  handleSearch = debounce(() => {
    this.props
      .search({
        query: this.state.search,
        limit: 3,
        isFromSearchBar: true
      })
      .then((data) => {
        console.log('[SUCCESS]: ', 'Search Success!')

        this.setState({
          ...this.state,
          open: true
        })
      })
  }, 300)

  handleGoToProfile(e) {
    e.preventDefault()
    e.stopPropagation()

    history.push('/profile/' + e.target.id)
    analytics.logEvent('click_search_item')

    this.setState(
      {
        ...this.state,
        search: '',
        open: false
      },
      () => {
        this.props.clearSearchBarResults()
        this.props.clearMobileDrawer()
      }
    )
  }

  handleGoToTag(e, tag) {
    e.preventDefault()
    e.stopPropagation()

    history.push(`/topic/${tag}`)
    analytics.logEvent('click_search_item')

    this.setState(
      {
        ...this.state,
        search: '',
        open: false
      },
      () => {
        this.props.clearSearchBarResults()
        this.props.clearMobileDrawer()
      }
    )
  }

  handleGoToOrganization(e) {
    e.preventDefault()
    e.stopPropagation()

    history.push(`/organization/${e.target.id}`)
    analytics.logEvent('click_search_item')

    this.setState(
      {
        ...this.state,
        search: '',
        open: false
      },
      () => {
        this.props.clearSearchBarResults()
        this.props.clearMobileDrawer()
      }
    )
  }

  handleGoToSearchResults(e) {
    e.preventDefault()
    e.stopPropagation()

    if (this.state.search !== '') {
      history.push('/search/' + this.state.search)
      analytics.logEvent('open_search')

      this.props.clearSearchBarResults()
      this.props.clearMobileDrawer()

      this.setState({
        ...this.state,
        search: '',
        open: false
      })
    }
  }

  handleClose() {
    this.setState({
      ...this.state,
      open: false
    })
  }

  render() {
    const { classes, searchBarResults } = this.props
    const { search, open } = this.state
    // const displayProps = history.location.pathname.includes('profile-setup')

    const profiles = searchBarResults.profiles.map((profile) => {
      const { userId, avatar, personalAddress, privacySettings, documentVerified } = profile
      const showLocation =
        personalAddress.city !== '' && personalAddress.state !== '' && privacySettings.showLocation
      const verified = documentVerified ? 1 : 0

      return (
        <MenuItem
          key={userId}
          id={userId}
          className={classes.dropdownItem}
          onClick={this.handleGoToProfile}>
          <ProfileContainer id={userId}>
            <ProfilePicture
              id={userId}
              style={{
                backgroundImage: `url(${getAvatar(avatar)})`
              }}
              verified={verified}
            />

            <ProfileInfoContainer id={userId}>
              <ProfileName id={userId} style={!showLocation ? { marginTop: '.5rem' } : {}}>
                {getName(profile)}
              </ProfileName>

              {showLocation && (
                <ProfileLocation id={userId}>
                  {personalAddress.city + ', ' + personalAddress.state}
                </ProfileLocation>
              )}
            </ProfileInfoContainer>
          </ProfileContainer>
        </MenuItem>
      )
    })

    const tags = searchBarResults.tags.map((tag) => {
      const { _id } = tag

      return (
        <MenuItem
          key={_id}
          id={_id}
          className={classes.dropdownItem}
          onClick={(e) => {
            this.handleGoToTag(e, _id)
          }}>
          <ProfileContainer id={_id}>
            <ProfileInfoContainer id={_id}>
              <ProfileLocation id={_id}>{_id}</ProfileLocation>
            </ProfileInfoContainer>
          </ProfileContainer>
        </MenuItem>
      )
    })

    const organizations = searchBarResults.organizations.map((organization) => {
      const { _id, image, publicName, address } = organization
      const { cityName, state } = address

      return (
        <MenuItem
          key={_id}
          id={_id}
          className={classes.dropdownItem}
          onClick={this.handleGoToOrganization}>
          <ProfileContainer id={_id}>
            <ProfilePicture
              id={_id}
              style={{
                backgroundImage: `url(${getOrganizationImage(image)})`
              }}
            />

            <ProfileInfoContainer id={_id}>
              <ProfileName id={_id}>{publicName}</ProfileName>

              {cityName && state && (
                <ProfileLocation id={_id}>{cityName + ', ' + state}</ProfileLocation>
              )}
            </ProfileInfoContainer>
          </ProfileContainer>
        </MenuItem>
      )
    })

    const searchResults =
      profiles.length > 0 || tags.length > 0 || organizations.length > 0 ? (
        <div>
          {profiles.length > 0 && <MenuItem className={classes.dropdownHeader}>People</MenuItem>}

          {profiles}

          {organizations.length > 0 && (
            <MenuItem className={classes.dropdownHeader}>Organizations</MenuItem>
          )}

          {organizations}

          {tags.length > 0 && <MenuItem className={classes.dropdownHeader}>Issues</MenuItem>}

          {tags}

          <Divider className={classes.divider} />

          <MenuItem className={classes.dropdownItem} onClick={this.handleGoToSearchResults}>
            <AllResults>
              View all results for <b>{search}</b>
            </AllResults>
          </MenuItem>
        </div>
      ) : (
        <MenuItem className={classes.dropdownItem}>
          <AllResults>
            No results for <b>{search}</b>
          </AllResults>
        </MenuItem>
      )

    return (
      <SearchContainer
      // showProfile={displayProps}
      >
        <form onSubmit={this.handleGoToSearchResults}>
          <SearchIcon className={classes.searchIcon} onClick={this.handleGoToSearchResults} />
          <SearchInput
            ref={this.popperRef}
            name="search"
            type="text"
            value={search}
            placeholder="Search..."
            onChange={this.handleInputChange}
            autoComplete="off"
          />
        </form>

        {open && <MobileResultsContainer>{searchResults}</MobileResultsContainer>}
      </SearchContainer>
    )
  }
}

SearchBarMobile.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  searchBarResults: state.searchBarResults,
  searchResults: state.searchResults
})

const mapDispatchToProps = (dispatch) => ({
  search: (params) => {
    return dispatch(search(params))
  },
  clearSearchBarResults: () => {
    return dispatch(searchBarResultsActions.clear())
  },
  clearMobileDrawer: () => {
    return dispatch(mobileDrawerActions.clear())
  }
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SearchBarMobile))
)
