import styled from 'styled-components'
import config from '../../../../../../config.js'

/**
 * 1 - OrgDetails Comps
 * 2 - About Comps
 * 3 - Connections Comps
 */

/** 1 - OrgDetails Comps */
export const AsideContainer = styled.aside`
  float: left;
  width: 27rem;
  margin-right: 0.75rem;
  // padding: 1.5rem 1.25rem;
  box-sizing: border-box;
  border-radius: 0.3rem;

  @media (max-width: ${config.UI.drawer_breakpoint.max}) {
    display: block;
    float: none;
    width: auto; /** reset width for responsiveness on mobile */
    min-width: none;
    max-width: none;
    margin-right: 0rem; /** unset */
    margin-bottom: 0.5rem;
  }
`

export const MobileInterestContainer = styled.article`
  display: none;

  @media (max-width: ${config.UI.drawer_breakpoint.max}) {
    display: block;
  }
`

export const SectionContainer = styled.section`
  background-color: ${(props) => props.theme.palette.common.white};
  box-sizing: border-box;
  border-radius: 0.3rem;
  padding: 1.5rem 1.25rem;
  margin-bottom: 1rem;
`

/** 2 - About Comps */

export const ArticleContainer = styled.article``

export const ArticleHeader = styled.h3`
  line-height: ${(props) => props.theme.typography.lineHeight};
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.tall};
  font-weight: ${(props) => props.theme.typography.fontWeight.semiBold};
  color: ${(props) => props.theme.palette.text.primary};
  margin-bottom: 0.75rem;
`

export const RedArticleHeader = styled.h4`
  background: ${(props) => props.theme.palette.secondary.light};
  line-height: ${(props) => props.theme.typography.lineHeight};
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.small};
  font-weight: ${(props) => props.theme.typography.fontWeight.semiBold};
  color: ${(props) => props.theme.palette.text.primary};
  width: 10rem;
  padding: 0.5rem 1rem;
  margin-bottom: 0.75rem;
  display: block;
  border-radius: 0.3rem;
  text-align: center;
`

export const WhiteBackgroundContainer = styled.div`
  background-color: ${(props) => props.theme.palette.background.lightGray};
  padding: 1rem;
  box-sizing: border-box;
  border-radius: 0.3rem;
  margin-bottom: 1.2rem;

  @media (max-width: ${config.UI.drawer_breakpoint.max}) {
    padding: 0.7rem;
  }
`

export const AboutCopyContainer = styled.div`
  position: relative;
  margin-top: ${(props) => props.marginTop};
`

export const StatusCopy = styled.p`
  color: ${(props) => props.theme.palette.text.primary};
  display: inline;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.short};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  line-height: 1.4rem;
`

export const Row = styled.div`
  display: block;
  margin-bottom: 0.5rem;
`

export const Label = styled.label`
  display: block;
  float: left;
  width: 5rem;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.short};
  font-weight: ${(props) => props.theme.typography.fontWeight.bold};
  color: ${(props) => props.theme.palette.text.primary};

  @media (max-width: 400px) {
    float: none;
  }
`

export const ValueContainer = styled.div`
  display: block;
  overflow: hidden;

  @media (max-width: 400px) {
    overflow: auto;
  }
`

export const Value = styled.p`
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.short};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  color: ${(props) => props.theme.palette.text.primary};

  & > a {
    text-decoration: underline;

    &:link,
    &:visited,
    &:active {
      color: ${(props) => props.theme.palette.link.primary};
    }
  }
`

/** 3- Connections Comps */

export const TransparentInlineContainer = styled.div`
  display: inline-block;
  width: 100%;
  text-align: center;
`

export const FollowContent = styled.div`
  display: block;
  margin-bottom: 0.2rem;
  line-height: ${(props) => props.theme.typography.lineHeight};
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.regular};
  font-weight: ${(props) => props.theme.typography.fontWeight.semiBold};
  text-align: center;
  color: ${(props) => props.theme.palette.link.primary};

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`

export const RolesContainer = styled.article`
  background-color: ${(props) => props.theme.palette.common.white};
  box-sizing: border-box;
  border-radius: 0.3rem;
  padding: 2rem 2rem;
  margin-bottom: 1rem;

  & > div:not(:first-child) {
    margin-top: 1.5rem;
  }
`

export const AvatarListHeader = styled.h3`
  line-height: ${(props) => props.theme.typography.lineHeight};
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.large};
  font-weight: ${(props) => props.theme.typography.fontWeight.semiBold};
  color: ${(props) => props.theme.palette.text.primary};
`

export const AvatarWrapper = styled.div`
  padding: 1rem 0 1rem 0;
  min-width: 7rem;
  text-align: center;
  cursor: pointer;
`

export const Name = styled.h4`
  display: block;
  margin-top: 0.5rem;
  /* text-align: center; */
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.fontSizes.short};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  color: ${(props) => props.theme.palette.text.primary};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`

export const SponsoredByContainer = styled.div`
  display: flex;
  align-items: center;

  & img {
    object-fit: contain;
  }
`

export const SponsoredByItem = styled.div`
  display: flex;
  align-items: center;
`

export const SponsoredByTitle = styled.p`
  margin-top: 0;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: 1.8rem;
  font-weight: ${(props) => props.theme.typography.fontWeight.medium};
  color: ${(props) => props.theme.palette.text.primary};
`

export const Image = styled.img`
  background-size: cover; /** covers IE support */
  object-fit: cover;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
`
