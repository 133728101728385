//////////////////
// ACTION TYPES //
//////////////////

export const myNotificationActionTypes = {
	SET_MY_NOTIFICATIONS:    	 	'SET_MY_NOTIFICATIONS', 
	ADD_MY_NOTIFICATIONS:       	'ADD_MY_NOTIFICATIONS',
	MARK_MY_NOTIFICATION_AS_READ:  	'MARK_MY_NOTIFICATION_AS_READ',
	CLEAR_MY_NOTIFICATIONS:     	'CLEAR_MY_NOTIFICATIONS'
}

/////////////
// ACTIONS //
/////////////

// MY NOTIFICATIONS
const setNotifications = notifications => {
	return {
  		type: myNotificationActionTypes.SET_MY_NOTIFICATIONS,
  		notifications
	}
}

const addNotifications = notifications => {
	return {
  		type: myNotificationActionTypes.ADD_MY_NOTIFICATIONS,
		notifications
	}
}

const markNotificationAsRead = notificationId => {
	return {
  		type: myNotificationActionTypes.MARK_MY_NOTIFICATION_AS_READ,
  		notificationId
	}
}

const clearNotifications = () => {
	return {
  		type: myNotificationActionTypes.CLEAR_MY_NOTIFICATIONS
	}
}

export const myNotificationsActions = {
	setNotifications,
	addNotifications,
	markNotificationAsRead,
	clearNotifications
}