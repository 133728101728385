//////////////////
// ACTION TYPES //
//////////////////

export const actionResponseActionTypes = {
  	CREATE_ACTION_RESPONSE:  'CREATE_ACTION_RESPONSE',
  	CLEAR_ACTION_RESPONSE:   'CLEAR_ACTION_RESPONSE',
}

/////////////
// ACTIONS //
/////////////

// ACTION RESPONSE
const createActionResponse = message => {
  	return { 
    	type: actionResponseActionTypes.CREATE_ACTION_RESPONSE, 
    	message 
  	}
}

const clearActionResponse = () => {
  	return { 
    	type: actionResponseActionTypes.CLEAR_ACTION_RESPONSE 
  	}
}

export const actionResponseActions = {
	createActionResponse,
	clearActionResponse
}