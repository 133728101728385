import { useState, useCallback } from 'react'
import ImageUpload from '../image-upload/image-upload'
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import EditIcon from '@mui/icons-material/Edit'
import CloseIcon from '@mui/icons-material/Close'
import { styled } from '@mui/system'
import {
  ImageUploadContainer,
  PreviewContainer,
  PostImagePreview
} from './styled/image-upload-comps'

const MuiEditButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.primary,
  position: 'absolute',
  top: '0.75rem',
  left: '1rem',
  zIndex: 100,
  backgroundColor: theme.palette.secondary.light,
  '&:hover': {
    backgroundColor: theme.palette.secondary.main
  }
}))

const MuiCloseIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.primary,
  position: 'absolute',
  top: '1rem',
  right: '1rem',
  zIndex: 100,
  padding: 0,
  backgroundColor: theme.palette.secondary.light,
  '&:hover': {
    backgroundColor: theme.palette.secondary.main
  }
}))

function PostImage({
  postImage,
  setPostImage,
  takeImage,
  isEditing,
  isImageDifferent,
  setIsImageDifferent,
  postImagePreview,
  setPostImagePreview
}) {
  const [open, setOpen] = useState(false)
  // const [postImagePreview, setPostImagePreview] = useState('')
  const [showTakeImage, setShowTakeImage] = useState(true)

  const handleEditImage = () => {
    setOpen(true)
  }

  const handleOpenImageUpload = () => {
    setOpen(true)
  }

  const handleCloseImageUpload = () => {
    setOpen(false)
  }

  const handleRemoveImageFromEditForm = useCallback(() => {
    setIsImageDifferent(true)
    setPostImage(undefined)
    setPostImagePreview(null)
    setShowTakeImage(false)
  }, [setIsImageDifferent, setPostImage])

  return (
    <ImageUploadContainer>
      {(!postImagePreview && !takeImage) || !showTakeImage ? (
        <Button
          onClick={handleOpenImageUpload}
          sx={{
            backgroundColor: 'secondary.light',
            color: 'text.primary',
            '&:hover': {
              backgroundColor: 'secondary.main',
              color: 'text.secondary'
            }
          }}>
          <AddAPhotoIcon
            sx={{
              fontSize: '1.25rem',
              margin: '0 0.25rem',
              color: 'text.primary'
            }}
          />
          Photo
        </Button>
      ) : (
        <PreviewContainer>
          <MuiEditButton onClick={handleEditImage} variant="contained">
            Edit
            <EditIcon sx={{ fill: 'text.primary', marginLeft: '0.25rem' }} fontSize="small" />
          </MuiEditButton>

          <MuiCloseIconButton onClick={handleRemoveImageFromEditForm}>
            <CloseIcon sx={{ fill: 'text.primary', padding: '0.25rem' }} fontSize="small" />
          </MuiCloseIconButton>

          <PostImagePreview
            id="#post-image"
            src={postImagePreview ? postImagePreview : takeImage}
          />
        </PreviewContainer>
      )}

      <ImageUpload
        open={open}
        handleOpen={handleOpenImageUpload}
        handleClose={handleCloseImageUpload}
        postForm={true}
        postImage={postImage}
        setPostImage={setPostImage}
        postImagePreview={postImagePreview}
        setPostImagePreview={setPostImagePreview}
        isImageDifferent={isImageDifferent}
        setIsImageDifferent={setIsImageDifferent}
        setShowTakeImage={setShowTakeImage}
      />
    </ImageUploadContainer>
  )
}

export default PostImage
