import { useEffect, useState } from 'react'
import moment from 'moment'
import withStyles from '@mui/styles/withStyles'
import {
  Table,
  TableBody,
  TableRow,
  TablePagination,
  Paper
  // CircularProgress
} from '@mui/material'
import * as TransAction_actionCreators from '../../../../../../../redux/action-creators/organization-transaction-history'
import { bindActionCreators } from 'redux'
import { useDispatch, useSelector } from 'react-redux'
import { Container, NoResults } from './styled/transaction-history'
import { styles } from './styles/styles'
import { stableSort, getSorting } from './utils/utility'
import EnhancedTableHead from './components/EnhancedTableHead'
import { CustomTableCell } from '../../../../../my-account/components/billing/stripe/components/transaction-history/styles/styles'
import { useParams } from 'react-router-dom'

const TransActionHistory = ({ classes }) => {
  const dispatch = useDispatch()
  const { setTransActionLimits, setTransActionPage, Organizations_history_DonationTokens } =
    bindActionCreators(TransAction_actionCreators, dispatch)

  const [ispageChanged, setispageChanged] = useState(false)
  const {
    Org_transActionHistory: { rowsPerPage, page, history, afterDate, loading, totalRecords },
    Org_transActionHistory
  } = useSelector((state) => state)

  const { orgId } = useParams()

  useEffect(() => {
    // eslint-disable-next-line no-unused-vars
    let mount = true
    Organizations_history_DonationTokens({ rowsPerPage, afterDate, ispageChanged, orgId })

    return () => {
      mount = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsPerPage, orgId])

  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState('whenCreated')
  const rows = []

  const handleRequestSort = (event, property) => {
    const newOrderBy = property
    let newOrder = 'desc'

    if (orderBy === property && order === 'desc') {
      newOrder = 'asc'
    }

    setOrder(newOrder)
    setOrderBy(newOrderBy)
  }

  const createData = (donationTokens, type, name_from, name_to, whenCreated, _id) => {
    return { donationTokens, type, name_from, name_to, whenCreated, _id }
  }
  if (history) {
    for (let i = 0; i < history?.length; i++) {
      rows.push(
        createData(
          history[i]?.donationTokens,
          history[i]?.type,
          history[i]?.name_from || '',
          history[i]?.name_to || '',
          history[i]?.whenCreated,
          history[i]?._id
        )
      )
    }
  }

  const changePageHandler = async (page) => {
    await setispageChanged(true)
    await setTransActionPage(page)
    const nextPageContent = history?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    if (Org_transActionHistory.page < page) {
      if (nextPageContent.length <= 0) {
        await Organizations_history_DonationTokens({ rowsPerPage, afterDate, orgId, update: true })
      }
    }
  }

  const changeLimitHandler = (page) => {
    setispageChanged(false)
    setTransActionLimits(page)
  }

  useEffect(() => {
    return () => {
      setTransActionPage(0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container>
      <span>Transaction History</span>
      {!loading && (
        <>
          {rows.length > 0 ? (
            <Paper className={classes.root}>
              <div className={classes.tableWrapper}>
                <Table className={classes.table} aria-labelledby="tableTitle">
                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={(e, prop) => handleRequestSort(e, prop)}
                    rowCount={rows.length - 1}
                  />

                  <TableBody>
                    <>
                      {stableSort(rows, getSorting(order, orderBy))
                        ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        ?.map((n, inx) => {
                          return (
                            <TableRow hover tabIndex={-1} key={inx} className={classes.row}>
                              <CustomTableCell
                                component="th"
                                scope="row"
                                padding="normal"
                                style={{ fontWeight: 400 }}>
                                {n.donationTokens}
                              </CustomTableCell>

                              <CustomTableCell align="right">{n.type}</CustomTableCell>

                              <CustomTableCell align="right">{n.name_from}</CustomTableCell>

                              <CustomTableCell align="right">{n.name_to}</CustomTableCell>

                              <CustomTableCell align="right">
                                {moment(n.whenCreated).format('MMM Do YYYY, LT')}
                              </CustomTableCell>
                            </TableRow>
                          )
                        })}
                    </>
                  </TableBody>
                </Table>
              </div>
              {totalRecords && (
                <TablePagination
                  component="div"
                  count={totalRecords}
                  rowsPerPage={rowsPerPage}
                  rowsPerPageOptions={[]}
                  page={page}
                  backIconButtonProps={{
                    'aria-label': 'Previous Page'
                  }}
                  nextIconButtonProps={{
                    'aria-label': 'Next Page'
                  }}
                  onPageChange={(e, page) => changePageHandler(page)}
                  onRowsPerPageChange={(e) => changeLimitHandler(e.target.value)}
                />
              )}
            </Paper>
          ) : (
            <NoResults>No results</NoResults>
          )}
        </>
      )}
    </Container>
  )
}

export default withStyles(styles)(TransActionHistory)
