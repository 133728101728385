import config from '../../../../../../config.js'

const loadMoreDiscussions = (
  afterDate,
  takeId,
  setIsLoadingMore,
  setHideLoadMore,
  actions
) => {
  const { loadLimits } = config

  setIsLoadingMore(true)

  const _getDiscussions = () => {
    return actions.getDiscussions({
      takeIds: [takeId],
      limit: loadLimits.discussions.perPost,
      afterDate
    })
  }

  const _getRebuttals = (debateIds) => {
    return actions.getRebuttals({
      debateIds,
      limit: loadLimits.rebuttals
    })
  }

  // Action to get articles by topic ID
  return _getDiscussions()
    .then((data1) => {
      if (data1.error) {
        console.log('[FAIL]: ', 'Failed to retrieve more discussions.')

        return Promise.resolve({ error: true })
      }

      console.log('[SUCCESS]: ', 'Successfully retrieved more discussions!')

      setHideLoadMore(data1.discussions.length < loadLimits.discussions.perPost)

      const discussionIds = data1.discussions.map((discussion) => discussion._id)

      return _getRebuttals(discussionIds)
    })
    .then((data2) => {
      if (data2.error) {
        console.log('[FAIL]: ', 'Failed to retrieve rebuttals.')
      }

      console.log('[SUCCESS]: ', 'Successfully retrieved rebuttals!')
      
      setIsLoadingMore(false)
    })
    .catch((error) => {
      console.log('[ERROR]: ', error)

      setIsLoadingMore(false)
    })
}

export default loadMoreDiscussions
