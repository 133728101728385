import config from '../../../config'
import { bindActionCreators } from 'redux'
import * as actionCreators from '../../action-creators/transAction-history'
import { getOrganizationsByIDs } from '../organizations/getOrganizationsByIDs'
import { getProfilesByUserIDs } from '../profiles/getProfilesByUserIDs'
import { authenticatedFetch } from '../../../utils/functions/authenticatedFetch'

export const history_DonationTokens = (params) => {
  const { rowsPerPage, afterDate, ispageChanged, currentProfile, update } = params
  let responseCode = ''
  let AllTypesTaken = []
  const method = 'GET'
  let url = `${config.baseURL}/api/donation-token-transaction/user/?limit=${rowsPerPage}`

  if (update) {
    if (afterDate) {
      url += `&afterDate=${afterDate}`
    }
  } else {
    if (ispageChanged && afterDate) {
      url += `&afterDate=${afterDate}`
    }
  }

  const headers = {
    'Content-Type': 'application/json'
  }

  // Thunk middleware knows how to handle functions.
  // It passes the dispatch method as an argument to the function,
  // thus making it able to dispatch actions itself.
  return (dispatch) => {
    const {
      setLoading,
      setTransActionHistory,
      addTransActionHistory,
      updateTransActionHistory_donation,
      updateTransActionHistory_transfer,
      updateTransActionHistory_subscription,
      updateTransActionHistory_purchase,
      updateTransActionHistory_verification
    } = bindActionCreators(actionCreators, dispatch)

    setLoading(true)
    !update && setTransActionHistory([])
    return authenticatedFetch(url, {
      method,
      headers,
      credentials: 'include'
    })
      .then((response) => {
        responseCode = response.status

        return response.json()
      })
      .then((data) => {
        if (responseCode === 200) {
          if (!data?.transactions?.length > 0) {
            setLoading(false)
            return
          }
          update ? addTransActionHistory(data?.transactions) : setTransActionHistory(data)
          const AllTypes = data?.transactions?.map((transaction) => {
            return transaction?.type
          })

          const uniqueAllTypes = [...new Set(AllTypes)]

          //transferType

          const transferType = data?.transactions?.filter(
            (transaction) => transaction?.type === 'transfer'
          )

          const transferType_Id = transferType?.map((item) => item.userId)
          const transferType_FromId = transferType?.map((item) => item.from)

          //Check this is user is currentUser or not

          const ids = [...transferType_Id, ...transferType_FromId]

          const otherUsersIds = ids.filter((id) => currentProfile.userId !== id)

          if (transferType.length > 0) {
            dispatch(getProfilesByUserIDs({ userIds: otherUsersIds })).then((ProfilesData) => {
              const ProfilesDataArray = Object.entries(ProfilesData.profiles).map(
                ([key, profile]) => {
                  return { ...profile }
                }
              )

              if (responseCode === 200) {
                for (let i = 0; i < data?.transactions.length; i++) {
                  const transaction = data?.transactions[i]
                  for (let j = 0; j < ProfilesDataArray?.length; j++) {
                    const Person = ProfilesDataArray[j]
                    const newValue =
                      transaction?.from === Person?.userId
                        ? {
                            ...transaction,
                            publicName: `${Person?.fname} ${Person?.lname}`
                          }
                        : transaction?.userId === Person?.userId
                        ? {
                            ...transaction,
                            transferedGuy: `${Person?.fname} ${Person?.lname}`
                          }
                        : transaction

                    const currect = [newValue]?.filter((item) => item?.type === 'transfer')

                    if (currect[0]?.transferedGuy || currect[0]?.publicName) {
                      updateTransActionHistory_transfer({
                        ...currect[0],
                        publicName: currect[0].publicName || '',
                        transferedGuy: currect[0].transferedGuy || ''
                      })
                    }
                  }

                  if (i < data?.transactions.length) {
                    const found = AllTypesTaken.find((element) => element === 'transfer')
                    !found && AllTypesTaken.push('transfer')
                  }
                  if (uniqueAllTypes.length === AllTypesTaken.length) {
                    setLoading(false)
                  }
                }
              }
            })
          }

          //donationType

          const donationType = data?.transactions?.filter(
            (transaction) => transaction?.type === 'donation'
          )
          const donationType_orgId = donationType?.map((item) => item.organizationId)

          if (donationType.length > 0) {
            dispatch(getOrganizationsByIDs({ organizationIds: donationType_orgId })).then(
              (organizationsData) => {
                if (responseCode === 200) {
                  for (let i = 0; i < data?.transactions.length; i++) {
                    const transaction = data?.transactions[i]
                    for (let j = 0; j < organizationsData?.organizations.length; j++) {
                      const Persons = organizationsData?.organizations[j]

                      const newValue =
                        Persons._id === transaction.organizationId
                          ? {
                              ...transaction,
                              publicName: Persons.publicName
                            }
                          : transaction
                      const currect = [newValue]?.filter((item) => item?.publicName)

                      if (currect.length > 0) {
                        updateTransActionHistory_donation({
                          ...currect[0],
                          name_to: currect[0].publicName,
                          name_from: 'You'
                        })
                      }
                    }

                    if (i < data?.transactions.length) {
                      const found = AllTypesTaken.find((element) => element === 'donation')
                      !found && AllTypesTaken.push('donation')
                    }
                    if (uniqueAllTypes.length === AllTypesTaken.length) {
                      setLoading(false)
                    }
                  }
                }
              }
            )
          }

          //subscriptionType

          const subscriptionType = data?.transactions?.filter(
            (transaction) => transaction?.type === 'subscription'
          )
          if (subscriptionType.length > 0) {
            for (let i = 0; i < data?.transactions.length; i++) {
              for (let j = 0; j < subscriptionType?.length; j++) {
                updateTransActionHistory_subscription(subscriptionType[j])
              }

              if (i < data?.transactions.length) {
                const found = AllTypesTaken.find((element) => element === 'subscription')
                !found && AllTypesTaken.push('subscription')
              }

              if (uniqueAllTypes.length === AllTypesTaken.length) {
                setLoading(false)
              }
            }
          }

          //purchaseType

          const purchaseType = data?.transactions?.filter(
            (transaction) => transaction?.type === 'purchase'
          )
          if (purchaseType.length > 0) {
            for (let i = 0; i < data?.transactions.length; i++) {
              for (let j = 0; j < purchaseType?.length; j++) {
                updateTransActionHistory_purchase(purchaseType[j])
              }

              if (i < data?.transactions.length) {
                const found = AllTypesTaken.find((element) => element === 'purchase')
                !found && AllTypesTaken.push('purchase')
              }
              if (uniqueAllTypes.length === AllTypesTaken.length) {
                setLoading(false)
              }
            }
          }

          //verification

          const verificationType = data?.transactions?.filter(
            (transaction) => transaction?.type === 'verification'
          )
          if (verificationType.length > 0) {
            for (let i = 0; i < data?.transactions.length; i++) {
              for (let j = 0; j < verificationType?.length; j++) {
                updateTransActionHistory_verification(verificationType[j])
              }

              if (i < data?.transactions.length) {
                const found = AllTypesTaken.find((element) => element === 'verification')
                !found && AllTypesTaken.push('verification')
              }

              if (uniqueAllTypes.length === AllTypesTaken.length) {
                setLoading(false)
              }
            }
          }

          return {
            error: false,
            code: responseCode,
            message: data.message
          }
        }

        return {
          error: true,
          code: responseCode,
          message: data.message
        }
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)

        return {
          error: true,
          code: '',
          message: error
        }
      })
  }
}
