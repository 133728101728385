interface ValidationErrors {
  required?: string;
  invalidOption?: string;
}

function validateSubjectSelect(subject: string): ValidationErrors {
  const errors: ValidationErrors = {} 
  //as ValidationErrors;

  // Trim spaces
  const trimmedSubject = subject.trim();

  // Required field
  if (!trimmedSubject) {
    errors.required = 'Subject is required.';
  }

  // Invalid option check (assuming valid options are non-empty strings)
  if (trimmedSubject && !/^[a-zA-Z0-9\s\.,!?-]*$/.test(trimmedSubject)) {
    errors.invalidOption = 'Selected subject is invalid.';
  }

  return errors;
}

export default validateSubjectSelect;