import config from '../../../config'
import { authenticatedFetch } from '../../../utils/functions/authenticatedFetch'
/*
 * METHOD TO SEARCH ORGANIZATIONS
 */
export function searchOrganizationMembers(params) {
  let responseCode = ''
  const method = 'GET'
  let url = `${config.baseURL}/api/search/organizationMember?query=${params.query}&limit=${params.limit}&orgName=${params.orgName}`

  if (params.roleName) {
    url += `&roleName=${params.roleName}`
  }

  const headers = {
    'Content-Type': 'application/json'
  }

  return function (dispatch) {
    console.log('[FETCH]: Sending search organization members request to CivilTalk')

    return authenticatedFetch(url, {
      method,
      headers,
      credentials: 'include'
    })
      .then((response) => {
        responseCode = response.status

        return response.json()
      })
      .then((data) => {
        if (responseCode === 200) {
          console.log(`[SUCCESS]:`, data.message)

          return {
            error: false,
            code: responseCode,
            message: data.message,
            data
          }
        }

        console.log(`[ERROR]: while searching org members`, data)

        return {
          error: true,
          code: responseCode,
          message: data.message
        }
      })
      .catch((error) => {
        console.log(`[ERROR]: caught while searching org members`, error)

        return {
          error: true,
          code: '',
          message: error
        }
      })
  }
}
