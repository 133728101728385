const updateProfileValidation = (fields) => {
  const { about, missionAndVision } = fields

  // CORE BELIEFS
  if (about.length > 750) {
    return {
      error: 'About can only be 750 characters long.',
      field: 'about'
    }
  }

  if (about.trim().length < 1) {
    return {
      error: 'About is required.',
      field: 'about'
    }
  }

  if (missionAndVision.length > 750) {
    return {
      error: 'Mission and vision can only be 750 characters long.',
      field: 'missionAndVision'
    }
  }

  if (missionAndVision.trim().length < 1) {
    return {
      error: 'Mission and vision is required.',
      field: 'missionAndVision'
    }
  }

  return {
    error: '',
    field: ''
  }
}

export default updateProfileValidation
