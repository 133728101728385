import Validator from 'validator'

const EditTakeValidation = ({ takeEdit, postEmotion }) => {
  // Edit Hot Take Validator
  if (Validator.isEmpty(takeEdit)) {
    return {
      error: 'Your take is required'
    }
  } else if (!Validator.isLength(takeEdit, { min: 1, max: 3000 })) {
    return {
      error: 'Your take must be 1-3000 characters long'
    }
  } else if (Validator.isEmpty(postEmotion)) {
    return {
      error: 'Please add an emotion to your edited take.'
    }
  }

  return {
    error: ''
  }
}

export default EditTakeValidation
