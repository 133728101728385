import MuiButton from '@mui/material/Button'
import { withStyles } from '@mui/styles'

const BackButton = withStyles((theme) => ({
  root: {
    display: 'inline-block',
    width: '6rem',
    marginRight: '0.5rem',
    marginTop: '1rem',
    color: theme.palette.primary.main,
    textTransform: 'capitalize'
  }
}))(MuiButton)

const TryAgainButton = withStyles((theme) => ({
  root: {
    display: 'inline-block',
    width: '8rem',
    marginRight: '0.5rem',
    marginTop: '1rem',
    color: theme.palette.text.primary,
    textTransform: 'capitalize',
    backgroundColor: theme.palette.cancelButton.light,
    '&:hover': {
      backgroundColor: theme.palette.cancelButton.dark
    }
  }
}))(MuiButton)

const SubmitButton = withStyles((theme) => ({
  root: {
    display: 'inline-block',
    width: '10rem',
    marginLeft: '0.5rem',
    marginTop: '1rem',
    textTransform: 'capitalize',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light
    }
  }
}))(MuiButton)

// step 3 - account for longer copy
const ConfirmButton = withStyles((theme) => ({
  root: {
    display: 'inline-block',
    width: '12rem',
    marginLeft: '0.5rem',
    marginTop: '1rem',
    textTransform: 'capitalize',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light
    }
  }
}))(MuiButton)

export { BackButton, TryAgainButton, ConfirmButton, SubmitButton }
