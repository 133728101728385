import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getZipCodeLocation } from '../../../../../redux/thunks/smartystreets/getZipCodeLocation'
import { updateProfileLocation } from '../../../../../redux/thunks/profiles/updateProfileLocation'
import SmartyStreetsInputForm from './components/smarty-streets-input-form'
import ConfirmSmartyStreetsResults from './components/confirm-smarty-streets-results'
import CurrentLocation from './components/current-location'

function LocationForm() {
  const [city, updateCity] = useState('')
  const [county, updateCounty] = useState('')
  const [state, updateState] = useState('')
  const [zipCode, updateZipCode] = useState('')
  const [step, updateStep] = useState(1)
  const [error, setError] = useState('')

  /** Redux */
  const currentLocation = useSelector((state) => state.currentProfile.personalAddress)
  const dispatch = useDispatch()

  const updateLocationResults = (lookups) => {
    const { zipcodes } = lookups

    updateCity(zipcodes[0].defaultCity)
    updateCounty(zipcodes[0].countyName)
    updateState(zipcodes[0].stateAbbreviation)
    updateZipCode(zipcodes[0].zipcode)
  }

  const handleStep1 = () => {
    updateStep(2)
  }

  const handleStep2 = () => {
    const params = { city, state, zipCode }

    dispatch(getZipCodeLocation(params)).then((data) => {
      if (data.code === 200) {
        setError('')
        const { lookups } = data.location

        updateLocationResults(lookups)
        updateStep(3)
      } else {
        setError(data.message)
      }
    })
  }

  const handleStep3 = () => {
    const fieldsToUpdate = { city, county, state, zipCode }

    dispatch(updateProfileLocation({ fieldsToUpdate }))
  }

  const handleBack = () => {
    if (step === 3) {
      updateStep(2)
    }
  }

  /** set location from redux store on component load if it exists */
  useEffect(() => {
    if (
      currentLocation.city === '' &&
      currentLocation.county === '' &&
      currentLocation.state === '' &&
      currentLocation.zipCode === ''
    ) {
      updateStep(2)
    }

    if (step === 1) {
      updateCity(currentLocation.city)
      updateCounty(currentLocation.county)
      updateState(currentLocation.state)
      updateZipCode(currentLocation.zipCode)
    }
  }, [])

  return (
    <>
      {step === 1 && (
        <CurrentLocation
          city={city}
          county={county}
          state={state}
          zipCode={zipCode}
          handleStep1={handleStep1}
        />
      )}

      {step === 2 && (
        <SmartyStreetsInputForm
          currentLocation={currentLocation}
          city={city}
          updateCity={updateCity}
          state={state}
          updateState={updateState}
          zipCode={zipCode}
          updateZipCode={updateZipCode}
          handleStep2={handleStep2}
          error={error}
        />
      )}

      {step === 3 && (
        <ConfirmSmartyStreetsResults
          city={city}
          county={county}
          state={state}
          zipCode={zipCode}
          handleBack={handleBack}
          handleStep3={handleStep3}
          error={error}
        />
      )}
    </>
  )
}

export default LocationForm
