import FoundingMemberBadge from '../../../../../media/accolades/founding-member.png'
import EarlyMemberBadge from '../../../../../media/accolades/early-member.png'
import {
  OuterContainer,
  InnerContainer,
  IconContainer,
  IconSpan,
  Image,
  Name
} from './styled/badge-comps'

const getBadgeMeta = (badge) => {
  const meta = {
    img: '',
    desc: badge.desc
  }

  switch (badge.type) {
    case 'founding-member':
      meta.img = FoundingMemberBadge
      break

    case 'early-member':
      meta.img = EarlyMemberBadge
      break

    case 'instructor-badge':
      meta.img = `https://res.cloudinary.com/headliner/image/upload/v1676325117/badges/instructor_badge.png`

    case 'uconn-civiltalk-certified':
      meta.img = `https://res.cloudinary.com/headliner/image/upload/v1682643285/badges/uconn-civiltalk-certified.png`
      break

    case 'uconn-husky':
      meta.img = `https://res.cloudinary.com/headliner/image/upload/v1673995817/badges/husky-dog-logo-circleR.jpg`
    default:
      break
  }

  return meta
}

function Badge(props) {
  const { badge, theme } = props

  const badgeMeta = getBadgeMeta(badge)

  console.log('badge', badge)
  console.log('badgeMeta', badgeMeta)
  return (
    <OuterContainer key={badge.userId}>
      <InnerContainer>
        <Image
          style={
            badge.type !== 'uconn-civiltalk-certified'
              ? {
                  backgroundImage: `url( ${badgeMeta.img})`,
                  width: '7rem',
                  height: '7rem'
                }
              : {
                  backgroundImage: `url( ${badgeMeta.img})`
                }
          }
        />
      </InnerContainer>
    </OuterContainer>
  )
}

export default Badge
