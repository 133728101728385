interface ValidationErrors {
  required?: string;
  invalidOption?: string;
}

function validateYearSelect(year: string): ValidationErrors {
  const errors: ValidationErrors = {};

  // Trim spaces
  const trimmedYear = year.trim();

  // Required field
  if (!trimmedYear) {
    errors.required = 'Year is required.';
  }

  // Valid year check
  const currentYear = new Date().getFullYear();
  const yearNumber = parseInt(trimmedYear, 10);

  if (isNaN(yearNumber) || yearNumber < 2024 || yearNumber > currentYear + 1) {
    errors.invalidOption = 'Selected year is invalid.';
  }

  return errors;
}

export default validateYearSelect;