import styled from 'styled-components'
import config from '../../../../../../../../config.js'

export const MainContainer = styled.div`
  margin-top: 1rem;
`

export const Heading = styled.p`
  ${'' /* padding-bottom: 0.1rem; */}
  ${'' /* border-bottom: 2px solid ${(props) => props.theme.palette.secondary.main}; */}
  color: ${(props) => props.theme.typography.fontColor.bodyCopy};
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-weight: ${(props) => props.theme.typography.fontWeight.bold};
  ${'' /* font-size: ${(props) => props.theme.typography.fontSizes.large}; */}
  font-size: 1.4rem;
`

export const Subheading = styled.p`
  display: block;
  margin-top: 1rem;
  color: ${(props) => props.theme.typography.fontColor.bodyCopy};
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  font-size: ${(props) => props.theme.typography.fontSizes.regular};

  & > a:link,
  & > a:visited,
  & > a:active {
    color: ${(props) => props.theme.palette.secondary.main};
    outline: none;
    text-decoration: none;
  }
`

export const ActionsContainer = styled.div`
  margin-top: 1rem;
  text-align: left;
`

export const StripeImage = styled.img`
  display: inline-block;
  vertical-align: middle;
  width: auto;
  height: 1.8rem;
  margin-left: 1rem;

  @media (max-width: 500px) {
    display: block;
    margin: 1rem auto 0 auto;
  }
`
