import { useState, useEffect } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material'
import { CourseSelectContainer, CourseSelectLabel } from './styled/course-select-comps'

const useStyles = makeStyles((theme) =>
  createStyles({
    select: {
      width: 0,
      height: 0,
      visibility: 'hidden'
    },
    courseSelect: {},
    selectHeader: {
      padding: '.5rem 1rem',
      lineHeight: '1rem',
      // color: theme.palette.text.primary,
      color: '#333333', // coal
      fontWeight: 600,
      fontSize: '1rem' // 16px
    },
    menuItem: {
      minWidth: '10rem',
      padding: '.3rem 1rem',
      // color: theme.palette.text.primary,
      color: '#333333', // coal
      fontWeight: 500,
      fontSize: '1rem',
      '.selected': {
        background: 'green' // theme.palette.secondary.main
      }
    }
  })
)

type Props = {
  courses: Array<{
    _id: string
    orgId: string
    title: string
    desc: string
    instructorId: string
    members: [string]
    subject: string
    courseNumber: string
    status: string
    season: string
    year: string
    whenCreated: string
    whenModified: string
  }>
  courseId: string
  prompt: {}
  organizationId: string
  setCourseId: (a: string) => string
}

function CourseSelect(props: Props) {
  const { courses, courseId, setCourseId, prompt, organizationId } = props
  const classes = useStyles()
  const [open, setOpen] = useState(false)

  if (prompt) return null

  if (organizationId) return null

  if (courses.length) {
    return (
      <CourseSelectContainer>
        <CourseSelectLabel id="course-select-label">Course:</CourseSelectLabel>
        <FormControl>
          <Select
            labelId="course-select-label"
            id="course-select"
            className={classes.courseSelect}
            open={open}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            value={courseId}
            onChange={(e) => setCourseId(e.target.value)}>
            {courses
              .filter((course) => course.status === 'open')
              .map((course) => (
                <MenuItem key={course._id} value={course._id} className={classes.menuItem}>
                  {`${course.title} ${course.season} ${course.year}`}
                </MenuItem>
              ))}
            <MenuItem key={0} value={'no_course'} className={classes.menuItem}>
              No course
            </MenuItem>
          </Select>
        </FormControl>
      </CourseSelectContainer>
    )
  } else {
    return null
  }
}

export default CourseSelect
