import config from '../../../config'
import { authenticatedFetch } from '../../../utils/functions/authenticatedFetch'
import { loadedTakesActions } from '../../action-creators/loaded-takes.js'

/*
 * METHOD TO CREATE VIEW
 */
export const createView = (params) => {
  let responseCode = ''
  const method = 'POST'
  const url = `${config.baseURL}/api/view/`
  const headers = {
    'Content-Type': 'application/json'
  }

  const requestBody = {
    'views': {
      'takeId': params.takeId,
      'userIds': [params.userId]
    }
  }

  const requestBodyJSON = JSON.stringify(requestBody)

  // Thunk middleware knows how to handle functions.
  // It passes the dispatch method as an argument to the function,
  // thus making it able to dispatch actions itself.
  return (dispatch) => {
    console.log('Sending create view request to CivilTalk')

    // Return the fetch so react components calling 'store.dispatch()' can use 'then()'
    return authenticatedFetch(url, {
      method,
      body: requestBodyJSON,
      headers,
      credentials: 'include'
    })
      .then((response) => {
        responseCode = response.status

        return response.json()
      })
      .then((data) => {
        if (responseCode === 201) {
          // update this take in Redux store with the new viewCount
          dispatch(
            loadedTakesActions.updateViewCount({
              takeId: params.takeId
            })
          )

          return {
            error: false,
            code: responseCode,
            message: data.message
          }
        } else {
          console.log('[DEBUG]: User has already viewed this take - carry on', data)
        }
      })
      .catch((error) => {
        console.log(error)

        return {
          error: true,
          code: '',
          message: error
        }
      })
  }
}
